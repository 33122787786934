import moment from 'moment';
import { ChevronRight, Info, Search } from 'react-feather';
import { theme } from '../../../../assets/theme';
import { useAuth } from '../../../../context/auth.context';
import useFetchList from '../../../../hooks/useFetchList';
import { LabRequestTemplateProperties } from '../../../../types/templateLabRequest';
import { Button } from '../../../Button/Button/Button';
import EmptyList from '../../../EmptyList';
import FormInput from '../../../Form/FormInput2';
import Separator from '../../../Separator';
import Spinner from '../../../Spinner';
import { ICDProperties } from '../../../../types/icd.type';

interface Props {
	setSelectedTemplate: React.Dispatch<
		React.SetStateAction<LabRequestTemplateProperties | undefined>
	>;
	nextStep: () => void;
	setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
	icds?: ICDProperties[];
	setIcds: React.Dispatch<React.SetStateAction<ICDProperties[] | undefined>>;

}

export default function ListLabRequestTemplate(props: Props) {
	const { user } = useAuth();

	const {
		data: labRequestTemplates,
		isLoading,
		pagination,
		search: searchLabTemplate,
		setSearch: setSearchLabTemplate,
		changePage,
	} = useFetchList<LabRequestTemplateProperties>({
		endpoint: 'template-lab-request',
		initialQuery: {
			doctorId: user?.userId,
		},
		primaryKey: 'templateLabRequestId',
		pushData: true,
	});

	function handleNextPage() {
		if (!isLoading && pagination.next) {
			changePage(pagination.page + 1);
		}
	}

	function onClickItem(item: LabRequestTemplateProperties) {
		props.setIsEditing(true);
		props.setSelectedTemplate(item);
		props.nextStep();
	}

	return (
		<div className="flex-1 flex flex-col">
			<div className="px-3 py-1">
				<FormInput
					prefix={
						<Search width={20} height={20} color={theme.colors.charcoal200} />
					}
					placeholder="Search template name"
					value={searchLabTemplate}
					onChange={setSearchLabTemplate}
				/>
			</div>
			<div className="flex-1 min-h-0">
				<div className="px-3">
					{!isLoading ? (
						labRequestTemplates.length > 0 ? (
							labRequestTemplates.map((template) => (
								<div key={template.templateLabRequestId}>
									<div className="py-4">
										<Item
											name={template.templateName}
											createdAt={template.createdAt}
											onClick={() => onClickItem(template)}
										/>
									</div>
									<Separator />
								</div>
							))
						) : (
							<EmptyList
								icon={<Info color={theme.colors.black50} />}
								title="Template is empty"
								description="You can add new lab request template"
							/>
						)
					) : (
						<div className="py-4 mx-auto w-fit">
							<Spinner size={25} color={theme.colors.primary} />
						</div>
					)}
				</div>
			</div>
			{!!pagination.next && (
				<div className="w-80 p-6 mx-auto">
					<Button type="SOLIDASH" onClick={handleNextPage}>
						Load More
					</Button>
				</div>
			)}
		</div>
	);
}

function Item(props: { name?: string; createdAt?: Date; onClick: () => void }) {
	return (
		<button
			onClick={props.onClick}
			className="bg-transparent border-none text-left w-full cursor-pointer flex gap-x-2"
		>
			<div className="flex-1">
				<p className="m-0">{props.name}</p>
				<div className="mt-2">
					<p className="m-0 text-3.5 text-black-60">Created at</p>
					<p className="m-0 text-4">
						{moment(props.createdAt).format('MMMM DD, YYYY')}
					</p>
				</div>
			</div>
			<ChevronRight width={20} height={20} color={theme.colors.black30} />
		</button>
	);
}
