import { CheckSquare, Plus, Square } from 'react-feather';
import { theme } from '../../../../../assets/theme';
import CustomScrollDiv from '../../../../CustomScrollDiv';
import EmptyList from '../../../../EmptyList';
import FormInput, { EFormType } from '../../../../Form/FormInput';
import SidebarMenuHeader from '../Components/SidebarMenuHeader';
import { ReactComponent as LabRequestIcon } from '../../../../../assets/icons/doc-multi-choice-with-pen.svg';
import IconButton from '../../../../Button/IconButton';
import { useMemo, useState } from 'react';
import { ELabRequestState } from './SidebarLabRequest';
import { IUser } from '../../../../../types/user.type';
import { LabRequestProperties } from '../../../../../types/labRequest.type';
import ItemLabRequestProcedure from './Components/ItemLabRequestProcedure';
import ButtonRemoveSave from '../Components/ButtonRemoveSave';
import {
	apiCreateLabRequest,
	apiDeleteLabRequest,
	apiUpdateLabRequest,
} from '../../../../../api/labRequest';
import { apiCreateTemplateLabRequest } from '../../../../../api/templateLabRequest';
import { useAuth } from '../../../../../context/auth.context';

interface Props {
	transactionConsultationId?: string;
	patientProfile?: IUser;
	labRequest?: LabRequestProperties;
	setLabRequest: React.Dispatch<
		React.SetStateAction<LabRequestProperties | undefined>
	>;
	saveLabRequest: React.Dispatch<
		React.SetStateAction<LabRequestProperties | undefined>
	>;
	onNext: ({ nav, dto }: { nav: ELabRequestState; dto?: any }) => void;
	onBack: () => void;
}

export default function SidebarLabRequestForm(props: Props) {
	const { user } = useAuth();

	const [isLoading, setIsLoading] = useState(false);
	const [templateName, setTemplateName] = useState<string>();

	const isDisableButton: boolean = useMemo(
		() =>
			!props.labRequest?.assessmentDiagnosis ||
			!props.labRequest.procedures ||
			props.labRequest.procedures.length === 0 ||
			!props.labRequest.specialInstructions,
		[props.labRequest],
	);

	function onUnselectProcedure(healthServiceId?: string) {
		if (!healthServiceId) return;

		props.setLabRequest((old) => {
			const newData = { ...old };
			newData.procedures = newData.procedures?.filter(
				(procedure) => procedure.healthServiceId !== healthServiceId,
			);
			return newData;
		});
	}

	async function removeLabRequest() {
		if (!props.labRequest?.labRequestId) {
			props.onBack();
			return;
		}

		setIsLoading(true);

		const res = await apiDeleteLabRequest(props.labRequest.labRequestId);
		if (res?.isSuccess) {
			props.saveLabRequest(undefined);
			props.onBack();
		} else {
			setIsLoading(false);
		}
	}

	async function saveLabRequest() {
		if (!props.labRequest) return;

		setIsLoading(true);

		let isSuccess = false;

		let data: LabRequestProperties = {
			...props.labRequest,
			isTemporary: true,
		};

		let labRequestId = '';
		if (props.labRequest.labRequestId) {
			const res = await apiUpdateLabRequest(
				props.labRequest.labRequestId,
				data,
			);
			if (res) {
				if (res.labRequestId) {
					labRequestId = res.labRequestId;
				}
				props.saveLabRequest(res);
				isSuccess = true;
			}
		} else {
			data = {
				...data,
				validUntil: new Date().toISOString(),
				transactionConsultationId: props.transactionConsultationId,
				doctorId: user?.userId,
				patientId: props.patientProfile?.userId,
				metaPatient: {
					firstName: props.patientProfile?.firstName,
					middleName: props.patientProfile?.middleName,
					lastName: props.patientProfile?.lastName,
					birthdate: props.patientProfile?.birthdate,
					gender: props.patientProfile?.gender,
				},
			};
			const res = await apiCreateLabRequest(data);
			if (res) {
				if (res.labRequestId) {
					labRequestId = res.labRequestId;
				}
				props.saveLabRequest(res);
				isSuccess = true;
			}
		}

		if (templateName) {
			isSuccess = false;
			const res = await apiCreateTemplateLabRequest({
				doctorId: user?.userId,
				templateName,
				assessmentDiagnosis: props.labRequest.assessmentDiagnosis,
				templateProcedures: props.labRequest.procedures,
				specialInstructions: props.labRequest.specialInstructions,
				saveFromId: labRequestId,
			});
			if (res) {
				isSuccess = true;
			}
		}

		if (isSuccess) {
			props.onBack();
		} else {
			setIsLoading(false);
		}
	}

	return (
		<div className="w-full flex flex-col">
			<SidebarMenuHeader onBack={props.onBack} title="Lab Request" />
			<CustomScrollDiv className="relative flex overflow-auto w-full h-full">
				<div className="w-full">
					<div className="flex-1 pb-6">
						<FormInput
							label="Assessment/Diagnosis"
							type={EFormType.MULTI_LINE}
							rows={4}
							placeholder="Write here"
							value={props.labRequest?.assessmentDiagnosis}
							onChange={(val) =>
								props.setLabRequest((old) => ({
									...old,
									assessmentDiagnosis: val,
								}))
							}
						/>
						<div className="mt-2">
							<div className="flex items-center">
								<p className="flex-1 m-0 text-4">Diagnostic Procedure(s)</p>
								<ButtonAddLabRequest
									onClick={() =>
										props.onNext({
											nav: ELabRequestState.LIST_PROCEDURES,
											dto: {
												selectedHealthServices:
													props.labRequest?.procedures?.map(
														(procedures) => procedures.metaHealthService,
													),
											},
										})
									}
								/>
							</div>
							<div>
								{props.labRequest?.procedures &&
								props.labRequest.procedures.length > 0 ? (
									props.labRequest.procedures.map((procedure) => (
										<ItemLabRequestProcedure
											key={procedure.healthServiceId}
											service={{
												healthServiceId: procedure.healthServiceId,
												title: procedure.metaHealthService.healthServiceName,
												isSelected: true,
											}}
											onSelect={onUnselectProcedure}
										/>
									))
								) : (
									<EmptyList
										icon={<LabRequestIcon color={theme.colors.black50} />}
										title="Lab Request Empty"
										description="You can add lab request from template or add manually"
									/>
								)}
							</div>
						</div>
						<FormInput
							label="Special Instructions"
							type={EFormType.MULTI_LINE}
							rows={4}
							placeholder="Write here"
							value={props.labRequest?.specialInstructions}
							onChange={(val) =>
								props.setLabRequest((old) => ({
									...old,
									specialInstructions: val,
								}))
							}
						/>
					</div>
				</div>
			</CustomScrollDiv>
			<div className="border-t-solid border-t border-gray-10 py-3 px-4">
				<div className="flex gap-x-2">
					<IconButton
						onClick={() =>
							setTemplateName((old) => (old !== undefined ? undefined : ''))
						}
					>
						{templateName !== undefined ? (
							<CheckSquare color={theme.colors.primary} />
						) : (
							<Square color={theme.colors.ash800} />
						)}
					</IconButton>
					<p className="m-0 text-4">Save this lab request as template</p>
				</div>
				{templateName !== undefined && (
					<FormInput
						label="Template Name"
						type={EFormType.SINGLE_LINE}
						placeholder="Write here"
						value={templateName}
						onChange={setTemplateName}
					/>
				)}
				<div className="mt-4">
					<ButtonRemoveSave
						isEnableRemoveBtn={
							!isLoading &&
							(!isDisableButton || !!props.labRequest?.labRequestId)
						}
						isEnableSaveBtn={!isLoading && !isDisableButton}
						onRemove={removeLabRequest}
						onSave={saveLabRequest}
					/>
				</div>
			</div>
		</div>
	);
}

function ButtonAddLabRequest(props: { onClick: any }) {
	return (
		<button
			onClick={props.onClick}
			className="flex items-center gap-x-1 text-primary text-4 m-0 p-0 bg-transparent border-none cursor-pointer"
		>
			<Plus color={theme.colors.primary} />
			<span>Add Lab Request</span>
		</button>
	);
}
