import React, { useState } from 'react';
import { Row, Col, Typography, Button, message, notification } from 'antd';
import { AuthByPhoneSendOTPResponse } from '../types/authByPhone.type';
import { httpRequest } from '../helpers/api';
import { useHistory } from 'react-router-dom';
import SignInModalOTP from './login/SignInModalOTP';
import SignInModal from './login/SignInModal';
import styled from 'styled-components';

const { Title, Text } = Typography;

const JoinDoctorBanner: React.FC = () => {
	const history = useHistory();

	const [role, setRole] = useState<'Patient' | 'Doctor'>();
	const [phoneNumber, setPhoneNumber] = useState<string>('');
	const [otp, setOTP] = useState<string>('');
	const [sessionId, setSessionId] = useState<string>('');

	const [showSignInModal, setShowSignInModal] = useState(false);
	const [showSignInModalOTP, setShowSignInModalOTP] = useState(false);

	const sendOTP = async (phoneNumber: string) => {
		try {
			const res: AuthByPhoneSendOTPResponse = await httpRequest.post(
				`${process.env.REACT_APP_BASE_URL}/auth/phone/send-otp`,
				{ phoneNumber: '+63' + phoneNumber },
			);
			console.log('ini yg dimasukin ke OTP::', res.data.payload.otp);
			console.log(
				'ini yg dimasukin ke sessionID::',
				res.data.payload.sessionId,
			);

			setOTP(res.data.payload.otp);
			setSessionId(res.data.payload.sessionId);
		} catch (e) {
			message.error('ERROR: Send OTP failed, please try again!');
			console.log('ERROR at sendOTP (AppHeader):::', e);
		}
	};

	return (
		<section id="join-as-doctor">
			<div className="container container-sm">
				<Row className="content" gutter={[24, 24]} align="middle">
					<Col xs={24} md={18} lg={20}>
						<Title level={4} className="white-heading">
							Are you a Doctor?
						</Title>
						<Text>
							Join our team and help us improve access to health services in
							Philippines!
						</Text>
					</Col>
					<Col xs={24} md={6} lg={4}>
						<CustomBtn
							type="default"
							onClick={() => {
								setRole('Doctor');
								setShowSignInModal(true);
							}}
						>
							Register Now
						</CustomBtn>
					</Col>
				</Row>
			</div>
			{showSignInModal && (
				<SignInModal
					phoneNumber={phoneNumber}
					role={role!}
					onConfirm={() => {
						setShowSignInModal(false);
						sendOTP(phoneNumber);
						setShowSignInModalOTP(true);
					}}
					onChange={(e) => {
						setPhoneNumber(e);
					}}
					showSignInModal={showSignInModal}
					onCancel={() => {
						setShowSignInModal(false);
					}}
				/>
			)}
			{showSignInModalOTP && (
				<SignInModalOTP
					role={role!}
					sessionId={sessionId}
					otp={otp}
					phoneNumber={phoneNumber}
					showSignInModal={showSignInModalOTP}
					onCancel={() => {
						setShowSignInModalOTP(false);
					}}
					onConfirm={({ isNewAccount, isDoctor }) => {
						setShowSignInModalOTP(false);
						if (isNewAccount) {
							history.push('/register');
						} else {
							if (role === 'Doctor' && isDoctor) {
								history.push('/app');
							} else {
								notification.open({
									message: 'Your account is not a doctor',
									description:
										'Please complete the registration form if you want to continue as a doctor. [1]',
									// onClick: () => {
									//   console.log('Notification Clicked!');
									// },
								});
								history.push('/register');
							}
						}
					}}
				/>
			)}
		</section>
	);
};
export default JoinDoctorBanner;

const CustomBtn = styled(Button)`
	width: 175px;
	height: 48px;

	span {
		font-weight: 600;
		font-size: 20px;
	}

	@media (min-width: 992px) {
		width: 225px;
		height: 68px;
		span {
			font-weight: 600;
			font-size: 28px;
		}
	}
`;
