import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../Header';
import InputsCalculator from './InputsCalculator';

type Props = {
	nextBtn: () => void;
};

export default function IndexMenstruation(Props: Props) {
	const history = useHistory();
	return (
		<div style={{ minHeight: '100vh' }}>
			<Header
				title="Add Period"
				backBtnHandler={(e) => history.push('/App/medical-calculator')}
			/>
			<InputsCalculator {...Props} />
		</div>
	);
}
