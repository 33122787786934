import { createGlobalState } from 'react-hooks-global-state';

type IAuthData = {
	isShowModalStore: boolean;
	isShowModalLendAHand: boolean;
};

const initialState: IAuthData = {
	isShowModalStore: false,
	isShowModalLendAHand: false,
};
const { useGlobalState } = createGlobalState(initialState);

export default function useModal() {
	const [isShowModalStore, setIsShowModalStore] =
		useGlobalState('isShowModalStore');
	const [isShowModalLendAHand, setIsShowModalLendAHand] = useGlobalState(
		'isShowModalLendAHand',
	);

	return {
		isShowModalStore,
		setIsShowModalStore,
		isShowModalLendAHand,
		setIsShowModalLendAHand,
	};
}
