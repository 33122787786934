import React from 'react';
import { Typography, Button, Space, Row, Col } from 'antd';
import styled from 'styled-components';
import { IconRoleDoctor, IconRolePatient } from '../../assets/images';

const { Text, Title } = Typography;

interface RoleButtonProps {
	role: string;
	onPressPatient?: () => void;
	onPressDoctor?: () => void;
}

function RoleButton({ role, onPressDoctor, onPressPatient }: RoleButtonProps) {
	return (
		<>
			<RoleSpace>
				<Row>
					{role === 'Patient' ? <IconRolePatient /> : <IconRoleDoctor />}
				</Row>
				<Row>
					<CustomModalTitle level={5}>I am a {role}</CustomModalTitle>
				</Row>
				<Row>
					<Text
						style={{
							fontSize: '14px',
							lineHeight: '22px',
							textAlign: 'center',
							color: '#748494',
						}}
					>
						If you are a {role} sign in here
					</Text>
				</Row>
				<Row>
					<Button
						type="primary"
						onClick={role === 'Patient' ? onPressPatient : onPressDoctor}
						style={{ margin: '6px 0 6px 0' }}
					>
						Sign in as {role}
					</Button>
				</Row>
			</RoleSpace>
		</>
	);
}

export default RoleButton;

const CustomModalTitle = styled(Title)`
	font-weight: 700 !important;
	color: black !important;
	margin-bottom: 0 !important;
	text-align: center;
`;

const RoleSpace = styled(Space)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0px;
	gap: 12px;

	max-width: 300px;
	max-height: 340px;

	padding: 60px 25px 60px 25px;
	margin: 0 10px 0 10px;

	background: #ffffff;
	box-shadow:
		0px 1px 3px rgba(0, 0, 0, 0.1),
		0px 1px 2px rgba(0, 0, 0, 0.06);
	border-radius: 18px;
`;
