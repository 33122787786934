import React from 'react';
import { Button, Col, Divider, Modal, Row, Typography } from 'antd';
import { iconPregnancy as IconPregnancy } from '../../../assets/icons';
import './styles.less';
import COLORS from '../../../assets/globalColors';

type Props = {
	isVisible: boolean;
	setVisibility: (state: boolean) => void;
	isLoading?: boolean;
};

const { Text, Paragraph } = Typography;

export default function ModalInfoPregnancy(props: Props) {
	function cancelReject() {
		props.setVisibility(false);
	}

	return (
		<Modal
			className="modal"
			open={props.isVisible}
			footer={null}
			closable={false}
		>
			<div className="flex flex-col items-center">
				<IconPregnancy />

				<Text
					style={{ marginBottom: 12, marginTop: 24 }}
					className="text-6 font-semibold"
				>
					Pregnancy Tracker
				</Text>
				<Text style={{ marginBottom: 16 }} className="text-4.5 font-semibold">
					What is Pregnancy Due Date with Ultrasound?
				</Text>
				<Paragraph
					style={{ marginBottom: 0, color: COLORS.black_70, fontSize: 16 }}
				>
					Ultrasound (or sonogram) scans are totally painless and safe. They use
					high-frequency sound waves to get an image of the fetus to check fetal
					development and the progress of your pregnancy. <br /> <br />
					The ultrasound can detect the fetus’s heartbeat around the 6th week,
					and this is one of the ways your health care provider can tell how far
					along you are in your pregnancy.
				</Paragraph>
			</div>
			<Divider style={{ margin: '12px 0px' }} />

			<Row className="mt-2.5 justify-end">
				<Col span={12}>
					<Button
						type="primary"
						size="large"
						onClick={cancelReject}
						className="rounded-3 bg-primary w-full"
					>
						<Text style={{ fontSize: 14, fontWeight: 700, color: '#fff' }}>
							Close
						</Text>
					</Button>
				</Col>
			</Row>
		</Modal>
	);
}
