import { message } from 'antd';
import { httpRequest } from '../helpers/api';
import { getErrorMessage } from '../helpers/errorHandler';
import {
	ApiResponseDefault,
	ApiResponsePagination,
} from '../types/apiResponse.type';
import { ApiPaginationResults, ApiResponseSuccess } from '../types/api.type';
import { DoctorDocumentProperties } from '../types/doctorDocument.type';
import { TemplateDoctorDocumentProperties } from '../types/doctorDocumentTemplate.type';
import { generateQueryString } from '../helpers/generateQueryString';

export async function apiGetListDoctorDocumentsByConsultations(
	transactionConsultationIds: string[],
) {
	try {
		const res = await httpRequest.get<
			ApiResponsePagination<DoctorDocumentProperties>
		>(
			`doctor-documents?transactionConsultationIds=${transactionConsultationIds.join(
				',',
			)}`,
		);
		return res.data.payload.results;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}

export async function apiGetListDoctorDocumentByConsultation(
	transactionConsultationId: string,
): Promise<ApiPaginationResults<DoctorDocumentProperties> | null> {
	let res = await httpRequest.get<
		ApiResponseDefault<ApiPaginationResults<DoctorDocumentProperties>>
	>(
		'/doctor-documents' +
			generateQueryString({
				transactionConsultationIds: [transactionConsultationId],
			}),
	);

	if (res && res.data && res.data.payload) {
		return res.data.payload;
	} else {
		return null;
	}
}

export async function apiGetDoctorDocument(doctorDocumentId: string) {
	try {
		const res = await httpRequest.get<
			ApiResponseDefault<DoctorDocumentProperties>
		>(`doctor-documents/${doctorDocumentId}`);
		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}

export async function apiCreateDoctorDocument(data: DoctorDocumentProperties) {
	try {
		const res = await httpRequest.post<
			ApiResponseDefault<DoctorDocumentProperties>
		>('doctor-documents', data);
		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}

export async function apiUpdateDoctorDocument(
	doctorDocumentId: string,
	data: Partial<DoctorDocumentProperties>,
) {
	try {
		const res = await httpRequest.patch<
			ApiResponseDefault<DoctorDocumentProperties>
		>(`doctor-documents/${doctorDocumentId}`, data);
		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}

export async function apiDeleteDoctorDocument(doctorDocumentId: string) {
	try {
		const res = await httpRequest.delete<
			ApiResponseDefault<{ isSuccess: boolean }>
		>(`doctor-documents/${doctorDocumentId}`);
		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}

export async function apiCreateTemplateDoctorDocument(
	data: TemplateDoctorDocumentProperties,
) {
	try {
		const res = await httpRequest.post<
			ApiResponseDefault<DoctorDocumentProperties>
		>('template-doctor-documents', data);
		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}
