import React, { useState } from 'react';
import SignInModal from './SignInModal';
import ChooseRoleModal from './ChooseRoleModal';
import { apiSendOTP } from '../../api/auth';
import { AuthByPhoneSendOTPResponse } from '../../types/authByPhone.type';
import { message, notification } from 'antd';
import { getErrorMessage } from '../../helpers/errorHandler';
import { useGlobal } from '../../context/global.context';
import SignInModalOTP from './SignInModalOTP';
import { useHistory, useLocation } from 'react-router-dom';

export default function SignInModalWithFunction() {
	const history = useHistory();
	const location = useLocation();

	const {
		isShowModalChooseRole,
		setIsShowModalChooseRole,
		isShowSignInModal,
		setIsShowSignInModal,
	} = useGlobal();

	const [isShowSignInModalOTP, setIsShowSignInModalOTP] = useState(false);

	const [role, setRole] = useState<'Patient' | 'Doctor'>();
	const [phoneNumber, setPhoneNumber] = useState<string>('');
	const [otp, setOTP] = useState<string>('');
	const [sessionId, setSessionId] = useState<string>('');

	const sendOTP = async (phoneNumber: string) => {
		try {
			const res: AuthByPhoneSendOTPResponse['data']['payload'] =
				await apiSendOTP(phoneNumber);

			if (res.otp) {
				setOTP(res.otp);
			}
			setSessionId(res.sessionId);
			setIsShowSignInModal(false);
			setIsShowSignInModalOTP(true);
		} catch (err) {
			message.error(
				'ERROR: Send OTP failed, please try again! ' + getErrorMessage(err),
			);
			console.log('ERROR at sendOTP (AppHeader):::', err);
		}
	};

	return (
		<>
			{isShowModalChooseRole && (
				<ChooseRoleModal
					showChooseRole={isShowModalChooseRole}
					onCancel={() => {
						setIsShowModalChooseRole(false);
					}}
					onConfirm={({ role }) => {
						setRole(role);
						setIsShowModalChooseRole(false);
						setIsShowSignInModal(true);
					}}
				/>
			)}

			{isShowSignInModal && (
				<SignInModal
					phoneNumber={phoneNumber}
					role={role!}
					onConfirm={() => {
						sendOTP(phoneNumber);
					}}
					onChange={(e) => {
						setPhoneNumber(e);
					}}
					showSignInModal={isShowSignInModal}
					onCancel={() => {
						setIsShowSignInModal(false);
					}}
				/>
			)}

			{/* Modal - Sign In - OTP */}
			{isShowSignInModalOTP && (
				<SignInModalOTP
					role={role!}
					sessionId={sessionId}
					otp={otp}
					phoneNumber={phoneNumber}
					showSignInModal={isShowSignInModalOTP}
					onCancel={() => {
						setIsShowSignInModalOTP(false);
					}}
					onConfirm={({ isNewAccount, isDoctor }) => {
						setIsShowSignInModalOTP(false);
						if (isNewAccount) {
							history.push('/register');
						} else {
							if (role === 'Doctor' && isDoctor) {
								console.info('location', location);
								history.replace('/app');
								window.location.href = '/app';
							} else {
								notification.open({
									message: 'Your account is not a doctor',
									description:
										'Please complete the registration form if you want to continue as a doctor. [3]',
									// onClick: () => {
									//   console.log('Notification Clicked!');
									// },
								});
								history.push('/register');
							}
						}
					}}
				/>
			)}
		</>
	);
}
