import { Button, Spin, Typography } from 'antd';
import COLORS from '../../../assets/globalColors';
import { BannerProps } from '../../../types/banner.type';
import { Swiper, SwiperSlide } from 'swiper/react';
import { type Swiper as SwiperRef } from 'swiper';
import PromoDealItem, { handleClickPromoDealItem } from './PromoDealItem';
import { iconChevronBlack as IconChevron } from '../../../assets/icons';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

export default function BottomPromoAndDeals({
	isLoading,
	banners,
	bgVariant = 'gray',
}: {
	isLoading: boolean;
	banners: BannerProps[];
	bgVariant?: 'white' | 'gray';
}) {
	const history = useHistory();
	const bannerId: string = (history?.location?.state as any)?.bannerId;

	const [swiperInstance, setSwiperInstance] = useState<SwiperRef | null>(null);

	const bgColor = bgVariant === 'white' ? COLORS.white : COLORS.gray_10;
	const padding = bgVariant === 'white' ? '20px 0px' : '48px 60px';
	return (
		<div
			style={{
				backgroundColor: bgColor,
				padding: padding,
			}}
		>
			<Typography.Title level={4}>Promo and Deals</Typography.Title>
			{isLoading && <Spin spinning />}
			{!isLoading && banners.length > 0 && (
				<>
					<div className="relative">
						<Swiper
							spaceBetween={10}
							slidesPerView={2.5}
							onSwiper={(swiper) => setSwiperInstance(swiper)}
							loop={true}
							style={{ width: '95%', margin: '0 auto' }}
						>
							{banners
								.filter((item) => item.bannerId !== bannerId)
								.map((banner) => (
									<SwiperSlide
										key={banner.bannerId}
										onClick={() =>
											handleClickPromoDealItem(
												banner.bannerType,
												banner,
												history,
											)
										}
									>
										<PromoDealItem
											banner={banner}
											isDetail
											bgVariant={bgVariant}
										/>
									</SwiperSlide>
								))}
						</Swiper>
						<>
							{banners.length && (
								<>
									<BtnArrow
										icon={
											<IconChevron style={{ transform: 'rotate(180deg)' }} />
										}
										onClick={() => swiperInstance?.slidePrev()}
										style={{ left: -22 }}
									></BtnArrow>
									<BtnArrow
										icon={<IconChevron />}
										onClick={() => swiperInstance?.slideNext()}
										style={{ right: -22 }}
									></BtnArrow>
								</>
							)}
						</>
					</div>
				</>
			)}
		</div>
	);
}

const BtnArrow = styled(Button)`
	width: 44px;
	height: 44px;
	display: grid;
	place-items: center;
	position: absolute;
	border-radius: 50% !important;
	top: 50%;
`;
