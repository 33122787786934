import { RcFile } from 'antd/lib/upload';

export async function exportAsPng(exportImage: any) {
	console.log('ini signature export as png');
	console.log(exportImage);
	console.log('converting to File...');
	const file = await convertPngToFile(exportImage);
	console.log('processed file is:::', file);
	return file;
}

export async function convertPngToFile(url: string) {
	const res = await fetch(url);
	const data = await res.blob();
	const metadata = {
		type: 'image/jpeg',
	};
	const name = `${Math.random().toString()}.jpg`;
	return new File([data], name, metadata);
}

export async function getBase64(file: RcFile): Promise<string> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result as string);
		reader.onerror = (error) => reject(error);
	});
}
