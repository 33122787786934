import { useEffect, useState } from 'react';
import { IndividualHealthServiceProperties } from '../../../../../types/individualHealthService.type';
import { LabRequestProperties } from '../../../../../types/labRequest.type';
import { IUser } from '../../../../../types/user.type';
import SidebarLabRequestForm from './SidebarLabRequestForm';
import SidebarLabRequestListProcedures from './SidebarLabRequestListProcedures';

interface Props {
	transactionConsultationId?: string;
	patientProfile?: IUser;
	labRequest?: LabRequestProperties;
	setLabRequest: React.Dispatch<
		React.SetStateAction<LabRequestProperties | undefined>
	>;
	onBack: Function;
}

export enum ELabRequestState {
	FORM = 'FORM',
	LIST_PROCEDURES = 'LIST_PROCEDURES',
}

export default function SidebarLabRequest(props: Props) {
	const [nav, setNav] = useState<ELabRequestState[]>([ELabRequestState.FORM]);

	const [labRequest, setLabRequest] = useState<LabRequestProperties>();
	const [dto, setDto] = useState<any>();

	useEffect(() => {
		if (!props.labRequest) return;

		setLabRequest(props.labRequest);
	}, [props.labRequest]);

	useEffect(() => {
		if (!dto?.selectedHealthServices) return;

		setLabRequest((old) => {
			const newData = { ...old };
			newData.procedures = (
				dto.selectedHealthServices as IndividualHealthServiceProperties[]
			).map((service) => ({
				healthServiceId: service.healthServiceId,
				metaHealthService: service,
			}));
			return newData;
		});
	}, [dto]);

	function addNav({ nav, dto }: { nav: ELabRequestState; dto?: any }) {
		setDto(dto);
		setNav((oldNav) => {
			const newNav = [...oldNav];
			const index = oldNav.indexOf(nav);
			if (index >= 0) {
				newNav.splice(index + 1, oldNav.length - index - 1);
			} else {
				newNav.push(nav);
			}
			return newNav;
		});
	}

	function onBack() {
		setDto(undefined);
		if (nav.length > 1) {
			setNav((old) => [...old.slice(0, -1)]);
		} else {
			props.onBack();
		}
	}

	switch (nav.at(-1)) {
		case ELabRequestState.FORM:
			return (
				<SidebarLabRequestForm
					transactionConsultationId={props.transactionConsultationId}
					patientProfile={props.patientProfile}
					labRequest={labRequest}
					setLabRequest={setLabRequest}
					saveLabRequest={props.setLabRequest}
					onNext={addNav}
					onBack={onBack}
				/>
			);
		case ELabRequestState.LIST_PROCEDURES:
			return (
				<SidebarLabRequestListProcedures
					selectedHealthServices={dto?.selectedHealthServices}
					onNext={addNav}
					onBack={onBack}
				/>
			);
	}

	return <></>;
}
