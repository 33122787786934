import clsx from 'clsx';
import React from 'react';
import AppButton from '../AppButton';

type Props = {
	btnLoadingLeft?: boolean;
	btnLoadingRight?: boolean;
	textLeft: string;
	textRight: string;
	disabledRight?: boolean;
	onPressLeft: () => void;
	onPressRight: () => void;
	btnTypeLeft?: 'primary' | 'secondary' | 'outlined' | 'opacity' | 'default';
	//   btnStyleLeft?: any;
	classStyleLeft?: any;
	textStyleLeft?: any;
	btnTypeRight?: 'primary' | 'secondary' | 'outlined' | 'opacity' | 'default';
	btnStyleRight?: any;
	textStyleRight?: any;
	//   style?: ViewStyle;
	classNames?: any;
};

export default function AppButtonTwoSide(props: Props) {
	return (
		<div>
			<AppButton
				loading={props.btnLoadingLeft}
				type={props.btnTypeLeft ? props.btnTypeLeft : 'default'}
				// classNames={[LAYOUT["flex-1"], LAYOUT["mr-5"], props.btnStyleLeft]}
				classNames={clsx(
					'app-button-two-side',
					props.classNames,
					props.classStyleLeft,
				)}
				classText={props.textStyleLeft}
				onPress={props.onPressLeft}
			>
				{props.textLeft}
			</AppButton>
		</div>
	);
}
