import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import AppLayout from '../../../../layout/AppLayout';
import PageHeader from '../../../../components/PageHeader';
import { Button, Card, Divider, Row, Spin, Typography, Col, Image } from 'antd';
import {
	iconEditOutlineColorize as IconEditOutlineColorize,
	iconDeleteOutlineColorize as IconDeleteOutline,
} from '../../../../assets/icons';
import {
	CertificateProperties,
	DoctorProperties,
	EducationProperties,
} from '../../../../types/doctor.type';
import { Info } from 'react-feather';
import EmptyList from '../../../../components/EmptyList';
import ModalRemoveEducation from '../../../../components/Webapp/DoctorInformation/ModalRemoveEducation';
import ModalRemoveCertificate from '../../../../components/Webapp/DoctorInformation/ModalRemoveCertificate';
import { httpRequest } from '../../../../helpers/api';
import { ApiResponseDefault } from '../../../../types/apiResponse.type';
import ModalAddEditCertificate from '../../../../components/Webapp/DoctorInformation/ModalAddEditCertificate';
import { DEFAULT_IMAGE } from '../../../../helpers/constants';
import ModalAddEducation from '../../../../components/Webapp/DoctorInformation/ModalAddEducation';
import { useAuth } from '../../../../context/auth.context';

const { Text } = Typography;

const activePath = '/app/setting/doctor-information';

export default function DoctorInformationSetting() {
	const history = useHistory();
	const { userId } = useAuth();

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [isModalAddEducation, setIsModalAddEducation] =
		useState<boolean>(false);
	const [isModalAddCertificate, setIsModalAddCertificate] =
		useState<boolean>(false);

	const [isModalRemoveEducation, setIsModalRemoveEducation] =
		useState<boolean>(false);
	const [isModalRemoveCertificate, setIsModalRemoveCertificate] =
		useState<boolean>(false);

	const [selectedEducation, setSelectedEducation] =
		useState<EducationProperties>();
	const [selectedCertificate, setSelectedCertificate] =
		useState<CertificateProperties>();

	const [doctor, setDoctor] = useState<DoctorProperties>();

	const fetchList = async () => {
		setIsLoading(true);
		try {
			const res = await httpRequest.get<ApiResponseDefault<DoctorProperties>>(
				'/doctors/' + userId,
			);
			if (res) {
				setDoctor(res.data.payload);
			}
			setIsLoading(false);
		} catch (error) {
			console.info('error fetch doctor ::: ', error);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchList();
	}, [userId]);

	//Helper Function

	const addEditCertificate = async (name: string, image: any, type: string) => {
		try {
			setIsLoading(true);

			if (type === 'add') {
				const data = {
					doctorId: userId,
					name: name,
				};

				const res = await httpRequest.post<
					ApiResponseDefault<CertificateProperties>
				>('/certificates', data);

				if (res.data.payload && image) {
					let formData = new FormData();
					formData.append('image', image);
					await httpRequest.put<ApiResponseDefault<null>>(
						'certificates/' +
							res.data.payload.certificateId +
							'/upload-certificate',
						formData,
						{
							headers: {
								'Content-Type': 'multipart/form-data',
							},
						},
					);
				}
			} else {
				const data = {
					name: name,
				};
				const res = await httpRequest.patch<
					ApiResponseDefault<CertificateProperties>
				>('/certificates/' + selectedCertificate?.certificateId, data);

				if (image && res.data.payload) {
					let formData = new FormData();
					formData.append('image', image);

					await httpRequest.put<ApiResponseDefault<null>>(
						'certificates/' +
							selectedCertificate?.certificateId +
							'/upload-certificate',
						formData,
						{
							headers: {
								'Content-Type': 'multipart/form-data',
							},
						},
					);
				}
			}

			setIsModalAddCertificate(false);
			setSelectedCertificate(undefined);
			fetchList();
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			console.info('error create or edit certificate ::: ', error);
		}
	};

	const addEducation = async (
		stage: string,
		institution: string,
		startYear: number,
		endYear: number,
	) => {
		try {
			setIsLoading(true);
			const newData = {
				doctorId: userId,
				stage: stage,
				institutionName: institution,
				startYear: startYear,
				endYear: endYear,
			};

			const res = await httpRequest.post<
				ApiResponseDefault<EducationProperties>
			>('/educations', newData);

			if (res) {
				fetchList();
				setIsModalAddEducation(false);
			}
		} catch (error) {
			setIsLoading(false);
			console.info('error add education ::: ', error);
		}
	};

	const handleCloseAddEditCertificateModal = () => {
		setSelectedCertificate(undefined);
		setIsModalAddCertificate(false);
	};

	const handleRemoveEducation = async (educationId: string) => {
		try {
			const res = await httpRequest.delete<ApiResponseDefault<null>>(
				'/educations/' + educationId,
			);

			if (res) {
				await fetchList();
				setSelectedEducation(undefined);
				setIsModalRemoveEducation(false);
			}
		} catch (error) {
			console.info('error remove education ::: ', error);
		}
	};

	const handleRemoveCertificate = async (certificateId: string) => {
		try {
			const res = await httpRequest.delete<ApiResponseDefault<null>>(
				'/certificates/' + certificateId,
			);

			if (res) {
				await fetchList();
				setIsModalRemoveCertificate(false);
			}
		} catch (error) {
			console.info('error remove certificate ::: ', error);
		}
	};

	// Components
	const ComponentTitle = (title: string) => {
		return (
			<Row align="middle">
				<Text
					style={{
						fontWeight: 600,
						fontSize: 18,
					}}
				>
					{title}
				</Text>
				{title === 'Profile' && (
					<Button
						type="ghost"
						style={{
							borderColor: '#D81F64',
							position: 'absolute',
							right: 0,
							marginRight: 24,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
						onClick={() => history.push(`${activePath}/edit`)}
					>
						<IconEditOutlineColorize />
						<Text
							style={{
								fontSize: 14,
								fontWeight: 700,
								color: '#D81F64',
								marginLeft: 8,
							}}
						>
							Edit
						</Text>
					</Button>
				)}
			</Row>
		);
	};

	const ProfileSection = (title: string, items: any) => {
		return (
			<Row>
				<Col span={8}>
					<Text style={{ fontWeight: 600, fontSize: 16, color: '#405261' }}>
						{title}
					</Text>
				</Col>
				<Col style={{ flex: 1 }}>
					{items.map((item: any, index: number) => (
						<div key={index}>
							{item.name === 'Specialization' && (
								<>
									{Object.keys(item.value).length > 0 ? (
										<>
											{Object.keys(item.value)?.map((specialistId: string) => {
												const specialist = item.value[specialistId];
												return (
													<div key={specialistId}>
														<Col>
															<Text
																style={{
																	fontWeight: 400,
																	fontSize: 14,
																	color: '#748494',
																}}
															>
																{item.name}
															</Text>
														</Col>
														<Col>
															<Text
																style={{
																	fontWeight: 400,
																	fontSize: 18,
																	color: '#121212',
																}}
															>
																{specialist.specialistName}
															</Text>
														</Col>
														{specialist?.subSpecialists?.length > 0 && (
															<>
																<Col>
																	<Text
																		style={{
																			fontWeight: 400,
																			fontSize: 14,
																			color: '#748494',
																		}}
																	>
																		{'Sub Specialization'}
																	</Text>
																</Col>
																{specialist?.subSpecialists?.map(
																	(sub: any, index: number) => (
																		<Col key={index}>
																			<Text
																				style={{
																					fontWeight: 400,
																					fontSize: 18,
																					color: '#121212',
																				}}
																			>
																				{sub.subSpecialistName}
																			</Text>
																		</Col>
																	),
																)}
															</>
														)}
														<Divider
															style={{ marginTop: 10, marginBottom: 10 }}
														/>
													</div>
												);
											})}
										</>
									) : (
										<>
											<Col>
												<Text
													style={{
														fontWeight: 400,
														fontSize: 14,
														color: '#748494',
													}}
												>
													{item.name}
												</Text>
											</Col>
											<Col>
												<Text
													style={{
														fontWeight: 400,
														fontSize: 16,
														color: '#929292',
														fontStyle: 'italic',
													}}
												>
													Not Set
												</Text>
											</Col>
										</>
									)}
								</>
							)}
							{item.name === 'Signature' && (
								<>
									<Col>
										<Text
											style={{
												fontWeight: 400,
												fontSize: 14,
												color: '#748494',
											}}
										>
											{item.name}
										</Text>
									</Col>
									<Image
										width={'100%'}
										height={220}
										style={{
											borderWidth: 1,
											borderStyle: 'solid',
											borderRadius: 10,
											objectFit: 'contain',
										}}
										src={item.value}
										fallback={DEFAULT_IMAGE}
									/>
								</>
							)}
							{item.name !== 'Specialization' && item.name !== 'Signature' && (
								<>
									<Col>
										<Text
											style={{
												fontWeight: 400,
												fontSize: 14,
												color: '#748494',
											}}
										>
											{item.name}
										</Text>
									</Col>
									<Col>
										{item.value ? (
											<Text
												style={{
													fontWeight: 400,
													fontSize: 18,
													color: '#121212',
												}}
											>
												{item.value}
											</Text>
										) : (
											<Text
												style={{
													fontWeight: 400,
													fontSize: 16,
													color: '#929292',
													fontStyle: 'italic',
												}}
											>
												Not Set
											</Text>
										)}
									</Col>
								</>
							)}
						</div>
					))}
				</Col>
			</Row>
		);
	};

	const ButtonEditDeleteComponent = (
		type: string,
		selectedItem: () => void,
	) => {
		return (
			<div style={{ display: 'flex', flexDirection: 'row' }}>
				{type === 'Certificate' && (
					<Button
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
						onClick={() => {
							selectedItem();
							setIsModalAddCertificate(true);
						}}
					>
						<IconEditOutlineColorize />
					</Button>
				)}
				<Button
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
					onClick={() => {
						selectedItem();
						type === 'Education'
							? setIsModalRemoveEducation(true)
							: setIsModalRemoveCertificate(true);
					}}
				>
					<IconDeleteOutline />
				</Button>
			</div>
		);
	};

	const EducationItem = (item: EducationProperties) => {
		const selectedEducation = () => setSelectedEducation(item);
		return (
			<Row
				style={{
					display: 'flex',
					flex: 1,
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<Col style={{ flex: 1 }}>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							marginRight: 10,
						}}
					>
						<Text style={{ fontWeight: 400, fontSize: 16, color: '#000' }}>
							{item.stage}
						</Text>
						<Text style={{ fontWeight: 400, fontSize: 14, color: '#777777' }}>
							{item.institutionName}
						</Text>
						<Text
							style={{ fontWeight: 400, fontSize: 14, color: '#777777' }}
						>{`${item.startYear} - ${item.endYear}`}</Text>
					</div>
				</Col>
				<Col>{ButtonEditDeleteComponent('Education', selectedEducation)}</Col>
			</Row>
		);
	};

	const CertificateItem = (item: CertificateProperties) => {
		const selectedCertificate = () => setSelectedCertificate(item);
		return (
			<Row
				style={{
					display: 'flex',
					flex: 1,
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<Col style={{ flex: 1 }}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Image
							width={150}
							height={90}
							style={{ borderRadius: 10, objectFit: 'contain' }}
							src={item.url || DEFAULT_IMAGE}
						/>
						<Text
							style={{
								fontWeight: 400,
								fontSize: 16,
								color: '#000000',
								marginLeft: 10,
								marginRight: 10,
								flex: 1,
							}}
						>
							{item.name}
						</Text>
					</div>
				</Col>
				<Col>
					{ButtonEditDeleteComponent('Certificate', selectedCertificate)}
				</Col>
			</Row>
		);
	};

	// Component Groups
	const Profile = () => {
		const title = 'Profile';

		let specialistGrouping = [];
		if (doctor?.specialists && doctor?.specialists?.length > 0) {
			specialistGrouping = doctor?.specialists?.reduce(function (
				storage: any,
				item: any,
			) {
				const data = {
					specialistId: item.specialistId || '',
					specialistName: item.specialistName || '',
					subSpecialists:
						item.subSpecialistId || item.subSpecialistName
							? [
									{
										subSpecialistId: item.subSpecialistId || '',
										subSpecialistName: item.subSpecialistName || '',
									},
							  ]
							: [],
				};

				let group = item['specialistId'];

				storage[group] = storage[group] || {};

				const storageLength = Object.keys(storage[group]).length;
				if (storageLength > 0) {
					storage[group] = {
						...storage[group],
						subSpecialists: [
							...(storage[group].subSpecialists || []),
							...(data.subSpecialists || []),
						],
					};
				} else {
					storage[group] = data;
				}
				return storage;
			}, {});
		}

		let identifyItems = [
			{ name: 'First Name', value: doctor?.user?.firstName },
			{ name: 'Middle Name', value: doctor?.user?.middleName },
			{ name: 'Last Name', value: doctor?.user?.lastName },
			{ name: "Doctor's Title", value: doctor?.title },
			{ name: 'Nickname', value: doctor?.user?.nickName },
			{ name: 'Experience', value: `${doctor?.experience} Years` },
		];
		let specializationItems = [
			{ name: 'Specialization', value: specialistGrouping },
			{ name: 'Other Sub Specialitation', value: doctor?.otherSpecialist },
		];
		let taxInformationItems = [
			{ name: 'PRC Number', value: doctor?.prcNumber },
			{ name: 'PTR Number', value: doctor?.ptrNumber },
			{ name: 'Tax Identification Number (TIN)', value: doctor?.tinNumber },
		];
		let signatureItems = [
			{ name: 'Name of Signature', value: doctor?.nameOfSignature },
			{ name: 'Signature', value: doctor?.signatureImageUrl },
		];

		return (
			<div style={{ marginBottom: 80 }}>
				{ComponentTitle(title)}
				<Divider style={{ marginTop: 20, marginBottom: 20, borderWidth: 4 }} />
				{ProfileSection('Identify', identifyItems)}
				<Divider />
				{ProfileSection('Specialization', specializationItems)}
				<Divider />
				{ProfileSection('Tax Information', taxInformationItems)}
				<Divider />
				{ProfileSection('Signature', signatureItems)}
			</div>
		);
	};

	const Education = () => {
		return (
			<div style={{ marginBottom: 80 }}>
				{ComponentTitle('Education')}
				<Divider style={{ marginTop: 20, marginBottom: 20, borderWidth: 4 }} />
				<Row>
					<Col span={8}>
						<Text style={{ fontWeight: 600, fontSize: 16, color: '#405261' }}>
							Education
						</Text>
					</Col>
					<Col style={{ flex: 1 }}>
						{doctor?.educations?.length !== 0 ? (
							doctor?.educations?.map(
								(item: EducationProperties, index: number) => (
									<div key={index}>
										{EducationItem(item)}
										{index + 1 !== doctor?.educations?.length && (
											<Divider style={{ marginTop: 10, marginBottom: 10 }} />
										)}
									</div>
								),
							)
						) : (
							<EmptyList
								icon={<Info size={40} color={'#AEAEAE'} />}
								title="Data Empty"
								description="You have not added Certificates."
							/>
						)}
						<Button
							type="ghost"
							style={{
								width: '100%',
								borderColor: '#D81F64',
								marginTop: doctor?.educations?.length !== 0 ? 20 : 0,
							}}
							onClick={() => setIsModalAddEducation(true)}
						>
							<Text
								style={{
									fontSize: 14,
									fontWeight: 700,
									color: '#D81F64',
									marginLeft: 8,
								}}
							>
								Add New Education
							</Text>
						</Button>
					</Col>
				</Row>
			</div>
		);
	};

	const Certificate = () => {
		return (
			<div style={{ marginBottom: 80 }}>
				{ComponentTitle('Certificates')}
				<Divider style={{ marginTop: 20, marginBottom: 20, borderWidth: 4 }} />
				<Row>
					<Col span={8}>
						<Text style={{ fontWeight: 600, fontSize: 16, color: '#405261' }}>
							Certificates
						</Text>
					</Col>
					<Col style={{ flex: 1 }}>
						{doctor?.certificates?.length !== 0 ? (
							doctor?.certificates?.map(
								(item: CertificateProperties, index: number) => (
									<div key={index}>
										{CertificateItem(item)}
										{index + 1 !== doctor?.certificates?.length && (
											<Divider style={{ marginTop: 10, marginBottom: 10 }} />
										)}
									</div>
								),
							)
						) : (
							<EmptyList
								icon={<Info size={40} color={'#AEAEAE'} />}
								title="Data Empty"
								description="You have not added certificate."
							/>
						)}
						<Button
							type="ghost"
							style={{
								width: '100%',
								borderColor: '#D81F64',
								marginTop: doctor?.certificates?.length !== 0 ? 20 : 0,
							}}
							onClick={() => setIsModalAddCertificate(true)}
						>
							<Text
								style={{
									fontSize: 14,
									fontWeight: 700,
									color: '#D81F64',
									marginLeft: 8,
								}}
							>
								Add New Certificate
							</Text>
						</Button>
					</Col>
				</Row>
			</div>
		);
	};

	return (
		<AppLayout activePath={activePath}>
			<Card style={{ paddingBottom: 110 }}>
				<PageHeader
					title="Doctor Information"
					onClick={() => history.goBack()}
				/>
				<Divider />
				{isLoading && <CustomSpin spinning={isLoading} />}
				{!isLoading && doctor && (
					<>
						<Profile />
						<Education />
						<Certificate />
					</>
				)}
				<ModalRemoveEducation
					isVisible={isModalRemoveEducation}
					setVisibility={setIsModalRemoveEducation}
					removeEducationHandler={() =>
						handleRemoveEducation(selectedEducation?.educationId || '')
					}
				/>
				<ModalRemoveCertificate
					isVisible={isModalRemoveCertificate}
					setVisibility={setIsModalRemoveCertificate}
					removeCertificateHandler={() =>
						handleRemoveCertificate(selectedCertificate?.certificateId || '')
					}
				/>
				<ModalAddEducation
					isVisible={isModalAddEducation}
					setVisibility={setIsModalAddEducation}
					action={(stage, institutionName, startYear, endYear) =>
						addEducation(stage, institutionName, startYear, endYear)
					}
					isLoading={isLoading}
				/>
				<ModalAddEditCertificate
					isVisible={isModalAddCertificate}
					isClose={() => handleCloseAddEditCertificateModal()}
					action={(name: string, image: any, action: string) =>
						addEditCertificate(name, image, action)
					}
					isLoading={isLoading}
					data={selectedCertificate}
				/>
			</Card>
		</AppLayout>
	);
}

// Custom Components
const CustomSpin = styled(Spin)`
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0);
`;
