import { Card, Col, Row, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';
import COLORS from '../../../assets/globalColors';
import { iconTriangle as IconTriangle } from '../../../assets/icons';

type Props = {
	dataIndicator: any;
	resultValue: any;
	estimated: any;
};
export default function Score({
	dataIndicator,
	resultValue,
	estimated,
}: Props) {
	return (
		<CardResult>
			<Row className="justify-between">
				<Col>
					<Row>
						<Typography.Text
							style={{ fontSize: 16, color: COLORS.primary_black_50 }}
						>
							Health Risk
						</Typography.Text>
					</Row>
					<Row
						style={{
							backgroundColor: resultValue?.item?.color || undefined,
							padding: '4px 10px',
							borderRadius: 9999,
							alignItems: 'center',
							justifyContent: 'center',
							marginTop: 4,
						}}
					>
						<Typography.Text
							style={{
								fontSize: 18,
								textAlign: 'center',
								fontWeight: 600,
								color: COLORS.white,
							}}
						>
							{resultValue?.item?.title}
						</Typography.Text>
					</Row>
				</Col>
				<Col>
					<Row>
						<Typography.Text
							style={{ fontSize: 16, color: COLORS.primary_black_50 }}
						>
							Risk Score
						</Typography.Text>
					</Row>
					<Row
						style={{
							backgroundColor: ' #EEF2F4',
							padding: '4px 8px',
							borderRadius: 9999,
							alignItems: 'center',
							justifyContent: 'center',
							marginTop: 4,
						}}
					>
						<Typography.Text
							style={{
								fontSize: 18,
								textAlign: 'center',
								fontWeight: 600,
								color: COLORS.black,
							}}
						>
							{Number(resultValue?.score?.toFixed(2) || 0)}
						</Typography.Text>
					</Row>
				</Col>
			</Row>

			<div
				style={{
					marginTop: 25,
					display: 'flex',
					overflow: 'hidden',
					position: 'relative',
					width: '100%',
					height: 22,
					backgroundColor: '929292',
					borderRadius: 10,
				}}
			>
				{dataIndicator?.map((item: any, index: number) => (
					<div
						key={index}
						style={{
							zIndex: 1,
							width: `${item?.width}%`,
							backgroundColor: item?.color,
						}}
					/>
				))}
				<IconTriangle
					style={{
						position: 'absolute',
						zIndex: 2,
						bottom: -3,
						transform: '[{ translateX: -8 }, { translateY: 0 }]',
						color: COLORS?.white,
						left: `${Math.floor(resultValue?.pointer)}%`,
					}}
					width={20}
					height={20}
				/>
			</div>

			<Row style={{ marginTop: 24 }}>
				<Col span={24}>
					<Row>
						<Typography.Text
							style={{
								fontSize: 16,
								color: COLORS.primary_black_50,
							}}
						>
							{estimated?.title}
						</Typography.Text>
					</Row>
					<Row
						style={{
							alignItems: 'center',
							justifyContent: 'center',
							width: '100%',
							backgroundColor: '#EEF2F4',
							borderRadius: 20,
							padding: '10px 0px',
							marginTop: 8,
						}}
					>
						<Typography.Text
							style={{
								fontSize: 18,
								color: COLORS.black,
							}}
						>
							{estimated?.value}
						</Typography.Text>
					</Row>
				</Col>
			</Row>
		</CardResult>
	);
}

const CardResult = styled(Card)`
	border-radius: 20px;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

	div.ant-card-body {
		padding: 20px;
	}
`;
