import React, { useState } from 'react';
import { Button, Col, Modal, Row, Select, Typography } from 'antd';
import FormInput from '../../Form/FormInput';

type Props = {
	isVisible: boolean;
	setVisibility: (state: boolean) => void;
	isLoading: boolean;
	action: (
		stage: string,
		name: string,
		startYear: number,
		endYear: number,
	) => Promise<void>;
};

const { Text } = Typography;

export default function ModalAddEditCertificate(props: Props) {
	const [stage, setStage] = useState<string>('');
	const [institutionName, setInstitutionName] = useState<string>('');
	const [startYear, setStartYear] = useState<string>('');
	const [endYear, setEndYear] = useState<string>('');

	const handleAction = async () => {
		await props.action(
			stage,
			institutionName,
			Number(startYear),
			Number(endYear),
		);
		setStage('');
		setInstitutionName('');
		setStartYear('');
		setEndYear('');
	};

	return (
		<Modal
			className="modal"
			open={props.isVisible}
			footer={null}
			closable={false}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<Text style={{ fontWeight: 600, fontSize: 20, color: '#000000' }}>
					Add Education
				</Text>
				<Text style={{ fontWeight: 400, fontSize: 14, color: '#000000' }}>
					Write your education detail here
				</Text>
			</div>
			<div>
				<FormInput
					label="Stage"
					placeholder="eg. Medical Degree"
					onChange={(val) => setStage(val)}
					value={stage}
				/>
				<FormInput
					label="Specialization, Institution"
					placeholder="eg. Internal Medicine, Pamatasan ng Lungsod ng Maynila"
					onChange={(val) => setInstitutionName(val)}
					value={institutionName}
				/>
				<Row style={{ justifyContent: 'space-between' }}>
					<Col>
						<FormInput
							label="Start Year"
							placeholder="eg. 2020"
							onChange={(val) => setStartYear(val)}
							value={startYear}
						/>
					</Col>
					<Col>
						<FormInput
							label="End Year"
							placeholder="eg. 2022"
							onChange={(val) => setEndYear(val)}
							value={endYear}
						/>
					</Col>
				</Row>
				{startYear && endYear && startYear >= endYear && (
					<Text style={{ fontSize: 16 }} type="danger">
						Start Year must lower than End Year
					</Text>
				)}
			</div>
			<div className="modal-body">
				<Row gutter={24} style={{ marginTop: '48px' }}>
					<Col span={12}>
						<Button
							type="text"
							size="large"
							className="text-3.5 text-secondary rounded-3 bg-gray-10 border-0 w-full font-bold"
							onClick={() => props.setVisibility(false)}
						>
							Not Now
						</Button>
					</Col>
					<Col span={12}>
						<Button
							loading={props.isLoading}
							disabled={
								!stage ||
								!institutionName ||
								!startYear ||
								!endYear ||
								startYear >= endYear
							}
							type="primary"
							size="large"
							style={{ color: '#fff' }}
							className="text-3.5 text-white rounded-3 bg-primary w-full font-bold"
							onClick={async () => handleAction()}
						>
							Yes
						</Button>
					</Col>
				</Row>
			</div>
		</Modal>
	);
}
