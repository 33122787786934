import { Conversation, Message } from '@twilio/conversations';
import { Modal, message as msg, message } from 'antd';
import moment from 'moment';
import styled from 'styled-components';
import { useEffect, useMemo, useRef, useState } from 'react';
import Dropzone, { DropEvent, FileRejection } from 'react-dropzone';
import { Download, FileText, Paperclip, Send, Upload } from 'react-feather';
import { theme } from '../../../../assets/theme';
import { httpRequest } from '../../../../helpers/api';
import { getErrorMessage } from '../../../../helpers/errorHandler';
import { getInitialName } from '../../../../helpers/name';
import { ApiResponseDefault } from '../../../../types/apiResponse.type';
import { ChatMediaResponse } from '../../../../types/chatMedia.type';
import { ETransactionStatusType } from '../../../../types/transactionConsultation.type';
import { IUser } from '../../../../types/user.type';
import { Button } from '../../../Button/Button/Button';
import IconButton from '../../../Button/IconButton';
import CustomScrollDiv from '../../../CustomScrollDiv';
import FormInput, { EFormType } from '../../../Form/FormInput2';
import Spinner from '../../../Spinner';
import { useAuth } from '../../../../context/auth.context';

interface Props {
	otherParticipant?: IUser;
	conversation?: Conversation;
	transactionConsultationStatus?: ETransactionStatusType;
}

type LoadingState = 'ready' | 'failed' | 'initializing' | 'sending_message';

export default function TeleconferenceChat(props: Props) {
	const { user } = useAuth();

	const [newMessage, setNewMessage] = useState('');
	const [messages, setMessages] = useState<Message[]>([]);
	const [loadingState, setLoadingState] =
		useState<LoadingState>('initializing');

	useEffect(() => {
		if (!props.conversation) return;

		props.conversation.on('messageAdded', onMessageAdded);

		loadMessage(props.conversation);

		return () => {
			props.conversation?.removeListener('messageAdded', onMessageAdded);
		};
	}, [props.conversation]);

	async function loadMessage(conversation: Conversation) {
		try {
			const messagePaginator = await conversation.getMessages();
			setMessages(messagePaginator.items);
			setLoadingState('ready');
		} catch (err) {
			msg.error("Couldn't fetch messages IMPLEMENT RETRY");
			console.error(err);
			setLoadingState('failed');
		}
	}

	function onMessageAdded(message: Message) {
		setMessages((prevState) => [...prevState, message]);
	}

	async function sendMessage() {
		if (!newMessage) return;

		setLoadingState('sending_message');
		try {
			const tempId = Math.random().toString();

			const attributes = { giftedId: tempId };
			await props.conversation?.sendMessage(newMessage, attributes);
		} catch (err) {
			console.error(err);
			msg.error(err);
		}
		setNewMessage('');
		setLoadingState('ready');
	}

	async function sendFile(element: React.ChangeEvent<HTMLInputElement>) {
		const MAX_FILE_SIZE = 2048;
		const files = element.target.files;

		if (!files || files.length === 0) return;
		const fileSizeKiloBytes = files[0].size / 1024;
		if (fileSizeKiloBytes > MAX_FILE_SIZE) {
			message.error('File size is greater than 2mb');
			return;
		}

		await sendMessageWithAttributes({ file: files[0] });

		element.target.value = '';
	}

	async function sendMessageWithAttributes({
		message,
		file,
	}: {
		message?: string;
		file: File;
	}) {
		const type = file.type.toLowerCase();
		if (
			!(
				type === 'image/png' ||
				type === 'image/jpg' ||
				type === 'image/jpeg' ||
				type === 'application/pdf' ||
				type === 'text/plain'
			)
		) {
			const err = `Unaccepted file format. Type: ${type}`;
			console.error(err);
			msg.error(err);
			return;
		}

		setLoadingState('sending_message');

		const formData = new FormData();

		formData.append('media', file, file.name);

		try {
			let chatMediaResponse: ChatMediaResponse;

			try {
				const result = await httpRequest.post<
					ApiResponseDefault<ChatMediaResponse>
				>('/twilio/storages', formData);

				chatMediaResponse = result.data.payload;
			} catch (err) {
				const error = getErrorMessage(err);
				console.error(error);
				msg.error(error);
				setLoadingState('ready');
				return;
			}

			const attributes = {
				name: file.name,
				type: file.type,
				uri: chatMediaResponse.result?.fileLinkCache || null,
			};

			const index = await props.conversation?.sendMessage(
				message || null,
				attributes,
			);

			if (!index) {
				const err = `Failed to send message. Index: ${index}`;
				console.error(err);
				msg.error(err);
			}
		} catch (err) {
			console.error(err);
			msg.error(err);
		} finally {
			setLoadingState('ready');
		}
	}

	function onDrop(
		acceptedFiles: any[],
		fileRejections: FileRejection[],
		event: DropEvent,
	) {
		sendMessageWithAttributes({ file: acceptedFiles[0] });
	}

	return (
		<div className="w-full h-full">
			<Dropzone onDrop={onDrop}>
				{({ getRootProps, getInputProps, isDragActive }) => (
					<div
						{...getRootProps()}
						onClick={() => {}}
						id="OpenChannel"
						className="relative w-full h-full"
					>
						{isDragActive && (
							<div className="w-full h-full bg-gray-10 z-130 absolute flex justify-center items-center flex-col">
								<Upload />
								<h3>Release to Upload</h3>
							</div>
						)}
						<div
							className="flex flex-col basis-full h-full"
							style={{
								filter: `blur(${isDragActive ? 4 : 0}px)`,
							}}
						>
							<input id="files" {...getInputProps()} />
							<CustomScrollDiv className="flex-1 relative flex overflow-auto bg-gray-10 rounded-3">
								<div className="w-full">
									{loadingState !== 'initializing' ? (
										<ConversationsMessages
											identity={user?.userId || ''}
											messages={messages}
											otherParticipant={props.otherParticipant}
										/>
									) : (
										<div className="py-6 w-fit mx-auto">
											<Spinner size={25} color={theme.colors.primary} />
										</div>
									)}
								</div>
							</CustomScrollDiv>
							<div className="mt-2 flex items-center gap-x-2">
								<div className="flex-1">
									<FormInput
										placeholder="Type a message"
										type={EFormType.MULTI_LINE}
										rows={1}
										autoGrowRows
										autoComplete="off"
										disabled={loadingState !== 'ready'}
										value={newMessage}
										onChange={setNewMessage}
									/>
								</div>
								<div className="w-fit h-fit overflow-hidden rounded-full">
									<Button
										type="SOLIDASH"
										onClick={() => {
											document.getElementById('file-picker')?.click();
										}}
									>
										<div className="flex">
											<Paperclip />
											<input
												id="file-picker"
												type="file"
												accept="image/png, image/jpeg, image/jpg, application/pdf, text/plain"
												onChange={sendFile}
												hidden
												multiple={false}
											/>
										</div>
									</Button>
								</div>
								<div className="w-fit h-fit overflow-hidden rounded-full">
									<Button type="SOLID" onClick={sendMessage}>
										<div className="flex">
											<Send />
										</div>
									</Button>
								</div>
							</div>
						</div>
					</div>
				)}
			</Dropzone>
		</div>
	);
}

function ConversationsMessages(props: {
	messages: Message[];
	identity: string;
	otherParticipant?: IUser;
}) {
	const { user } = useAuth();

	return (
		<div id="messages">
			<ul className="p-4 space-y-2">
				{props.messages.map((m, index) => {
					if (m.author === props.identity)
						return (
							<MessageBubble
								key={m.index}
								isShowProfile={
									index === 0 ||
									(index > 0 &&
										props.messages[index - 1].author !==
											props.messages[index].author)
								}
								direction="outgoing"
								profile={user}
								message={m}
							/>
						);
					else
						return (
							<MessageBubble
								key={m.index}
								isShowProfile={
									index === 0 ||
									(index > 0 &&
										props.messages[index - 1].author !==
											props.messages[index].author)
								}
								direction="incoming"
								profile={props.otherParticipant}
								message={m}
							/>
						);
				})}
			</ul>
		</div>
	);
}

function MessageBubble(props: {
	direction: 'incoming' | 'outgoing';
	isShowProfile: boolean;
	message: Message;
	profile?: IUser;
}) {
	const mounted = useRef<HTMLLIElement>(null);
	const mediaType = useMemo(() => {
		const type = ((props.message.attributes as any)?.type as string)?.split(
			'/',
		);
		if (type?.length === 2) {
			return { type: type?.[0], format: type?.[1] };
		}
	}, [props.message.attributes]);
	const [mediaUrl, setMediaUrl] = useState<string>();
	const [openModal, setOpenModal] = useState(false);

	useEffect(() => {
		(async () => {
			const uri = (props.message.attributes as any)?.uri;
			if (!uri) return;

			setMediaUrl(uri);
		})();
	}, []);

	useEffect(() => {
		if (mounted.current) {
			mounted.current.scrollIntoView({ behavior: 'smooth' });
		}
	});

	async function download() {
		if (!mediaUrl) return;

		const link = document.createElement('a');
		link.href = mediaUrl;
		document.body.appendChild(link);
		link.click();
		link.remove();
	}

	return (
		<li
			ref={mounted}
			className={
				'flex gap-x-3 max-w-full' +
				(props.direction === 'outgoing' ? ' flex-row-reverse' : '')
			}
		>
			<div className="w-9 h-9 shrink-0">
				{props.isShowProfile && (
					<div className="rounded-full flex items-center justify-center bg-charcoal-300 overflow-hidden">
						{props.profile?.profilePic ? (
							<img
								src={props.profile.profilePic}
								className="w-full h-full object-cover"
							/>
						) : (
							<p className="m-0 text-white">
								{getInitialName(props.profile).slice(0, 2)}
							</p>
						)}
					</div>
				)}
			</div>
			<div
				className={
					'flex-1 flex min-w-0 ' +
					(props.direction === 'incoming' ? 'justify-start' : 'justify-end')
				}
			>
				<div
					className={
						'rounded-b-4 w-fit min-w-0 max-w-sm p-3 break-words text-3.5 shadow ' +
						(props.direction === 'incoming'
							? 'rounded-tr-4 bg-white'
							: 'rounded-tl-4 bg-primary text-white')
					}
				>
					{mediaType ? (
						mediaType.type === 'image' ? (
							<div>
								<img
									src={mediaUrl}
									alt="media"
									onClick={() => setOpenModal(true)}
									className="rounded-2 w-full h-full object-cover cursor-pointer"
								/>
								<div className="mt-1.5 flex items-center gap-x-2">
									<div className="flex-1 min-w-0">
										<p className="m-0 text-3.5 ">
											{(props.message.attributes as any)?.name}
										</p>
										<p className="m-0 text-2.5">
											{mediaType.format?.toUpperCase()}
										</p>
									</div>
									<div className="p-1">
										<IconButton onClick={download}>
											<Download
												width={24}
												height={24}
												color={
													props.direction === 'incoming'
														? theme.colors.black
														: theme.colors.white
												}
											/>
										</IconButton>
									</div>
								</div>
								<Modal
									open={openModal}
									onCancel={() => setOpenModal(false)}
									footer={null}
									title="Detail Image"
									width={896}
								>
									<img src={mediaUrl} alt="media" className="w-full h-full" />
								</Modal>
							</div>
						) : mediaType.format === 'pdf' ? (
							<div className="flex items-center gap-x-2">
								<div className="p-2.5 rounded-full bg-ash-400 flex">
									<FileText width={24} height={24} color={theme.colors.black} />
								</div>
								<div className="flex-1 min-w-0">
									<p className="m-0 text-3.5">
										{(props.message.attributes as any)?.name}
									</p>
									<p className="m-0 text-2.5">
										{mediaType.format?.toUpperCase()}
									</p>
								</div>
								<div className="p-1">
									<IconButton onClick={download}>
										<Download
											width={24}
											height={24}
											color={
												props.direction === 'incoming'
													? theme.colors.black
													: theme.colors.white
											}
										/>
									</IconButton>
								</div>
							</div>
						) : undefined
					) : (
						<p className="m-0">{props.message.body}</p>
					)}
					<p className="m-0 mt-0.5 text-right text-2.5">
						{moment(props.message.dateUpdated).format('hh:mm')}
					</p>
				</div>
			</div>
		</li>
	);
}

const ComponentEnded = styled.div`
	position: absolute;
	bottom: 16px;
	left: 0px;
	width: 380px;
	border-radius: 16px;
	padding: 10px 0px;
	left: 50%;
	margin-left: -190px;
	text-align: center;
	background-color: white;
	color: ${(props) => props.theme.colors.primary};
`;
