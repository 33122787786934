import { Col, Image, Row, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { ArticleProps } from '../../../types/article.type';
import DEFAULT_IMAGE from '../../../assets/default-image.jpeg';
import RenderHTML from '../../RenderHTML';
import moment from 'moment';
import COLORS from '../../../assets/globalColors';
import useWindowSize from '../../../hooks/useWindowSize';

const ArticleItem = ({
	article,
	isDetail,
	isHome,
}: {
	article: ArticleProps;
	isDetail?: boolean;
	isHome?: boolean;
}) => {
	const { isMobile } = useWindowSize();
	const history = useHistory();
	const maxTagShow = 2;
	return (
		<>
			<Row
				gutter={[24, 24]}
				style={{
					borderBottom: `1px solid rgba(0, 0, 0, 0.08)`,
					paddingBottom: isDetail ? 12 : 24,
					gap: 12,
				}}
			>
				<Col
					style={{ paddingLeft: 8 }}
					className="cursor-pointer"
					onClick={() => history.push(`/articles/${article.articleId}`)}
				>
					<Image
						src={
							article.images
								? article.images.length > 0
									? article.images[0]
									: DEFAULT_IMAGE
								: DEFAULT_IMAGE
						}
						alt={article.title}
						style={{ borderRadius: 10, objectFit: 'cover' }}
						fallback={DEFAULT_IMAGE}
						preview={false}
						width={isDetail ? 112 : 240}
						height={isHome ? 200 : isDetail ? 112 : 240}
					/>
				</Col>
				<Col className="flex-1 flex flex-col justify-between">
					<div className="flex flex-col gap-3">
						{article?.topics
							.map((topic) => topic.topicName)
							.filter((tag, idx) => idx < maxTagShow)
							.map((tag, index) => (
								<Typography.Text
									key={index}
									className={[
										'text-primary font-bold',
										isDetail ? 'text-3' : 'text-4',
									].join(' ')}
									style={{ marginRight: 10 }}
								>
									#{tag.toUpperCase()}
								</Typography.Text>
							))}
						{article?.topics.map((topic) => topic.topicName)!.length! >
						maxTagShow ? (
							<Typography.Text
								className={[
									'text-primary font-bold',
									isDetail ? 'text-3' : 'text-4',
								].join(' ')}
								style={{ marginRight: 10 }}
							>
								+
								{article?.topics.map((topic) => topic.topicName)!.length! -
									maxTagShow}{' '}
								More
							</Typography.Text>
						) : null}
						<Typography.Text
							className={[
								'text-black font-semibold block cursor-pointer',
								isMobile
									? 'text-4.5 line-clamp-2'
									: isDetail
									? 'text-4.5 line-clamp-2'
									: 'text-6',
							].join(' ')}
							style={{ lineHeight: '120%' }}
							onClick={() => history.push(`/articles/${article.articleId}`)}
						>
							{article.title}
						</Typography.Text>
						{isDetail ? null : (
							<Typography.Text
								className={[
									'text-4.5 block',
									isMobile ? 'line-clamp-3' : 'line-clamp-4',
								].join(' ')}
								style={{ lineHeight: '24px', color: COLORS.gray_80 }}
							>
								<RenderHTML content={article.contentText} />
							</Typography.Text>
						)}
					</div>

					<Typography.Text
						className={[
							'text-black-70 font-medium block',
							isDetail ? 'text-3.5' : 'text-4',
						].join(' ')}
					>
						{moment(article.publishedAt).format('DD MMMM YYYY')}
					</Typography.Text>
				</Col>
			</Row>
		</>
	);
};

export default ArticleItem;
