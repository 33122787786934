import { Card, Divider, Row, Typography, Button, Col, Space } from 'antd';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import COLORS from '../../../assets/globalColors';

import PageHeader from '../../../components/PageHeader';

import AppLayout from '../../../layout/AppLayout';
import { iconChevronBlack as IconChevron } from '../../../assets/icons';

const activePath = '/app/medical-calculator';

const { Text } = Typography;

export default function DiabetesRisk() {
	const history = useHistory();

	const listDiabetesRisk = useMemo(
		() => [
			{
				title: 'Findrisc Calculator',
				desc: 'A prediction tool that can identify patients at risk of developing diabetes without need for laboratory testing.',
				path: '/app/medical-calculator/diabetes-risk/find-risc',
			},
			{
				title: 'HbA1C',
				desc: 'Is your average blood glucose (Sugar) levels for the last two to three months.',
				path: '/app/medical-calculator/diabetes-risk/hba1c',
			},
		],
		[],
	);

	const ComponentCardConsult = ({
		item,
	}: {
		item: {
			title: string;
			desc: string;
			path: string;
		};
	}) => {
		return (
			<CardConsult onClick={() => history.push(item.path)}>
				<Row justify="space-between" className="items-center">
					<Col span={20}>
						<Row>
							<Text
								style={{
									fontSize: 16,
									color: COLORS.black,
									fontWeight: 700,
								}}
							>
								{item.title}
							</Text>
						</Row>
						<Row>
							<Text
								style={{
									fontSize: 14,
									color: COLORS.primary_black_60,
								}}
							>
								{item.desc}
							</Text>
						</Row>
					</Col>
					<Col span={1} style={{ paddingTop: 10 }}>
						<IconChevron />
					</Col>
				</Row>
			</CardConsult>
		);
	};

	return (
		<AppLayout activePath={activePath}>
			<Card style={{ minHeight: 550 }}>
				<PageHeader title="Diabetes Risk" onClick={() => history.goBack()} />
				<Divider />
				<Space direction="vertical" size="middle">
					{listDiabetesRisk.map((item) => (
						<ComponentCardConsult item={item} key={item.title} />
					))}
				</Space>
			</Card>
		</AppLayout>
	);
}

const CardConsult = styled(Card)`
	border-radius: 12px;
	cursor: pointer;

	div.ant-card-body {
		padding: 12px 16px;
	}
`;
