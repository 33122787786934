import { Button, Col, Image, Row, Spin, Typography, message } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import COLORS from '../../../assets/globalColors';
import { httpRequest } from '../../../helpers/api';
import AppLayout from '../../../layout/AppLayout';
import { BaseResponseProps2 } from '../../../types/config.type';
import DEFAULT_IMAGE from '../../../assets/default-image.jpeg';
import RenderHTML from '../../../components/RenderHTML';
import useFetchList from '../../../hooks/useFetchList';
import { BannerProps } from '../../../types/banner.type';
import SidebarArticle from '../../../components/Webapp/Articles/SidebarArticle';
import BottomPromoAndDeals from '../../../components/Webapp/Articles/BottomPromoAndDeals';
import { EventProps } from '../../../types/event.type';
import { IconRewardCFD } from '../../../assets/icons';
import { CalendarOutlined } from '@ant-design/icons';
import moment from 'moment';
import { openInNewTab } from '../Articles';
import { EPointCategory } from '../../../types/point.type';
import { apiPostPointReward } from '../../../types/pointReward';
import { useAuth } from '../../../context/auth.context';
import { useGlobal } from '../../../context/global.context';

export default function DetailEvent() {
	const { isLoggedIn, userId, isDoctor } = useAuth();
	const { setIsShowModalChooseRole } = useGlobal();
	const { eventId } = useParams() as { eventId: string };
	const [event, setEvent] = useState<EventProps | null>(null);
	const [isLoading, setIsLoading] = useState(false);

	const { data: banners, isLoading: isLoadingBanner } =
		useFetchList<BannerProps>({
			endpoint: '/banners',

			initialQuery: {
				isPublished: true,
				target: isDoctor ? 'DOCTOR' : 'PATIENT',
			},
		});

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const [isLoadingOpenRegisterUrl, setIsLoadingOpenRegisterUrl] =
		useState(false);

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			try {
				const res = await httpRequest.get<BaseResponseProps2<EventProps>>(
					`/events/${eventId}?isPublished=true`,
				);

				if (res && res.data && res.data.payload) {
					setEvent(res.data.payload);
				}
				setIsLoading(false);
			} catch (error) {
				console.log(error);
			}
		})();
	}, [eventId]);

	console.log(event);

	const handleRegister = async () => {
		if (!isLoggedIn) {
			setIsShowModalChooseRole(true);
		} else {
			setIsLoadingOpenRegisterUrl(true);

			const eventParticipant = await httpRequest.get<any>(
				'/event-participants/',
				{
					params: {
						eventId: eventId,
					},
				},
			);

			console.log(
				'eventParticipant====>',
				eventParticipant.data.payload.results,
			);

			const isRegister = eventParticipant.data?.payload?.results?.filter(
				(item: any) => item.eventParticipantId !== userId,
			);

			console.log('isRegister====>', isRegister);

			if (isRegister?.length > 0) {
				if (event?.registerUrl) {
					openInNewTab(event.registerUrl);
				}
				setIsLoadingOpenRegisterUrl(false);
			} else {
				const res = await httpRequest.post('/events/register/' + eventId);
				console.log('resss===>', res);
				const isSuccess = await apiPostPointReward({
					category: EPointCategory.REGISTER_EVENT,
					relatedActionId: eventId,
					isRelatedActionIdUnique: true,
					metaRelatedAction: event,
				});
				if (res && isSuccess) {
					message.success('Sucess Register Event. Event add to your schedule');
				} else {
					message.error('Failed Add Event. Please try again');
					if (event?.registerUrl) {
						openInNewTab(event.registerUrl);
					}
				}
				setIsLoadingOpenRegisterUrl(false);
			}
		}
	};

	const renderTags = () => {
		const tags = event?.tags || [];
		return (
			<div className="flex flex-row">
				{tags.map((tag, idx) =>
					idx < 3 ? (
						<Typography.Text
							key={tag.tagId}
							style={{
								marginRight: 10,
								color: COLORS.primary,
							}}
						>
							#{tag.tagName.toUpperCase()}
						</Typography.Text>
					) : null,
				)}
				{tags.length > 3 ? (
					<Typography.Text
						style={{
							color: COLORS.primary,
						}}
					>
						+{tags.length - 3} More
					</Typography.Text>
				) : null}
			</div>
		);
	};

	const renderSpecialities = () => {
		const specialists = event?.specialists || [];
		return (
			<div className="flex flex-row">
				{specialists.map((specialist, idx) =>
					idx < 3 ? (
						<div
							key={specialist.specialistId}
							style={{
								marginRight: 10,
								background: '#FDDAE7',
							}}
							className="py-2 px-3 bg-primary rounded-full text-primary"
						>
							{specialist.specialistName}
						</div>
					) : null,
				)}
				{specialists.length > 3 ? (
					<div
						style={{
							marginRight: 10,
							background: '#FDDAE7',
						}}
						className="py-2 px-3 bg-primary rounded-full text-primary"
					>
						+{specialists.length - 3} More
					</div>
				) : null}
			</div>
		);
	};

	const start = new Date(event?.eventStartAt || '');
	const end = new Date(event?.eventEndAt || '');
	const now = new Date();

	const isPast = now > start;

	return (
		<AppLayout activePath={'/medical-event'}>
			<div className={'container mt-12'}>
				{isLoading && (
					<div className="text-center my-10">
						<Spin spinning />
					</div>
				)}

				{!isLoading && (
					<Row gutter={[24, 24]} style={{ marginBottom: 100 }}>
						<Col span={24} lg={16}>
							<Typography.Title
								level={4}
								style={{ color: COLORS.gray_100, marginBottom: 8 }}
								className="font-bold"
							>
								{event?.title}
							</Typography.Title>
							{event?.isCPDAvailable && (
								<div className="px-2 py-1 mb-6 bg-primary rounded-full w-max flex items-center gap-x-2">
									<IconRewardCFD />
									<span className="text-white pt-1">With CFD Point</span>
								</div>
							)}

							<Image
								src={
									event?.imageUrls
										? event.imageUrls.length > 0
											? event.imageUrls[0]
											: DEFAULT_IMAGE
										: DEFAULT_IMAGE
								}
								alt={event?.title}
								style={{ borderRadius: 10 }}
								fallback={DEFAULT_IMAGE}
								preview={false}
								width="100%"
								height={500}
							/>
							<div
								style={{ margin: '24px 0px 36px' }}
								className="flex items-center gap-x-3 justify-between bg-gray-10 py-3.5 px-3 rounded-2"
							>
								<div className="flex items-center gap-x-2">
									<CalendarOutlined />
									<Typography.Text style={{ color: '#121212' }}>
										Date and Time{' '}
										{moment(event?.eventStartAt).format(
											'MMM DD, YYYY, hh:mm a',
										)}{' '}
										-{' '}
										{moment(event?.eventEndAt).format('MMM DD, YYYY, hh:mm a')}
									</Typography.Text>
								</div>
								{isPast ? null : (
									<Button
										loading={isLoadingOpenRegisterUrl}
										onClick={handleRegister}
										type="primary"
										className="text-3.5 btn-text-bold"
									>
										Register
									</Button>
								)}
							</div>
							<Typography.Text
								className="text-4.5 block"
								style={{ marginTop: 30, color: '#201D1D' }}
							>
								<RenderHTML content={event?.description || ''} />
							</Typography.Text>
							<div style={{ marginBottom: 30 }}>
								<Typography.Text className="text-6 font-bold block mb-2 block">
									Specialities
								</Typography.Text>
								{renderSpecialities()}
							</div>
							<div>
								<Typography.Text className="text-6 font-bold block mb-2 block">
									Tags
								</Typography.Text>
								{renderTags()}
							</div>
						</Col>

						<Col span={24} lg={8}>
							<div className="top-27 sticky">
								<SidebarArticle />
							</div>
						</Col>
					</Row>
				)}
			</div>

			<BottomPromoAndDeals isLoading={isLoadingBanner} banners={banners} />
		</AppLayout>
	);
}
