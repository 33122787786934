import { ArrowLeft } from 'react-feather';
import IconButton from '../../../../Button/IconButton';

interface Props {
	onBack: Function;
	title: string;
}

export default function SidebarMenuHeader(props: Props) {
	return (
		<div className="relative pb-2 px-6 top-0 flex items-center bg-white">
			<IconButton onClick={props.onBack} className="absolute left-0">
				<ArrowLeft />
			</IconButton>
			<p className="m-0 text-center w-full font-semibold">{props.title}</p>
		</div>
	);
}
