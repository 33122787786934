import { Card } from 'antd';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import AppBmiCalculator from './assets/AppBmiCalculator.svg';
import AppMenstruationCalendar from './assets/AppMenstruationCalendar.svg';
import AppPregnancyDueDate from './assets/AppPregnancyDueDate.svg';

type Props = {
	exclude?: string;
};

const HealthSupportsData = [
	{
		img: AppBmiCalculator,
		title: 'BMI Calculator',
		url: 'bmi',
	},
	{
		img: AppMenstruationCalendar,
		title: 'Menstruation Calendar',
		url: 'menstruation',
	},
	{
		img: AppPregnancyDueDate,
		title: 'Pregnancy Due Date',
		url: 'pregnancy',
	},
];

export default function AppToolsList(Props: Props) {
	const history = useHistory();

	const HealthSupportItems = HealthSupportsData.map((data, index) => {
		if (data.url === Props.exclude) {
			return null;
		}
		return (
			<Card
				key={index}
				onClick={(e) => {
					history.push(`/app/medical-calculator/${data.url}`);
				}}
				bodyStyle={CalculatorItemBodyStyles}
				style={CalculatorItemStyles}
			>
				<HealtSupportIconContainer>
					<img style={{ width: '100%' }} src={data.img} alt="React Logo" />
				</HealtSupportIconContainer>
				{data.title}
			</Card>
		);
	});

	return <>{HealthSupportItems}</>;
}

const HealtSupportIconContainer = styled.div`
	margin: 0 auto;
	margin-bottom: 8px;
	width: 50px;
`;

const CalculatorItemStyles: React.CSSProperties = {
	width: 140,
	aspectRatio: '1/1',
	backgroundColor: '#F8FAFB',
	borderColor: '#F8FAFB',
	borderWidth: '2px',
	cursor: 'pointer',
	borderRadius: '12px',
	textAlign: 'center',
	// lineHeight: "normal",
};

const CalculatorItemBodyStyles: React.CSSProperties = {
	// border: "1px solid black",
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
};
