import React, { forwardRef, useState } from 'react';
import {
	Typography,
	Modal,
	Row,
	Col,
	Button,
	Input,
	Divider,
	message,
} from 'antd';
import { iconStar as IconStar } from '../../assets/icons';
import { UserBankAccountProperties } from '../../types/bankAccount.type';
import { httpRequest } from '../../helpers/api';
import styled from 'styled-components';
import { ApiResponseDefault } from '../../types/apiResponse.type';
import BankAccountChooseItem from './BankAccountChooseItem';
import useFetchList from '../../hooks/useFetchList';
import { useHistory } from 'react-router-dom';
import EmptyList from '../EmptyList';
import { Info } from 'react-feather';
import { useAuth } from '../../context/auth.context';

interface IProps {
	visible: boolean;
	setVisible: any;
	currentAmount: number;
	fetchListCurrentPoint: any;
	fetchListPointHistory: any;
}

const { Text, Title } = Typography;

const ModalRedeem = forwardRef<any, IProps>(
	(
		{
			visible,
			setVisible,
			currentAmount,
			fetchListCurrentPoint,
			fetchListPointHistory,
		},
		ref,
	) => {
		const { userId, user } = useAuth();
		const history = useHistory();

		const [isShowModalConfirmation, setIsShowModalConfirmation] =
			React.useState<boolean>(false);
		const [isShowModalPointNotEnough, setIsShowModalPointNotEnough] =
			React.useState<boolean>(false);
		const [isLoadingRedeemPoint, setIsLoadingRedeemPoint] =
			React.useState<boolean>(false);

		const [radioChoice, setRadioChoice] = useState<string>('');
		const [selectedRedeemPoints, setSelectedRedeemPoints] =
			React.useState<number>();
		const [selectedBankAccount, setSelectedBankAccount] =
			React.useState<UserBankAccountProperties>();

		const { data: bankAccounts, fetchList: bankAccountsFetchList } =
			useFetchList<UserBankAccountProperties>({
				endpoint: '/user-bank-accounts',
				limit: 100000,
				initialQuery: {
					userId: userId,
				},
			});

		const mainBankAccount = React.useMemo(() => {
			if (!bankAccounts || bankAccounts.length === 0) return;

			const mainAccount = bankAccounts.filter((bank) => bank?.isDefault)?.[0];
			return mainAccount ? mainAccount : null;
		}, [bankAccounts]);

		React.useEffect(() => {
			if (!mainBankAccount) return;

			setSelectedBankAccount(mainBankAccount);
		}, [mainBankAccount]);

		React.useEffect(() => {
			setRadioChoice('');
			setSelectedRedeemPoints(undefined);
			bankAccountsFetchList();
		}, [visible]);

		const amountItem = (item: string) => {
			return (
				<Row
					key={item}
					onClick={() => {
						if (item !== 'Custom') {
							const amount = Number(item?.replace(',', ''));
							setRadioChoice(item);
							setSelectedRedeemPoints(amount);
						} else {
							setRadioChoice(item);
						}
					}}
					style={{
						width: '32%',
						padding: '6px 12px',
						border: '1px solid #DCDCDC',
						borderRadius: 8,
						marginBottom: 12,
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<Col style={{ marginTop: -4, marginRight: 12 }}>
						{radioChoice === item ? <SelectedEllips /> : <UnselectedEllips />}
					</Col>
					<Col style={{ display: 'flex', flexDirection: 'column' }}>
						<Text>{item}</Text>
					</Col>
				</Row>
			);
		};

		function roundingPoint() {
			if (selectedRedeemPoints === undefined || currentAmount === undefined)
				return;

			setSelectedRedeemPoints((points) => {
				if (points !== undefined) {
					let number = points;
					if (number > currentAmount) {
						number = currentAmount;
					}
					return Math.floor(number / 1000) * 1000;
				} else {
					return undefined;
				}
			});
		}

		function onRedeemPressed() {
			if (selectedRedeemPoints && selectedRedeemPoints > currentAmount) {
				return setIsShowModalPointNotEnough(true);
			}
			roundingPoint();
			setIsShowModalConfirmation(true);
		}

		async function handleRedeemPoint() {
			if (!userId || !user || !selectedRedeemPoints || !selectedBankAccount)
				return;

			setIsLoadingRedeemPoint(true);
			try {
				const data = {
					userId: userId,
					metaUser: user,
					amount: selectedRedeemPoints,
					userBankAccountId: selectedBankAccount?.userBankAccountId,
					metaBankAccount: selectedBankAccount,
				};
				await httpRequest.post<ApiResponseDefault<any>>('/redeem-points', data);
				message.success('Success Redeem Points');
			} catch (error) {
				console.error('ERROR Redeem Point', error);
				message.error('Failed Redeem Points');
			} finally {
				fetchListCurrentPoint();
				fetchListPointHistory();
				setIsLoadingRedeemPoint(false);
				setIsShowModalConfirmation(false);
				setVisible(false);
			}
		}

		const amountList = [
			'1,000',
			'2,000',
			'3,000',
			'4,000',
			'5,000',
			'6,000',
			'7,000',
			'8,000',
			'Custom',
		];

		return (
			<Modal
				open={visible}
				footer={null}
				closable={false}
				style={{ marginTop: -50 }}
			>
				<Title style={{ marginBottom: 10 }} level={5}>
					Redeem
				</Title>
				<Row
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						marginBottom: 20,
						backgroundColor: '#F1F3F5',
						padding: '12px 0',
						borderRadius: 12,
					}}
				>
					<Text style={{ fontSize: 32, fontWeight: 600 }}>
						<IconStar
							width={32}
							height={32}
							style={{ marginBottom: -5, marginRight: 6 }}
						/>
						{currentAmount}
					</Text>
					<Text style={{ fontSize: 15, fontWeight: 600 }}>Available Point</Text>
					<Text style={{ fontSize: 12, fontWeight: 400, color: '#748494' }}>
						Minimun point redemption is a increments of 1000
					</Text>
				</Row>
				<Row style={{ marginBottom: 12 }}>
					<Text style={{ fontSize: 16, fontWeight: 600, marginBottom: 12 }}>
						Choose Amount{' '}
					</Text>
					<div
						style={{
							display: 'flex',
							flexWrap: 'wrap',
							justifyContent: 'space-between',
						}}
					>
						{amountList.map((item) => amountItem(item))}
					</div>
					{radioChoice === 'Custom' && (
						<Input
							size="middle"
							placeholder="Amount"
							onChange={(e) => {
								const amount = Number(e.target.value);
								setSelectedRedeemPoints(amount);
							}}
							style={{
								flex: 1,
								borderRadius: 8,
								marginBottom: 12,
								padding: '4px 14px',
							}}
						/>
					)}
				</Row>
				<Row style={{ display: 'flex', flexDirection: 'column' }}>
					<Text style={{ fontSize: 16, fontWeight: 600, marginBottom: 12 }}>
						Select Bank Account
					</Text>
					{bankAccounts.length === 0 ? (
						<EmptyList
							icon={<Info />}
							title="Bank Account Empty"
							description="You can add your bank account in Manage Bank Account."
						/>
					) : (
						<div style={{ height: 175, overflowY: 'scroll' }}>
							{bankAccounts.map((item) => (
								<BankAccountChooseItem
									item={item}
									selectedBankAccount={selectedBankAccount}
									setSelectedBankAccount={setSelectedBankAccount}
								/>
							))}
						</div>
					)}
					<Text
						onClick={() => history.push('/app/setting/bank-account')}
						style={{
							fontSize: 14,
							fontWeight: 600,
							color: '#D81F64',
							textAlign: 'center',
							cursor: 'pointer',
						}}
					>
						Manage Bank Account
					</Text>
				</Row>
				<Divider />
				<Row
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						marginTop: 20,
					}}
				>
					<Button
						loading={isLoadingRedeemPoint}
						onClick={() => setVisible(false)}
						size="large"
						style={{
							backgroundColor: '#F1F3F5',
							width: '48%',
							borderRadius: 12,
						}}
					>
						<Text style={{ fontSize: 17, fontWeight: 500 }}>Cancel</Text>
					</Button>
					<Button
						loading={isLoadingRedeemPoint}
						onClick={() => onRedeemPressed()}
						disabled={!selectedBankAccount || !selectedRedeemPoints}
						size="large"
						style={{ width: '48%', borderRadius: 12 }}
						type="primary"
					>
						<Text style={{ fontSize: 17, fontWeight: 500, color: '#fff' }}>
							Redeem
						</Text>
					</Button>
				</Row>
				<Modal
					centered
					closable={false}
					open={isShowModalConfirmation}
					onOk={() => handleRedeemPoint()}
					onCancel={() => {
						setIsShowModalConfirmation(false);
					}}
					footer={
						<>
							<Row
								gutter={16}
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between',
									alignItems: 'center',
									padding: '0 16px',
								}}
							>
								<Button
									loading={isLoadingRedeemPoint}
									onClick={() => setIsShowModalConfirmation(false)}
									ghost
									style={{
										width: '48%',
										color: '#1D2B36',
										backgroundColor: '#F1F3F5',
										fontSize: 14,
										fontWeight: 700,
									}}
								>
									Not Now
								</Button>
								<Button
									loading={isLoadingRedeemPoint}
									type="primary"
									onClick={() => handleRedeemPoint()}
									style={{
										width: '48%',
										fontSize: 14,
										fontWeight: 700,
									}}
								>
									Yes
								</Button>
							</Row>
						</>
					}
				>
					<Title level={5}>Confirmation</Title>
					<Text>
						Are you sure you want to Redeem {selectedRedeemPoints} Medeasy
						Point?
					</Text>
				</Modal>
				<Modal
					centered
					closable={false}
					open={isShowModalPointNotEnough}
					onOk={() => setIsShowModalPointNotEnough(false)}
					footer={
						<Row
							gutter={16}
							style={{ display: 'flex', justifyContent: 'center' }}
						>
							<Button
								loading={isLoadingRedeemPoint}
								type="primary"
								onClick={() => setIsShowModalPointNotEnough(false)}
								style={{
									width: 100,
									fontSize: 14,
									fontWeight: 700,
								}}
							>
								Done
							</Button>
						</Row>
					}
				>
					<Title level={5}>Information</Title>
					<Text>
						Your point is not enough, minimun point redemption is a increments
						of 1000
					</Text>
				</Modal>
			</Modal>
		);
	},
);

export default ModalRedeem;

const SelectedEllips = styled.div`
	width: 16px;
	height: 16px;
	border: 5px solid #d81f64;
	border-radius: 10px;
`;

const UnselectedEllips = styled.div`
	width: 16px;
	height: 16px;
	border: 3px solid #a5b2bd;
	border-radius: 10px;
`;
