import { message } from 'antd';
import { httpRequest } from '../helpers/api';
import { getErrorMessage } from '../helpers/errorHandler';
import { ApiResponseDefault } from '../types/apiResponse.type';
import { MedicalIDProperties } from '../types/medicalID.type';

export async function apiGetMedicalId(patientId?: string) {
	try {
		const res = await httpRequest.get<ApiResponseDefault<MedicalIDProperties>>(
			`medical-ids/${patientId}`,
		);

		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}
