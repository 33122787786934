import React, { useContext } from 'react';
import { DatesContext } from '../../../screens/app/Menstruation/Index';
import {
	Form,
	Button,
	Divider,
	Card,
	Typography,
	DatePicker,
	Select,
} from 'antd';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';
import './styles.less';

type Props = {
	nextBtn: () => void;
};

const { Item } = Form;
const { Title } = Typography;
const { Option } = Select;

const mensCalParamsParams = [
	{
		label: 'When did your last period start?',
		name: 'periodStart',
		message: 'last period start date',
		suffix: 'cm',
	},
	{
		label: 'How long was your last period?',
		name: 'periodLong',
		message: 'last period duration',
		suffix: 'kg',
	},
	{
		label: 'On the average, how long is your menstrual cycle?',
		name: 'periodCycle',
		message: 'average period duration',
		suffix: 'years old',
	},
];

const OptionsPeriodCycle = Array.from(
	{ length: 20 },
	(value, index) => index + 21,
).map((data, index) => {
	return (
		<Option key={index} value={data}>
			{data}
		</Option>
	);
});

const OptionsMensLong = Array.from(
	{ length: 12 },
	(value, index) => index + 3,
).map((data, index) => {
	return (
		<Option key={index} value={data}>
			{data}
		</Option>
	);
});

const dateToISOString = (date: Date) => {
	const myDate = new Date(new Date(date).setHours(12));
	return new Date(
		myDate.toISOString().replace('-', '/').split('T')[0].replace('-', '/'),
	);
};

const dateManipualtion = (date: Date, operator: number) => {
	const rawDate = moment(date, 'YYYY/MM/DD').add(operator, 'days').toDate();
	const Date = dateToISOString(rawDate);
	return Date;
};

const calculateMens = async (
	mensStartDate: Date,
	mensCycle: number,
	mensLong: number,
) => {
	const mensStartArr = [];
	const mensEndArr = [];
	const nextOvulationArr = [];
	const fertileWindowStartArr = [];
	const fertileWindowEndArr = [];

	for (let step = 1; step <= 3; step++) {
		const mensStart = dateManipualtion(mensStartDate, mensCycle * (step - 1));
		const mensEnd = dateManipualtion(
			mensStartDate,
			mensCycle * (step - 1) + (mensLong - 1),
		);
		const nextOvulation = dateManipualtion(
			mensStartDate,
			mensCycle * step - 14,
		);
		const fertileWindowStart = dateManipualtion(
			mensStartDate,
			mensCycle * step - 17,
		);
		const fertileWindowEnd = dateManipualtion(
			mensStartDate,
			mensCycle * step - 13,
		);
		mensStartArr.push(mensStart);
		mensEndArr.push(mensEnd);
		nextOvulationArr.push(nextOvulation);
		fertileWindowStartArr.push(fertileWindowStart);
		fertileWindowEndArr.push(fertileWindowEnd);
	}

	const markedDates = {
		mensCycle: mensCycle,
		mensLong: mensLong,
		currDates: new Array(moment().toDate()),
		mensStart: mensStartArr,
		mensEnd: mensEndArr,
		nextOvul: nextOvulationArr,
		fertileStart: fertileWindowStartArr,
		fertileEnd: fertileWindowEndArr,
	};

	return await markedDates;
};

export default function InputsCalculator(Props: Props) {
	const [form] = Form.useForm();

	const datesContext = useContext(DatesContext);
	const { setDates } = datesContext;

	const submitHandler = (values: any) => {
		const mensStartDate = dateToISOString(values.periodStart);
		const mensCycle = values.periodCycle;
		const mensLong = values.periodLong;
		calculateMens(mensStartDate, mensCycle, mensLong).then((res) => {
			setDates(res);
			return res;
		});
	};

	const isNotValid = () => {
		const values = Object.values(form.getFieldsValue());
		const notValidForm = values.some(
			(x) => x === null || x === '' || x === undefined,
		);
		return notValidForm;
	};

	const MensCalInputs = mensCalParamsParams.map((param: any, index) => {
		return (
			<Item
				key={index}
				label={
					<Title level={5} style={TextStyles}>
						{param.label}
					</Title>
				}
				name={param.name}
				rules={[
					{
						required: true,
						message: `Your ${param.message} input can not be empty!`,
					},
				]}
			>
				{param.name === 'periodStart' ? (
					<DatePicker
						size="large"
						format={'DD/MM/YYYY'}
						className="date-input"
						style={{ borderRadius: 12, width: '100%' }}
						disabledDate={(current) => {
							return current > moment().endOf('day');
						}}
					/>
				) : (
					<Select size="large" className="select-input">
						{param.name === 'periodCycle'
							? OptionsPeriodCycle
							: OptionsMensLong}
					</Select>
				)}
			</Item>
		);
	});

	return (
		<Card style={CardWrapperStyles}>
			<Form
				form={form}
				layout="vertical"
				onFinish={submitHandler}
				requiredMark={false}
			>
				{MensCalInputs}
				<SubmitBtnContainer>
					<Divider />
					<Item>
						<Button
							size="large"
							type="primary"
							htmlType="submit"
							style={SubmitBtnStyles}
							onClick={(e) => {
								if (!isNotValid()) {
									Props.nextBtn();
								}
							}}
						>
							Calculate
						</Button>
					</Item>
				</SubmitBtnContainer>
			</Form>
		</Card>
	);
}

const SubmitBtnContainer = styled.div`
	// border: 1px solid black;
	position: absolute;
	bottom: 0px;
	left: 0px;
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: end;
`;

const TextStyles: React.CSSProperties = {
	marginBottom: 0,
};

const CardWrapperStyles = {
	minHeight: '600px',
	borderBottomLeftRadius: '12px',
	borderBottomRightRadius: '12px',
};

const SubmitBtnStyles = {
	marginRight: 24,
	borderRadius: 12,
	width: 184,
};
