import { ApiResponseSuccess } from '../types/api.type';
import { httpRequest } from '../helpers/api';
import { ApiResponseDefault } from '../types/apiResponse.type';

type AssignUpline = {
	isSuccess: boolean;
};

export async function apiSubmitReferral(data: {
	userId: string;
	referralCode: string;
}) {
	try {
		const res = await httpRequest.post<ApiResponseSuccess<AssignUpline | {}>>(
			`/user-referrals/${data.userId}/assign-upline`,
			{ uplineReferralCode: data.referralCode },
		);

		return res?.data;
	} catch (error) {
		console.error('error ', error);
		return (error as any)?.data;
	}
}

export async function apiGetReferral(data: { userId: string }) {
	try {
		const res = await httpRequest.get<ApiResponseDefault<any>>(
			`/user-referrals/${data.userId}`,
		);

		return res?.data?.payload?.referralCode;
	} catch (error) {
		console.error('error ', error);
	}
}

export async function apiGetUplineReferralCode(data: { userId: string }) {
	try {
		const res = await httpRequest.get<ApiResponseDefault<any>>(
			`/user-referrals/${data.userId}`,
		);
		return res?.data?.payload?.upline?.referralCode;
	} catch (error) {
		console.error('error ', error);
	}
}
