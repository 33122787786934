import { Col, Divider, Row, Spin, Typography } from 'antd';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
	iconChevronBlack as IconChevronBlack,
	iconChevron as IconChevron,
} from '../../../assets/icons';

import './styles.less';
import 'swiper/css';
import COLORS from '../../../assets/globalColors';
import { useState } from 'react';

const { Text } = Typography;

type Props = {
	dates: {
		year: number;
		numberMonth: number;
		date: number;
		weekday: number;
		day: string;
		month: string;
	}[];
	setSelectedDate: React.Dispatch<React.SetStateAction<string>>;
	setSelectedTime: React.Dispatch<React.SetStateAction<string>>;
	handleHour: any;
	isLoadingTimes: boolean;
	times: {
		weekday: number;
		times: {
			times: string;
			timeGroup: string;
		}[];
	}[];
	unAvailableWeek: any;
};
export default function ComponentDateTime({
	dates,
	setSelectedDate,
	handleHour,
	isLoadingTimes,
	times,
	setSelectedTime,
	unAvailableWeek,
}: Props) {
	const [swiperInstance, setSwiperInstance] = useState<any>();
	const [swiperActiveIndex, setSwiperActiveIndex] = useState<number>(0);

	const SwiperButtonNext = ({ type }: { type: string }) => {
		return type === 'right' ? (
			<button className="btn-next" onClick={() => swiperInstance.slideNext()}>
				<IconChevronBlack />
			</button>
		) : (
			<button
				disabled={swiperActiveIndex === 0}
				className="btn-next left"
				onClick={() => swiperInstance.slidePrev()}
			>
				{swiperActiveIndex > 0 ? (
					<IconChevronBlack className="chevron-left" />
				) : (
					<IconChevron className="chevron-left" />
				)}
			</button>
		);
	};

	const handleUnvailableWeek = (date: string) => {
		return unAvailableWeek.includes(date);
	};

	return (
		<>
			<Row style={{ marginBottom: 8 }}>
				<Text style={{ fontSize: 16, fontWeight: 500 }}>
					Choose a time from your schedule
				</Text>
			</Row>

			<Row gutter={8} className="items-center">
				<Col span={1} style={{ marginRight: 8 }}>
					<SwiperButtonNext type="left" />
				</Col>

				<Col span={21}>
					<Swiper
						style={{ paddingTop: 8, paddingBottom: 8 }}
						spaceBetween={12}
						slidesPerView={7}
						onSwiper={(swiper) => setSwiperInstance(swiper)}
						onSlideChange={(swiper) => setSwiperActiveIndex(swiper.activeIndex)}
					>
						{dates.map((date, idx) => (
							<SwiperSlide>
								<label
									htmlFor={`date${idx}`}
									key={idx}
									className="carousel-item"
									onClick={() =>
										setSelectedDate(
											`${date.year}-${date.numberMonth}-${date.date}`,
										)
									}
								>
									<input
										style={{ display: 'none' }}
										type="radio"
										id={`date${idx}`}
										name="date"
										value={date.weekday}
										onChange={handleHour}
										disabled={handleUnvailableWeek(date.day)}
									/>
									<div
										className="carousel-body"
										style={{
											backgroundColor:
												handleUnvailableWeek(date.day) && COLORS.ash_300,
										}}
									>
										<p
											className="day"
											style={{
												color:
													idx === 0 ? COLORS.black_100 : COLORS.charcoal_300,
											}}
										>
											{date.day}
										</p>
										<p
											className="date"
											style={{
												color: idx === 0 ? COLORS.primary : COLORS.charcoal_300,
												fontWeight: idx === 0 ? 600 : 500,
											}}
										>
											{date.date}
										</p>
										<p
											className="month"
											style={{
												color:
													idx === 0 ? COLORS.black_100 : COLORS.charcoal_300,
											}}
										>
											{date.month}
										</p>
									</div>
								</label>
							</SwiperSlide>
						))}
					</Swiper>
				</Col>
				<Col span={1}>
					<SwiperButtonNext type="right" />
				</Col>
			</Row>

			<div
				className="flex items-center"
				style={{ marginTop: 24, flexWrap: 'wrap' }}
			>
				<Spin spinning={isLoadingTimes} />
				{times &&
					times.map((item: any) =>
						item.times.map((t: any, idx: number) => (
							<label
								htmlFor={t.times}
								className="time-wrapper"
								onClick={() => setSelectedTime(t.times)}
								key={idx}
							>
								<input
									style={{ display: 'none' }}
									type="radio"
									id={t.times}
									name="time"
									value={t.times}
								/>
								<div className="time">{t.times}</div>
							</label>
						)),
					)}
			</div>

			<Divider />
		</>
	);
}
