import { useEffect, useRef, useState } from 'react';
import { Search } from 'react-feather';
import { theme } from '../../../../../../assets/theme';
import useFetchList from '../../../../../../hooks/useFetchList';
import FormInput from '../../../../../Form/FormInput2';
import CustomScrollDiv from '../../../../../CustomScrollDiv';
import { Collapse, Spin } from 'antd';
import { ICDProperties } from '../../../../../../types/icd.type';
import { CaretRightOutlined } from '@ant-design/icons';
import { useDebounce } from 'use-debounce';
import EmptyList from './../../../../../EmptyList';

const { Panel } = Collapse;

export default function ModalContentAddICD(props: {
	icds?: ICDProperties[];
	setIcds: React.Dispatch<React.SetStateAction<ICDProperties[] | undefined>>;
}) {
	const footerElement = useRef<HTMLDivElement>(null);
	const [search, setSearch] = useState('');
	const [debouncedSearch] = useDebounce(search, 500);

	return (
		<div className="h-full flex flex-col">
			<div className="p-3">
				<FormInput
					prefix={
						<Search width={20} height={20} color={theme.colors.charcoal200} />
					}
					placeholder={'Search code or title disease'}
					value={search}
					onChange={setSearch}
				/>
			</div>
			<div className="mt-3 flex-1 min-h-0">
				<CustomScrollDiv className="h-full min-h-0">
					<RecursiveCollapse
						item={null}
						icds={props.icds}
						setIcds={props.setIcds}
						search={debouncedSearch}
					/>
				</CustomScrollDiv>
			</div>
			<div ref={footerElement} />
		</div>
	);
}

const RecursiveCollapse = (props: {
	item?: string | null;
	icds?: ICDProperties[];
	setIcds: React.Dispatch<React.SetStateAction<ICDProperties[] | undefined>>;
	search?: string;
}) => {
	const { data, isLoading, setSearch, search } = useFetchList<any>({
		endpoint: 'icd',
		initialQuery: {
			limit: 1000000,
			parentId: props.item,
			search: props.search,
		},
	});

	useEffect(() => {
		if (props.search && props.search.length > 2) {
			setSearch(props.search);
		} else if (!props.search) {
			setSearch('');
		}
	}, [props.search, setSearch]);

	return (
		<Collapse accordion ghost expandIcon={customExpandIcon}>
			{isLoading ? (
				<Spin
					style={{
						display: 'flex',
						flexDirection: 'row',
						textAlign: 'center',
						justifyContent: 'center',
					}}
				/>
			) : data.length === 0 ? (
				<EmptyList
					icon={
						<Search width={50} height={50} color={theme.colors.charcoal200} />
					}
					title="No Results Found"
					description="Try a different search term."
				/>
			) : (
				data.map((dataItem, index) => (
					<Panel
						header={
							<div
								onClick={() => {
									if (!props.icds?.find((item) => item.id === dataItem.id)) {
										const newItem = {
											id: dataItem.id,
											parentId: dataItem.parentId,
											value: dataItem.value,
										};
										const updatedIcds = props.icds
											? [...props.icds, newItem]
											: [newItem];
										props.setIcds(updatedIcds);
									}
								}}
							>{`${dataItem.id}. ${dataItem.value}`}</div>
						}
						key={index}
						collapsible={'icon'}
					>
						<RecursiveCollapse
							item={dataItem.id}
							icds={props.icds}
							setIcds={props.setIcds}
						/>
					</Panel>
				))
			)}
		</Collapse>
	);
};

const customExpandIcon = (panelProps: any) => {
	const { isActive } = panelProps;
	const hasChildren = panelProps?.children?.props?.item?.includes('.');

	if (hasChildren) {
		return <div></div>;
	}

	return (
		<CaretRightOutlined
			style={{
				transform: isActive ? 'rotate(90deg)' : 'rotate(0deg)',
				transition: 'transform 0.2s ease',
			}}
		/>
	);
};
