const COLORS = {
	// MAIN COLOR
	primary: '#D81F64',
	secondary: '#85F4FF',
	disabled: 'lightgrey',
	disabledDark: 'darkgrey',

	primaryText: '#000000',
	primary_black_10: '#DCDCDC',
	primary_black_20: '#D8D8D8',
	primary_black_30: '#D3D3D3',
	primary_black_40: '#C0C0C0',
	primary_black_50: '#AEAEAE',
	primary_black_60: '#929292',
	primary_black_70: '#777777',
	primary_black_icon: '#111827',

	white: '#ffffff',
	black: '#000000',
	secondaryBlack: '#777777',
	secondaryBlack2: '#93A1B0',

	// LAYOUT COLOR
	primaryBackground: '#FDDAE7',
	background: '#f7f7f7',
	backgroundDisabled: '#f2f2f2',
	backgroundDark: '#000000',
	backgroundHome: '#ECF0F3',

	// DESIGN COLOR
	charcoal_500: '#405261',
	charcoal_300: '#748494',
	charcoal_400: '#556575',
	// charcoal_200: '#93A1B0',
	charcoal_200: '#A5B2BD',
	ash_200: '#F9FAFA',
	ash_300: '#F1F3F5',
	ash_400: '#E5E9EC',
	ash_500: '#D5DCE1',
	ash_600: '#C5CED6',
	ash_700: '#B7C2CC',
	ash_800: '#A5B2BD',
	black_30: '#D3D3D3',
	black_50: '#AEAEAE',
	black_70: '#777777',
	black_100: '#121212',
	main: '#D81F64',
	blue: '#2B9CDC',
	blue_surface: '#DAEFFA',
	greenApp: '#03CEA4',
	green: '#268E6C',
	yellowApp: '#E5AB0E',
	orangeApp: '#F26B29',
	purpleApp: '#825DD1',
	yellow: '#DA7B11',
	red: '#D7373F',

	gray_10: '#F1F3F5',
	gray_20: '#D5DCE1',
	gray_30: '#C5CED6',
	gray_50: '#A5B2BD',
	gray_60: '#748494',
	gray_70: '#405261',
	gray_80: '#314351',
	gray_90: '#253642',
	gray_100: '#1D2B36',

	// ALERT
	success: '#00C897',
	warn: '#FFC300',
	error: '#D82148',

	iconActive: '#D81F64',
	iconInactive: '#93A1B0',
	modalTutorial: 'rgba(253, 218, 231, 0.5)',

	bottomSheet: 'rgba(52, 52, 52, 0.8)',
};

export default COLORS;
