import { Button, Typography, Card } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import styled from 'styled-components';

type Props = {
	backBtnHandler: (e: any) => void;
	title: string;
};

const { Title, Text } = Typography;

export default function Header(props: Props) {
	return (
		<Card style={CardWrapperStyles}>
			<BackBtnContainer>
				<Button
					className="flex items-center"
					onClick={(e) => props.backBtnHandler(e)}
				>
					<ArrowLeftOutlined style={{ color: 'black' }} />
					<Text style={BackBtnTxtStyles}>Back</Text>
				</Button>
			</BackBtnContainer>
			<Title level={5} style={TitleStyles}>
				{props.title}
			</Title>
		</Card>
	);
}

const BackBtnContainer = styled.div`
	// border: 1px solid black;
	position: absolute;
	left: 24px;
`;

const CardWrapperStyles = {
	// border: "1px solid black",
	borderRadius: '12px',
	borderBottomLeftRadius: '0px',
	borderBottomRightRadius: '0px',
	display: 'flex',
	width: '100%',
	alignItems: 'center',
	justifyContent: 'center',
};

const BackBtnTxtStyles = {
	// border: "1px solid black",
	marginLeft: 7.5,
	color: 'black',
	fontSize: 16,
	fontWeight: 600,
};

const TitleStyles = {
	// border: "1px solid black",
	display: 'inline-block',
	fontSize: 24,
	fontWeight: 600,
	marginBottom: 0,
	margin: '0 auto',
};
