import { useState } from 'react';
import { ArrowLeft } from 'react-feather';
import { useHistory } from 'react-router-dom';
import { DrugMedicineProperties } from '../../../../types/drugMedicine';
import Separator from '../../../Separator';
import DetailFormulary from './DetailFormulary';
import ListFormulary from './ListFormulary';

export default function MainFormulary() {
	const history = useHistory();

	const [title, setTitle] = useState('Formulary');
	const [step, setStep] = useState(0);

	const [formulary, setFormulary] = useState<DrugMedicineProperties>();

	function setNextStep() {
		const _step = step + 1;
		if (_step === 1) {
			setTitle('Medicine Detail');
			setStep(_step);
		}
	}

	function onBack() {
		const _step = step - 1;
		if (_step >= 0) {
			if (_step === 0) {
				setTitle('Formulary');
			}
			setStep(_step);
		} else {
			history.goBack();
		}
	}

	return (
		<div>
			<div className="relative px-6 py-5.5 flex items-center">
				<button
					onClick={onBack}
					className="border-none bg-transparent p-0 absolute flex items-center gap-x-1 cursor-pointer"
				>
					<ArrowLeft width={20} height={20} />
					<p className="m-0 font-bold text-3.5">Back</p>
				</button>
				<h1 className="font-semibold text-6 m-0 w-full text-center">{title}</h1>
			</div>
			<Separator />
			{step === 0 ? (
				<ListFormulary
					setSelectedMedicine={setFormulary}
					setNextStep={setNextStep}
				/>
			) : step === 1 ? (
				<DetailFormulary drug={formulary!} />
			) : undefined}
		</div>
	);
}
