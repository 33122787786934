import React, { useMemo, useState } from 'react';
import COLORS from '../../../../assets/globalColors';
import { Divider, Input, Spin, Typography } from 'antd';
import AppLayout from '../../../../layout/AppLayout';
import { useAuth } from '../../../../context/auth.context';
import PageHeader from '../../../../components/PageHeader';
import { useHistory } from 'react-router-dom';
import Card from '../../../../components/Card';
import { PersonalEvent } from '../../../../types/personalEvent.type';
import moment from 'moment';
import { Clock, Info } from 'react-feather';
import useFetchList from '../../../../hooks/useFetchList';
import { getScheduleType } from '../../../../constants';
import { SearchOutlined } from '@ant-design/icons';
import EmptyList from '../../../../components/EmptyList';

const { Text } = Typography;

export default function PersonalEventList() {
	const history = useHistory();

	const activePath = '/app/schedule/personal-event';

	const { user } = useAuth();

	const {
		data: events,
		isLoading,
		search,
		setSearch,
	} = useFetchList<PersonalEvent>({
		endpoint: `/personal-events`,
		initialQuery: {
			ownerId: [user?.userId],
		},
	});

	return (
		<AppLayout activePath={activePath}>
			<Card style={{ paddingBottom: 110, padding: '24px 16px' }}>
				<PageHeader title="Personal Event" onClick={() => history.goBack()} />
				<Divider />
				<div style={{ padding: '0 16px' }}>
					<Input
						size="large"
						placeholder="Search in personal event"
						style={{
							borderRadius: 12,
							height: 48,
							marginBottom: 24,
						}}
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						prefix={
							<SearchOutlined
								style={{
									color: 'gray',
									marginRight: 10,
								}}
							/>
						}
					/>
					{isLoading ? (
						<div className="text-center my-10">
							<Spin spinning />
						</div>
					) : events.length > 0 ? (
						<div>
							{events.map((item) => (
								<div key={item.eventId}>
									<PersonalEventItem
										data={item}
										onPress={() => {
											history.push('details', {
												type: getScheduleType(item),
												id: item.eventId,
												from: activePath,
											});
										}}
									/>
									<Divider style={{ margin: '16px 0' }} />
								</div>
							))}
						</div>
					) : (
						<EmptyList
							icon={<Info color={COLORS.black_50} />}
							title="Personal event is empty"
							description="You can add new personal event in schedule"
						/>
					)}
				</div>
			</Card>
		</AppLayout>
	);
}

type Props = {
	data: PersonalEvent;
	onPress: () => any;
};

function PersonalEventItem({ data, onPress }: Props) {
	const [isSameDay, setIsSameDay] = useState<boolean>(false);

	const start = useMemo(() => moment(data.eventStartAt), [data]);

	const end = useMemo(() => moment(data.eventEndAt), [data]);

	const parseDate = () => {
		let shownDuration = '';

		if (
			start.year() !== end.year() ||
			start.month() !== end.month() ||
			start.day() !== end.day()
		) {
			shownDuration =
				start.format('HH:mm, D MMM YYYY') +
				' - ' +
				end.format('HH:mm, D MMM YYYY');
		} else {
			if (isSameDay === false) {
				setIsSameDay(true);
			}
			shownDuration = start.format('D MMMM YYYY');
		}

		return shownDuration;
	};

	const blockedEventComp = () => (
		<div style={{ display: 'flex', flexDirection: 'row' }}>
			<Text
				style={{
					fontSize: 12,
					backgroundColor: COLORS.ash_300,
					fontWeight: 400,
					borderRadius: 12,
					padding: '4px 12px',
					marginBottom: 8,
				}}
			>
				Blocked Event
			</Text>
		</div>
	);

	return (
		<div style={{ display: 'flex', flexDirection: 'column' }} onClick={onPress}>
			{data.isBlocking ? blockedEventComp() : false}
			<Text style={{ color: COLORS.charcoal_200, fontSize: 14 }}>
				{parseDate()}
			</Text>
			<Text style={{ fontWeight: 600, fontSize: 16, margin: '8px 0' }}>
				{data.title}
			</Text>
			<Text>{data.description}</Text>
			{isSameDay ? (
				<div style={{ marginTop: 8, display: 'flex', alignItems: 'center' }}>
					<Clock width={15} />
					<Text style={{ marginLeft: 8, fontWeight: 400, fontSize: 14 }}>
						{start.format('HH.mm')}-{end.format('HH.mm')}
					</Text>
				</div>
			) : (
				false
			)}
		</div>
	);
}
