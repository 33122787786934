import { ChevronRight, Info, Search } from 'react-feather';
import { theme } from '../../../../assets/theme';
import useFetchList from '../../../../hooks/useFetchList';
import { DrugMedicineProperties } from '../../../../types/drugMedicine';
import { Button } from '../../../Button/Button/Button';
import EmptyList from '../../../EmptyList';
import FormInput from '../../../Form/FormInput2';
import Separator from '../../../Separator';
import Spinner from '../../../Spinner';

interface Props {
	setSelectedMedicine: React.Dispatch<
		React.SetStateAction<DrugMedicineProperties | undefined>
	>;
	setNextStep: () => void;
}

export default function ListFormulary(props: Props) {
	const {
		data: medicines,
		isLoading,
		pagination,
		search,
		setSearch,
		changePage,
	} = useFetchList<DrugMedicineProperties>({
		endpoint: 'drug-medicines',
		initialQuery: {
			isPublished: true,
			limit: 1000000,
			order: 'drugMedicineName:ASC',
		},
		pushData: true,
		primaryKey: 'drugMedicineId',
	});

	function handleNextPage() {
		if (!isLoading && pagination.next) {
			changePage(pagination.page + 1);
		}
	}

	function onClickItem(item: DrugMedicineProperties) {
		props.setSelectedMedicine(item);
		props.setNextStep();
	}

	return (
		<div className="flex-1 flex flex-col">
			<div className="px-3 py-1">
				<FormInput
					prefix={
						<Search width={20} height={20} color={theme.colors.charcoal200} />
					}
					placeholder="Search formulary"
					value={search}
					onChange={setSearch}
				/>
			</div>
			<div className="flex-1 min-h-0">
				<div className="px-3">
					{!isLoading ? (
						medicines.length > 0 ? (
							medicines.map((medicine) => (
								<div key={medicine.drugMedicineId}>
									<div className="py-4">
										<button
											onClick={() => onClickItem(medicine)}
											className="bg-transparent border-none text-left w-full cursor-pointer flex items-center gap-x-2"
										>
											<p className="m-0 flex-1 min-w-0">
												{medicine.drugMedicineName}
											</p>
											<ChevronRight
												width={20}
												height={20}
												color={theme.colors.black30}
											/>
										</button>
									</div>
									<Separator />
								</div>
							))
						) : (
							<EmptyList
								icon={<Info color={theme.colors.black50} />}
								title="Formulary is empty"
							/>
						)
					) : (
						<div className="py-4 mx-auto w-fit">
							<Spinner size={25} color={theme.colors.primary} />
						</div>
					)}
				</div>
			</div>
			{!!pagination.next && (
				<div className="p-3 w-80 mx-auto">
					<Button type="SOLIDASH" onClick={handleNextPage}>
						Load More
					</Button>
				</div>
			)}
		</div>
	);
}
