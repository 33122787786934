import React, { useState } from 'react';
import { Modal, Typography, Space, Col, Row } from 'antd';
import RoleButton from './RoleButton';
import styled from 'styled-components';

const { Text, Title } = Typography;

interface ChooseRoleModalProps {
	showChooseRole: boolean;
	onCancel: () => void;
	onConfirm: ({ role }: { role: 'Patient' | 'Doctor' }) => void;
}

function ChooseRoleModal({
	showChooseRole,
	onCancel,
	onConfirm,
}: ChooseRoleModalProps) {
	const [showComingSoon, setShowComingSoon] = useState(false);

	return (
		<CustomModal
			centered
			visible={showChooseRole}
			footer={null}
			onCancel={onCancel}
		>
			<CustomModalTitle level={2}>Choose your role</CustomModalTitle>
			<Text
				style={{ fontSize: '18px', lineHeight: '22px', textAlign: 'center' }}
			>
				Please choose your role to continue Medeasy registration.
			</Text>
			<Row justify="space-evenly">
				<Col span={12}>
					<RoleButton
						role="Patient"
						onPressPatient={() => setShowComingSoon(true)}
					/>
				</Col>
				<Col span={12}>
					<RoleButton
						role="Doctor"
						onPressDoctor={() => onConfirm({ role: 'Doctor' })}
					/>
				</Col>
			</Row>
			<Modal
				centered={true}
				open={showComingSoon}
				footer={null}
				onCancel={() => setShowComingSoon(false)}
			>
				<div className="center modal-padding">
					<Space direction="vertical">
						<img className="logo-large" src="/Logo.svg" alt="Medeasy Logo" />
						<Title level={3} className="no-margin">
							Coming Soon
						</Title>
						<Text>
							Download the Medeasy app on your smartphone to access complete
							features and receive rewards.
						</Text>
					</Space>
				</div>
			</Modal>
		</CustomModal>
	);
}

export default ChooseRoleModal;

const CustomModalTitle = styled(Title)`
	font-weight: 700 !important;
	color: black !important;
	margin-bottom: 0 !important;
`;

const CustomModal = styled(Modal)`
	.ant-modal-title {
		font-size: 36px;
		font-weight: 700;
		color: black;
	}
	.ant-modal-body {
		display: grid;
		gap: 20px;
		display: flex;
		flex-direction: column;
		justify-content: center !important;
		align-items: center !important;
		padding: 3.75rem 3rem 3rem 3rem;
		max-width: 700px;
	}
	.ant-typography {
		font-weight: 400;
		letter-spacing: 0.02em;
		line-height: 150%;
	}
	span.ant-typography {
		font-size: 12px;
		color: black;
		text-transform: capitalize;
	}
	span.ant-typography:nth-child(2) {
		font-size: 12px;
		color: #748494;
		font-weight: 400;
		font-size: 18px;
	}
	div.ant-typography {
		font-size: 14px;
		color: #1d2b36;
		margin-bottom: 0;
		/* font-weight: 600; */
	}
`;
