import {
	DrugMedicineDoseProperties,
	DrugMedicineProperties,
} from '../../../../../../types/drugMedicine';
import { ReactComponent as Logo } from '../../../../../../assets/icons/logo.svg';
import { ReactComponent as InformationCircle } from '../../../../../../assets/icons/information-circle.svg';
import { theme } from '../../../../../../assets/theme';
import Tab from '../../../../../Tab/Tab';
import { useEffect, useMemo, useState } from 'react';
import Separator from '../../../../../Separator';
import { Button } from '../../../../../Button/Button/Button';
import { EModalAddMedicineState } from './ModalAddMedicine';
import { CheckCircle, ChevronDown, Circle } from 'react-feather';
import IconButton from '../../../../../Button/IconButton';
import FormInput from '../../../../../Form/FormInput2';
import CustomScrollDiv from '../../../../../CustomScrollDiv';

const tabs = ['Dosage List', 'Formulary'];

export default function ModalContentSelectedMedicine(props: {
	medicine: DrugMedicineProperties;
	setState: (state: EModalAddMedicineState) => void;
	setSelectedMedicine: React.Dispatch<
		React.SetStateAction<DrugMedicineProperties | undefined>
	>;
}) {
	const [selectedTabIndex, setSelectedTabIndex] = useState(0);

	const [selectedDosage, setSelectedDosage] = useState<
		DrugMedicineDoseProperties | string | undefined
	>();
	const [customDosage, setCustomDosage] = useState<string>();

	useEffect(() => {
		if (!props.medicine) return;

		let dose = props.medicine.doses?.find(
			(dose) => dose.dose === props.medicine.dose,
		);
		if (!dose) {
			// if (props.medicine.dose) {
			//   setSelectedDosage("Custom Dosage");
			//   setCustomDosage(props.medicine.dose);
			// }
		} else {
			setSelectedDosage(dose);
		}
	}, [props.medicine]);

	function onBack() {
		if (selectedTabIndex > 0) {
			setSelectedTabIndex((old) => old - 1);
		} else {
			props.setState(EModalAddMedicineState.ADD_MEDICINE);
		}
	}

	function onNext() {
		props.setSelectedMedicine((old) => ({
			...old,
			dose:
				selectedDosage === 'Custom Dosage'
					? customDosage
					: (selectedDosage as DrugMedicineDoseProperties).dose,
		}));
		props.setState(EModalAddMedicineState.FORM_ADD_MEDICINE);
	}

	return (
		<div className="h-full flex flex-col">
			<div className="flex-1 min-h-0 py-4.5 px-3 flex flex-col">
				<div>
					<div className="flex items-center gap-x-2">
						<p className="m-0 font-medium text-5.5">
							{props.medicine.drugMedicineName}
						</p>
						{!!props.medicine.points && !!props.medicine.partner && (
							<Logo width={18} height={18} />
						)}
					</div>
					{/* {!!props.medicine.points && !!props.medicine.partner && (
						<div className="border border-solid border-ash-400 bg-ash-200 p-2 flex items-center gap-x-1 rounded-2">
							<InformationCircle
								width={14}
								height={14}
								color={theme.colors.primary}
							/>
							<p className="m-0 text-3.5">
								You will get Medeasy Points if you prescribe this medicine
							</p>
						</div>
					)} */}
				</div>
				<div className="mt-2">
					<Tab
						tabs={tabs}
						selectedTabIndex={selectedTabIndex}
						setSelectedTabIndex={setSelectedTabIndex}
						useBottomSeparator
					/>
				</div>
				<div className="mt-2 flex-1 min-h-0">
					<CustomScrollDiv className="h-full" style={{ overflowX: 'hidden' }}>
						{selectedTabIndex === 0 ? (
							<DosageList
								doses={props.medicine.doses}
								selectedDosage={selectedDosage}
								setSelectedDosage={setSelectedDosage}
								customDosage={customDosage}
								setCustomDosage={setCustomDosage}
							/>
						) : selectedTabIndex === 1 ? (
							<FormularyList medicine={props.medicine} />
						) : undefined}
					</CustomScrollDiv>
				</div>
			</div>
			<Separator />
			<div className="p-3 flex justify-between">
				<div className="flex-1 max-w-48">
					<Button type="SOLIDASH" onClick={onBack}>
						Back
					</Button>
				</div>
				<div className="flex-1 max-w-48">
					<Button
						type="SOLID"
						onClick={onNext}
						isDisable={
							!selectedDosage ||
							(selectedDosage === 'Custom Dosage' && !customDosage)
						}
					>
						Next
					</Button>
				</div>
			</div>
		</div>
	);
}

function DosageList(props: {
	doses?: DrugMedicineDoseProperties[];
	selectedDosage?: DrugMedicineDoseProperties | string;
	customDosage?: string;
	setSelectedDosage: (dosage: DrugMedicineDoseProperties | string) => void;
	setCustomDosage: (cdosage: string) => void;
}) {
	return (
		<div>
			<div>
				{props.doses?.map((dose) => (
					<ItemDosage
						key={dose.doseId}
						value={dose.dose}
						isSelected={
							(props.selectedDosage as DrugMedicineDoseProperties)?.doseId ===
							dose.doseId
						}
						onSelectDosage={() => props.setSelectedDosage(dose)}
					/>
				))}
			</div>
			<ItemDosage
				value={props.customDosage}
				isSelected={(props.selectedDosage as string) === 'Custom Dosage'}
				onSelectDosage={() => props.setSelectedDosage('Custom Dosage')}
				onChangeCustomDosage={props.setCustomDosage}
			/>
		</div>
	);
}

function ItemDosage(props: {
	isSelected: boolean;
	value?: string;
	onChangeCustomDosage?: (val: string) => void;
	onSelectDosage: () => void;
}) {
	return (
		<>
			<div
				onClick={props.onSelectDosage}
				style={{ cursor: 'pointer' }}
				className="flex gap-x-2 py-4"
			>
				<IconButton onClick={() => {}}>
					{props.isSelected ? (
						<CheckCircle width={20} height={20} color={theme.colors.primary} />
					) : (
						<Circle width={20} height={20} color={theme.colors.ash800} />
					)}
				</IconButton>
				<div className="w-full">
					<p className="m-0 text-4">
						{props.onChangeCustomDosage ? 'Custom Dosage' : props.value}
					</p>
					{props.onChangeCustomDosage && props.isSelected && (
						<FormInput
							placeholder="Input dosage"
							value={props.value}
							onChange={props.onChangeCustomDosage}
						/>
					)}
				</div>
			</div>
			<Separator />
		</>
	);
}

function FormularyList(props: { medicine: DrugMedicineProperties }) {
	const formularySections = useMemo(() => {
		const sections = [];
		if (props.medicine) {
			for (const [key, val] of Object.entries(props.medicine)) {
				let title = '';
				switch (key) {
					case 'administration':
						title = 'Administration';
						break;
					case 'adverseDrugReactions':
						title = 'Adverse Drug Reactions';
						break;
					case 'compositions':
						title = 'Compositions';
						break;
					case 'contraIndications':
						title = 'Contra Indications';
						break;
					case 'description':
						title = 'Description';
						break;
					case 'dose':
						title = 'Dose';
						break;
					case 'doseNote':
						title = 'Dose Note';
						break;
					case 'indications':
						title = 'Indications';
						break;
					case 'pregnancyCategory':
						title = 'Pregnancy Category';
						break;
				}
				if (title && typeof val === 'string' && val.length > 0) {
					sections.push({
						title,
						description: val,
					});
				}
			}
		}
		return sections;
	}, [props.medicine]);

	return (
		<div>
			{formularySections.map((section) => (
				<ItemFormulary
					key={section.title}
					title={section.title}
					description={section.description}
				/>
			))}
		</div>
	);
}

function ItemFormulary(props: { title: string; description: string }) {
	const [isExpanded, setIsExpanded] = useState(false);

	return (
		<div>
			<button
				onClick={() => setIsExpanded((oldState) => !oldState)}
				className="py-4 w-full block p-0 bg-transparent border-none flex items-center justify-between cursor-pointer"
			>
				<p className="m-0 font-semibold">{props.title}</p>
				<ChevronDown
					width={20}
					height={20}
					className={'transition-transform' + (isExpanded ? ' rotate-180' : '')}
				/>
			</button>
			{isExpanded && (
				<div>
					<p className="m-0 text-4">{props.description}</p>
				</div>
			)}
		</div>
	);
}
