import { LogOut } from 'react-feather';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { theme } from '../assets/theme';
import Card from '../components/Card';
import useWindowSize from '../hooks/useWindowSize';
import { ReactComponent as SidebarHomeIcon } from '../assets/icons/sidebar-icon-home.svg';
import { ReactComponent as SidebarCalendarIcon } from '../assets/icons/sidebar-icon-calendar.svg';
import { ReactComponent as SidebarChecklistIcon } from '../assets/icons/sidebar-icon-checklist.svg';
import { ReactComponent as SidebarRxIcon } from '../assets/icons/sidebar-icon-rx.svg';
import { ReactComponent as SidebarDocumentIcon } from '../assets/icons/sidebar-icon-document.svg';
import { ReactComponent as SidebarHistoryIcon } from '../assets/icons/sidebar-icon-history.svg';
import { ReactComponent as SidebarWalletIcon } from '../assets/icons/sidebar-icon-wallet.svg';
import { ReactComponent as SidebarContactIcon } from '../assets/icons/sidebar-icon-contact.svg';
import { ReactComponent as SidebarMedicalFolderIcon } from '../assets/icons/sidebar-icon-medical-folder.svg';
import { ReactComponent as SidebarSettingIcon } from '../assets/icons/sidebar-icon-setting.svg';
import Text from 'antd/lib/typography/Text';
import { Modal, Space, Typography } from 'antd';
import { useState } from 'react';
import { useAuth } from '../context/auth.context';

const sideBarItems = [
	{
		title: 'General',
		menus: [
			{
				icon: SidebarHomeIcon,
				title: 'Home',
				link: '/app',
			},
			{
				icon: SidebarCalendarIcon,
				title: 'Schedule',
				link: '/app/schedule',
			},
		],
	},
	{
		title: 'Consultation',
		menus: [
			{
				icon: SidebarChecklistIcon,
				title: 'Approval',
				link: '/app/approval',
			},
			{
				icon: SidebarRxIcon,
				title: 'Rx and Lab Request',
				link: '/app/rx-and-lab-request',
			},
			{
				icon: SidebarDocumentIcon,
				title: 'Doctor Documents',
				link: '/app/doctor-documents',
			},
			{
				icon: SidebarHistoryIcon,
				title: 'History',
				link: '/app/history',
			},
		],
	},
	{
		title: 'Balance',
		menus: [
			{
				icon: SidebarWalletIcon,
				title: 'Balance',
				link: '/app/balance',
			},
		],
	},
	{
		title: 'Health Solution',
		menus: [
			{
				icon: SidebarContactIcon,
				title: 'Doctor Directory',
				link: '/app/doctor-directory',
			},
			{
				icon: SidebarChecklistIcon,
				title: 'Medical Service',
				link: '',
			},
			{
				icon: SidebarMedicalFolderIcon,
				title: 'Medical Calculator',
				link: '/app/medical-calculator',
			},
		],
	},
	{
		title: 'Setting',
		menus: [
			{
				icon: SidebarSettingIcon,
				title: 'Setting',
				link: '/app/setting',
			},
		],
	},
];

interface Props {
	activePath?: string;
}

const { Title } = Typography;

export default function Sidebar(props: Props) {
	const history = useHistory();
	const { width, height } = useWindowSize();
	const { signout } = useAuth();
	const isMinimized = width < 1280;
	const [isDownloadApp, setIsDownloadApp] = useState(false);

	return (
		<Card className="px-3 py-6 h-fit sticky top-5">
			<SideBarContainer
				style={{
					width: isMinimized ? 50 : 300,
					height: height - 192,
					alignItems: isMinimized ? 'center' : 'flex-start',
				}}
			>
				<SectionMenuContainer>
					{sideBarItems.map((sideBarItem) => (
						<SectionContainer key={sideBarItem.title}>
							{!isMinimized && <SectionTitle>{sideBarItem.title}</SectionTitle>}
							<MenuContainer>
								{sideBarItem.menus.map((sideBarMenu, idx) => {
									const isActivePath = props.activePath === sideBarMenu.link;
									return sideBarMenu.title === 'Medical Service' ? (
										<MenuItem
											key={idx}
											onClick={() => setIsDownloadApp(true)}
											style={
												isActivePath
													? {
															backgroundColor: theme.colors.primary,
															color: theme.colors.white,
													  }
													: undefined
											}
										>
											<sideBarMenu.icon />
											{!isMinimized && (
												<MenuItemText>{sideBarMenu.title}</MenuItemText>
											)}
										</MenuItem>
									) : (
										<Link key={sideBarMenu.link} to={sideBarMenu.link}>
											<MenuItem
												style={
													isActivePath
														? {
																backgroundColor: theme.colors.primary,
																color: theme.colors.white,
														  }
														: undefined
												}
											>
												<sideBarMenu.icon />
												{!isMinimized && (
													<MenuItemText>{sideBarMenu.title}</MenuItemText>
												)}
											</MenuItem>
										</Link>
									);
								})}
							</MenuContainer>
						</SectionContainer>
					))}
				</SectionMenuContainer>
				<LogOutButton
					onClick={async () => {
						await signout();
						history.replace('/');
					}}
				>
					<LogOut />
					{!isMinimized && <LogOutText>Log Out</LogOutText>}
				</LogOutButton>

				<div
					style={{
						marginTop: 20,
						justifyContent: 'center',
						textAlign: 'center',
						width: '100%',
					}}
				>
					<Text style={{ fontSize: 11 }}>
						v{process.env.REACT_APP_VERSION_NAME}
					</Text>
				</div>
				<Modal
					centered={true}
					open={isDownloadApp}
					footer={null}
					onCancel={() => setIsDownloadApp(false)}
				>
					<div className="center modal-padding">
						<Space direction="vertical">
							<img className="logo-large" src="/Logo.svg" alt="Medeasy Logo" />
							<Title level={4} className="no-margin">
								Download the Medeasy mobile app
							</Title>
							<Text>
								Download the Medeasy app on your smartphone to access complete
								features and receive rewards.
							</Text>
						</Space>
						<Space
							style={{ marginTop: 12 }}
							className="footer-download"
							direction="horizontal"
							size={24}
							wrap={true}
						>
							<Link
								to="#coming-soon-modal"
								onClick={() =>
									window.open(
										'https://play.google.com/store/apps/details?id=com.medeasyapp&hl=en&gl=US',
										'_blank',
										'noreferrer',
									)
								}
							>
								<img
									src="/images/google-play-download-icon.png"
									alt="Get it on Google Play"
								/>
							</Link>
							<Link
								to="#coming-soon-modal"
								onClick={() =>
									window.open(
										'https://apps.apple.com/id/app/medeasy-ph/id6443653585?l=id',
										'_blank',
										'noreferrer',
									)
								}
							>
								<img
									src="/images/app-store-download-icon.png"
									alt="Get it on App Store"
								/>
							</Link>
						</Space>
					</div>
				</Modal>
			</SideBarContainer>
		</Card>
	);
}

const SideBarContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	overflow: auto;
	&::-webkit-scrollbar {
		width: 4px;
	}
	&::-webkit-scrollbar-thumb {
		width: 4px;
		background: ${({ theme }) => theme.colors.gray20};
		border-radius: 32px;
	}
	&::-webkit-scrollbar-thumb:hover {
		background: ${({ theme }) => theme.colors.gray30};
	}
	&::-webkit-scrollbar-thumb:active {
		background: ${({ theme }) => theme.colors.gray60};
	}
`;

const SectionMenuContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	row-gap: 24px;
`;

const SectionContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0px;
	align-self: stretch;
`;

const SectionTitle = styled.p`
	margin: 0;
	color: ${({ theme }) => theme.colors.gray60};
	font-size: 16px;
`;

const MenuContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-self: stretch;
`;

const MenuItem = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 12px;
	gap: 6px;
	border-radius: 6px;
	color: ${({ theme }) => theme.colors.gray70};
	&:hover {
		background-color: ${({ theme }) => theme.colors.primary10};
		color: ${({ theme }) => theme.colors.primary};
	}
`;

const MenuItemText = styled.p`
	margin: 0;
	font-size: 18px;
`;

const LogOutButton = styled.button`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px 12px;
	gap: 5px;
	border-radius: 10px;
	color: none;
	border: none;
	background: none;
	cursor: pointer;
	color: ${({ theme }) => theme.colors.red};
	&:hover {
		background-color: ${({ theme }) => theme.colors.red};
		color: ${({ theme }) => theme.colors.white};
	}
`;

const LogOutText = styled.p`
	margin: 0;
	font-size: 14px;
	font-weight: 600;
`;
