import { generateQueryString } from '../helpers/generateQueryString';
import { AppConfigProperties } from '../types/appConfig.type';
import { httpRequest } from '../helpers/api';
import { ApiPaginationResults, ApiResponseSuccess } from '../types/api.type';

export async function apiGetAppConfig(key: string) {
	try {
		const res: ApiResponseSuccess<AppConfigProperties> = await httpRequest.get(
			'/app-configs/' + key,
		);
		return res?.data?.payload;
	} catch (err) {
		console.error('ERROT GET APPCONFIGS OF ', key, ': ', (err as any)?.data);
	}
}

export async function apiGetAppConfigs(keys: string[]) {
	try {
		const res: ApiResponseSuccess<ApiPaginationResults<AppConfigProperties>> =
			await httpRequest.get('/app-configs/' + generateQueryString(keys));

		return res?.data?.payload.results;
	} catch (err) {
		console.error('ERROT GET APPCONFIGS OF ', keys, ': ', (err as any)?.data);
	}
}
