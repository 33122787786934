import {
	AddSecretaryProperties,
	ApiDoctorSecretaryQuery,
	SecretaryProperties,
} from '../types/doctorSecretaries.type';
import { ApiPaginationResults, ApiResponseSuccess } from '../types/api.type';
import { httpRequest } from '../helpers/api';
import { generateQueryString } from '../helpers/generateQueryString';

export async function apiGetSecretary(
	doctorId: string,
	query?: ApiDoctorSecretaryQuery,
) {
	try {
		const res = await httpRequest.get<
			ApiResponseSuccess<ApiPaginationResults<SecretaryProperties>>
		>('/doctor-secretaries/doctor/' + doctorId + generateQueryString(query));

		return res?.data;
	} catch (err) {
		console.error('ERROR', (err as any)?.data?.message);
		return (err as any)?.data;
	}
}

export async function apiGetInvitationCode(doctorId: string) {
	try {
		const res = await httpRequest.get<ApiResponseSuccess<any>>(
			`/doctor-secretaries/doctor/${doctorId}/invitation-codes`,
		);
		return res?.data;
	} catch (err) {
		console.error('ERROR', (err as any)?.data?.message);
		return (err as any)?.data;
	}
}

export async function apiAddPartner(
	doctorId: string,
	secretaryId: string,
	data: AddSecretaryProperties,
) {
	try {
		const res = await httpRequest.post<ApiResponseSuccess<any>>(
			`/doctor-secretaries/doctor/${doctorId}/${secretaryId}/add-partner`,
			data,
		);
		return res?.data;
	} catch (err) {
		console.error('ERROR', (err as any)?.data?.message);
		return (err as any)?.data;
	}
}

export async function apiDeleteDoctorAndSecretary(
	doctorId: string,
	secretaryId: string,
) {
	try {
		const res = await httpRequest.delete<ApiResponseSuccess<any>>(
			`/doctor-secretaries/doctor/${doctorId}/${secretaryId}`,
		);
		return res?.data;
	} catch (err) {
		console.error('ERROR', (err as any)?.data?.message);
		return (err as any)?.data;
	}
}

export async function apiDeletePartner(
	doctorId: string,
	secretaryId: string,
	partnerId: string,
) {
	try {
		const res = await httpRequest.delete<ApiResponseSuccess<any>>(
			`/doctor-secretaries/doctor/${doctorId}/${secretaryId}/${partnerId}`,
		);
		return res?.data;
	} catch (err) {
		console.error('ERROR', (err as any)?.data?.message);
		return (err as any)?.data;
	}
}

export async function apiApproveSecretary(data: {
	doctorId: string;
	secretaryId: string;
}) {
	try {
		const res = await httpRequest.post<
			ApiResponseSuccess<{ isSuccess: boolean }>
		>(
			`/doctor-secretaries/doctor/${data.doctorId}/${data.secretaryId}/approve`,
		);
		return res?.data;
	} catch (err) {
		console.error('ERROR', (err as any)?.data?.message);
		return (err as any)?.data;
	}
}

export async function apiRejectSecretary(data: {
	doctorId: string;
	secretaryId: string;
}) {
	try {
		const res = await httpRequest.post<
			ApiResponseSuccess<{ isSuccess: boolean }>
		>(`/doctor-secretaries/doctor/${data.doctorId}/${data.secretaryId}/reject`);
		return res?.data;
	} catch (err) {
		console.error('ERROR', (err as any)?.data?.message);
		return (err as any)?.data;
	}
}
