import CustomScrollDiv from '../../../../CustomScrollDiv';
import SidebarMenuHeader from '../Components/SidebarMenuHeader';
import { ReactComponent as Logo } from '../../../../../assets/icons/logo.svg';
import Separator from '../../../../Separator';
import { Button } from '../../../../Button/Button/Button';
import { TemplateRxPrescriptionProperties } from '../../../../../types/templateRxPrescription.type';
import getMedicineName from '../../../../../helpers/getMedicineName';
import { EPrescriptionState } from './SidebarPrescription';

interface Props {
	templateRxPrescription: TemplateRxPrescriptionProperties;
	onNext: ({ state, dto }: { state: EPrescriptionState; dto?: any }) => void;
	onBack: () => void;
}

export default function SidebarPrescriptionTemplateDetail(props: Props) {
	function handleUseThisPrescription() {
		props.onNext({
			state: EPrescriptionState.LIST_MEDICINES,
			dto: {
				prescriptionItems:
					props.templateRxPrescription.templatePrescriptionItems,
			},
		});
	}

	return (
		<div className="w-full flex flex-col h-full">
			<SidebarMenuHeader onBack={props.onBack} title="Prescription for Flu" />
			<CustomScrollDiv className="flex overflow-auto w-full flex-1 min-h-0">
				<div className="w-full">
					<p className="m-0 mt-4 mb-2 text-black-60">Medicines</p>
					<div>
						{props.templateRxPrescription.templatePrescriptionItems.map(
							(template) => (
								<ItemTemplate
									key={template.drugMedicineId}
									title={
										template.drugMedicineDetail
											? getMedicineName(template.drugMedicineDetail)
											: ''
									}
									subtitle1={`qty. ${template.quantity}`}
									subtitle2={template.sig}
									point={template.drugMedicineDetail?.points}
									showBottomSeparator
								/>
							),
						)}
					</div>
				</div>
			</CustomScrollDiv>
			<div className="py-2 px-4">
				<Button onClick={handleUseThisPrescription} type="SOLID">
					Use This Prescription
				</Button>
			</div>
		</div>
	);
}

function ItemTemplate(props: {
	title: string;
	subtitle1: string;
	subtitle2: string;
	point?: number;
	showBottomSeparator?: boolean;
}) {
	return (
		<>
			<div className="py-3">
				<div className="flex-1 flex items-center gap-x-2">
					<p className="m-0 text-4.5 line-clamp-1">{props.title}</p>
					{props.point ? (
						<Logo width={18} height={18} className="shrink-0" />
					) : undefined}
				</div>
				<p className="m-0 text-black-70 text-3.5">{props.subtitle1}</p>
				<p className="m-0 text-black-70 text-3.5">{props.subtitle2}</p>
			</div>
			{props.showBottomSeparator && <Separator />}
		</>
	);
}
