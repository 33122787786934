import styled from 'styled-components';

interface Props {
	className?: string;
	children?: JSX.Element | JSX.Element[];
	style?: Object;
}

export default function Card(props: Props) {
	return (
		<CardContainer className={props.className} style={props.style}>
			{props.children}
		</CardContainer>
	);
}

const CardContainer = styled.div`
	background: ${({ theme }) => theme.colors.white};
	border: 1px solid #f1f3f5;
	border-radius: 12px;
`;
