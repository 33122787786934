import React from 'react';
import RootNavigator from './navigation/RootNavigator';
import './assets/app.less';
import { theme } from './assets/theme';
import { ThemeProvider } from 'styled-components';
import { AuthProvider } from './context/auth.context';
import { GlobalProvider } from './context/global.context';
import { GoogleOAuthProvider } from '@react-oauth/google';

function App() {
	const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';
	return (
		<ThemeProvider theme={theme}>
			<AuthProvider>
				<GlobalProvider>
					<GoogleOAuthProvider clientId={CLIENT_ID}>
						<RootNavigator />
					</GoogleOAuthProvider>
				</GlobalProvider>
			</AuthProvider>
		</ThemeProvider>
	);
}

export default App;
