import React from 'react';
import { Row, Col, Space, Typography } from 'antd';
import styled from 'styled-components';

interface MessageOTPProps {
	previewTimer?: any;
	alertOTP: boolean;
	timeLeft: number;
	setCounter: () => void;
	resetTime: () => void;
	resendOTP: () => void;
}

const { Text } = Typography;

export default function MessageOTP({
	previewTimer,
	alertOTP,
	timeLeft,
	setCounter,
	resetTime,
	resendOTP,
}: MessageOTPProps) {
	if (alertOTP) {
		return (
			<CustomRow>
				<CustomText>
					If you didn't receive OTP Verification Code, check your phone number
				</CustomText>
			</CustomRow>
		);
	}
	if (timeLeft > 0) {
		return (
			<CustomRow>
				<CustomText>
					Please wait{' '}
					<Text
						style={{
							color: '#D81F64',
							textAlign: 'center',
							marginBottom: 10,
							fontSize: 14,
						}}
						strong
					>
						{previewTimer}
					</Text>{' '}
					seconds before resend code
				</CustomText>
			</CustomRow>
		);
	}
	return (
		<Space align="center" direction="vertical">
			<Text
				style={{
					marginRight: 4,
					color: 'black',
					textAlign: 'center',
					fontSize: '14px',
				}}
			>
				Didn’t receive verification code?
			</Text>
			<div
				onClick={() => {
					resendOTP();
					setCounter();
					resetTime();
				}}
				style={{ cursor: 'pointer' }}
			>
				<Text style={{ color: '#D81F64' }} strong underline>
					Resend Code
				</Text>
			</div>
		</Space>
	);
}

const CustomRow = styled(Row)`
	margin: 10px 0 20px 0;
`;

const CustomText = styled(Text)`
	color: black;
	text-align: center;
	margin-bottom: 10px;
	font-size: 14px;
`;

const CustomTextBold = styled(Text)`
	color: '#D81F64';
	text-align: center;
	margin-bottom: 10px;
	font-size: 14px;
`;
