import { Modal, Typography } from 'antd';
import { iconPlus as IconPlus } from '../../../assets/icons';
import styled from 'styled-components';
import { ReactComponent as SidebarRxIcon } from '../../../assets/icons/sidebar-icon-rx.svg';
import { ReactComponent as SidebarDocumentIcon } from '../../../assets/icons/sidebar-icon-document.svg';
import { ReactComponent as SidebarLabRequest } from '../../../assets/icons/sidebar-icon-lab-request.svg';
import { Link } from 'react-router-dom';
import { ChevronRight } from 'react-feather';

const { Text, Paragraph } = Typography;

type Props = {
	isVisible: boolean;
	setVisibility: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function ModalNewDocument(props: Props) {
	function cancelRemove() {
		props.setVisibility(false);
	}

	const sections = [
		{
			icon: SidebarRxIcon,
			title: 'Prescription',
			nav: '/app/rx-and-lab-request/create-rx',
		},
		{
			icon: SidebarLabRequest,
			title: 'Lab Request',
			nav: '/app/rx-and-lab-request/create-lab-request',
		},

		{
			icon: SidebarDocumentIcon,
			title: 'Doctor Documents',
			nav: '/app/doctor-documents',
		},
	];

	return (
		<Modal
			className="modal"
			open={props.isVisible}
			onCancel={cancelRemove}
			footer={null}
		>
			<div className="flex flex-col items-center">
				<IconContainer>
					<IconPlus width={26} height={24} />
				</IconContainer>
				<div className="center" style={{ marginTop: 24, marginBottom: 24 }}>
					<Text style={{ marginBottom: 10 }} className="text-5 font-semibold">
						Create New Document
					</Text>
					<Paragraph style={{ marginBottom: 0 }}>
						Select the type of document you want to create
					</Paragraph>
				</div>
			</div>
			<SectionMenuContainer>
				{sections.map((item) => {
					return (
						<Link key={item.nav} to={item.nav}>
							<MenuItem>
								<item.icon />
								<MenuItemText>{item.title}</MenuItemText>
								<ChevronRight />
							</MenuItem>
						</Link>
					);
				})}
			</SectionMenuContainer>
		</Modal>
	);
}

const SectionMenuContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	row-gap: 17px;
`;

const MenuItem = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	border-width: 0.5px;
	border-style: solid;
	padding: 12px;
	gap: 6px;
	border-radius: 12px;
	color: ${({ theme }) => theme.colors.gray70};
	&:hover {
		background-color: ${({ theme }) => theme.colors.primary10};
		color: ${({ theme }) => theme.colors.primary};
	}
`;

const MenuItemText = styled.p`
	margin: 0;
	font-size: 18px;
	flex: 1;
`;

const IconContainer = styled.div`
	height: 64px;
	width: 64px;
	background-color: #fef5f9;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
`;
