import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Carousel } from 'antd';
import ModalBmiCalculator from './ModalBmiCalculator';
import CalculatorHeader from '../Header';
import CalculatorInputs from './InputsCalculator';
import CalculatorResult from './ResultCalculator';

export default function IndexBmi() {
	const [bmiResult, setBmiResult] = useState<{
		value: number;
		weight: number;
		idealWeight: number;
		height: number;
		age: number;
		gender: string;
	}>({
		value: 0,
		weight: 0,
		height: 0,
		idealWeight: 0,
		age: 0,
		gender: '',
	});

	const history = useHistory();

	const [currSlide, setCurrSlide] = useState<number>(0);

	const bannerRef: any = useRef();

	const backBtnHandler = (e: any) => {
		bannerRef.current.prev();
	};

	const nextBtnHandler = (e: any) => {
		bannerRef.current.next();
	};

	const changeHandler = (currentSlide: number) => {
		setCurrSlide(currentSlide);
	};

	const switchWhereToBack = (e: any) => {
		currSlide === 0
			? history.push('/App/medical-calculator')
			: backBtnHandler(e);
	};

	const calculateBmi = (result: any) => {
		setBmiResult(result);
	};

	return (
		<>
			<ModalBmiCalculator />
			<div style={{ minHeight: '100vh' }}>
				<CalculatorHeader
					title="BMI Calculator"
					backBtnHandler={(e) => switchWhereToBack(e)}
				/>
				<Carousel
					dots={false}
					style={{ height: '100%' }}
					afterChange={changeHandler}
					ref={bannerRef}
				>
					<CalculatorInputs
						calculateBmi={calculateBmi}
						nextBtnHandler={(e) => nextBtnHandler(e)}
					/>
					<CalculatorResult data={bmiResult} />
				</Carousel>
			</div>
		</>
	);
}
