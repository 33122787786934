import { Card, Col, Divider, Pagination, Row, Spin, Typography } from 'antd';
import styled from 'styled-components';
import COLORS from '../../../assets/globalColors';
import useFetchList from '../../../hooks/useFetchList';
import AppLayout from '../../../layout/AppLayout';
import { BannerProps } from '../../../types/banner.type';
import PageHeader from '../../../components/PageHeader';
import { useHistory } from 'react-router-dom';
import ScrollUp from '../../../components/ScrollUp';
import { DEFAULT_IMAGE } from '../../../helpers/constants';
import { handleClickPromoDealItem } from '../../../components/Webapp/Articles/PromoDealItem';
import { useAuth } from '../../../context/auth.context';

const activePath = '/promo-deals';

const { Meta } = Card;

export default function PromoDeals() {
	const { isLoggedIn, isDoctor } = useAuth();
	const history = useHistory();
	const {
		data: banners,
		isLoading: isLoadingBanner,
		pagination,
		changePage,
	} = useFetchList<BannerProps>({
		endpoint: '/banners',
		limit: 9,
		initialQuery: {
			isPublished: true,
			target: isDoctor ? 'DOCTOR' : 'PATIENT',
		},
	});

	return (
		<AppLayout activePath={isLoggedIn ? '/app/promo-deals' : '/promo-deals'}>
			<div className={isLoggedIn ? 'wrapper-login p-6' : 'container mt-12'}>
				{isLoggedIn ? (
					<>
						<PageHeader
							title="More Promo and Deals"
							onClick={() => history.goBack()}
						/>
						<Divider style={{ marginTop: 0 }} />
					</>
				) : (
					<Row className={isLoggedIn ? '' : 'mt-10'}>
						{' '}
						<Typography.Title
							level={4}
							style={{ color: COLORS.gray_100 }}
							className="font-semibold"
						>
							Promo and Deals
						</Typography.Title>
					</Row>
				)}
				{isLoadingBanner && <Spin spinning />}
				<Row gutter={[16, 16]}>
					{!isLoadingBanner &&
						banners.length > 0 &&
						banners.map((banner) => (
							<Col span={24} md={12} lg={8}>
								<CustomCard
									hoverable
									cover={
										<img
											alt={banner.title}
											src={banner.bannerImageUrl || DEFAULT_IMAGE}
										/>
									}
									onClick={() =>
										handleClickPromoDealItem(banner.bannerType, banner, history)
									}
								>
									<Meta title={banner.title} />
								</CustomCard>
							</Col>
						))}
				</Row>
				<Row
					style={{
						marginTop: isLoggedIn ? 40 : 80,
						marginBottom: isLoggedIn ? 0 : 80,
					}}
					className="justify-center"
				>
					<CustomPagination
						current={pagination.page}
						pageSize={pagination.perPage}
						onChange={changePage}
						total={pagination.totalData}
					/>
				</Row>
			</div>
			<ScrollUp />
		</AppLayout>
	);
}

const CustomPagination = styled(Pagination)`
	.ant-pagination-item,
	.ant-pagination-next,
	.ant-pagination-prev {
		width: 48px !important;
		height: 48px !important;
		line-height: 48px;
	}

	.ant-pagination-item {
		border-color: ${COLORS.primary};
		a {
			color: ${COLORS.primary};
		}
	}

	.ant-pagination-item-ellipsis {
		color: ${COLORS.primary} !important;
	}
	.ant-pagination-options {
		display: none;
	}

	.ant-pagination-item-active {
		background-color: ${COLORS.primary};
		a {
			color: #fff;
		}
	}
`;

const CustomCard = styled(Card)`
	width: 100%;
	border-radius: 24px !important;

	.ant-card {
		border-radius: 24px !important;
	}
	.ant-card-cover {
		overflow: hidden;
		img {
			overflow: hidden;
			height: 200px;
			border-top-right-radius: 24px;
			border-top-left-radius: 24px;
		}
	}

	.ant-card-body {
		padding: 16px 8px;
	}

	.ant-card-meta-title {
		font-size: 24px;
		font-weight: 500;
		color: #201d1d;
	}

	.ant-card-meta-description {
		font-size: 14px;
	}
`;
