import { useEffect, useState } from 'react';
import { ArrowLeft } from 'react-feather';
import { useHistory } from 'react-router-dom';
import { TemplateRxPrescriptionProperties } from '../../../../types/templateRxPrescription.type';
import { Button } from '../../../Button/Button/Button';
import Separator from '../../../Separator';
import FormCreateRxTemplate from './FormCreateRxTemplate';
import ListTemplate from './ListRxTemplate';

export default function MainRxTemplate() {
	const history = useHistory();

	const [step, setStep] = useState(0);
	const [title, setTitle] = useState('Rx Template');
	const [isEditing, setIsEditing] = useState(false);

	const [template, setTemplate] =
		useState<Partial<TemplateRxPrescriptionProperties>>();

	useEffect(() => {
		if (!isEditing) return;

		let _title = title;
		if (_title.includes('Create')) {
			_title = _title.replace('Create', 'Edit');
		}
		setTitle(_title);
	}, [isEditing, title]);

	function nextStep() {
		const _step = step + 1;
		if (_step === 1) {
			setTitle(`${isEditing ? 'Edit' : 'Create'} Rx Template`);
			setStep(_step);
		}
	}

	function onBack() {
		const _step = step - 1;
		if (_step >= 0) {
			if (_step === 0) {
				setIsEditing(false);
				setTitle('Rx Template');
			}
			setStep(_step);
		} else {
			history.goBack();
		}
	}

	function createPrescriptionTemplate() {
		setTemplate(undefined);
		nextStep();
	}

	return (
		<div className="flex-1 flex flex-col">
			<div className="relative px-6 py-5.5 flex items-center">
				<button
					onClick={onBack}
					className="border-none bg-transparent p-0 absolute flex items-center gap-x-1 cursor-pointer"
				>
					<ArrowLeft width={20} height={20} />
					<p className="m-0 font-bold text-3.5">Back</p>
				</button>
				<h1 className="font-semibold text-6 m-0 w-full text-center">{title}</h1>
				{step === 0 && (
					<div className="w-24 absolute right-6">
						<Button type="SOLID" onClick={createPrescriptionTemplate}>
							Create
						</Button>
					</div>
				)}
			</div>
			<Separator />
			{step === 0 ? (
				<ListTemplate
					setTemplate={(t) => {
						setIsEditing(true);
						setTemplate(t);
					}}
					setNextStep={nextStep}
					setIsEditing={() => setIsEditing(true)}
				/>
			) : step === 1 || step === 2 ? (
				<FormCreateRxTemplate
					step={step}
					template={template}
					setTemplate={setTemplate}
					setNextStep={nextStep}
					onBack={onBack}
				/>
			) : undefined}
		</div>
	);
}
