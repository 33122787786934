import { useState, useEffect } from 'react';

export function useOTPCountdown(countdown: number, count: number) {
	const [timeLeft, setTimeLeft] = useState<number>(countdown);
	const [alertOTP, setAlertOTP] = useState<boolean>(false);

	useEffect(() => {
		if (!timeLeft) return;

		const intervalId = setInterval(() => {
			setTimeLeft(timeLeft - 1);
		}, 1000);

		return () => clearInterval(intervalId);
	}, [timeLeft]);

	const resetTime = () => {
		if (count > 4) {
			setAlertOTP(true);
		} else {
			setTimeLeft(countdown * count);
		}
	};

	const previewTimer = (sec_num: number) => {
		var minutes = Math.floor(sec_num / 60) % 60;
		var seconds = sec_num % 60;

		if (minutes === 0) {
			return seconds < 10 ? `0${seconds}` : seconds;
		}

		return [minutes, seconds]
			.map((v) => (v < 10 ? '0' + v : v))
			.filter((v, i) => v !== '00' || i > 0)
			.join(':');
	};

	return {
		timeLeft,
		previewTimer,
		resetTime,
		alertOTP,
	};
}
