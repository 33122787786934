import { message } from 'antd';
import { httpRequest } from '../helpers/api';
import { getErrorMessage } from '../helpers/errorHandler';
import { ApiResponseDefault } from '../types/apiResponse.type';
import { TemplateRxPrescriptionProperties } from '../types/templateRxPrescription.type';

export async function apiGetTemplateRxPrescription(
	templateRxPrescriptionId: string,
) {
	try {
		const res = await httpRequest.get<
			ApiResponseDefault<TemplateRxPrescriptionProperties>
		>('/template-rx-prescriptions/' + templateRxPrescriptionId);
		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}

export async function apiCreateTemplateRxPrescription(
	data: TemplateRxPrescriptionProperties,
) {
	try {
		const res = await httpRequest.post<
			ApiResponseDefault<TemplateRxPrescriptionProperties>
		>('template-rx-prescriptions', data);
		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}

export async function apiUpdateTemplateRxPrescription(
	templateRxPrescriptionId: string,
	data: Partial<TemplateRxPrescriptionProperties>,
) {
	try {
		const res = await httpRequest.patch<
			ApiResponseDefault<TemplateRxPrescriptionProperties>
		>('/template-rx-prescriptions/' + templateRxPrescriptionId, data);
		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}

export async function apiDeleteTemplateRxPrescription(
	templateRxPrescriptionId: string,
) {
	try {
		const res = await httpRequest.delete<
			ApiResponseDefault<TemplateRxPrescriptionProperties>
		>('/template-rx-prescriptions/' + templateRxPrescriptionId);
		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}
