import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import './styles.less';
import { useHistory, useLocation } from 'react-router-dom';
import AppLayout from '../../../layout/AppLayout';
import {
	Card,
	Row,
	Typography,
	Col,
	Divider,
	Button,
	Tag,
	Image,
	Dropdown,
	message,
} from 'antd';
import { ArrowRight } from 'react-feather';
import {
	iconMedServiceColorize as IconMedServiceColorize,
	iconPersonalEventColorize as IconPersonalEventColorize,
	iconPersonalEventBlockedColorize as IconPersonalEventBlockedColorize,
	iconTelemedicineColorize as IconTelemedicineColorize,
	iconAppointmentColorize as IconAppointmentColorize,
	iconClockOutline as IconClockOutline,
	iconReceiptOutline as IconReceiptOutline,
	iconCancelOutline as IconCancelOutline,
	iconChevronBlack as IconChevron,
	iconChatOutline as IconChatOutline,
	iconMedicalNotesOutline as IconMedicalNotesOutline,
	iconPrescriptionOutline as IconPrescriptionOutline,
	iconLabRequestOutline as IconLabRequestOutline,
	iconClearanceOutline as IconClearanceOutline,
	iconFitToWorkOutline as IconFitToWorkOutline,
	iconMedicalCertificateOutline as IconMedicalCertificateOutline,
	iconDoctorDocumentOutline as IconDoctorDocumentOutline,
	iconDeleteOutline as IconDeleteOutline,
	iconEditOutline as IconEditOutline,
	iconCalendar as IconCalendar,
	iconWarningCircleColorize as IconWarningCircleColorize,
} from '../../../assets/icons';
import PageHeader from '../../../components/PageHeader';
import {
	CheckCircleOutlined,
	CloseCircleOutlined,
	ClockCircleOutlined,
	HomeOutlined,
	MoreOutlined,
	TagOutlined,
	WarningOutlined,
} from '@ant-design/icons';
import { EScheduleTypes } from '../../../types/schedule.type';
import {
	EConsultationType,
	ETransactionStatusType,
	TransactionConsultationProperties,
} from '../../../types/transactionConsultation.type';
import { PersonalEvent } from '../../../types/personalEvent.type';
import {
	EAvailableServiceTypes,
	TransactionHealthServiceProps,
} from '../../../types/transactionHealthService.type';
import { PersonalConsultationProperties } from '../../../types/personalConsultation.type';
import {
	DoctorDocumentProperties,
	EMetaType,
} from '../../../types/doctorDocument.type';
// import useAuth from "../../../hooks/useAuth";
import useSchedulesNew from '../../../hooks/useSchedulesNew';
import useConsultationDoctorDocument from '../../../hooks/useConsultationDoctorDocument';
import useBookConsultation from '../../../hooks/useBookConsultation';
import { dataTypeEvent, getScheduleType } from '../../../constants';
import { apiGetTransactionConsultation } from '../../../api/transactionConsultation';
import { apiGetListRxPrescriptionByConsultation } from '../../../api/rxPrescription';
import { apiGetListLabRequestByConsultation } from '../../../api/labRequest';
import { apiGetPersonalConsultation } from '../../../api/personalConsultation';
import { apiGetPersonalEvent } from '../../../api/personalEvent';
import { apiGetTransactionHealthService } from '../../../api/transactionHealthService';
import { EPaymentStatus } from '../../../types/payment.type';
import { getName } from '../../../helpers/name';
import { getBirthdate, getGender } from '../../../helpers/profile';
import { calculateAge } from '../../../helpers/datetime';
import ItemGroupAgenda from '../../../components/CalendarAgenda/ItemGroup';
import { httpRequest } from '../../../helpers/api';
import { getErrorMessage } from '../../../helpers/errorHandler';
import { ApiResponseDefault } from '../../../types/apiResponse.type';
import COLORS from '../../../assets/globalColors';
import ModalApproveReschedule from '../../../components/Webapp/Reschedule/ModalApproveReschedule';
import ModalRejectReschedule from '../../../components/Webapp/Reschedule/ModalRejectReschedule';
import ModalAcceptConsultation from '../../../components/Webapp/DetailSchedule/ModalAcceptConsultation';
import ModalRejectConsultation from '../../../components/Webapp/DetailSchedule/ModalRejectConsultation';
import { EPartnerType } from '../../../types/partner.type';
import { useAuth } from '../../../context/auth.context';
import { AvailabilityConsultationType } from '../../../types/doctor.type';
// import useAuth from "../../../hooks/useAuth";

const formatDate = 'YYYY-MM-DD';

type IAttachment = {
	title: string; // Clearance, Fit to Work, Rx-Prescription, ...
	price: number;
	onPress?: () => any;
	onPay?: () => any;
	validUntil?: string;
};

const { Text, Paragraph, Title } = Typography;

export default function DetailScheduleScreen() {
	const history = useHistory();
	const location = useLocation();

	const { userId, isSecretary, secretaryManagedDoctors, isDoctor, isPatient } =
		useAuth();
	const [isModalApproveRescheduleVisible, setIsModalApproveRescheduleVisible] =
		useState(false);
	const [isModalRejectRescheduleVisible, setIsModalRejectRescheduleVisible] =
		useState(false);
	const [isModalAcceptConsulVisible, setIsModalAcceptConsulVisible] =
		useState(false);
	const [isModalRejectConsulVisible, setIsModalRejectConsulVisible] =
		useState(false);

	const [fetchTrue, setFetchTrue] = useState(true);
	const initialData = location.state as unknown as {
		type: EScheduleTypes;
		id: string;
		from: string;
	};
	console.log(initialData, 'initialData');
	const paths = ['/app/schedule', '/app/history', '/app/approval'];
	const activePath =
		paths.find((p) => p === initialData.from) || '/app/schedule';

	const scheduleType = initialData?.type;
	const scheduleId = initialData?.id;

	const {
		doctorDocumentClearance,
		doctorDocumentFitToWork,
		doctorDocumentMedicalCertificate,
		fetchListDoctorDocumentByConsultation,
	} = useConsultationDoctorDocument({
		transactionConsultationId: scheduleId,
		lazy: true,
	});

	const disabledDates = ['2022-09-06', '2022-09-07'];
	const { schedules: semiProcessedSchedules, fetchList } = useSchedulesNew({
		userId: userId || '',
	});

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [detail, setDetail] = useState<
		| TransactionConsultationProperties
		| PersonalEvent
		| TransactionHealthServiceProps
		| PersonalConsultationProperties
	>();
	const [myRole, setMyRole] = useState<
		'doctor' | 'secretary' | 'patient' | undefined
	>();
	const [readMore, setReadMore] = useState<boolean>(false);
	const [attachments, setAttachments] = useState<IAttachment[]>([]);
	const [selectedAttachment, setSelectedAttachment] = useState<IAttachment>();

	const { setDoctorDocInfo, setDoctorDocPrice } = useBookConsultation();

	// Fetch API
	const fetchDetail = async () => {
		setIsLoading(true);
		try {
			if (
				scheduleType?.valueOf() === EScheduleTypes.telemedicine ||
				scheduleType?.valueOf() === EScheduleTypes['consult-now'] ||
				scheduleType?.valueOf() === EScheduleTypes.appointment
			) {
				const res = await apiGetTransactionConsultation(scheduleId);

				if (res) {
					setDetail(res);

					// if (res.payment) {
					// 	setDetailTransactionPayment(res.payment);
					// }

					if (res?.doctorId === userId) {
						setMyRole('doctor');
					} else if (res?.patientId === userId) {
						setMyRole('patient');
					} else if (
						isSecretary &&
						secretaryManagedDoctors.find(
							(item) => item.doctorId === res.doctorId,
						)
					) {
						setMyRole('secretary');
					}

					if (res.transactionStatus === ETransactionStatusType.COMPLETED) {
						fetchDocumentResult();
					}
				}
			} else if (
				scheduleType?.valueOf() === EScheduleTypes['telemedicine-personal'] ||
				scheduleType?.valueOf() === EScheduleTypes['appointment-personal']
			) {
				const res = await apiGetPersonalConsultation(scheduleId);

				if (res) {
					console.log(
						'res from apiGetPersonalConsultation',
						JSON.stringify(res),
					);
					setDetail(res);

					if (res?.doctorId === userId) {
						setMyRole('doctor');
					} else if (res?.patientId === userId) {
						setMyRole('patient');
					} else if (
						isSecretary &&
						secretaryManagedDoctors.find(
							(item) => item.doctorId === res.doctorId,
						)
					) {
						setMyRole('secretary');
					}
				}
			} else if (
				scheduleType?.valueOf() === EScheduleTypes['personal-event'] ||
				scheduleType?.valueOf() === EScheduleTypes['personal-event-blocked']
			) {
				const res = await apiGetPersonalEvent(scheduleId);
				if (res) {
					console.log('res from apiGetPersonalEvent', JSON.stringify(res));
					setDetail(res);

					if (
						isSecretary &&
						secretaryManagedDoctors.find(
							(item) => item.doctorId === res?.ownerId,
						)
					) {
						setMyRole('secretary');
					} else {
						setMyRole(undefined);
					}
				}
			} else if (
				scheduleType?.valueOf() === EScheduleTypes['medical-service']
			) {
				const res = await apiGetTransactionHealthService(scheduleId);
				if (res) {
					console.log(
						'res from apiGetTransactionHealthService',
						JSON.stringify(res),
					);
					setDetail(res);
					// if (res.payment) {
					// 	setDetailTransactionPayment(res.payment);
					// }

					if (res?.patientId === userId) {
						setMyRole('patient');
					} else if (isSecretary) {
						setMyRole('secretary');
					}
				}
				setMyRole('patient');
			}
		} catch (error) {
			console.error('ERROR: fetchDetail failed ::: ', error);
		} finally {
			setIsLoading(false);
		}
	};

	const fetchDocumentResult = async () => {
		// rx-prescription

		// lab request

		// doctor document
		// fetch ke /doctor-documents
		const fetchDDResult = (await fetchListDoctorDocumentByConsultation(
			scheduleId,
		)) as DoctorDocumentProperties[];

		const [fetchDocDocRes, resRXPrescriptions, resLabRequests] =
			await Promise.all([
				fetchDDResult,
				apiGetListRxPrescriptionByConsultation(scheduleId),
				apiGetListLabRequestByConsultation(scheduleId),
			]);

		// []
		// setAttachments([])
		const attchs: IAttachment[] = [];

		// Set Attacchment for all document
		for (const doctorDoc of fetchDocDocRes) {
			if (doctorDoc && doctorDoc.price) {
				let price: number = doctorDoc.price;
				if (price !== 0) {
					price =
						doctorDoc.payment?.paymentStatus === EPaymentStatus.PAID_FULL
							? 0
							: price;
				}

				const item = {
					title: parseEMetaType(doctorDoc.type).name,
					price,
					validUntil: moment(doctorDoc.validUntil).format('MMM DD, YYYY'),
					onPay: () => {
						setDoctorDocInfo(doctorDoc);
						// resetPayment();
						setDoctorDocPrice(price);
					},
				};

				const index = attchs.findIndex((att) => att.title === item.title);

				if (index >= 0) {
					attchs.splice(index, 1);
				}

				attchs.push({ ...item });
			}
		}

		for (const rxPrescription of resRXPrescriptions?.results || []) {
			const item = {
				title: 'Rx Prescription',
				price: 0,
			};

			attchs.push({ ...item });
		}

		for (const labRequest of resLabRequests?.results || []) {
			const item = {
				title: 'Lab Request',
				price: 0,
			};

			attchs.push(item);
		}

		console.log('attchs::', attchs);
		setAttachments(attchs);
	};

	useEffect(() => {
		if (fetchTrue) {
			fetchDetail();
			setFetchTrue(false);
		}
	}, [fetchTrue, setFetchTrue]);
	console.log('detail:', detail);

	function uppercase(text: string) {
		return text
			.toLowerCase()
			.split(' ')
			.map((txt) => txt[0].toUpperCase() + txt.slice(1))
			.join(' ');
	}
	// Get schedule list (schedule list only shows when initialData.from === "/app/approval")
	const parseDateSelection: any = useMemo(() => {
		let parsedData: any = {},
			detailItems: any = [];

		if (Array.isArray(disabledDates)) {
			// Result: Object with Date as key and states as value
			parsedData = {
				...disabledDates.reduce((a: any, value: any) => {
					return {
						...a,
						[value]: {
							selected: false,
							disabled: true,
							disableTouchEvent: true,
						},
					};
				}, {}),
			};
		}

		if (Array.isArray(semiProcessedSchedules)) {
			// Iterate each category of eventItems
			for (let i = 0; i < semiProcessedSchedules?.length; i++) {
				let subItem = semiProcessedSchedules[i];
				for (let j = 0; j < subItem?.data?.length; j++) {
					let itemDate = subItem?.data?.[j]?.date;
					const isBlocking = subItem?.data?.[j]?.isBlockingEvent;
					let dots = [...(parsedData?.[itemDate]?.dots || [])];
					if (!isBlocking) {
						dots = [
							...dots,
							dataTypeEvent.find((dot: any) => dot?.type === subItem?.name),
						];
					}
					parsedData = {
						...parsedData,
						[`${itemDate}`]: {
							...parsedData[itemDate],
							selected: isBlocking ? true : false,
							marked: isBlocking ? false : true,
							selectedColor: isBlocking ? '#D5DCE1' : undefined,
							dots,
						},
					};

					let foundIndex = detailItems?.findIndex(
						(item: any) => item?.date === itemDate,
					);
					if (foundIndex !== -1) {
						detailItems[foundIndex] = {
							...detailItems?.[foundIndex],
							data: [
								...(detailItems?.[foundIndex]?.data || []),
								{
									...subItem?.data?.[j],
									settings: dataTypeEvent.find(
										(dot: any) => dot?.type === subItem?.name,
									),
								},
							],
						};
					} else {
						detailItems = [
							...detailItems,
							{
								date: itemDate,
								data: [
									...(detailItems?.data || []),
									{
										...subItem?.data?.[j],
										settings: dataTypeEvent.find(
											(dot: any) => dot?.type === subItem?.name,
										),
									},
								],
							},
						];
					}
				}
			}
		}

		detailItems = detailItems?.sort((a: any, b: any) => {
			if (a?.date < b?.date) {
				return -1;
			} else if (a?.date > b?.date) {
				return 1;
			} else {
				return 0;
			}
		});

		let startAt;

		if (
			scheduleType?.valueOf() === EScheduleTypes.telemedicine ||
			scheduleType?.valueOf() === EScheduleTypes.appointment ||
			scheduleType?.valueOf() === EScheduleTypes['consult-now']
		) {
			const detailData = detail as TransactionConsultationProperties;
			startAt = detailData?.scheduledStartAt;
		} else if (
			scheduleType?.valueOf() === EScheduleTypes['telemedicine-personal'] ||
			scheduleType?.valueOf() === EScheduleTypes['appointment-personal']
		) {
			const detailData = detail as PersonalConsultationProperties;
			startAt = detailData?.scheduledStartAt;
		} else if (
			scheduleType?.valueOf() === EScheduleTypes['personal-event'] ||
			scheduleType?.valueOf() === EScheduleTypes['personal-event-blocked']
		) {
			const detailData = detail as PersonalEvent;
			startAt = detailData?.eventStartAt;
		} else if (scheduleType?.valueOf() === EScheduleTypes['medical-service']) {
			const detailData = detail as TransactionHealthServiceProps;
			startAt = detailData?.scheduledStartAt;
		}

		const selectedDate = moment(startAt).format('YYYY-MM-DD');

		if (selectedDate) {
			detailItems = detailItems?.filter(
				(item: any) => item?.date === selectedDate,
			);
			parsedData[`${selectedDate}`] = {
				...parsedData?.[`${selectedDate}`],
			};
		}
		parsedData = parsedData[selectedDate];
		console.log('parsedData-====>>>>', parsedData);
		return { data: parsedData, detail: detailItems };
	}, []);

	// Approve or Reject Event (for events when initialData.from === "/app/approval")

	async function updateTransactionConsult(
		id: string,
		data: Partial<TransactionConsultationProperties>,
	) {
		try {
			const res = await httpRequest.patch<
				ApiResponseDefault<TransactionConsultationProperties>
			>(`transaction-consults/${id}`, data);
			if (
				data.transactionStatus &&
				data.transactionStatus === ETransactionStatusType.APPROVED
			) {
				setFetchTrue(true);
				message.success('Success approve event!');
				setIsModalAcceptConsulVisible(false);
				setIsModalApproveRescheduleVisible(false);
			} else if (
				data.transactionStatus &&
				data.transactionStatus === ETransactionStatusType.REJECTED
			) {
				setFetchTrue(true);
				message.success('Success reject event!');
				setIsModalRejectConsulVisible(false);
				setIsModalRejectRescheduleVisible(false);
			}
			// history.goBack();
		} catch (err) {
			console.log(err);
			if (
				data.transactionStatus &&
				data.transactionStatus === ETransactionStatusType.APPROVED
			) {
				message.error('ERROR: Failed to approve event! Please try again');
			} else if (
				data.transactionStatus &&
				data.transactionStatus === ETransactionStatusType.REJECTED
			) {
				message.error('ERROR: Failed to reject event! Please try again');
			}
			// history.goBack();
		}
	}

	// Helper Functions
	function parseEMetaType(t?: EMetaType) {
		switch (t) {
			case EMetaType.CLEARANCE:
				return {
					name: 'Clearance',
					bgColor: '#C8F1E9',
					color: '#03CEA4',
				};
			case EMetaType.FIT_TO_WORK:
				return {
					name: 'Fit to Work',
					bgColor: '#EAE4F9',
					color: '#825DD1',
				};
			case EMetaType.MEDICAL_CERTIFICATE:
				return {
					name: 'Medical Certificate',
					bgColor: '#DAEFFA',
					color: '#2B9CDC',
				};
			case EMetaType.OTHERS:
				return {
					name: 'Others Document',
					bgColor: null,
					color: null,
				};
			default:
				return {
					name: '',
					bgColor: null,
					color: null,
				};
		}
	}

	const isStartDateNotSameWithEndDate = useMemo(() => {
		let startAt;
		let endAt;

		if (
			scheduleType?.valueOf() === EScheduleTypes['personal-event'] ||
			scheduleType?.valueOf() === EScheduleTypes['personal-event-blocked']
		) {
			startAt = (detail as PersonalEvent)?.eventStartAt;
			endAt = (detail as PersonalEvent)?.eventEndAt;
			if (!startAt || !endAt) {
				return false;
			}
		}
		const start = startAt ? new Date(startAt) : new Date();
		const end = endAt ? new Date(endAt) : new Date();
		return start.toLocaleDateString() !== end.toLocaleDateString();
	}, [scheduleType, detail]);

	const TagStatus = () => {
		let temp = (detail as TransactionConsultationProperties) || '';
		let status = '';
		let title = '';
		let color = '';

		if (
			temp?.transactionStatus?.valueOf() ===
			ETransactionStatusType.WAITING_PAYMENT
		) {
			title = 'Waiting Payment';
			status = 'is waiting payment';
			color = 'orange';
		} else if (
			temp?.transactionStatus?.valueOf() ===
			ETransactionStatusType.WAITING_APPROVAL
		) {
			title = 'Waiting Approval';
			status = 'is waiting approval';
			color = 'orange';
		} else if (
			temp?.transactionStatus?.valueOf() === ETransactionStatusType.APPROVED
		) {
			title = 'Approved';
			status = 'has been approved';
			color = 'green';
		} else if (
			temp?.transactionStatus?.valueOf() === ETransactionStatusType.REJECTED
		) {
			title = 'Rejected';
			status = 'has been rejected';
			color = 'red';
		} else if (
			temp?.transactionStatus?.valueOf() ===
			ETransactionStatusType.REJECTED_CHANGE_DOCTOR
		) {
			title = 'Rejected';
			status = 'has been rejected by Doctor';
			color = 'red';
		} else if (
			temp?.transactionStatus?.valueOf() ===
			ETransactionStatusType.REJECTED_REFUND
		) {
			title = 'Rejected';
			status = 'has been rejected (refund)';
			color = 'red';
		} else if (
			temp?.transactionStatus?.valueOf() ===
			ETransactionStatusType.CANCELED_BY_SYSTEM
		) {
			title = 'Expired';
			status = 'has expired';
			color = 'red';
		} else if (
			temp?.transactionStatus?.valueOf() ===
			ETransactionStatusType.CANCELED_BY_USER
		) {
			title = 'Cancelled';
			status = 'has been cancelled';
			color = 'red';
		} else if (
			temp?.transactionStatus?.valueOf() === ETransactionStatusType.COMPLETED
		) {
			title = 'Completed';
			status = 'has been completed';
			color = 'green';
		} else if (
			temp?.transactionStatus?.valueOf() ===
			ETransactionStatusType.REJECTED_CHANGE_SCHEDULE
		) {
			title = 'Rescheduled';
			status = 'has been rescheduled';
			color = 'orange';
		}

		if (color === 'green') {
			return (
				<>
					<Row
						align="middle"
						style={{
							width: '100%',
							borderRadius: 8,
							marginTop: 12,
							backgroundColor: '#D7F4EB',
							border: '1px solid #268E6C',
							paddingTop: 5,
							paddingBottom: 5,
						}}
					>
						<Col>
							<CheckCircleOutlined
								style={{ color: '#268E6C', marginLeft: 9, marginRight: 9 }}
							/>
						</Col>
						<Col>
							<Row style={{ margin: 0 }}>
								<Text
									style={{ color: '#268E6C', fontSize: 14, fontWeight: 700 }}
								>
									{title}
								</Text>
							</Row>
							<Row style={{ margin: 0 }}>
								<Text style={{ color: '#268E6C', fontSize: 14 }}>
									This consultation {status}
								</Text>
							</Row>
						</Col>
					</Row>
				</>
			);
		} else if (color === 'orange') {
			return (
				<>
					<Row
						align="middle"
						style={{
							width: '100%',
							borderRadius: 8,
							marginTop: 12,
							marginBottom: 12,
							backgroundColor: '#FCF1E3',
							border: '1px solid #DA7B11',
							paddingTop: 5,
							paddingBottom: 5,
						}}
					>
						<Col>
							<ClockCircleOutlined
								style={{ color: '#DA7B11', marginLeft: 9, marginRight: 9 }}
							/>
						</Col>
						<Col>
							<Row style={{ margin: 0 }}>
								<Text
									style={{ color: '#DA7B11', fontSize: 14, fontWeight: 700 }}
								>
									{title}
								</Text>
							</Row>
							<Row style={{ margin: 0 }}>
								<Text style={{ color: '#DA7B11', fontSize: 14 }}>
									This consultation {status}
								</Text>
							</Row>
						</Col>
					</Row>
				</>
			);
		} else if (color === 'red') {
			return (
				<>
					<Row
						align="middle"
						style={{
							width: '100%',
							borderRadius: 8,
							marginTop: 12,
							marginBottom: 12,
							backgroundColor: '#F6D5D6',
							border: '1px solid #D7373F',
							paddingTop: 5,
							paddingBottom: 5,
						}}
					>
						<Col>
							<CloseCircleOutlined
								style={{ color: '#D7373F', marginLeft: 9, marginRight: 9 }}
							/>
						</Col>
						<Col>
							<Row style={{ margin: 0 }}>
								<Text
									style={{ color: '#D7373F', fontSize: 14, fontWeight: 700 }}
								>
									{title}
								</Text>
							</Row>
							<Row style={{ margin: 0 }}>
								<Text style={{ color: '#D7373F', fontSize: 14 }}>
									This consultation {status}
								</Text>
							</Row>
						</Col>
					</Row>
				</>
			);
		}
	};

	// Components

	const ComponentBanner = () => {
		const telemedicineStyles = {
			height: 50,
			paddingTop: 12,
			paddingBottom: 12,
			width: '100%',
			backgroundColor: '#D81F64',
		};
		const inPersonStyles = {
			height: 50,
			paddingTop: 12,
			paddingBottom: 12,
			width: '100%',
			backgroundColor: '#2B9CDC',
			color: 'white',
		};
		const personalEventStyles = {
			height: 50,
			paddingTop: 12,
			paddingBottom: 12,
			width: '100%',
			backgroundColor: '#03CEA4',
			color: 'white',
		};
		const personalEventBlockStyles = {
			height: 50,
			paddingTop: 12,
			paddingBottom: 12,
			width: '100%',
			backgroundColor: '#DCDCDC',
			color: 'black',
		};
		const medicalServiceStyles = {
			height: 50,
			paddingTop: 12,
			paddingBottom: 12,
			width: '100%',
			backgroundColor: '#F26B29',
			color: 'white',
		};
		const consultNowStyles = {
			height: 50,
			paddingTop: 12,
			paddingBottom: 12,
			width: '100%',
			backgroundColor: COLORS.yellowApp,
			color: 'white',
		};

		let style: any = {};
		let textStyle: any = {};
		let title: string = '';
		let icon: any = {};

		if (
			scheduleType?.valueOf() === EScheduleTypes.telemedicine ||
			scheduleType?.valueOf() === EScheduleTypes['telemedicine-personal']
		) {
			icon = <IconTelemedicineColorize width={11} />;
			style = telemedicineStyles;
			title = 'Telemedicine';
			textStyle = { fontSize: 18, color: 'white', fontWeight: 600 };
		} else if (
			scheduleType?.valueOf() === EScheduleTypes.appointment ||
			scheduleType?.valueOf() === EScheduleTypes['appointment-personal']
		) {
			icon = <IconAppointmentColorize width={11} />;
			style = inPersonStyles;
			title = 'In Person Appointment';
			textStyle = { fontSize: 18, color: 'white', fontWeight: 600 };
		} else if (scheduleType?.valueOf() === EScheduleTypes['personal-event']) {
			icon = <IconPersonalEventColorize width={11} />;
			style = personalEventStyles;
			title = 'Personal Event';
			textStyle = { fontSize: 18, color: 'white', fontWeight: 600 };
		} else if (
			scheduleType?.valueOf() === EScheduleTypes['personal-event-blocked']
		) {
			icon = <IconPersonalEventBlockedColorize width={11} />;
			style = personalEventBlockStyles;
			title = 'Personal Event (Blocked)';
			textStyle = { fontSize: 18, color: '#1D2B36', fontWeight: 600 };
		} else if (scheduleType?.valueOf() === EScheduleTypes['medical-service']) {
			icon = <IconMedServiceColorize width={11} />;
			style = medicalServiceStyles;
			title = 'Medical Service';
			textStyle = { fontSize: 18, color: 'white', fontWeight: 600 };
		} else if (scheduleType?.valueOf() === EScheduleTypes['consult-now']) {
			icon = <IconWarningCircleColorize width={18} height={18} />;
			style = consultNowStyles;
			title = 'Consult Now';
			textStyle = { fontSize: 18, color: 'white', fontWeight: 600 };
		}

		return (
			<Row style={style} justify="center" align="middle">
				<div
					style={{
						width: 24,
						height: 24,
						backgroundColor: 'white',
						borderRadius: '50%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						marginRight: 6,
					}}
				>
					{icon}
				</div>
				<Text style={textStyle}>{title}</Text>
			</Row>
		);
	};

	const ComponentPatientProfile = () => {
		let patientName;
		let patientGender;
		let patientAge;
		let pwId: string;
		if (
			scheduleType?.valueOf() === EScheduleTypes.telemedicine ||
			scheduleType?.valueOf() === EScheduleTypes['consult-now'] ||
			scheduleType?.valueOf() === EScheduleTypes.appointment
		) {
			const detailData = detail as TransactionConsultationProperties;
			patientName = getName(detailData?.metaPatient);
			patientGender = getGender(detailData?.metaPatient) || '-';
			patientAge =
				detailData?.metaPatient?.patientAge ||
				calculateAge(getBirthdate(detailData?.metaPatient)) ||
				'-';
			pwId = detailData?.metaPatient?.pwId || '-';
		} else if (
			scheduleType?.valueOf() === EScheduleTypes['telemedicine-personal'] ||
			scheduleType?.valueOf() === EScheduleTypes['appointment-personal']
		) {
			const detailData = detail as PersonalConsultationProperties;
			patientName = getName(detailData?.metaPatient);
			patientGender = getGender(detailData?.metaPatient) || '-';
			patientAge =
				detailData?.metaPatient?.patientAge ||
				calculateAge(getBirthdate(detailData?.metaPatient)) ||
				'-';
			pwId = detailData?.metaPatient?.pwId || '-';
		} else if (scheduleType?.valueOf() === EScheduleTypes['medical-service']) {
			const detailData = detail as TransactionHealthServiceProps;
			patientName = getName(detailData?.metaPatient);
			patientGender = getGender(detailData?.metaPatient) || '-';
			patientAge =
				detailData?.metaPatient?.patientAge ||
				calculateAge(getBirthdate(detailData?.metaPatient)) ||
				'-';
			pwId = detailData?.metaPatient?.pwId || '-';
		} else {
			return false;
		}

		return (
			<div style={{ paddingTop: 12 }}>
				<Row>
					<Text style={{ fontSize: 16, paddingBottom: 8, fontWeight: 500 }}>
						Patient Profile
					</Text>
				</Row>
				<Row>
					<Col style={{ paddingRight: 30 }}>
						<Row>
							<Text type="secondary" style={{ fontSize: 14 }}>
								Name
							</Text>
						</Row>
						<Row>
							<Text style={{ fontSize: 14 }}>{patientName}</Text>
						</Row>
						<Row>
							<Text type="secondary" style={{ fontSize: 14 }}>
								Age
							</Text>
						</Row>
						<Row>
							<Text style={{ fontSize: 14 }}>{patientAge}</Text>
						</Row>
					</Col>
					<Col>
						<Row>
							<Text type="secondary" style={{ fontSize: 14 }}>
								Gender
							</Text>
						</Row>
						<Row>
							<Text style={{ fontSize: 14 }}>{patientGender}</Text>
						</Row>
						<Row>
							<Text type="secondary" style={{ fontSize: 14 }}>
								PWD/Senior ID
							</Text>
						</Row>
						{pwId !== undefined && pwId !== null && (
							<Row>
								<Text style={{ fontSize: 14 }}>{pwId}</Text>
							</Row>
						)}
					</Col>
				</Row>
			</div>
		);
	};

	const isNowTheDay = (startAt: string) => {
		const now = new Date();
		const schedule = new Date(startAt);
		return (
			now.getFullYear() === schedule.getFullYear() &&
			now.getMonth() === schedule.getMonth() &&
			now.getDate() === schedule.getDate()
		);
	};

	const ComponentDateTime = () => {
		let startAt;
		let endAt;

		if (
			scheduleType?.valueOf() === EScheduleTypes.telemedicine ||
			scheduleType?.valueOf() === EScheduleTypes.appointment ||
			scheduleType?.valueOf() === EScheduleTypes['consult-now']
		) {
			const detailData = detail as TransactionConsultationProperties;
			startAt = detailData?.scheduledStartAt;
			endAt = detailData?.scheduledEndAt;
		} else if (
			scheduleType?.valueOf() === EScheduleTypes['telemedicine-personal'] ||
			scheduleType?.valueOf() === EScheduleTypes['appointment-personal']
		) {
			const detailData = detail as PersonalConsultationProperties;
			startAt = detailData?.scheduledStartAt;
			endAt = detailData?.scheduledEndAt;
		} else if (
			scheduleType?.valueOf() === EScheduleTypes['personal-event'] ||
			scheduleType?.valueOf() === EScheduleTypes['personal-event-blocked']
		) {
			const detailData = detail as PersonalEvent;
			startAt = detailData?.eventStartAt;
			endAt = detailData?.eventEndAt;
		} else if (scheduleType?.valueOf() === EScheduleTypes['medical-service']) {
			const detailData = detail as TransactionHealthServiceProps;
			startAt = detailData?.scheduledStartAt;
			endAt = detailData?.scheduledEndAt;
		} else {
			return false;
		}

		return (
			<div style={{ paddingTop: 12 }}>
				<Row>
					<Text style={{ fontSize: 16, paddingBottom: 8, fontWeight: 500 }}>
						Date & Time
					</Text>
				</Row>
				<Row>
					<Col style={{ paddingRight: 30 }}>
						{isStartDateNotSameWithEndDate && (
							<Row>
								<Text type="secondary" style={{ fontSize: 14 }}>
									Date & Time
								</Text>
							</Row>
						)}
						{!isStartDateNotSameWithEndDate && (
							<Row>
								<Text type="secondary" style={{ fontSize: 14 }}>
									Date
								</Text>
							</Row>
						)}
						{isStartDateNotSameWithEndDate && (
							<Row>
								<Text style={{ fontSize: 14 }}>
									{moment(startAt).format('ddd, MMM DD, YYYY HH:mm')} -{' '}
									{moment(endAt).format('ddd, MMM DD, YYYY HH:mm')}
								</Text>
							</Row>
						)}
						{!isStartDateNotSameWithEndDate && (
							<Row>
								<Text style={{ fontSize: 14 }}>
									{moment(startAt).format('ddd, MMM DD, YYYY')}
								</Text>
							</Row>
						)}
					</Col>
					{!isStartDateNotSameWithEndDate && (
						<Col>
							<Row>
								<Text type="secondary" style={{ fontSize: 14 }}>
									Time
								</Text>
							</Row>
							<Row>
								<Text style={{ fontSize: 14 }}>
									{' '}
									{startAt && endAt
										? `${moment(startAt).format('HH:mm')} - ${moment(
												endAt,
										  ).format('HH:mm')}`
										: '-'}
								</Text>
							</Row>
						</Col>
					)}
				</Row>
			</div>
		);
	};

	const ComponentPlace = () => {
		let imageUrl;
		let locationName;
		let locationAddress;
		let isHomeService = false;

		if (scheduleType?.valueOf() === EScheduleTypes.appointment) {
			const detailData = detail as TransactionConsultationProperties;
			imageUrl =
				detailData?.metaPartner?.imageLogoURL ||
				detailData?.metaPartner?.imageCoverURL;
			locationName = detailData?.metaPartner?.partnerName;
			locationAddress = detailData?.metaPartner?.address;
		} else if (
			scheduleType?.valueOf() === EScheduleTypes['appointment-personal']
		) {
			const detailData = detail as PersonalConsultationProperties;
			imageUrl =
				detailData?.metaPartner?.imageLogoURL ||
				detailData?.metaPartner?.imageCoverURL;
			locationName =
				detailData?.place || detailData?.metaPartner?.partnerName || '-';
			locationAddress = detailData?.metaPartner?.address;
		} else if (scheduleType?.valueOf() === EScheduleTypes['medical-service']) {
			const detailData = detail as TransactionHealthServiceProps;

			if (
				detailData?.serviceType &&
				detailData?.serviceType === EAvailableServiceTypes.IN_CLINIC
			) {
				imageUrl =
					detailData?.metaPartner?.imageLogoURL ||
					detailData?.metaPartner?.imageCoverURL;
				locationName = detailData?.metaPartner?.partnerName;
				locationAddress = detailData?.metaPartner?.address;
			} else {
				isHomeService = true;
				locationName = detailData?.metaPartner?.partnerName;
				locationAddress =
					detailData?.metaAddress?.label +
					' (' +
					detailData?.metaAddress?.addressDetail +
					')';
			}
		} else {
			return false;
		}

		return (
			<div style={{ paddingTop: 12 }}>
				<Row>
					<Text style={{ fontSize: 16, paddingBottom: 8, fontWeight: 500 }}>
						Place
					</Text>
				</Row>
				<Row align="middle">
					<Col>
						{!isHomeService && (
							<Image
								preview={false}
								width={56}
								height={56}
								src={imageUrl}
								style={{ borderRadius: 4 }}
							/>
						)}
						{isHomeService && (
							<div
								style={{
									width: 44,
									height: 44,
									backgroundColor: '#F26B29',
									borderRadius: 12,
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<HomeOutlined
									style={{ color: 'white' }}
									width={20}
									height={19}
								/>
							</div>
						)}
					</Col>
					<Col style={{ paddingLeft: 6 }}>
						<Row>
							{!isHomeService && (
								<Text type="secondary" style={{ fontSize: 12 }}>
									{locationAddress}
								</Text>
							)}
							{isHomeService && (
								<Text type="secondary" style={{ fontSize: 12 }}>
									Home Service by
								</Text>
							)}
						</Row>
						<Row>
							<Text style={{ fontSize: 14, fontWeight: 500 }}>
								{locationName}
							</Text>
						</Row>
					</Col>
				</Row>
			</div>
		);
	};

	const ComponentReasonForConsultation = () => {
		let labelDescription;
		let desc: string;

		if (
			scheduleType?.valueOf() === EScheduleTypes.telemedicine ||
			scheduleType?.valueOf() === EScheduleTypes['consult-now'] ||
			scheduleType?.valueOf() === EScheduleTypes.appointment
		) {
			const detailData = detail as TransactionConsultationProperties;
			labelDescription = 'Reason of Consultation';
			desc =
				detailData?.reasonOfConsultation ||
				detailData?.medicalRecord?.reasonOfConsultation ||
				detailData?.description ||
				'-';
		} else if (
			scheduleType?.valueOf() === EScheduleTypes['telemedicine-personal'] ||
			scheduleType?.valueOf() === EScheduleTypes['appointment-personal']
		) {
			labelDescription = 'Description';
			const detailData = detail as PersonalConsultationProperties;
			desc = detailData?.description || '-';
		} else if (
			scheduleType?.valueOf() === EScheduleTypes['personal-event'] ||
			scheduleType?.valueOf() === EScheduleTypes['personal-event-blocked']
		) {
			labelDescription = 'Description';
			const detailData = detail as PersonalEvent;
			desc = detailData?.description || '-';

			if (isSecretary && detailData?.createdByUserId !== userId) {
				return undefined;
			}
		} else {
			return false;
		}

		function concatentate(string: string) {
			return (
				<>
					{!readMore ? (
						<Paragraph style={{ fontSize: 14 }}>
							{string.slice(0, 213)}...
							<Text
								underline
								style={{ cursor: 'pointer', fontSize: 14, fontWeight: 500 }}
								onClick={() => setReadMore(true)}
							>
								Read More
							</Text>
						</Paragraph>
					) : (
						<Paragraph style={{ fontSize: 14 }}>{desc}</Paragraph>
					)}
				</>
			);
		}

		return (
			<Row>
				<Row style={{ width: '100%' }}>
					<Text style={{ fontSize: 16, paddingBottom: 8, fontWeight: 500 }}>
						{labelDescription}
					</Text>
				</Row>
				<Row>
					{desc.length > 213 ? (
						concatentate(desc)
					) : (
						<Paragraph style={{ fontSize: 14 }}>{desc}</Paragraph>
					)}
				</Row>
			</Row>
		);
	};

	const ComponentBlockingWarning = () => {
		return (
			<>
				<Row>
					<CustomBlockingCard>
						<Row align="middle">
							<WarningOutlined height={15} />
							<Text style={{ fontWeight: 500, fontSize: 16, paddingLeft: 10 }}>
								Blocking
							</Text>
						</Row>
						<Paragraph
							style={{
								color: 'rgba(0, 0, 0, 0.6)',
								fontSize: 14,
								paddingTop: 10,
							}}
						>
							Blocking is activated in this personal schedule, all patients will
							be notified and they will be offered to reschedule, refund or
							change to another doctor. Potential Patients also cannot invite
							doctors to consult during this personal schedule.
						</Paragraph>
					</CustomBlockingCard>
				</Row>
			</>
		);
	};

	const ComponentItems = () => {
		const details = detail as TransactionHealthServiceProps;

		return (
			<>
				<Row>
					<Col>
						<Text style={{ fontSize: 16, paddingBottom: 8, fontWeight: 500 }}>
							Items
						</Text>
						<ul
							style={{
								padding: 0,
								listStyleType: 'none',
								WebkitColumnCount: 2,
								MozColumnCount: 2,
							}}
						>
							{details?.transactionHealthServiceItems?.map((e) => {
								return (
									<>
										<li>
											<Text style={{ fontSize: 14 }}>
												• {e.metaHealthService?.healthServiceName || '-'}
											</Text>
										</li>
									</>
								);
							})}
						</ul>
					</Col>
				</Row>
			</>
		);
	};

	const ComponentMedicalServicePackage = () => {
		return (
			<>
				<Row>
					<Title level={5} style={{ paddingTop: 24 }}>
						Hematology
					</Title>
				</Row>
				<Paragraph style={{ fontSize: 14, color: '#556575' }}>
					Suspendisse erat dolor, imperdiet sed consequat ut, bibendum non
					neque. Etiam tristique vehicula eros. Nullam ComponentPlacerat massa
					turpis, at posuere nisi aliquet at.
				</Paragraph>
			</>
		);
	};

	const ComponentConsultationButtonCard = (
		icon: any,
		onClick = () => {},
		title: string,
		description: string,
	) => {
		return (
			<CustomComponentConsultationButtonCard onClick={onClick}>
				<Row>
					<Col>
						<Row>
							<div className="icon-background">{icon}</div>
						</Row>
						<Row>
							<Text style={{ fontSize: 14, fontWeight: 500 }}>{title}</Text>
						</Row>
						<Row style={{ width: 160 }}>
							<Paragraph type="secondary" style={{ fontSize: 12 }}>
								{description}
							</Paragraph>
						</Row>
					</Col>
					<Col
						style={{
							width: 15,
							position: 'relative',
							top: 60,
							left: 0,
							cursor: 'pointer',
						}}
					>
						<IconChevron color="black" />
					</Col>
				</Row>
			</CustomComponentConsultationButtonCard>
		);
	};

	const ComponentAttachmentButtonCard = (
		icon: any,
		title: string,
		validUntil: string,
		price?: string,
	) => {
		return (
			<>
				<CustomComponentAttachmentButtonCard>
					<Row align="middle">
						<Col>
							<div className="icon-background">{icon}</div>
						</Col>
						<Col>
							<Row>
								<Text style={{ fontSize: 14, fontWeight: 500 }}>{title}</Text>
							</Row>
							<Row align="middle">
								<ClockCircleOutlined
									style={{
										color: '#929292',
										fontSize: 9,
									}}
								/>
								<Text
									type="secondary"
									style={{ fontSize: 12, color: '#929292', marginLeft: 7 }}
								>
									Valid until {validUntil}
								</Text>
							</Row>
							{price && (
								<Row align="middle">
									<TagOutlined
										style={{
											color: '#929292',
											fontSize: 9,
										}}
										rotate={270}
									/>
									<Text
										type="secondary"
										style={{
											fontSize: 12,
											color: '#929292',
											marginLeft: 7,
										}}
									>
										₱ {price}
									</Text>
								</Row>
							)}
						</Col>
						<Col
							style={{
								position: 'absolute',
								right: 25,
								cursor: 'pointer',
							}}
						>
							<Row align="middle" style={{ maxWidth: 77 }}>
								<Col>
									{price !== undefined && price !== '0' && (
										<CustomTagPaid>Paid</CustomTagPaid>
									)}
									{price !== undefined && price === '0' && (
										<CustomTagUnpaid>Unpaid</CustomTagUnpaid>
									)}
								</Col>
								<Col style={{ paddingTop: 10 }}>
									<IconChevron color="black" />
								</Col>
							</Row>
						</Col>
					</Row>
				</CustomComponentAttachmentButtonCard>
			</>
		);
	};

	// Component Group

	const ConsultationBefore = () => {
		const item = detail as TransactionConsultationProperties;
		let partnerType: EPartnerType;
		let partnerId = item.partnerId;

		if (
			item.consultationType?.valueOf() ===
				EConsultationType.TELEMEDICINE_CHAT_CONSULTATION ||
			item.consultationType?.valueOf() ===
				EConsultationType.TELEMEDICINE_VIDEO_CHAT_CONSULTATION
		) {
			partnerType = EPartnerType.ONLINE_CLINIC;
		} else if (
			item.consultationType?.valueOf() ===
			EConsultationType.FACE_TO_FACE_CONSULTATION
		) {
			partnerType = EPartnerType.CLINIC;
		} else if (
			item.consultationType?.valueOf() ===
				EConsultationType.CONSULT_NOW_CHAT_CONSULTATION ||
			item.consultationType?.valueOf() ===
				EConsultationType.CONSULT_NOW_VIDEO_CHAT_CONSULTATION
		) {
			partnerType = EPartnerType.CONSULT_NOW;
		}
		return (
			<>
				<Row>
					<Text style={{ fontSize: 16, fontWeight: 500 }}>Consultation</Text>
				</Row>
				<Row>
					{/* {ComponentConsultationButtonCard(
            <IconClockOutline />,
            () => {},
            'Offer Reschedule',
            'Offer patient different time for the consultation'
          )} */}
					{ComponentConsultationButtonCard(
						<IconCancelOutline id="icon-cancel-outline" />,
						() =>
							history.push('/app/schedule/reschedule', {
								partnerId,
								partnerType,
								transactionConsultationId: initialData.id,
							}),
						'Cancel Consultation',
						'Cancel the consultation',
					)}
					{ComponentConsultationButtonCard(
						<IconReceiptOutline />,
						() => {},
						'Earning Receipt',
						'View the earning receipt',
					)}
				</Row>
			</>
		);
	};

	const ConsultationAfter = () => {
		return (
			<>
				<Row>
					<Text style={{ fontSize: 16, fontWeight: 500 }}>Consultation</Text>
				</Row>
				<Row>
					{ComponentConsultationButtonCard(
						<IconChatOutline />,
						() => {
							history.push(`/app/consultation/${initialData.id}`);
						},
						'See chat history',
						'See chat history during the consultation',
					)}
					{ComponentConsultationButtonCard(
						<IconMedicalNotesOutline />,
						() => {},
						'Medical Notes',
						'Notes for patients (Only Doctors can see)',
					)}
					{ComponentConsultationButtonCard(
						<IconReceiptOutline />,
						() => {},
						'Earning Receipt',
						'View the earning receipt',
					)}
				</Row>
				<Divider />
				<Row>
					<Text style={{ fontSize: 16, fontWeight: 500 }}>Attachment</Text>
				</Row>
				<Row>
					{attachments.map((e) => {
						if (
							e.title === 'Prescription' ||
							e.title === 'RX Prescription' ||
							e.title === 'Rx Prescription'
						) {
							return ComponentAttachmentButtonCard(
								<IconPrescriptionOutline />,
								'Prescription',
								e?.validUntil || '-',
							);
						} else if (e.title === 'Lab Request') {
							return ComponentAttachmentButtonCard(
								<IconLabRequestOutline id="icon-lab-request" />,
								'Lab Request',
								e?.validUntil || '-',
							);
						} else if (e.title === 'Clearance') {
							return ComponentAttachmentButtonCard(
								<IconClearanceOutline />,
								'Clearance',
								e?.validUntil || '-',
								e.price.toString(),
							);
						} else if (e.title === 'Fit to Work' || e.title === 'Fit To Work') {
							return ComponentAttachmentButtonCard(
								<IconFitToWorkOutline />,
								'Fit to Work',
								e?.validUntil || '-',
								e.price.toString(),
							);
						} else if (e.title === 'Medical Certificate') {
							return ComponentAttachmentButtonCard(
								<IconMedicalCertificateOutline />,
								'Medical Certificate',
								e?.validUntil || '-',
								e.price.toString(),
							);
						} else if (e.title === 'Doctor Document') {
							return ComponentAttachmentButtonCard(
								<IconDoctorDocumentOutline />,
								'Doctor Document',
								e?.validUntil || '-',
								e.price.toString(),
							);
						} else {
							return false;
						}
					})}
				</Row>
			</>
		);
	};

	const ScheduleList = () => {
		return (
			<>
				{initialData.from && initialData.from === '/app/approval' && (
					<>
						<div className="list-agenda">
							{parseDateSelection?.detail?.map((item: any, index: number) => (
								<ItemGroupAgenda
									item={item}
									index={index}
									upcomingIndex={0}
									isDoctor={isDoctor}
									isSecretary={isSecretary}
									isPatient={isPatient}
									fetchList={fetchList}
								/>
							))}
						</div>
					</>
				)}
			</>
		);
	};

	const ApproveReject = () => {
		const details = detail as TransactionConsultationProperties;

		const handleClickApprove = () => {
			if (
				details.transactionStatus?.valueOf() ===
				ETransactionStatusType.WAITING_APPROVAL
			) {
				setIsModalAcceptConsulVisible(true);
			} else if (
				details.transactionStatus?.valueOf() ===
				ETransactionStatusType.REJECTED_CHANGE_SCHEDULE
			) {
				setIsModalApproveRescheduleVisible(true);
			}
		};

		const handleClickReject = () => {
			if (
				details.transactionStatus?.valueOf() ===
				ETransactionStatusType.WAITING_APPROVAL
			) {
				setIsModalRejectConsulVisible(true);
			} else if (
				details.transactionStatus?.valueOf() ===
				ETransactionStatusType.REJECTED_CHANGE_SCHEDULE
			) {
				setIsModalRejectRescheduleVisible(true);
			}
		};

		return (
			<>
				{details?.transactionStatus?.valueOf() ===
					ETransactionStatusType.WAITING_APPROVAL ||
				details?.transactionStatus?.valueOf() ===
					ETransactionStatusType.REJECTED_CHANGE_SCHEDULE ? (
					<Row justify="end" gutter={10}>
						<Col>
							<Button
								type="ghost"
								style={{
									width: 160,
									height: 40,
									borderRadius: 12,
									borderColor: COLORS.primary,
								}}
								onClick={handleClickReject}
							>
								<Text
									style={{
										fontWeight: 700,
										fontSize: 14,
										color: COLORS.primary,
									}}
								>
									Reject
								</Text>
							</Button>
						</Col>
						<Col>
							<Button
								type="primary"
								style={{
									width: 160,
									height: 40,
									borderRadius: 12,
								}}
								onClick={handleClickApprove}
							>
								<Text style={{ fontWeight: 700, fontSize: 14, color: '#fff' }}>
									Approve
								</Text>
							</Button>
						</Col>
					</Row>
				) : null}
			</>
		);
	};

	const ComponentRescheduling = () => {
		const item = detail as TransactionConsultationProperties;

		let oldStartAt, oldEndAt, newStartAt, newEndAt;

		if (
			[
				ETransactionStatusType.REJECTED_CHANGE_SCHEDULE,
				ETransactionStatusType.APPROVED,
				ETransactionStatusType.COMPLETED,
			].includes(item.transactionStatus) &&
			item.history
		) {
			const found = item.history.find(
				(item: any) =>
					item.newData.transactionStatus ===
					ETransactionStatusType.REJECTED_CHANGE_SCHEDULE,
			);

			const type = getScheduleType(item);

			if (
				found &&
				found.newData &&
				(type === EScheduleTypes.telemedicine ||
					type === EScheduleTypes.appointment ||
					type === EScheduleTypes['consult-now'])
			) {
				oldStartAt = found.newData.oldScheduledStartAt;
				oldEndAt = found.newData.oldScheduledEndAt;
				newStartAt = found.newData.newScheduledStartAt;
				newEndAt = found.newData.newScheduledEndAt;
			}
		}
		return (
			<div className="reschedule-wrapper">
				<Row>
					<Text style={{ fontWeight: 600, fontSize: 16 }}>
						Telemedicine Rescheduling
					</Text>
				</Row>
				<Row
					align="middle"
					justify="space-between"
					style={{
						background: COLORS.ash_200,
						borderRadius: 8,
						padding: '20px 12px',
					}}
				>
					<Col>
						<Row align="middle" gutter={10}>
							<Col style={{ paddingTop: '6px' }}>
								<IconCalendar width={15} />
							</Col>
							<Col>
								<Text style={{ fontSize: 14 }}>
									{moment(oldStartAt, 'YYYY-MM-DD').format('MMM DD, ')}
									{moment(oldStartAt).format('HH:mm')}
									{' - '}
									{moment(oldEndAt).format('HH:mm')}
								</Text>
							</Col>
						</Row>
					</Col>
					<Col className="icon-wrapper">
						<ArrowRight
							style={{ width: 16, height: 16, color: '#fff', fontWeight: 900 }}
						/>
					</Col>
					<Col>
						<Row align="middle" gutter={10}>
							<Col style={{ paddingTop: '6px' }}>
								<IconCalendar width={15} />
							</Col>
							<Col>
								<Text style={{ fontSize: 14 }}>
									{moment(newStartAt, 'YYYY-MM-DD').format('MMM DD, ')}
									{moment(newStartAt).format('HH:mm')}
									{' - '}
									{moment(newEndAt).format('HH:mm')}
								</Text>
							</Col>
						</Row>
					</Col>
				</Row>
			</div>
		);
	};

	// Views Based on Event Types

	const ViewTelemedicineBefore = () => {
		const item = detail as TransactionConsultationProperties;
		console.log('item', item);
		const items = [
			{
				key: '1',
				label: (
					<div
						className="flex items-center"
						onClick={() =>
							history.push(
								`/app/schedule/new/${item.transactionConsultationId}/${1}`,
							)
						}
					>
						<IconEditOutline
							style={{ marginRight: 10 }}
							width={15}
							height={15}
						/>
						<Text style={{ fontSize: 14 }}>Edit Telemedicine</Text>
					</div>
				),
			},
			{
				key: '2',
				label: (
					<div
						className="flex items-center"
						onClick={() =>
							handleDeletePersonAppointment(item.transactionConsultationId)
						}
					>
						<IconDeleteOutline
							style={{ marginRight: 10 }}
							width={15}
							height={15}
						/>
						<Text style={{ fontSize: 14 }}>Delete Telemedicine</Text>
					</div>
				),
			},
		];
		return (
			<>
				<PageHeader
					title={uppercase(`Detail ${activePath.replace('/app/', '')}`)}
					onClick={() => {
						history.goBack();
					}}
					rightmostChild={
						<Dropdown
							menu={{ items }}
							placement="bottomRight"
							overlayStyle={{ width: '180px' }}
						>
							<Button type="text" style={{ padding: 0 }}>
								<MoreOutlined />
							</Button>
						</Dropdown>
					}
				/>

				<div
					style={{
						marginRight: '-24px',
						marginLeft: '-24px',
						marginBottom: 16,
					}}
				>
					<ComponentBanner />
				</div>
				{TagStatus()}
				{item?.transactionStatus?.valueOf() ===
					ETransactionStatusType.REJECTED_CHANGE_SCHEDULE && (
					<ComponentRescheduling />
				)}

				<Row justify="space-between">
					{ComponentPatientProfile()}
					{ComponentDateTime()}
				</Row>
				<Divider />
				{ComponentReasonForConsultation()}
				<Divider />

				{item?.transactionStatus?.valueOf() ===
					ETransactionStatusType.APPROVED && (
					<>
						<ConsultationBefore />
						<Divider />
					</>
				)}

				{item?.transactionStatus?.valueOf() ===
					ETransactionStatusType.WAITING_APPROVAL ||
				item?.transactionStatus?.valueOf() ===
					ETransactionStatusType.REJECTED_CHANGE_SCHEDULE ? (
					<>
						{ScheduleList()}
						{parseDateSelection?.detail?.length > 0 && <Divider />}
					</>
				) : null}

				{item?.transactionStatus?.valueOf() ===
				ETransactionStatusType.APPROVED ? (
					<>
						<Row justify="end">
							<ButtonJoin
								type="primary"
								onClick={() => {
									if (item.scheduledStartAt) {
										if (isNowTheDay(item.scheduledStartAt?.toString())) {
											history.push(`/app/consultation/${initialData.id}`);
										} else {
											message.info(
												`This telemedicine are scheduled at ${moment(
													item.scheduledStartAt,
												).format('DD MMM YYYY')}`,
											);
										}
									} else {
										history.push(`/app/consultation/${initialData.id}`);
									}
								}}
							>
								Start Telemedicine
							</ButtonJoin>
						</Row>
					</>
				) : null}
				{ApproveReject()}
			</>
		);
	};

	const ViewTelemedicineAfter = () => {
		return (
			<>
				<PageHeader
					title={uppercase(`Detail ${activePath.replace('/app/', '')}`)}
					onClick={() => {
						history.goBack();
					}}
				/>
				<div
					style={{
						marginRight: '-24px',
						marginLeft: '-24px',
						marginBottom: 16,
					}}
				>
					<ComponentBanner />
				</div>
				{TagStatus()}
				<Divider style={{ margin: '6px 0px' }} />
				<Row justify="space-between">
					{ComponentPatientProfile()}
					{ComponentDateTime()}
				</Row>
				<Divider />
				{ComponentReasonForConsultation()}
				<Divider />
				<ConsultationAfter />
				<Divider />
			</>
		);
	};

	const handleDeletePersonAppointment = async (id: any) => {
		console.log('id', id);
		try {
			//transaction-consults
			//const res = await httpRequest.delete(`/personal-consults/${id}`);
			const res = await httpRequest.patch(`/transaction-consults/${id}`, {
				transactionStatus: ETransactionStatusType.CANCELED_BY_DOCTOR_OR_SECRETARY,
			}
			)
			console.log('id', id);
			message.success('Success in delete personal consults');
			history.push('/app/schedule');
		} catch (error) {
			message.error('Failed to delete personal consults');
			console.log(error);
		}
	};

	const ViewInPersonAppointmentBefore = () => {
		const item = detail as TransactionConsultationProperties;
		console.log('item', item);
		const items = [
			{
				key: '1',
				label: (
					<div
						className="flex items-center"
						onClick={() =>
							history.push(
								`/app/schedule/new/${item.transactionConsultationId}/${2}`,
							)
						}
					>
						<IconEditOutline
							style={{ marginRight: 10 }}
							width={15}
							height={15}
						/>
						<Text style={{ fontSize: 14 }}>Edit Appointment</Text>
					</div>
				),
			},
			{
				key: '2',
				label: (
					<div
						className="flex items-center"
						onClick={() =>
							handleDeletePersonAppointment(item.transactionConsultationId)
						}
					>
						<IconDeleteOutline
							style={{ marginRight: 10 }}
							width={15}
							height={15}
						/>
						<Text style={{ fontSize: 14 }}>Delete Appointment</Text>
					</div>
				),
			},
		];

		return (
			<>
				<PageHeader
					title={uppercase(`Detail ${activePath.replace('/app/', '')}`)}
					onClick={() => {
						history.goBack();
					}}
					rightmostChild={
						<Dropdown
							menu={{ items }}
							placement="bottomRight"
							overlayStyle={{ width: '180px' }}
						>
							<Button type="text" style={{ padding: 0 }}>
								<MoreOutlined />
							</Button>
						</Dropdown>
					}
				/>
				<div
					style={{
						marginRight: '-24px',
						marginLeft: '-24px',
						marginBottom: 16,
					}}
				>
					<ComponentBanner />
				</div>
				{TagStatus()}
				{item?.transactionStatus?.valueOf() ===
					ETransactionStatusType.REJECTED_CHANGE_SCHEDULE && (
					<ComponentRescheduling />
				)}
				{ComponentPlace()}
				<Row
					justify="space-between"
					style={{ paddingTop: 10, paddingBottom: 10 }}
				>
					{ComponentPatientProfile()}
					{ComponentDateTime()}
				</Row>
				{ComponentReasonForConsultation()}
				<Divider />
				{item?.transactionStatus?.valueOf() ===
					ETransactionStatusType.APPROVED && (
					<>
						<ConsultationBefore />
						<Divider />
					</>
				)}

				{item?.transactionStatus?.valueOf() ===
					ETransactionStatusType.WAITING_APPROVAL ||
				item?.transactionStatus?.valueOf() ===
					ETransactionStatusType.REJECTED_CHANGE_SCHEDULE ? (
					<>
						{ScheduleList()}
						{parseDateSelection?.detail?.length > 0 && <Divider />}
					</>
				) : null}

				{item?.transactionStatus?.valueOf() ===
				ETransactionStatusType.APPROVED ? (
					<>
						<Row justify="end">
							<ButtonJoin
								type="primary"
								onClick={() => {
									if (item.scheduledStartAt) {
										if (isNowTheDay(item.scheduledStartAt?.toString())) {
											history.push(`/app/consultation/${initialData.id}`);
										} else {
											message.info(
												`This telemedicine are scheduled at ${moment(
													item.scheduledStartAt,
												).format('DD MMM YYYY')}`,
											);
										}
									} else {
										history.push(`/app/consultation/${initialData.id}`);
									}
								}}
							>
								Start Consultation
							</ButtonJoin>
						</Row>
					</>
				) : null}
				{ApproveReject()}
			</>
		);
	};

	const ViewInPersonAppointmentAfter = () => {
		return (
			<>
				<PageHeader
					title={uppercase(`Detail ${activePath.replace('/app/', '')}`)}
					onClick={() => {
						history.goBack();
					}}
				/>
				<div
					style={{
						marginRight: '-24px',
						marginLeft: '-24px',
						marginBottom: 16,
					}}
				>
					<ComponentBanner />
				</div>
				{TagStatus()}
				{ComponentPlace()}

				<Divider style={{ margin: '6px 0px' }} />
				<Row justify="space-between">
					{ComponentPatientProfile()}
					{ComponentDateTime()}
				</Row>
				<Divider />
				{ComponentReasonForConsultation()}
				<Divider />
				<ConsultationAfter />
				<Divider />
			</>
		);
	};

	const ViewConsultNowBefore = () => {
		const item = detail as TransactionConsultationProperties;

		return (
			<>
				<PageHeader
					title={uppercase(`Detail ${activePath.replace('/app/', '')}`)}
					onClick={() => {
						history.goBack();
					}}
				/>
				<div
					style={{
						marginRight: '-24px',
						marginLeft: '-24px',
						marginBottom: 16,
					}}
				>
					<ComponentBanner />
				</div>
				{TagStatus()}
				{item?.transactionStatus?.valueOf() ===
					ETransactionStatusType.REJECTED_CHANGE_SCHEDULE && (
					<ComponentRescheduling />
				)}

				<Row justify="space-between">
					{ComponentPatientProfile()}
					{ComponentDateTime()}
				</Row>
				<Divider />
				{ComponentReasonForConsultation()}
				<Divider />

				{item?.transactionStatus?.valueOf() ===
					ETransactionStatusType.APPROVED && (
					<>
						<ConsultationBefore />
						<Divider />
					</>
				)}

				{item?.transactionStatus?.valueOf() ===
					ETransactionStatusType.WAITING_APPROVAL ||
				item?.transactionStatus?.valueOf() ===
					ETransactionStatusType.REJECTED_CHANGE_SCHEDULE ? (
					<>
						{ScheduleList()}
						{parseDateSelection?.detail?.length > 0 && <Divider />}
					</>
				) : null}

				{item?.transactionStatus?.valueOf() ===
				ETransactionStatusType.APPROVED ? (
					<>
						<Row justify="end">
							<ButtonJoin
								type="primary"
								onClick={() => {
									if (item.scheduledStartAt) {
										if (isNowTheDay(item.scheduledStartAt?.toString())) {
											history.push(`/app/consultation/${initialData.id}`);
										} else {
											message.info(
												`This telemedicine are scheduled at ${moment(
													item.scheduledStartAt,
												).format('DD MMM YYYY')}`,
											);
										}
									} else {
										history.push(`/app/consultation/${initialData.id}`);
									}
								}}
							>
								Start Telemedicine
							</ButtonJoin>
						</Row>
					</>
				) : null}
				{ApproveReject()}
			</>
		);
	};

	const ViewConsultNowAfter = () => {
		return (
			<>
				<PageHeader
					title={uppercase(`Detail ${activePath.replace('/app/', '')}`)}
					onClick={() => {
						history.goBack();
					}}
				/>
				<div
					style={{
						marginRight: '-24px',
						marginLeft: '-24px',
						marginBottom: 16,
					}}
				>
					<ComponentBanner />
				</div>
				{TagStatus()}
				<Divider style={{ margin: '6px 0px' }} />
				<Row justify="space-between">
					{ComponentPatientProfile()}
					{ComponentDateTime()}
				</Row>
				<Divider />
				{ComponentReasonForConsultation()}
				<Divider />
				<ConsultationAfter />
				<Divider />
			</>
		);
	};

	const handleDeletePersonalEvent = async (id: any) => {
		console.log('id', id);
		try {
			const res = await httpRequest.delete(`/personal-events/${id}`);
			message.success('Success in delete personal event');
			history.push('/app');
		} catch (error) {
			message.error('Failed to delete personal events');
			console.log(error);
		}
	};

	const ViewPersonalEvent = () => {
		const detailData = detail as PersonalEvent;
		const desc = detailData?.description || '-';

		const items = [
			{
				key: '1',
				label: (
					<div
						className="flex items-center"
						onClick={() =>
							history.push(`/app/schedule/new/${detailData.eventId}/${0}`)
						}
					>
						<IconEditOutline
							style={{ marginRight: 10 }}
							width={15}
							height={15}
						/>
						<Text style={{ fontSize: 14 }}>Edit Event</Text>
					</div>
				),
			},
			{
				key: '2',
				label: (
					<div
						className="flex items-center"
						onClick={() => handleDeletePersonalEvent(detailData.eventId)}
					>
						<IconDeleteOutline
							style={{ marginRight: 10 }}
							width={15}
							height={15}
						/>
						<Text style={{ fontSize: 14 }}>Delete Event</Text>
					</div>
				),
			},
		];
		return (
			<>
				<PageHeader
					title={uppercase(`Detail ${activePath.replace('/app/', '')}`)}
					onClick={() => {
						history.goBack();
					}}
					rightmostChild={
						<Dropdown
							menu={{ items }}
							placement="bottomRight"
							overlayStyle={{ width: '180px' }}
						>
							<Button type="text" style={{ padding: 0 }}>
								<MoreOutlined />
							</Button>
						</Dropdown>
					}
				/>
				<div style={{ marginRight: '-24px', marginLeft: '-24px' }}>
					<ComponentBanner />
				</div>

				<Title
					level={5}
					style={{
						fontWeight: 500,
						paddingTop: 24,
						marginBottom: 0,
						fontSize: 24,
					}}
				>
					{detailData?.title}
				</Title>

				<Divider style={{ marginTop: 12, marginBottom: 12 }} />
				{ComponentDateTime()}
				<Divider />

				<Row>
					<Text style={{ fontSize: 16, paddingBottom: 8, fontWeight: 500 }}>
						Description
					</Text>
				</Row>

				<Paragraph style={{ fontSize: 14 }}>{desc}</Paragraph>
			</>
		);
	};

	const ViewPersonalEventBlocked = () => {
		const detailData = detail as PersonalEvent;
		const desc = detailData?.description || '-';

		const items = [
			{
				key: '1',
				label: (
					<div
						className="flex items-center"
						onClick={() =>
							history.push(`/app/schedule/new/${detailData.eventId}/${0}`)
						}
					>
						<IconEditOutline
							style={{ marginRight: 10 }}
							width={15}
							height={15}
						/>
						<Text style={{ fontSize: 14 }}>Edit Event</Text>
					</div>
				),
			},
			{
				key: '2',
				label: (
					<div
						className="flex items-center"
						onClick={() => handleDeletePersonalEvent(detailData.eventId)}
					>
						<IconDeleteOutline
							style={{ marginRight: 10 }}
							width={15}
							height={15}
						/>
						<Text style={{ fontSize: 14 }}>Delete Event</Text>
					</div>
				),
			},
		];

		return (
			<>
				<PageHeader
					title={uppercase(`Detail ${activePath.replace('/app/', '')}`)}
					onClick={() => {
						history.goBack();
					}}
					rightmostChild={
						<Dropdown
							menu={{ items }}
							placement="bottomRight"
							overlayStyle={{ width: '180px' }}
						>
							<Button type="text" style={{ padding: 0 }}>
								<MoreOutlined />
							</Button>
						</Dropdown>
					}
				/>
				<div style={{ marginRight: '-24px', marginLeft: '-24px' }}>
					<ComponentBanner />
				</div>

				<Title
					level={5}
					style={{
						fontWeight: 500,
						paddingTop: 24,
						marginBottom: 0,
						fontSize: 24,
					}}
				>
					{detailData?.title}
				</Title>
				<Divider style={{ marginTop: 12, marginBottom: 12 }} />
				{ComponentDateTime()}
				<Divider />
				<Row>
					<Text style={{ fontSize: 16, paddingBottom: 8, fontWeight: 500 }}>
						Description
					</Text>
				</Row>
				<Paragraph style={{ fontSize: 14 }}>{desc}</Paragraph>
				{ComponentBlockingWarning()}
			</>
		);
	};

	const ViewMedicalService = () => {
		return (
			<>
				<PageHeader
					title={uppercase(`Detail ${activePath.replace('/app/', '')}`)}
					onClick={() => {
						history.goBack();
					}}
				/>
				<div style={{ marginRight: '-24px', marginLeft: '-24px' }}>
					<ComponentBanner />
				</div>

				<ComponentMedicalServicePackage />
				<Divider />
				<Row justify="space-between">
					<ComponentItems />
					{ComponentPlace()}
				</Row>
				<Divider />
				<Row justify="space-between">
					{ComponentPatientProfile()}
					{ComponentDateTime()}
				</Row>
				<Divider />
				<Row>
					<Text style={{ fontSize: 16, paddingBottom: 8, fontWeight: 500 }}>
						Payment
					</Text>
				</Row>
				{ComponentConsultationButtonCard(
					<IconReceiptOutline />,
					() => {},
					'Payment Receipt',
					'View the payment receipt',
				)}
			</>
		);
	};

	const RenderView = () => {
		const detailData = detail as TransactionConsultationProperties;

		if (
			scheduleType?.valueOf() === EScheduleTypes.telemedicine ||
			scheduleType?.valueOf() === EScheduleTypes['telemedicine-personal']
		) {
			if (detailData?.transactionStatus === ETransactionStatusType.COMPLETED) {
				return ViewTelemedicineAfter();
			} else {
				return ViewTelemedicineBefore();
			}
		} else if (
			scheduleType?.valueOf() === EScheduleTypes.appointment ||
			scheduleType?.valueOf() === EScheduleTypes['appointment-personal']
		) {
			if (detailData?.transactionStatus === ETransactionStatusType.COMPLETED) {
				return ViewInPersonAppointmentAfter();
			} else {
				return ViewInPersonAppointmentBefore();
			}
		} else if (scheduleType?.valueOf() === EScheduleTypes['consult-now']) {
			if (detailData?.transactionStatus === ETransactionStatusType.COMPLETED) {
				return ViewConsultNowAfter();
			} else {
				return ViewConsultNowBefore();
			}
		} else if (scheduleType?.valueOf() === EScheduleTypes['personal-event']) {
			return ViewPersonalEvent();
		} else if (
			scheduleType?.valueOf() === EScheduleTypes['personal-event-blocked']
		) {
			return ViewPersonalEventBlocked();
		} else if (scheduleType?.valueOf() === EScheduleTypes['medical-service']) {
			return ViewMedicalService();
		}
	};

	return (
		<AppLayout activePath={activePath}>
			<Card>{RenderView()}</Card>
			<ModalApproveReschedule
				isVisible={isModalApproveRescheduleVisible}
				setVisibility={setIsModalApproveRescheduleVisible}
				actionHandler={() => {
					updateTransactionConsult(scheduleId, {
						transactionStatus: ETransactionStatusType.APPROVED,
					});
				}}
			/>
			<ModalRejectReschedule
				isVisible={isModalRejectRescheduleVisible}
				setVisibility={setIsModalRejectRescheduleVisible}
				actionHandler={() => {
					updateTransactionConsult(scheduleId, {
						transactionStatus: ETransactionStatusType.REJECTED,
					});
				}}
			/>
			<ModalAcceptConsultation
				isVisible={isModalAcceptConsulVisible}
				setVisibility={setIsModalAcceptConsulVisible}
				actionHandler={() => {
					updateTransactionConsult(scheduleId, {
						transactionStatus: ETransactionStatusType.APPROVED,
					});
				}}
			/>
			<ModalRejectConsultation
				isVisible={isModalRejectConsulVisible}
				setVisibility={setIsModalRejectConsulVisible}
				transactionConsultationId={initialData.id}
				setFetchTrue={setFetchTrue}
			/>
		</AppLayout>
	);
}

// Custom Styles

const CustomComponentConsultationButtonCard = styled(Card)`
	height: 140px;
	width: 205px;
	margin: 6px 12px 6px 0;
	cursor: pointer;
	div.ant-card-body {
		padding: 12px;
	}
	.icon-background {
		background-color: #f1f3f5;
		width: 40px;
		height: 40px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 12px;
	}
	&:hover {
		border-color: #d81f64;
		strong {
			color: #d81f64;
		}
		.icon-background {
			background-color: rgba(253, 218, 231, 0.5);
			path {
				stroke: #d81f64 !important;
				fill: none !important;
			}
			svg#icon-cancel-outline {
				path {
					fill: #d81f64 !important;
					stroke: none !important;
				}
			}
		}
		path {
			fill: #d81f64;
		}
	}
`;

const CustomComponentAttachmentButtonCard = styled(Card)`
	width: 100%;
	border: 1px solid #d5dce1;
	border-radius: 16px;
	cursor: pointer;
	margin: 6px 0 6px 0;
	div.ant-card-body {
		padding: 12px;
	}
	.icon-background {
		background-color: #f1f3f5;
		width: 40px;
		height: 40px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 10px;
	}
	&:hover {
		.icon-background {
			background-color: rgba(253, 218, 231, 0.5);
			svg#icon-lab-request {
				path {
					stroke: #d81f64 !important;
					fill: none !important;
				}
			}
			path {
				fill: #d81f64 !important;
			}
		}
	}
`;

const CustomBlockingCard = styled(Card)`
	padding: 12px;
	background-color: #fff9ea;
	border-radius: 16px;
	width: 100%;
	border-color: #fff9ea;
	div.ant-card-body {
		padding: 0;
	}
`;

const CustomTagPaid = styled(Tag)`
	background-color: rgba(38, 142, 108, 0.25);
	border: 0.5px solid #268e6c;
	color: #268e6c;
	width: 49px;
	height: 19px;
	padding: 4px 6px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border-radius: 20px;
`;

const CustomTagUnpaid = styled(Tag)`
	background-color: #f1f3f5;
	border: 0.5px solid #b7c2cc;
	color: #b7c2cc;
	width: 49px;
	height: 19px;
	padding: 4px 6px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border-radius: 20px;
`;

const ButtonJoin = styled(Button)`
	width: 180px;
	height: 40px;
	border-radius: 12px;
	border: none;
	span {
		font-size: 14px;
		font-weight: 700;
		color: ${(props) => props.theme.colors.white};
	}
	&:disabled {
		span {
			color: ${(props) => props.theme.colors.ash700};
		}
	}
`;
