import { useRef } from 'react';

import styled from 'styled-components';
import { useAuth } from '../../../context/auth.context';

interface Props {
	uri: string;
	print: boolean;
}

export default function WebPDF(props: Props) {
	const { token } = useAuth();

	const iframe = useRef<HTMLIFrameElement>(null);

	const uri = `${props.uri}?token=${token}&print=${props.print}`;

	console.log(uri);

	return (
		<CustomIframe
			ref={iframe}
			src={uri}
			width="100%"
			height="100%"
			loading="lazy"
			className="border-none relative z-50 overflow-scroll"
		/>
	);
}

const CustomIframe = styled.iframe`
	min-height: 1200px;
`;
