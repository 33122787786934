import React from 'react';
import { Button, Col, Modal, Row, Typography } from 'antd';

type Props = {
	isVisible: boolean;
	setVisibility: (state: boolean) => void;
	actionHandler?: (data: any) => any;
	dataPatient: {
		patientName: string;
		age: string;
		gender: 'male' | 'female';
	};
	dataRelatives: {
		patientName: string;
		age: string;
		gender: 'male' | 'female';
	}[];
};

const { Text } = Typography;

export default function ModalInfoPatient(props: Props) {
	return (
		<Modal
			className="modal"
			open={props.isVisible}
			footer={null}
			closable={false}
		>
			<div className="">
				<Row className="flex justify-center">
					<Text className="text-6">Choose Data</Text>
				</Row>
				<Row className="flex justify-center mt-2 mb-6">
					<Text className="text-4.5">
						Please choose patient / relatives data for this document
					</Text>
				</Row>
				{/* <Row>
					<Text style={{ marginBottom: 4 }} className="text-2">
						Patient Name: {props.dataPatient?.patientName ?? '-'}
					</Text>
				</Row>
				<Row>
					<Text style={{ marginBottom: 4 }} className="text-2">
						Patient Age: {props.dataPatient?.age ?? '-'} y.o
					</Text>
				</Row>{' '}
				<Row>
					<Text style={{ marginBottom: 4 }} className="text-2">
						Gender: {props.dataPatient?.gender ?? '-'}
					</Text>
				</Row> */}
				<Row gutter={24} className="mt-2.5 flex justify-center items-center">
					<Col span={12}>
						<Text
							style={{ marginBottom: 4, display: 'flex', flex: 1 }}
							className="text-2"
						>
							{props.dataPatient?.patientName}
						</Text>
					</Col>
					<Col>
						<Button
							type="ghost"
							size="large"
							onClick={() => props.actionHandler?.(props.dataPatient)}
							className="rounded-3 w-32"
							style={{ fontSize: 16, fontWeight: 700 }}
						>
							Select
						</Button>
					</Col>
				</Row>
				{props.dataRelatives?.map((item) => (
					<Row
						gutter={24}
						className="mt-2.5 flex justify-center items-center"
						key={item.patientName}
					>
						<Col span={12}>
							<Text
								style={{ marginBottom: 4, display: 'flex', flex: 1 }}
								className="text-2"
							>
								{item.patientName} - Relative
							</Text>
						</Col>
						<Col>
							<Button
								type="ghost"
								size="large"
								onClick={() => props.actionHandler?.(item)}
								className="rounded-3 w-32"
								style={{ fontSize: 16, fontWeight: 700 }}
							>
								Select
							</Button>
						</Col>
					</Row>
				))}
			</div>
			{/* <Divider style={{ margin: '12px 0px' }} /> */}

			{/* <Row gutter={24} className="mt-2.5">
				<Col span={12}>
					<Button
						type="text"
						size="large"
						onClick={cancelReject}
						className="text-3.5 text-secondary rounded-3 bg-gray-10 border-0 w-full font-bold"
					>
						<Text style={{ fontSize: 14, fontWeight: 700 }}>Cancel</Text>
					</Button>
				</Col>
				<Col span={12}>
					<Button
						type="primary"
						size="large"
						onClick={props.actionHandler}
						className="rounded-3 bg-primary w-full"
					>
						<Text style={{ fontSize: 14, fontWeight: 700, color: '#fff' }}>
							Yes, Sure
						</Text>
					</Button>
				</Col>
			</Row> */}
		</Modal>
	);
}
