import moment from 'moment';
import {
	MessageSquare,
	Mic,
	MicOff,
	Phone,
	Video,
	VideoOff,
} from 'react-feather';
import { theme } from '../../../../assets/theme';
import { getName } from '../../../../helpers/name';
import { getBirthdate, getGender } from '../../../../helpers/profile';
import { TransactionConsultationProperties } from '../../../../types/transactionConsultation.type';
import BtnTeleconferenceCtr from '../Components/BtnTeleconferenceCtr';
import { ReactComponent as Collection } from '../../../../assets/icons/collection.svg';
import {
	AudioTrack,
	LocalVideoTrack,
	RemoteParticipant,
	RemoteTrack,
	Room,
	VideoTrack,
} from 'twilio-video';
import { useEffect } from 'react';
import { useTelemedicineCountdown } from '../../../../hooks/useTelemedicineCountdown';

interface Props {
	transactionConsultation?: TransactionConsultationProperties;
	room?: Room;
	localVideoTrack?: LocalVideoTrack;
	isMicOn: boolean;
	isVideoOn: boolean;
	toggleMic: () => void;
	toggleVideo: () => void;
	toggleChat: () => void;
	toggleConsultation: () => void;
	endTeleconference: () => void;
}

export default function TeleconferenceVideo(props: Props) {
	const { timeLeft, previewTimer } = useTelemedicineCountdown(900); // 30 minutes

	useEffect(() => {
		if (!props.localVideoTrack) {
			removeMedia({ id: 'local-media' });
			return;
		}

		addMedia({ track: props.localVideoTrack, id: 'local-media' });
	}, [props.localVideoTrack]);

	useEffect(() => {
		if (props.room) {
			const room = props.room;

			room.on('participantConnected', onParticipantConnected);
			room.participants.forEach((participant) => {
				participant.tracks.forEach((pub) => {
					if (pub.track?.kind === 'audio' || pub.track?.kind === 'video') {
						addMedia({ track: pub.track, id: 'remote-media' });
					}
				});

				participant.on('trackSubscribed', onTrackSubscribed);
				participant.on('trackUnsubscribed', onTrackUnsubscribed);
			});

			return () => {
				room.removeListener('participantConnected', onParticipantConnected);
			};
		}
	}, [props.room]);

	function onParticipantConnected(participant: RemoteParticipant) {
		participant.tracks.forEach((publication) => {
			if (publication) {
				const track = publication.track;
				if (track && (track.kind === 'audio' || track.kind === 'video')) {
					addMedia({ track, id: 'remote-media' });
				}
			}
		});

		participant.on('trackSubscribed', onTrackSubscribed);
		participant.on('trackUnsubscribed', onTrackUnsubscribed);
	}

	function onTrackSubscribed(track: RemoteTrack) {
		if (track.kind === 'video' || track.kind === 'audio') {
			addMedia({ track, id: 'remote-media' });
		}
	}

	function onTrackUnsubscribed(track: RemoteTrack) {
		if (track.kind === 'video' || track.kind === 'audio') {
			removeMedia({ track });
		}
	}

	return (
		<div className="px-3 h-full flex-1 min-w-0 flex flex-col">
			<div className="rounded-6 overflow-hidden relative h-full flex-1 flex items-center justify-center bg-gray-90">
				<div className="px-12 min-h-0 min-w-0 h-full aspect-video">
					<div
						id="remote-media"
						className="h-full w-fit mx-auto bg-gray-70 rounded-3 overflow-hidden"
					/>
				</div>
				<div
					className="absolute top-5 lg:left-auto left-12 lg:right-12 aspect-video rounded-3 overflow-hidden"
					style={{ width: 245 }}
				>
					<div
						id="local-media"
						className="h-full w-full bg-gray-80 overflow-hidden"
					/>
				</div>
				<div className="p-4 absolute w-full bottom-0 flex items-center justify-between">
					<div className="text-white">
						<p className="m-0 font-medium text-6">
							{getName(props.transactionConsultation?.metaPatient)}
						</p>
						<p className="m-0">
							{getGender(props.transactionConsultation?.metaPatient)}{' '}
							<span className="text-gray-60">•</span>{' '}
							{moment().diff(
								getBirthdate(props.transactionConsultation?.metaPatient),
								'years',
								false,
							)}{' '}
							yo
						</p>
					</div>
					<div className="px-3 py-1 rounded-full bg-primary font-medium text-white text-3.5">
						{previewTimer(timeLeft)}
					</div>
				</div>
			</div>
			<div className="h-28 p-6 w-full flex justify-center items-center gap-x-12">
				<BtnTeleconferenceCtr onClick={props.toggleMic}>
					{props.isMicOn ? (
						<Mic width={32} height={32} />
					) : (
						<MicOff width={32} height={32} />
					)}
				</BtnTeleconferenceCtr>
				<BtnTeleconferenceCtr onClick={props.toggleVideo}>
					{props.isVideoOn ? (
						<Video width={32} height={32} />
					) : (
						<VideoOff width={32} height={32} />
					)}
				</BtnTeleconferenceCtr>
				<BtnTeleconferenceCtr onClick={props.endTeleconference} bg="bg-primary">
					<Phone width={32} height={32} color={theme.colors.white} />
				</BtnTeleconferenceCtr>
				<BtnTeleconferenceCtr onClick={props.toggleChat}>
					<MessageSquare width={32} height={32} />
				</BtnTeleconferenceCtr>
				<BtnTeleconferenceCtr onClick={props.toggleConsultation}>
					<Collection width={32} height={32} />
				</BtnTeleconferenceCtr>
			</div>
		</div>
	);
}

function removeMedia({
	track,
	id,
}: {
	track?: VideoTrack | AudioTrack;
	id?: 'local-media' | 'remote-media';
}) {
	if (track) {
		track.detach().forEach((element) => element.remove());
	} else if (id) {
		document.getElementById(id)!.innerHTML = '';
	}
}

function addMedia({
	track,
	id,
}: {
	track: VideoTrack | AudioTrack;
	id: 'local-media' | 'remote-media';
}) {
	document.getElementById(id)?.appendChild(track.attach());
	document
		.getElementById(id)
		?.getElementsByTagName('video')
		.item(0)
		?.classList.add('block', 'h-full', 'w-min', 'mx-auto');
}
