import { createGlobalState } from 'react-hooks-global-state';
import { ISchedule } from '../types/schedule.type';
import merge from 'deepmerge';

type IInitialState = {
	isLoading?: boolean;
	isLoadingAction?: boolean;
	schedules: ISchedule[];
};

const initialState: IInitialState = {
	isLoading: false,
	isLoadingAction: false,
	schedules: [],
};
const { useGlobalState } = createGlobalState(initialState);

export default function useSchedulesNewData() {
	const [isLoading, setIsLoading] = useGlobalState('isLoading');
	const [isLoadingAction, setIsLoadingAction] =
		useGlobalState('isLoadingAction');

	const [schedules, setSchedules] = useGlobalState('schedules');

	const mergeSchedules = async (data: ISchedule[]) => {
		console.log('===> mergeSchedules', data.length);

		setSchedules(data);
	};

	return {
		schedules,
		isLoading,
		isLoadingAction,

		setIsLoading,
		setIsLoadingAction,
		setSchedules,

		mergeSchedules,
	};
}

export function combineMerge(target: any[], source: any[], options: any) {
	const destination = target.slice();

	source.forEach((item, index) => {
		if (typeof destination[index] === 'undefined') {
			destination[index] = options.cloneUnlessOtherwiseSpecified(item, options);
		} else if (options.isMergeableObject(item)) {
			destination[index] = merge(target[index], item, options);
		} else if (target.indexOf(item) === -1) {
			destination.push(item);
		}
	});
	return destination;
}
