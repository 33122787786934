import { message } from 'antd';
import { httpRequest } from '../helpers/api';
import { getErrorMessage } from '../helpers/errorHandler';
import { ApiResponseDefault } from '../types/apiResponse.type';
import { LabRequestTemplateProperties } from '../types/templateLabRequest';

export async function apiGetTemplateLabRequest(templateLabRequestId: string) {
	try {
		const res = await httpRequest.get<
			ApiResponseDefault<LabRequestTemplateProperties>
		>('/template-lab-request/' + templateLabRequestId);
		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}

export async function apiCreateTemplateLabRequest(
	data: LabRequestTemplateProperties,
) {
	try {
		const res = await httpRequest.post<
			ApiResponseDefault<LabRequestTemplateProperties>
		>('template-lab-request', data);
		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}

export async function apiUpdateTemplateLabRequest(
	templateLabRequestId: string,
	data: Partial<LabRequestTemplateProperties>,
) {
	try {
		const res = await httpRequest.patch<
			ApiResponseDefault<LabRequestTemplateProperties>
		>('/template-lab-request/' + templateLabRequestId, data);
		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}

export async function apiDeleteTemplateLabRequest(
	templateLabRequestId: string,
) {
	try {
		const res = await httpRequest.delete<
			ApiResponseDefault<LabRequestTemplateProperties>
		>('/template-lab-request/' + templateLabRequestId);
		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}
