import { Card, Col, Row, Typography } from 'antd';
import {
	iconChevronBlack as IconChevron,
	iconMensCalendar as IconMensCalendar,
	iconBMICalc as IconBMICalc,
	iconPregnancyConsult as IconPregnancyConsult,
} from '../../../assets/icons';
import React, { useMemo } from 'react';
import COLORS from '../../../assets/globalColors';
import './styles.less';

import moment from 'moment';
import { dueDate } from '../../../constants/pregnancyTracker';
import { useHistory } from 'react-router-dom';

type PregnancyData = {
	scanDate: string;
	weekAge: number;
	dayAge: number;
};

export default function PregnancyResult({
	pregnancyData,
}: {
	pregnancyData: PregnancyData;
}) {
	const history = useHistory();
	const pregnancyWeeks = useMemo(() => {
		const date = new Date(new Date().toDateString());
		const scanDate = new Date(new Date(pregnancyData.scanDate).toDateString());
		return (
			pregnancyData.weekAge +
			Math.floor((date.getDate() - scanDate.getDate()) / 7)
		);
	}, [pregnancyData]);

	const meetBabyOn = useMemo(() => {
		const babyDayAge = pregnancyData.weekAge * 7 + pregnancyData.dayAge;
		const babyRemainingBornDay = 40 * 7 - babyDayAge;
		const scanDate = new Date(pregnancyData.scanDate);
		const babyBornDate = scanDate.setDate(
			scanDate.getDate() + babyRemainingBornDay,
		);
		return moment(babyBornDate).format('MMMM DD, YYYY');
	}, [pregnancyData]);

	const progressBarBabyAge = useMemo(() => {
		const babyDayAge = pregnancyData.weekAge * 7 + pregnancyData.dayAge;
		const date = new Date(new Date().toDateString());
		const scanDate = new Date(new Date(pregnancyData.scanDate).toDateString());
		return (
			((babyDayAge + (date.getDate() - scanDate.getDate())) / (40 * 7)) * 100
		);
	}, [pregnancyData]);

	const listCalculator = useMemo(
		() => [
			{
				title: 'BMI Calculator',
				icon: (
					<IconBMICalc width={40} height={40} style={{ marginBottom: 16 }} />
				),
				path: '/app/medical-calculator/bmi',
			},
			{
				title: 'Menstruation Calendar',
				icon: (
					<IconMensCalendar
						width={40}
						height={40}
						style={{ marginBottom: 16 }}
					/>
				),
				path: '/app/medical-calculator/menstruation',
			},
		],
		[],
	);

	const ComponentCardCalc = ({
		item,
	}: {
		item: { title: string; icon: any; path: string };
	}) => {
		return (
			<div
				className="card-item-calculator"
				onClick={() => history.push(item.path)}
			>
				{item.icon && item.icon}
				<Typography.Text
					style={{
						fontSize: 14,
						textAlign: 'center',
					}}
				>
					{item.title}
				</Typography.Text>
			</div>
		);
	};

	const ComponentIndicator = () => {
		return (
			<div style={{ margin: '20px 0px 50px' }}>
				<div style={{ position: 'relative' }}>
					<div
						style={{
							marginTop: 6,
							padding: '4px 0px',
						}}
					>
						<div
							style={{
								position: 'relative',
								backgroundColor: '#DCDCDC',
								height: 10,
								borderRadius: 5,
								overflow: 'hidden',
							}}
						>
							<div
								style={{
									position: 'absolute',
									backgroundColor: COLORS.primary,
									width: progressBarBabyAge + '%',
									height: 10,
									borderRadius: 5,
								}}
							/>
						</div>
					</div>
					<div
						style={{
							position: 'absolute',
							height: 18,
							width: 2,
							backgroundColor:
								progressBarBabyAge > 33.33
									? COLORS.primary
									: COLORS.primary_black_50,
							left: '33%',
							bottom: 0,
						}}
					/>
					<div
						style={{
							position: 'absolute',
							height: 18,
							width: 2,
							backgroundColor:
								progressBarBabyAge > 66.67
									? COLORS.primary
									: COLORS.primary_black_50,
							right: '33%',
							bottom: 0,
						}}
					/>
				</div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						marginTop: 8,
						position: 'relative',
					}}
				>
					<Typography.Text
						style={{
							position: 'absolute',
							width: '100%',
							textAlign: 'left',
							fontSize: 14,
							color:
								progressBarBabyAge > 33.33
									? COLORS.primary
									: COLORS.primary_black_60,
						}}
					>
						1st Trisemester
					</Typography.Text>
					<Typography.Text
						style={{
							position: 'absolute',
							width: '100%',
							textAlign: 'center',
							fontSize: 14,
							color:
								progressBarBabyAge > 66.67
									? COLORS.primary
									: COLORS.primary_black_60,
						}}
					>
						2nd Trisemester
					</Typography.Text>
					<Typography.Text
						style={{
							position: 'absolute',
							width: '100%',
							textAlign: 'right',
							fontSize: 14,
						}}
					>
						3rd Trisemester
					</Typography.Text>
				</div>
			</div>
		);
	};

	const ComponentCardConsult = () => {
		return (
			<Card style={{ margin: '24px 0px', borderRadius: 12 }}>
				<Row justify="space-between" className="items-center">
					<Col span={23}>
						<Row gutter={11}>
							<Col>
								<IconPregnancyConsult width={42} height={42} />
							</Col>
							<Col>
								<Row>
									<Typography.Text
										style={{
											fontSize: 16,
											color: COLORS.primary,
											fontWeight: 700,
										}}
									>
										Consult with Doctor
									</Typography.Text>
								</Row>
								<Row>
									<Typography.Text
										style={{
											fontSize: 14,
											color: COLORS.black_70,
										}}
									>
										Get medical advices about your pregnancy
									</Typography.Text>
								</Row>
							</Col>
						</Row>
					</Col>
					<Col span={1} style={{ paddingTop: 10 }}>
						<IconChevron />
					</Col>
				</Row>
			</Card>
		);
	};

	return (
		<div className="pregnancy-results">
			<div className="flex flex-col items-center">
				{dueDate[pregnancyWeeks - 1]?.imageUrl ? (
					<div
						className="flex flex-col items-center"
						style={{ marginTop: 30, marginBottom: 30 }}
					>
						<div className="circle-img" style={{ marginBottom: 20 }}>
							<img src={dueDate[pregnancyWeeks - 1].imageUrl!} alt="" />
							<div className="circle"></div>
						</div>

						<Typography.Text
							style={{ fontSize: 20, fontWeight: 700, color: COLORS.primary }}
						>
							You are {pregnancyWeeks} weeks Pregnant
						</Typography.Text>
						<Typography.Text
							style={{
								fontSize: 14,
								color: COLORS.primary_black_60,
							}}
						>
							Your baby is the size of a{' '}
							{dueDate[pregnancyWeeks - 1]?.sizeOf
								? dueDate[pregnancyWeeks - 1]?.sizeOf
								: ''}
						</Typography.Text>
					</div>
				) : (
					<div
						className="circle-text"
						style={{ marginTop: 80, marginBottom: 100 }}
					>
						<Typography.Text
							style={{ fontSize: 20, fontWeight: 700, color: COLORS.primary }}
						>
							You are {pregnancyWeeks} weeks Pregnant
						</Typography.Text>
						<div className="circle"></div>
					</div>
				)}
				<Typography.Text
					style={{ textAlign: 'center', fontSize: 16, padding: '0px 20px' }}
				>
					{dueDate[pregnancyWeeks - 1]?.description
						? dueDate[pregnancyWeeks - 1]?.description
						: ''}
				</Typography.Text>
			</div>
			<Row style={{ marginTop: 32 }}>
				<Typography.Text style={{ fontSize: 16 }}>
					You will meet your baby on :{' '}
					<span style={{ color: COLORS.primary, fontWeight: 700 }}>
						{meetBabyOn}
					</span>
				</Typography.Text>
			</Row>

			<ComponentIndicator />
			<div
				style={{ width: '100%', height: 5, backgroundColor: COLORS.ash_300 }}
			></div>
			<ComponentCardConsult />

			<div>
				<Row style={{ marginBottom: 16 }}>
					<Typography.Text
						style={{
							fontSize: 18,
							color: COLORS.black,
							fontWeight: 600,
						}}
					>
						Track your health with our other tools
					</Typography.Text>
				</Row>
				<Row gutter={16}>
					{listCalculator.map((item, index) => (
						<Col key={index}>
							<ComponentCardCalc item={item} />
						</Col>
					))}
				</Row>
			</div>
		</div>
	);
}
