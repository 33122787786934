import {
	Button,
	Card,
	Col,
	Divider,
	Result,
	Row,
	Spin,
	Tabs,
	Tag,
	Typography,
} from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { iconNoResults as IconEmptyList } from '../../../assets/icons';
import AppLayout from '../../../layout/AppLayout';
import './styles.less';

import {
	iconAppointment as IconAppointment,
	iconCalendar as IconCalendar,
	iconLocation as IconLocation,
	iconPersonalEventBlockedColorize as IconPatient,
	iconStethoscope as IconDoctor,
	iconTelemedicine as IconTelemedicine,
	iconWarningCircle as IconWarningCircle,
} from '../../../assets/icons';
// import useAuth from '../../../hooks/useAuth';
import { getScheduleType } from '../../../constants';
import { useAuth } from '../../../context/auth.context';
import { httpRequest } from '../../../helpers/api';
import { calculateAge } from '../../../helpers/datetime';
import { getName } from '../../../helpers/name';
import { getBirthdate, getGender } from '../../../helpers/profile';
import { getSpecializationText } from '../../../helpers/specialist';
import { capitalizeFirstLetter } from '../../../helpers/text';
import { EConsultationType } from '../../../hooks/useBookConsultation';
import { EScheduleTypes } from '../../../types/schedule.type';
import {
	ETransactionStatusType,
	TransactionConsultationProperties,
} from '../../../types/transactionConsultation.type';

import { ArrowRightOutlined } from '@ant-design/icons';
import COLORS from '../../../assets/globalColors';
import { IPayloadPagination } from '../../../helpers/pagination';
import { IHttpResponse } from '../../../types/pagination.type';

const activePath = '/app/approval';

const { Text, Title } = Typography;

export default function ApprovalScreen() {
	const { userId } = useAuth();
	const history = useHistory();

	console.log(userId);

	const [data, setData] = useState<TransactionConsultationProperties[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [all, setAll] = useState<any[]>([]);
	const [needConfirm, setNeedConfirm] = useState<any[]>([]);
	const [approved, setApproved] = useState<any[]>([]);
	const [rejected, setRejected] = useState<any[]>([]);

	const fetchData = async () => {
		setIsLoading(true);

		try {
			const res: {
				data: IHttpResponse<
					IPayloadPagination<TransactionConsultationProperties>
				>;
			} = await httpRequest.get(
				`${process.env.REACT_APP_BASE_URL}/transaction-consults?doctorId=${userId}&filterTransactionStatuses=WAITING_APPROVAL%2CAPPROVED%2CREJECTED%2CREJECTED_CHANGE_SCHEDULE`,
			);
			setData(res.data.payload.results);
			console.log(res.data.payload.results);
			setIsLoading(false);
			return res.data.payload.results;
		} catch (e) {
			console.log('ERROR: fetchData @ Approval Screen');
			console.log(e);
			setIsLoading(false);
			return false;
		}
	};

	const filterData = async () => {
		setIsLoading(true);
		// await fetchList();
		let data = await fetchData();
		console.log('doctorId::', userId);
		console.log('data from fetchList::', data);

		let tempAll = [];
		let tempNeedConfirm = [];
		let tempApproved = [];
		let tempRejected = [];
		if (data) {
			for (let transaction of data) {
				tempAll.push(transaction);
				if (
					transaction.transactionStatus.valueOf() ===
						ETransactionStatusType.WAITING_APPROVAL ||
					transaction.transactionStatus.valueOf() ===
						ETransactionStatusType.REJECTED_CHANGE_SCHEDULE
				) {
					tempNeedConfirm.push(transaction);
				} else if (
					transaction.transactionStatus.valueOf() ===
					ETransactionStatusType.APPROVED
				) {
					tempApproved.push(transaction);
				} else if (
					transaction.transactionStatus.valueOf() ===
					ETransactionStatusType.REJECTED
				) {
					tempRejected.push(transaction);
				}
			}

			setAll(tempAll);
			setNeedConfirm(tempNeedConfirm);
			setApproved(tempApproved);
			setRejected(tempRejected);
		}

		setIsLoading(false);
	};

	// Components
	const ComponentStatusButton = (item: TransactionConsultationProperties) => {
		const status =
			item.transactionStatus || ETransactionStatusType.WAITING_APPROVAL;
		let title;
		let fontColor;
		let backgroundColor;
		let borderColor;
		if (
			status.valueOf() === ETransactionStatusType.WAITING_APPROVAL ||
			status.valueOf() === ETransactionStatusType.REJECTED_CHANGE_SCHEDULE
		) {
			title = 'Need Confirmation';
			fontColor = '#000000';
			backgroundColor = '#F1F3F5';
			borderColor = '#F1F3F5';
		} else if (status.valueOf() === ETransactionStatusType.APPROVED) {
			title = 'Approved';
			fontColor = '#268E6C';
			backgroundColor = '#FFFFFF';
			borderColor = '#268E6C';
		} else if (status.valueOf() === ETransactionStatusType.REJECTED) {
			title = 'Rejected';
			fontColor = '#B7241D';
			backgroundColor = '#FFFFFF';
			borderColor = '#B7241D';
		}

		return (
			<Tag
				style={{
					color: fontColor,
					backgroundColor: backgroundColor,
					borderColor: borderColor,
					borderWidth: 1,
					borderRadius: 12,
					padding: '8px 12px',
					width: 139,
					display: 'flex',
					justifyContent: 'center',
					fontSize: 14,
				}}
			>
				{title}
			</Tag>
		);
	};

	const ComponentEventTypeDateTime = (
		item: TransactionConsultationProperties,
	) => {
		let eventTitle;
		let eventIcon;

		if (
			item.consultationType.valueOf() ===
				EConsultationType.TELEMEDICINE_CHAT_CONSULTATION ||
			item.consultationType.valueOf() ===
				EConsultationType.TELEMEDICINE_VIDEO_CHAT_CONSULTATION
		) {
			eventTitle = 'Telemedicine';
			eventIcon = (
				<div
					style={{
						backgroundColor: '#D81F64',
						borderRadius: '100%',
						width: 20,
						height: 20,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						marginRight: 5,
					}}
				>
					<IconTelemedicine width={12} />
				</div>
			);
		} else if (
			item.consultationType.valueOf() ===
			EConsultationType.FACE_TO_FACE_CONSULTATION
		) {
			eventTitle = 'In Person';
			eventIcon = (
				<div
					style={{
						backgroundColor: '#2B9CDC',
						borderRadius: '100%',
						width: 20,
						height: 20,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						marginRight: 5,
					}}
				>
					<IconAppointment width={12} />
				</div>
			);
		} else if (
			item.consultationType.valueOf() ===
				EConsultationType.CONSULT_NOW_CHAT_CONSULTATION ||
			item.consultationType.valueOf() ===
				EConsultationType.CONSULT_NOW_VIDEO_CHAT_CONSULTATION
		) {
			eventTitle = 'Consult Now';
			eventIcon = (
				<div
					style={{
						backgroundColor: COLORS.yellowApp,
						borderRadius: '100%',
						width: 20,
						height: 20,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						marginRight: 5,
					}}
				>
					<IconWarningCircle width={12} />
				</div>
			);
		}

		return (
			<>
				<Row style={{ width: '100%' }} align="middle" justify="space-between">
					<Col>
						<Row>
							{eventIcon}
							<Text type="secondary" style={{ fontSize: 14 }}>
								{eventTitle}
							</Text>
						</Row>
					</Col>
					<Col>
						{item.transactionStatus.valueOf() ===
						ETransactionStatusType.REJECTED_CHANGE_SCHEDULE ? (
							<Button
								type="text"
								style={{
									background: '#FEF5F9',
									borderRadius: 30,
									minHeight: 15,
								}}
							>
								<Text style={{ color: COLORS.primary, fontSize: 14 }}>
									Reschedule
								</Text>
							</Button>
						) : (
							<Text type="secondary" style={{ fontSize: 14 }}>
								{item?.scheduledStartAt &&
									moment(item?.scheduledStartAt, 'YYYY-MM-DD').format(
										'MMM DD, ',
									)}
								{item?.scheduledStartAt &&
									moment(item?.scheduledStartAt).format('HH:mm')}
								{' - '}
								{item?.scheduledEndAt &&
									moment(item?.scheduledEndAt).format('HH:mm')}
							</Text>
						)}
					</Col>
				</Row>
			</>
		);
	};

	const ComponentDoctorProfile = (item: TransactionConsultationProperties) => {
		return (
			<>
				<Row style={{ width: '100%' }}>
					<Col style={{ marginRight: 10 }}>
						<IconDoctor />
					</Col>
					<Col>
						<Row>
							<Text style={{ fontSize: 16, fontWeight: 500 }}>
								{getName({
									firstName: item?.metaDoctor?.user?.firstName,
									middleName: item?.metaDoctor?.user?.middleName,
									lastName: item?.metaDoctor?.user?.lastName,
									nickName: item?.metaDoctor?.user?.nickName,
									title: item?.metaDoctor?.title,
								})}
							</Text>
						</Row>
						<Row>
							{item?.metaDoctor?.specialists &&
								item?.metaDoctor?.specialists.length > 0 && (
									<Text
										type="secondary"
										style={{ fontWeight: 500, fontSize: 14 }}
									>
										{getSpecializationText(
											item?.metaDoctor?.specialists,
											item?.metaDoctor?.otherSpecialist,
										)}
									</Text>
								)}
						</Row>
					</Col>
				</Row>
			</>
		);
	};

	const ComponentPatientProfile = (item: TransactionConsultationProperties) => {
		return (
			<>
				<Row style={{ width: '100%' }}>
					<Col style={{ marginRight: 10 }}>
						<IconPatient width={15} />
					</Col>
					<Col>
						<Row>
							<Text style={{ fontSize: 16, fontWeight: 500 }}>
								{getName(item?.metaPatient)}
							</Text>
						</Row>
						<Row align="middle">
							<Text type="secondary" style={{ fontSize: 14 }}>
								{capitalizeFirstLetter(getGender(item?.metaPatient))}
							</Text>
							<div
								style={{
									width: 5,
									height: 5,
									borderRadius: '100%',
									marginLeft: 5,
									marginRight: 5,
									backgroundColor: '#D5DCE1',
								}}
							/>
							<Text type="secondary" style={{ fontSize: 14 }}>
								{calculateAge(getBirthdate(item?.metaPatient)) + ' yo'}
							</Text>
						</Row>
					</Col>
				</Row>
			</>
		);
	};

	const ComponentLocation = (item: TransactionConsultationProperties) => {
		return (
			<>
				<Row gutter={10}>
					<Col style={{ paddingTop: 2 }}>
						<IconLocation />
					</Col>
					<Col>
						<Text type="secondary" style={{ fontSize: 14 }}>
							{item?.metaPartner?.partnerName}
						</Text>
					</Col>
				</Row>
			</>
		);
	};

	const ComponentRescheduleDate = (item: TransactionConsultationProperties) => {
		let oldStartAt, oldEndAt, newStartAt, newEndAt;

		if (
			[
				ETransactionStatusType.REJECTED_CHANGE_SCHEDULE,
				ETransactionStatusType.APPROVED,
				ETransactionStatusType.COMPLETED,
			].includes(item.transactionStatus) &&
			item.history
		) {
			const found = item.history.find(
				(item: any) =>
					item.newData.transactionStatus ===
					ETransactionStatusType.REJECTED_CHANGE_SCHEDULE,
			);

			const type = getScheduleType(item);

			if (
				found &&
				found.newData &&
				(type === EScheduleTypes.telemedicine ||
					type === EScheduleTypes.appointment ||
					type === EScheduleTypes['consult-now'])
			) {
				oldStartAt = found.newData.oldScheduledStartAt;
				oldEndAt = found.newData.oldScheduledEndAt;
				newStartAt = found.newData.newScheduledStartAt;
				newEndAt = found.newData.newScheduledEndAt;
			}
		}

		return (
			<>
				<Row align="middle" gutter={10}>
					<Col>
						<IconCalendar width={15} />
					</Col>
					<Col>
						<Row style={{ paddingBottom: 6 }} gutter={10} align="middle">
							<Col>
								<Text style={{ fontSize: 14 }}>
									{moment(oldStartAt, 'YYYY-MM-DD').format('MMM DD, ')}
									{moment(oldStartAt).format('HH:mm')}
									{' - '}
									{moment(oldEndAt).format('HH:mm')}
								</Text>
							</Col>
							<Col>
								<ArrowRightOutlined style={{ fontSize: 12 }} />
							</Col>
							<Col>
								<Text style={{ fontSize: 14 }}>
									{moment(newStartAt, 'YYYY-MM-DD').format('MMM DD, ')}
									{moment(newStartAt).format('HH:mm')}
									{' - '}
									{moment(newEndAt).format('HH:mm')}
								</Text>
							</Col>
						</Row>
					</Col>
				</Row>
			</>
		);
	};
	// Event Card
	const EventCard = (item: TransactionConsultationProperties) => {
		let type: EScheduleTypes;
		let id = item.transactionConsultationId;

		if (
			item.consultationType.valueOf() ===
				EConsultationType.TELEMEDICINE_CHAT_CONSULTATION ||
			item.consultationType.valueOf() ===
				EConsultationType.TELEMEDICINE_VIDEO_CHAT_CONSULTATION
		) {
			type = EScheduleTypes.telemedicine;
		} else if (
			item.consultationType.valueOf() ===
			EConsultationType.FACE_TO_FACE_CONSULTATION
		) {
			type = EScheduleTypes.appointment;
		} else if (
			item.consultationType.valueOf() ===
				EConsultationType.CONSULT_NOW_CHAT_CONSULTATION ||
			item.consultationType.valueOf() ===
				EConsultationType.CONSULT_NOW_VIDEO_CHAT_CONSULTATION
		) {
			type = EScheduleTypes['consult-now'];
		}
		return (
			<div
				className="event-card"
				onClick={() => {
					history.push('/app/schedule/details', {
						type: type,
						id: id,
						from: activePath,
					});
				}}
			>
				{ComponentStatusButton(item)}
				{ComponentEventTypeDateTime(item)}
				{ComponentDoctorProfile(item)}
				{ComponentPatientProfile(item)}
				{item.transactionStatus.valueOf() ===
					ETransactionStatusType.REJECTED_CHANGE_SCHEDULE &&
					ComponentRescheduleDate(item)}
				{item.consultationType.valueOf() ===
					EConsultationType.FACE_TO_FACE_CONSULTATION &&
					item.metaPartner?.partnerName &&
					ComponentLocation(item)}
			</div>
		);
	};

	useEffect(() => {
		filterData();
	}, []);

	// Tab Items
	const tabItems = [
		{
			label: `All`,
			key: '0',
			children:
				all.length > 0 ? (
					all.map((e) => {
						return EventCard(e);
					})
				) : (
					<Result
						icon={<IconEmptyList />}
						title="No Approval Yet"
						subTitle="Approvals regarding telemedicine, consultation request, and other approval info will appear here"
					/>
				),
		},
		{
			label: `Need Confirmation`,
			key: '1',
			children:
				needConfirm.length > 0 ? (
					needConfirm.map((e) => {
						return EventCard(e);
					})
				) : (
					<Result
						icon={<IconEmptyList />}
						title="No Approval Yet"
						subTitle="Approvals regarding telemedicine, consultation request, and other approval info will appear here"
					/>
				),
		},
		{
			label: `Approved`,
			key: '2',
			children:
				approved.length > 0 ? (
					approved.map((e) => {
						return EventCard(e);
					})
				) : (
					<Result
						icon={<IconEmptyList />}
						title="No Approval Yet"
						subTitle="Approvals regarding telemedicine, consultation request, and other approval info will appear here"
					/>
				),
		},
		{
			label: `Rejected`,
			key: '3',
			children:
				rejected.length > 0 ? (
					rejected.map((e) => {
						return EventCard(e);
					})
				) : (
					<Result
						icon={<IconEmptyList />}
						title="No Approval Yet"
						subTitle="Approvals regarding telemedicine, consultation request, and other approval info will appear here"
					/>
				),
		},
	];

	return (
		<AppLayout activePath={activePath}>
			<Card>
				<Title level={5} style={{ fontSize: 24 }}>
					Approval
				</Title>
				<Divider />
				{isLoading && <Spin spinning={isLoading} />}
				{!isLoading && <Tabs defaultActiveKey="0" centered items={tabItems} />}
			</Card>
		</AppLayout>
	);
}
