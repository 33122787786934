export type UserBankAccountProperties = {
	userBankAccountId?: string;
	userId: string;
	bankCode: string;

	bankName?: string; // local only

	bankAccountNumber: string;
	bankAccountOwner: string;
	status: EBankStatus;
	isDefault?: boolean;

	approvedOrRejectedBy?: string;
	approvedOrRejectedDate?: Date;
	rejectReason?: string;

	updatedAt?: Date;
	createdAt?: Date;
	user?: any;
};

export enum EBankStatus {
	WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL',
	REJECTED = 'REJECTED',
	APPROVED = 'APPROVED',
}

export type BankListItemProperties = {
	id: string;
	name: string;
};
