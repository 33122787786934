import React from 'react';
import clsx from 'clsx';

import './styles.less';

type Props = {
	type?: 'primary' | 'secondary' | 'outlined' | 'opacity' | 'default';
	disabled?: boolean;
	loading?: boolean;
	size?: 'small' | 'large';
	classText?: any;
	classNames?: any;
	[x: string]: any;
};
export default function AppButton(props: Props) {
	const type = props.type || 'primary';

	const childComponent =
		typeof props.children === 'string' ? (
			<p className={clsx(`${type}Text`, props?.classText)}>{props.children}</p>
		) : (
			props.children
		);

	return (
		<>
			{props.disabled || props.loading ? (
				<div
					{...props}
					className={clsx('app-button', `${type}Disabled`, props.classNames)}
				>
					{props.loading ? <>loading...</> : childComponent}
				</div>
			) : (
				<div
					{...props}
					className={clsx('app-button', `${type}`, props.classNames)}
				>
					{childComponent}
				</div>
			)}
		</>
	);
}
