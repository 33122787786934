interface Props {
	icon: any;
	title: string;
	description?: string;
}

export default function EmptyList(props: Props) {
	return (
		<div className="w-full p-6 flex flex-col items-center">
			<div className="p-2">{props.icon}</div>
			<p className="m-0 font-medium text-center">{props.title}</p>
			{!!props.description && (
				<p className="text-black-60 text-4 text-center">{props.description}</p>
			)}
		</div>
	);
}
