import React, { useRef, useEffect, useState, useMemo } from 'react';
import AppLayout from '../layout/AppLayout';
import HomeHero from '../components/HomeHero';
import JoinDoctorBanner from '../components/JoinDoctorBanner';
import { Typography, Card, Row, Col, Button, Spin } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import COLORS from '../assets/globalColors';
import { IconChevronRightPrimary } from '../assets/icons';
import {
	CircleHome1,
	CircleHome2,
	IconButtonAppStore,
	IconButtonGooglePlay,
	IllustrationApps,
} from '../assets/images';
import useFetchList from '../hooks/useFetchList';
import { BannerProps } from '../types/banner.type';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
import { ArticleProps } from '../types/article.type';
import { DEFAULT_IMAGE } from '../helpers/constants';
import moment from 'moment';
import ArticleItem from '../components/Webapp/Articles/ArticleItem';
import { handleClickPromoDealItem } from '../components/Webapp/Articles/PromoDealItem';
import useWindowSize from '../hooks/useWindowSize';
import ScrollUp from '../components/ScrollUp';
import { useAuth } from '../context/auth.context';

SwiperCore.use([Pagination]);

const FEATURES = [
	{
		title: 'Connect',
		image: '/images/connect-icon.png',
		description:
			'Conveniently book your consultations and medical work-ups with our partner doctors and clinics',
	},
	{
		title: 'Shop',
		image: '/images/shop-icon.png',
		description:
			'Shop online from our wide variety of health and wellness products and get big discount and deals',
	},
	{
		title: 'Stay Updated',
		image: '/images/stay-update-icon.png',
		description:
			'Receive updates on the latest developments in the medical field',
	},
	{
		title: 'Keep Track',
		image: '/images/keep-track-icon.png',
		description:
			'Easiest way to manage and monitor your medical appointments and even your personal schedule ',
	},
	{
		title: 'And So Much More',
		image: '/images/more-icon.png',
		description: '',
	},
];

const BENEFIT = [
	{
		title: 'Joining Reward',
		image: '/images/join-reward-icon.png',
		description:
			'Sign up and get more than P100 worth of voucher and reward points which you can use for purchases within Medeasy.',
	},
	{
		title: 'Member Reward',
		image: '/images/member-reward-icon.png',
		description:
			'Earn reward points by simply using Medeasy - daily log in, buying points, booking services, and sharing medical news and events',
	},
	{
		title: 'Referral Reward',
		image: '/images/referral-reward-icon.png',
		description:
			'Get rewards when a friend or family signs up and completes a transaction using your referral code',
	},
];

const { Meta } = Card;
const { Title, Text } = Typography;
const Homepage: React.FC = () => {
	const { isPatient, isDoctor } = useAuth();
	const { isMobile } = useWindowSize();
	const { data: banners, isLoading: isLoadingBanner } =
		useFetchList<BannerProps>({
			endpoint: '/banners',
			limit: 100000,
			initialQuery: {
				isPublished: true,
				target: isDoctor ? 'DOCTOR' : 'PATIENT',
			},
		});

	const { data: articles, isLoading } = useFetchList<ArticleProps>({
		endpoint: '/articles',
		limit: 4,
		initialQuery: {
			isPublished: 'true',
			targetReaders: isPatient ? 'PATIENT' : 'DOCTOR',
			sort: 'publishedAt:DESC',
		},
	});

	const mainArticle = useMemo(() => articles[0], [articles]);

	const location = useLocation();
	const history = useHistory();
	const [activePath, setActivePath] = useState('/');

	const rootRef = useRef<HTMLDivElement>(null);
	const featureRef = useRef<HTMLDivElement>(null);
	const benefitRef = useRef<HTMLDivElement>(null);
	const storeRef = useRef<HTMLDivElement>(null);
	const lendHandRef = useRef<HTMLDivElement>(null);
	const promoDealsRef = useRef<HTMLDivElement>(null);
	const articleRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		let scrolled;
		switch (location.hash) {
			case '#coming-soon-modal':
				return;

			case '#features':
				scrolled = featureRef;
				break;

			case '#perks-and-benefit':
				scrolled = benefitRef;
				break;

			// case '#store':
			//   scrolled = storeRef;
			//   break;

			// case '#lend-a-hand':
			//   scrolled = lendHandRef;
			//   break;

			case '#promo-deals':
				scrolled = promoDealsRef;
				break;

			case '#articles':
				scrolled = articleRef;
				break;

			default:
				scrolled = rootRef;
				break;
		}
		if (scrolled && scrolled.current) {
			setActivePath('/' + location.hash);
			scrolled.current.scrollIntoView({ behavior: 'smooth' });
		}
	}, [location]);

	// useEffect(() => {
	//   if (isLoggedIn && isDoctor) {
	//     history.push('/app');
	//   }
	// }, [history, isDoctor, isLoggedIn]);

	return (
		<AppLayout ref={rootRef} activePath={activePath}>
			<HomeHero />

			<section id="features" ref={featureRef}>
				<div className="container center">
					<Title level={3} className="white-heading">
						Holistic Healthcare At Your Fingertips
					</Title>
					<Row
						gutter={[32, 52]}
						justify="center"
						className="content"
						style={{ marginLeft: 'auto', marginRight: 'auto' }}
					>
						{FEATURES.map((item) => (
							<Col key={item.title} flex="316px">
								<Card
									bordered={false}
									className="center card-hug card-shadow"
									cover={
										<img
											className="card-icon"
											alt={item.title}
											src={item.image}
										/>
									}
								>
									<Meta title={item.title} description={item.description} />
								</Card>
							</Col>
						))}
					</Row>
				</div>
			</section>

			<section id="perks-and-benefit" ref={benefitRef}>
				<div className="container center">
					<Title level={3} style={{ color: COLORS.black, marginBottom: 20 }}>
						Enjoy perks when you use Medeasy!
					</Title>
					<Text className="text-6" style={{ color: COLORS.gray_60 }}>
						Earn reward points upon signing up, using app features, and
						referring your friends.
					</Text>
					<Row
						gutter={[32, 52]}
						justify="center"
						className="content"
						style={{ marginLeft: 'auto', marginRight: 'auto' }}
					>
						{BENEFIT.map((item) => (
							<Col key={item.title} flex="280px">
								<Card
									bordered={false}
									className="center card-hug"
									cover={
										<img
											className="card-icon"
											alt={item.title}
											src={item.image}
										/>
									}
								>
									<Meta title={item.title} description={item.description} />
								</Card>
							</Col>
						))}
					</Row>
					<div className="flex justify-center ">
						<Link
							to={'/our-apps'}
							className="text-6 text-primary gap-x-2.5 underline font-semibold text-center block flex items-center"
						>
							Take a Look Our Application <IconChevronRightPrimary />
						</Link>
					</div>
				</div>
			</section>

			<section id="download" className="relative">
				<div className="container">
					<div className="flex justify-between items-center">
						<div className="z-10">
							<Title
								level={3}
								style={{
									color: COLORS.white,
									marginBottom: 20,
									lineHeight: '44.16px',
								}}
							>
								Download Medeasy <br className="hidden lg:block" /> App now
							</Title>
							<Text className="text-5 text-white">
								Enjoy all perks when you use the Medeasy app!
							</Text>
							<div
								className="flex items-center"
								style={{ marginTop: 60, columnGap: 25 }}
							>
								<Button
									type="link"
									style={{ padding: 0 }}
									href="https://play.google.com/store/apps/details?id=com.medeasyapp&hl=en&gl=US"
									target="_blank"
									rel="noreferrer"
								>
									<IconButtonGooglePlay />
								</Button>

								<Button
									type="link"
									style={{ padding: 0 }}
									className="cursor-pointer"
									href="https://apps.apple.com/id/app/medeasy-ph/id6443653585?l=id"
									target="_blank"
									rel="noreferrer"
								>
									<IconButtonAppStore />
								</Button>
							</div>
						</div>
						<div
							className="absolute z-10 hidden lg:block"
							style={{ bottom: -15, right: 50 }}
						>
							<IllustrationApps />
						</div>
						<div className="absolute" style={{ top: 0, left: -20, zIndex: 0 }}>
							<CircleHome1 />
						</div>
						<div className="absolute" style={{ top: 0, right: -20, zIndex: 0 }}>
							<CircleHome2 />
						</div>
					</div>
				</div>
			</section>

			<section id="promo-deals" ref={promoDealsRef}>
				<div className="container center">
					<Title level={3} style={{ color: COLORS.black, marginBottom: 12 }}>
						Promo and Deals
					</Title>
					<Text className="text-6" style={{ color: COLORS.gray_60 }}>
						Use the voucher code in every promo to get a discounts
					</Text>
				</div>
				{isLoadingBanner && <Spin spinning />}
				{!isLoadingBanner && banners.length > 0 && (
					<Swiper
						spaceBetween={isMobile ? 30 : 70}
						centeredSlides={true}
						slidesPerView={isMobile ? 1.5 : 2.7}
						modules={[Pagination]}
						pagination={{ clickable: true }}
						style={{ paddingBottom: 80, paddingTop: 50 }}
					>
						{banners?.map((banner) => (
							<SwiperSlide
								key={banner.bannerId}
								onClick={() =>
									handleClickPromoDealItem(banner.bannerType, banner, history)
								}
							>
								<img
									src={banner.bannerImageUrl}
									alt={banner.title}
									className="img-banner-voucher"
								/>
							</SwiperSlide>
						))}
					</Swiper>
				)}
			</section>

			<section id="articles" ref={articleRef}>
				<div className="container">
					<div className="center">
						<Title level={3} style={{ color: COLORS.black, marginBottom: 12 }}>
							Articles
						</Title>
						<Text className="text-6" style={{ color: COLORS.gray_60 }}>
							Get new insights everyday from our the latest articles
						</Text>
						{isLoading && <Spin spinning />}
					</div>

					{!isLoading && (
						<>
							<MainArticle article={mainArticle} />
							<div style={{ marginTop: 48 }}>
								<Title
									level={4}
									style={{ color: COLORS.black, marginBottom: 12 }}
								>
									New Article
								</Title>
								<Row gutter={[24, 43]}>
									{articles
										.filter((art) => art.articleId !== mainArticle?.articleId)
										.map((article) => (
											<Col span={24} key={article.articleId}>
												<ArticleItem article={article} isHome />
											</Col>
										))}
								</Row>
							</div>
						</>
					)}
				</div>
			</section>

			<section id="more">
				<div className="container">
					<Row justify="center" style={{ paddingBottom: 30 }}>
						<Title level={3} style={{ color: 'black' }}>
							Upcoming Feature
						</Title>
					</Row>
					<Row gutter={[24, 24]}>
						<Col xs={24} lg={12}>
							<Card
								id="store"
								// ref={storeRef}
								bordered={false}
								className="card-hug card-shadow-md"
							>
								<Row gutter={[20, 20]} className="card-grid" align="middle">
									<Col flex="auto">
										<span className="tag-primary">COMING SOON</span>
										<Title level={4}>Medeasy Store</Title>
										<Text>
											Our store shall offer health and wellness products at the
											best prices which you can purchase with cash or points.
										</Text>
									</Col>
									<Col flex="150px">
										<img src="/images/store-icon.png" alt="Medeasy Store" />
									</Col>
								</Row>
							</Card>
						</Col>
						<Col xs={24} lg={12}>
							<Card
								id="lend-a-hand"
								// ref={lendHandRef}
								bordered={false}
								className="card-hug card-shadow-md"
							>
								<Row gutter={[20, 20]} className="card-grid" align="middle">
									<Col flex="auto">
										<span className="tag-primary">COMING SOON</span>
										<Title level={4}>Lend a Hand</Title>
										<Text>
											Join us as we help less fortunate patients in need of
											financial assistance.
										</Text>
									</Col>
									<Col flex="150px">
										<img src="/images/lend-hand-icon.png" alt="Lend a Hand" />
									</Col>
								</Row>
							</Card>
						</Col>
					</Row>
				</div>
			</section>

			<JoinDoctorBanner />
			<ScrollUp />
		</AppLayout>
	);
};

export default Homepage;

const MainArticle = ({ article }: { article: ArticleProps }) => {
	const { isMobile } = useWindowSize();
	const history = useHistory();
	return (
		<div
			style={{
				background: `url(${
					article?.images?.[0] || DEFAULT_IMAGE
				}) no-repeat center center`,
				height: isMobile ? 350 : 500,
				backgroundSize: 'cover',
				padding: isMobile ? '20px' : '40px 64px',
				borderRadius: 15,
				marginTop: 40,
			}}
			className="flex items-end cursor-pointer"
			onClick={() => history.push(`/articles/${article.articleId}`)}
		>
			<div>
				<Typography.Text
					className="text-white font-bold block"
					style={{ fontSize: isMobile ? 32 : 48, lineHeight: '44px' }}
				>
					{article?.title}
				</Typography.Text>
				<div className="flex items-center gap-x-2 text-white mt-5">
					<Typography.Text className="text-white text-5">
						{moment(article?.publishedAt).format('DD MMMM YYYY')}
					</Typography.Text>
					{article?.topics.length > 0 && (
						<>
							<span>|</span>
							<Typography.Text className="text-white text-5">
								{article?.topics.map((topic) => `#${topic.topicName}`)}
							</Typography.Text>
						</>
					)}
				</div>
			</div>
		</div>
	);
};
