import { TemplateRxPrescriptionProperties } from '../../../../../../types/templateRxPrescription.type';
import { ReactComponent as Logo } from '../../../../../../assets/icons/logo.svg';
import Separator from '../../../../../Separator';
import { EModalAddMedicineState } from './ModalAddMedicine';
import { RxPrescriptionProperties } from '../../../../../../types/rxPrescription.type';
import CustomScrollDiv from '../../../../../CustomScrollDiv';
import { Button } from '../../../../../Button/Button/Button';

interface Props {
	template?: TemplateRxPrescriptionProperties;
	setState: React.Dispatch<React.SetStateAction<EModalAddMedicineState>>;
	setPrescription: React.Dispatch<
		React.SetStateAction<RxPrescriptionProperties | undefined>
	>;
	onCloseModal: () => void;
}

export default function ModalContentSelectedTemplate(props: Props) {
	function onNext() {
		props.setPrescription((old) => {
			const prescriptionItems = old?.prescriptionItems ?? [];

			if (props.template?.templatePrescriptionItems) {
				for (const tpitem of props.template?.templatePrescriptionItems) {
					// const index = prescriptionItems.findIndex(
					// 	(prescription) =>
					// 		prescription.drugMedicineDetail.drugMedicineId ===
					// 		tpitem.drugMedicineDetail?.drugMedicineId,
					// );
					// if (index >= 0) {
					// 	const item = {
					// 		drugMedicineId: tpitem.drugMedicineId,
					// 		drugMedicineVariantId: tpitem.drugMedicineVariantId,
					// 		drugMedicineDetail: tpitem.drugMedicineDetail!,
					// 		quantity: tpitem.quantity,
					// 		sig: tpitem.sig,
					// 	};
					// 	prescriptionItems[index] = {
					// 		...item,
					// 		drugMedicineId: item!.drugMedicineDetail!.drugMedicineId!,
					// 	};
					// } else {
					// }
					const item = {
						drugMedicineId: tpitem.drugMedicineId,
						drugMedicineVariantId: tpitem.drugMedicineVariantId,
						drugMedicineDetail: tpitem.drugMedicineDetail!,
						quantity: tpitem.quantity,
						sig: tpitem.sig,
					};
					prescriptionItems.push({
						...item,
						drugMedicineId: item!.drugMedicineDetail!.drugMedicineId!,
					});
				}
			}

			return { ...old, prescriptionItems };
		});
		props.onCloseModal();
	}

	return (
		<div className="h-full flex flex-col">
			<div className="flex-1 py-4.5 px-3 flex flex-col min-h-0">
				<div>
					<p className="m-0 font-medium text-5.5">
						{props.template?.templateName}
					</p>
					<p className="m-0 mt-2 text-4 text-black-60">Medicines</p>
				</div>
				<div className="mt-2 flex-1 min-h-0 flex flex-col min-h-0">
					<CustomScrollDiv className="flex-1 min-h-0">
						{props.template?.templatePrescriptionItems.map((medicine) => (
							<div
								key={
									medicine.drugMedicineDetail?.drugMedicineId ??
									medicine.drugMedicineId
								}
							>
								<div className="py-3">
									<ItemMedicine
										name={medicine.drugMedicineDetail?.drugMedicineName}
										dosage={medicine.drugMedicineDetail?.dose}
										qty={medicine.quantity}
										sig={medicine.sig}
										doesHavePoint={!!medicine.drugMedicineDetail?.points}
									/>
								</div>
								<Separator />
							</div>
						))}
					</CustomScrollDiv>
				</div>
			</div>
			<Separator />
			<div className="p-3 flex justify-between">
				<div className="flex-1 max-w-48">
					<Button
						type="SOLIDASH"
						onClick={() => props.setState(EModalAddMedicineState.ADD_MEDICINE)}
					>
						Back
					</Button>
				</div>
				<div className="flex-1 max-w-48">
					<Button type="SOLID" onClick={onNext}>
						Next
					</Button>
				</div>
			</div>
		</div>
	);
}

function ItemMedicine(props: {
	name?: string;
	dosage?: string;
	qty: number;
	sig: string;
	doesHavePoint?: boolean;
}) {
	return (
		<div>
			<div className="flex gap-x-1 items-center">
				<p className="m-0 line-clamp-1 text-4.5 font-medium">{props.name}</p>
				{props.doesHavePoint && <Logo width={20} height={20} />}
			</div>
			<div className="mt-2 flex gap-x-2.5">
				<p className="flex-1 m-0 text-3.5 text-black-70">{props.dosage}</p>
				<p className="flex-1 m-0 text-3.5 text-black-70">
					{props.qty ? `qty. ${props.qty}` : ''}
				</p>
			</div>
			<div>
				<p className="m-0 mt-2 text-3.5 text-black-70">{props.sig}</p>
			</div>
		</div>
	);
}
