import moment from 'moment';
import { useEffect, useState } from 'react';
import { httpRequest } from '../helpers/api';
import { calculateAge } from '../helpers/datetime';
import { generateQueryString } from '../helpers/generateQueryString';
import { getName } from '../helpers/name';
import { getBirthdate } from '../helpers/profile';
import { getProfilePicture } from '../helpers/profilePicture';
import { getSpecializationText } from '../helpers/specialist';
import { capitalizeFirstLetter } from '../helpers/text';
import { ApiPaginationResults, ApiResponseSuccess } from '../types/api.type';
import { PatientProperties } from '../types/patient.type';
import { PersonalConsultationProperties } from '../types/personalConsultation.type';
import { PersonalEvent } from '../types/personalEvent.type';
import {
	EScheduleTypes,
	ISchedule,
	IScheduleData,
} from '../types/schedule.type';
import {
	EConsultationType,
	ETransactionStatusType,
	TransactionConsultationProperties,
} from '../types/transactionConsultation.type';
import { TransactionHealthServiceProps } from '../types/transactionHealthService.type';
import useSchedulesNewData from './useSchedulesNewData';
import { useAuth } from '../context/auth.context';
import { apiGetMyProfile } from '../api/auth';
import { IUser } from '../types/user.type';

type Props = {
	userId: string;
	excludedData?: (FetchedScheduleType | null)[];
};

export enum FetchedScheduleType {
	HEALTH_SERVICE,
	CONSULTATION_DOCTOR,
	CONSULTATION_PATIENT,
	CONSULTATION_SECRETARY,
	PERSONAL_EVENT,
	PERSONAL_CONSULTATION_DOCTOR,
	PERSONAL_CONSULTATION_SECRETARY,
}

type IFilter = {
	startAt?: Date | string;
	endAt?: Date | string;
};

export default function useSchedulesNew(props: Props) {
	const { isLoading, setIsLoading, schedules, setSchedules, mergeSchedules } =
		useSchedulesNewData();
	const { token } = useAuth();

	const [userId, setUserId] = useState<string | undefined>(props.userId);

	useEffect(() => {
		fetchList();
	}, [userId]);

	const fetchList = async (filter?: IFilter) => {
		setIsLoading(true);
		try {
			let resultUser: IUser = await apiGetMyProfile(token);

			const res = await apiGetListSchedule(filter, resultUser?.userId);
			let newList: ISchedule[] = [];
			for (const arr of Object.values(res)) {
				newList = [...newList, ...arr];
			}

			await mergeSchedules(newList);
			setIsLoading(false);
		} catch (err) {
			setIsLoading(false);
			console.info('err fetchList', err);
		}
	};

	const apiGetListSchedule = async (filter?: IFilter, userIdProps?: string) => {
		try {
			const query = {
				doctor: {
					TrxConsult: {
						doctorId: userId ? userId : userIdProps,
						filterTransactionStatuses: [
							ETransactionStatusType.WAITING_APPROVAL,
							ETransactionStatusType.APPROVED,
							ETransactionStatusType.COMPLETED,
						],
						scheduledStartAt: filter?.startAt,
						scheduledEndAt: filter?.endAt,
					},
					PersonalConsult: {
						doctorId: userId ? userId : userIdProps,
						scheduledStartAt: filter?.startAt,
						scheduledEndAt: filter?.endAt,
					},
				},
				patient: {
					TrxConsult: {
						patientId: userId ? userId : userIdProps,
						filterTransactionStatuses: [
							// ETransactionStatusType.WAITING_PAYMENT,
							ETransactionStatusType.WAITING_APPROVAL,
							ETransactionStatusType.APPROVED,
							ETransactionStatusType.COMPLETED,
						],
						scheduledStartAt: filter?.startAt,
						scheduledEndAt: filter?.endAt,
					},
					HealthService: {
						patientId: userId ? userId : userIdProps,
						scheduledStartAt: filter?.startAt,
						scheduledEndAt: filter?.endAt,
					},
				},
				user: {
					PersonalEvent: {
						ownerId: userId ? userId : userIdProps,
						eventStartAt: filter?.startAt,
						eventEndAt: filter?.endAt,
					},
				},
				// secretary: {
				//   DoctorTrxConsult: {
				//     doctorIds:
				//       secretaryManagedDoctors.length > 0
				//         ? secretaryManagedDoctors.map(item => item.doctorId).join(',')
				//         : 'RANDOM', // doctorIds who managed by this secretary
				//     scheduledStartAt: filter?.startAt,
				//     scheduledEndAt: filter?.endAt,
				//   },
				//   DoctorPersonalConsult: {
				//     doctorIds:
				//       secretaryManagedDoctors.length > 0
				//         ? secretaryManagedDoctors.map(item => item.doctorId).join(',')
				//         : 'RANDOM', // doctorIds who managed by this secretary,
				//     scheduledStartAt: filter?.startAt,
				//     scheduledEndAt: filter?.endAt,
				//   },
				//   DoctorPersonalEvent: {
				//     createdByUserId: userId,
				//     eventStartAt: filter?.startAt,
				//     eventEndAt: filter?.endAt,
				//   },
				// },
			};
			// console.info('query', JSON.stringify(query));

			// hati2, harus urut res dan call api nya, jangan asal masukin
			const [
				res_Doctor_TrxConsult, // #1
				res_Doctor_PersonalConsult, // #2
				res_Patient_TrxConsult, // #3
				res_Patient_TrxHealthService, // #4
				res_User_PersonalEvent, // #5
				// res_Secretary_DoctorTrxConsult, // #6
				// res_Secretary_DoctorPersonalConsult, // #7
				// res_Secretary_DoctorPersonalEvent, // #8
			] = await Promise.all([
				// #1
				props.excludedData?.includes(FetchedScheduleType.CONSULTATION_DOCTOR)
					? fakeFetchList()
					: apiGetListTransactionConsultation(
							generateQueryString(query.doctor.TrxConsult),
					  ),
				// #2
				props.excludedData?.includes(
					FetchedScheduleType.PERSONAL_CONSULTATION_DOCTOR,
				)
					? fakeFetchList()
					: apiGetListPersonalConsultation(
							generateQueryString(query.doctor.PersonalConsult),
					  ),
				// #3
				props.excludedData?.includes(FetchedScheduleType.CONSULTATION_PATIENT)
					? fakeFetchList()
					: apiGetListTransactionConsultation(
							generateQueryString(query.patient.TrxConsult),
					  ),
				// #4
				props.excludedData?.includes(FetchedScheduleType.HEALTH_SERVICE)
					? fakeFetchList()
					: apiGetListTransactionHealthService(
							generateQueryString(query.patient.HealthService),
					  ),
				// #5
				props.excludedData?.includes(FetchedScheduleType.PERSONAL_EVENT)
					? fakeFetchList()
					: apiGetListPersonalEvent(
							generateQueryString(query.user.PersonalEvent),
					  ),
				// #6
				// props.excludedData?.includes(FetchedScheduleType.CONSULTATION_SECRETARY)
				//   ? fakeFetchList()
				//   : apiGetListTransactionConsultation(
				//       generateQueryString(query.secretary.DoctorTrxConsult)
				//     ),
				// // #7
				// props.excludedData?.includes(
				//   FetchedScheduleType.PERSONAL_CONSULTATION_SECRETARY
				// )
				//   ? fakeFetchList()
				//   : apiGetListPersonalConsultation(
				//       generateQueryString(query.secretary.DoctorPersonalConsult)
				//     ),
				// // #8
				// props.excludedData?.includes(FetchedScheduleType.PERSONAL_EVENT)
				//   ? fakeFetchList()
				//   : apiGetListPersonalEvent(
				//       generateQueryString(query.secretary.DoctorPersonalEvent)
				//     ),
			]);

			// mapping raw data to expected data
			const listSch_Doctor_TrxConsult: ISchedule[] = mapListTrxConsultToListSch(
				res_Doctor_TrxConsult.results,
				'doctor',
			);
			const listSch_Doctor_PersonalConsult: ISchedule[] =
				mapListPersonalConsultToListSch(
					res_Doctor_PersonalConsult.results,
					'doctor',
				);

			const listSch_Patient_TrxConsult: ISchedule[] =
				mapListTrxConsultToListSch(res_Patient_TrxConsult.results, 'patient');
			const listSch_Patient_TrxHealthService: ISchedule[] =
				mapListTrxHealthServiceToListSch(
					res_Patient_TrxHealthService.results,
					'patient',
				);

			const listSch_User_PersonalEvent: ISchedule[] =
				mapListPersonalEventToListSch(
					res_User_PersonalEvent.results,
					'patient',
				);

			// const listSch_Secretary_DoctorTrxConsult: ISchedule[] =
			//   mapListTrxConsultToListSch(
			//     res_Secretary_DoctorTrxConsult.results,
			//     "secretary"
			//   );
			// const listSch_Secretary_DoctorPersonalConsult: ISchedule[] =
			//   mapListPersonalConsultToListSch(
			//     res_Secretary_DoctorPersonalConsult.results,
			//     "secretary"
			//   );

			// const listSch_Secretary_DoctorPersonalEvent: ISchedule[] =
			//   mapListPersonalEventToListSch(
			//     res_Secretary_DoctorPersonalEvent.results,
			//     "secretary"
			//   );

			return {
				listSch_Doctor_TrxConsult,
				listSch_Doctor_PersonalConsult,
				listSch_Patient_TrxConsult,
				listSch_Patient_TrxHealthService,
				listSch_User_PersonalEvent,
				// listSch_Secretary_DoctorTrxConsult,
				// listSch_Secretary_DoctorPersonalConsult,
				// listSch_Secretary_DoctorPersonalEvent,
			};
		} catch (err) {
			console.info('err fetchList', err);
			return {};
		}
	};

	const fakeFetchList = async () => ({
		results: [],
	});

	const resetScheduleNew = (newUserId: string) => {
		setSchedules([]);
		setUserId(newUserId);
	};

	return {
		isLoading,
		schedules,
		todaySchedules: [],
		userId,
		fetchList,
		apiGetListSchedule,
		setUserId,
		resetScheduleNew,
	};
}

// MAPPING DATA CONSULTATION
function mapSingleTrxConsultToSingleSch(
	item: TransactionConsultationProperties,
	myRole: 'user' | 'patient' | 'doctor' | 'secretary',
): IScheduleData {
	if (getName(item.metaPatient) === 'Aditia Prasetio') {
		console.info('### ===> item', item);
		console.info('myRole', myRole);
	}
	return {
		name: getName(item.metaPatient) || 'Patient Name',
		date: moment(item.scheduledStartAt).format('YYYY-MM-DD'),
		desc: item.medicalRecord?.reasonOfConsultation || item.description,
		reason: item.medicalRecord?.reasonOfConsultation || item.description,
		startTime: moment(item.scheduledStartAt).format('HH:mm'),
		endTime: moment(item.scheduledEndAt).format('HH:mm'),
		detailPatient:
			myRole === 'doctor' ? getDetailPatient(item.metaPatient) : undefined,
		detailDoctor:
			myRole === 'patient' ? getDetailDoctor(item.metaDoctor) : undefined,
		raw: { ...item },
	};
}

export function mapListTrxConsultToListSch(
	list: TransactionConsultationProperties[],
	myRole: 'user' | 'patient' | 'doctor' | 'secretary',
): ISchedule[] {
	const resObject: { [key: string]: ISchedule } = {};
	// resObject = {
	//   telemedicine: {
	//     name: 'telemedicine',
	//     data: [],
	//     myRole: 'patient',
	//   },
	// }
	for (const item of list) {
		const type: EScheduleTypes =
			item.consultationType ===
				EConsultationType.TELEMEDICINE_CHAT_CONSULTATION ||
			item.consultationType ===
				EConsultationType.TELEMEDICINE_VIDEO_CHAT_CONSULTATION
				? EScheduleTypes.telemedicine
				: item.consultationType ===
						EConsultationType.CONSULT_NOW_CHAT_CONSULTATION ||
				  item.consultationType ===
						EConsultationType.CONSULT_NOW_VIDEO_CHAT_CONSULTATION
				? EScheduleTypes['consult-now']
				: EScheduleTypes.appointment;
		if (resObject[type]) {
			resObject[type].data.push(mapSingleTrxConsultToSingleSch(item, myRole));
		} else {
			resObject[type] = {
				name: type,
				data: [mapSingleTrxConsultToSingleSch(item, myRole)],
				myRole,
			};
		}
	}

	return Object.values(resObject);
}

// MAPPING DATA CONSULTATION
function mapSinglePersonalConsultToSingleSch(
	item: PersonalConsultationProperties,
	myRole: 'user' | 'patient' | 'doctor' | 'secretary',
): IScheduleData {
	if (getName(item.metaPatient) === 'Aditia Prasetio') {
		console.info('### ===> item', item);
		console.info('myRole', myRole);
	}
	return {
		name: getName(item.metaPatient) || 'Patient Name',
		date: moment(item.scheduledStartAt).format('YYYY-MM-DD'),
		desc: item.medicalRecord?.reasonOfConsultation || item.description,
		reason: item.medicalRecord?.reasonOfConsultation || item.description,
		startTime: moment(item.scheduledStartAt).format('HH:mm'),
		endTime: moment(item.scheduledEndAt).format('HH:mm'),
		detailPatient:
			myRole === 'doctor' ? getDetailPatient(item.metaPatient) : undefined,
		detailDoctor:
			myRole === 'patient' ? getDetailDoctor(item.metaDoctor) : undefined,
		raw: { ...item },
	};
}

export function mapListPersonalConsultToListSch(
	list: PersonalConsultationProperties[],
	myRole: 'user' | 'patient' | 'doctor' | 'secretary',
): ISchedule[] {
	const resObject: { [key: string]: ISchedule } = {};
	// resObject = {
	//   telemedicine: {
	//     name: 'telemedicine',
	//     data: [],
	//     myRole: 'patient',
	//   },
	// }
	for (const item of list) {
		const type: EScheduleTypes =
			item.consultationType ===
				EConsultationType.TELEMEDICINE_CHAT_CONSULTATION ||
			item.consultationType ===
				EConsultationType.TELEMEDICINE_VIDEO_CHAT_CONSULTATION
				? EScheduleTypes['telemedicine-personal']
				: EScheduleTypes['appointment-personal'];
		if (resObject[type]) {
			resObject[type].data.push(
				mapSinglePersonalConsultToSingleSch(item, myRole),
			);
		} else {
			resObject[type] = {
				name: type,
				data: [mapSinglePersonalConsultToSingleSch(item, myRole)],
				myRole,
			};
		}
	}
	return Object.values(resObject);
}

// MAPPING DATA HEALTH SERVICE
function mapSingleTrxHealthServiceToSingleSch(
	item: TransactionHealthServiceProps,
	myRole: 'user' | 'patient' | 'doctor' | 'secretary',
): IScheduleData {
	return {
		name: getName(item.metaPatient) || 'Patient Name',
		date: moment(item.scheduledStartAt).format('YYYY-MM-DD'),
		// desc: item.medicalRecord?.reasonOfConsultation,
		// reason: item.medicalRecord?.reasonOfConsultation,
		startTime: moment(item.scheduledStartAt).format('HH:mm'),
		endTime: moment(item.scheduledEndAt).format('HH:mm'),
		detailPatient:
			myRole === 'patient' ? getDetailPatient(item.metaPatient) : undefined,
		raw: { ...item },
	};
}

export function mapListTrxHealthServiceToListSch(
	list: TransactionHealthServiceProps[],
	myRole: 'user' | 'patient' | 'doctor' | 'secretary',
): ISchedule[] {
	const resObject: { [key: string]: ISchedule } = {};
	// resObject = {
	//   telemedicine: {
	//     name: 'medical-service',
	//     data: [],
	//     myRole: 'patient',
	//   },
	// }
	for (const item of list) {
		const type: EScheduleTypes = EScheduleTypes['medical-service'];
		if (resObject[type]) {
			resObject[type].data.push(
				mapSingleTrxHealthServiceToSingleSch(item, myRole),
			);
		} else {
			resObject[type] = {
				name: type,
				data: [mapSingleTrxHealthServiceToSingleSch(item, myRole)],
				myRole,
			};
		}
	}

	return Object.values(resObject);
}

// MAPPING DATA PERSONAL EVENT
function mapSinglePersonalEventToSingleSch(
	item: PersonalEvent,
	_myRole: 'user' | 'patient' | 'doctor' | 'secretary',
): IScheduleData {
	return {
		name: item.title,
		date: moment(item.eventStartAt).format('YYYY-MM-DD'),
		desc: item.description,
		startTime: moment(item.eventStartAt).format('HH:mm'),
		endTime: moment(item.eventEndAt).format('HH:mm'),
		raw: { ...item },
	};
}

function mapListPersonalEventToListSch(
	list: PersonalEvent[],
	myRole: 'user' | 'patient' | 'doctor' | 'secretary',
): ISchedule[] {
	const resObject: { [key: string]: ISchedule } = {};
	// resObject = {
	//   'personal-event': {
	//     name: 'personal-event',
	//     data: [],
	//     myRole: 'patient',
	//   },
	// }
	for (const item of list) {
		const type: EScheduleTypes = item.isBlocking
			? EScheduleTypes['personal-event-blocked']
			: EScheduleTypes['personal-event'];
		if (resObject[type]) {
			resObject[type].data.push(
				mapSinglePersonalEventToSingleSch(item, myRole),
			);
		} else {
			resObject[type] = {
				name: type,
				data: [mapSinglePersonalEventToSingleSch(item, myRole)],
				myRole,
			};
		}
	}

	return Object.values(resObject);
}

export function getDetailPatient(patientProfile?: PatientProperties) {
	let age;
	if ((patientProfile as any)?.patientAge) {
		age = (patientProfile as any)?.patientAge;
	}
	age = calculateAge(getBirthdate(patientProfile));

	return {
		name: getName(patientProfile),
		age,
		ageString: age ? age + ' yo' : '',
		gender: capitalizeFirstLetter(patientProfile?.gender || '') as any,
		img: getProfilePicture(patientProfile),
		pwIdStatus: patientProfile?.pwIdStatus,
		profilePic: patientProfile?.profilePic,
	};
}

export function getDetailDoctor(doctorProfile?: any) {
	let age;
	if (doctorProfile?.patientAge) {
		age = doctorProfile?.patientAge;
	}
	age = calculateAge(getBirthdate(doctorProfile));

	return {
		name: getName(doctorProfile),
		title: getSpecializationText(
			doctorProfile?.specialists || [],
			doctorProfile?.otherSpecialist,
		),
		img: getProfilePicture(doctorProfile),
		age,
		ageString: age ? age + ' yo' : '',
	};
}

async function apiGetListTransactionConsultation(
	queryString?: string,
): Promise<ApiPaginationResults<TransactionConsultationProperties>> {
	try {
		const res: ApiResponseSuccess<
			ApiPaginationResults<TransactionConsultationProperties>
		> = await httpRequest.get('transaction-consults' + queryString);

		if (res?.data?.payload) {
			return res?.data?.payload;
		} else {
			return { results: [] };
		}
	} catch (err) {
		return Promise.reject(err);
	}
}

async function apiGetListPersonalConsultation(
	queryString?: string,
): Promise<ApiPaginationResults<PersonalConsultationProperties>> {
	try {
		const res: ApiResponseSuccess<
			ApiPaginationResults<PersonalConsultationProperties>
		> = await httpRequest.get('personal-consults' + queryString);

		if (res?.data?.payload) {
			return res?.data?.payload;
		} else {
			return { results: [] };
		}
	} catch (err) {
		return Promise.reject(err);
	}
}

async function apiGetListTransactionHealthService(
	queryString?: string,
): Promise<ApiPaginationResults<TransactionHealthServiceProps>> {
	try {
		const res: ApiResponseSuccess<
			ApiPaginationResults<TransactionHealthServiceProps>
		> = await httpRequest.get('transaction-health-services' + queryString);

		if (res?.data?.payload) {
			return res?.data?.payload;
		} else {
			return { results: [] };
		}
	} catch (err) {
		return Promise.reject(err);
	}
}

async function apiGetListPersonalEvent(
	queryString?: string,
): Promise<ApiPaginationResults<PersonalEvent>> {
	try {
		const res: ApiResponseSuccess<ApiPaginationResults<PersonalEvent>> =
			await httpRequest.get('personal-events' + queryString);

		if (res?.data?.payload) {
			return res?.data?.payload;
		} else {
			return { results: [] };
		}
	} catch (err) {
		return Promise.reject(err);
	}
}
