import Separator from '../../Separator';
import { ReactComponent as MedicalCertificate } from '../../../assets/icons/medical-certificate.svg';
import { ReactComponent as Clearance } from '../../../assets/icons/clearance.svg';
import { ReactComponent as FitToWork } from '../../../assets/icons/fit-to-work.svg';
import { ReactComponent as OwnTemplate } from '../../../assets/icons/own-template.svg';
import { ReactComponent as DoctorDocumentHistory } from '../../../assets/icons/doctor-document-history.svg';
import { ChevronRight } from 'react-feather';
import { EKycStatus } from '../../../types/doctor.type';
import { useAuth } from '../../../context/auth.context';

export enum DoctorDocumentNav {
	Main = 'Main',
	MedicalCertificate = 'Medical-Certificate',
	Clearance = 'Clearance',
	FitToWork = 'Fit-To-Work',
	FormOwnTemplate = 'Form-Own-Template',
	OwnTemplate = 'Own-Template',
	History = 'History',
	Result = 'Result',
}

const doctorDocuments = [
	{
		icon: <MedicalCertificate />,
		title: 'Create Medical Certificate',
		description: 'Create and manage medical certificate',
		nav: DoctorDocumentNav.MedicalCertificate,
	},
	{
		icon: <Clearance />,
		title: 'Create Clearance',
		description: 'Create and manage clearance document',
		nav: DoctorDocumentNav.Clearance,
	},
	{
		icon: <FitToWork />,
		title: 'Create Fit to Work',
		description: 'Create and manage fit to work document',
		nav: DoctorDocumentNav.FitToWork,
	},
	{
		icon: <OwnTemplate />,
		title: 'Create Own Template',
		description: 'Create and manage your own template',
		nav: DoctorDocumentNav.OwnTemplate,
	},
	{
		icon: <DoctorDocumentHistory />,
		title: 'Doctor Document History',
		description: 'See your document history',
		nav: DoctorDocumentNav.History,
	},
];

interface Props {
	title?: string;
	addNav: (nav: DoctorDocumentNav) => void;
}

export default function MainDoctorDocument(props: Props) {
	const { doctorKYCStatus } = useAuth();
	const isDisabled = doctorKYCStatus !== EKycStatus.APPROVED;

	function ItemDoctorDocument(cprops: {
		icon: JSX.Element;
		title: string;
		description: string;
		nav: DoctorDocumentNav;
		isDisabled?: boolean;
	}) {
		return (
			<button
				disabled={cprops.isDisabled}
				onClick={() => props.addNav(cprops.nav)}
				className={
					(isDisabled ? 'bg-gray' : 'bg-transparent') +
					' w-full text-left flex gap-x-2.5 items-center py-4 px-2 border border-solid rounded-6 border-black-10 cursor-pointer'
				}
			>
				{cprops.icon}
				<div className="flex-1">
					<p className="m-0 font-semibold text-4">{cprops.title}</p>
					<p className="m-0 text-3.5 text-black-60">{cprops.description}</p>
				</div>
				<div className="flex">
					<ChevronRight />
				</div>
			</button>
		);
	}

	return (
		<div>
			<div className="relative px-6 py-5.5 flex items-center">
				<h1 className="font-semibold text-6 m-0 w-full">{props.title}</h1>
			</div>
			<Separator style={{ height: 2 }} />
			<div className="py-6 px-3 space-y-2">
				{doctorDocuments.map((doc, index) => (
					<ItemDoctorDocument
						key={index}
						icon={doc.icon}
						title={doc.title}
						description={doc.description}
						nav={doc.nav}
						isDisabled={isDisabled}
					/>
				))}
			</div>
		</div>
	);
}
