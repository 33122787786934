import React from 'react';
import SidebarArticle from './SidebarArticle';
import useFetchList from '../../../hooks/useFetchList';
import { BannerProps } from '../../../types/banner.type';
import { useAuth } from '../../../context/auth.context';
import { ArticleProps } from '../../../types/article.type';

export default function SidebarArticleWithFunction() {
	const { isDoctor } = useAuth();

	const { data: banners, isLoading: isLoadingBanner } =
		useFetchList<BannerProps>({
			endpoint: '/banners',
			limit: 3,
			initialQuery: {
				isPublished: true,
				target: isDoctor ? 'DOCTOR' : 'PATIENT',
			},
		});

	const { data: articles, isLoading: isLoadingArticle } =
		useFetchList<ArticleProps>({
			endpoint: '/articles',
			limit: 3,
			initialQuery: {
				isPublished: 'true',
				targetReaders: isDoctor ? 'DOCTOR' : 'PATIENT',
				sort: 'publishedAt:DESC',
			},
		});

	return (
		<SidebarArticle
			banners={banners}
			isLoading={isLoadingArticle || isLoadingBanner}
			articles={articles}
		/>
	);
}
