import React, { useState, useEffect } from 'react';
import { Divider, Typography } from 'antd';
import AppLayout from '../../../../layout/AppLayout';
import PageHeader from '../../../../components/PageHeader';
import { useHistory } from 'react-router-dom';
import Card from '../../../../components/Card';
import COLORS from '../../../../assets/globalColors';
import { TokenResponse, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { Button } from '../../../../components/Button/Button/Button';
import moment from 'moment';
import useSyncCalendar from '../../../../hooks/useSyncCalendar';
import { IGooleCalendarSchedule } from '../../../../types/schedule.type';
import { useAuth } from '../../../../context/auth.context';
import { httpRequest } from '../../../../helpers/api';
import {
	ApiResponseDefault,
	ApiResponsePagination,
} from '../../../../types/apiResponse.type';
import { DoctorProperties } from '../../../../types/doctor.type';
import { profile } from 'console';

const { Text } = Typography;

interface GoogleProfile {
	email: string;
	name: string;
}

export default function SyncCalender() {
	const history = useHistory();
	const { user } = useAuth();

	const { loadingIntegrate, setLoadingIntegrate, addSyncCalendar } =
		useSyncCalendar();
	const [googleAccount, setGoogleAccount] = useState<string | null>(null);
	const [googleAccountName, setGoogleAccountName] = useState<string | null>(
		null,
	);
	const [accessToken, setAccessToken] = useState<string | null>(null);

	const activePath = '/app/schedule/sync-calender';

	const login = useGoogleLogin({
		scope: 'https://www.googleapis.com/auth/calendar',
		onSuccess: async (tokenResponse: TokenResponse) => {
			setLoadingIntegrate(true);
			setAccessToken(tokenResponse.access_token);

			try {
				// Fetch the user's profile information
				const profileResponse = await axios.get(
					'https://www.googleapis.com/oauth2/v1/userinfo',
					{
						headers: {
							Authorization: `Bearer ${tokenResponse.access_token}`,
						},
					},
				);
				const profileData = profileResponse.data as GoogleProfile;

				setGoogleAccount(profileData.email);
				setGoogleAccountName(profileData.name);

				localStorage.setItem('googleAccount', profileData.email);
				localStorage.setItem('googleAccountName', profileData.name);
				localStorage.setItem('googleAccessToken', tokenResponse.access_token);

				const startDate = moment()
					.subtract(1, 'month')
					.startOf('month')
					.toISOString();
				const endDate = moment().add(2, 'month').endOf('month').toISOString();

				const resp = await fetchCalendarEvents(
					tokenResponse.access_token,
					startDate,
					endDate,
				);
				let dataImported: any[] = [];

				console.log('token response:', tokenResponse.access_token);
				console.log('resp:', resp);

				if (resp?.items && resp?.items?.length > 0) {
					for (const event of resp.items) {
						if (event.start?.dateTime && event.end?.dateTime) {
							dataImported.push({
								localEventId: event.id,
								title: event.summary,
								description: event.description ?? '',
								eventStartAt: event.start.dateTime,
								eventEndAt: event.end.dateTime,
								isBlocking: false,
								createdByUserId: user?.userId,
								metaCreatedByUser: user,
								ownerId: user?.userId,
								metaOwner: user,
								isPrivate: true,
							});
						}
					}
				}

				const resAddSync = await addSyncCalendar({
					personalEventList: dataImported,
					startDate,
					endDate,
				});

				console.log('resAddSync====>', JSON.stringify(resAddSync));
				await updateDoctorEmail(profileData.email);
			} catch (error) {
				console.error('Error during Google login or calendar sync:', error);
			} finally {
				setLoadingIntegrate(false);
			}
		},
		onError(errorResponse) {
			setLoadingIntegrate(false);
			console.error('Google login error:', errorResponse);
		},
	});

	const fetchCalendarEvents = async (
		token: string,
		startDate: string,
		endDate: string,
	): Promise<IGooleCalendarSchedule | null | undefined> => {
		const calendarId = 'primary';
		const url = `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events?maxResults=1000&timeMax=${endDate}&timeMin=${startDate}`;

		try {
			const resp = await axios.get(url, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			return resp.data as IGooleCalendarSchedule;
		} catch (error) {
			console.error('Error fetching calendar events', error);
			return null;
		}
	};

	const handleButtonClick = () => {
		if (googleAccount && googleAccountName) {
			console.log('Logged in with Google Account:', googleAccount);
			console.log('Account Name:', googleAccountName);
			console.log('user:', user?.name);
		} else {
			login();
			console.log('uesr email:', user?.email);
			console.log('doctor data:', user);
		}
	};

	const updateDoctorEmail = async (email: string) => {
		try {
			const res = await httpRequest.get<ApiResponseDefault<DoctorProperties>>(
				'/doctors/' + user?.userId,
			);

			if (!res || !res.data || !res.data.payload) {
				throw new Error('Failed to fetch doctor data');
			}

			const doctorData = res.data.payload;

			console.log(doctorData.user);
			if (doctorData.user) {
				doctorData.user.email = email;
				doctorData.user.firstName = 'Test';
			} else {
				console.log('Fail to get doctor data');
			}

			const updateResUser = await httpRequest.patch<
				ApiResponseDefault<DoctorProperties>
			>('/user/' + user?.userId, { email: email });

			if (
				!updateResUser ||
				!updateResUser.data ||
				!updateResUser.data.payload
			) {
				throw new Error('Failed to update user data');
			}
			console.log('User data updated:', updateResUser.data.payload);
		} catch (error) {
			console.error('Error updating doctor email:', error);
		}
	};

    const autoSyncCalendar = async (token: string) => {
        setLoadingIntegrate(true);
        try {
            const startDate = moment()
                .subtract(1, 'month')
                .startOf('month')
                .toISOString();
            const endDate = moment().add(2, 'month').endOf('month').toISOString();

            const resp = await fetchCalendarEvents(token, startDate, endDate);
            let dataImported: any[] = [];

            if (resp?.items && resp?.items.length > 0) {
                for (const event of resp.items) {
                    if (event.start?.dateTime && event.end?.dateTime) {
                        dataImported.push({
                            localEventId: event.id,
                            title: event.summary,
                            description: event.description ?? '',
                            eventStartAt: event.start.dateTime,
                            eventEndAt: event.end.dateTime,
                            isBlocking: false,
                            createdByUserId: user?.userId,
                            metaCreatedByUser: user,
                            ownerId: user?.userId,
                            metaOwner: user,
                            isPrivate: true,
                        });
                    }
                }
            }

            const resAddSync = await addSyncCalendar({
                personalEventList: dataImported,
                startDate,
                endDate,
            });

            console.log('resAddSync====>', JSON.stringify(resAddSync));
        } catch (error) {
            console.error('Error during auto sync:', error);
        } finally {
            setLoadingIntegrate(false);
        }
    };

    useEffect(() => {
        const storedToken = localStorage.getItem('googleAccessToken');
        const storedAccount = localStorage.getItem('googleAccount');
        const storedAccountName = localStorage.getItem('googleAccountName');

        if (storedToken && storedAccount && storedAccountName) {
            setAccessToken(storedToken);
            setGoogleAccount(storedAccount);
            setGoogleAccountName(storedAccountName);

            // Automatically sync the calendar
            autoSyncCalendar(storedToken);
        }
    }, []);

	const handleUnsycClick = async () => {
		if (googleAccount) {
			try {
				setLoadingIntegrate(true);

				const resRemovesync = await addSyncCalendar({
					personalEventList: [],
					startDate: '',
					endDate: '',
				});

				console.log('resRemovesync====>', JSON.stringify(resRemovesync));

				localStorage.removeItem('googleAccount');
				localStorage.removeItem('googleAccountName');
				localStorage.removeItem('googleAccessToken');

				setGoogleAccount(null);
				setGoogleAccountName(null);
			} catch (error) {
				console.error('Error during unsync:', error);
			} finally {
			}
		}
	};

	return (
		<AppLayout activePath={activePath}>
			<Card style={{ paddingBottom: 110, padding: '24px 16px' }}>
				<PageHeader title="Sync Calendar" onClick={() => history.goBack()} />
				<Divider />
				<div
					style={{
						padding: '0 16px',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<Text>
						Choose the account you want to synchronize with <span>MedEasy</span>{' '}
						calendar
					</Text>
					<Text
						style={{
							fontSize: 14,
							color: COLORS.charcoal_200,
							margin: '16px 0px',
						}}
					>
						Google Account
					</Text>
					<Button
						type="OUTLINE"
						isDisable={loadingIntegrate}
						onClick={googleAccount ? handleUnsycClick : handleButtonClick}
					>
						{loadingIntegrate
							? googleAccount
								? 'Unsyncing'
								: 'Loading Sync Calendar'
							: googleAccount
							? 'Unsync Google Account'
							: 'Sign in With Google'}
					</Button>
					{googleAccount && (
						<div style={{ marginTop: 16 }}>
							<Text>Synced with Google Account: {googleAccount}</Text>
							<br>
							</br>
							<Text>Account Name: {googleAccountName}</Text>
						</div>
					)}
				</div>
			</Card>
		</AppLayout>
	);
}
