import { IUser } from './user.type';

export enum EPWIdStatus {
	APPROVED = 'APPROVED',
	REJECTED = 'REJECTED',
	WAITING_APPROVAL = 'WAITING_APPROVAL',
}

export type PatientProperties = {
	patientId?: string;
	nickName: string;
	pwId?: string;
	pwIdUrl?: string;
	pwIdStatus?: EPWIdStatus;
	gender?: string;
	profilePic?: string;
	user?: IUser;
	patientName?: string;
	patientAge?: string;
	patientGender?: string;
};

export const initialPatientInfo: PatientProperties = {
	patientId: undefined,
	nickName: '',
	pwId: undefined,
	pwIdUrl: undefined,
	gender: 'male',
};
