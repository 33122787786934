import { useCallback, useEffect, useRef, useState } from 'react';
import { Search } from 'react-feather';
import { theme } from '../../../../../../assets/theme';
// import useAuth from "../../../../../../hooks/useAuth";
import { useAuth } from '../../../../../../context/auth.context';
import useFetchList from '../../../../../../hooks/useFetchList';
import { DrugMedicineProperties } from '../../../../../../types/drugMedicine';
import { TemplateRxPrescriptionProperties } from '../../../../../../types/templateRxPrescription.type';
import CustomScrollDiv from '../../../../../CustomScrollDiv';
import FormInput from '../../../../../Form/FormInput2';
import Spinner from '../../../../../Spinner';
import Tab from '../../../../../Tab/Tab';
import { EModalAddMedicineState } from './ModalAddMedicine';
import { ChevronRight, Info, PlusCircle } from 'react-feather';
import { Button } from '../../../../../Button/Button/Button';
import EmptyList from '../../../../../EmptyList';
import Separator from '../../../../../Separator';
import { ReactComponent as Clinical } from '../../../../../../assets/icons/clinical.svg';
import { ReactComponent as Logo } from '../../../../../../assets/icons/logo.svg';
import { ReactComponent as Medicines } from '../../../../../../assets/icons/medicines.svg';

const tabs = ['All Medicines', 'Template'];

interface Props {
	isHideTemplate?: boolean;
	setState: (state: EModalAddMedicineState) => void;
	setSelectedTemplate: (item: TemplateRxPrescriptionProperties) => void;
	setSelectedMedicine: (item: DrugMedicineProperties) => void;
	state?: any;
	setIsSearchNotFound?: any;
}

export default function ModalContentAddMedicine(props: Props) {
	const { user } = useAuth();

	const footerElement = useRef<HTMLDivElement>(null);

	const [selectedTabIndex, setSelectedTabIndex] = useState(
		!props.isHideTemplate ? 0 : 0,
	);

	const [search, setSearch] = useState<string>();

	const {
		data: medicines,
		isLoading: isLoadingMedicine,
		pagination: paginationMedicine,
		setSearch: setSearchMedicine,
		changePage: changePageMedicine,
		resetQuery: resetQueryMedicine,
	} = useFetchList<DrugMedicineProperties>({
		endpoint: 'drug-medicines',
		initialQuery: {
			isPublished: true,
			limit: 1000000,
			order: 'drugMedicineName:ASC',
		},
		pushData: true,
		primaryKey: 'drugMedicineId',
	});

	function handleNextPageMedicine() {
		if (!isLoadingMedicine && paginationMedicine.next) {
			changePageMedicine(paginationMedicine.page + 1);
		}
	}

	const {
		data: templates,
		isLoading: isLoadingTemplate,
		pagination: paginationTemplate,
		setSearch: setSearchTemplate,
		changePage: changePageTemplate,
		resetQuery: resetQueryTemplate,
	} = useFetchList<TemplateRxPrescriptionProperties>({
		endpoint: 'template-rx-prescriptions',
		initialQuery: {
			doctorId: user?.userId,
		},
		pushData: true,
		primaryKey: 'templateRxPrescriptionId',
	});

	function handleNextPageTemplate() {
		if (!isLoadingTemplate && paginationTemplate.next) {
			changePageTemplate(paginationTemplate.page + 1);
		}
	}

	useEffect(() => {
		setSearchMedicine(search);
		setSearchTemplate(search);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search]);

	useEffect(() => {
		resetQueryMedicine();
		resetQueryTemplate();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedTabIndex]);

	const isLoading = isLoadingMedicine || isLoadingTemplate;

	const handleObserver = useCallback((entries) => {
		const target = entries[0];
		if (target.isIntersecting) {
			if (selectedTabIndex === 0) {
				handleNextPageTemplate();
			} else if (selectedTabIndex === 1) {
				handleNextPageMedicine();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (footerElement.current) {
			const element = footerElement.current;

			const option = {
				root: null,
				rootMargin: '20px',
				threshold: 0,
			};
			const observer = new IntersectionObserver(handleObserver, option);
			observer.observe(element);

			return () => {
				observer.unobserve(element);
				observer.disconnect();
			};
		}
	}, [handleObserver]);

	useEffect(() => {
		if (selectedTabIndex === 0) {
			if (isLoadingMedicine) {
				props.setIsSearchNotFound(false);
			} else {
				if (!medicines.length) {
					props.setIsSearchNotFound(true);
				} else {
					props.setIsSearchNotFound(false);
				}
			}
		} else {
			if (isLoadingTemplate) {
				props.setIsSearchNotFound(false);
			} else {
				if (!templates.length) {
					props.setIsSearchNotFound(true);
				} else {
					props.setIsSearchNotFound(false);
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoadingMedicine, isLoadingTemplate]);

	function onSelectMedicine(medicine: DrugMedicineProperties) {
		props.setSelectedMedicine(medicine);
		props.setState(EModalAddMedicineState.SELECTED_MEDICINE);
	}

	function onSelectTemplate(template: TemplateRxPrescriptionProperties) {
		props.setSelectedTemplate(template);
		props.setState(EModalAddMedicineState.SELECTED_TEMPLATE);
	}

	return (
		<div className="h-full flex flex-col">
			<div className="p-3">
				<FormInput
					prefix={
						<Search width={20} height={20} color={theme.colors.charcoal200} />
					}
					placeholder={`Search medicine${
						!props.isHideTemplate ? ' or template name' : ''
					}`}
					value={search}
					onChange={setSearch}
				/>
			</div>
			{!props.isHideTemplate && (
				<Tab
					tabs={tabs}
					selectedTabIndex={selectedTabIndex}
					setSelectedTabIndex={(e: any) => {
						setSelectedTabIndex(e);
						setSearch('');
					}}
					useBottomSeparator
				/>
			)}
			<div className="mt-3 flex-1 min-h-0">
				<CustomScrollDiv className="h-full min-h-0">
					{!isLoading ? (
						selectedTabIndex === 1 ? (
							<ListItem
								type="Template"
								data={templates}
								setState={props.setState}
								setSelectedTemplate={onSelectTemplate}
							/>
						) : selectedTabIndex === 0 ? (
							<ListItem
								type="Medicine"
								data={medicines}
								setState={props.setState}
								setSelectedMedicine={onSelectMedicine}
							/>
						) : undefined
					) : (
						<div className="py-4 w-fit mx-auto">
							<Spinner size={25} color={theme.colors.primary} />
						</div>
					)}
				</CustomScrollDiv>
			</div>
			<div ref={footerElement} />
		</div>
	);
}

function ListItem(props: {
	type: 'Medicine' | 'Template';
	data: (TemplateRxPrescriptionProperties | DrugMedicineProperties)[];
	setState: (state: EModalAddMedicineState) => void;
	setSelectedTemplate?: (item: TemplateRxPrescriptionProperties) => void;
	setSelectedMedicine?: (item: DrugMedicineProperties) => void;
}) {
	return (
		<div>
			<div className="flex gap-x-2 items-center bg-ash-300 px-4 py-3">
				{props.type === 'Template' && (
					<Clinical width={38} height={38} color={theme.colors.primary} />
				)}
				{props.type === 'Medicine' && (
					<Medicines width={38} height={38} color={theme.colors.primary} />
				)}
				<div>
					<p className="m-0 font-medium text-4.5">
						{props.type === 'Template' && 'Prescription Template'}
						{props.type === 'Medicine' && 'All Medicines'}
					</p>
					<p className="m-0 text-3.5 text-charcoal-300">
						{props.type === 'Template' &&
							'Use pre made prescription from your template'}
						{props.type === 'Medicine' &&
							'Add medicine individually to your perscription'}
					</p>
				</div>
			</div>
			<div className="py-4 px-3">
				{props.data.length > 0 ? (
					props.data.map((d, index) => {
						// console.log((d as DrugMedicineProperties).points);
						return (
							<div key={index}>
								{props.type === 'Template' && (
									<ItemTemplate
										title={
											(d as TemplateRxPrescriptionProperties)?.templateName ??
											''
										}
										numberOfMedicine={
											(d as TemplateRxPrescriptionProperties)
												?.templatePrescriptionItems?.length ?? 0
										}
										onClick={() =>
											props.setSelectedTemplate?.(
												d as TemplateRxPrescriptionProperties,
											)
										}
									/>
								)}
								{props.type === 'Medicine' && (
									<ItemMedicine
										title={
											(d as DrugMedicineProperties)?.drugMedicineName?.trim() ??
											''
										}
										onClick={() =>
											props.setSelectedMedicine?.(d as DrugMedicineProperties)
										}
										doesHavePoint={
											!!(d as DrugMedicineProperties).partner &&
											!!(d as DrugMedicineProperties).points
										}
									/>
								)}
								<Separator />
							</div>
						);
					})
				) : (
					<div>
						<EmptyList
							icon={<Info color={theme.colors.black50} />}
							title="Medicine not found"
							description="You can add new medicine for this prescription"
						/>
					</div>
				)}
			</div>
		</div>
	);
}

function ItemTemplate(props: {
	title: string;
	numberOfMedicine: number;
	onClick: () => void;
}) {
	return (
		<button
			onClick={props.onClick}
			className="border-none bg-transparent cursor-pointer p-0 text-left w-full flex gap-x-2 p-3"
		>
			<div className="flex-1">
				<p className="m-0">{props.title}</p>
				<p className="m-0 text-black-70 text-3.5">
					{props.numberOfMedicine} Medicine
					{props.numberOfMedicine > 1 ? 's' : ''}
				</p>
			</div>
			<div>
				<ChevronRight width={20} height={20} color={theme.colors.black30} />
			</div>
		</button>
	);
}

function ItemMedicine(props: {
	title: string;
	onClick: () => void;
	doesHavePoint: boolean;
}) {
	return (
		<button
			onClick={props.onClick}
			className="bg-transparent border-none w-full cursor-pointer text-left flex gap-x-2 items-center p-3"
		>
			<div className="flex gap-x-2 items-center flex-1">
				<p className="m-0">{props.title}</p>
				{props.doesHavePoint && (
					<Logo width={18} height={18} className="shrink-0" />
				)}
			</div>
			<ChevronRight width={20} height={20} color={theme.colors.black30} />
		</button>
	);
}
