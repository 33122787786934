import React, { useState } from 'react';
import { Form, Button, Input, Divider, Card, Typography } from 'antd';
import styled from 'styled-components';
import BmiGenderFemale from '../assets/BmiGenderFemale.svg';
import BmiGenderMale from '../assets/BmiGenderMale.svg';

type Props = {
	nextBtnHandler: (e: any) => void;
	calculateBmi: (result: any) => void;
};

const { Item } = Form;
const { Text } = Typography;

const genders = [
	{ label: 'MALE', icon: BmiGenderMale },
	{ label: 'FEMALE', icon: BmiGenderFemale },
];

const mbiParams = [
	{
		label: 'Height (cm)',
		name: 'height',
		suffix: 'cm',
	},
	{
		label: 'Weight (kg)',
		name: 'weight',
		suffix: 'kg',
	},
	{
		label: 'Age',
		name: 'age',
		suffix: 'years old',
	},
];

const initialErrorInputNumber = [false, false, false];

export default function InputsCalculator(Props: Props) {
	const [form] = Form.useForm();
	const [gender, setGender] = useState<string>('');
	const [errGender, setErrGender] = useState<boolean>(false);
	const [errNotNumber, setErrNotNumber] = useState<any>(
		initialErrorInputNumber,
	);

	const selectGenderHandler = (
		e: React.MouseEvent<Element, MouseEvent>,
		gndr: string,
	) => setGender(gndr);

	const setIdealBmi = async (values: any) => {
		const result =
			values.weight / ((values.height / 100) * (values.height / 100));

		if ((await result) < 18.5) {
			return 18.5;
		} else if ((await result) > 24.9) {
			return 24.9;
		} else {
			return 0;
		}
	};

	const submitHandler = (values: any) => {
		// if (Object.values(values).some((x) => typeof x !== "number")) {
		//   return null;
		// }

		const result =
			values.weight / ((values.height / 100) * (values.height / 100));

		setIdealBmi(values)
			.then((res) => {
				return res * ((values.height / 100) * (values.height / 100));
			})
			.then((idealW) => {
				Props.calculateBmi({
					value: result,
					height: values.height,
					weight: values.weight,
					idealWeight: idealW === 0 ? values.weight : idealW,
					age: values.age,
					gender: gender,
				});
			});
	};

	const isNotValid = () => {
		gender === '' && setErrGender(true);

		const values = Object.values(form.getFieldsValue());

		const newErrNotNumber = errNotNumber.map(
			(input: boolean, index: number) => {
				if (values[index] === undefined) {
					return false;
				}
				return isNaN(Number(values[index]));
			},
		);

		setErrNotNumber(newErrNotNumber);

		const notValidForm = values.some(
			(x) => x === null || x === '' || x === undefined || isNaN(Number(x)),
		);

		return notValidForm || gender === '';
	};

	const MbiInputs = mbiParams.map((param: any, index) => {
		return (
			<Item
				key={index}
				label={param.label}
				name={param.name}
				validateStatus={`${errNotNumber[index] ? 'error' : ''}`}
				help={errNotNumber[index] ? 'Input should be number only' : null}
				rules={[
					{
						required: true,
						message: `Your ${param.name} input can not be empty!`,
					},
				]}
			>
				<Input
					size="large"
					onChange={() => setErrNotNumber(initialErrorInputNumber)}
					style={{ borderRadius: 12, marginTop: '-20px' }}
					suffix={<Text disabled>{param.suffix}</Text>}
				/>
			</Item>
		);
	});

	const GenderOptions = genders.map((gndr: any, index) => {
		return (
			<Card
				key={index}
				onClick={(e) => {
					selectGenderHandler(e, gndr.label);
					setErrGender(false);
				}}
				style={
					gender === gndr.label ? SelectedCardGenderStyles : CardGenderStyles
				}
			>
				<img
					style={{
						width: 68,
						height: 65,
						filter: `${gender === gndr.label ? '' : 'grayscale(1)'}`,
					}}
					src={gndr.icon}
					alt="React Logo"
				/>
				<div style={{ textAlign: 'center' }}>{gndr.label}</div>
			</Card>
		);
	});

	return (
		<Card style={CardWrapperStyles}>
			<GenderOptionsContainer>{GenderOptions}</GenderOptionsContainer>
			<Text
				style={{ display: `${errGender ? 'block' : 'none'}` }}
				type="danger"
			>
				Please choose your gender first
			</Text>
			<Form
				form={form}
				layout="vertical"
				onFinish={submitHandler}
				requiredMark={false}
			>
				{MbiInputs}
				<SubmitBtnContainer>
					<Divider />
					<Item>
						<Button
							size="large"
							type="primary"
							htmlType="submit"
							style={SubmitBtnStyles}
							onClick={(e) => {
								if (!isNotValid()) {
									Props.nextBtnHandler(e);
								}
							}}
						>
							Calculate
						</Button>
					</Item>
				</SubmitBtnContainer>
			</Form>
		</Card>
	);
}

const SubmitBtnContainer = styled.div`
	// border: 1px solid black;
	position: absolute;
	bottom: 0px;
	left: 0px;
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: end;
`;

const GenderOptionsContainer = styled.div`
	// border: 1px solid black;
	display: flex;
	widht: 100%;
	gap: 14px;
	margin-bottom: 20px;
`;

const CardWrapperStyles = {
	minHeight: '600px',
	borderBottomLeftRadius: '12px',
	borderBottomRightRadius: '12px',
};

const SubmitBtnStyles = {
	marginRight: 24,
	borderRadius: 12,
	width: 184,
};

const CardGenderStyles = {
	// border: "1px solid black",
	backgroundColor: '#F8FAFB',
	width: '100%',
	height: '120px',
	borderColor: '#F8FAFB',
	borderWidth: '2px',
	cursor: 'pointer',
	borderRadius: '12px',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
};

const SelectedCardGenderStyles = {
	backgroundColor: 'white',
	width: '100%',
	height: '120px',
	borderColor: '#D81F64',
	borderWidth: '2px',
	cursor: 'pointer',
	borderRadius: '12px',
	display: 'flex',
	// flexDirection: "column",
	justifyContent: 'center',
	alignItems: 'center',
};
