import AppLayout from '../../../layout/AppLayout';
import React, { useState } from 'react';
import { Card, Divider, Tabs, Spin, Collapse } from 'antd';
import useFetchList from '../../../hooks/useFetchList';
import { useHistory } from 'react-router-dom';
import { FAQProps } from '../../../types/supports';
import { CaretRightOutlined } from '@ant-design/icons';
import COLORS from '../../../assets/globalColors';
import PageHeader from '../../../components/PageHeader';
import { useAuth } from '../../../context/auth.context';

const activePath = '/app/balance';

const { Panel } = Collapse;

export default function PointInformationScreen() {
	const { isDoctor } = useAuth();
	const history = useHistory();

	const [activeTab, setActiveTab] = useState<string>(isDoctor ? '0' : '1');

	// Fetch API
	const {
		isLoading: isLoading,
		data: data,
		setQuery: setQuery,
	} = useFetchList<FAQProps>({
		endpoint: `faqs`,
		initialQuery: {
			limit: 9999,
			types: 'POINT',
			isPublished: '1',
			targetReaders: isDoctor ? 'DOCTOR' : 'PATIENT',
		},
	});

	React.useEffect(() => {
		setQuery((oldVal) => ({
			...oldVal,
			targetReaders: activeTab === '0' ? 'DOCTOR' : 'PATIENT',
		}));
	}, [activeTab]);

	// Tab Item Children
	const information = () => {
		return (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				{isLoading ? (
					<Spin spinning={isLoading} style={{ marginTop: 40 }} />
				) : (
					<Collapse
						bordered={false}
						defaultActiveKey={['1']}
						expandIcon={({ isActive }) => (
							<CaretRightOutlined rotate={isActive ? 90 : 0} />
						)}
						style={{ background: COLORS.white }}
					>
						{data.map((item) => (
							<Panel header={item.question} key={item.faqId}>
								<p>{item.answer}</p>
							</Panel>
						))}
					</Collapse>
				)}
			</div>
		);
	};

	// Tab Items
	const tabItems = [
		{
			label: 'Doctor',
			key: '0',
			children: information(),
		},
		{
			label: 'Patient',
			key: '1',
			children: information(),
		},
	];

	return (
		<AppLayout activePath={activePath}>
			<Card>
				<PageHeader
					title="Point Information"
					onClick={() => history.goBack()}
				/>
				<Divider />
				<Tabs
					defaultActiveKey={activeTab}
					centered
					items={tabItems}
					onChange={(e) => {
						console.log(e);
						setActiveTab(e);
					}}
				/>
			</Card>
		</AppLayout>
	);
}
