import React, { useMemo } from 'react';
import {
	iconTelemedicine,
	iconAppointment,
	iconWarningCircle,
	iconPersonalEvent,
	iconMedService,
	iconWebinarCPD,
	iconWebinarRTD,
	iconWebinarSpecialist,
	iconWebinarPublic,
} from '../../../../assets/icons';
import COLORS from '../../../../assets/globalColors';
import { Divider, Typography } from 'antd';
import AppLayout from '../../../../layout/AppLayout';
import { useAuth } from '../../../../context/auth.context';
import PageHeader from '../../../../components/PageHeader';
import { useHistory } from 'react-router-dom';
import Card from '../../../../components/Card';

const styleDefault = { width: 36, height: 36, borderRadius: 18 };
const indicator = { width: 16, height: 16, borderRadius: 8 };

const { Text } = Typography;

export default function ScheduleIconInformation() {
	const { isPatient } = useAuth();
	const history = useHistory();

	const activePath = '/app/schedule/schedule-icon-information';

	const dataSchedule = useMemo(
		() => [
			{
				title: 'Icon Telemedicine',
				desc: 'This icon indicates Telemedicine consultation with the Doctor',
				icon: (
					<IconElement
						type="isSchedule"
						color={COLORS.primary}
						icon={iconTelemedicine}
					/>
				),
			},
			{
				title: 'Icon In-Person Consultation',
				desc: 'This icon indicates In-Person consultation with the Doctor',
				icon: (
					<IconElement
						type="isSchedule"
						color={COLORS.blue}
						icon={iconAppointment}
					/>
				),
			},
			{
				title: 'Icon Consult Now',
				desc: 'This icon indicates Consult Now consultation with the Doctor',
				icon: (
					<IconElement
						type="isSchedule"
						color={COLORS.yellowApp}
						icon={iconWarningCircle}
					/>
				),
			},
			{
				title: 'Icon Personal Event',
				desc: 'This icon indicates Personal Event',
				icon: (
					<IconElement
						type="isSchedule"
						color={COLORS.greenApp}
						icon={iconPersonalEvent}
					/>
				),
			},
			{
				title: 'Icon Personal Event (Block)',
				desc: 'This personal event will block all current or future activities',
				icon: (
					<IconElement
						type="isSchedule"
						color={COLORS.primary_black_60}
						icon={iconPersonalEvent}
					/>
				),
			},
			{
				title: 'Icon Medical Services',
				desc: 'This icon indicates Medical Service',
				icon: (
					<IconElement
						type="isSchedule"
						color={COLORS.orangeApp}
						icon={iconMedService}
					/>
				),
			},
			{
				title: 'Icon Webinar - CPD',
				desc: 'This icon indicates Medical Webinar - CPD',
				type: 'isNotPatient',
				icon: (
					<IconElement
						type="isSchedule"
						color={COLORS.purpleApp}
						icon={iconWebinarCPD}
					/>
				),
			},
			{
				title: 'Icon Webinar - RTD',
				desc: 'This icon indicates Medical Webinar - RTD',
				type: 'isNotPatient',
				icon: (
					<IconElement
						type="isSchedule"
						color={COLORS.purpleApp}
						icon={iconWebinarRTD}
					/>
				),
			},
			{
				title: 'Icon Webinar - Specialist',
				desc: 'This icon indicates Medical Webinar - Specialist',
				type: 'isNotPatient',
				icon: (
					<IconElement
						type="isSchedule"
						color={COLORS.purpleApp}
						icon={iconWebinarSpecialist}
					/>
				),
			},
			{
				title: 'Icon Webinar - Public',
				desc: 'This icon indicates Medical Webinar - Public',
				type: 'isNotPatient',
				icon: (
					<IconElement
						type="isSchedule"
						color={COLORS.purpleApp}
						icon={iconWebinarPublic}
					/>
				),
			},
			{
				title: 'Icon Webinar or Event',
				desc: 'This icon indicates Medical Webinar or Event',
				type: 'isPatientOnly',
				icon: (
					<IconElement
						type="isSchedule"
						color={COLORS.purpleApp}
						icon={iconWebinarPublic}
					/>
				),
			},
		],
		[],
	);

	const dataCalendar = useMemo(
		() => [
			{
				title: 'Selected Date',
				desc: 'This icon indicates date being selected',
				icon: (
					<IconElement
						type="isCalendar"
						color={COLORS.primary}
						textColor={COLORS.white}
					/>
				),
			},
			{
				title: 'Current Date',
				desc: 'This icon indicates current date',
				icon: (
					<IconElement
						type="isCalendar"
						color="transparent"
						textColor={COLORS.primary}
					/>
				),
			},
			{
				title: 'Disabled Date',
				desc: 'This icon indicates date is disabled, choose another date',
				icon: (
					<IconElement
						type="isCalendar"
						color="transparent"
						textColor={COLORS.black_30}
					/>
				),
			},
			{
				title: 'Blocked Date',
				desc: 'This feature blocks a doctor’s clinic schedule for a certain period of time',
				type: 'isNotPatient',
				icon: (
					<IconElement
						type="isCalendar"
						color={COLORS.ash_500}
						textColor={COLORS.black}
					/>
				),
			},
		],
		[],
	);

	const dataIndicator = useMemo(
		() => [
			{
				title: 'Telemedicine Consultation',
				desc: 'This icon indicates telemedicine consultation',
				icon: (
					<IconElement
						type="isIndicator"
						color="transparent"
						colorIndicator={COLORS.primary}
					/>
				),
			},
			{
				title: 'In-Person Consultation',
				desc: 'This icon indicates in-person consultation',
				icon: (
					<IconElement
						type="isIndicator"
						color="transparent"
						colorIndicator={COLORS.blue}
					/>
				),
			},
			{
				title: 'Consult Now',
				desc: 'This icon indicates consult now',
				icon: (
					<IconElement
						type="isIndicator"
						color="transparent"
						colorIndicator={COLORS.yellowApp}
					/>
				),
			},
			{
				title: 'Personal Event',
				desc: 'This icon indicates your personal event',
				icon: (
					<IconElement
						type="isIndicator"
						color="transparent"
						colorIndicator={COLORS.greenApp}
					/>
				),
			},
			{
				title: 'Personal Event (Blocked)',
				desc: 'This icon indicates your personal event and blocked consultation schedule',
				icon: (
					<IconElement
						type="isIndicator"
						color="transparent"
						colorIndicator={COLORS.disabled}
					/>
				),
			},
			{
				title: 'Webinar',
				desc: 'This icon indicates webinar event',
				icon: (
					<IconElement
						type="isIndicator"
						color="transparent"
						colorIndicator={COLORS.purpleApp}
					/>
				),
			},
		],
		[],
	);

	const sectionsList: any = useMemo(
		() => [
			{
				id: '0',
				title: 'Schedule type',
				data: [...dataSchedule]
					?.map((item: any) => ({ ...item }))
					?.filter((item: any) => {
						let filter = 'isPatientOnly';
						if (isPatient) {
							filter = 'isNotPatient';
						}
						return item?.type !== filter;
					}),
			},
			{
				id: '1',
				title: 'Calendar information',
				data: [...dataCalendar]
					?.map((item: any) => ({ ...item }))
					?.filter((item: any) => {
						let filter = 'isPatientOnly';
						if (isPatient) {
							filter = 'isNotPatient';
						}
						return item?.type !== filter;
					}),
			},
			{
				id: '2',
				title: 'Calendar indicator type',
				data: dataIndicator,
			},
		],
		[dataSchedule, dataCalendar, dataIndicator],
	);

	return (
		<AppLayout activePath={activePath}>
			<Card style={{ paddingBottom: 110, padding: '24px 16px' }}>
				<PageHeader
					title="Schedule Icon Information"
					onClick={() => history.goBack()}
				/>
				<Divider />
				<div style={{ padding: '0 16px' }}>
					{sectionsList.map((item: any) => (
						<Section title={item.title} key={item.title}>
							<div style={{ marginTop: 16 }}>
								{item.data?.map((item: any) => (
									<>
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center',
											}}
											key={item.title}
										>
											{item?.icon}
											<div
												style={{
													marginLeft: 16,
													display: 'flex',
													flexDirection: 'column',
												}}
											>
												<Text
													style={{
														fontSize: 16,
													}}
												>
													{item?.title}
												</Text>
												<Text
													style={{
														color: COLORS.charcoal_200,
														fontSize: 14,
													}}
												>
													{item?.desc}
												</Text>
											</div>
										</div>
										<Divider style={{ margin: '16px 0' }} />
									</>
								))}
							</div>
						</Section>
					))}
				</div>
			</Card>
		</AppLayout>
	);
}

const IconElement = ({
	color,
	icon: Icon,
	type,
	textColor,
	colorIndicator,
}: any) => {
	let content = null;
	switch (type) {
		case 'isSchedule':
			content = <Icon width={20} height={20} />;
			break;
		case 'isCalendar':
			content = <Text style={{ color: textColor }}>29</Text>;
			break;
		case 'isIndicator':
			content = (
				<div style={{ ...indicator, ...{ backgroundColor: colorIndicator } }}>
					<span />
				</div>
			);
			break;
		default:
			break;
	}
	return (
		<div
			style={{
				...styleDefault,
				...{
					backgroundColor: color,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				},
			}}
		>
			{content}
		</div>
	);
};

function Section({ title, children }: any) {
	return (
		<div className="section">
			<Text
				style={{
					color: COLORS.charcoal_200,
					fontSize: 14,
				}}
			>
				{title}
			</Text>
			{children}
		</div>
	);
}
