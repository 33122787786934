import { PersonalEvent } from '../types/personalEvent.type';
import { httpRequest } from '../helpers/api';
import { ApiPaginationResults, ApiResponseSuccess } from '../types/api.type';

export async function apiGetPersonalEvent(
	eventId: string,
): Promise<PersonalEvent | undefined> {
	try {
		const res: ApiResponseSuccess<PersonalEvent> = await httpRequest.get(
			'/personal-events/' + eventId,
		);
		return res?.data?.payload;
	} catch (err) {
		console.error('ERROR', (err as any)?.data?.message);
	}
}

export async function apiGetListPersonalEvent(
	queryString?: string,
): Promise<ApiPaginationResults<PersonalEvent>> {
	try {
		const res: ApiResponseSuccess<ApiPaginationResults<PersonalEvent>> =
			await httpRequest.get('personal-events' + queryString);

		if (res?.data?.payload) {
			return res?.data?.payload;
		} else {
			return { results: [] };
		}
	} catch (err) {
		return Promise.reject(err);
	}
}
