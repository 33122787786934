import { message } from 'antd';
import { httpRequest } from '../helpers/api';
import { getErrorMessage } from '../helpers/errorHandler';
import { ApiResponsePagination } from '../types/apiResponse.type';
import { EmergencyContactProperties } from '../types/emergencyContact.type';

export async function apiGetEmergencyContacts(patientId: string) {
	try {
		const res = await httpRequest.get<
			ApiResponsePagination<EmergencyContactProperties>
		>(`emergency-contacts?patientId=${patientId}`);

		return res.data.payload.results;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}
