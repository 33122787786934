import { ChevronRight, Search } from 'react-feather';
import { theme } from '../../../../../assets/theme';
import FormInput from '../../../../Form/FormInput2';
import { ReactComponent as Clinical } from '../../../../../assets/icons/clinical.svg';
import { ReactComponent as Medicines } from '../../../../../assets/icons/medicines.svg';
import CustomScrollDiv from '../../../../CustomScrollDiv';
import Separator from '../../../../Separator';
import { Button } from '../../../../Button/Button/Button';
import { EPrescriptionState } from './SidebarPrescription';
import SidebarMenuHeader from '../Components/SidebarMenuHeader';
import { DrugMedicineProperties } from '../../../../../types/drugMedicine';
import { ReactComponent as Logo } from '../../../../../assets/icons/logo.svg';
import { TemplateRxPrescriptionProperties } from '../../../../../types/templateRxPrescription.type';
import useFetchList from '../../../../../hooks/useFetchList';
import Spinner from '../../../../Spinner';
import { useAuth } from '../../../../../context/auth.context';

interface Props {
	onClick: ({ state, dto }: { state: EPrescriptionState; dto?: any }) => void;
	onBack: () => void;
}

export default function SidebarPrescriptionAddMedicine(props: Props) {
	const { user } = useAuth();

	const {
		data: medicines,
		isLoading: isLoadingMedicine,
		pagination: paginationMedicine,
		changePage: changePageMedicine,
		setSearch: setSearchMedicines,
	} = useFetchList<DrugMedicineProperties>({
		endpoint: 'drug-medicines',
		initialQuery: {
			isPublished: true,
			limit: 1000000,
			order: 'drugMedicineName:ASC',
		},
		pushData: true,
		primaryKey: 'drugMedicineId',
	});

	const {
		data: templates,
		isLoading: isLoadingTemplate,
		pagination: paginationTemplate,
		changePage: changePageTemplate,
		setSearch: setSearchTemplates,
	} = useFetchList<TemplateRxPrescriptionProperties>({
		endpoint: 'template-rx-prescriptions',
		initialQuery: {
			doctorId: user?.userId,
		},
		pushData: true,
		primaryKey: 'templateRxPrescriptionId',
	});

	function handleNextPageMedicine() {
		if (!isLoadingMedicine && paginationMedicine.next) {
			changePageMedicine(paginationMedicine.page + 1);
		}
	}

	function handleNextPageTemplate() {
		if (!isLoadingTemplate && paginationTemplate.next) {
			changePageTemplate(paginationTemplate.page + 1);
		}
	}

	function onClickTemplate(template: TemplateRxPrescriptionProperties) {
		if (!template) return;

		props.onClick({
			state: EPrescriptionState.TEMPLATE_DETAIL,
			dto: { templateRxPrescription: template },
		});
	}

	function onClickMedicine(medicine: DrugMedicineProperties) {
		props.onClick({
			state: EPrescriptionState.MEDICINE_SELECTED,
			dto: { selectedMedicine: medicine },
		});
	}

	return (
		<div className="w-full flex flex-col h-full">
			<SidebarMenuHeader onBack={props.onBack} title="Prescription" />
			<div className="flex flex-1 min-h-0">
				<div className="flex flex-col w-full min-h-0 flex-1">
					<div className="pb-2">
						<FormInput
							placeholder="Search medicine or template name"
							onChange={(e) => {
								setSearchMedicines(e);
								setSearchTemplates(e);
							}}
							prefix={
								<Search
									width={20}
									height={20}
									color={theme.colors.charcoal200}
								/>
							}
						/>
					</div>
					<CustomScrollDiv className="flex overflow-auto w-full flex-1 min-h-0">
						<div className="flex flex-col w-full h-full">
							{templates.length > 0 ? (
								<div>
									<div className="px-4 py-3 flex items-center gap-x-2 bg-ash-300">
										<Clinical
											width={50}
											height={50}
											color={theme.colors.primary}
										/>
										<div>
											<p className="m-0 font-medium text-4.5">
												Prescription Template
											</p>
											<p className="m-0 text-3.5 text-charcoal-300">
												Use pre made prescription from your template
											</p>
										</div>
									</div>
									<div>
										{templates.map((template) => (
											<ItemTemplate
												key={template.templateRxPrescriptionId}
												title={template.templateName}
												subtitle={`${
													template.templatePrescriptionItems.length
												} medicine${
													template.templatePrescriptionItems.length > 1
														? 's'
														: ''
												}`}
												showBottomSeparator={true}
												onClick={() => onClickTemplate(template)}
											/>
										))}
									</div>
									{paginationTemplate.next && (
										<div className="py-4">
											<Button onClick={handleNextPageTemplate} type="OUTLINE">
												Load More
											</Button>
										</div>
									)}
								</div>
							) : isLoadingTemplate ? (
								<div className="p-4 w-fit mx-auto">
									<Spinner size={25} color={theme.colors.primary} />
								</div>
							) : undefined}
							{medicines.length > 0 ? (
								<div>
									<div className="px-4 py-3 flex items-center gap-x-2 bg-ash-300">
										<Medicines
											width={50}
											height={50}
											color={theme.colors.primary}
										/>
										<div>
											<p className="m-0 font-medium text-4.5">All Medicines</p>
											<p className="m-0 text-3.5 text-charcoal-300">
												Add medicine individually to your perscription
											</p>
										</div>
									</div>
									<div>
										{medicines.map((medicine) => (
											<ItemMedicine
												key={medicine.drugMedicineId}
												title={medicine.drugMedicineName ?? ''}
												showBottomSeparator={true}
												point={medicine.points}
												onClick={() => onClickMedicine(medicine)}
											/>
										))}
									</div>
									{paginationMedicine.next && (
										<div className="py-4">
											<Button onClick={handleNextPageMedicine} type="OUTLINE">
												Load More
											</Button>
										</div>
									)}
								</div>
							) : isLoadingMedicine ? (
								<div className="p-4 w-fit mx-auto">
									<Spinner size={25} color={theme.colors.primary} />
								</div>
							) : undefined}
						</div>
					</CustomScrollDiv>
				</div>
			</div>
		</div>
	);
}

function ItemTemplate(props: {
	title?: string;
	subtitle?: string;
	showBottomSeparator?: boolean;
	onClick: () => void;
}) {
	return (
		<>
			<button
				onClick={props.onClick}
				className="bg-transparent border-none m-0 w-full flex text-left items-center py-4 cursor-pointer"
			>
				<div className="flex-1">
					<p className="m-0 text-4.5 line-clamp-1">{props.title}</p>
					<p className="m-0 mt-1 text-black-70 text-3.5">{props.subtitle}</p>
				</div>
				<ChevronRight width={20} height={20} color={theme.colors.black30} />
			</button>
			{props.showBottomSeparator && <Separator />}
		</>
	);
}

function ItemMedicine(props: {
	title: string;
	point?: number;
	showBottomSeparator?: boolean;
	onClick: () => void;
}) {
	return (
		<>
			<button
				onClick={props.onClick}
				className="bg-transparent border-none m-0 w-full flex gap-x-2 text-left items-center py-4 cursor-pointer"
			>
				<div className="flex-1 flex items-center gap-x-2">
					<p className="m-0 text-4.5 line-clamp-1">{props.title}</p>
					{props.point ? (
						<Logo width={18} height={18} className="shrink-0" />
					) : undefined}
				</div>
				<ChevronRight width={20} height={20} color={theme.colors.black30} />
			</button>
			{props.showBottomSeparator && <Separator />}
		</>
	);
}
