import { message } from 'antd';
import { httpRequest } from '../helpers/api';
import { getErrorMessage } from '../helpers/errorHandler';
import { ApiResponseDefault } from '../types/apiResponse.type';
import { MedicalRecordProperties } from '../types/medicalRecord.type';

export async function apiGetMedicalNotes(patientId: string) {
	if (!patientId) return;

	try {
		const res = await httpRequest.get<
			ApiResponseDefault<MedicalRecordProperties>
		>(`medical-records?patientId=${patientId}`);
		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}

export async function apiUpdateMedicalNotes(
	medicalNotesId: string,
	data: Partial<MedicalRecordProperties>,
) {
	try {
		const res = await httpRequest.patch<
			ApiResponseDefault<MedicalRecordProperties>
		>(`medical-records/${medicalNotesId}`, data);

		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}
