import { Switch } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { ArrowLeft, Mic, MicOff, Video, VideoOff } from 'react-feather';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { LocalVideoTrack } from 'twilio-video';
import { apiExtendConsultation } from '../../../../api/transactionConsultation';
import { theme } from '../../../../assets/theme';
import { getInitialName, getName } from '../../../../helpers/name';
import { getBirthdate, getGender } from '../../../../helpers/profile';
import useWindowSize from '../../../../hooks/useWindowSize';
import {
	EConsultationType,
	ETransactionStatusType,
	TransactionConsultationProperties,
} from '../../../../types/transactionConsultation.type';
import Separator from '../../../Separator';
import Spinner from '../../../Spinner';
import BtnTeleconferenceCtr from '../Components/BtnTeleconferenceCtr';
import TeleconferenceRoomInfo from '../Components/TeleconferenceRoomInfo';

interface Props {
	roomType: 'VIDEO_CHAT' | 'CHAT' | 'IN_PERSON' | undefined;
	transactionConsultation?: TransactionConsultationProperties;
	localVideoTrack?: LocalVideoTrack;
	isMicOn: boolean;
	isVideoOn: boolean;
	isLoadingFetchConsultation: boolean;
	isLoadingJoinRoom: boolean;
	toggleMic: () => void;
	toggleVideo: () => void;
	startTeleconference: () => void;
}

export default function WaitingRoom(props: Props) {
	const widthScreen = useWindowSize().width;
	const history = useHistory();
	const [extendConsult, setExtendConsult] = useState(
		props.transactionConsultation?.isCanExtend,
	);

	useEffect(() => {
		if (!props.transactionConsultation) return;

		setExtendConsult(props.transactionConsultation.isCanExtend);
	}, [props.transactionConsultation]);

	useEffect(() => {
		if (!props.localVideoTrack) {
			if (document.getElementById('local-media')?.innerHTML) {
				document.getElementById('local-media')!.innerHTML = '';
			}
			return;
		}

		document
			.getElementById('local-media')
			?.appendChild(props.localVideoTrack.attach());
		document
			.getElementById('local-media')
			?.firstElementChild?.classList.add('w-full', 'h-full', 'object-cover');
	}, [props.localVideoTrack]);

	async function onChangeExtendConsult(isCanExtend: boolean) {
		if (!props.transactionConsultation?.transactionConsultationId) return;

		const res = await apiExtendConsultation(
			props.transactionConsultation?.transactionConsultationId,
			isCanExtend,
		);
		if (res) {
			setExtendConsult(res.isCanExtend);
		}
	}

	return (
		<div className="w-full lg:p-6 bg-white rounded-6">
			<div className="relative py-4 lg:py-6 px-7 flex items-center">
				<ButtonBack onClick={() => history.goBack()}>
					<ArrowLeft width={20} height={20} />
					<span className="font-bold text-3.5">Back</span>
				</ButtonBack>
				<p className="m-0 w-full font-bold text-5 text-center">
					Start {getTitle(props.transactionConsultation?.consultationType)}
				</p>
			</div>
			{widthScreen < 1024 && <Separator />}
			<div className="mt-6 lg:mt-0 py-3 lg:py-0 px-10 lg:px-16 flex lg:flex-row flex-col gap-x-6">
				<div
					className="relative flex flex-col items-center aspect-video rounded-3 overflow-hidden"
					style={{ width: widthScreen >= 1024 ? '63%' : '100%' }}
				>
					{props.roomType ? (
						props.roomType === 'VIDEO_CHAT' ? (
							<>
								<div id="local-media" className="w-full h-full bg-gray-80" />
								<div className="absolute bottom-4 flex gap-x-12">
									<BtnTeleconferenceCtr onClick={props.toggleMic}>
										{props.isMicOn ? (
											<Mic width={32} height={32} />
										) : (
											<MicOff width={32} height={32} />
										)}
									</BtnTeleconferenceCtr>
									<BtnTeleconferenceCtr onClick={props.toggleVideo}>
										{props.isVideoOn ? (
											<Video width={32} height={32} />
										) : (
											<VideoOff width={32} height={32} />
										)}
									</BtnTeleconferenceCtr>
								</div>
							</>
						) : (
							<div className="relative p-6 rounded-6 border border-solid border-gray-20 w-full h-full flex flex-col items-center justify-center">
								<div
									className="absolute rounded-full border-2 border-solid border-gray-50 opacity-10 aspect-square"
									style={{
										width: '90%',
										top: '-120%',
										right: '-5%',
									}}
								/>
								<div
									className="absolute rounded-full border-2 border-solid border-gray-50 opacity-10 aspect-square"
									style={{
										width: '100%',
										top: '-110%',
										right: '-23%',
									}}
								/>
								<div
									className="absolute rounded-full border-2 border-solid border-gray-50 opacity-10 aspect-square"
									style={{
										width: '110%',
										top: '80%',
										left: '-60%',
									}}
								/>
								<TeleconferenceRoomInfo
									consultationType={
										props.transactionConsultation?.consultationType
									}
									partnerName={
										props.transactionConsultation?.metaPartner?.partnerName
									}
									partnerAddress={
										props.transactionConsultation?.metaPartner?.address
									}
								/>
							</div>
						)
					) : (
						<div className="w-full h-full flex items-center justify-center">
							<Spinner size={25} color={theme.colors.primary} />
						</div>
					)}
				</div>
				{widthScreen < 1024 && (
					<Separator className="mt-6 mb-3" style={{ height: 3 }} />
				)}
				<div className="flex-1 flex flex-col px-4 text-4">
					<div>
						<p className="m-0 font-bold">Patient</p>
						<div className="mt-4 flex items-center gap-x-2">
							{!props.isLoadingFetchConsultation ? (
								<>
									<div className="w-15 h-15 rounded-full bg-charcoal-300 flex items-center justify-center overflow-hidden">
										{props.transactionConsultation?.metaPatient?.profilePic ? (
											<img
												src={
													props.transactionConsultation.metaPatient.profilePic
												}
											/>
										) : (
											<span className="font-bold text-white">
												{getInitialName(
													props.transactionConsultation?.metaPatient,
												)}
											</span>
										)}
									</div>
									{props.transactionConsultation !== undefined && (
										<div className="h-full flex flex-col justify-between">
											<p className="m-0 font-medium">
												{getName(props.transactionConsultation.metaPatient)}
											</p>
											<p className="m-0 text-charcoal-200">
												{getGender(props.transactionConsultation.metaPatient)} •{' '}
												{moment().diff(
													getBirthdate(
														props.transactionConsultation.metaPatient,
													),
													'years',
													false,
												)}{' '}
												yo
											</p>
										</div>
									)}
								</>
							) : (
								<Spinner size={25} color={theme.colors.primary} />
							)}
						</div>
						<div className="mt-4.5">
							<p className="m-0 text-black-60">Reason for consultation</p>
							{!props.isLoadingFetchConsultation ? (
								<p
									className={
										'm-0' +
										(props.transactionConsultation?.reasonOfConsultation
											? ' text-black-80'
											: '')
									}
								>
									{props.transactionConsultation?.reasonOfConsultation || '-'}
								</p>
							) : (
								<Spinner size={25} color={theme.colors.primary} />
							)}
						</div>
					</div>
					<div className="mt-auto">
						<Separator className="my-6" />
						<div className="flex items-center gap-x-2">
							<div>
								<p className="m-0">Enable extend telemedicine</p>
								<p className="m-0 text-3.5 text-charcoal-300">
									Enable this feature if you accept to extend telemedicine more
									than 15 mins. (Maximum of 25 mins)
								</p>
							</div>
							<div>
								{!props.isLoadingFetchConsultation ? (
									<Switch
										checked={extendConsult}
										onChange={onChangeExtendConsult}
									/>
								) : (
									<Spinner size={25} color={theme.colors.primary} />
								)}
							</div>
						</div>
						<div className="mt-6">
							<BtnStartConsultation
								isLoading={props.isLoadingJoinRoom}
								isDisable={props.transactionConsultation === undefined}
								onClick={props.startTeleconference}
							>
								{props?.transactionConsultation?.transactionStatus !==
									ETransactionStatusType.COMPLETED && 'Start Now'}
								{props?.transactionConsultation?.transactionStatus ===
									ETransactionStatusType.COMPLETED &&
									props.transactionConsultation?.consultationType ===
										(EConsultationType.CONSULT_NOW_CHAT_CONSULTATION ||
											EConsultationType.TELEMEDICINE_CHAT_CONSULTATION) &&
									'See Chat History'}
								{props?.transactionConsultation?.transactionStatus ===
									ETransactionStatusType.COMPLETED &&
									props.transactionConsultation?.consultationType ===
										EConsultationType.TELEMEDICINE_VIDEO_CHAT_CONSULTATION &&
									'Telemedicine has ended'}
							</BtnStartConsultation>
						</div>
					</div>
				</div>
			</div>
			<div className="relative py-6 px-3 flex items-center" />
		</div>
	);
}

function BtnStartConsultation(props: {
	children: any;
	onClick: () => void;
	isDisable: boolean;
	isLoading: boolean;
}) {
	const isDisable = props.isDisable || props.isLoading;

	return (
		<button
			onClick={!props.isDisable ? props.onClick : undefined}
			className={`m-0 p-4 w-full flex justify-center border-none rounded-4 ${
				!isDisable ? 'bg-primary' : 'bg-ash-300'
			} ${!isDisable ? 'text-white cursor-pointer' : 'text-ash-700'} font-bold`}
		>
			{!props.isLoading ? (
				props.children
			) : (
				<Spinner size={25} color={theme.colors.ash700} />
			)}
		</button>
	);
}

function getTitle(type?: EConsultationType) {
	switch (type) {
		case EConsultationType.CONSULT_NOW_CHAT_CONSULTATION:
		case EConsultationType.CONSULT_NOW_VIDEO_CHAT_CONSULTATION:
			return 'Consult Now';
		case EConsultationType.TELEMEDICINE_CHAT_CONSULTATION:
		case EConsultationType.TELEMEDICINE_VIDEO_CHAT_CONSULTATION:
			return 'Telemedicine';
		case EConsultationType.FACE_TO_FACE_CONSULTATION:
			return 'In Person Consultation';
		default:
			return 'Consultation';
	}
}

const ButtonBack = styled.button`
	position: relative;
	display: flex;
	align-items: center;
	margin: 0;
	border: none;
	cursor: pointer;
	background: transparent;

	@media (min-width: 1024px) {
		padding: 24px 12px;
	}
`;
