import { useMemo, useState } from 'react';
import { CheckSquare, Plus, Square, Trash2 } from 'react-feather';
import { theme } from '../../../../../assets/theme';
import EmptyList from '../../../../EmptyList';
import FormInput, { EFormType } from '../../../../Form/FormInput2';
import { ReactComponent as MedicinesIcon } from '../../../../../assets/icons/medicines.svg';
import IconButton from '../../../../Button/IconButton';
import { EPrescriptionState } from './SidebarPrescription';
import CustomScrollDiv from '../../../../CustomScrollDiv';
import SidebarMenuHeader from '../Components/SidebarMenuHeader';
import { ReactComponent as Logo } from '../../../../../assets/icons/logo.svg';
import Separator from '../../../../Separator';
import { RxPrescriptionProperties } from '../../../../../types/rxPrescription.type';
import getMedicineName from '../../../../../helpers/getMedicineName';
import { IUser } from '../../../../../types/user.type';
import ButtonRemoveSave from '../Components/ButtonRemoveSave';
import {
	apiCreateRxPrescription,
	apiDeleteRxPrescription,
	apiUpdateRxPrescription,
} from '../../../../../api/rxPrescription';
import { apiCreateTemplateRxPrescription } from '../../../../../api/templateRxPrescription';
import { useAuth } from '../../../../../context/auth.context';

interface Props {
	transactionConsultationId?: string;
	patientProfile?: IUser;
	rxPrescription?: RxPrescriptionProperties;
	setRxPrescription: React.Dispatch<
		React.SetStateAction<RxPrescriptionProperties | undefined>
	>;
	saveRxPrescription: React.Dispatch<
		React.SetStateAction<RxPrescriptionProperties | undefined>
	>;
	onNext: ({ state, dto }: { state: EPrescriptionState; dto?: any }) => void;
	onBack: () => void;
}

export default function SidebarPrescriptionListMedicines(props: Props) {
	const { user } = useAuth();

	const [isLoading, setIsLoading] = useState(false);
	const [templateName, setTemplateName] = useState<string>();

	const isDisableButton: boolean = useMemo(
		() =>
			!props.rxPrescription?.prescriptionItems ||
			props.rxPrescription?.prescriptionItems.length === 0 ||
			!props.rxPrescription?.validUntil ||
			props.rxPrescription?.validUntil.length === 0,
		[props.rxPrescription],
	);

	async function removePrescription() {
		if (!props.rxPrescription?.rxPrescriptionId) {
			props.onBack();
			return;
		}

		setIsLoading(true);

		const res = await apiDeleteRxPrescription(
			props.rxPrescription.rxPrescriptionId,
		);
		if (res?.isSuccess) {
			props.saveRxPrescription(undefined);
			props.onBack();
		} else {
			setIsLoading(false);
		}
	}

	async function savePrescription() {
		if (!props.rxPrescription) return;

		setIsLoading(true);

		let isSuccess = false;

		let data: RxPrescriptionProperties = {
			...props.rxPrescription,
			isTemporary: true,
		};

		let rxPrescriptionId = '';
		if (props.rxPrescription.rxPrescriptionId) {
			const res = await apiUpdateRxPrescription(
				props.rxPrescription.rxPrescriptionId,
				data,
			);
			if (res) {
				if (res.rxPrescriptionId) {
					rxPrescriptionId = res.rxPrescriptionId;
				}
				props.saveRxPrescription(res);
				isSuccess = true;
			}
		} else {
			data = {
				...data,
				transactionConsultationId: props.transactionConsultationId,
				doctorId: user?.userId,
				patientId: props.patientProfile?.userId,
				metaPatient: {
					firstName: props.patientProfile?.firstName,
					middleName: props.patientProfile?.middleName,
					lastName: props.patientProfile?.lastName,
					birthdate: props.patientProfile?.birthdate,
					gender: props.patientProfile?.gender,
				},
			};
			const res = await apiCreateRxPrescription(data);
			if (res) {
				if (res.rxPrescriptionId) {
					rxPrescriptionId = res.rxPrescriptionId;
				}
				props.saveRxPrescription(res);
				isSuccess = true;
			}
		}

		if (templateName && data.prescriptionItems) {
			isSuccess = false;
			const res = await apiCreateTemplateRxPrescription({
				doctorId: user?.userId || '',
				templateName,
				saveFromId: rxPrescriptionId,
				templatePrescriptionItems: data.prescriptionItems,
			});
			if (res) {
				isSuccess = true;
			}
		}

		if (isSuccess) {
			props.onBack();
		} else {
			setIsLoading(false);
		}
	}

	function onDeleteMedicineItem(id: string) {
		if (!props.rxPrescription?.prescriptionItems) return;

		const prescriptionItems = [
			...props.rxPrescription.prescriptionItems,
		].filter((item) => item.drugMedicineId !== id);
		props.setRxPrescription((old) => ({ ...old, prescriptionItems }));
	}

	return (
		<div className="w-full flex flex-col h-full">
			<SidebarMenuHeader onBack={props.onBack} title="Prescription" />
			<div>
				<FormInput
					label="Valid Until"
					value={props.rxPrescription?.validUntil}
					onChange={(val) =>
						props.setRxPrescription((old) => ({ ...old, validUntil: val }))
					}
					type={EFormType.DATE_PICKER}
				/>
			</div>
			<div className="mt-4 mb-1 flex items-center">
				<p className="flex-1 m-0 text-4 text-black-60">Medicines</p>
				<ButtonAddMedicine
					onClick={() =>
						props.onNext({ state: EPrescriptionState.ADD_MEDICINE })
					}
				/>
			</div>
			<CustomScrollDiv className="flex flex-col overflow-auto w-full h-full">
				<div>
					{props.rxPrescription?.prescriptionItems &&
					props.rxPrescription.prescriptionItems.length > 0 ? (
						props.rxPrescription.prescriptionItems.map((medicine) => (
							<ItemMedicine
								key={medicine.drugMedicineId}
								title={getMedicineName(medicine.drugMedicineDetail)}
								subtitle1={`qty. ${medicine.quantity}`}
								subtitle2={medicine.sig}
								point={medicine.drugMedicineDetail.points}
								onClick={() =>
									props.onNext({ state: EPrescriptionState.MEDICINE_DETAIL })
								}
								onDelete={() => onDeleteMedicineItem(medicine.drugMedicineId)}
								useBottomSeparator
							/>
						))
					) : (
						<EmptyList
							icon={<MedicinesIcon color={theme.colors.black50} />}
							title="Prescription Empty"
							description="You can add prescription from template or add medicines manually"
						/>
					)}
				</div>
			</CustomScrollDiv>
			<div className="border-t-solid border-t border-gray-10 py-3 px-4">
				<div className="flex gap-x-2">
					<IconButton
						onClick={() =>
							setTemplateName((old) => (old !== undefined ? undefined : ''))
						}
					>
						{templateName !== undefined ? (
							<CheckSquare color={theme.colors.primary} />
						) : (
							<Square color={theme.colors.ash800} />
						)}
					</IconButton>
					<p className="m-0 text-4">Save this prescription as template</p>
				</div>
				{templateName !== undefined && (
					<FormInput
						label="Template Name"
						type={EFormType.SINGLE_LINE}
						placeholder="Write here"
						value={templateName}
						onChange={setTemplateName}
					/>
				)}
				<div className="mt-4">
					<ButtonRemoveSave
						isEnableRemoveBtn={
							!isLoading &&
							(!isDisableButton || !!props.rxPrescription?.rxPrescriptionId)
						}
						isEnableSaveBtn={!isLoading && !isDisableButton}
						onRemove={removePrescription}
						onSave={savePrescription}
					/>
				</div>
			</div>
		</div>
	);
}

function ButtonAddMedicine(props: { onClick: any }) {
	return (
		<button
			onClick={props.onClick}
			className="flex items-center gap-x-1 text-primary text-4 m-0 p-0 bg-transparent border-none cursor-pointer"
		>
			<Plus color={theme.colors.primary} />
			<span>Add Medicine</span>
		</button>
	);
}

function ItemMedicine(props: {
	title: string;
	subtitle1: string;
	subtitle2: string;
	point?: number;
	useBottomSeparator?: boolean;
	onClick: () => void;
	onDelete: () => void;
}) {
	return (
		<>
			<div className="w-full py-3 flex items-center gap-x-2">
				<button
					onClick={props.onClick}
					className="flex-1 p-0 border-none bg-transparent cursor-pointer text-left"
				>
					<div className="flex-1 flex items-center gap-x-2">
						<p className="m-0 text-4.5 line-clamp-1">{props.title}</p>
						{props.point ? (
							<Logo width={18} height={18} className="shrink-0" />
						) : undefined}
					</div>
					<p className="m-0 text-3.5 text-black-70">{props.subtitle1}</p>
					<p className="m-0 text-3.5 text-black-70">{props.subtitle2}</p>
				</button>
				<button
					onClick={props.onDelete}
					className="p-0 bg-transparent border-none cursor-pointer"
				>
					<Trash2 width={24} height={24} color={theme.colors.primary} />
				</button>
			</div>

			{props.useBottomSeparator && <Separator />}
		</>
	);
}
