import React from 'react';
import { Button, Col, Divider, Modal, Row, Typography } from 'antd';

type Props = {
	isVisible: boolean;
	setVisibility: (state: boolean) => void;
	actionHandler: () => any;
};

const { Text } = Typography;

export default function ModalConfirmationChangePhone(props: Props) {
	return (
		<Modal
			className="modal"
			open={props.isVisible}
			footer={null}
			closable={false}
		>
			<div className="">
				<Row className="flex justify-center">
					<Text className="text-6">Confirmation</Text>
				</Row>
				<Row className="flex justify-center mt-2 mb-6">
					<Text className="text-4.5 text-center">
						Are you sure you want to change your cellphone number?
					</Text>
					<Text className="text-4.5 text-center">
						If yes, all data will be reset again
					</Text>
				</Row>
			</div>
			<Divider style={{ margin: '12px 0px' }} />

			<Row gutter={24} className="flex justify-center mt-2.5">
				<Col span={11} className="flex justify-center">
					<Button
						type="text"
						size="large"
						onClick={() => props.setVisibility(false)}
						className="text-3.5 text-secondary rounded-3 bg-gray-10 border-0 w-full font-bold"
					>
						<Text style={{ fontSize: 14, fontWeight: 700 }}>Cancel</Text>
					</Button>
				</Col>
				<Col span={11} className="flex justify-center">
					<Button
						type="primary"
						size="large"
						onClick={() => props.actionHandler()}
						className="rounded-3 bg-primary w-full"
					>
						<Text style={{ fontSize: 14, fontWeight: 700, color: '#fff' }}>
							Yes, Sure
						</Text>
					</Button>
				</Col>
			</Row>
		</Modal>
	);
}
