import React, { useEffect, useState } from 'react';
import AppLayout from '../layout/AppLayout';
import SectionTitle from '../components/SectionTitle';
import { httpRequest } from '../helpers/api';
import { IHttpResponse } from '../helpers/pagination';
import { message, Skeleton } from 'antd';
import RenderHTML from '../components/RenderHTML';

type Props = {
	type: 'terms-and-conditions' | 'privacy-policy';
};
const SinglePage: React.FC<Props> = ({ type }) => {
	const [content, setContent] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		setIsLoading(true);
		(async () => {
			try {
				let fetch =
					await httpRequest.get<IHttpResponse<{ content: string }>>(type);
				setContent(fetch.data.payload.content);
			} catch (err) {
				console.error(err);
				message.error('Failed to get data!');
			}
		})();
		setIsLoading(false);

		// back to top
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, [type]);
	return (
		<AppLayout>
			<article>
				<SectionTitle
					title={
						type === 'terms-and-conditions'
							? 'Term & Conditions'
							: 'Privacy and Policy'
					}
				/>
				<div className="single-content container container-sm">
					{isLoading || !content ? (
						<Skeleton />
					) : (
						<RenderHTML content={content} />
					)}
				</div>
			</article>
		</AppLayout>
	);
};
export default SinglePage;
