import { isNumber } from 'lodash';
import { useEffect, useState } from 'react';
import { EditDrugMedicineProperties } from '../../../../../../types/drugMedicine';
import {
	RxPrescriptionItem,
	RxPrescriptionProperties,
} from '../../../../../../types/rxPrescription.type';
import { Button } from '../../../../../Button/Button/Button';
import FormInput, { EFormType } from '../../../../../Form/FormInput2';
import Separator from '../../../../../Separator';
import { EModalAddMedicineState } from './ModalAddMedicine';

interface Props {
	medicine?: EditDrugMedicineProperties;
	setState: (state: EModalAddMedicineState) => void;
	setPrescription: React.Dispatch<
		React.SetStateAction<RxPrescriptionProperties | undefined>
	>;
	onCloseModal: () => void;
}

export default function ModalContentFormAddMedicine(props: Props) {
	const [prescriptionItem, setPrescriptionItem] =
		useState<Partial<RxPrescriptionItem>>();

	useEffect(() => {
		if (!props.medicine) return;

		setPrescriptionItem((old) => ({
			...old,
			quantity: props.medicine?.quantity,
			sig: props.medicine?.sig,
			drugMedicineDetail: props.medicine,
		}));
	}, [props.medicine]);

	function appendPrescription() {
		props.setPrescription((old) => {
			const prescriptionItems = old?.prescriptionItems ?? [];

			const findIndex = (): number => {
				if (prescriptionItem?.drugMedicineDetail?.drugMedicineId) {
					const index = prescriptionItems.findIndex(
						(prescription) =>
							prescription.drugMedicineDetail.drugMedicineId ===
							prescriptionItem?.drugMedicineDetail?.drugMedicineId,
					);
					return index;
				} else {
					const index = prescriptionItems.findIndex(
						(prescription) =>
							prescription.drugMedicineDetail.drugMedicineName ===
							prescriptionItem?.drugMedicineDetail?.drugMedicineName,
					);
					return index;
				}
			};

			const index = findIndex();

			if (index >= 0) {
				if (
					prescriptionItem?.drugMedicineDetail?.drugMedicineName !==
					prescriptionItems[index].drugMedicineDetail?.drugMedicineName
				) {
					let prescriptionItemTmp: Partial<RxPrescriptionItem> = {
						drugMedicineDetail: {
							drugMedicineName:
								prescriptionItem?.drugMedicineDetail?.drugMedicineName,
							dose: prescriptionItem?.drugMedicineDetail?.dose,
						},
						quantity: prescriptionItem?.quantity || 0,
						sig: prescriptionItem?.sig || '',
					};
					prescriptionItems[index] = prescriptionItemTmp as RxPrescriptionItem;
				} else {
					prescriptionItems[index] = {
						...(prescriptionItem as RxPrescriptionItem),
						drugMedicineId:
							prescriptionItem!.drugMedicineDetail!.drugMedicineId!,
					};
				}
			} else {
				prescriptionItems.push(prescriptionItem as RxPrescriptionItem);
			}

			return { ...old, prescriptionItems };
		});
		props.onCloseModal();
	}

	return (
		<div className="h-full flex flex-col">
			<div className="px-4 py-1">
				<FormInput
					label="Name"
					placeholder="Input medicine name"
					value={prescriptionItem?.drugMedicineDetail?.drugMedicineName}
					onChange={
						!props.medicine
							? (val) =>
									setPrescriptionItem((old) => ({
										...old,
										drugMedicineDetail: {
											...old?.drugMedicineDetail,
											drugMedicineName: val,
										},
									}))
							: (val) =>
									setPrescriptionItem((old) => ({
										...old,
										drugMedicineId: undefined,
										drugMedicineDetail: {
											...old?.drugMedicineDetail,
											partner: undefined,
											points: undefined,
											drugMedicineName: val,
										},
									}))
					}
					disabled={!!prescriptionItem?.drugMedicineDetail?.partner || false}
				/>
				<div className="flex gap-x-5 justify-between">
					<div className="flex-1">
						<FormInput
							label="Dosage"
							placeholder="eg. 300mg"
							value={prescriptionItem?.drugMedicineDetail?.dose}
							onChange={(val) =>
								setPrescriptionItem((old) => ({
									...old,
									drugMedicineDetail: {
										...old?.drugMedicineDetail,
										dose: val,
									},
								}))
							}
						/>
					</div>
					<div className="flex-1">
						<FormInput
							label="Quantity"
							placeholder="eg. 20"
							value={prescriptionItem?.quantity?.toString()}
							onChange={(val) => {
								const qty = Number(val);
								if (isNumber(qty) && !isNaN(qty)) {
									setPrescriptionItem((old) => ({
										...old,
										quantity: qty,
									}));
								}
							}}
						/>
					</div>
				</div>
				<FormInput
					label="Sig."
					placeholder="eg. 1 cap before meal per consumption"
					value={prescriptionItem?.sig}
					onChange={(val) =>
						setPrescriptionItem((old) => ({
							...old,
							sig: val,
						}))
					}
					type={EFormType.MULTI_LINE}
				/>
			</div>
			<Separator />
			<div className="p-3 flex justify-between">
				<div className="flex-1 max-w-48">
					<Button
						type="SOLIDASH"
						onClick={() =>
							props.setState(
								props.medicine
									? EModalAddMedicineState.SELECTED_MEDICINE
									: EModalAddMedicineState.ADD_MEDICINE,
							)
						}
					>
						Back
					</Button>
				</div>
				<div className="flex-1 max-w-48">
					<Button
						type="SOLID"
						onClick={appendPrescription}
						isDisable={
							!prescriptionItem?.quantity ||
							!prescriptionItem.sig ||
							!prescriptionItem.drugMedicineDetail?.drugMedicineName ||
							!prescriptionItem.drugMedicineDetail.dose
						}
					>
						Create
					</Button>
				</div>
			</div>
		</div>
	);
}
