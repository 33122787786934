import { Layout, Col, Row, Typography, Space } from 'antd';
import { Link } from 'react-router-dom';
import useModal from '../hooks/useModal';
import { useGlobal } from '../context/global.context';

const { Footer } = Layout;
const { Title, Text } = Typography;

type Props = {
	setShowComingSoon: (value: boolean) => void;
};

const AppFooter: React.FC<Props> = ({ setShowComingSoon }) => {
	const { setIsShowModalStore } = useModal();
	const { setIsShowSignInModal } = useGlobal();

	return (
		<Footer id="footer">
			<div className="container container-sm">
				<Row gutter={[40, 40]}>
					<Col xs={24} md={24} lg={8}>
						<div className="logo-footer">
							<img src="/Logo.svg" alt="Medeasy logo" />
							<span>Medeasy App</span>
						</div>
					</Col>
					<Col xs={24} md={6} lg={4}>
						<Title level={5}>Company</Title>
						<ul className="links">
							<li>
								<Link to="/#features">About Us</Link>
							</li>
							<li>
								<Link
									to="#coming-soon-modal"
									onClick={() => setShowComingSoon(true)}
								>
									Blog
								</Link>
							</li>
							<li>
								<Link
									to="#coming-soon-modal"
									onClick={() => setShowComingSoon(true)}
								>
									FAQ
								</Link>
							</li>
							<li>
								<Link
									to="#coming-soon-modal"
									onClick={() => setShowComingSoon(true)}
								>
									Contact US
								</Link>
							</li>
						</ul>
					</Col>
					<Col xs={24} md={6} lg={4}>
						<Title level={5}>Services</Title>
						<ul className="links">
							<li>
								<Link
									to="#coming-soon-modal"
									onClick={() => setShowComingSoon(true)}
								>
									Find Doctor
								</Link>
							</li>
							<li>
								<Link
									to="#coming-soon-modal"
									onClick={() => setShowComingSoon(true)}
								>
									Medical Procedures
								</Link>
							</li>
							<li>
								<Link
									to="#coming-soon-modal"
									onClick={() => setIsShowModalStore(true)}
								>
									Medeasy Store
								</Link>
							</li>
						</ul>
					</Col>
					<Col xs={24} md={6} lg={4}>
						<Title level={5}>Others</Title>
						<ul className="links">
							<li>
								<Link to="/privacy-policy">Privacy Policy</Link>
							</li>
							<li>
								<Link to="/term-and-conditions">Term & Conditions</Link>
							</li>
							<li>
								<Link
									to="#coming-soon-modal"
									onClick={() => setShowComingSoon(true)}
								>
									Advertisement
								</Link>
							</li>
							<li>
								<Link
									to="#coming-soon-modal"
									onClick={() => {
										setIsShowSignInModal(true);
									}}
								>
									Join as Doctor
								</Link>
							</li>
							<li>
								<Link
									to="#coming-soon-modal"
									onClick={() => setShowComingSoon(true)}
								>
									Register your hospital
								</Link>
							</li>
							<li>
								<Link to="/request-delete-account">Request delete account</Link>
							</li>
						</ul>
					</Col>
					<Col xs={24} md={6} lg={4}>
						<Title level={5}>Download App</Title>
						<Space
							className="footer-download"
							direction="horizontal"
							size={24}
							wrap={true}
						>
							<Link
								to="#coming-soon-modal"
								onClick={() =>
									window.open(
										'https://play.google.com/store/apps/details?id=com.medeasyapp&hl=en&gl=US',
										'_blank',
										'noreferrer',
									)
								}
							>
								<img
									src="/images/google-play-download-icon.png"
									alt="Get it on Google Play"
								/>
							</Link>
							<Link
								to="#coming-soon-modal"
								onClick={() =>
									window.open(
										'https://apps.apple.com/id/app/medeasy-ph/id6443653585?l=id',
										'_blank',
										'noreferrer',
									)
								}
							>
								<img
									src="/images/app-store-download-icon.png"
									alt="Get it on App Store"
								/>
							</Link>
						</Space>
					</Col>
				</Row>
				<div className="copyright center">
					<Text className="text-gray-50">
						Copyright &copy; 2022 Medeasy v{process.env.REACT_APP_VERSION_NAME}{' '}
						All rights reserved
					</Text>
				</div>
			</div>
		</Footer>
	);
};
export default AppFooter;
