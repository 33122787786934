import { Modal } from 'antd';
import { Button } from '../../../../../Button/Button/Button';
import ModalContentAddICD from './ModalContentAddICD';
import { ICDProperties } from '../../../../../../types/icd.type';

interface Props {
	isShow: boolean;
	isHideTemplate?: boolean;
	onCloseModal: () => void;
	icds?: ICDProperties[];
	setIcds: React.Dispatch<React.SetStateAction<ICDProperties[] | undefined>>;
}

export default function ModalAddICD(props: Props) {
	return (
		<Modal
			open={props.isShow}
			onCancel={props.onCloseModal}
			footer={
				<Button onClick={props.onCloseModal} type={'SOLIDASH'}>
					Close
				</Button>
			}
			title={<p className="m-0 text-center font-semibold">Add ICD</p>}
			bodyStyle={{ padding: 0 }}
			wrapClassName="modal-h-full"
			style={{ height: 700 }}
		>
			<div className="flex-1 min-h-0">
				<ModalContentAddICD icds={props.icds} setIcds={props.setIcds} />
			</div>
		</Modal>
	);
}
