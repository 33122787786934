import React from 'react';
import { Typography } from 'antd';

type Props = {
	subtitle?: string;
	title: string;
};

const { Title, Text } = Typography;
const SectionTitle: React.FC<Props> = ({ title, subtitle }) => {
	return (
		<header id="section-title">
			<div className="container container-sm">
				<Text>{subtitle || 'Medeasy'}</Text>
				<Title level={1}>{title}</Title>
			</div>
		</header>
	);
};
export default SectionTitle;
