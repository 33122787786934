import { useState } from 'react';
import { ArrowLeft } from 'react-feather';
import { apiUpdateDoctorDocument } from '../../../../api/doctorDocument';
import { EMetaType } from '../../../../types/doctorDocument.type';
import Separator from '../../../Separator';
import Steps from '../../../Steps';
import PreviewDoctorDocument from '../../Components/PreviewDocument';
import FormCreateDoctorDocument from './FormCreateDoctorDocument';

const steps = ['Fill Form', 'Preview'];

interface Props {
	name?: string;
	type?: EMetaType;
	docId?: string;
	onBack: () => void;
}

export default function MainCreateDoctorDocument(props: Props) {
	const [title, setTitle] = useState(
		`${props.docId ? 'Edit' : 'Create'} ${props.name}`,
	);
	const [step, setStep] = useState(0);
	const [isConfirmed, setIsConfirmed] = useState(false);

	const [doctorDocumentId, setDoctorDocumentId] = useState(props.docId);

	function setNextStep(step: number) {
		if (step === 1) {
			setTitle(`Preview ${props.name}`);
			setStep(step);
		}
	}

	function onBack() {
		if (step > 0) {
			setTitle(`Create ${props.name}`);
			setStep((old) => old - 1);
		} else {
			props.onBack();
		}
	}

	async function confirmDoctorDocument() {
		if (!doctorDocumentId) return;

		const res = await apiUpdateDoctorDocument(doctorDocumentId, {
			isTemporary: false,
		});
		if (res) {
			console.log('update');
			setTitle('Result');
			setIsConfirmed(true);
		}
	}

	return (
		<div>
			<div className="relative px-6 py-5.5 flex items-center">
				{!isConfirmed && (
					<button
						onClick={onBack}
						className="border-none bg-transparent p-0 absolute flex items-center gap-x-1 cursor-pointer"
					>
						<ArrowLeft width={20} height={20} />
						<p className="m-0 font-bold text-3.5">Back</p>
					</button>
				)}
				<h1 className="font-semibold text-6 m-0 w-full text-center">{title}</h1>
			</div>
			{!isConfirmed && (
				<>
					<Separator style={{ height: 2 }} />
					<div className="px-13 py-3.5">
						<Steps steps={steps} activeStepIndex={step} />
					</div>
				</>
			)}
			<Separator />
			{step === 0 ? (
				<FormCreateDoctorDocument
					type={props.type}
					doctorDocumentId={doctorDocumentId}
					setDoctorDocumentId={setDoctorDocumentId}
					step={step}
					setNextStep={setNextStep}
				/>
			) : step === 1 ? (
				<PreviewDoctorDocument
					docId={doctorDocumentId!}
					type="doctor-document"
					buttonType={!isConfirmed ? 'confirm' : 'back'}
					btnSuffix={
						!isConfirmed
							? props.name ?? ''
							: props.type === EMetaType.OTHERS
							? 'Own Template'
							: 'Doctor Document'
					}
					onClickBtn={
						!isConfirmed ? confirmDoctorDocument : () => props.onBack()
					}
				/>
			) : undefined}
		</div>
	);
}
