import React, { forwardRef, useState } from 'react';
import { Typography, Modal, Row, Button, Divider, message } from 'antd';
import { iconBankAccount as IconBankAccount } from '../../assets/icons';
import { UserBankAccountProperties } from '../../types/bankAccount.type';
import { httpRequest } from '../../helpers/api';
import { ApiResponseDefault } from '../../types/apiResponse.type';
import BankAccountChooseItem from './BankAccountChooseItem';
import { useHistory } from 'react-router-dom';
import EmptyList from '../EmptyList';
import { Info } from 'react-feather';

interface IProps {
	visible: any;
	setVisible: any;
	bankAccountList: UserBankAccountProperties[];
	selectedBankAccount: any;
	fetchList: any;
}

const { Text } = Typography;

const ModalChangeMainBankAccount = forwardRef<any, IProps>(
	(
		{ visible, setVisible, bankAccountList, selectedBankAccount, fetchList },
		ref,
	) => {
		const history = useHistory();

		const [isLoadingConfirm, setIsLoadingConfirm] = useState<boolean>(false);
		const [tmpMainBankAccount, setTmpMainBankAccount] = useState<any>();

		React.useEffect(() => {
			selectedBankAccount && setTmpMainBankAccount(selectedBankAccount);
		}, [visible]);

		async function setAccountAsDefault() {
			if (!tmpMainBankAccount) return;
			setIsLoadingConfirm(true);
			try {
				const res = await httpRequest.post<
					ApiResponseDefault<{ isSuccess: boolean }>
				>(
					`/user-bank-accounts/${tmpMainBankAccount.userBankAccountId}/set-as-default`,
				);

				message.success('Success Change Main Bank Account');
			} catch (error) {
				message.error('Failed Change Main Bank Account');
				console.log(error);
			} finally {
				fetchList();
				setIsLoadingConfirm(false);
				setVisible(false);
			}
		}

		return (
			<Modal open={visible} footer={null} closable={false}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						marginBottom: 24,
					}}
				>
					<div
						style={{
							width: 64,
							height: 64,
							backgroundColor: '#F1F3F5',
							borderRadius: '50%',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							marginBottom: 24,
						}}
					>
						<IconBankAccount width={24} height={18} />
					</div>
					<Text style={{ fontSize: 20, fontWeight: 600, marginBottom: 10 }}>
						Select Bank Account
					</Text>
					<Text style={{ fontSize: 14, fontWeight: 400 }}>
						Choose your bank account that will be used to withdraw
					</Text>
				</div>
				<Row style={{ display: 'flex', flexDirection: 'column' }}>
					<div style={{ height: 300, overflowY: 'scroll' }}>
						{bankAccountList.length === 0 ? (
							<EmptyList
								icon={<Info />}
								title="Bank Account Empty"
								description="You can add your bank account in Manage Your Account"
							/>
						) : (
							<>
								{bankAccountList.map((item) => (
									<BankAccountChooseItem
										item={item}
										selectedBankAccount={tmpMainBankAccount}
										setSelectedBankAccount={setTmpMainBankAccount}
									/>
								))}
							</>
						)}
					</div>
					<Text
						onClick={() => history.push('/app/setting/bank-account')}
						style={{
							fontSize: 14,
							fontWeight: 600,
							color: '#D81F64',
							textAlign: 'center',
							marginTop: 20,
							cursor: 'pointer',
						}}
					>
						Manage Bank Account
					</Text>
				</Row>
				<Divider />
				<Row
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						marginTop: 20,
					}}
				>
					<Button
						loading={isLoadingConfirm}
						onClick={() => setVisible(false)}
						size="large"
						style={{
							backgroundColor: '#F1F3F5',
							width: '48%',
							borderRadius: 12,
						}}
					>
						<Text style={{ fontSize: 17, fontWeight: 500 }}>Cancel</Text>
					</Button>
					<Button
						onClick={() => setAccountAsDefault()}
						loading={isLoadingConfirm}
						disabled={!selectedBankAccount}
						size="large"
						style={{ width: '48%', borderRadius: 12 }}
						type="primary"
					>
						<Text style={{ fontSize: 17, fontWeight: 500, color: '#fff' }}>
							Confirm
						</Text>
					</Button>
				</Row>
			</Modal>
		);
	},
);

export default ModalChangeMainBankAccount;
