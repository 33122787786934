import {
	DoctorPartnerScheduleProperties,
	UpdateDoctorPartnerScheduleRequest,
} from '../types/doctorPartnerSchedule';
import { httpRequest } from '../helpers/api';
import { ApiResponseSuccess } from '../types/api.type';

export async function apiCreateDoctorPartnerSchedule(
	data: DoctorPartnerScheduleProperties,
): Promise<DoctorPartnerScheduleProperties> {
	const res: ApiResponseSuccess<DoctorPartnerScheduleProperties> =
		await httpRequest.post('/doctor-partner-schedules', data);

	if (res && res.data && res.data.payload) {
		return res.data.payload;
	} else {
		return {
			doctorId: '',
			partnerId: '',
			startAt: '',
			endAt: '',
			weekday: 0,
		};
	}
}

export async function apiUpdateDoctorPartnerSchedule(
	data: UpdateDoctorPartnerScheduleRequest,
): Promise<DoctorPartnerScheduleProperties> {
	const res: ApiResponseSuccess<DoctorPartnerScheduleProperties> =
		await httpRequest.put('/doctor-partner-schedules/per-weekday', data);

	if (res && res.data && res.data.payload) {
		return res.data.payload;
	} else {
		return {
			doctorId: '',
			partnerId: '',
			startAt: '',
			endAt: '',
			weekday: 0,
		};
	}
}

export async function apiDeleteDoctorPartnerSchedule(
	scheduleId: string,
): Promise<boolean> {
	const res: ApiResponseSuccess<boolean> = await httpRequest.delete(
		'/doctor-partner-schedules/' + scheduleId,
	);

	if (res) {
		return true;
	} else {
		return false;
	}
}
