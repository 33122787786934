import { Button, Col, Divider, Modal, Row, Typography } from 'antd';
import { iconApproveConfirmation as IconApproveConfirmation } from '../../../../assets/icons';
import styled from 'styled-components';

type Props = {
	isVisible: boolean;
	setVisibility: (state: boolean) => void;
	actionHandler?: () => void;
};

const { Text, Paragraph } = Typography;

export default function ModalEndConsultation(props: Props) {
	function cancelReject() {
		props.setVisibility(false);
	}

	return (
		<Modal open={props.isVisible} footer={null} closable={false}>
			<div className="flex flex-col items-center">
				<IconWrapper>
					<IconApproveConfirmation />
				</IconWrapper>
				<Text style={{ marginBottom: 10 }} className="text-5 font-semibold">
					Confirmation
				</Text>
				<Paragraph style={{ marginBottom: 0, textAlign: 'center' }}>
					Are you sure want to end this Consultation? Both the Doctor and the
					Patient will not be able to chat here
				</Paragraph>
			</div>
			<Divider style={{ margin: '12px 0px' }} />

			<Row gutter={24} className="mt-2.5">
				<Col span={12}>
					<Button
						type="text"
						size="large"
						onClick={cancelReject}
						className="text-3.5 text-secondary rounded-3 bg-gray-10 border-0 w-full font-bold"
					>
						<Text style={{ fontSize: 14, fontWeight: 700 }}>Cancel</Text>
					</Button>
				</Col>
				<Col span={12}>
					<Button
						type="primary"
						size="large"
						onClick={props.actionHandler}
						className="rounded-3 bg-primary w-full"
					>
						<Text style={{ fontSize: 14, fontWeight: 700, color: '#fff' }}>
							End Consultation
						</Text>
					</Button>
				</Col>
			</Row>
		</Modal>
	);
}

const IconWrapper = styled.div`
	height: 64px;
	width: 64px;
	background-color: ${(props) => props.theme.colors.gray10};
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 24px;
`;
