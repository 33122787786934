export function getSpecializationText(specs?: any[], other?: string) {
	if (!specs) {
		specs = [];
	}
	const names = specs.map((item) => item.specialistName);

	if (other) {
		names.push(other);
	}

	return names.join(', ');
}
