import React, { useEffect } from 'react';
import AppLayout from '../../../../layout/AppLayout';
import { Button, Card, Divider, Typography } from 'antd';

const { Text, Title } = Typography;

export default function RequestDeleteAccountPage() {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, []);

	return (
		<AppLayout activePath={'/request-delete-account'}>
			<Card className="pb-10">
				<Title className="text-center" level={5}>
					Request Delete Account
				</Title>
				<Divider />
				<div className="flex flex-col items-center">
					<div className="flex flex-col items-center" style={{ lineHeight: 2 }}>
						<Text>
							Deleting your account will remove all your personal information
							from our database. This can be undone.
						</Text>
						<Text>We will process your request max 3 x 24 hours</Text>
					</div>
					<Button
						type="primary"
						size="large"
						className="mt-10 text-4"
						onClick={() =>
							window.open(
								'https://forms.gle/JxfKddxruV6bdMMy7',
								'_blank',
								'noreferrer',
							)
						}
					>
						Request For Delete
					</Button>
				</div>
			</Card>
		</AppLayout>
	);
}
