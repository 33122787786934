import { Edit, Trash2 } from 'react-feather';
import IconButton from '../../Button/IconButton';
import { theme } from '../../../assets/theme';

export function ICDItem(props: {
	id: string;
	value: string;
	onDeleteItem: () => void;
}) {
	return (
		<div className="flex gap-x-2 items-center">
			<div className="flex-1">
				<div className="flex gap-x-1 items-center">
					<p className="m-0 text-4.5 font-medium">
						{`${props.id}. ${props.value}`}
					</p>
				</div>
			</div>
			{/* <IconButton onClick={props.onEditItem}>
				<Edit width={24} height={24} color={theme.colors.primary} />
			</IconButton> */}
			<IconButton onClick={props.onDeleteItem}>
				<Trash2 width={24} height={24} color={theme.colors.primary} />
			</IconButton>
		</div>
	);
}
