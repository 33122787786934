import { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import AppLayout from '../../../../layout/AppLayout';
import PageHeader from '../../../../components/PageHeader';
import {
	Button,
	Card,
	Divider,
	message,
	Row,
	Spin,
	Typography,
	Col,
} from 'antd';
import {
	iconGiftInvite as IconGiftInvite,
	iconReward as IconReward,
	iconShareCode as IconShareCode,
	iconRectangle as IconRectangle,
	iconInformationCirclePrimary as IconInformationCircle,
} from '../../../../assets/icons';
import { Copy } from 'react-feather';
import COLORS from '../../../../assets/globalColors';
import './styles.less';
import { apiGetReferral } from '../../../../api/userRefferal';
import { useAuth } from '../../../../context/auth.context';

const activePath = '/app/setting';
const { Text } = Typography;

const howto = [
	{
		title: 'Invite your friends',
		description: 'Share your referral code to your friends',
	},
	{
		title: 'Input Referral Code',
		description: 'Ask your friend to enter your referral code',
	},
	{
		title: 'You get the point',
		description: 'Then you get Points',
	},
];

export default function InviteFriends() {
	const history = useHistory();
	const { userId } = useAuth();
	const [referralCode, setReferralCode] = useState<string>();
	const [isLoadingGetReferralCode, setIsLoadingGetReferralCode] =
		useState(false);

	const copyHandler = () => {
		if (!referralCode) return;

		navigator.clipboard.writeText(referralCode);

		message.success('Referral code copied to clipboard');
	};

	useEffect(() => {
		if (!userId || userId.length === 0) return;

		(async () => {
			setIsLoadingGetReferralCode(true);
			const referralCode = await apiGetReferral({ userId });
			setReferralCode(referralCode);
			setIsLoadingGetReferralCode(false);
		})();
	}, [userId]);

	function shareCodeHandler() {
		if (!referralCode || referralCode.length === 0) return;

		// Check if navigator.share is supported by the browser
		if (navigator.share) {
			navigator
				.share({
					text: `Let's join MedEasy Now! Use my referral code *${referralCode}* to get the reward. https://medeasyapp.page.link/download`,
					url: 'https://medeasyapp.page.link/download',
					title: 'MedEasy',
				})
				.then(() => {
					console.log('Sharing successfull');
				})
				.catch(() => {
					console.log('Sharing failed');
				});
		} else {
			// Browser not support
			navigator.clipboard.writeText(
				`Let's join MedEasy Now! Use my referral code *${referralCode}* to get the reward. https://medeasyapp.page.link/download`,
			);
			message.success('Copied to clipboard');
			console.log('Sorry! Your browser does not support Web Share API');
		}
	}

	const ComponentHowTo = ({
		data,
		index,
	}: {
		data: { title: string; description: string };
		index: number;
	}) => {
		return (
			<Row key={index} gutter={12} align="middle" style={{ marginBottom: 20 }}>
				<Col>
					<Row
						style={{
							background: COLORS.white,
							height: 45,
							width: 45,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							border: '1px solid',
							borderColor: COLORS.black_30,
							borderRadius: '50%',
						}}
					>
						<Text
							style={{
								fontSize: 20,
								fontWeight: 600,
								color: COLORS.primary,
							}}
						>
							{index + 1}
						</Text>
					</Row>
				</Col>
				<Col>
					<Row>
						<Text
							style={{
								color: COLORS.black,
								fontSize: 18,
							}}
						>
							{data.title}
						</Text>
					</Row>
					<Row>
						<Text
							style={{
								color: COLORS.black_70,
								fontSize: 16,
							}}
						>
							{data.description}
						</Text>
					</Row>
				</Col>
			</Row>
		);
	};

	const ComponentMyReferral = () => {
		return (
			<Row align="middle" justify="space-between" className="reward my-5">
				<IconRectangle className="rectangle" />
				<div className="dot left"></div>
				<div className="dot right"></div>
				<Col span={16}>
					<Row>
						<Text
							style={{ fontSize: 14, color: COLORS.white, marginBottom: 4 }}
						>
							My Referral Code
						</Text>
					</Row>
					<Row style={{ marginBottom: 22 }}>
						{isLoadingGetReferralCode ? (
							<Spin />
						) : (
							<Button
								type="text"
								style={{
									padding: 0,
								}}
								className="flex items-center justify-between"
								onClick={copyHandler}
							>
								<Text
									style={{
										fontSize: 32,
										fontWeight: 600,
										color: COLORS.white,
										cursor: 'pointer',
									}}
								>
									{referralCode}
								</Text>{' '}
								<Copy style={{ marginLeft: 8, color: COLORS.white }} />
							</Button>
						)}
					</Row>
					<Row>
						<Button
							type="primary"
							onClick={shareCodeHandler}
							style={{
								background: COLORS.white,
								borderRadius: 8,
								paddingRight: 10,
								paddingLeft: 10,
							}}
							className="flex items-center gap-3 justify-between"
						>
							<IconShareCode width={15} height={15} />
							<Text style={{ fontSize: 14 }}>Share Code</Text>
						</Button>
					</Row>
				</Col>
				<Col span={6} style={{ marginRight: 8 }}>
					<IconReward />
				</Col>
			</Row>
		);
	};

	return (
		<AppLayout activePath={activePath}>
			<Card style={{ paddingBottom: 110 }}>
				<PageHeader
					title="Invite Friends to get Points"
					onClick={() => history.goBack()}
				/>
				<Divider />
				<div className="bg-gray-10 p-4 rounded-4.5 relative overflow-hidden">
					<div className="circle circle-1"></div>
					<div className="circle circle-2"></div>
					<div className="circle circle-3"></div>

					<Row className="pt-4">
						<Col>
							<Row>
								<IconGiftInvite style={{ marginBottom: 16 }} />
							</Row>
							<Row>
								<Text
									style={{ fontSize: 32, fontWeight: 500, lineHeight: 1.2 }}
								>
									Invite Friends and you will get <br /> Points
								</Text>
							</Row>
						</Col>
					</Row>
					<Divider
						dashed={true}
						style={{
							margin: '20px 0px',
							color: COLORS.ash_500,
							borderWidth: 1.1,
						}}
					/>
					{howto.map((data, index) => (
						<ComponentHowTo key={index} data={data} index={index} />
					))}
					<ComponentMyReferral />
					<Row
						align="middle"
						gutter={10}
						style={{ background: COLORS.white, padding: 12, borderRadius: 12 }}
					>
						<Col>
							<IconInformationCircle width={15} height={15} />
						</Col>
						<Col>
							<Text style={{ color: COLORS.black_70, fontSize: 16 }}>
								For more information about Referral please{' '}
								<Link
									to="#"
									className="font-bold"
									style={{ textDecoration: 'underline' }}
								>
									see here
								</Link>
							</Text>
						</Col>
					</Row>
				</div>
			</Card>
		</AppLayout>
	);
}
