export interface AddScheduleProps {
	age?: number;
	description?: string;
	startTime?: string;
	startTimeHours?: number;
	startTimeMinutes?: number;
	endTime?: string;
	endTimeHours?: number;
	endTimeMinutes?: number;
	startDate?: string;
	endDate?: string;
	links?: string;
	patientName?: string;
	via?: string;
	sex?: string;
	place?: string;
	title?: string;
	isBlocking?: boolean;
}

export const initialAddScheduleTelemedicineProps: AddScheduleProps = {
	age: 0,
	description: '',
	startTime: '',
	startTimeHours: 0,
	startTimeMinutes: 0,
	endTime: '',
	endTimeHours: 0,
	endTimeMinutes: 0,
	startDate: '',
	endDate: '',
	links: '',
	patientName: '',
	via: '',
	sex: '',
};

export const initialAddSchedulePersonalAppointmentProps: AddScheduleProps = {
	age: 0,
	description: '',
	startTime: '',
	startTimeHours: 0,
	startTimeMinutes: 0,
	endTime: '',
	endTimeHours: 0,
	endTimeMinutes: 0,
	startDate: '',
	endDate: '',
	place: '',
	patientName: '',
	sex: '',
};

export const initialAddSchedulePersonalProps: AddScheduleProps = {
	description: '',
	startTime: '',
	startTimeHours: 0,
	startTimeMinutes: 0,
	endTime: '',
	endTimeHours: 0,
	endTimeMinutes: 0,
	startDate: '',
	endDate: '',
};
