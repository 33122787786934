import React, { forwardRef } from 'react';
import { Typography, Modal, Row, Col, Button, Tag } from 'antd';
import moment from 'moment';

interface IProps {
	visible: boolean;
	setVisible: any;
	activeTab: string;
	data: any;
}

const { Text, Title } = Typography;

const ModalDetail = forwardRef<any, IProps>(
	({ visible, setVisible, data, activeTab }, ref) => {
		function parseCategoryToTitle(category: string) {
			return category
				.toLowerCase()
				.split('_')
				.map((cat) => cat[0].toUpperCase() + cat.slice(1))
				.join(' ');
		}

		const statusColor = (status: string) => {
			let bgColor = '';
			let color = '';

			if (status === 'PENDING' || status === 'WAITING_APPROVAL') {
				bgColor = '#FCF1E3';
				color = '#DA7B11';
			} else if (status === 'EXPIRED' || status === 'REJECTED') {
				bgColor = '#FEF5F9';
				color = '#D81F64';
			} else if (status === 'AVAILABLE' || status === 'APPROVED') {
				bgColor = '#D7F4EB';
				color = '#268E6C';
			}

			return { bgColor: bgColor, color: color };
		};

		// Component Group - Tel
		return (
			<Modal open={visible} footer={null} closable={false}>
				<Title style={{ marginBottom: 10 }} level={5}>
					{activeTab === '0' ? 'Balance' : 'Point'} Detail
				</Title>
				<Row
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						marginBottom: 12,
					}}
				>
					<Col
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-start',
						}}
					>
						<Text style={{ fontSize: 16, fontWeight: 600 }}>
							{data.category ? parseCategoryToTitle(data.category) : 'Unknown'}
						</Text>
						<Text style={{ fontSize: 14, fontWeight: 400, color: '#929292' }}>
							{data.note}
						</Text>
					</Col>
					<Col style={{ display: 'flex', flexDirection: 'column' }}>
						{data.category &&
							parseCategoryToTitle(data.category) !== 'Withdrawal' &&
							data.status && (
								<Tag
									style={{
										color: statusColor(data.status).color,
										backgroundColor: statusColor(data.status).bgColor,
										border: `1px solid ${statusColor(data.status).color}`,
										marginRight: 0,
										textAlign: 'center',
									}}
								>
									{data.status}
								</Tag>
							)}
						<Text
							style={{
								fontSize: 14,
								fontWeight: 400,
								color: Number(data.amount) < 0 ? '#D82148' : '#00C897',
								marginTop: 4,
							}}
						>
							{!(Number(data.amount) < 0) && '+'}
							{activeTab === '0' && '₱'}
							{data.amount}
							{activeTab === '1' && ' Points'}
						</Text>
					</Col>
				</Row>
				{data.category &&
					parseCategoryToTitle(data.category) === 'Withdrawal' && (
						<Row
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
								marginBottom: 12,
							}}
						>
							<Col
								style={{
									display: 'flex',
									flex: 1,
									flexDirection: 'column',
									alignItems: 'flex-start',
								}}
							>
								<Text style={{ fontSize: 14, fontWeight: 400 }}>
									{data.metaTransaction.metaBankAccount.bankName}
								</Text>
								<Text style={{ fontSize: 14, fontWeight: 400 }}>
									{data.metaTransaction.metaBankAccount.bankAccountOwner}
								</Text>
								<Text style={{ fontSize: 14, fontWeight: 400 }}>
									{data.metaTransaction.metaBankAccount.bankAccountNumber}
								</Text>
							</Col>
							<Col>
								<Tag
									style={{
										color: statusColor(data.metaTransaction.status).color,
										backgroundColor: statusColor(data.metaTransaction.status)
											.bgColor,
										border: `1px solid ${
											statusColor(data.metaTransaction.status).color
										}`,
										marginRight: 0,
										textAlign: 'center',
									}}
								>
									{data.metaTransaction.status.replace('_', ' ')}
								</Tag>
							</Col>
						</Row>
					)}
				<Row style={{ display: 'flex', flexDirection: 'column' }}>
					<Text style={{ fontSize: 14, fontWeight: 400 }}>
						Created at {moment(data.createdAt).format('MMMM Do YYYY H:mm:ss')}
					</Text>
					{!(
						new Date(data.createdAt).getTime() ===
						new Date(data.updatedAt).getTime()
					) && (
						<Text style={{ fontSize: 14, fontWeight: 400 }}>
							Updated at {moment(data.updatedAt).format('MMMM Do YYYY H:mm:ss')}
						</Text>
					)}
				</Row>
				<Row></Row>
				<Row
					style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}
				>
					<Button
						type="primary"
						onClick={() => {
							setVisible(false);
						}}
					>
						Done
					</Button>
				</Row>
			</Modal>
		);
	},
);

export default ModalDetail;
