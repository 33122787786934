type Props = {
	birthdate?: any;

	gender?: string;
	patientGender?: string;
	patientAge?: string;

	user?: Props;
};

export function getBirthdate(profile?: Props): string {
	if (profile) {
		if (profile.birthdate) {
			return profile.birthdate;
		} else if (profile.user) {
			return getBirthdate(profile.user);
		} else {
			return '';
		}
	} else {
		return '';
	}
}

export function getGender(profile?: Props): string {
	if (profile) {
		if (profile.gender) {
			return profile.gender[0].toUpperCase() + profile.gender.slice(1);
		} else if (profile.patientGender) {
			return (
				profile.patientGender[0].toUpperCase() + profile.patientGender.slice(1)
			);
		} else if (profile.user) {
			return getGender(profile.user);
		} else {
			return '';
		}
	} else {
		return '';
	}
}
