import { EScheduleTypes } from '../types/schedule.type';
import COLORS from '../assets/globalColors';

import {
	iconAppointment,
	iconAppointmentColorize,
	iconMedService,
	iconMedServiceColorize,
	iconPersonalEvent,
	iconPersonalEventColorize,
	iconPersonalEventBlocked,
	iconPersonalEventBlockedColorize,
	iconTelemedicine,
	iconTelemedicineColorize,
	iconWebinar,
	iconWebinarColorize,
	iconWarningCircle,
	iconWarningCircleColorize,
} from '../assets/icons';
import { PersonalEvent } from '../types/personalEvent.type';
import { PersonalConsultationProperties } from '../types/personalConsultation.type';
import {
	EConsultationType,
	TransactionConsultationProperties,
} from '../types/transactionConsultation.type';
import { TransactionHealthServiceProps } from '../types/transactionHealthService.type';

export const dataTypeEvent = [
	{
		name: 'Telemedicine',
		type: EScheduleTypes.telemedicine,
		color: '#D62464',
		icon: iconTelemedicine,
		iconColorize: iconTelemedicineColorize,
	},
	{
		name: 'Telemedicine ~',
		type: EScheduleTypes['telemedicine-personal'],
		color: '#D62464',
		icon: iconTelemedicine,
		iconColorize: iconTelemedicineColorize,
	},
	{
		name: 'Medical Service',
		type: EScheduleTypes['medical-service'],
		color: '#F26B29',
		icon: iconMedService,
		iconColorize: iconMedServiceColorize,
	},
	{
		name: 'In-Person Appointment',
		type: EScheduleTypes.appointment,
		color: '#2B9CDC',
		icon: iconAppointment,
		iconColorize: iconAppointmentColorize,
	},
	{
		name: 'In-Person Appointment ~',
		type: EScheduleTypes['appointment-personal'],
		color: '#2B9CDC',
		icon: iconAppointment,
		iconColorize: iconAppointmentColorize,
	},
	{
		name: 'Personal Event',
		type: EScheduleTypes['personal-event'],
		color: '#22CDA4',
		icon: iconPersonalEvent,
		iconColorize: iconPersonalEventColorize,
	},
	{
		name: 'Personal Event (Blocked)',
		type: EScheduleTypes['personal-event-blocked'],
		color: COLORS.disabledDark,
		icon: iconPersonalEventBlocked,
		iconColorize: iconPersonalEventBlockedColorize,
	},
	{
		name: 'Personal Event Local',
		type: EScheduleTypes['personal-event-local'],
		color: COLORS.disabledDark,
		icon: iconPersonalEvent,
		iconColorize: iconPersonalEventColorize,
	},
	{
		name: 'Webinar',
		type: EScheduleTypes.webinar,
		color: '#825DD1',
		icon: iconWebinar,
		iconColorize: iconWebinarColorize,
	},
	{
		name: 'Consult Now',
		type: EScheduleTypes['consult-now'],
		color: '#E5AB0E',
		icon: iconWarningCircle,
		iconColorize: iconWarningCircleColorize,
	},
];

export const dummyEvent = [
	{
		name: 'telemedicine',
		data: [
			{
				name: 'Rizki Rahmadi',
				date: '2022-09-27',
				desc: 'Sakit',
				reason: 'Sakit',
				startTime: '09:30',
				endTime: '10:00',
				detailDoctor: {
					name: 'Aditya Arya Tusan',
					title:
						'Cardiology, Family Medicine, Psychiatry, Critical Care (Intensivist)',
					age: 22,
					ageString: '22 yo',
				},
				raw: {
					transactionConsultationId: 'fa232100-1d1b-4483-b75d-bfd25ae40401',
					doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
					patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
					patientInRelationId: null,
					partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
					consultationType: 'TELEMEDICINE_VIDEO_CHAT_CONSULTATION',
					scheduledStartAt: '2022-09-27T02:30:00.000Z',
					scheduledEndAt: '2022-09-27T03:00:00.000Z',
					isCanExtend: null,
					doctorJoinAt: null,
					doctorEndAt: null,
					patientJoinAt: null,
					patientEndAt: null,
					transactionStatus: 'APPROVED',
					lastUpdatedByUser: {
						action: 'login_success',
						userId: '01GCDSV3QRE7ZXTKF13KEM78SP',
						payload: {
							phoneNumber: '6312345',
						},
						userType: 'customer',
						sessionId:
							'566b921243c8fcf3b1d3c5016a8575232cd8b8db7d49bd62d9fdaee703063b02',
					},
					description: null,
					place: null,
					link: null,
					metaPatient: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						updatedAt: '2022-09-22T10:42:40.193Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					metaPatientInRelation: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
						updatedAt: '2022-09-22T10:42:40.193Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					metaDoctor: {
						user: {
							city: null,
							name: 'Aditya Arya Tusan',
							email: null,
							phone: '6382345',
							gender: 'male',
							status: 'active',
							userId: '01GCDSTJ8FWEPN6H6MTQBR206D',
							lastName: 'Tusan',
							nickName: 'Aditya',
							province: null,
							userType: 'customer',
							birthdate: '1999-11-30',
							createdAt: '2022-09-08T05:40:36.240Z',
							firstName: 'Aditya',
							updatedAt: '2022-09-08T08:31:31.839Z',
							middleName: 'Arya',
							profilePic: null,
						},
						title: 'SKG',
						doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
						partners: [
							{
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:01:45.057Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:15.723Z',
								pricePerSession: 500,
							},
							{
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:01:45.057Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:01:59.322Z',
								pricePerSession: 500,
							},
							{
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:53:12.891Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:20.032Z',
								pricePerSession: 500,
							},
							{
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:53:55.441Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:53:55.441Z',
								pricePerSession: null,
							},
						],
						createdAt: '2022-09-08T05:47:23.761Z',
						kycStatus: 'APPROVED',
						prcNumber: '',
						ptrNumber: '',
						schedules: [
							{
								endAt: '2022-01-01T15:00:00.970Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.841Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:08.456Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:08.456Z',
								scheduleId: 'a31ae4ad-a007-4a79-a76f-0e4b46321701',
							},
							{
								endAt: '2022-01-01T15:00:00.776Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.973Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:29.740Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:29.740Z',
								scheduleId: '54f7f208-0156-4a7b-abab-01d5a4ab58aa',
							},
							{
								endAt: '2022-01-01T15:00:00.351Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.695Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:53.389Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:53.389Z',
								scheduleId: '8a73f804-38a2-4c63-90d6-0274230d2926',
							},
							{
								endAt: '2022-01-01T15:00:00.079Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.689Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:00.869Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:00.869Z',
								scheduleId: 'f9642816-0af0-4b81-aec1-f6d84f0e004f',
							},
							{
								endAt: '2022-01-01T15:00:00.488Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.787Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:18.728Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:18.728Z',
								scheduleId: '4126c29f-9586-498a-b58b-03f45d8f5949',
							},
							{
								endAt: '2022-01-01T15:00:00.048Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.494Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:27.765Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:27.765Z',
								scheduleId: '4f7a2e8e-4afd-41bf-88f2-59fbcf1a7d1b',
							},
							{
								endAt: '2022-01-01T15:00:00.194Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.066Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:28.709Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:28.709Z',
								scheduleId: 'b59890d5-44a0-447b-9f31-ca137e27779a',
							},
							{
								endAt: '2022-01-01T15:00:00.814Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.197Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:36.439Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:36.439Z',
								scheduleId: 'cb76674b-12ef-4dbc-98aa-99edbb61bc25',
							},
							{
								endAt: '2022-01-01T15:00:00.683Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.952Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:44.409Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:44.409Z',
								scheduleId: '4a3b9e33-2601-4228-903e-79e2c1a171c0',
							},
							{
								endAt: '2022-01-01T15:00:00.303Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.810Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:52.482Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:52.482Z',
								scheduleId: '3459a911-20d6-4179-8873-115af073b8df',
							},
							{
								endAt: '2022-01-01T15:00:00.632Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.098Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:00.731Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:00.731Z',
								scheduleId: '53cbe7a8-8254-4b86-8d49-9153906af659',
							},
							{
								endAt: '2022-01-01T15:00:00.267Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.342Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:10.158Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:10.158Z',
								scheduleId: '368e3dcb-c87e-4382-93b5-f92124af68a0',
							},
							{
								endAt: '2022-01-01T15:00:00.511Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.547Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:23.678Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:23.678Z',
								scheduleId: '506cd274-9f8e-4296-a480-7949bde125e4',
							},
							{
								endAt: '2022-01-01T15:00:00.418Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.377Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:17.220Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:17.220Z',
								scheduleId: '4f2f93d0-8d2a-4a61-9742-db0e509dec5e',
							},
							{
								endAt: '2022-01-01T15:00:00.198Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.805Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:25.263Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:25.263Z',
								scheduleId: '5415d0c1-6a9d-440e-9df9-fc7166caab12',
							},
							{
								endAt: '2022-01-01T15:00:00.972Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.496Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:32.657Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:32.657Z',
								scheduleId: '05ac2913-2d60-4e02-b4b5-340448bab3ac',
							},
							{
								endAt: '2022-01-01T15:00:00.610Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.873Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:40.316Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:40.316Z',
								scheduleId: 'b4b36f6b-1c4b-4886-8645-ddcbd41671cc',
							},
							{
								endAt: '2022-01-01T15:00:00.149Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.634Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:48.087Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:48.087Z',
								scheduleId: '188cf832-1275-49bd-885f-240e390b8e6f',
							},
							{
								endAt: '2022-01-01T15:00:00.118Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.566Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:57.046Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:57.046Z',
								scheduleId: 'c703d009-2840-4730-a9d1-e4ad1dc6a917',
							},
							{
								endAt: '2022-01-01T15:00:00.184Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.850Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:05.067Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:03:05.067Z',
								scheduleId: 'c12eb042-bd87-41b1-9c12-255681814c02',
							},
							{
								endAt: '2022-01-01T15:00:00.691Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.931Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:23.318Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:23.318Z',
								scheduleId: '2ffbdaa9-c727-4de3-ade4-302d22731359',
							},
							{
								endAt: '2022-01-01T15:00:00.512Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.375Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:33.507Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:33.507Z',
								scheduleId: '22a089d9-af75-45cc-927e-d0845099ef05',
							},
							{
								endAt: '2022-01-01T15:00:00.268Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.661Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:43.515Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:43.515Z',
								scheduleId: '9d0fb904-4e28-42af-a4d4-060ad52e704b',
							},
							{
								endAt: '2022-01-01T15:00:00.388Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.083Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:54.009Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:54.009Z',
								scheduleId: '3cbb04c9-e894-45b3-9ab6-4cd9ca68c3dd',
							},
							{
								endAt: '2022-01-01T15:00:00.833Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.384Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:05.486Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:05.486Z',
								scheduleId: 'fc14c9b9-4b89-4667-af32-6c27bd1147d4',
							},
							{
								endAt: '2022-01-01T15:00:00.505Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.098Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:13.020Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:13.020Z',
								scheduleId: '1a17c05d-ef4b-4f58-b4b0-a03ec697dac5',
							},
							{
								endAt: '2022-01-01T15:00:00.599Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.683Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:21.254Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:21.254Z',
								scheduleId: 'f096a199-fe82-40c5-861c-cab046ddbdd7',
							},
							{
								endAt: '2022-01-01T15:00:00.376Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.414Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-14T02:27:52.427Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-14T02:27:52.427Z',
								scheduleId: '42d7db46-19a3-47d0-8af5-cf850aa25ca7',
							},
						],
						startWork: null,
						tinNumber: '',
						updatedAt: '2022-09-08T08:31:30.898Z',
						educations: [],
						experience: 2,
						orderScore: 2,
						specialists: [
							{
								icon: 'family_medicine',
								level: 2,
								createdAt: '2022-09-08T03:16:01.512Z',
								updatedAt: '2022-09-08T03:16:01.512Z',
								specialistId: '9baad78f-2237-4933-9c77-811cc886b878',
								specialistName: 'Cardiology',
								parentSpecialistId: '55df1bba-f1e7-4e57-982d-084327ef4aac',
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									specialistId: '9baad78f-2237-4933-9c77-811cc886b878',
								},
							},
							{
								icon: 'family_medicine',
								level: 1,
								createdAt: '2022-09-08T03:16:01.498Z',
								updatedAt: '2022-09-08T03:16:01.498Z',
								specialistId: '55df1bba-f1e7-4e57-982d-084327ef4aac',
								specialistName: 'Family Medicine',
								parentSpecialistId: 'dafe825a-cc64-4b00-8d7d-e7ed8adab9ca',
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									specialistId: '55df1bba-f1e7-4e57-982d-084327ef4aac',
								},
							},
							{
								icon: 'psychiatry',
								level: 1,
								createdAt: '2022-09-08T03:16:01.498Z',
								updatedAt: '2022-09-08T03:16:01.498Z',
								specialistId: '87bcd861-d18d-4e00-adb7-c913ba60f435',
								specialistName: 'Psychiatry',
								parentSpecialistId: 'dafe825a-cc64-4b00-8d7d-e7ed8adab9ca',
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									specialistId: '87bcd861-d18d-4e00-adb7-c913ba60f435',
								},
							},
							{
								icon: 'critical_care_intensivist',
								level: 2,
								createdAt: '2022-09-08T03:16:01.512Z',
								updatedAt: '2022-09-08T03:16:01.512Z',
								specialistId: '22fc908d-8b1e-4bd9-b7f0-7f4072c70d7f',
								specialistName: 'Critical Care (Intensivist)',
								parentSpecialistId: '87bcd861-d18d-4e00-adb7-c913ba60f435',
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									specialistId: '22fc908d-8b1e-4bd9-b7f0-7f4072c70d7f',
								},
							},
						],
						certificates: [],
						nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
						otherSpecialist: '',
						signatureImageUrl:
							'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
						schedulesInPartners: [
							{
								endAt: '2022-01-01T15:00:00.194Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.066Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:28.709Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:28.709Z',
								scheduleId: 'b59890d5-44a0-447b-9f31-ca137e27779a',
							},
							{
								endAt: '2022-01-01T15:00:00.388Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.083Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:54.009Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:54.009Z',
								scheduleId: '3cbb04c9-e894-45b3-9ab6-4cd9ca68c3dd',
							},
							{
								endAt: '2022-01-01T15:00:00.632Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.098Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:00.731Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:00.731Z',
								scheduleId: '53cbe7a8-8254-4b86-8d49-9153906af659',
							},
							{
								endAt: '2022-01-01T15:00:00.505Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.098Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:13.020Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:13.020Z',
								scheduleId: '1a17c05d-ef4b-4f58-b4b0-a03ec697dac5',
							},
							{
								endAt: '2022-01-01T15:00:00.814Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.197Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:36.439Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:36.439Z',
								scheduleId: 'cb76674b-12ef-4dbc-98aa-99edbb61bc25',
							},
							{
								endAt: '2022-01-01T15:00:00.267Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.342Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:10.158Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:10.158Z',
								scheduleId: '368e3dcb-c87e-4382-93b5-f92124af68a0',
							},
							{
								endAt: '2022-01-01T15:00:00.512Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.375Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:33.507Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:33.507Z',
								scheduleId: '22a089d9-af75-45cc-927e-d0845099ef05',
							},
							{
								endAt: '2022-01-01T15:00:00.418Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.377Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:17.220Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:17.220Z',
								scheduleId: '4f2f93d0-8d2a-4a61-9742-db0e509dec5e',
							},
							{
								endAt: '2022-01-01T15:00:00.833Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.384Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:05.486Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:05.486Z',
								scheduleId: 'fc14c9b9-4b89-4667-af32-6c27bd1147d4',
							},
							{
								endAt: '2022-01-01T15:00:00.376Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.414Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-14T02:27:52.427Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-14T02:27:52.427Z',
								scheduleId: '42d7db46-19a3-47d0-8af5-cf850aa25ca7',
							},
							{
								endAt: '2022-01-01T15:00:00.048Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.494Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:27.765Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:27.765Z',
								scheduleId: '4f7a2e8e-4afd-41bf-88f2-59fbcf1a7d1b',
							},
							{
								endAt: '2022-01-01T15:00:00.972Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.496Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:32.657Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:32.657Z',
								scheduleId: '05ac2913-2d60-4e02-b4b5-340448bab3ac',
							},
							{
								endAt: '2022-01-01T15:00:00.511Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.547Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:23.678Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:23.678Z',
								scheduleId: '506cd274-9f8e-4296-a480-7949bde125e4',
							},
							{
								endAt: '2022-01-01T15:00:00.118Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.566Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:57.046Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:57.046Z',
								scheduleId: 'c703d009-2840-4730-a9d1-e4ad1dc6a917',
							},
							{
								endAt: '2022-01-01T15:00:00.149Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.634Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:48.087Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:48.087Z',
								scheduleId: '188cf832-1275-49bd-885f-240e390b8e6f',
							},
							{
								endAt: '2022-01-01T15:00:00.268Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.661Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:43.515Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:43.515Z',
								scheduleId: '9d0fb904-4e28-42af-a4d4-060ad52e704b',
							},
							{
								endAt: '2022-01-01T15:00:00.599Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.683Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:21.254Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:21.254Z',
								scheduleId: 'f096a199-fe82-40c5-861c-cab046ddbdd7',
							},
							{
								endAt: '2022-01-01T15:00:00.079Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.689Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:00.869Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:00.869Z',
								scheduleId: 'f9642816-0af0-4b81-aec1-f6d84f0e004f',
							},
							{
								endAt: '2022-01-01T15:00:00.351Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.695Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:53.389Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:53.389Z',
								scheduleId: '8a73f804-38a2-4c63-90d6-0274230d2926',
							},
							{
								endAt: '2022-01-01T15:00:00.488Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.787Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:18.728Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:18.728Z',
								scheduleId: '4126c29f-9586-498a-b58b-03f45d8f5949',
							},
							{
								endAt: '2022-01-01T15:00:00.198Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.805Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:25.263Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:25.263Z',
								scheduleId: '5415d0c1-6a9d-440e-9df9-fc7166caab12',
							},
							{
								endAt: '2022-01-01T15:00:00.303Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.810Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:52.482Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:52.482Z',
								scheduleId: '3459a911-20d6-4179-8873-115af073b8df',
							},
							{
								endAt: '2022-01-01T15:00:00.970Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.841Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:08.456Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:08.456Z',
								scheduleId: 'a31ae4ad-a007-4a79-a76f-0e4b46321701',
							},
							{
								endAt: '2022-01-01T15:00:00.184Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.850Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:05.067Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:03:05.067Z',
								scheduleId: 'c12eb042-bd87-41b1-9c12-255681814c02',
							},
							{
								endAt: '2022-01-01T15:00:00.610Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.873Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:40.316Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:40.316Z',
								scheduleId: 'b4b36f6b-1c4b-4886-8645-ddcbd41671cc',
							},
							{
								endAt: '2022-01-01T15:00:00.691Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.931Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:23.318Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:23.318Z',
								scheduleId: '2ffbdaa9-c727-4de3-ade4-302d22731359',
							},
							{
								endAt: '2022-01-01T15:00:00.683Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.952Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:44.409Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:44.409Z',
								scheduleId: '4a3b9e33-2601-4228-903e-79e2c1a171c0',
							},
							{
								endAt: '2022-01-01T15:00:00.776Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.973Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:29.740Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:29.740Z',
								scheduleId: '54f7f208-0156-4a7b-abab-01d5a4ab58aa',
							},
						],
						specialityCertificate: null,
						availableConsultationTypes: [
							'TELEMEDICINE_VIDEO_CHAT_CONSULTATION',
							'TELEMEDICINE_CHAT_CONSULTATION',
							'CONSULT_NOW_VIDEO_CHAT_CONSULTATION',
							'CONSULT_NOW_CHAT_CONSULTATION',
							'FACE_TO_FACE_CONSULTATION',
						],
					},
					metaPartner: {
						partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
						isPublished: true,
						partnerName: 'Online Clinic',
						partnerType: 'ONLINE_CLINIC',
						isConfirmedPartner: true,
					},
					createdByUserId: '01GCDNAV6270SMVQBEAQ90VY4G',
					metaCreatedByUser: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						updatedAt: '2022-09-22T10:42:40.193Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					createdAt: '2022-09-27T02:20:18.580Z',
					updatedAt: '2022-09-27T09:07:51.783Z',
					medicalRecord: {
						medicalRecordId: 'b3a6d213-7052-4238-99f7-1a823c650cf9',
						doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
						patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patientInRelationId: null,
						reasonOfConsultation: 'Sakit',
						historyPhysicalFindings: null,
						diagnosis: null,
						planForManagement: null,
						transactionConsultationId: 'fa232100-1d1b-4483-b75d-bfd25ae40401',
						uploadedFile: null,
						createdAt: '2022-09-27T02:20:18.601Z',
						updatedAt: '2022-09-27T02:20:18.601Z',
					},
					history: [
						{
							historyId: 'd292c92e-ea07-45e9-b537-d7219ad29c1d',
							transactionConsultationId: 'fa232100-1d1b-4483-b75d-bfd25ae40401',
							description: 'Update transaction status',
							newData: {
								transactionStatus: 'APPROVED',
							},
							createdByUserId: '01GCDSV3QRE7ZXTKF13KEM78SP',
							metaCreatedByUser: {
								action: 'login_success',
								userId: '01GCDSV3QRE7ZXTKF13KEM78SP',
								payload: {
									phoneNumber: '6312345',
								},
								userType: 'customer',
								sessionId:
									'566b921243c8fcf3b1d3c5016a8575232cd8b8db7d49bd62d9fdaee703063b02',
							},
							createdAt: '2022-09-27T09:07:51.842Z',
							updatedAt: '2022-09-27T09:07:51.842Z',
						},
						{
							historyId: '7fd79308-f9b6-49fc-8911-de0a4b6fa76e',
							transactionConsultationId: 'fa232100-1d1b-4483-b75d-bfd25ae40401',
							description: 'Update transaction status',
							newData: {
								transactionStatus: 'WAITING_APPROVAL',
							},
							createdByUserId: '01GCDNAV6270SMVQBEAQ90VY4G',
							metaCreatedByUser: {
								city: null,
								name: 'Rizki Rahmadi',
								email: null,
								phone: '63125',
								doctor: null,
								gender: 'male',
								status: 'active',
								userId: '01GCDNAV6270SMVQBEAQ90VY4G',
								patient: {
									pwId: null,
									pwIdUrl: null,
									createdAt: '2022-09-08T04:22:06.889Z',
									patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
									updatedAt: '2022-09-08T04:22:06.889Z',
									pwIdStatus: 'WAITING_APPROVAL',
								},
								lastName: 'Rahmadi',
								nickName: 'Iki',
								province: null,
								userType: 'customer',
								birthdate: '1999-03-11',
								createdAt: '2022-09-08T04:22:06.787Z',
								firstName: 'Rizki',
								updatedAt: '2022-09-22T10:42:40.193Z',
								middleName: null,
								profilePic: null,
								isEmailVerified: false,
								isPhoneVerified: true,
							},
							createdAt: '2022-09-27T02:21:08.268Z',
							updatedAt: '2022-09-27T02:21:08.268Z',
						},
					],
					payment: {
						paymentId: '98cac366-64b6-4428-9ee4-5aa71a360b83',
						invoiceId: 'CT/20220927/MYH',
						transactionId: 'fa232100-1d1b-4483-b75d-bfd25ae40401',
						transactionType: 'TELEMEDICINE',
						totalNeedToPay: 575,
						paymentStatus: 'PAID_FULL',
						buyerId: '01GCDNAV6270SMVQBEAQ90VY4G',
						metaBuyerDetail: {
							city: null,
							name: 'Rizki Rahmadi',
							email: null,
							phone: '63125',
							doctor: null,
							gender: 'male',
							status: 'active',
							userId: '01GCDNAV6270SMVQBEAQ90VY4G',
							patient: {
								pwId: null,
								pwIdUrl: null,
								createdAt: '2022-09-08T04:22:06.889Z',
								patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
								updatedAt: '2022-09-08T04:22:06.889Z',
								pwIdStatus: 'WAITING_APPROVAL',
							},
							lastName: 'Rahmadi',
							nickName: 'Iki',
							province: null,
							userType: 'customer',
							birthdate: '1999-03-11',
							createdAt: '2022-09-08T04:22:06.787Z',
							firstName: 'Rizki',
							updatedAt: '2022-09-22T10:42:40.193Z',
							middleName: null,
							profilePic: null,
							isEmailVerified: false,
							isPhoneVerified: true,
						},
						meta: null,
						expiredAt: '2022-09-28T06:20:18.857Z',
						createdAt: '2022-09-27T02:20:18.858Z',
						updatedAt: '2022-09-27T02:21:08.244Z',
						paymentDetails: [
							{
								paymentDetailId: 'fe68fe33-d950-44c3-a66b-75ae4b458505',
								paymentId: '98cac366-64b6-4428-9ee4-5aa71a360b83',
								paymentType: 'BASE_PRICE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 500,
								status: 'PAID_FULL',
								meta: {
									consultationFee: 500,
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-28T06:20:18.857Z',
								createdAt: '2022-09-27T02:20:18.924Z',
								updatedAt: '2022-09-27T02:20:18.924Z',
							},
							{
								paymentDetailId: '68899f08-8f6e-459f-b7f8-fa69394f7f87',
								paymentId: '98cac366-64b6-4428-9ee4-5aa71a360b83',
								paymentType: 'PATIENT_TRANSACTION_FEE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 75,
								status: 'PAID_FULL',
								meta: {
									key: 'PATIENT_TRANSACTION_FEE',
									value: '5%+50',
									configId: 'PATIENT_TRANSACTION_FEE',
									createdAt: '2022-09-08T06:08:45.373Z',
									updatedAt: '2022-09-08T06:08:45.373Z',
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-28T06:20:18.857Z',
								createdAt: '2022-09-27T02:20:18.928Z',
								updatedAt: '2022-09-27T02:20:18.928Z',
							},
							{
								paymentDetailId: 'f6d4f74b-abea-4401-bec2-4b312d5a05a8',
								paymentId: '98cac366-64b6-4428-9ee4-5aa71a360b83',
								paymentType: 'PAYMENT_GATEWAY',
								paymentMethod: 'EWALLET',
								paymentVendorType: 'PAYMONGO',
								paymentVendorId: 'GCASH',
								externalId: null,
								totalPay: 575,
								status: 'PAID_FULL',
								meta: {
									data: {
										id: 'evt_c8a1LpiVkahNvps1rH8UXzf4',
										type: 'event',
										attributes: {
											data: {
												id: 'pay_sRheykMLAaAeHDYBzB9Wgwro',
												type: 'payment',
												attributes: {
													fee: 1438,
													taxes: [],
													amount: 57500,
													origin: 'api',
													payout: null,
													source: {
														id: 'src_UdJLj8PFsXRRLcJkivkDNKey',
														type: 'gcash',
													},
													status: 'paid',
													billing: null,
													paid_at: 1664245255,
													refunds: [],
													currency: 'PHP',
													disputed: false,
													livemode: false,
													metadata: {
														paymentId: '98cac366-64b6-4428-9ee4-5aa71a360b83',
													},
													access_url: null,
													created_at: 1664245255,
													net_amount: 56062,
													tax_amount: null,
													updated_at: 1664245255,
													description: null,
													available_at: 1664442000,
													payment_intent_id: null,
													statement_descriptor: 'PAYMONGO',
													balance_transaction_id:
														'bal_txn_iaHqzvgvkqNU2GD8xUp558Fb',
													external_reference_number: null,
												},
											},
											type: 'payment.paid',
											livemode: false,
											created_at: 1664245255,
											updated_at: 1664245255,
											previous_data: {},
										},
									},
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-28T06:20:18.857Z',
								createdAt: '2022-09-27T02:20:18.921Z',
								updatedAt: '2022-09-27T02:21:08.231Z',
							},
						],
						paymentDetailsPartners: [
							{
								paymentDetailId: 'b7e29e37-32d8-472b-92a0-9d723d524111',
								paymentId: '98cac366-64b6-4428-9ee4-5aa71a360b83',
								paymentType: 'FINAL_PAYMENT',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 473,
								status: 'PAID_FULL',
								meta: {},
								type: 'PAYMENT',
								expiredAt: '2022-09-28T06:20:18.857Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-27T02:20:18.939Z',
								updatedAt: '2022-09-27T02:20:18.939Z',
							},
							{
								paymentDetailId: '34d3dcdd-579a-4b03-96bc-6a532f5265c2',
								paymentId: '98cac366-64b6-4428-9ee4-5aa71a360b83',
								paymentType: 'DOCTOR_TRANSACTION_FEE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 17,
								status: 'PAID_FULL',
								meta: {
									key: 'DOCTOR_TRANSACTION_FEE',
									value: '3%',
									configId: 'DOCTOR_TRANSACTION_FEE',
									createdAt: '2022-09-08T06:08:45.373Z',
									updatedAt: '2022-09-08T06:08:45.373Z',
								},
								type: 'DISCOUNT',
								expiredAt: '2022-09-28T06:20:18.857Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-27T02:20:18.939Z',
								updatedAt: '2022-09-27T02:20:18.939Z',
							},
							{
								paymentDetailId: 'fbe48b61-1ed8-4c43-b2e8-687c57028e20',
								paymentId: '98cac366-64b6-4428-9ee4-5aa71a360b83',
								paymentType: 'DOCTOR_COMMISSION_FEE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 10,
								status: 'PAID_FULL',
								meta: {
									key: 'DOCTOR_COMMISSION_FEE',
									value: '2%',
									configId: 'DOCTOR_COMMISSION_FEE',
									createdAt: '2022-09-08T09:27:39.090Z',
									updatedAt: '2022-09-08T09:27:39.090Z',
								},
								type: 'DISCOUNT',
								expiredAt: '2022-09-28T06:20:18.857Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-27T02:20:18.939Z',
								updatedAt: '2022-09-27T02:20:18.939Z',
							},
							{
								paymentDetailId: '728f97a5-d9cc-40dd-b33b-a1b7c023e9ef',
								paymentId: '98cac366-64b6-4428-9ee4-5aa71a360b83',
								paymentType: 'PATIENT_PWD_DISCOUNT',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 0,
								status: 'PAID_FULL',
								meta: {},
								type: 'DISCOUNT',
								expiredAt: '2022-09-28T06:20:18.857Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-27T02:20:18.939Z',
								updatedAt: '2022-09-27T02:20:18.939Z',
							},
							{
								paymentDetailId: '86c60238-740c-446d-92b8-b936b04f5baa',
								paymentId: '98cac366-64b6-4428-9ee4-5aa71a360b83',
								paymentType: 'BASE_PRICE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 500,
								status: 'PAID_FULL',
								meta: {},
								type: 'PAYMENT',
								expiredAt: '2022-09-28T06:20:18.857Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-27T02:20:18.939Z',
								updatedAt: '2022-09-27T02:20:18.939Z',
							},
						],
					},
				},
			},
			{
				name: 'Rizki Rahmadi',
				date: '2022-09-22',
				desc: 'Maag',
				reason: 'Maag',
				startTime: '17:30',
				endTime: '18:00',
				detailDoctor: {
					name: 'Aditya Arya Tusan',
					title:
						'Psychiatry, Cardiology, Family Medicine, Critical Care (Intensivist)',
					age: 22,
					ageString: '22 yo',
				},
				raw: {
					transactionConsultationId: 'e7181437-f08a-4cf6-b4b6-37497ad01181',
					doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
					patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
					patientInRelationId: null,
					partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
					consultationType: 'TELEMEDICINE_VIDEO_CHAT_CONSULTATION',
					scheduledStartAt: '2022-09-22T10:30:00.000Z',
					scheduledEndAt: '2022-09-22T11:00:00.000Z',
					isCanExtend: null,
					doctorJoinAt: null,
					doctorEndAt: null,
					patientJoinAt: null,
					patientEndAt: null,
					transactionStatus: 'COMPLETED',
					lastUpdatedByUser: {
						action: 'login_success',
						userId: '01GCDSTJ8FWEPN6H6MTQBR206D',
						payload: {
							phoneNumber: '6382345',
						},
						userType: 'customer',
						sessionId:
							'576095c13d928bdaf03c1ae372c6e5835b5cbf21bf963c5165783d27bada67b9',
					},
					description: null,
					place: null,
					link: null,
					metaPatient: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						updatedAt: '2022-09-08T07:10:14.649Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					metaPatientInRelation: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
						updatedAt: '2022-09-08T07:10:14.649Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					metaDoctor: {
						user: {
							city: null,
							name: 'Aditya Arya Tusan',
							email: null,
							phone: '6382345',
							gender: 'male',
							status: 'active',
							userId: '01GCDSTJ8FWEPN6H6MTQBR206D',
							lastName: 'Tusan',
							nickName: 'Aditya',
							province: null,
							userType: 'customer',
							birthdate: '1999-11-30',
							createdAt: '2022-09-08T05:40:36.240Z',
							firstName: 'Aditya',
							updatedAt: '2022-09-08T08:31:31.839Z',
							middleName: 'Arya',
							profilePic: null,
						},
						title: 'SKG',
						doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
						partners: [
							{
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:01:45.057Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:15.723Z',
								pricePerSession: 500,
							},
							{
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:01:45.057Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:01:59.322Z',
								pricePerSession: 500,
							},
							{
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:53:12.891Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:20.032Z',
								pricePerSession: 500,
							},
							{
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:53:55.441Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:53:55.441Z',
								pricePerSession: null,
							},
						],
						createdAt: '2022-09-08T05:47:23.761Z',
						kycStatus: 'APPROVED',
						prcNumber: '',
						ptrNumber: '',
						schedules: [
							{
								endAt: '2022-01-01T15:00:00.970Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.841Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:08.456Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:08.456Z',
								scheduleId: 'a31ae4ad-a007-4a79-a76f-0e4b46321701',
							},
							{
								endAt: '2022-01-01T15:00:00.776Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.973Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:29.740Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:29.740Z',
								scheduleId: '54f7f208-0156-4a7b-abab-01d5a4ab58aa',
							},
							{
								endAt: '2022-01-01T15:00:00.351Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.695Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:53.389Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:53.389Z',
								scheduleId: '8a73f804-38a2-4c63-90d6-0274230d2926',
							},
							{
								endAt: '2022-01-01T15:00:00.079Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.689Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:00.869Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:00.869Z',
								scheduleId: 'f9642816-0af0-4b81-aec1-f6d84f0e004f',
							},
							{
								endAt: '2022-01-01T15:00:00.488Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.787Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:18.728Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:18.728Z',
								scheduleId: '4126c29f-9586-498a-b58b-03f45d8f5949',
							},
							{
								endAt: '2022-01-01T15:00:00.048Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.494Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:27.765Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:27.765Z',
								scheduleId: '4f7a2e8e-4afd-41bf-88f2-59fbcf1a7d1b',
							},
							{
								endAt: '2022-01-01T15:00:00.194Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.066Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:28.709Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:28.709Z',
								scheduleId: 'b59890d5-44a0-447b-9f31-ca137e27779a',
							},
							{
								endAt: '2022-01-01T15:00:00.814Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.197Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:36.439Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:36.439Z',
								scheduleId: 'cb76674b-12ef-4dbc-98aa-99edbb61bc25',
							},
							{
								endAt: '2022-01-01T15:00:00.683Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.952Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:44.409Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:44.409Z',
								scheduleId: '4a3b9e33-2601-4228-903e-79e2c1a171c0',
							},
							{
								endAt: '2022-01-01T15:00:00.303Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.810Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:52.482Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:52.482Z',
								scheduleId: '3459a911-20d6-4179-8873-115af073b8df',
							},
							{
								endAt: '2022-01-01T15:00:00.632Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.098Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:00.731Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:00.731Z',
								scheduleId: '53cbe7a8-8254-4b86-8d49-9153906af659',
							},
							{
								endAt: '2022-01-01T15:00:00.267Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.342Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:10.158Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:10.158Z',
								scheduleId: '368e3dcb-c87e-4382-93b5-f92124af68a0',
							},
							{
								endAt: '2022-01-01T15:00:00.511Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.547Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:23.678Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:23.678Z',
								scheduleId: '506cd274-9f8e-4296-a480-7949bde125e4',
							},
							{
								endAt: '2022-01-01T15:00:00.418Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.377Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:17.220Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:17.220Z',
								scheduleId: '4f2f93d0-8d2a-4a61-9742-db0e509dec5e',
							},
							{
								endAt: '2022-01-01T15:00:00.198Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.805Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:25.263Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:25.263Z',
								scheduleId: '5415d0c1-6a9d-440e-9df9-fc7166caab12',
							},
							{
								endAt: '2022-01-01T15:00:00.972Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.496Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:32.657Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:32.657Z',
								scheduleId: '05ac2913-2d60-4e02-b4b5-340448bab3ac',
							},
							{
								endAt: '2022-01-01T15:00:00.610Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.873Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:40.316Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:40.316Z',
								scheduleId: 'b4b36f6b-1c4b-4886-8645-ddcbd41671cc',
							},
							{
								endAt: '2022-01-01T15:00:00.149Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.634Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:48.087Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:48.087Z',
								scheduleId: '188cf832-1275-49bd-885f-240e390b8e6f',
							},
							{
								endAt: '2022-01-01T15:00:00.118Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.566Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:57.046Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:57.046Z',
								scheduleId: 'c703d009-2840-4730-a9d1-e4ad1dc6a917',
							},
							{
								endAt: '2022-01-01T15:00:00.184Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.850Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:05.067Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:03:05.067Z',
								scheduleId: 'c12eb042-bd87-41b1-9c12-255681814c02',
							},
							{
								endAt: '2022-01-01T15:00:00.691Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.931Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:23.318Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:23.318Z',
								scheduleId: '2ffbdaa9-c727-4de3-ade4-302d22731359',
							},
							{
								endAt: '2022-01-01T15:00:00.512Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.375Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:33.507Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:33.507Z',
								scheduleId: '22a089d9-af75-45cc-927e-d0845099ef05',
							},
							{
								endAt: '2022-01-01T15:00:00.268Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.661Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:43.515Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:43.515Z',
								scheduleId: '9d0fb904-4e28-42af-a4d4-060ad52e704b',
							},
							{
								endAt: '2022-01-01T15:00:00.388Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.083Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:54.009Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:54.009Z',
								scheduleId: '3cbb04c9-e894-45b3-9ab6-4cd9ca68c3dd',
							},
							{
								endAt: '2022-01-01T15:00:00.833Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.384Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:05.486Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:05.486Z',
								scheduleId: 'fc14c9b9-4b89-4667-af32-6c27bd1147d4',
							},
							{
								endAt: '2022-01-01T15:00:00.505Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.098Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:13.020Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:13.020Z',
								scheduleId: '1a17c05d-ef4b-4f58-b4b0-a03ec697dac5',
							},
							{
								endAt: '2022-01-01T15:00:00.599Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.683Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:21.254Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:21.254Z',
								scheduleId: 'f096a199-fe82-40c5-861c-cab046ddbdd7',
							},
							{
								endAt: '2022-01-01T15:00:00.376Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.414Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-14T02:27:52.427Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-14T02:27:52.427Z',
								scheduleId: '42d7db46-19a3-47d0-8af5-cf850aa25ca7',
							},
						],
						startWork: null,
						tinNumber: '',
						updatedAt: '2022-09-08T08:31:30.898Z',
						educations: [],
						experience: 2,
						orderScore: 2,
						specialists: [
							{
								icon: 'psychiatry',
								level: 1,
								createdAt: '2022-09-08T03:16:01.498Z',
								updatedAt: '2022-09-08T03:16:01.498Z',
								specialistId: '87bcd861-d18d-4e00-adb7-c913ba60f435',
								specialistName: 'Psychiatry',
								parentSpecialistId: 'dafe825a-cc64-4b00-8d7d-e7ed8adab9ca',
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									specialistId: '87bcd861-d18d-4e00-adb7-c913ba60f435',
								},
							},
							{
								icon: 'family_medicine',
								level: 2,
								createdAt: '2022-09-08T03:16:01.512Z',
								updatedAt: '2022-09-08T03:16:01.512Z',
								specialistId: '9baad78f-2237-4933-9c77-811cc886b878',
								specialistName: 'Cardiology',
								parentSpecialistId: '55df1bba-f1e7-4e57-982d-084327ef4aac',
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									specialistId: '9baad78f-2237-4933-9c77-811cc886b878',
								},
							},
							{
								icon: 'family_medicine',
								level: 1,
								createdAt: '2022-09-08T03:16:01.498Z',
								updatedAt: '2022-09-08T03:16:01.498Z',
								specialistId: '55df1bba-f1e7-4e57-982d-084327ef4aac',
								specialistName: 'Family Medicine',
								parentSpecialistId: 'dafe825a-cc64-4b00-8d7d-e7ed8adab9ca',
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									specialistId: '55df1bba-f1e7-4e57-982d-084327ef4aac',
								},
							},
							{
								icon: 'critical_care_intensivist',
								level: 2,
								createdAt: '2022-09-08T03:16:01.512Z',
								updatedAt: '2022-09-08T03:16:01.512Z',
								specialistId: '22fc908d-8b1e-4bd9-b7f0-7f4072c70d7f',
								specialistName: 'Critical Care (Intensivist)',
								parentSpecialistId: '87bcd861-d18d-4e00-adb7-c913ba60f435',
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									specialistId: '22fc908d-8b1e-4bd9-b7f0-7f4072c70d7f',
								},
							},
						],
						certificates: [],
						nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
						otherSpecialist: '',
						signatureImageUrl:
							'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
						schedulesInPartners: [
							{
								endAt: '2022-01-01T15:00:00.194Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.066Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:28.709Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:28.709Z',
								scheduleId: 'b59890d5-44a0-447b-9f31-ca137e27779a',
							},
							{
								endAt: '2022-01-01T15:00:00.388Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.083Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:54.009Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:54.009Z',
								scheduleId: '3cbb04c9-e894-45b3-9ab6-4cd9ca68c3dd',
							},
							{
								endAt: '2022-01-01T15:00:00.632Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.098Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:00.731Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:00.731Z',
								scheduleId: '53cbe7a8-8254-4b86-8d49-9153906af659',
							},
							{
								endAt: '2022-01-01T15:00:00.505Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.098Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:13.020Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:13.020Z',
								scheduleId: '1a17c05d-ef4b-4f58-b4b0-a03ec697dac5',
							},
							{
								endAt: '2022-01-01T15:00:00.814Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.197Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:36.439Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:36.439Z',
								scheduleId: 'cb76674b-12ef-4dbc-98aa-99edbb61bc25',
							},
							{
								endAt: '2022-01-01T15:00:00.267Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.342Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:10.158Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:10.158Z',
								scheduleId: '368e3dcb-c87e-4382-93b5-f92124af68a0',
							},
							{
								endAt: '2022-01-01T15:00:00.512Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.375Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:33.507Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:33.507Z',
								scheduleId: '22a089d9-af75-45cc-927e-d0845099ef05',
							},
							{
								endAt: '2022-01-01T15:00:00.418Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.377Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:17.220Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:17.220Z',
								scheduleId: '4f2f93d0-8d2a-4a61-9742-db0e509dec5e',
							},
							{
								endAt: '2022-01-01T15:00:00.833Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.384Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:05.486Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:05.486Z',
								scheduleId: 'fc14c9b9-4b89-4667-af32-6c27bd1147d4',
							},
							{
								endAt: '2022-01-01T15:00:00.376Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.414Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-14T02:27:52.427Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-14T02:27:52.427Z',
								scheduleId: '42d7db46-19a3-47d0-8af5-cf850aa25ca7',
							},
							{
								endAt: '2022-01-01T15:00:00.048Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.494Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:27.765Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:27.765Z',
								scheduleId: '4f7a2e8e-4afd-41bf-88f2-59fbcf1a7d1b',
							},
							{
								endAt: '2022-01-01T15:00:00.972Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.496Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:32.657Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:32.657Z',
								scheduleId: '05ac2913-2d60-4e02-b4b5-340448bab3ac',
							},
							{
								endAt: '2022-01-01T15:00:00.511Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.547Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:23.678Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:23.678Z',
								scheduleId: '506cd274-9f8e-4296-a480-7949bde125e4',
							},
							{
								endAt: '2022-01-01T15:00:00.118Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.566Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:57.046Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:57.046Z',
								scheduleId: 'c703d009-2840-4730-a9d1-e4ad1dc6a917',
							},
							{
								endAt: '2022-01-01T15:00:00.149Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.634Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:48.087Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:48.087Z',
								scheduleId: '188cf832-1275-49bd-885f-240e390b8e6f',
							},
							{
								endAt: '2022-01-01T15:00:00.268Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.661Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:43.515Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:43.515Z',
								scheduleId: '9d0fb904-4e28-42af-a4d4-060ad52e704b',
							},
							{
								endAt: '2022-01-01T15:00:00.599Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.683Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:21.254Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:21.254Z',
								scheduleId: 'f096a199-fe82-40c5-861c-cab046ddbdd7',
							},
							{
								endAt: '2022-01-01T15:00:00.079Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.689Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:00.869Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:00.869Z',
								scheduleId: 'f9642816-0af0-4b81-aec1-f6d84f0e004f',
							},
							{
								endAt: '2022-01-01T15:00:00.351Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.695Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:53.389Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:53.389Z',
								scheduleId: '8a73f804-38a2-4c63-90d6-0274230d2926',
							},
							{
								endAt: '2022-01-01T15:00:00.488Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.787Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:18.728Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:18.728Z',
								scheduleId: '4126c29f-9586-498a-b58b-03f45d8f5949',
							},
							{
								endAt: '2022-01-01T15:00:00.198Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.805Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:25.263Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:25.263Z',
								scheduleId: '5415d0c1-6a9d-440e-9df9-fc7166caab12',
							},
							{
								endAt: '2022-01-01T15:00:00.303Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.810Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:52.482Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:52.482Z',
								scheduleId: '3459a911-20d6-4179-8873-115af073b8df',
							},
							{
								endAt: '2022-01-01T15:00:00.970Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.841Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:08.456Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:08.456Z',
								scheduleId: 'a31ae4ad-a007-4a79-a76f-0e4b46321701',
							},
							{
								endAt: '2022-01-01T15:00:00.184Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.850Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:05.067Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:03:05.067Z',
								scheduleId: 'c12eb042-bd87-41b1-9c12-255681814c02',
							},
							{
								endAt: '2022-01-01T15:00:00.610Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.873Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:40.316Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:40.316Z',
								scheduleId: 'b4b36f6b-1c4b-4886-8645-ddcbd41671cc',
							},
							{
								endAt: '2022-01-01T15:00:00.691Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.931Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:23.318Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:23.318Z',
								scheduleId: '2ffbdaa9-c727-4de3-ade4-302d22731359',
							},
							{
								endAt: '2022-01-01T15:00:00.683Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.952Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:44.409Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:44.409Z',
								scheduleId: '4a3b9e33-2601-4228-903e-79e2c1a171c0',
							},
							{
								endAt: '2022-01-01T15:00:00.776Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.973Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:29.740Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:29.740Z',
								scheduleId: '54f7f208-0156-4a7b-abab-01d5a4ab58aa',
							},
						],
						specialityCertificate: null,
						availableConsultationTypes: [
							'TELEMEDICINE_VIDEO_CHAT_CONSULTATION',
							'TELEMEDICINE_CHAT_CONSULTATION',
							'CONSULT_NOW_VIDEO_CHAT_CONSULTATION',
							'CONSULT_NOW_CHAT_CONSULTATION',
							'FACE_TO_FACE_CONSULTATION',
						],
					},
					metaPartner: {
						partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
						isPublished: true,
						partnerName: 'Online Clinic',
						partnerType: 'ONLINE_CLINIC',
						isConfirmedPartner: true,
					},
					createdByUserId: '01GCDNAV6270SMVQBEAQ90VY4G',
					metaCreatedByUser: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						updatedAt: '2022-09-08T07:10:14.649Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					createdAt: '2022-09-22T10:08:39.931Z',
					updatedAt: '2022-09-22T10:23:52.071Z',
					medicalRecord: {
						medicalRecordId: 'ec86adf3-3f2b-4def-b2d1-6c8408574208',
						doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
						patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patientInRelationId: null,
						reasonOfConsultation: 'Maag',
						historyPhysicalFindings: 'A',
						diagnosis: 'A',
						planForManagement: 'A',
						transactionConsultationId: 'e7181437-f08a-4cf6-b4b6-37497ad01181',
						uploadedFile: null,
						createdAt: '2022-09-22T10:08:39.946Z',
						updatedAt: '2022-09-22T10:19:07.355Z',
					},
					history: [
						{
							historyId: 'a3eaed82-1bfe-46fb-9cf9-6d61124f18a0',
							transactionConsultationId: 'e7181437-f08a-4cf6-b4b6-37497ad01181',
							description: 'Update transaction status',
							newData: {
								transactionStatus: 'COMPLETED',
							},
							createdByUserId: '01GCDSTJ8FWEPN6H6MTQBR206D',
							metaCreatedByUser: {
								action: 'login_success',
								userId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								payload: {
									phoneNumber: '6382345',
								},
								userType: 'customer',
								sessionId:
									'576095c13d928bdaf03c1ae372c6e5835b5cbf21bf963c5165783d27bada67b9',
							},
							createdAt: '2022-09-22T10:23:52.082Z',
							updatedAt: '2022-09-22T10:23:52.082Z',
						},
						{
							historyId: 'e8a7fd7e-8928-4b94-9de2-bfd6f69ea9ed',
							transactionConsultationId: 'e7181437-f08a-4cf6-b4b6-37497ad01181',
							description: 'Update transaction status',
							newData: {
								transactionStatus: 'WAITING_APPROVAL',
							},
							createdByUserId: '01GCDNAV6270SMVQBEAQ90VY4G',
							metaCreatedByUser: {
								city: null,
								name: 'Rizki Rahmadi',
								email: null,
								phone: '63125',
								doctor: null,
								gender: 'male',
								status: 'active',
								userId: '01GCDNAV6270SMVQBEAQ90VY4G',
								patient: {
									pwId: null,
									pwIdUrl: null,
									createdAt: '2022-09-08T04:22:06.889Z',
									patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
									updatedAt: '2022-09-08T04:22:06.889Z',
									pwIdStatus: 'WAITING_APPROVAL',
								},
								lastName: 'Rahmadi',
								nickName: 'Iki',
								province: null,
								userType: 'customer',
								birthdate: '1999-03-11',
								createdAt: '2022-09-08T04:22:06.787Z',
								firstName: 'Rizki',
								updatedAt: '2022-09-08T07:10:14.649Z',
								middleName: null,
								profilePic: null,
								isEmailVerified: false,
								isPhoneVerified: true,
							},
							createdAt: '2022-09-22T10:09:21.367Z',
							updatedAt: '2022-09-22T10:09:21.367Z',
						},
						{
							historyId: '2d9c29a1-568f-4253-b70c-3b84c0f01915',
							transactionConsultationId: 'e7181437-f08a-4cf6-b4b6-37497ad01181',
							description: 'Update transaction status',
							newData: {
								transactionStatus: 'APPROVED',
							},
							createdByUserId: '01GCDSTJ8FWEPN6H6MTQBR206D',
							metaCreatedByUser: {
								action: 'login_success',
								userId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								payload: {
									phoneNumber: '6382345',
								},
								userType: 'customer',
								sessionId:
									'576095c13d928bdaf03c1ae372c6e5835b5cbf21bf963c5165783d27bada67b9',
							},
							createdAt: '2022-09-22T10:11:28.989Z',
							updatedAt: '2022-09-22T10:11:28.989Z',
						},
					],
					payment: {
						paymentId: 'e27dc513-ef6c-42e4-986a-adf0a5103037',
						invoiceId: 'CT/20220922/NL6',
						transactionId: 'e7181437-f08a-4cf6-b4b6-37497ad01181',
						transactionType: 'TELEMEDICINE',
						totalNeedToPay: 575,
						paymentStatus: 'PAID_FULL',
						buyerId: '01GCDNAV6270SMVQBEAQ90VY4G',
						metaBuyerDetail: {
							city: null,
							name: 'Rizki Rahmadi',
							email: null,
							phone: '63125',
							doctor: null,
							gender: 'male',
							status: 'active',
							userId: '01GCDNAV6270SMVQBEAQ90VY4G',
							patient: {
								pwId: null,
								pwIdUrl: null,
								createdAt: '2022-09-08T04:22:06.889Z',
								patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
								updatedAt: '2022-09-08T04:22:06.889Z',
								pwIdStatus: 'WAITING_APPROVAL',
							},
							lastName: 'Rahmadi',
							nickName: 'Iki',
							province: null,
							userType: 'customer',
							birthdate: '1999-03-11',
							createdAt: '2022-09-08T04:22:06.787Z',
							firstName: 'Rizki',
							updatedAt: '2022-09-08T07:10:14.649Z',
							middleName: null,
							profilePic: null,
							isEmailVerified: false,
							isPhoneVerified: true,
						},
						meta: null,
						expiredAt: '2022-09-23T14:08:40.119Z',
						createdAt: '2022-09-22T10:08:40.120Z',
						updatedAt: '2022-09-22T10:09:21.347Z',
						paymentDetails: [
							{
								paymentDetailId: 'bdf00e55-6171-45be-8e54-26d0d9c77648',
								paymentId: 'e27dc513-ef6c-42e4-986a-adf0a5103037',
								paymentType: 'BASE_PRICE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 500,
								status: 'PAID_FULL',
								meta: {
									consultationFee: 500,
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-23T14:08:40.119Z',
								createdAt: '2022-09-22T10:08:40.195Z',
								updatedAt: '2022-09-22T10:08:40.195Z',
							},
							{
								paymentDetailId: '7a80c295-dd44-4935-9e6e-9f31b4c793e4',
								paymentId: 'e27dc513-ef6c-42e4-986a-adf0a5103037',
								paymentType: 'PATIENT_TRANSACTION_FEE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 75,
								status: 'PAID_FULL',
								meta: {
									key: 'PATIENT_TRANSACTION_FEE',
									value: '5%+50',
									configId: 'PATIENT_TRANSACTION_FEE',
									createdAt: '2022-09-08T06:08:45.373Z',
									updatedAt: '2022-09-08T06:08:45.373Z',
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-23T14:08:40.119Z',
								createdAt: '2022-09-22T10:08:40.197Z',
								updatedAt: '2022-09-22T10:08:40.197Z',
							},
							{
								paymentDetailId: '0ec9329c-4d2d-4831-a44c-aa6d98a88d15',
								paymentId: 'e27dc513-ef6c-42e4-986a-adf0a5103037',
								paymentType: 'PAYMENT_GATEWAY',
								paymentMethod: 'EWALLET',
								paymentVendorType: 'PAYMONGO',
								paymentVendorId: 'GCASH',
								externalId: null,
								totalPay: 575,
								status: 'PAID_FULL',
								meta: {
									data: {
										id: 'evt_Vjb5fn6gArTsBEXCp8FSdWD5',
										type: 'event',
										attributes: {
											data: {
												id: 'pay_NmzCdEVv3eJSikSJKEow4vYw',
												type: 'payment',
												attributes: {
													fee: 1438,
													taxes: [],
													amount: 57500,
													origin: 'api',
													payout: null,
													source: {
														id: 'src_2HKFGVSZQgQoZyszcikLsMtM',
														type: 'gcash',
													},
													status: 'paid',
													billing: null,
													paid_at: 1663841342,
													refunds: [],
													currency: 'PHP',
													disputed: false,
													livemode: false,
													metadata: {
														paymentId: 'e27dc513-ef6c-42e4-986a-adf0a5103037',
													},
													access_url: null,
													created_at: 1663841342,
													net_amount: 56062,
													tax_amount: null,
													updated_at: 1663841342,
													description: null,
													available_at: 1664269200,
													payment_intent_id: null,
													statement_descriptor: 'PAYMONGO',
													balance_transaction_id:
														'bal_txn_d63SfXECn3tmvUR2tKJuw3Qn',
													external_reference_number: null,
												},
											},
											type: 'payment.paid',
											livemode: false,
											created_at: 1663841342,
											updated_at: 1663841342,
											previous_data: {},
										},
									},
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-23T14:08:40.119Z',
								createdAt: '2022-09-22T10:08:40.192Z',
								updatedAt: '2022-09-22T10:09:21.338Z',
							},
						],
						paymentDetailsPartners: [
							{
								paymentDetailId: '98317482-23be-4bf3-b273-8fd39b0456ea',
								paymentId: 'e27dc513-ef6c-42e4-986a-adf0a5103037',
								paymentType: 'FINAL_PAYMENT',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 473,
								status: 'PAID_FULL',
								meta: {},
								type: 'PAYMENT',
								expiredAt: '2022-09-23T14:08:40.119Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-22T10:08:40.207Z',
								updatedAt: '2022-09-22T10:08:40.207Z',
							},
							{
								paymentDetailId: 'e6f01600-63bb-4b1d-92a3-cace72e04b77',
								paymentId: 'e27dc513-ef6c-42e4-986a-adf0a5103037',
								paymentType: 'DOCTOR_TRANSACTION_FEE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 17,
								status: 'PAID_FULL',
								meta: {
									key: 'DOCTOR_TRANSACTION_FEE',
									value: '3%',
									configId: 'DOCTOR_TRANSACTION_FEE',
									createdAt: '2022-09-08T06:08:45.373Z',
									updatedAt: '2022-09-08T06:08:45.373Z',
								},
								type: 'DISCOUNT',
								expiredAt: '2022-09-23T14:08:40.119Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-22T10:08:40.207Z',
								updatedAt: '2022-09-22T10:08:40.207Z',
							},
							{
								paymentDetailId: 'cc4f1ff8-9ef8-4e28-80eb-de55ee54ed12',
								paymentId: 'e27dc513-ef6c-42e4-986a-adf0a5103037',
								paymentType: 'DOCTOR_COMMISSION_FEE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 10,
								status: 'PAID_FULL',
								meta: {
									key: 'DOCTOR_COMMISSION_FEE',
									value: '2%',
									configId: 'DOCTOR_COMMISSION_FEE',
									createdAt: '2022-09-08T09:27:39.090Z',
									updatedAt: '2022-09-08T09:27:39.090Z',
								},
								type: 'DISCOUNT',
								expiredAt: '2022-09-23T14:08:40.119Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-22T10:08:40.207Z',
								updatedAt: '2022-09-22T10:08:40.207Z',
							},
							{
								paymentDetailId: 'a903849e-f87d-464a-8db0-c136a6a23fd7',
								paymentId: 'e27dc513-ef6c-42e4-986a-adf0a5103037',
								paymentType: 'PATIENT_PWD_DISCOUNT',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 0,
								status: 'PAID_FULL',
								meta: {},
								type: 'DISCOUNT',
								expiredAt: '2022-09-23T14:08:40.119Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-22T10:08:40.207Z',
								updatedAt: '2022-09-22T10:08:40.207Z',
							},
							{
								paymentDetailId: '4103dcc7-141d-4b55-9cc2-6d3b5b40dac7',
								paymentId: 'e27dc513-ef6c-42e4-986a-adf0a5103037',
								paymentType: 'BASE_PRICE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 500,
								status: 'PAID_FULL',
								meta: {},
								type: 'PAYMENT',
								expiredAt: '2022-09-23T14:08:40.119Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-22T10:08:40.207Z',
								updatedAt: '2022-09-22T10:08:40.207Z',
							},
						],
					},
				},
			},
			{
				name: 'Rizki Rahmadi',
				date: '2022-09-20',
				desc: 'Maag',
				reason: 'Maag',
				startTime: '12:30',
				endTime: '13:00',
				detailDoctor: {
					name: 'Aditya Arya Tusan',
					title:
						'Psychiatry, Cardiology, Family Medicine, Critical Care (Intensivist)',
					age: 22,
					ageString: '22 yo',
				},
				raw: {
					transactionConsultationId: '40090f32-924b-4dca-9d14-59d42cb265dd',
					doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
					patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
					patientInRelationId: null,
					partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
					consultationType: 'TELEMEDICINE_VIDEO_CHAT_CONSULTATION',
					scheduledStartAt: '2022-09-20T05:30:00.000Z',
					scheduledEndAt: '2022-09-20T06:00:00.000Z',
					isCanExtend: null,
					doctorJoinAt: null,
					doctorEndAt: null,
					patientJoinAt: null,
					patientEndAt: null,
					transactionStatus: 'APPROVED',
					lastUpdatedByUser: {
						action: 'login_success',
						userId: '01GCDSTJ8FWEPN6H6MTQBR206D',
						payload: {
							phoneNumber: '6382345',
						},
						userType: 'customer',
						sessionId:
							'ad383d01080234254147d58a70ad2a07e8ed3ab842585e7d56f4b44fb005bd5a',
					},
					description: null,
					place: null,
					link: null,
					metaPatient: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						updatedAt: '2022-09-08T07:10:14.649Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					metaPatientInRelation: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
						updatedAt: '2022-09-08T07:10:14.649Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					metaDoctor: {
						user: {
							city: null,
							name: 'Aditya Arya Tusan',
							email: null,
							phone: '6382345',
							gender: 'male',
							status: 'active',
							userId: '01GCDSTJ8FWEPN6H6MTQBR206D',
							lastName: 'Tusan',
							nickName: 'Aditya',
							province: null,
							userType: 'customer',
							birthdate: '1999-11-30',
							createdAt: '2022-09-08T05:40:36.240Z',
							firstName: 'Aditya',
							updatedAt: '2022-09-08T08:31:31.839Z',
							middleName: 'Arya',
							profilePic: null,
						},
						title: 'SKG',
						doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
						partners: [
							{
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:01:45.057Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:15.723Z',
								pricePerSession: 500,
							},
							{
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:01:45.057Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:01:59.322Z',
								pricePerSession: 500,
							},
							{
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:53:12.891Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:20.032Z',
								pricePerSession: 500,
							},
							{
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:53:55.441Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:53:55.441Z',
								pricePerSession: null,
							},
						],
						createdAt: '2022-09-08T05:47:23.761Z',
						kycStatus: 'APPROVED',
						prcNumber: '',
						ptrNumber: '',
						schedules: [
							{
								endAt: '2022-01-01T15:00:00.970Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.841Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:08.456Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:08.456Z',
								scheduleId: 'a31ae4ad-a007-4a79-a76f-0e4b46321701',
							},
							{
								endAt: '2022-01-01T15:00:00.776Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.973Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:29.740Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:29.740Z',
								scheduleId: '54f7f208-0156-4a7b-abab-01d5a4ab58aa',
							},
							{
								endAt: '2022-01-01T15:00:00.351Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.695Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:53.389Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:53.389Z',
								scheduleId: '8a73f804-38a2-4c63-90d6-0274230d2926',
							},
							{
								endAt: '2022-01-01T15:00:00.079Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.689Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:00.869Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:00.869Z',
								scheduleId: 'f9642816-0af0-4b81-aec1-f6d84f0e004f',
							},
							{
								endAt: '2022-01-01T15:00:00.488Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.787Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:18.728Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:18.728Z',
								scheduleId: '4126c29f-9586-498a-b58b-03f45d8f5949',
							},
							{
								endAt: '2022-01-01T15:00:00.048Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.494Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:27.765Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:27.765Z',
								scheduleId: '4f7a2e8e-4afd-41bf-88f2-59fbcf1a7d1b',
							},
							{
								endAt: '2022-01-01T15:00:00.194Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.066Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:28.709Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:28.709Z',
								scheduleId: 'b59890d5-44a0-447b-9f31-ca137e27779a',
							},
							{
								endAt: '2022-01-01T15:00:00.814Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.197Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:36.439Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:36.439Z',
								scheduleId: 'cb76674b-12ef-4dbc-98aa-99edbb61bc25',
							},
							{
								endAt: '2022-01-01T15:00:00.683Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.952Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:44.409Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:44.409Z',
								scheduleId: '4a3b9e33-2601-4228-903e-79e2c1a171c0',
							},
							{
								endAt: '2022-01-01T15:00:00.303Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.810Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:52.482Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:52.482Z',
								scheduleId: '3459a911-20d6-4179-8873-115af073b8df',
							},
							{
								endAt: '2022-01-01T15:00:00.632Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.098Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:00.731Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:00.731Z',
								scheduleId: '53cbe7a8-8254-4b86-8d49-9153906af659',
							},
							{
								endAt: '2022-01-01T15:00:00.267Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.342Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:10.158Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:10.158Z',
								scheduleId: '368e3dcb-c87e-4382-93b5-f92124af68a0',
							},
							{
								endAt: '2022-01-01T15:00:00.511Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.547Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:23.678Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:23.678Z',
								scheduleId: '506cd274-9f8e-4296-a480-7949bde125e4',
							},
							{
								endAt: '2022-01-01T15:00:00.418Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.377Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:17.220Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:17.220Z',
								scheduleId: '4f2f93d0-8d2a-4a61-9742-db0e509dec5e',
							},
							{
								endAt: '2022-01-01T15:00:00.198Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.805Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:25.263Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:25.263Z',
								scheduleId: '5415d0c1-6a9d-440e-9df9-fc7166caab12',
							},
							{
								endAt: '2022-01-01T15:00:00.972Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.496Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:32.657Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:32.657Z',
								scheduleId: '05ac2913-2d60-4e02-b4b5-340448bab3ac',
							},
							{
								endAt: '2022-01-01T15:00:00.610Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.873Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:40.316Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:40.316Z',
								scheduleId: 'b4b36f6b-1c4b-4886-8645-ddcbd41671cc',
							},
							{
								endAt: '2022-01-01T15:00:00.149Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.634Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:48.087Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:48.087Z',
								scheduleId: '188cf832-1275-49bd-885f-240e390b8e6f',
							},
							{
								endAt: '2022-01-01T15:00:00.118Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.566Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:57.046Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:57.046Z',
								scheduleId: 'c703d009-2840-4730-a9d1-e4ad1dc6a917',
							},
							{
								endAt: '2022-01-01T15:00:00.184Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.850Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:05.067Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:03:05.067Z',
								scheduleId: 'c12eb042-bd87-41b1-9c12-255681814c02',
							},
							{
								endAt: '2022-01-01T15:00:00.691Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.931Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:23.318Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:23.318Z',
								scheduleId: '2ffbdaa9-c727-4de3-ade4-302d22731359',
							},
							{
								endAt: '2022-01-01T15:00:00.512Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.375Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:33.507Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:33.507Z',
								scheduleId: '22a089d9-af75-45cc-927e-d0845099ef05',
							},
							{
								endAt: '2022-01-01T15:00:00.268Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.661Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:43.515Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:43.515Z',
								scheduleId: '9d0fb904-4e28-42af-a4d4-060ad52e704b',
							},
							{
								endAt: '2022-01-01T15:00:00.388Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.083Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:54.009Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:54.009Z',
								scheduleId: '3cbb04c9-e894-45b3-9ab6-4cd9ca68c3dd',
							},
							{
								endAt: '2022-01-01T15:00:00.833Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.384Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:05.486Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:05.486Z',
								scheduleId: 'fc14c9b9-4b89-4667-af32-6c27bd1147d4',
							},
							{
								endAt: '2022-01-01T15:00:00.505Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.098Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:13.020Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:13.020Z',
								scheduleId: '1a17c05d-ef4b-4f58-b4b0-a03ec697dac5',
							},
							{
								endAt: '2022-01-01T15:00:00.599Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.683Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:21.254Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:21.254Z',
								scheduleId: 'f096a199-fe82-40c5-861c-cab046ddbdd7',
							},
							{
								endAt: '2022-01-01T15:00:00.376Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.414Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-14T02:27:52.427Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-14T02:27:52.427Z',
								scheduleId: '42d7db46-19a3-47d0-8af5-cf850aa25ca7',
							},
						],
						startWork: null,
						tinNumber: '',
						updatedAt: '2022-09-08T08:31:30.898Z',
						educations: [],
						experience: 2,
						orderScore: 2,
						specialists: [
							{
								icon: 'psychiatry',
								level: 1,
								createdAt: '2022-09-08T03:16:01.498Z',
								updatedAt: '2022-09-08T03:16:01.498Z',
								specialistId: '87bcd861-d18d-4e00-adb7-c913ba60f435',
								specialistName: 'Psychiatry',
								parentSpecialistId: 'dafe825a-cc64-4b00-8d7d-e7ed8adab9ca',
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									specialistId: '87bcd861-d18d-4e00-adb7-c913ba60f435',
								},
							},
							{
								icon: 'family_medicine',
								level: 2,
								createdAt: '2022-09-08T03:16:01.512Z',
								updatedAt: '2022-09-08T03:16:01.512Z',
								specialistId: '9baad78f-2237-4933-9c77-811cc886b878',
								specialistName: 'Cardiology',
								parentSpecialistId: '55df1bba-f1e7-4e57-982d-084327ef4aac',
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									specialistId: '9baad78f-2237-4933-9c77-811cc886b878',
								},
							},
							{
								icon: 'family_medicine',
								level: 1,
								createdAt: '2022-09-08T03:16:01.498Z',
								updatedAt: '2022-09-08T03:16:01.498Z',
								specialistId: '55df1bba-f1e7-4e57-982d-084327ef4aac',
								specialistName: 'Family Medicine',
								parentSpecialistId: 'dafe825a-cc64-4b00-8d7d-e7ed8adab9ca',
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									specialistId: '55df1bba-f1e7-4e57-982d-084327ef4aac',
								},
							},
							{
								icon: 'critical_care_intensivist',
								level: 2,
								createdAt: '2022-09-08T03:16:01.512Z',
								updatedAt: '2022-09-08T03:16:01.512Z',
								specialistId: '22fc908d-8b1e-4bd9-b7f0-7f4072c70d7f',
								specialistName: 'Critical Care (Intensivist)',
								parentSpecialistId: '87bcd861-d18d-4e00-adb7-c913ba60f435',
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									specialistId: '22fc908d-8b1e-4bd9-b7f0-7f4072c70d7f',
								},
							},
						],
						certificates: [],
						nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
						otherSpecialist: '',
						signatureImageUrl:
							'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
						schedulesInPartners: [
							{
								endAt: '2022-01-01T15:00:00.194Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.066Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:28.709Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:28.709Z',
								scheduleId: 'b59890d5-44a0-447b-9f31-ca137e27779a',
							},
							{
								endAt: '2022-01-01T15:00:00.388Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.083Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:54.009Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:54.009Z',
								scheduleId: '3cbb04c9-e894-45b3-9ab6-4cd9ca68c3dd',
							},
							{
								endAt: '2022-01-01T15:00:00.632Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.098Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:00.731Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:00.731Z',
								scheduleId: '53cbe7a8-8254-4b86-8d49-9153906af659',
							},
							{
								endAt: '2022-01-01T15:00:00.505Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.098Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:13.020Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:13.020Z',
								scheduleId: '1a17c05d-ef4b-4f58-b4b0-a03ec697dac5',
							},
							{
								endAt: '2022-01-01T15:00:00.814Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.197Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:36.439Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:36.439Z',
								scheduleId: 'cb76674b-12ef-4dbc-98aa-99edbb61bc25',
							},
							{
								endAt: '2022-01-01T15:00:00.267Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.342Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:10.158Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:10.158Z',
								scheduleId: '368e3dcb-c87e-4382-93b5-f92124af68a0',
							},
							{
								endAt: '2022-01-01T15:00:00.512Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.375Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:33.507Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:33.507Z',
								scheduleId: '22a089d9-af75-45cc-927e-d0845099ef05',
							},
							{
								endAt: '2022-01-01T15:00:00.418Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.377Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:17.220Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:17.220Z',
								scheduleId: '4f2f93d0-8d2a-4a61-9742-db0e509dec5e',
							},
							{
								endAt: '2022-01-01T15:00:00.833Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.384Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:05.486Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:05.486Z',
								scheduleId: 'fc14c9b9-4b89-4667-af32-6c27bd1147d4',
							},
							{
								endAt: '2022-01-01T15:00:00.376Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.414Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-14T02:27:52.427Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-14T02:27:52.427Z',
								scheduleId: '42d7db46-19a3-47d0-8af5-cf850aa25ca7',
							},
							{
								endAt: '2022-01-01T15:00:00.048Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.494Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:27.765Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:27.765Z',
								scheduleId: '4f7a2e8e-4afd-41bf-88f2-59fbcf1a7d1b',
							},
							{
								endAt: '2022-01-01T15:00:00.972Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.496Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:32.657Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:32.657Z',
								scheduleId: '05ac2913-2d60-4e02-b4b5-340448bab3ac',
							},
							{
								endAt: '2022-01-01T15:00:00.511Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.547Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:23.678Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:23.678Z',
								scheduleId: '506cd274-9f8e-4296-a480-7949bde125e4',
							},
							{
								endAt: '2022-01-01T15:00:00.118Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.566Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:57.046Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:57.046Z',
								scheduleId: 'c703d009-2840-4730-a9d1-e4ad1dc6a917',
							},
							{
								endAt: '2022-01-01T15:00:00.149Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.634Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:48.087Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:48.087Z',
								scheduleId: '188cf832-1275-49bd-885f-240e390b8e6f',
							},
							{
								endAt: '2022-01-01T15:00:00.268Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.661Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:43.515Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:43.515Z',
								scheduleId: '9d0fb904-4e28-42af-a4d4-060ad52e704b',
							},
							{
								endAt: '2022-01-01T15:00:00.599Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.683Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:21.254Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:21.254Z',
								scheduleId: 'f096a199-fe82-40c5-861c-cab046ddbdd7',
							},
							{
								endAt: '2022-01-01T15:00:00.079Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.689Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:00.869Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:00.869Z',
								scheduleId: 'f9642816-0af0-4b81-aec1-f6d84f0e004f',
							},
							{
								endAt: '2022-01-01T15:00:00.351Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.695Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:53.389Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:53.389Z',
								scheduleId: '8a73f804-38a2-4c63-90d6-0274230d2926',
							},
							{
								endAt: '2022-01-01T15:00:00.488Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.787Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:18.728Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:18.728Z',
								scheduleId: '4126c29f-9586-498a-b58b-03f45d8f5949',
							},
							{
								endAt: '2022-01-01T15:00:00.198Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.805Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:25.263Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:25.263Z',
								scheduleId: '5415d0c1-6a9d-440e-9df9-fc7166caab12',
							},
							{
								endAt: '2022-01-01T15:00:00.303Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.810Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:52.482Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:52.482Z',
								scheduleId: '3459a911-20d6-4179-8873-115af073b8df',
							},
							{
								endAt: '2022-01-01T15:00:00.970Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.841Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:08.456Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:08.456Z',
								scheduleId: 'a31ae4ad-a007-4a79-a76f-0e4b46321701',
							},
							{
								endAt: '2022-01-01T15:00:00.184Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.850Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:05.067Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:03:05.067Z',
								scheduleId: 'c12eb042-bd87-41b1-9c12-255681814c02',
							},
							{
								endAt: '2022-01-01T15:00:00.610Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.873Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:40.316Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:40.316Z',
								scheduleId: 'b4b36f6b-1c4b-4886-8645-ddcbd41671cc',
							},
							{
								endAt: '2022-01-01T15:00:00.691Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.931Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:23.318Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:23.318Z',
								scheduleId: '2ffbdaa9-c727-4de3-ade4-302d22731359',
							},
							{
								endAt: '2022-01-01T15:00:00.683Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.952Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:44.409Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:44.409Z',
								scheduleId: '4a3b9e33-2601-4228-903e-79e2c1a171c0',
							},
							{
								endAt: '2022-01-01T15:00:00.776Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.973Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:29.740Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:29.740Z',
								scheduleId: '54f7f208-0156-4a7b-abab-01d5a4ab58aa',
							},
						],
						specialityCertificate: null,
						availableConsultationTypes: [
							'TELEMEDICINE_VIDEO_CHAT_CONSULTATION',
							'TELEMEDICINE_CHAT_CONSULTATION',
							'CONSULT_NOW_VIDEO_CHAT_CONSULTATION',
							'CONSULT_NOW_CHAT_CONSULTATION',
							'FACE_TO_FACE_CONSULTATION',
						],
					},
					metaPartner: {
						partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
						isPublished: true,
						partnerName: 'Online Clinic',
						partnerType: 'ONLINE_CLINIC',
						isConfirmedPartner: true,
					},
					createdByUserId: '01GCDNAV6270SMVQBEAQ90VY4G',
					metaCreatedByUser: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						updatedAt: '2022-09-08T07:10:14.649Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					createdAt: '2022-09-20T04:48:44.509Z',
					updatedAt: '2022-09-22T08:29:59.233Z',
					medicalRecord: {
						medicalRecordId: 'a13cfbcb-3ad8-46b9-b74c-b96c91b9c341',
						doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
						patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patientInRelationId: null,
						reasonOfConsultation: 'Maag',
						historyPhysicalFindings: null,
						diagnosis: null,
						planForManagement: null,
						transactionConsultationId: '40090f32-924b-4dca-9d14-59d42cb265dd',
						uploadedFile: null,
						createdAt: '2022-09-20T04:48:44.528Z',
						updatedAt: '2022-09-20T04:48:44.528Z',
					},
					history: [
						{
							historyId: 'cfc2e2bd-aacd-4b23-9413-f4030f49d67e',
							transactionConsultationId: '40090f32-924b-4dca-9d14-59d42cb265dd',
							description: 'Update transaction status',
							newData: {
								transactionStatus: 'WAITING_APPROVAL',
							},
							createdByUserId: '01GCDNAV6270SMVQBEAQ90VY4G',
							metaCreatedByUser: {
								city: null,
								name: 'Rizki Rahmadi',
								email: null,
								phone: '63125',
								doctor: null,
								gender: 'male',
								status: 'active',
								userId: '01GCDNAV6270SMVQBEAQ90VY4G',
								patient: {
									pwId: null,
									pwIdUrl: null,
									createdAt: '2022-09-08T04:22:06.889Z',
									patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
									updatedAt: '2022-09-08T04:22:06.889Z',
									pwIdStatus: 'WAITING_APPROVAL',
								},
								lastName: 'Rahmadi',
								nickName: 'Iki',
								province: null,
								userType: 'customer',
								birthdate: '1999-03-11',
								createdAt: '2022-09-08T04:22:06.787Z',
								firstName: 'Rizki',
								updatedAt: '2022-09-08T07:10:14.649Z',
								middleName: null,
								profilePic: null,
								isEmailVerified: false,
								isPhoneVerified: true,
							},
							createdAt: '2022-09-20T04:49:35.788Z',
							updatedAt: '2022-09-20T04:49:35.788Z',
						},
						{
							historyId: 'ffa057ee-5f14-4421-88a2-2e496d0a713d',
							transactionConsultationId: '40090f32-924b-4dca-9d14-59d42cb265dd',
							description: 'Update transaction status',
							newData: {
								transactionStatus: 'APPROVED',
							},
							createdByUserId: '01GCDSTJ8FWEPN6H6MTQBR206D',
							metaCreatedByUser: {
								action: 'login_success',
								userId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								payload: {
									phoneNumber: '6382345',
								},
								userType: 'customer',
								sessionId:
									'ad383d01080234254147d58a70ad2a07e8ed3ab842585e7d56f4b44fb005bd5a',
							},
							createdAt: '2022-09-22T08:29:59.244Z',
							updatedAt: '2022-09-22T08:29:59.244Z',
						},
					],
					payment: {
						paymentId: '8c157922-bbb5-4a41-b885-45c549bd79f9',
						invoiceId: 'CT/20220920/GYY',
						transactionId: '40090f32-924b-4dca-9d14-59d42cb265dd',
						transactionType: 'TELEMEDICINE',
						totalNeedToPay: 575,
						paymentStatus: 'PAID_FULL',
						buyerId: '01GCDNAV6270SMVQBEAQ90VY4G',
						metaBuyerDetail: {
							city: null,
							name: 'Rizki Rahmadi',
							email: null,
							phone: '63125',
							doctor: null,
							gender: 'male',
							status: 'active',
							userId: '01GCDNAV6270SMVQBEAQ90VY4G',
							patient: {
								pwId: null,
								pwIdUrl: null,
								createdAt: '2022-09-08T04:22:06.889Z',
								patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
								updatedAt: '2022-09-08T04:22:06.889Z',
								pwIdStatus: 'WAITING_APPROVAL',
							},
							lastName: 'Rahmadi',
							nickName: 'Iki',
							province: null,
							userType: 'customer',
							birthdate: '1999-03-11',
							createdAt: '2022-09-08T04:22:06.787Z',
							firstName: 'Rizki',
							updatedAt: '2022-09-08T07:10:14.649Z',
							middleName: null,
							profilePic: null,
							isEmailVerified: false,
							isPhoneVerified: true,
						},
						meta: null,
						expiredAt: '2022-09-21T08:48:45.253Z',
						createdAt: '2022-09-20T04:48:45.255Z',
						updatedAt: '2022-09-20T04:49:35.754Z',
						paymentDetails: [
							{
								paymentDetailId: 'e1f94fa4-a48e-4b2f-a818-739abd98351c',
								paymentId: '8c157922-bbb5-4a41-b885-45c549bd79f9',
								paymentType: 'BASE_PRICE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 500,
								status: 'PAID_FULL',
								meta: {
									consultationFee: 500,
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-21T08:48:45.253Z',
								createdAt: '2022-09-20T04:48:45.323Z',
								updatedAt: '2022-09-20T04:48:45.323Z',
							},
							{
								paymentDetailId: 'ad5574cd-9e3c-4455-ab30-b8093d4300c0',
								paymentId: '8c157922-bbb5-4a41-b885-45c549bd79f9',
								paymentType: 'PATIENT_TRANSACTION_FEE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 75,
								status: 'PAID_FULL',
								meta: {
									key: 'PATIENT_TRANSACTION_FEE',
									value: '5%+50',
									configId: 'PATIENT_TRANSACTION_FEE',
									createdAt: '2022-09-08T06:08:45.373Z',
									updatedAt: '2022-09-08T06:08:45.373Z',
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-21T08:48:45.253Z',
								createdAt: '2022-09-20T04:48:45.325Z',
								updatedAt: '2022-09-20T04:48:45.325Z',
							},
							{
								paymentDetailId: '8a1d6e9f-596c-4e86-8c64-4aedffea1d7f',
								paymentId: '8c157922-bbb5-4a41-b885-45c549bd79f9',
								paymentType: 'PAYMENT_GATEWAY',
								paymentMethod: 'EWALLET',
								paymentVendorType: 'PAYMONGO',
								paymentVendorId: 'GCASH',
								externalId: null,
								totalPay: 575,
								status: 'PAID_FULL',
								meta: {
									data: {
										id: 'evt_v1mYwdi19UKymKXTga42kHK8',
										type: 'event',
										attributes: {
											data: {
												id: 'pay_kBppG9LNXvszwh1ZoqF3RrjC',
												type: 'payment',
												attributes: {
													fee: 1438,
													taxes: [],
													amount: 57500,
													origin: 'api',
													payout: null,
													source: {
														id: 'src_MMkMJttXXiyqBNbx9ciRSnzW',
														type: 'gcash',
													},
													status: 'paid',
													billing: null,
													paid_at: 1663649356,
													refunds: [],
													currency: 'PHP',
													disputed: false,
													livemode: false,
													metadata: {
														paymentId: '8c157922-bbb5-4a41-b885-45c549bd79f9',
													},
													access_url: null,
													created_at: 1663649356,
													net_amount: 56062,
													tax_amount: null,
													updated_at: 1663649356,
													description: null,
													available_at: 1663837200,
													payment_intent_id: null,
													statement_descriptor: 'PAYMONGO',
													balance_transaction_id:
														'bal_txn_sfdb5JAVvwpY78fTRWVkUjt2',
													external_reference_number: null,
												},
											},
											type: 'payment.paid',
											livemode: false,
											created_at: 1663649356,
											updated_at: 1663649356,
											previous_data: {},
										},
									},
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-21T08:48:45.253Z',
								createdAt: '2022-09-20T04:48:45.320Z',
								updatedAt: '2022-09-20T04:49:35.739Z',
							},
						],
						paymentDetailsPartners: [
							{
								paymentDetailId: 'b7ea9e53-7aca-4c3d-a22f-8544fa0a0068',
								paymentId: '8c157922-bbb5-4a41-b885-45c549bd79f9',
								paymentType: 'FINAL_PAYMENT',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 473,
								status: 'PAID_FULL',
								meta: {},
								type: 'PAYMENT',
								expiredAt: '2022-09-21T08:48:45.253Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-20T04:48:45.332Z',
								updatedAt: '2022-09-20T04:48:45.332Z',
							},
							{
								paymentDetailId: 'e8c5a47f-f74d-4298-9e71-3ada8cfd3f24',
								paymentId: '8c157922-bbb5-4a41-b885-45c549bd79f9',
								paymentType: 'DOCTOR_TRANSACTION_FEE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 17,
								status: 'PAID_FULL',
								meta: {
									key: 'DOCTOR_TRANSACTION_FEE',
									value: '3%',
									configId: 'DOCTOR_TRANSACTION_FEE',
									createdAt: '2022-09-08T06:08:45.373Z',
									updatedAt: '2022-09-08T06:08:45.373Z',
								},
								type: 'DISCOUNT',
								expiredAt: '2022-09-21T08:48:45.253Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-20T04:48:45.332Z',
								updatedAt: '2022-09-20T04:48:45.332Z',
							},
							{
								paymentDetailId: 'cfb5f3cc-147d-416f-b6e3-e224534f0bad',
								paymentId: '8c157922-bbb5-4a41-b885-45c549bd79f9',
								paymentType: 'DOCTOR_COMMISSION_FEE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 10,
								status: 'PAID_FULL',
								meta: {
									key: 'DOCTOR_COMMISSION_FEE',
									value: '2%',
									configId: 'DOCTOR_COMMISSION_FEE',
									createdAt: '2022-09-08T09:27:39.090Z',
									updatedAt: '2022-09-08T09:27:39.090Z',
								},
								type: 'DISCOUNT',
								expiredAt: '2022-09-21T08:48:45.253Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-20T04:48:45.332Z',
								updatedAt: '2022-09-20T04:48:45.332Z',
							},
							{
								paymentDetailId: '00f76eb4-e0f4-4ebe-ac14-474803bae945',
								paymentId: '8c157922-bbb5-4a41-b885-45c549bd79f9',
								paymentType: 'PATIENT_PWD_DISCOUNT',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 0,
								status: 'PAID_FULL',
								meta: {},
								type: 'DISCOUNT',
								expiredAt: '2022-09-21T08:48:45.253Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-20T04:48:45.332Z',
								updatedAt: '2022-09-20T04:48:45.332Z',
							},
							{
								paymentDetailId: '7a5be045-d019-441b-b049-89ee7c1a3de0',
								paymentId: '8c157922-bbb5-4a41-b885-45c549bd79f9',
								paymentType: 'BASE_PRICE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 500,
								status: 'PAID_FULL',
								meta: {},
								type: 'PAYMENT',
								expiredAt: '2022-09-21T08:48:45.253Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-20T04:48:45.332Z',
								updatedAt: '2022-09-20T04:48:45.332Z',
							},
						],
					},
				},
			},
			{
				name: 'Rizki Rahmadi',
				date: '2022-09-13',
				desc: 'Maag',
				reason: 'Maag',
				startTime: '11:30',
				endTime: '12:00',
				detailDoctor: {
					name: 'Aditya Arya Tusan',
					title:
						'Psychiatry, Cardiology, Family Medicine, Critical Care (Intensivist)',
					age: 22,
					ageString: '22 yo',
				},
				raw: {
					transactionConsultationId: 'dfce3304-64ca-46d7-85af-9d9acefe9991',
					doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
					patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
					patientInRelationId: null,
					partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
					consultationType: 'TELEMEDICINE_VIDEO_CHAT_CONSULTATION',
					scheduledStartAt: '2022-09-13T04:30:00.000Z',
					scheduledEndAt: '2022-09-13T05:00:00.000Z',
					isCanExtend: null,
					doctorJoinAt: null,
					doctorEndAt: null,
					patientJoinAt: null,
					patientEndAt: null,
					transactionStatus: 'COMPLETED',
					lastUpdatedByUser: {
						action: 'login_success',
						userId: '01GCDSTJ8FWEPN6H6MTQBR206D',
						payload: {
							phoneNumber: '6382345',
						},
						userType: 'customer',
						sessionId:
							'6abc4621efcb22619a990fe214e1de514e6bef35a9a48ba4fd838f2e75dcfd7d',
					},
					description: null,
					place: null,
					link: null,
					metaPatient: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						updatedAt: '2022-09-08T07:10:14.649Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					metaPatientInRelation: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
						updatedAt: '2022-09-08T07:10:14.649Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					metaDoctor: {
						user: {
							city: null,
							name: 'Aditya Arya Tusan',
							email: null,
							phone: '6382345',
							gender: 'male',
							status: 'active',
							userId: '01GCDSTJ8FWEPN6H6MTQBR206D',
							lastName: 'Tusan',
							nickName: 'Aditya',
							province: null,
							userType: 'customer',
							birthdate: '1999-11-30',
							createdAt: '2022-09-08T05:40:36.240Z',
							firstName: 'Aditya',
							updatedAt: '2022-09-08T08:31:31.839Z',
							middleName: 'Arya',
							profilePic: null,
						},
						title: 'SKG',
						doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
						partners: [
							{
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:01:45.057Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:15.723Z',
								pricePerSession: 500,
							},
							{
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:01:45.057Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:01:59.322Z',
								pricePerSession: 500,
							},
							{
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:53:12.891Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:20.032Z',
								pricePerSession: 500,
							},
							{
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:53:55.441Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:53:55.441Z',
								pricePerSession: null,
							},
						],
						createdAt: '2022-09-08T05:47:23.761Z',
						kycStatus: 'APPROVED',
						prcNumber: '',
						ptrNumber: '',
						schedules: [
							{
								endAt: '2022-01-01T15:00:00.970Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.841Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:08.456Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:08.456Z',
								scheduleId: 'a31ae4ad-a007-4a79-a76f-0e4b46321701',
							},
							{
								endAt: '2022-01-01T15:00:00.776Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.973Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:29.740Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:29.740Z',
								scheduleId: '54f7f208-0156-4a7b-abab-01d5a4ab58aa',
							},
							{
								endAt: '2022-01-01T15:00:00.457Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.095Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:45.000Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:45.000Z',
								scheduleId: '39074432-4205-402f-997c-aa50803c2393',
							},
							{
								endAt: '2022-01-01T15:00:00.351Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.695Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:53.389Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:53.389Z',
								scheduleId: '8a73f804-38a2-4c63-90d6-0274230d2926',
							},
							{
								endAt: '2022-01-01T15:00:00.079Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.689Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:00.869Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:00.869Z',
								scheduleId: 'f9642816-0af0-4b81-aec1-f6d84f0e004f',
							},
							{
								endAt: '2022-01-01T15:00:00.777Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.303Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:09.660Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:09.660Z',
								scheduleId: '51f82da8-9357-45fa-9bd5-e01957ca0bbf',
							},
							{
								endAt: '2022-01-01T15:00:00.488Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.787Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:18.728Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:18.728Z',
								scheduleId: '4126c29f-9586-498a-b58b-03f45d8f5949',
							},
							{
								endAt: '2022-01-01T15:00:00.048Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.494Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:27.765Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:27.765Z',
								scheduleId: '4f7a2e8e-4afd-41bf-88f2-59fbcf1a7d1b',
							},
							{
								endAt: '2022-01-01T15:00:00.339Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.285Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:56.277Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:56.277Z',
								scheduleId: '9e8417db-9105-410e-b740-5b218437c06d',
							},
							{
								endAt: '2022-01-01T15:00:00.194Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.066Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:28.709Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:28.709Z',
								scheduleId: 'b59890d5-44a0-447b-9f31-ca137e27779a',
							},
							{
								endAt: '2022-01-01T15:00:00.814Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.197Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:36.439Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:36.439Z',
								scheduleId: 'cb76674b-12ef-4dbc-98aa-99edbb61bc25',
							},
							{
								endAt: '2022-01-01T15:00:00.683Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.952Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:44.409Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:44.409Z',
								scheduleId: '4a3b9e33-2601-4228-903e-79e2c1a171c0',
							},
							{
								endAt: '2022-01-01T15:00:00.303Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.810Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:52.482Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:52.482Z',
								scheduleId: '3459a911-20d6-4179-8873-115af073b8df',
							},
							{
								endAt: '2022-01-01T15:00:00.632Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.098Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:00.731Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:00.731Z',
								scheduleId: '53cbe7a8-8254-4b86-8d49-9153906af659',
							},
							{
								endAt: '2022-01-01T15:00:00.267Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.342Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:10.158Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:10.158Z',
								scheduleId: '368e3dcb-c87e-4382-93b5-f92124af68a0',
							},
							{
								endAt: '2022-01-01T15:00:00.511Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.547Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:23.678Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:23.678Z',
								scheduleId: '506cd274-9f8e-4296-a480-7949bde125e4',
							},
							{
								endAt: '2022-01-01T15:00:00.418Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.377Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:17.220Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:17.220Z',
								scheduleId: '4f2f93d0-8d2a-4a61-9742-db0e509dec5e',
							},
							{
								endAt: '2022-01-01T15:00:00.198Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.805Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:25.263Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:25.263Z',
								scheduleId: '5415d0c1-6a9d-440e-9df9-fc7166caab12',
							},
							{
								endAt: '2022-01-01T15:00:00.972Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.496Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:32.657Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:32.657Z',
								scheduleId: '05ac2913-2d60-4e02-b4b5-340448bab3ac',
							},
							{
								endAt: '2022-01-01T15:00:00.610Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.873Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:40.316Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:40.316Z',
								scheduleId: 'b4b36f6b-1c4b-4886-8645-ddcbd41671cc',
							},
							{
								endAt: '2022-01-01T15:00:00.149Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.634Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:48.087Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:48.087Z',
								scheduleId: '188cf832-1275-49bd-885f-240e390b8e6f',
							},
							{
								endAt: '2022-01-01T15:00:00.118Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.566Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:57.046Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:57.046Z',
								scheduleId: 'c703d009-2840-4730-a9d1-e4ad1dc6a917',
							},
							{
								endAt: '2022-01-01T15:00:00.184Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.850Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:05.067Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:03:05.067Z',
								scheduleId: 'c12eb042-bd87-41b1-9c12-255681814c02',
							},
							{
								endAt: '2022-01-01T15:00:00.691Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.931Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:23.318Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:23.318Z',
								scheduleId: '2ffbdaa9-c727-4de3-ade4-302d22731359',
							},
							{
								endAt: '2022-01-01T15:00:00.512Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.375Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:33.507Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:33.507Z',
								scheduleId: '22a089d9-af75-45cc-927e-d0845099ef05',
							},
							{
								endAt: '2022-01-01T15:00:00.268Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.661Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:43.515Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:43.515Z',
								scheduleId: '9d0fb904-4e28-42af-a4d4-060ad52e704b',
							},
							{
								endAt: '2022-01-01T15:00:00.388Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.083Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:54.009Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:54.009Z',
								scheduleId: '3cbb04c9-e894-45b3-9ab6-4cd9ca68c3dd',
							},
							{
								endAt: '2022-01-01T15:00:00.833Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.384Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:05.486Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:05.486Z',
								scheduleId: 'fc14c9b9-4b89-4667-af32-6c27bd1147d4',
							},
							{
								endAt: '2022-01-01T15:00:00.505Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.098Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:13.020Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:13.020Z',
								scheduleId: '1a17c05d-ef4b-4f58-b4b0-a03ec697dac5',
							},
							{
								endAt: '2022-01-01T15:00:00.599Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.683Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:21.254Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:21.254Z',
								scheduleId: 'f096a199-fe82-40c5-861c-cab046ddbdd7',
							},
						],
						startWork: null,
						tinNumber: '',
						updatedAt: '2022-09-08T08:31:30.898Z',
						educations: [],
						experience: 2,
						orderScore: 2,
						specialists: [
							{
								icon: 'psychiatry',
								level: 1,
								createdAt: '2022-09-08T03:16:01.498Z',
								updatedAt: '2022-09-08T03:16:01.498Z',
								specialistId: '87bcd861-d18d-4e00-adb7-c913ba60f435',
								specialistName: 'Psychiatry',
								parentSpecialistId: 'dafe825a-cc64-4b00-8d7d-e7ed8adab9ca',
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									specialistId: '87bcd861-d18d-4e00-adb7-c913ba60f435',
								},
							},
							{
								icon: 'family_medicine',
								level: 2,
								createdAt: '2022-09-08T03:16:01.512Z',
								updatedAt: '2022-09-08T03:16:01.512Z',
								specialistId: '9baad78f-2237-4933-9c77-811cc886b878',
								specialistName: 'Cardiology',
								parentSpecialistId: '55df1bba-f1e7-4e57-982d-084327ef4aac',
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									specialistId: '9baad78f-2237-4933-9c77-811cc886b878',
								},
							},
							{
								icon: 'family_medicine',
								level: 1,
								createdAt: '2022-09-08T03:16:01.498Z',
								updatedAt: '2022-09-08T03:16:01.498Z',
								specialistId: '55df1bba-f1e7-4e57-982d-084327ef4aac',
								specialistName: 'Family Medicine',
								parentSpecialistId: 'dafe825a-cc64-4b00-8d7d-e7ed8adab9ca',
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									specialistId: '55df1bba-f1e7-4e57-982d-084327ef4aac',
								},
							},
							{
								icon: 'critical_care_intensivist',
								level: 2,
								createdAt: '2022-09-08T03:16:01.512Z',
								updatedAt: '2022-09-08T03:16:01.512Z',
								specialistId: '22fc908d-8b1e-4bd9-b7f0-7f4072c70d7f',
								specialistName: 'Critical Care (Intensivist)',
								parentSpecialistId: '87bcd861-d18d-4e00-adb7-c913ba60f435',
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									specialistId: '22fc908d-8b1e-4bd9-b7f0-7f4072c70d7f',
								},
							},
						],
						certificates: [],
						nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
						otherSpecialist: '',
						signatureImageUrl:
							'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
						schedulesInPartners: [
							{
								endAt: '2022-01-01T15:00:00.194Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.066Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:28.709Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:28.709Z',
								scheduleId: 'b59890d5-44a0-447b-9f31-ca137e27779a',
							},
							{
								endAt: '2022-01-01T15:00:00.388Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.083Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:54.009Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:54.009Z',
								scheduleId: '3cbb04c9-e894-45b3-9ab6-4cd9ca68c3dd',
							},
							{
								endAt: '2022-01-01T15:00:00.457Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.095Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:45.000Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:45.000Z',
								scheduleId: '39074432-4205-402f-997c-aa50803c2393',
							},
							{
								endAt: '2022-01-01T15:00:00.632Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.098Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:00.731Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:00.731Z',
								scheduleId: '53cbe7a8-8254-4b86-8d49-9153906af659',
							},
							{
								endAt: '2022-01-01T15:00:00.505Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.098Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:13.020Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:13.020Z',
								scheduleId: '1a17c05d-ef4b-4f58-b4b0-a03ec697dac5',
							},
							{
								endAt: '2022-01-01T15:00:00.814Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.197Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:36.439Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:36.439Z',
								scheduleId: 'cb76674b-12ef-4dbc-98aa-99edbb61bc25',
							},
							{
								endAt: '2022-01-01T15:00:00.339Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.285Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:56.277Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:56.277Z',
								scheduleId: '9e8417db-9105-410e-b740-5b218437c06d',
							},
							{
								endAt: '2022-01-01T15:00:00.777Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.303Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:09.660Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:09.660Z',
								scheduleId: '51f82da8-9357-45fa-9bd5-e01957ca0bbf',
							},
							{
								endAt: '2022-01-01T15:00:00.267Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.342Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:10.158Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:10.158Z',
								scheduleId: '368e3dcb-c87e-4382-93b5-f92124af68a0',
							},
							{
								endAt: '2022-01-01T15:00:00.512Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.375Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:33.507Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:33.507Z',
								scheduleId: '22a089d9-af75-45cc-927e-d0845099ef05',
							},
							{
								endAt: '2022-01-01T15:00:00.418Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.377Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:17.220Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:17.220Z',
								scheduleId: '4f2f93d0-8d2a-4a61-9742-db0e509dec5e',
							},
							{
								endAt: '2022-01-01T15:00:00.833Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.384Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:05.486Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:05.486Z',
								scheduleId: 'fc14c9b9-4b89-4667-af32-6c27bd1147d4',
							},
							{
								endAt: '2022-01-01T15:00:00.048Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.494Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:27.765Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:27.765Z',
								scheduleId: '4f7a2e8e-4afd-41bf-88f2-59fbcf1a7d1b',
							},
							{
								endAt: '2022-01-01T15:00:00.972Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.496Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:32.657Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:32.657Z',
								scheduleId: '05ac2913-2d60-4e02-b4b5-340448bab3ac',
							},
							{
								endAt: '2022-01-01T15:00:00.511Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.547Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:23.678Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:23.678Z',
								scheduleId: '506cd274-9f8e-4296-a480-7949bde125e4',
							},
							{
								endAt: '2022-01-01T15:00:00.118Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.566Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:57.046Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:57.046Z',
								scheduleId: 'c703d009-2840-4730-a9d1-e4ad1dc6a917',
							},
							{
								endAt: '2022-01-01T15:00:00.149Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.634Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:48.087Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:48.087Z',
								scheduleId: '188cf832-1275-49bd-885f-240e390b8e6f',
							},
							{
								endAt: '2022-01-01T15:00:00.268Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.661Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:43.515Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:43.515Z',
								scheduleId: '9d0fb904-4e28-42af-a4d4-060ad52e704b',
							},
							{
								endAt: '2022-01-01T15:00:00.599Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.683Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:21.254Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:21.254Z',
								scheduleId: 'f096a199-fe82-40c5-861c-cab046ddbdd7',
							},
							{
								endAt: '2022-01-01T15:00:00.079Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.689Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:00.869Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:00.869Z',
								scheduleId: 'f9642816-0af0-4b81-aec1-f6d84f0e004f',
							},
							{
								endAt: '2022-01-01T15:00:00.351Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.695Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:53.389Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:53.389Z',
								scheduleId: '8a73f804-38a2-4c63-90d6-0274230d2926',
							},
							{
								endAt: '2022-01-01T15:00:00.488Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.787Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:18.728Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:18.728Z',
								scheduleId: '4126c29f-9586-498a-b58b-03f45d8f5949',
							},
							{
								endAt: '2022-01-01T15:00:00.198Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.805Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:25.263Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:25.263Z',
								scheduleId: '5415d0c1-6a9d-440e-9df9-fc7166caab12',
							},
							{
								endAt: '2022-01-01T15:00:00.303Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.810Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:52.482Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:52.482Z',
								scheduleId: '3459a911-20d6-4179-8873-115af073b8df',
							},
							{
								endAt: '2022-01-01T15:00:00.970Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.841Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:08.456Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:08.456Z',
								scheduleId: 'a31ae4ad-a007-4a79-a76f-0e4b46321701',
							},
							{
								endAt: '2022-01-01T15:00:00.184Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.850Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:05.067Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:03:05.067Z',
								scheduleId: 'c12eb042-bd87-41b1-9c12-255681814c02',
							},
							{
								endAt: '2022-01-01T15:00:00.610Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.873Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:40.316Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:40.316Z',
								scheduleId: 'b4b36f6b-1c4b-4886-8645-ddcbd41671cc',
							},
							{
								endAt: '2022-01-01T15:00:00.691Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.931Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:23.318Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:23.318Z',
								scheduleId: '2ffbdaa9-c727-4de3-ade4-302d22731359',
							},
							{
								endAt: '2022-01-01T15:00:00.683Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.952Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:44.409Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:44.409Z',
								scheduleId: '4a3b9e33-2601-4228-903e-79e2c1a171c0',
							},
							{
								endAt: '2022-01-01T15:00:00.776Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.973Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:29.740Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:29.740Z',
								scheduleId: '54f7f208-0156-4a7b-abab-01d5a4ab58aa',
							},
						],
						specialityCertificate: null,
						availableConsultationTypes: [
							'TELEMEDICINE_VIDEO_CHAT_CONSULTATION',
							'TELEMEDICINE_CHAT_CONSULTATION',
							'CONSULT_NOW_VIDEO_CHAT_CONSULTATION',
							'CONSULT_NOW_CHAT_CONSULTATION',
							'FACE_TO_FACE_CONSULTATION',
						],
					},
					metaPartner: {
						partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
						isPublished: true,
						partnerName: 'Online Clinic',
						partnerType: 'ONLINE_CLINIC',
						isConfirmedPartner: true,
					},
					createdByUserId: '01GCDNAV6270SMVQBEAQ90VY4G',
					metaCreatedByUser: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						updatedAt: '2022-09-08T07:10:14.649Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					createdAt: '2022-09-13T04:07:35.641Z',
					updatedAt: '2022-09-13T04:48:31.544Z',
					medicalRecord: {
						medicalRecordId: 'beba7005-4ede-4e2d-980a-6cf39b979d56',
						doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
						patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patientInRelationId: null,
						reasonOfConsultation: 'Maag',
						historyPhysicalFindings: 'A',
						diagnosis: 'A',
						planForManagement: 'A',
						transactionConsultationId: 'dfce3304-64ca-46d7-85af-9d9acefe9991',
						uploadedFile: null,
						createdAt: '2022-09-13T04:07:35.658Z',
						updatedAt: '2022-09-13T04:33:27.995Z',
					},
					history: [
						{
							historyId: 'ebdf0113-9e1e-4b4d-9af3-5fda720d574d',
							transactionConsultationId: 'dfce3304-64ca-46d7-85af-9d9acefe9991',
							description: 'Update transaction status',
							newData: {
								transactionStatus: 'APPROVED',
							},
							createdByUserId: '01GCDSTJ8FWEPN6H6MTQBR206D',
							metaCreatedByUser: {
								action: 'login_success',
								userId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								payload: {
									phoneNumber: '6382345',
								},
								userType: 'customer',
								sessionId:
									'6abc4621efcb22619a990fe214e1de514e6bef35a9a48ba4fd838f2e75dcfd7d',
							},
							createdAt: '2022-09-13T04:11:16.294Z',
							updatedAt: '2022-09-13T04:11:16.294Z',
						},
						{
							historyId: '3e659efc-b900-4c96-b499-d9419df0f1b5',
							transactionConsultationId: 'dfce3304-64ca-46d7-85af-9d9acefe9991',
							description: 'Update transaction status',
							newData: {
								transactionStatus: 'WAITING_APPROVAL',
							},
							createdByUserId: '01GCDNAV6270SMVQBEAQ90VY4G',
							metaCreatedByUser: {
								city: null,
								name: 'Rizki Rahmadi',
								email: null,
								phone: '63125',
								doctor: null,
								gender: 'male',
								status: 'active',
								userId: '01GCDNAV6270SMVQBEAQ90VY4G',
								patient: {
									pwId: null,
									pwIdUrl: null,
									createdAt: '2022-09-08T04:22:06.889Z',
									patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
									updatedAt: '2022-09-08T04:22:06.889Z',
									pwIdStatus: 'WAITING_APPROVAL',
								},
								lastName: 'Rahmadi',
								nickName: 'Iki',
								province: null,
								userType: 'customer',
								birthdate: '1999-03-11',
								createdAt: '2022-09-08T04:22:06.787Z',
								firstName: 'Rizki',
								updatedAt: '2022-09-08T07:10:14.649Z',
								middleName: null,
								profilePic: null,
								isEmailVerified: false,
								isPhoneVerified: true,
							},
							createdAt: '2022-09-13T04:08:11.565Z',
							updatedAt: '2022-09-13T04:08:11.565Z',
						},
						{
							historyId: 'f6f9bb71-0e21-4aa7-a8ac-53a7e74d7762',
							transactionConsultationId: 'dfce3304-64ca-46d7-85af-9d9acefe9991',
							description: 'Update transaction status',
							newData: {
								transactionStatus: 'COMPLETED',
							},
							createdByUserId: '01GCDSTJ8FWEPN6H6MTQBR206D',
							metaCreatedByUser: {
								action: 'login_success',
								userId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								payload: {
									phoneNumber: '6382345',
								},
								userType: 'customer',
								sessionId:
									'6abc4621efcb22619a990fe214e1de514e6bef35a9a48ba4fd838f2e75dcfd7d',
							},
							createdAt: '2022-09-13T04:48:31.554Z',
							updatedAt: '2022-09-13T04:48:31.554Z',
						},
					],
					payment: {
						paymentId: '88624c25-dc16-48aa-b394-558960b85c1f',
						invoiceId: 'CT/20220913/JKB',
						transactionId: 'dfce3304-64ca-46d7-85af-9d9acefe9991',
						transactionType: 'TELEMEDICINE',
						totalNeedToPay: 500,
						paymentStatus: 'PAID_FULL',
						buyerId: '01GCDNAV6270SMVQBEAQ90VY4G',
						metaBuyerDetail: {
							city: null,
							name: 'Rizki Rahmadi',
							email: null,
							phone: '63125',
							doctor: null,
							gender: 'male',
							status: 'active',
							userId: '01GCDNAV6270SMVQBEAQ90VY4G',
							patient: {
								pwId: null,
								pwIdUrl: null,
								createdAt: '2022-09-08T04:22:06.889Z',
								patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
								updatedAt: '2022-09-08T04:22:06.889Z',
								pwIdStatus: 'WAITING_APPROVAL',
							},
							lastName: 'Rahmadi',
							nickName: 'Iki',
							province: null,
							userType: 'customer',
							birthdate: '1999-03-11',
							createdAt: '2022-09-08T04:22:06.787Z',
							firstName: 'Rizki',
							updatedAt: '2022-09-08T07:10:14.649Z',
							middleName: null,
							profilePic: null,
							isEmailVerified: false,
							isPhoneVerified: true,
						},
						meta: null,
						expiredAt: '2022-09-14T08:07:35.903Z',
						createdAt: '2022-09-13T04:07:35.904Z',
						updatedAt: '2022-09-13T04:08:11.536Z',
						paymentDetails: [
							{
								paymentDetailId: 'dd11657c-c266-45ea-ab71-894e1a0f466d',
								paymentId: '88624c25-dc16-48aa-b394-558960b85c1f',
								paymentType: 'BASE_PRICE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 500,
								status: 'PAID_FULL',
								meta: {
									consultationFee: 500,
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-14T08:07:35.903Z',
								createdAt: '2022-09-13T04:07:35.971Z',
								updatedAt: '2022-09-13T04:07:35.971Z',
							},
							{
								paymentDetailId: '3350caee-310e-43c8-81af-dcf763bf18da',
								paymentId: '88624c25-dc16-48aa-b394-558960b85c1f',
								paymentType: 'PAYMENT_GATEWAY',
								paymentMethod: 'EWALLET',
								paymentVendorType: 'PAYMONGO',
								paymentVendorId: 'GCASH',
								externalId: null,
								totalPay: 500,
								status: 'PAID_FULL',
								meta: {
									data: {
										id: 'evt_T7noEvxptGWvUMTLHt8M1ru7',
										type: 'event',
										attributes: {
											data: {
												id: 'pay_eRgsLsgFDTqUENspe199PJue',
												type: 'payment',
												attributes: {
													fee: 1250,
													taxes: [],
													amount: 50000,
													origin: 'api',
													payout: null,
													source: {
														id: 'src_HTvpPkdhyTPR9buvCUkCRPYZ',
														type: 'gcash',
													},
													status: 'paid',
													billing: null,
													paid_at: 1663042075,
													refunds: [],
													currency: 'PHP',
													disputed: false,
													livemode: false,
													metadata: {
														paymentId: '88624c25-dc16-48aa-b394-558960b85c1f',
													},
													access_url: null,
													created_at: 1663042075,
													net_amount: 48750,
													tax_amount: null,
													updated_at: 1663042075,
													description: null,
													available_at: 1663232400,
													payment_intent_id: null,
													statement_descriptor: 'PAYMONGO',
													balance_transaction_id:
														'bal_txn_4fZ9NndW3ga1bjkiwAypMwxk',
													external_reference_number: null,
												},
											},
											type: 'payment.paid',
											livemode: false,
											created_at: 1663042075,
											updated_at: 1663042075,
											previous_data: {},
										},
									},
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-14T08:07:35.903Z',
								createdAt: '2022-09-13T04:07:35.969Z',
								updatedAt: '2022-09-13T04:08:11.525Z',
							},
						],
						paymentDetailsPartners: [
							{
								paymentDetailId: '1ae4654a-d987-4714-8918-9d356e7b5e5e',
								paymentId: '88624c25-dc16-48aa-b394-558960b85c1f',
								paymentType: 'FINAL_PAYMENT',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 475,
								status: 'PAID_FULL',
								meta: {},
								type: 'PAYMENT',
								expiredAt: '2022-09-14T08:07:35.903Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-13T04:07:35.976Z',
								updatedAt: '2022-09-13T04:07:35.976Z',
							},
							{
								paymentDetailId: '5c6334b4-81c4-4dd2-bcc2-3184b6069fcd',
								paymentId: '88624c25-dc16-48aa-b394-558960b85c1f',
								paymentType: 'DOCTOR_TRANSACTION_FEE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 15,
								status: 'PAID_FULL',
								meta: {
									key: 'DOCTOR_TRANSACTION_FEE',
									value: '3%',
									configId: 'DOCTOR_TRANSACTION_FEE',
									createdAt: '2022-09-08T06:08:45.373Z',
									updatedAt: '2022-09-08T06:08:45.373Z',
								},
								type: 'DISCOUNT',
								expiredAt: '2022-09-14T08:07:35.903Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-13T04:07:35.976Z',
								updatedAt: '2022-09-13T04:07:35.976Z',
							},
							{
								paymentDetailId: '1cf37c2f-debe-4edc-988c-51777f5c0c3a',
								paymentId: '88624c25-dc16-48aa-b394-558960b85c1f',
								paymentType: 'DOCTOR_COMMISSION_FEE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 10,
								status: 'PAID_FULL',
								meta: {
									key: 'DOCTOR_COMMISSION_FEE',
									value: '2%',
									configId: 'DOCTOR_COMMISSION_FEE',
									createdAt: '2022-09-08T09:27:39.090Z',
									updatedAt: '2022-09-08T09:27:39.090Z',
								},
								type: 'DISCOUNT',
								expiredAt: '2022-09-14T08:07:35.903Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-13T04:07:35.976Z',
								updatedAt: '2022-09-13T04:07:35.976Z',
							},
							{
								paymentDetailId: '652aeb6d-03a8-4b7a-aeae-98ae93410f40',
								paymentId: '88624c25-dc16-48aa-b394-558960b85c1f',
								paymentType: 'PATIENT_PWD_DISCOUNT',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 0,
								status: 'PAID_FULL',
								meta: {},
								type: 'DISCOUNT',
								expiredAt: '2022-09-14T08:07:35.903Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-13T04:07:35.976Z',
								updatedAt: '2022-09-13T04:07:35.976Z',
							},
							{
								paymentDetailId: '7dd469a8-bbc6-412c-9f9b-fcfc20feab76',
								paymentId: '88624c25-dc16-48aa-b394-558960b85c1f',
								paymentType: 'BASE_PRICE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 500,
								status: 'PAID_FULL',
								meta: {},
								type: 'PAYMENT',
								expiredAt: '2022-09-14T08:07:35.903Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-13T04:07:35.976Z',
								updatedAt: '2022-09-13T04:07:35.976Z',
							},
						],
					},
				},
			},
			{
				name: 'Rizki Rahmadi',
				date: '2022-09-12',
				desc: 'Sakit gigi',
				reason: 'Sakit gigi',
				startTime: '13:30',
				endTime: '14:00',
				detailDoctor: {
					name: 'Aditya Arya Tusan',
					title:
						'Psychiatry, Cardiology, Family Medicine, Critical Care (Intensivist)',
					age: 22,
					ageString: '22 yo',
				},
				raw: {
					transactionConsultationId: 'b251e0a2-2c96-44e7-9e75-18cd0d7bb51c',
					doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
					patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
					patientInRelationId: null,
					partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
					consultationType: 'TELEMEDICINE_VIDEO_CHAT_CONSULTATION',
					scheduledStartAt: '2022-09-12T06:30:00.000Z',
					scheduledEndAt: '2022-09-12T07:00:00.000Z',
					isCanExtend: null,
					doctorJoinAt: null,
					doctorEndAt: null,
					patientJoinAt: null,
					patientEndAt: null,
					transactionStatus: 'COMPLETED',
					lastUpdatedByUser: {
						action: 'login_success',
						userId: '01GCDSTJ8FWEPN6H6MTQBR206D',
						payload: {
							phoneNumber: '6382345',
						},
						userType: 'customer',
						sessionId:
							'80f76b37815c63d2215c9376b821981f7eeaa2b9c869fe1aab4da8694a25f922',
					},
					description: null,
					place: null,
					link: null,
					metaPatient: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						updatedAt: '2022-09-08T07:10:14.649Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					metaPatientInRelation: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
						updatedAt: '2022-09-08T07:10:14.649Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					metaDoctor: {
						user: {
							city: null,
							name: 'Aditya Arya Tusan',
							email: null,
							phone: '6382345',
							gender: 'male',
							status: 'active',
							userId: '01GCDSTJ8FWEPN6H6MTQBR206D',
							lastName: 'Tusan',
							nickName: 'Aditya',
							province: null,
							userType: 'customer',
							birthdate: '1999-11-30',
							createdAt: '2022-09-08T05:40:36.240Z',
							firstName: 'Aditya',
							updatedAt: '2022-09-08T08:31:31.839Z',
							middleName: 'Arya',
							profilePic: null,
						},
						title: 'SKG',
						doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
						partners: [
							{
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:01:45.057Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:15.723Z',
								pricePerSession: 500,
							},
							{
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:01:45.057Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:01:59.322Z',
								pricePerSession: 500,
							},
							{
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:53:12.891Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:20.032Z',
								pricePerSession: 500,
							},
							{
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:53:55.441Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:53:55.441Z',
								pricePerSession: null,
							},
						],
						createdAt: '2022-09-08T05:47:23.761Z',
						kycStatus: 'APPROVED',
						prcNumber: '',
						ptrNumber: '',
						schedules: [
							{
								endAt: '2022-01-01T15:00:00.970Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.841Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:08.456Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:08.456Z',
								scheduleId: 'a31ae4ad-a007-4a79-a76f-0e4b46321701',
							},
							{
								endAt: '2022-01-01T15:00:00.776Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.973Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:29.740Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:29.740Z',
								scheduleId: '54f7f208-0156-4a7b-abab-01d5a4ab58aa',
							},
							{
								endAt: '2022-01-01T15:00:00.457Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.095Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:45.000Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:45.000Z',
								scheduleId: '39074432-4205-402f-997c-aa50803c2393',
							},
							{
								endAt: '2022-01-01T15:00:00.351Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.695Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:53.389Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:53.389Z',
								scheduleId: '8a73f804-38a2-4c63-90d6-0274230d2926',
							},
							{
								endAt: '2022-01-01T15:00:00.079Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.689Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:00.869Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:00.869Z',
								scheduleId: 'f9642816-0af0-4b81-aec1-f6d84f0e004f',
							},
							{
								endAt: '2022-01-01T15:00:00.777Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.303Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:09.660Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:09.660Z',
								scheduleId: '51f82da8-9357-45fa-9bd5-e01957ca0bbf',
							},
							{
								endAt: '2022-01-01T15:00:00.488Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.787Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:18.728Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:18.728Z',
								scheduleId: '4126c29f-9586-498a-b58b-03f45d8f5949',
							},
							{
								endAt: '2022-01-01T15:00:00.048Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.494Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:27.765Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:27.765Z',
								scheduleId: '4f7a2e8e-4afd-41bf-88f2-59fbcf1a7d1b',
							},
							{
								endAt: '2022-01-01T15:00:00.339Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.285Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:56.277Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:56.277Z',
								scheduleId: '9e8417db-9105-410e-b740-5b218437c06d',
							},
							{
								endAt: '2022-01-01T15:00:00.194Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.066Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:28.709Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:28.709Z',
								scheduleId: 'b59890d5-44a0-447b-9f31-ca137e27779a',
							},
							{
								endAt: '2022-01-01T15:00:00.814Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.197Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:36.439Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:36.439Z',
								scheduleId: 'cb76674b-12ef-4dbc-98aa-99edbb61bc25',
							},
							{
								endAt: '2022-01-01T15:00:00.683Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.952Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:44.409Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:44.409Z',
								scheduleId: '4a3b9e33-2601-4228-903e-79e2c1a171c0',
							},
							{
								endAt: '2022-01-01T15:00:00.303Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.810Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:52.482Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:52.482Z',
								scheduleId: '3459a911-20d6-4179-8873-115af073b8df',
							},
							{
								endAt: '2022-01-01T15:00:00.632Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.098Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:00.731Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:00.731Z',
								scheduleId: '53cbe7a8-8254-4b86-8d49-9153906af659',
							},
							{
								endAt: '2022-01-01T15:00:00.267Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.342Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:10.158Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:10.158Z',
								scheduleId: '368e3dcb-c87e-4382-93b5-f92124af68a0',
							},
							{
								endAt: '2022-01-01T15:00:00.511Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.547Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:23.678Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:23.678Z',
								scheduleId: '506cd274-9f8e-4296-a480-7949bde125e4',
							},
							{
								endAt: '2022-01-01T15:00:00.418Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.377Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:17.220Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:17.220Z',
								scheduleId: '4f2f93d0-8d2a-4a61-9742-db0e509dec5e',
							},
							{
								endAt: '2022-01-01T15:00:00.198Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.805Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:25.263Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:25.263Z',
								scheduleId: '5415d0c1-6a9d-440e-9df9-fc7166caab12',
							},
							{
								endAt: '2022-01-01T15:00:00.972Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.496Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:32.657Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:32.657Z',
								scheduleId: '05ac2913-2d60-4e02-b4b5-340448bab3ac',
							},
							{
								endAt: '2022-01-01T15:00:00.610Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.873Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:40.316Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:40.316Z',
								scheduleId: 'b4b36f6b-1c4b-4886-8645-ddcbd41671cc',
							},
							{
								endAt: '2022-01-01T15:00:00.149Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.634Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:48.087Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:48.087Z',
								scheduleId: '188cf832-1275-49bd-885f-240e390b8e6f',
							},
							{
								endAt: '2022-01-01T15:00:00.118Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.566Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:57.046Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:57.046Z',
								scheduleId: 'c703d009-2840-4730-a9d1-e4ad1dc6a917',
							},
							{
								endAt: '2022-01-01T15:00:00.184Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.850Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:05.067Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:03:05.067Z',
								scheduleId: 'c12eb042-bd87-41b1-9c12-255681814c02',
							},
							{
								endAt: '2022-01-01T15:00:00.691Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.931Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:23.318Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:23.318Z',
								scheduleId: '2ffbdaa9-c727-4de3-ade4-302d22731359',
							},
							{
								endAt: '2022-01-01T15:00:00.512Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.375Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:33.507Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:33.507Z',
								scheduleId: '22a089d9-af75-45cc-927e-d0845099ef05',
							},
							{
								endAt: '2022-01-01T15:00:00.268Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.661Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:43.515Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:43.515Z',
								scheduleId: '9d0fb904-4e28-42af-a4d4-060ad52e704b',
							},
							{
								endAt: '2022-01-01T15:00:00.388Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.083Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:54.009Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:54.009Z',
								scheduleId: '3cbb04c9-e894-45b3-9ab6-4cd9ca68c3dd',
							},
							{
								endAt: '2022-01-01T15:00:00.833Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.384Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:05.486Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:05.486Z',
								scheduleId: 'fc14c9b9-4b89-4667-af32-6c27bd1147d4',
							},
							{
								endAt: '2022-01-01T15:00:00.505Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.098Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:13.020Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:13.020Z',
								scheduleId: '1a17c05d-ef4b-4f58-b4b0-a03ec697dac5',
							},
							{
								endAt: '2022-01-01T15:00:00.599Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.683Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:21.254Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:21.254Z',
								scheduleId: 'f096a199-fe82-40c5-861c-cab046ddbdd7',
							},
						],
						startWork: null,
						tinNumber: '',
						updatedAt: '2022-09-08T08:31:30.898Z',
						educations: [],
						experience: 2,
						orderScore: 2,
						specialists: [
							{
								icon: 'psychiatry',
								level: 1,
								createdAt: '2022-09-08T03:16:01.498Z',
								updatedAt: '2022-09-08T03:16:01.498Z',
								specialistId: '87bcd861-d18d-4e00-adb7-c913ba60f435',
								specialistName: 'Psychiatry',
								parentSpecialistId: 'dafe825a-cc64-4b00-8d7d-e7ed8adab9ca',
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									specialistId: '87bcd861-d18d-4e00-adb7-c913ba60f435',
								},
							},
							{
								icon: 'family_medicine',
								level: 2,
								createdAt: '2022-09-08T03:16:01.512Z',
								updatedAt: '2022-09-08T03:16:01.512Z',
								specialistId: '9baad78f-2237-4933-9c77-811cc886b878',
								specialistName: 'Cardiology',
								parentSpecialistId: '55df1bba-f1e7-4e57-982d-084327ef4aac',
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									specialistId: '9baad78f-2237-4933-9c77-811cc886b878',
								},
							},
							{
								icon: 'family_medicine',
								level: 1,
								createdAt: '2022-09-08T03:16:01.498Z',
								updatedAt: '2022-09-08T03:16:01.498Z',
								specialistId: '55df1bba-f1e7-4e57-982d-084327ef4aac',
								specialistName: 'Family Medicine',
								parentSpecialistId: 'dafe825a-cc64-4b00-8d7d-e7ed8adab9ca',
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									specialistId: '55df1bba-f1e7-4e57-982d-084327ef4aac',
								},
							},
							{
								icon: 'critical_care_intensivist',
								level: 2,
								createdAt: '2022-09-08T03:16:01.512Z',
								updatedAt: '2022-09-08T03:16:01.512Z',
								specialistId: '22fc908d-8b1e-4bd9-b7f0-7f4072c70d7f',
								specialistName: 'Critical Care (Intensivist)',
								parentSpecialistId: '87bcd861-d18d-4e00-adb7-c913ba60f435',
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									specialistId: '22fc908d-8b1e-4bd9-b7f0-7f4072c70d7f',
								},
							},
						],
						certificates: [],
						nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
						otherSpecialist: '',
						signatureImageUrl:
							'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
						schedulesInPartners: [
							{
								endAt: '2022-01-01T15:00:00.194Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.066Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:28.709Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:28.709Z',
								scheduleId: 'b59890d5-44a0-447b-9f31-ca137e27779a',
							},
							{
								endAt: '2022-01-01T15:00:00.388Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.083Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:54.009Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:54.009Z',
								scheduleId: '3cbb04c9-e894-45b3-9ab6-4cd9ca68c3dd',
							},
							{
								endAt: '2022-01-01T15:00:00.457Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.095Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:45.000Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:45.000Z',
								scheduleId: '39074432-4205-402f-997c-aa50803c2393',
							},
							{
								endAt: '2022-01-01T15:00:00.632Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.098Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:00.731Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:00.731Z',
								scheduleId: '53cbe7a8-8254-4b86-8d49-9153906af659',
							},
							{
								endAt: '2022-01-01T15:00:00.505Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.098Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:13.020Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:13.020Z',
								scheduleId: '1a17c05d-ef4b-4f58-b4b0-a03ec697dac5',
							},
							{
								endAt: '2022-01-01T15:00:00.814Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.197Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:36.439Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:36.439Z',
								scheduleId: 'cb76674b-12ef-4dbc-98aa-99edbb61bc25',
							},
							{
								endAt: '2022-01-01T15:00:00.339Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.285Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:56.277Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:56.277Z',
								scheduleId: '9e8417db-9105-410e-b740-5b218437c06d',
							},
							{
								endAt: '2022-01-01T15:00:00.777Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.303Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:09.660Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:09.660Z',
								scheduleId: '51f82da8-9357-45fa-9bd5-e01957ca0bbf',
							},
							{
								endAt: '2022-01-01T15:00:00.267Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.342Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:10.158Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:10.158Z',
								scheduleId: '368e3dcb-c87e-4382-93b5-f92124af68a0',
							},
							{
								endAt: '2022-01-01T15:00:00.512Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.375Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:33.507Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:33.507Z',
								scheduleId: '22a089d9-af75-45cc-927e-d0845099ef05',
							},
							{
								endAt: '2022-01-01T15:00:00.418Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.377Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:17.220Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:17.220Z',
								scheduleId: '4f2f93d0-8d2a-4a61-9742-db0e509dec5e',
							},
							{
								endAt: '2022-01-01T15:00:00.833Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.384Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:05.486Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:05.486Z',
								scheduleId: 'fc14c9b9-4b89-4667-af32-6c27bd1147d4',
							},
							{
								endAt: '2022-01-01T15:00:00.048Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.494Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:27.765Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:27.765Z',
								scheduleId: '4f7a2e8e-4afd-41bf-88f2-59fbcf1a7d1b',
							},
							{
								endAt: '2022-01-01T15:00:00.972Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.496Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:32.657Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:32.657Z',
								scheduleId: '05ac2913-2d60-4e02-b4b5-340448bab3ac',
							},
							{
								endAt: '2022-01-01T15:00:00.511Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.547Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:23.678Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:23.678Z',
								scheduleId: '506cd274-9f8e-4296-a480-7949bde125e4',
							},
							{
								endAt: '2022-01-01T15:00:00.118Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.566Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:57.046Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:57.046Z',
								scheduleId: 'c703d009-2840-4730-a9d1-e4ad1dc6a917',
							},
							{
								endAt: '2022-01-01T15:00:00.149Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.634Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:48.087Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:48.087Z',
								scheduleId: '188cf832-1275-49bd-885f-240e390b8e6f',
							},
							{
								endAt: '2022-01-01T15:00:00.268Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.661Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:43.515Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:43.515Z',
								scheduleId: '9d0fb904-4e28-42af-a4d4-060ad52e704b',
							},
							{
								endAt: '2022-01-01T15:00:00.599Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.683Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:21.254Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:21.254Z',
								scheduleId: 'f096a199-fe82-40c5-861c-cab046ddbdd7',
							},
							{
								endAt: '2022-01-01T15:00:00.079Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.689Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:00.869Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:00.869Z',
								scheduleId: 'f9642816-0af0-4b81-aec1-f6d84f0e004f',
							},
							{
								endAt: '2022-01-01T15:00:00.351Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.695Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:53.389Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:53.389Z',
								scheduleId: '8a73f804-38a2-4c63-90d6-0274230d2926',
							},
							{
								endAt: '2022-01-01T15:00:00.488Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.787Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:18.728Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:18.728Z',
								scheduleId: '4126c29f-9586-498a-b58b-03f45d8f5949',
							},
							{
								endAt: '2022-01-01T15:00:00.198Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.805Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:25.263Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:25.263Z',
								scheduleId: '5415d0c1-6a9d-440e-9df9-fc7166caab12',
							},
							{
								endAt: '2022-01-01T15:00:00.303Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.810Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:52.482Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:52.482Z',
								scheduleId: '3459a911-20d6-4179-8873-115af073b8df',
							},
							{
								endAt: '2022-01-01T15:00:00.970Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.841Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:08.456Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:08.456Z',
								scheduleId: 'a31ae4ad-a007-4a79-a76f-0e4b46321701',
							},
							{
								endAt: '2022-01-01T15:00:00.184Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.850Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:05.067Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:03:05.067Z',
								scheduleId: 'c12eb042-bd87-41b1-9c12-255681814c02',
							},
							{
								endAt: '2022-01-01T15:00:00.610Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.873Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:40.316Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:40.316Z',
								scheduleId: 'b4b36f6b-1c4b-4886-8645-ddcbd41671cc',
							},
							{
								endAt: '2022-01-01T15:00:00.691Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.931Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:23.318Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:23.318Z',
								scheduleId: '2ffbdaa9-c727-4de3-ade4-302d22731359',
							},
							{
								endAt: '2022-01-01T15:00:00.683Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.952Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:44.409Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:44.409Z',
								scheduleId: '4a3b9e33-2601-4228-903e-79e2c1a171c0',
							},
							{
								endAt: '2022-01-01T15:00:00.776Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.973Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:29.740Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:29.740Z',
								scheduleId: '54f7f208-0156-4a7b-abab-01d5a4ab58aa',
							},
						],
						specialityCertificate: null,
						availableConsultationTypes: [
							'TELEMEDICINE_VIDEO_CHAT_CONSULTATION',
							'TELEMEDICINE_CHAT_CONSULTATION',
							'CONSULT_NOW_VIDEO_CHAT_CONSULTATION',
							'CONSULT_NOW_CHAT_CONSULTATION',
							'FACE_TO_FACE_CONSULTATION',
						],
					},
					metaPartner: {
						partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
						isPublished: true,
						partnerName: 'Online Clinic',
						partnerType: 'ONLINE_CLINIC',
						isConfirmedPartner: true,
					},
					createdByUserId: '01GCDNAV6270SMVQBEAQ90VY4G',
					metaCreatedByUser: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						updatedAt: '2022-09-08T07:10:14.649Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					createdAt: '2022-09-12T06:07:58.672Z',
					updatedAt: '2022-09-12T06:33:27.554Z',
					medicalRecord: {
						medicalRecordId: '788f2073-5b18-4188-961d-b929cd6046a9',
						doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
						patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patientInRelationId: null,
						reasonOfConsultation: 'Sakit gigi',
						historyPhysicalFindings: 'A',
						diagnosis: 'A',
						planForManagement: 'A',
						transactionConsultationId: 'b251e0a2-2c96-44e7-9e75-18cd0d7bb51c',
						uploadedFile: null,
						createdAt: '2022-09-12T06:07:58.693Z',
						updatedAt: '2022-09-12T06:27:48.817Z',
					},
					history: [
						{
							historyId: '21c5af57-efa7-4e5c-9e1f-31dde6e8810a',
							transactionConsultationId: 'b251e0a2-2c96-44e7-9e75-18cd0d7bb51c',
							description: 'Update transaction status',
							newData: {
								transactionStatus: 'WAITING_APPROVAL',
							},
							createdByUserId: '01GCDNAV6270SMVQBEAQ90VY4G',
							metaCreatedByUser: {
								city: null,
								name: 'Rizki Rahmadi',
								email: null,
								phone: '63125',
								doctor: null,
								gender: 'male',
								status: 'active',
								userId: '01GCDNAV6270SMVQBEAQ90VY4G',
								patient: {
									pwId: null,
									pwIdUrl: null,
									createdAt: '2022-09-08T04:22:06.889Z',
									patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
									updatedAt: '2022-09-08T04:22:06.889Z',
									pwIdStatus: 'WAITING_APPROVAL',
								},
								lastName: 'Rahmadi',
								nickName: 'Iki',
								province: null,
								userType: 'customer',
								birthdate: '1999-03-11',
								createdAt: '2022-09-08T04:22:06.787Z',
								firstName: 'Rizki',
								updatedAt: '2022-09-08T07:10:14.649Z',
								middleName: null,
								profilePic: null,
								isEmailVerified: false,
								isPhoneVerified: true,
							},
							createdAt: '2022-09-12T06:08:49.501Z',
							updatedAt: '2022-09-12T06:08:49.501Z',
						},
						{
							historyId: 'ee49f9f1-8292-491b-b65c-b586549ce451',
							transactionConsultationId: 'b251e0a2-2c96-44e7-9e75-18cd0d7bb51c',
							description: 'Update transaction status',
							newData: {
								transactionStatus: 'COMPLETED',
							},
							createdByUserId: '01GCDSTJ8FWEPN6H6MTQBR206D',
							metaCreatedByUser: {
								action: 'login_success',
								userId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								payload: {
									phoneNumber: '6382345',
								},
								userType: 'customer',
								sessionId:
									'80f76b37815c63d2215c9376b821981f7eeaa2b9c869fe1aab4da8694a25f922',
							},
							createdAt: '2022-09-12T06:33:27.568Z',
							updatedAt: '2022-09-12T06:33:27.568Z',
						},
						{
							historyId: '26805a54-c33d-42f3-87ab-43a0569a7fea',
							transactionConsultationId: 'b251e0a2-2c96-44e7-9e75-18cd0d7bb51c',
							description: 'Update transaction status',
							newData: {
								transactionStatus: 'APPROVED',
							},
							createdByUserId: '01GCDSTJ8FWEPN6H6MTQBR206D',
							metaCreatedByUser: {
								action: 'login_success',
								userId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								payload: {
									phoneNumber: '6382345',
								},
								userType: 'customer',
								sessionId:
									'80f76b37815c63d2215c9376b821981f7eeaa2b9c869fe1aab4da8694a25f922',
							},
							createdAt: '2022-09-12T06:10:36.563Z',
							updatedAt: '2022-09-12T06:10:36.563Z',
						},
					],
					payment: {
						paymentId: '83d7ad9c-0a16-4565-b90f-e3b4d4afc504',
						invoiceId: 'CT/20220912/TJ6',
						transactionId: 'b251e0a2-2c96-44e7-9e75-18cd0d7bb51c',
						transactionType: 'TELEMEDICINE',
						totalNeedToPay: 575,
						paymentStatus: 'PAID_FULL',
						buyerId: '01GCDNAV6270SMVQBEAQ90VY4G',
						metaBuyerDetail: {
							city: null,
							name: 'Rizki Rahmadi',
							email: null,
							phone: '63125',
							doctor: null,
							gender: 'male',
							status: 'active',
							userId: '01GCDNAV6270SMVQBEAQ90VY4G',
							patient: {
								pwId: null,
								pwIdUrl: null,
								createdAt: '2022-09-08T04:22:06.889Z',
								patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
								updatedAt: '2022-09-08T04:22:06.889Z',
								pwIdStatus: 'WAITING_APPROVAL',
							},
							lastName: 'Rahmadi',
							nickName: 'Iki',
							province: null,
							userType: 'customer',
							birthdate: '1999-03-11',
							createdAt: '2022-09-08T04:22:06.787Z',
							firstName: 'Rizki',
							updatedAt: '2022-09-08T07:10:14.649Z',
							middleName: null,
							profilePic: null,
							isEmailVerified: false,
							isPhoneVerified: true,
						},
						meta: null,
						expiredAt: '2022-09-13T10:07:58.952Z',
						createdAt: '2022-09-12T06:07:58.953Z',
						updatedAt: '2022-09-12T06:08:49.467Z',
						paymentDetails: [
							{
								paymentDetailId: '5537b65a-a12f-4fc3-9cb1-a395a7b27142',
								paymentId: '83d7ad9c-0a16-4565-b90f-e3b4d4afc504',
								paymentType: 'BASE_PRICE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 500,
								status: 'PAID_FULL',
								meta: {
									consultationFee: 500,
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-13T10:07:58.952Z',
								createdAt: '2022-09-12T06:07:59.017Z',
								updatedAt: '2022-09-12T06:07:59.017Z',
							},
							{
								paymentDetailId: '50fa848c-d0b0-4f67-b0ab-397bd093306d',
								paymentId: '83d7ad9c-0a16-4565-b90f-e3b4d4afc504',
								paymentType: 'PATIENT_TRANSACTION_FEE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 75,
								status: 'PAID_FULL',
								meta: {
									key: 'PATIENT_TRANSACTION_FEE',
									value: '5%+50',
									configId: 'PATIENT_TRANSACTION_FEE',
									createdAt: '2022-09-08T06:08:45.373Z',
									updatedAt: '2022-09-08T06:08:45.373Z',
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-13T10:07:58.952Z',
								createdAt: '2022-09-12T06:07:59.019Z',
								updatedAt: '2022-09-12T06:07:59.019Z',
							},
							{
								paymentDetailId: '809ccda9-1b0a-4c1d-b90e-29b0ac2069c1',
								paymentId: '83d7ad9c-0a16-4565-b90f-e3b4d4afc504',
								paymentType: 'PAYMENT_GATEWAY',
								paymentMethod: 'EWALLET',
								paymentVendorType: 'PAYMONGO',
								paymentVendorId: 'GCASH',
								externalId: null,
								totalPay: 575,
								status: 'PAID_FULL',
								meta: {
									data: {
										id: 'evt_ceX4k2HsrpTEXjDesxJt3fNk',
										type: 'event',
										attributes: {
											data: {
												id: 'pay_z7DYkpF5TNr8oHPTKQspf2fD',
												type: 'payment',
												attributes: {
													fee: 1438,
													taxes: [],
													amount: 57500,
													origin: 'api',
													payout: null,
													source: {
														id: 'src_ZKYqMv3MB9KFBxXs4TmpGk2r',
														type: 'gcash',
													},
													status: 'paid',
													billing: null,
													paid_at: 1662962909,
													refunds: [],
													currency: 'PHP',
													disputed: false,
													livemode: false,
													metadata: {
														paymentId: '83d7ad9c-0a16-4565-b90f-e3b4d4afc504',
													},
													access_url: null,
													created_at: 1662962909,
													net_amount: 56062,
													tax_amount: null,
													updated_at: 1662962909,
													description: null,
													available_at: 1663146000,
													payment_intent_id: null,
													statement_descriptor: 'PAYMONGO',
													balance_transaction_id:
														'bal_txn_ieGHvbsaRPpcxpTmJr1iRYRg',
													external_reference_number: null,
												},
											},
											type: 'payment.paid',
											livemode: false,
											created_at: 1662962909,
											updated_at: 1662962909,
											previous_data: {},
										},
									},
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-13T10:07:58.952Z',
								createdAt: '2022-09-12T06:07:59.011Z',
								updatedAt: '2022-09-12T06:08:49.446Z',
							},
						],
						paymentDetailsPartners: [
							{
								paymentDetailId: 'f25f1a08-e1ee-48da-bdac-c823ff663b5f',
								paymentId: '83d7ad9c-0a16-4565-b90f-e3b4d4afc504',
								paymentType: 'FINAL_PAYMENT',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 473,
								status: 'PAID_FULL',
								meta: {},
								type: 'PAYMENT',
								expiredAt: '2022-09-13T10:07:58.952Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-12T06:07:59.024Z',
								updatedAt: '2022-09-12T06:07:59.024Z',
							},
							{
								paymentDetailId: '7c7f8d53-ad45-4b68-a323-d7e6cd6eeaa8',
								paymentId: '83d7ad9c-0a16-4565-b90f-e3b4d4afc504',
								paymentType: 'DOCTOR_TRANSACTION_FEE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 17,
								status: 'PAID_FULL',
								meta: {
									key: 'DOCTOR_TRANSACTION_FEE',
									value: '3%',
									configId: 'DOCTOR_TRANSACTION_FEE',
									createdAt: '2022-09-08T06:08:45.373Z',
									updatedAt: '2022-09-08T06:08:45.373Z',
								},
								type: 'DISCOUNT',
								expiredAt: '2022-09-13T10:07:58.952Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-12T06:07:59.024Z',
								updatedAt: '2022-09-12T06:07:59.024Z',
							},
							{
								paymentDetailId: 'd58dc31d-cb0e-4a1a-9d53-5f4dd7cafea6',
								paymentId: '83d7ad9c-0a16-4565-b90f-e3b4d4afc504',
								paymentType: 'DOCTOR_COMMISSION_FEE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 10,
								status: 'PAID_FULL',
								meta: {
									key: 'DOCTOR_COMMISSION_FEE',
									value: '2%',
									configId: 'DOCTOR_COMMISSION_FEE',
									createdAt: '2022-09-08T09:27:39.090Z',
									updatedAt: '2022-09-08T09:27:39.090Z',
								},
								type: 'DISCOUNT',
								expiredAt: '2022-09-13T10:07:58.952Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-12T06:07:59.024Z',
								updatedAt: '2022-09-12T06:07:59.024Z',
							},
							{
								paymentDetailId: 'f0a1912e-f109-4172-aebb-08b119ff961c',
								paymentId: '83d7ad9c-0a16-4565-b90f-e3b4d4afc504',
								paymentType: 'PATIENT_PWD_DISCOUNT',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 0,
								status: 'PAID_FULL',
								meta: {},
								type: 'DISCOUNT',
								expiredAt: '2022-09-13T10:07:58.952Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-12T06:07:59.024Z',
								updatedAt: '2022-09-12T06:07:59.024Z',
							},
							{
								paymentDetailId: 'e0cc4d32-a0b6-44d5-b23e-2f60583fdf2c',
								paymentId: '83d7ad9c-0a16-4565-b90f-e3b4d4afc504',
								paymentType: 'BASE_PRICE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 500,
								status: 'PAID_FULL',
								meta: {},
								type: 'PAYMENT',
								expiredAt: '2022-09-13T10:07:58.952Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-12T06:07:59.024Z',
								updatedAt: '2022-09-12T06:07:59.024Z',
							},
						],
					},
				},
			},
			{
				name: 'Rizki Rahmadi',
				date: '2022-09-09',
				desc: 'denggong',
				reason: 'denggong',
				startTime: '09:00',
				endTime: '09:30',
				detailDoctor: {
					name: 'Joe Keery',
					title:
						'Family Medicine, Nephrology, Ear, Nose & Throat (ENT), Orthopedic Surgery, General Surgery, Oncology, Cardiology, Endocrinology & Metabolism',
					ageString: '',
				},
				raw: {
					transactionConsultationId: '785b2e1d-f5bc-4563-952f-24c6674d8364',
					doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
					patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
					patientInRelationId: null,
					partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
					consultationType: 'TELEMEDICINE_VIDEO_CHAT_CONSULTATION',
					scheduledStartAt: '2022-09-09T02:00:00.000Z',
					scheduledEndAt: '2022-09-09T02:30:00.000Z',
					isCanExtend: null,
					doctorJoinAt: null,
					doctorEndAt: null,
					patientJoinAt: null,
					patientEndAt: null,
					transactionStatus: 'COMPLETED',
					lastUpdatedByUser: {
						action: 'login_success',
						userId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
						payload: {
							phoneNumber: '6381234',
						},
						userType: 'customer',
						sessionId:
							'0a0d4dbf636902f8881eb34138a07b8a99060a89696bcb89638a68b8a40d2080',
					},
					description: null,
					place: null,
					link: null,
					metaPatient: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						updatedAt: '2022-09-08T07:10:14.649Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					metaPatientInRelation: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
						updatedAt: '2022-09-08T07:10:14.649Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					metaDoctor: {
						user: {
							city: null,
							name: 'Joe Keery',
							email: null,
							phone: '6381234',
							gender: 'male',
							status: 'active',
							userId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
							lastName: 'Keery',
							nickName: 'Joe',
							province: null,
							userType: 'customer',
							birthdate: null,
							createdAt: '2022-09-08T05:53:00.754Z',
							firstName: 'Joe',
							updatedAt: '2022-09-08T08:47:58.908Z',
							middleName: '',
							profilePic: null,
						},
						title: 'Sp. THT-KL.',
						doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
						partners: [
							{
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:47:33.727Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:49:38.710Z',
								pricePerSession: 500,
							},
							{
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:48:06.192Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:48:06.192Z',
								pricePerSession: null,
							},
							{
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:48:02.199Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T06:50:50.821Z',
								pricePerSession: 500,
							},
						],
						createdAt: '2022-09-08T06:03:26.570Z',
						kycStatus: 'APPROVED',
						prcNumber: '',
						ptrNumber: '',
						schedules: [
							{
								endAt: '2022-01-01T15:00:00.658Z',
								startAt: '2022-01-01T16:00:00.531Z',
								weekday: 0,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:48:19.974Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:48:19.974Z',
								scheduleId: '2ffddb19-c73d-4991-9e21-6292c8a6d3a7',
							},
							{
								endAt: '2022-01-01T15:00:00.877Z',
								startAt: '2022-01-01T16:00:00.717Z',
								weekday: 1,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:48:31.587Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:48:31.587Z',
								scheduleId: 'b26b4633-1391-48f5-8d51-3450101c5cc7',
							},
							{
								endAt: '2022-01-01T15:00:00.765Z',
								startAt: '2022-01-01T16:00:00.899Z',
								weekday: 2,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:48:43.096Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:48:43.096Z',
								scheduleId: '93fe7e95-5bf4-40b9-a1df-a6f11724a838',
							},
							{
								endAt: '2022-01-01T15:00:00.501Z',
								startAt: '2022-01-01T16:00:00.704Z',
								weekday: 3,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:48:54.680Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:48:54.680Z',
								scheduleId: '512acb0c-78c1-465e-b888-dadb31768bde',
							},
							{
								endAt: '2022-01-01T15:00:00.642Z',
								startAt: '2022-01-01T16:00:00.994Z',
								weekday: 4,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:49:04.137Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:49:04.137Z',
								scheduleId: 'effc8673-de6d-4b36-b7c3-8f76a40598dd',
							},
							{
								endAt: '2022-01-01T15:00:00.621Z',
								startAt: '2022-01-01T16:00:00.890Z',
								weekday: 5,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:49:12.306Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:49:12.306Z',
								scheduleId: '0fff586c-299c-4b08-a015-fa8917f85445',
							},
							{
								endAt: '2022-01-01T15:00:00.552Z',
								startAt: '2022-01-01T16:00:00.594Z',
								weekday: 6,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:49:21.324Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:49:21.324Z',
								scheduleId: '4f5fb361-cfb9-4f4b-b885-fb57b35ebfdf',
							},
							{
								endAt: '2022-01-01T15:00:00.193Z',
								startAt: '2022-01-01T16:00:00.688Z',
								weekday: 0,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:49:50.419Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:49:50.419Z',
								scheduleId: '96d03175-120c-4de6-809a-9984d2b895c6',
							},
							{
								endAt: '2022-01-01T15:00:00.151Z',
								startAt: '2022-01-01T16:00:00.103Z',
								weekday: 1,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:49:59.678Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:49:59.678Z',
								scheduleId: '267d0e0d-e93e-4cff-99ce-7217c17c72b2',
							},
							{
								endAt: '2022-01-01T15:00:00.815Z',
								startAt: '2022-01-01T16:00:00.051Z',
								weekday: 2,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:50:08.538Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:50:08.538Z',
								scheduleId: '05af5b5c-e9f9-4eae-af8f-7798b7a77c8c',
							},
							{
								endAt: '2022-01-01T15:00:00.514Z',
								startAt: '2022-01-01T16:00:00.095Z',
								weekday: 3,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:50:16.576Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:50:16.576Z',
								scheduleId: '036a27b1-c7ef-4c15-a09a-bd4930740ed5',
							},
							{
								endAt: '2022-01-01T15:00:00.316Z',
								startAt: '2022-01-01T16:00:00.984Z',
								weekday: 4,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:50:24.164Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:50:24.164Z',
								scheduleId: '2713bd60-53be-4db0-b498-0f022f86aba0',
							},
							{
								endAt: '2022-01-01T15:00:00.413Z',
								startAt: '2022-01-01T16:00:00.151Z',
								weekday: 5,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:50:32.105Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:50:32.105Z',
								scheduleId: 'f745ce8f-f27d-45a1-90b5-cc0eebc81355',
							},
							{
								endAt: '2022-01-01T15:00:00.919Z',
								startAt: '2022-01-01T16:00:00.086Z',
								weekday: 6,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:50:40.960Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:50:40.960Z',
								scheduleId: '99c70c12-88ef-41f5-9a55-f9f2cfc41781',
							},
							{
								endAt: '2022-01-01T14:00:00.988Z',
								startAt: '2022-01-01T22:00:00.623Z',
								weekday: 1,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:51:26.346Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T06:51:26.346Z',
								scheduleId: '38cafd85-4378-43a3-a720-00b6ddac6398',
							},
							{
								endAt: '2022-01-01T14:00:00.554Z',
								startAt: '2022-01-01T22:00:00.438Z',
								weekday: 0,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:51:41.754Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T06:51:41.754Z',
								scheduleId: 'c86888ab-75c5-4c86-8a1b-943c0b587028',
							},
							{
								endAt: '2022-01-01T14:00:00.699Z',
								startAt: '2022-01-01T22:00:00.142Z',
								weekday: 2,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:51:49.906Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T06:51:49.906Z',
								scheduleId: '475b6f3a-aa77-4f83-a495-eb918e348eee',
							},
							{
								endAt: '2022-01-01T14:00:00.547Z',
								startAt: '2022-01-01T22:00:00.098Z',
								weekday: 3,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:51:58.977Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T06:51:58.977Z',
								scheduleId: '53be5bee-bf63-43e6-87a2-5502ab54cd5e',
							},
							{
								endAt: '2022-01-01T14:00:00.650Z',
								startAt: '2022-01-01T22:00:00.789Z',
								weekday: 4,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:52:08.167Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T06:52:08.167Z',
								scheduleId: '0a791048-cf5c-4196-9925-6aedf6f2b6a2',
							},
							{
								endAt: '2022-01-01T14:00:00.198Z',
								startAt: '2022-01-01T22:00:00.815Z',
								weekday: 5,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:52:15.824Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T06:52:15.824Z',
								scheduleId: '73c51491-b54b-49b2-bd97-880a977c5911',
							},
							{
								endAt: '2022-01-01T14:00:00.523Z',
								startAt: '2022-01-01T22:00:00.953Z',
								weekday: 6,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:52:24.653Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T06:52:24.653Z',
								scheduleId: '7474fb2a-3d11-4a58-969d-a3f53a66ef64',
							},
						],
						startWork: null,
						tinNumber: '',
						updatedAt: '2022-09-08T08:47:58.390Z',
						educations: [],
						experience: 5,
						orderScore: 2,
						specialists: [
							{
								icon: 'family_medicine',
								level: 1,
								createdAt: '2022-09-08T03:16:01.498Z',
								updatedAt: '2022-09-08T03:16:01.498Z',
								specialistId: '55df1bba-f1e7-4e57-982d-084327ef4aac',
								specialistName: 'Family Medicine',
								parentSpecialistId: 'dafe825a-cc64-4b00-8d7d-e7ed8adab9ca',
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									specialistId: '55df1bba-f1e7-4e57-982d-084327ef4aac',
								},
							},
							{
								icon: 'general_surgery',
								level: 2,
								createdAt: '2022-09-08T03:16:01.512Z',
								updatedAt: '2022-09-08T03:16:01.512Z',
								specialistId: '533c6630-6efc-41a3-ade2-962b799055f5',
								specialistName: 'Nephrology',
								parentSpecialistId: '3d25d57c-d0f4-4011-aef4-f300b5d3b9ea',
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									specialistId: '533c6630-6efc-41a3-ade2-962b799055f5',
								},
							},
							{
								icon: 'ear_nose_throat',
								level: 1,
								createdAt: '2022-09-08T03:16:01.498Z',
								updatedAt: '2022-09-08T03:16:01.498Z',
								specialistId: 'af16c17b-41b6-43e8-9718-2917661f9952',
								specialistName: 'Ear, Nose & Throat (ENT)',
								parentSpecialistId: 'dafe825a-cc64-4b00-8d7d-e7ed8adab9ca',
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									specialistId: 'af16c17b-41b6-43e8-9718-2917661f9952',
								},
							},
							{
								icon: 'orthopedic_surgery',
								level: 1,
								createdAt: '2022-09-08T03:16:01.498Z',
								updatedAt: '2022-09-08T03:16:01.498Z',
								specialistId: '42f5ed40-e442-4581-a324-9f81d86d8032',
								specialistName: 'Orthopedic Surgery',
								parentSpecialistId: 'dafe825a-cc64-4b00-8d7d-e7ed8adab9ca',
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									specialistId: '42f5ed40-e442-4581-a324-9f81d86d8032',
								},
							},
							{
								icon: 'general_surgery',
								level: 1,
								createdAt: '2022-09-08T03:16:01.498Z',
								updatedAt: '2022-09-08T03:16:01.498Z',
								specialistId: '3d25d57c-d0f4-4011-aef4-f300b5d3b9ea',
								specialistName: 'General Surgery',
								parentSpecialistId: 'dafe825a-cc64-4b00-8d7d-e7ed8adab9ca',
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									specialistId: '3d25d57c-d0f4-4011-aef4-f300b5d3b9ea',
								},
							},
							{
								icon: 'orthopedic_surgery',
								level: 2,
								createdAt: '2022-09-08T03:16:01.512Z',
								updatedAt: '2022-09-08T03:16:01.512Z',
								specialistId: '22ffc6c1-e324-4202-b325-ce5ecdc79b7d',
								specialistName: 'Oncology',
								parentSpecialistId: '42f5ed40-e442-4581-a324-9f81d86d8032',
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									specialistId: '22ffc6c1-e324-4202-b325-ce5ecdc79b7d',
								},
							},
							{
								icon: 'family_medicine',
								level: 2,
								createdAt: '2022-09-08T03:16:01.512Z',
								updatedAt: '2022-09-08T03:16:01.512Z',
								specialistId: '9baad78f-2237-4933-9c77-811cc886b878',
								specialistName: 'Cardiology',
								parentSpecialistId: '55df1bba-f1e7-4e57-982d-084327ef4aac',
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									specialistId: '9baad78f-2237-4933-9c77-811cc886b878',
								},
							},
							{
								icon: 'ear_nose_throat',
								level: 2,
								createdAt: '2022-09-08T03:16:01.512Z',
								updatedAt: '2022-09-08T03:16:01.512Z',
								specialistId: '4cf66658-09ce-4c10-be57-61af8ed52708',
								specialistName: 'Endocrinology & Metabolism',
								parentSpecialistId: 'af16c17b-41b6-43e8-9718-2917661f9952',
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									specialistId: '4cf66658-09ce-4c10-be57-61af8ed52708',
								},
							},
						],
						certificates: [],
						nameOfSignature: 'dr. Joe Keery, Sp. THT-KL.',
						otherSpecialist: '',
						signatureImageUrl:
							'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/ed196eae-be86-4ded-a9dd-defe1f7425f2',
						schedulesInPartners: [
							{
								endAt: '2022-01-01T15:00:00.815Z',
								doctor: {
									title: 'Sp. THT-KL.',
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									createdAt: '2022-09-08T06:03:26.570Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:47:58.390Z',
									experience: 2017,
									nameOfSignature: 'dr. Joe Keery, Sp. THT-KL.',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/ed196eae-be86-4ded-a9dd-defe1f7425f2',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.051Z',
								weekday: 2,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:50:08.538Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:50:08.538Z',
								scheduleId: '05af5b5c-e9f9-4eae-af8f-7798b7a77c8c',
							},
							{
								endAt: '2022-01-01T15:00:00.919Z',
								doctor: {
									title: 'Sp. THT-KL.',
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									createdAt: '2022-09-08T06:03:26.570Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:47:58.390Z',
									experience: 2017,
									nameOfSignature: 'dr. Joe Keery, Sp. THT-KL.',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/ed196eae-be86-4ded-a9dd-defe1f7425f2',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.086Z',
								weekday: 6,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:50:40.960Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:50:40.960Z',
								scheduleId: '99c70c12-88ef-41f5-9a55-f9f2cfc41781',
							},
							{
								endAt: '2022-01-01T15:00:00.514Z',
								doctor: {
									title: 'Sp. THT-KL.',
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									createdAt: '2022-09-08T06:03:26.570Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:47:58.390Z',
									experience: 2017,
									nameOfSignature: 'dr. Joe Keery, Sp. THT-KL.',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/ed196eae-be86-4ded-a9dd-defe1f7425f2',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.095Z',
								weekday: 3,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:50:16.576Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:50:16.576Z',
								scheduleId: '036a27b1-c7ef-4c15-a09a-bd4930740ed5',
							},
							{
								endAt: '2022-01-01T15:00:00.151Z',
								doctor: {
									title: 'Sp. THT-KL.',
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									createdAt: '2022-09-08T06:03:26.570Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:47:58.390Z',
									experience: 2017,
									nameOfSignature: 'dr. Joe Keery, Sp. THT-KL.',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/ed196eae-be86-4ded-a9dd-defe1f7425f2',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.103Z',
								weekday: 1,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:49:59.678Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:49:59.678Z',
								scheduleId: '267d0e0d-e93e-4cff-99ce-7217c17c72b2',
							},
							{
								endAt: '2022-01-01T15:00:00.413Z',
								doctor: {
									title: 'Sp. THT-KL.',
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									createdAt: '2022-09-08T06:03:26.570Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:47:58.390Z',
									experience: 2017,
									nameOfSignature: 'dr. Joe Keery, Sp. THT-KL.',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/ed196eae-be86-4ded-a9dd-defe1f7425f2',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.151Z',
								weekday: 5,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:50:32.105Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:50:32.105Z',
								scheduleId: 'f745ce8f-f27d-45a1-90b5-cc0eebc81355',
							},
							{
								endAt: '2022-01-01T15:00:00.658Z',
								doctor: {
									title: 'Sp. THT-KL.',
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									createdAt: '2022-09-08T06:03:26.570Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:47:58.390Z',
									experience: 2017,
									nameOfSignature: 'dr. Joe Keery, Sp. THT-KL.',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/ed196eae-be86-4ded-a9dd-defe1f7425f2',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.531Z',
								weekday: 0,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:48:19.974Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:48:19.974Z',
								scheduleId: '2ffddb19-c73d-4991-9e21-6292c8a6d3a7',
							},
							{
								endAt: '2022-01-01T15:00:00.552Z',
								doctor: {
									title: 'Sp. THT-KL.',
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									createdAt: '2022-09-08T06:03:26.570Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:47:58.390Z',
									experience: 2017,
									nameOfSignature: 'dr. Joe Keery, Sp. THT-KL.',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/ed196eae-be86-4ded-a9dd-defe1f7425f2',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.594Z',
								weekday: 6,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:49:21.324Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:49:21.324Z',
								scheduleId: '4f5fb361-cfb9-4f4b-b885-fb57b35ebfdf',
							},
							{
								endAt: '2022-01-01T15:00:00.193Z',
								doctor: {
									title: 'Sp. THT-KL.',
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									createdAt: '2022-09-08T06:03:26.570Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:47:58.390Z',
									experience: 2017,
									nameOfSignature: 'dr. Joe Keery, Sp. THT-KL.',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/ed196eae-be86-4ded-a9dd-defe1f7425f2',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.688Z',
								weekday: 0,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:49:50.419Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:49:50.419Z',
								scheduleId: '96d03175-120c-4de6-809a-9984d2b895c6',
							},
							{
								endAt: '2022-01-01T15:00:00.501Z',
								doctor: {
									title: 'Sp. THT-KL.',
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									createdAt: '2022-09-08T06:03:26.570Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:47:58.390Z',
									experience: 2017,
									nameOfSignature: 'dr. Joe Keery, Sp. THT-KL.',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/ed196eae-be86-4ded-a9dd-defe1f7425f2',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.704Z',
								weekday: 3,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:48:54.680Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:48:54.680Z',
								scheduleId: '512acb0c-78c1-465e-b888-dadb31768bde',
							},
							{
								endAt: '2022-01-01T15:00:00.877Z',
								doctor: {
									title: 'Sp. THT-KL.',
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									createdAt: '2022-09-08T06:03:26.570Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:47:58.390Z',
									experience: 2017,
									nameOfSignature: 'dr. Joe Keery, Sp. THT-KL.',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/ed196eae-be86-4ded-a9dd-defe1f7425f2',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.717Z',
								weekday: 1,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:48:31.587Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:48:31.587Z',
								scheduleId: 'b26b4633-1391-48f5-8d51-3450101c5cc7',
							},
							{
								endAt: '2022-01-01T15:00:00.621Z',
								doctor: {
									title: 'Sp. THT-KL.',
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									createdAt: '2022-09-08T06:03:26.570Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:47:58.390Z',
									experience: 2017,
									nameOfSignature: 'dr. Joe Keery, Sp. THT-KL.',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/ed196eae-be86-4ded-a9dd-defe1f7425f2',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.890Z',
								weekday: 5,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:49:12.306Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:49:12.306Z',
								scheduleId: '0fff586c-299c-4b08-a015-fa8917f85445',
							},
							{
								endAt: '2022-01-01T15:00:00.765Z',
								doctor: {
									title: 'Sp. THT-KL.',
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									createdAt: '2022-09-08T06:03:26.570Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:47:58.390Z',
									experience: 2017,
									nameOfSignature: 'dr. Joe Keery, Sp. THT-KL.',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/ed196eae-be86-4ded-a9dd-defe1f7425f2',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.899Z',
								weekday: 2,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:48:43.096Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:48:43.096Z',
								scheduleId: '93fe7e95-5bf4-40b9-a1df-a6f11724a838',
							},
							{
								endAt: '2022-01-01T15:00:00.316Z',
								doctor: {
									title: 'Sp. THT-KL.',
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									createdAt: '2022-09-08T06:03:26.570Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:47:58.390Z',
									experience: 2017,
									nameOfSignature: 'dr. Joe Keery, Sp. THT-KL.',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/ed196eae-be86-4ded-a9dd-defe1f7425f2',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.984Z',
								weekday: 4,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:50:24.164Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:50:24.164Z',
								scheduleId: '2713bd60-53be-4db0-b498-0f022f86aba0',
							},
							{
								endAt: '2022-01-01T15:00:00.642Z',
								doctor: {
									title: 'Sp. THT-KL.',
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									createdAt: '2022-09-08T06:03:26.570Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:47:58.390Z',
									experience: 2017,
									nameOfSignature: 'dr. Joe Keery, Sp. THT-KL.',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/ed196eae-be86-4ded-a9dd-defe1f7425f2',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.994Z',
								weekday: 4,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:49:04.137Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:49:04.137Z',
								scheduleId: 'effc8673-de6d-4b36-b7c3-8f76a40598dd',
							},
							{
								endAt: '2022-01-01T14:00:00.547Z',
								doctor: {
									title: 'Sp. THT-KL.',
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									createdAt: '2022-09-08T06:03:26.570Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:47:58.390Z',
									experience: 2017,
									nameOfSignature: 'dr. Joe Keery, Sp. THT-KL.',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/ed196eae-be86-4ded-a9dd-defe1f7425f2',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T22:00:00.098Z',
								weekday: 3,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:51:58.977Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T06:51:58.977Z',
								scheduleId: '53be5bee-bf63-43e6-87a2-5502ab54cd5e',
							},
							{
								endAt: '2022-01-01T14:00:00.699Z',
								doctor: {
									title: 'Sp. THT-KL.',
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									createdAt: '2022-09-08T06:03:26.570Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:47:58.390Z',
									experience: 2017,
									nameOfSignature: 'dr. Joe Keery, Sp. THT-KL.',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/ed196eae-be86-4ded-a9dd-defe1f7425f2',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T22:00:00.142Z',
								weekday: 2,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:51:49.906Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T06:51:49.906Z',
								scheduleId: '475b6f3a-aa77-4f83-a495-eb918e348eee',
							},
							{
								endAt: '2022-01-01T14:00:00.554Z',
								doctor: {
									title: 'Sp. THT-KL.',
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									createdAt: '2022-09-08T06:03:26.570Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:47:58.390Z',
									experience: 2017,
									nameOfSignature: 'dr. Joe Keery, Sp. THT-KL.',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/ed196eae-be86-4ded-a9dd-defe1f7425f2',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T22:00:00.438Z',
								weekday: 0,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:51:41.754Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T06:51:41.754Z',
								scheduleId: 'c86888ab-75c5-4c86-8a1b-943c0b587028',
							},
							{
								endAt: '2022-01-01T14:00:00.988Z',
								doctor: {
									title: 'Sp. THT-KL.',
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									createdAt: '2022-09-08T06:03:26.570Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:47:58.390Z',
									experience: 2017,
									nameOfSignature: 'dr. Joe Keery, Sp. THT-KL.',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/ed196eae-be86-4ded-a9dd-defe1f7425f2',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T22:00:00.623Z',
								weekday: 1,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:51:26.346Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T06:51:26.346Z',
								scheduleId: '38cafd85-4378-43a3-a720-00b6ddac6398',
							},
							{
								endAt: '2022-01-01T14:00:00.650Z',
								doctor: {
									title: 'Sp. THT-KL.',
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									createdAt: '2022-09-08T06:03:26.570Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:47:58.390Z',
									experience: 2017,
									nameOfSignature: 'dr. Joe Keery, Sp. THT-KL.',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/ed196eae-be86-4ded-a9dd-defe1f7425f2',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T22:00:00.789Z',
								weekday: 4,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:52:08.167Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T06:52:08.167Z',
								scheduleId: '0a791048-cf5c-4196-9925-6aedf6f2b6a2',
							},
							{
								endAt: '2022-01-01T14:00:00.198Z',
								doctor: {
									title: 'Sp. THT-KL.',
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									createdAt: '2022-09-08T06:03:26.570Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:47:58.390Z',
									experience: 2017,
									nameOfSignature: 'dr. Joe Keery, Sp. THT-KL.',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/ed196eae-be86-4ded-a9dd-defe1f7425f2',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T22:00:00.815Z',
								weekday: 5,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:52:15.824Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T06:52:15.824Z',
								scheduleId: '73c51491-b54b-49b2-bd97-880a977c5911',
							},
							{
								endAt: '2022-01-01T14:00:00.523Z',
								doctor: {
									title: 'Sp. THT-KL.',
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									createdAt: '2022-09-08T06:03:26.570Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:47:58.390Z',
									experience: 2017,
									nameOfSignature: 'dr. Joe Keery, Sp. THT-KL.',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/ed196eae-be86-4ded-a9dd-defe1f7425f2',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T22:00:00.953Z',
								weekday: 6,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:52:24.653Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T06:52:24.653Z',
								scheduleId: '7474fb2a-3d11-4a58-969d-a3f53a66ef64',
							},
						],
						specialityCertificate: null,
						availableConsultationTypes: [
							'TELEMEDICINE_VIDEO_CHAT_CONSULTATION',
							'TELEMEDICINE_CHAT_CONSULTATION',
							'CONSULT_NOW_VIDEO_CHAT_CONSULTATION',
							'CONSULT_NOW_CHAT_CONSULTATION',
							'FACE_TO_FACE_CONSULTATION',
						],
					},
					metaPartner: {
						partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
						isPublished: true,
						partnerName: 'Online Clinic',
						partnerType: 'ONLINE_CLINIC',
						isConfirmedPartner: true,
					},
					createdByUserId: '01GCDNAV6270SMVQBEAQ90VY4G',
					metaCreatedByUser: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						updatedAt: '2022-09-08T07:10:14.649Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					createdAt: '2022-09-09T01:17:21.455Z',
					updatedAt: '2022-09-09T08:15:32.144Z',
					medicalRecord: {
						medicalRecordId: '8f23c8de-1d9f-42a0-9c9a-47f33207106a',
						doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
						patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patientInRelationId: null,
						reasonOfConsultation: 'denggong',
						historyPhysicalFindings: 'A',
						diagnosis: 'A',
						planForManagement: 'A',
						transactionConsultationId: '785b2e1d-f5bc-4563-952f-24c6674d8364',
						uploadedFile: null,
						createdAt: '2022-09-09T01:17:21.468Z',
						updatedAt: '2022-09-09T08:01:02.163Z',
					},
					history: [
						{
							historyId: '78e50053-46e3-4657-95ec-be7d2a59a0d4',
							transactionConsultationId: '785b2e1d-f5bc-4563-952f-24c6674d8364',
							description: 'Update transaction status',
							newData: {
								transactionStatus: 'COMPLETED',
							},
							createdByUserId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
							metaCreatedByUser: {
								action: 'login_success',
								userId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								payload: {
									phoneNumber: '6381234',
								},
								userType: 'customer',
								sessionId:
									'0a0d4dbf636902f8881eb34138a07b8a99060a89696bcb89638a68b8a40d2080',
							},
							createdAt: '2022-09-09T08:15:32.158Z',
							updatedAt: '2022-09-09T08:15:32.158Z',
						},
						{
							historyId: '56e624b0-dc92-46ff-a6d1-689ed50a230d',
							transactionConsultationId: '785b2e1d-f5bc-4563-952f-24c6674d8364',
							description: 'Update transaction status',
							newData: {
								transactionStatus: 'APPROVED',
							},
							createdByUserId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
							metaCreatedByUser: {
								action: 'login_success',
								userId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								payload: {
									phoneNumber: '6381234',
								},
								userType: 'customer',
								sessionId:
									'0a0d4dbf636902f8881eb34138a07b8a99060a89696bcb89638a68b8a40d2080',
							},
							createdAt: '2022-09-09T07:39:15.433Z',
							updatedAt: '2022-09-09T07:39:15.433Z',
						},
						{
							historyId: '1d4bd1d0-0865-41b5-9eff-4cefe04c4a2d',
							transactionConsultationId: '785b2e1d-f5bc-4563-952f-24c6674d8364',
							description: 'Update transaction status',
							newData: {
								transactionStatus: 'WAITING_APPROVAL',
							},
							createdByUserId: '01GCDNAV6270SMVQBEAQ90VY4G',
							metaCreatedByUser: {
								city: null,
								name: 'Rizki Rahmadi',
								email: null,
								phone: '63125',
								doctor: null,
								gender: 'male',
								status: 'active',
								userId: '01GCDNAV6270SMVQBEAQ90VY4G',
								patient: {
									pwId: null,
									pwIdUrl: null,
									createdAt: '2022-09-08T04:22:06.889Z',
									patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
									updatedAt: '2022-09-08T04:22:06.889Z',
									pwIdStatus: 'WAITING_APPROVAL',
								},
								lastName: 'Rahmadi',
								nickName: 'Iki',
								province: null,
								userType: 'customer',
								birthdate: '1999-03-11',
								createdAt: '2022-09-08T04:22:06.787Z',
								firstName: 'Rizki',
								updatedAt: '2022-09-08T07:10:14.649Z',
								middleName: null,
								profilePic: null,
								isEmailVerified: false,
								isPhoneVerified: true,
							},
							createdAt: '2022-09-09T01:18:13.738Z',
							updatedAt: '2022-09-09T01:18:13.738Z',
						},
					],
					payment: {
						paymentId: 'a0cd748a-7b7e-41eb-a55e-1afb6a1a75e7',
						invoiceId: 'CT/20220909/3YV',
						transactionId: '785b2e1d-f5bc-4563-952f-24c6674d8364',
						transactionType: 'TELEMEDICINE',
						totalNeedToPay: 575,
						paymentStatus: 'PAID_FULL',
						buyerId: '01GCDNAV6270SMVQBEAQ90VY4G',
						metaBuyerDetail: {
							city: null,
							name: 'Rizki Rahmadi',
							email: null,
							phone: '63125',
							doctor: null,
							gender: 'male',
							status: 'active',
							userId: '01GCDNAV6270SMVQBEAQ90VY4G',
							patient: {
								pwId: null,
								pwIdUrl: null,
								createdAt: '2022-09-08T04:22:06.889Z',
								patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
								updatedAt: '2022-09-08T04:22:06.889Z',
								pwIdStatus: 'WAITING_APPROVAL',
							},
							lastName: 'Rahmadi',
							nickName: 'Iki',
							province: null,
							userType: 'customer',
							birthdate: '1999-03-11',
							createdAt: '2022-09-08T04:22:06.787Z',
							firstName: 'Rizki',
							updatedAt: '2022-09-08T07:10:14.649Z',
							middleName: null,
							profilePic: null,
							isEmailVerified: false,
							isPhoneVerified: true,
						},
						meta: null,
						expiredAt: '2022-09-10T05:17:21.609Z',
						createdAt: '2022-09-09T01:17:21.610Z',
						updatedAt: '2022-09-09T01:18:13.714Z',
						paymentDetails: [
							{
								paymentDetailId: '2d5ab62c-4b24-4d32-96f9-f3748b3cec23',
								paymentId: 'a0cd748a-7b7e-41eb-a55e-1afb6a1a75e7',
								paymentType: 'PATIENT_TRANSACTION_FEE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 75,
								status: 'PAID_FULL',
								meta: {
									key: 'PATIENT_TRANSACTION_FEE',
									value: '5%+50',
									configId: 'PATIENT_TRANSACTION_FEE',
									createdAt: '2022-09-08T06:08:45.373Z',
									updatedAt: '2022-09-08T06:08:45.373Z',
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-10T05:17:21.609Z',
								createdAt: '2022-09-09T01:17:21.691Z',
								updatedAt: '2022-09-09T01:17:21.691Z',
							},
							{
								paymentDetailId: '751f843c-b0ad-40ca-9702-e9109fd4def3',
								paymentId: 'a0cd748a-7b7e-41eb-a55e-1afb6a1a75e7',
								paymentType: 'BASE_PRICE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 500,
								status: 'PAID_FULL',
								meta: {
									consultationFee: 500,
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-10T05:17:21.609Z',
								createdAt: '2022-09-09T01:17:21.689Z',
								updatedAt: '2022-09-09T01:17:21.689Z',
							},
							{
								paymentDetailId: 'a12a26ac-eadc-4df8-aaab-415dab56593f',
								paymentId: 'a0cd748a-7b7e-41eb-a55e-1afb6a1a75e7',
								paymentType: 'PAYMENT_GATEWAY',
								paymentMethod: 'EWALLET',
								paymentVendorType: 'PAYMONGO',
								paymentVendorId: 'GCASH',
								externalId: null,
								totalPay: 575,
								status: 'PAID_FULL',
								meta: {
									data: {
										id: 'evt_hKiwTiLVCG581syc1HxxHeXT',
										type: 'event',
										attributes: {
											data: {
												id: 'pay_g4BZs3PyDvBZTJs7JkqVeaPa',
												type: 'payment',
												attributes: {
													fee: 1438,
													taxes: [],
													amount: 57500,
													origin: 'api',
													payout: null,
													source: {
														id: 'src_KAxzjfqWubeCt3jwgXLQ1344',
														type: 'gcash',
													},
													status: 'paid',
													billing: null,
													paid_at: 1662686270,
													refunds: [],
													currency: 'PHP',
													disputed: false,
													livemode: false,
													metadata: {
														paymentId: 'a0cd748a-7b7e-41eb-a55e-1afb6a1a75e7',
													},
													access_url: null,
													created_at: 1662686270,
													net_amount: 56062,
													tax_amount: null,
													updated_at: 1662686270,
													description: null,
													available_at: 1663059600,
													payment_intent_id: null,
													statement_descriptor: 'PAYMONGO',
													balance_transaction_id:
														'bal_txn_UDkDdMbj27pRa3QsTzTSHwjX',
													external_reference_number: null,
												},
											},
											type: 'payment.paid',
											livemode: false,
											created_at: 1662686270,
											updated_at: 1662686270,
											previous_data: {},
										},
									},
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-10T05:17:21.609Z',
								createdAt: '2022-09-09T01:17:21.687Z',
								updatedAt: '2022-09-09T01:18:13.699Z',
							},
						],
						paymentDetailsPartners: [
							{
								paymentDetailId: 'fc73379f-aa02-4d14-8be1-fee52a007997',
								paymentId: 'a0cd748a-7b7e-41eb-a55e-1afb6a1a75e7',
								paymentType: 'FINAL_PAYMENT',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 473,
								status: 'PAID_FULL',
								meta: {},
								type: 'PAYMENT',
								expiredAt: '2022-09-10T05:17:21.609Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-09T01:17:21.697Z',
								updatedAt: '2022-09-09T01:17:21.697Z',
							},
							{
								paymentDetailId: '0364fd24-ba25-40bf-91b7-9d06315fc640',
								paymentId: 'a0cd748a-7b7e-41eb-a55e-1afb6a1a75e7',
								paymentType: 'DOCTOR_TRANSACTION_FEE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 17,
								status: 'PAID_FULL',
								meta: {
									key: 'DOCTOR_TRANSACTION_FEE',
									value: '3%',
									configId: 'DOCTOR_TRANSACTION_FEE',
									createdAt: '2022-09-08T06:08:45.373Z',
									updatedAt: '2022-09-08T06:08:45.373Z',
								},
								type: 'DISCOUNT',
								expiredAt: '2022-09-10T05:17:21.609Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-09T01:17:21.697Z',
								updatedAt: '2022-09-09T01:17:21.697Z',
							},
							{
								paymentDetailId: '54c11c92-46f1-4ccb-b393-b7e9ed11a44d',
								paymentId: 'a0cd748a-7b7e-41eb-a55e-1afb6a1a75e7',
								paymentType: 'DOCTOR_COMMISSION_FEE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 10,
								status: 'PAID_FULL',
								meta: {
									key: 'DOCTOR_COMMISSION_FEE',
									value: '2%',
									configId: 'DOCTOR_COMMISSION_FEE',
									createdAt: '2022-09-08T09:27:39.090Z',
									updatedAt: '2022-09-08T09:27:39.090Z',
								},
								type: 'DISCOUNT',
								expiredAt: '2022-09-10T05:17:21.609Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-09T01:17:21.697Z',
								updatedAt: '2022-09-09T01:17:21.697Z',
							},
							{
								paymentDetailId: '3f06419b-51e3-4569-b053-921fb6dad5c3',
								paymentId: 'a0cd748a-7b7e-41eb-a55e-1afb6a1a75e7',
								paymentType: 'PATIENT_PWD_DISCOUNT',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 0,
								status: 'PAID_FULL',
								meta: {},
								type: 'DISCOUNT',
								expiredAt: '2022-09-10T05:17:21.609Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-09T01:17:21.697Z',
								updatedAt: '2022-09-09T01:17:21.697Z',
							},
							{
								paymentDetailId: '550c0aef-0458-4990-bad7-d5ff88e65925',
								paymentId: 'a0cd748a-7b7e-41eb-a55e-1afb6a1a75e7',
								paymentType: 'BASE_PRICE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 500,
								status: 'PAID_FULL',
								meta: {},
								type: 'PAYMENT',
								expiredAt: '2022-09-10T05:17:21.609Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-09T01:17:21.697Z',
								updatedAt: '2022-09-09T01:17:21.697Z',
							},
						],
					},
				},
			},
			{
				name: 'Rizki Rahmadi',
				date: '2022-09-08',
				desc: 'sakit gigi',
				reason: 'sakit gigi',
				startTime: '15:30',
				endTime: '16:00',
				detailDoctor: {
					name: 'Aditya Arya Tusan',
					title: '',
					age: 22,
					ageString: '22 yo',
				},
				raw: {
					transactionConsultationId: '514e4cbd-4362-47e2-81cb-381d1677d9c1',
					doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
					patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
					patientInRelationId: null,
					partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
					consultationType: 'TELEMEDICINE_VIDEO_CHAT_CONSULTATION',
					scheduledStartAt: '2022-09-08T08:30:00.000Z',
					scheduledEndAt: '2022-09-08T09:00:00.000Z',
					isCanExtend: null,
					doctorJoinAt: null,
					doctorEndAt: null,
					patientJoinAt: null,
					patientEndAt: null,
					transactionStatus: 'COMPLETED',
					lastUpdatedByUser: {
						action: 'login_success',
						userId: '01GCDSTJ8FWEPN6H6MTQBR206D',
						payload: {
							phoneNumber: '6382345',
						},
						userType: 'customer',
						sessionId:
							'8bb8fd35ea9626d6f080660169d943d93793af825c33b829bfb455f0180e9e5d',
					},
					description: null,
					place: null,
					link: null,
					metaPatient: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						updatedAt: '2022-09-08T07:10:14.649Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					metaPatientInRelation: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
						updatedAt: '2022-09-08T07:10:14.649Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					metaDoctor: {
						user: {
							city: null,
							name: 'Aditya Arya Tusan',
							email: null,
							phone: '6382345',
							gender: 'male',
							status: 'active',
							userId: '01GCDSTJ8FWEPN6H6MTQBR206D',
							lastName: 'Tusan',
							nickName: 'Aditya',
							province: null,
							userType: 'customer',
							birthdate: '1999-11-30',
							createdAt: '2022-09-08T05:40:36.240Z',
							firstName: 'Aditya',
							updatedAt: '2022-09-08T05:51:28.275Z',
							middleName: 'Arya',
							profilePic: null,
						},
						title: 'Aditya, SKG',
						doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
						partners: [
							{
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:01:45.057Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:15.723Z',
								pricePerSession: 500,
							},
							{
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:01:45.057Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:01:59.322Z',
								pricePerSession: 500,
							},
							{
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:53:12.891Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:20.032Z',
								pricePerSession: 500,
							},
							{
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:53:55.441Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:53:55.441Z',
								pricePerSession: null,
							},
						],
						createdAt: '2022-09-08T05:47:23.761Z',
						kycStatus: 'APPROVED',
						prcNumber: '',
						ptrNumber: '',
						schedules: [
							{
								endAt: '2022-01-01T15:00:00.970Z',
								startAt: '2022-01-01T16:00:00.841Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:08.456Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:08.456Z',
								scheduleId: 'a31ae4ad-a007-4a79-a76f-0e4b46321701',
							},
							{
								endAt: '2022-01-01T15:00:00.776Z',
								startAt: '2022-01-01T16:00:00.973Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:29.740Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:29.740Z',
								scheduleId: '54f7f208-0156-4a7b-abab-01d5a4ab58aa',
							},
							{
								endAt: '2022-01-01T15:00:00.457Z',
								startAt: '2022-01-01T16:00:00.095Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:45.000Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:45.000Z',
								scheduleId: '39074432-4205-402f-997c-aa50803c2393',
							},
							{
								endAt: '2022-01-01T15:00:00.351Z',
								startAt: '2022-01-01T16:00:00.695Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:53.389Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:53.389Z',
								scheduleId: '8a73f804-38a2-4c63-90d6-0274230d2926',
							},
							{
								endAt: '2022-01-01T15:00:00.079Z',
								startAt: '2022-01-01T16:00:00.689Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:00.869Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:00.869Z',
								scheduleId: 'f9642816-0af0-4b81-aec1-f6d84f0e004f',
							},
							{
								endAt: '2022-01-01T15:00:00.777Z',
								startAt: '2022-01-01T16:00:00.303Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:09.660Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:09.660Z',
								scheduleId: '51f82da8-9357-45fa-9bd5-e01957ca0bbf',
							},
							{
								endAt: '2022-01-01T15:00:00.488Z',
								startAt: '2022-01-01T16:00:00.787Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:18.728Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:18.728Z',
								scheduleId: '4126c29f-9586-498a-b58b-03f45d8f5949',
							},
							{
								endAt: '2022-01-01T15:00:00.048Z',
								startAt: '2022-01-01T16:00:00.494Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:27.765Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:27.765Z',
								scheduleId: '4f7a2e8e-4afd-41bf-88f2-59fbcf1a7d1b',
							},
							{
								endAt: '2022-01-01T15:00:00.339Z',
								startAt: '2022-01-01T16:00:00.285Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:56.277Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:56.277Z',
								scheduleId: '9e8417db-9105-410e-b740-5b218437c06d',
							},
							{
								endAt: '2022-01-01T15:00:00.194Z',
								startAt: '2022-01-01T16:00:00.066Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:28.709Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:28.709Z',
								scheduleId: 'b59890d5-44a0-447b-9f31-ca137e27779a',
							},
							{
								endAt: '2022-01-01T15:00:00.814Z',
								startAt: '2022-01-01T16:00:00.197Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:36.439Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:36.439Z',
								scheduleId: 'cb76674b-12ef-4dbc-98aa-99edbb61bc25',
							},
							{
								endAt: '2022-01-01T15:00:00.683Z',
								startAt: '2022-01-01T16:00:00.952Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:44.409Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:44.409Z',
								scheduleId: '4a3b9e33-2601-4228-903e-79e2c1a171c0',
							},
							{
								endAt: '2022-01-01T15:00:00.303Z',
								startAt: '2022-01-01T16:00:00.810Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:52.482Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:52.482Z',
								scheduleId: '3459a911-20d6-4179-8873-115af073b8df',
							},
							{
								endAt: '2022-01-01T15:00:00.632Z',
								startAt: '2022-01-01T16:00:00.098Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:00.731Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:00.731Z',
								scheduleId: '53cbe7a8-8254-4b86-8d49-9153906af659',
							},
							{
								endAt: '2022-01-01T15:00:00.267Z',
								startAt: '2022-01-01T16:00:00.342Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:10.158Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:10.158Z',
								scheduleId: '368e3dcb-c87e-4382-93b5-f92124af68a0',
							},
							{
								endAt: '2022-01-01T15:00:00.511Z',
								startAt: '2022-01-01T16:00:00.547Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:23.678Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:23.678Z',
								scheduleId: '506cd274-9f8e-4296-a480-7949bde125e4',
							},
							{
								endAt: '2022-01-01T15:00:00.418Z',
								startAt: '2022-01-01T16:00:00.377Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:17.220Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:17.220Z',
								scheduleId: '4f2f93d0-8d2a-4a61-9742-db0e509dec5e',
							},
							{
								endAt: '2022-01-01T15:00:00.198Z',
								startAt: '2022-01-01T16:00:00.805Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:25.263Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:25.263Z',
								scheduleId: '5415d0c1-6a9d-440e-9df9-fc7166caab12',
							},
							{
								endAt: '2022-01-01T15:00:00.972Z',
								startAt: '2022-01-01T16:00:00.496Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:32.657Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:32.657Z',
								scheduleId: '05ac2913-2d60-4e02-b4b5-340448bab3ac',
							},
							{
								endAt: '2022-01-01T15:00:00.610Z',
								startAt: '2022-01-01T16:00:00.873Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:40.316Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:40.316Z',
								scheduleId: 'b4b36f6b-1c4b-4886-8645-ddcbd41671cc',
							},
							{
								endAt: '2022-01-01T15:00:00.149Z',
								startAt: '2022-01-01T16:00:00.634Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:48.087Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:48.087Z',
								scheduleId: '188cf832-1275-49bd-885f-240e390b8e6f',
							},
							{
								endAt: '2022-01-01T15:00:00.118Z',
								startAt: '2022-01-01T16:00:00.566Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:57.046Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:57.046Z',
								scheduleId: 'c703d009-2840-4730-a9d1-e4ad1dc6a917',
							},
							{
								endAt: '2022-01-01T15:00:00.184Z',
								startAt: '2022-01-01T16:00:00.850Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:05.067Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:03:05.067Z',
								scheduleId: 'c12eb042-bd87-41b1-9c12-255681814c02',
							},
							{
								endAt: '2022-01-01T15:00:00.691Z',
								startAt: '2022-01-01T16:00:00.931Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:23.318Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:23.318Z',
								scheduleId: '2ffbdaa9-c727-4de3-ade4-302d22731359',
							},
							{
								endAt: '2022-01-01T15:00:00.512Z',
								startAt: '2022-01-01T16:00:00.375Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:33.507Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:33.507Z',
								scheduleId: '22a089d9-af75-45cc-927e-d0845099ef05',
							},
							{
								endAt: '2022-01-01T15:00:00.268Z',
								startAt: '2022-01-01T16:00:00.661Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:43.515Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:43.515Z',
								scheduleId: '9d0fb904-4e28-42af-a4d4-060ad52e704b',
							},
							{
								endAt: '2022-01-01T15:00:00.388Z',
								startAt: '2022-01-01T16:00:00.083Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:54.009Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:54.009Z',
								scheduleId: '3cbb04c9-e894-45b3-9ab6-4cd9ca68c3dd',
							},
							{
								endAt: '2022-01-01T15:00:00.833Z',
								startAt: '2022-01-01T16:00:00.384Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:05.486Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:05.486Z',
								scheduleId: 'fc14c9b9-4b89-4667-af32-6c27bd1147d4',
							},
							{
								endAt: '2022-01-01T15:00:00.505Z',
								startAt: '2022-01-01T16:00:00.098Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:13.020Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:13.020Z',
								scheduleId: '1a17c05d-ef4b-4f58-b4b0-a03ec697dac5',
							},
							{
								endAt: '2022-01-01T15:00:00.599Z',
								startAt: '2022-01-01T16:00:00.683Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:21.254Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:21.254Z',
								scheduleId: 'f096a199-fe82-40c5-861c-cab046ddbdd7',
							},
						],
						startWork: null,
						tinNumber: '',
						updatedAt: '2022-09-08T05:52:09.599Z',
						educations: [],
						experience: 2,
						orderScore: 2,
						specialists: [],
						certificates: [],
						nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
						otherSpecialist: '',
						signatureImageUrl:
							'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
						schedulesInPartners: [
							{
								endAt: '2022-01-01T15:00:00.194Z',
								doctor: {
									title: 'Aditya, SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T05:52:09.599Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.066Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:28.709Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:28.709Z',
								scheduleId: 'b59890d5-44a0-447b-9f31-ca137e27779a',
							},
							{
								endAt: '2022-01-01T15:00:00.388Z',
								doctor: {
									title: 'Aditya, SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T05:52:09.599Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.083Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:54.009Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:54.009Z',
								scheduleId: '3cbb04c9-e894-45b3-9ab6-4cd9ca68c3dd',
							},
							{
								endAt: '2022-01-01T15:00:00.457Z',
								doctor: {
									title: 'Aditya, SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T05:52:09.599Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.095Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:45.000Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:45.000Z',
								scheduleId: '39074432-4205-402f-997c-aa50803c2393',
							},
							{
								endAt: '2022-01-01T15:00:00.632Z',
								doctor: {
									title: 'Aditya, SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T05:52:09.599Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.098Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:00.731Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:00.731Z',
								scheduleId: '53cbe7a8-8254-4b86-8d49-9153906af659',
							},
							{
								endAt: '2022-01-01T15:00:00.505Z',
								doctor: {
									title: 'Aditya, SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T05:52:09.599Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.098Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:13.020Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:13.020Z',
								scheduleId: '1a17c05d-ef4b-4f58-b4b0-a03ec697dac5',
							},
							{
								endAt: '2022-01-01T15:00:00.814Z',
								doctor: {
									title: 'Aditya, SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T05:52:09.599Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.197Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:36.439Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:36.439Z',
								scheduleId: 'cb76674b-12ef-4dbc-98aa-99edbb61bc25',
							},
							{
								endAt: '2022-01-01T15:00:00.339Z',
								doctor: {
									title: 'Aditya, SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T05:52:09.599Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.285Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:56.277Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:56.277Z',
								scheduleId: '9e8417db-9105-410e-b740-5b218437c06d',
							},
							{
								endAt: '2022-01-01T15:00:00.777Z',
								doctor: {
									title: 'Aditya, SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T05:52:09.599Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.303Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:09.660Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:09.660Z',
								scheduleId: '51f82da8-9357-45fa-9bd5-e01957ca0bbf',
							},
							{
								endAt: '2022-01-01T15:00:00.267Z',
								doctor: {
									title: 'Aditya, SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T05:52:09.599Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.342Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:10.158Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:10.158Z',
								scheduleId: '368e3dcb-c87e-4382-93b5-f92124af68a0',
							},
							{
								endAt: '2022-01-01T15:00:00.512Z',
								doctor: {
									title: 'Aditya, SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T05:52:09.599Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.375Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:33.507Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:33.507Z',
								scheduleId: '22a089d9-af75-45cc-927e-d0845099ef05',
							},
							{
								endAt: '2022-01-01T15:00:00.418Z',
								doctor: {
									title: 'Aditya, SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T05:52:09.599Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.377Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:17.220Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:17.220Z',
								scheduleId: '4f2f93d0-8d2a-4a61-9742-db0e509dec5e',
							},
							{
								endAt: '2022-01-01T15:00:00.833Z',
								doctor: {
									title: 'Aditya, SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T05:52:09.599Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.384Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:05.486Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:05.486Z',
								scheduleId: 'fc14c9b9-4b89-4667-af32-6c27bd1147d4',
							},
							{
								endAt: '2022-01-01T15:00:00.048Z',
								doctor: {
									title: 'Aditya, SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T05:52:09.599Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.494Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:27.765Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:27.765Z',
								scheduleId: '4f7a2e8e-4afd-41bf-88f2-59fbcf1a7d1b',
							},
							{
								endAt: '2022-01-01T15:00:00.972Z',
								doctor: {
									title: 'Aditya, SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T05:52:09.599Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.496Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:32.657Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:32.657Z',
								scheduleId: '05ac2913-2d60-4e02-b4b5-340448bab3ac',
							},
							{
								endAt: '2022-01-01T15:00:00.511Z',
								doctor: {
									title: 'Aditya, SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T05:52:09.599Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.547Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:23.678Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:23.678Z',
								scheduleId: '506cd274-9f8e-4296-a480-7949bde125e4',
							},
							{
								endAt: '2022-01-01T15:00:00.118Z',
								doctor: {
									title: 'Aditya, SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T05:52:09.599Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.566Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:57.046Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:57.046Z',
								scheduleId: 'c703d009-2840-4730-a9d1-e4ad1dc6a917',
							},
							{
								endAt: '2022-01-01T15:00:00.149Z',
								doctor: {
									title: 'Aditya, SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T05:52:09.599Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.634Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:48.087Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:48.087Z',
								scheduleId: '188cf832-1275-49bd-885f-240e390b8e6f',
							},
							{
								endAt: '2022-01-01T15:00:00.268Z',
								doctor: {
									title: 'Aditya, SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T05:52:09.599Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.661Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:43.515Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:43.515Z',
								scheduleId: '9d0fb904-4e28-42af-a4d4-060ad52e704b',
							},
							{
								endAt: '2022-01-01T15:00:00.599Z',
								doctor: {
									title: 'Aditya, SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T05:52:09.599Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.683Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:21.254Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:21.254Z',
								scheduleId: 'f096a199-fe82-40c5-861c-cab046ddbdd7',
							},
							{
								endAt: '2022-01-01T15:00:00.079Z',
								doctor: {
									title: 'Aditya, SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T05:52:09.599Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.689Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:00.869Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:00.869Z',
								scheduleId: 'f9642816-0af0-4b81-aec1-f6d84f0e004f',
							},
							{
								endAt: '2022-01-01T15:00:00.351Z',
								doctor: {
									title: 'Aditya, SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T05:52:09.599Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.695Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:53.389Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:53.389Z',
								scheduleId: '8a73f804-38a2-4c63-90d6-0274230d2926',
							},
							{
								endAt: '2022-01-01T15:00:00.488Z',
								doctor: {
									title: 'Aditya, SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T05:52:09.599Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.787Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:18.728Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:18.728Z',
								scheduleId: '4126c29f-9586-498a-b58b-03f45d8f5949',
							},
							{
								endAt: '2022-01-01T15:00:00.198Z',
								doctor: {
									title: 'Aditya, SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T05:52:09.599Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.805Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:25.263Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:25.263Z',
								scheduleId: '5415d0c1-6a9d-440e-9df9-fc7166caab12',
							},
							{
								endAt: '2022-01-01T15:00:00.303Z',
								doctor: {
									title: 'Aditya, SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T05:52:09.599Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.810Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:52.482Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:52.482Z',
								scheduleId: '3459a911-20d6-4179-8873-115af073b8df',
							},
							{
								endAt: '2022-01-01T15:00:00.970Z',
								doctor: {
									title: 'Aditya, SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T05:52:09.599Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.841Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:08.456Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:08.456Z',
								scheduleId: 'a31ae4ad-a007-4a79-a76f-0e4b46321701',
							},
							{
								endAt: '2022-01-01T15:00:00.184Z',
								doctor: {
									title: 'Aditya, SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T05:52:09.599Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.850Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:05.067Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:03:05.067Z',
								scheduleId: 'c12eb042-bd87-41b1-9c12-255681814c02',
							},
							{
								endAt: '2022-01-01T15:00:00.610Z',
								doctor: {
									title: 'Aditya, SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T05:52:09.599Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.873Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:40.316Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:40.316Z',
								scheduleId: 'b4b36f6b-1c4b-4886-8645-ddcbd41671cc',
							},
							{
								endAt: '2022-01-01T15:00:00.691Z',
								doctor: {
									title: 'Aditya, SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T05:52:09.599Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.931Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:23.318Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:23.318Z',
								scheduleId: '2ffbdaa9-c727-4de3-ade4-302d22731359',
							},
							{
								endAt: '2022-01-01T15:00:00.683Z',
								doctor: {
									title: 'Aditya, SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T05:52:09.599Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.952Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:44.409Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:44.409Z',
								scheduleId: '4a3b9e33-2601-4228-903e-79e2c1a171c0',
							},
							{
								endAt: '2022-01-01T15:00:00.776Z',
								doctor: {
									title: 'Aditya, SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T05:52:09.599Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.973Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:29.740Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:29.740Z',
								scheduleId: '54f7f208-0156-4a7b-abab-01d5a4ab58aa',
							},
						],
						specialityCertificate: null,
						availableConsultationTypes: [
							'TELEMEDICINE_VIDEO_CHAT_CONSULTATION',
							'TELEMEDICINE_CHAT_CONSULTATION',
							'CONSULT_NOW_VIDEO_CHAT_CONSULTATION',
							'CONSULT_NOW_CHAT_CONSULTATION',
							'FACE_TO_FACE_CONSULTATION',
						],
					},
					metaPartner: {
						partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
						isPublished: true,
						partnerName: 'Online Clinic',
						partnerType: 'ONLINE_CLINIC',
						isConfirmedPartner: true,
					},
					createdByUserId: '01GCDNAV6270SMVQBEAQ90VY4G',
					metaCreatedByUser: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						updatedAt: '2022-09-08T07:10:14.649Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					createdAt: '2022-09-08T07:59:10.581Z',
					updatedAt: '2022-09-08T10:14:15.269Z',
					medicalRecord: {
						medicalRecordId: '91851083-33bc-43ee-a988-34bec188ae41',
						doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
						patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patientInRelationId: null,
						reasonOfConsultation: 'sakit gigi',
						historyPhysicalFindings: 'A',
						diagnosis: 'A',
						planForManagement: 'A',
						transactionConsultationId: '514e4cbd-4362-47e2-81cb-381d1677d9c1',
						uploadedFile: null,
						createdAt: '2022-09-08T07:59:10.597Z',
						updatedAt: '2022-09-08T10:09:29.580Z',
					},
					history: [
						{
							historyId: 'e50bd9b3-0d16-4262-bd04-e0647dd02292',
							transactionConsultationId: '514e4cbd-4362-47e2-81cb-381d1677d9c1',
							description: 'Update transaction status',
							newData: {
								transactionStatus: 'COMPLETED',
							},
							createdByUserId: '01GCDSTJ8FWEPN6H6MTQBR206D',
							metaCreatedByUser: {
								action: 'login_success',
								userId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								payload: {
									phoneNumber: '6382345',
								},
								userType: 'customer',
								sessionId:
									'8bb8fd35ea9626d6f080660169d943d93793af825c33b829bfb455f0180e9e5d',
							},
							createdAt: '2022-09-08T10:14:15.279Z',
							updatedAt: '2022-09-08T10:14:15.279Z',
						},
						{
							historyId: 'd32903da-e7a3-4cf7-8852-850a37f594da',
							transactionConsultationId: '514e4cbd-4362-47e2-81cb-381d1677d9c1',
							description: 'Update transaction status',
							newData: {
								transactionStatus: 'APPROVED',
							},
							createdByUserId: '01GCDSTJ8FWEPN6H6MTQBR206D',
							metaCreatedByUser: {
								action: 'login_success',
								userId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								payload: {
									phoneNumber: '6382345',
								},
								userType: 'customer',
								sessionId:
									'd26d928299c3ef377fe7fd769fcfc17e3a26515c51f5db297676cc1285cab928',
							},
							createdAt: '2022-09-08T08:54:42.997Z',
							updatedAt: '2022-09-08T08:54:42.997Z',
						},
						{
							historyId: '3cbf676f-e365-4543-9a50-28ada8939396',
							transactionConsultationId: '514e4cbd-4362-47e2-81cb-381d1677d9c1',
							description: 'Update transaction status',
							newData: {
								transactionStatus: 'WAITING_APPROVAL',
							},
							createdByUserId: '01GCDNAV6270SMVQBEAQ90VY4G',
							metaCreatedByUser: {
								city: null,
								name: 'Rizki Rahmadi',
								email: null,
								phone: '63125',
								doctor: null,
								gender: 'male',
								status: 'active',
								userId: '01GCDNAV6270SMVQBEAQ90VY4G',
								patient: {
									pwId: null,
									pwIdUrl: null,
									createdAt: '2022-09-08T04:22:06.889Z',
									patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
									updatedAt: '2022-09-08T04:22:06.889Z',
									pwIdStatus: 'WAITING_APPROVAL',
								},
								lastName: 'Rahmadi',
								nickName: 'Iki',
								province: null,
								userType: 'customer',
								birthdate: '1999-03-11',
								createdAt: '2022-09-08T04:22:06.787Z',
								firstName: 'Rizki',
								updatedAt: '2022-09-08T07:10:14.649Z',
								middleName: null,
								profilePic: null,
								isEmailVerified: false,
								isPhoneVerified: true,
							},
							createdAt: '2022-09-08T08:00:02.661Z',
							updatedAt: '2022-09-08T08:00:02.661Z',
						},
					],
					payment: {
						paymentId: 'c1aaf000-89e5-406a-921a-991620a834ed',
						invoiceId: 'CT/20220908/7TF',
						transactionId: '514e4cbd-4362-47e2-81cb-381d1677d9c1',
						transactionType: 'TELEMEDICINE',
						totalNeedToPay: 575,
						paymentStatus: 'PAID_FULL',
						buyerId: '01GCDNAV6270SMVQBEAQ90VY4G',
						metaBuyerDetail: {
							city: null,
							name: 'Rizki Rahmadi',
							email: null,
							phone: '63125',
							doctor: null,
							gender: 'male',
							status: 'active',
							userId: '01GCDNAV6270SMVQBEAQ90VY4G',
							patient: {
								pwId: null,
								pwIdUrl: null,
								createdAt: '2022-09-08T04:22:06.889Z',
								patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
								updatedAt: '2022-09-08T04:22:06.889Z',
								pwIdStatus: 'WAITING_APPROVAL',
							},
							lastName: 'Rahmadi',
							nickName: 'Iki',
							province: null,
							userType: 'customer',
							birthdate: '1999-03-11',
							createdAt: '2022-09-08T04:22:06.787Z',
							firstName: 'Rizki',
							updatedAt: '2022-09-08T07:10:14.649Z',
							middleName: null,
							profilePic: null,
							isEmailVerified: false,
							isPhoneVerified: true,
						},
						meta: null,
						expiredAt: '2022-09-09T11:59:10.754Z',
						createdAt: '2022-09-08T07:59:10.755Z',
						updatedAt: '2022-09-08T08:00:02.611Z',
						paymentDetails: [
							{
								paymentDetailId: '2d1a7310-dd0b-4b5e-8e35-64ec51ba8f24',
								paymentId: 'c1aaf000-89e5-406a-921a-991620a834ed',
								paymentType: 'BASE_PRICE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 500,
								status: 'PAID_FULL',
								meta: {
									consultationFee: 500,
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-09T11:59:10.754Z',
								createdAt: '2022-09-08T07:59:10.829Z',
								updatedAt: '2022-09-08T07:59:10.829Z',
							},
							{
								paymentDetailId: '1240c9da-8936-46c5-bc1c-6aef38e9f71e',
								paymentId: 'c1aaf000-89e5-406a-921a-991620a834ed',
								paymentType: 'PATIENT_TRANSACTION_FEE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 75,
								status: 'PAID_FULL',
								meta: {
									key: 'PATIENT_TRANSACTION_FEE',
									value: '5%+50',
									configId: 'PATIENT_TRANSACTION_FEE',
									createdAt: '2022-09-08T06:08:45.373Z',
									updatedAt: '2022-09-08T06:08:45.373Z',
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-09T11:59:10.754Z',
								createdAt: '2022-09-08T07:59:10.833Z',
								updatedAt: '2022-09-08T07:59:10.833Z',
							},
							{
								paymentDetailId: '71372806-c5bc-4cd4-9b45-35d91be1fe4b',
								paymentId: 'c1aaf000-89e5-406a-921a-991620a834ed',
								paymentType: 'PAYMENT_GATEWAY',
								paymentMethod: 'EWALLET',
								paymentVendorType: 'PAYMONGO',
								paymentVendorId: 'GCASH',
								externalId: null,
								totalPay: 575,
								status: 'PAID_FULL',
								meta: {
									data: {
										id: 'evt_nbrZLtxw7UJTG3r19AJ8qRqo',
										type: 'event',
										attributes: {
											data: {
												id: 'pay_5zWbu7RcUY8zCLmSjph1pyBM',
												type: 'payment',
												attributes: {
													fee: 1438,
													taxes: [],
													amount: 57500,
													origin: 'api',
													payout: null,
													source: {
														id: 'src_TVipwkQ4qEyP9ipPsE14yNDi',
														type: 'gcash',
													},
													status: 'paid',
													billing: null,
													paid_at: 1662623981,
													refunds: [],
													currency: 'PHP',
													disputed: false,
													livemode: false,
													metadata: {
														paymentId: 'c1aaf000-89e5-406a-921a-991620a834ed',
													},
													access_url: null,
													created_at: 1662623981,
													net_amount: 56062,
													tax_amount: null,
													updated_at: 1662623981,
													description: null,
													available_at: 1662973200,
													payment_intent_id: null,
													statement_descriptor: 'PAYMONGO',
													balance_transaction_id:
														'bal_txn_1PYsU1gxwXnn3xFpYouzNLxK',
													external_reference_number: null,
												},
											},
											type: 'payment.paid',
											livemode: false,
											created_at: 1662623981,
											updated_at: 1662623981,
											previous_data: {},
										},
									},
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-09T11:59:10.754Z',
								createdAt: '2022-09-08T07:59:10.826Z',
								updatedAt: '2022-09-08T08:00:02.585Z',
							},
						],
						paymentDetailsPartners: [
							{
								paymentDetailId: '30d63b96-c614-4627-a5f0-b02cd5cb0001',
								paymentId: 'c1aaf000-89e5-406a-921a-991620a834ed',
								paymentType: 'FINAL_PAYMENT',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 483,
								status: 'PAID_FULL',
								meta: {},
								type: 'PAYMENT',
								expiredAt: '2022-09-09T11:59:10.754Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-08T07:59:10.839Z',
								updatedAt: '2022-09-08T07:59:10.839Z',
							},
							{
								paymentDetailId: '9dfd3bdc-9afb-4fb1-b494-88ec4145e7b0',
								paymentId: 'c1aaf000-89e5-406a-921a-991620a834ed',
								paymentType: 'DOCTOR_TRANSACTION_FEE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 17,
								status: 'PAID_FULL',
								meta: {
									key: 'DOCTOR_TRANSACTION_FEE',
									value: '3%',
									configId: 'DOCTOR_TRANSACTION_FEE',
									createdAt: '2022-09-08T06:08:45.373Z',
									updatedAt: '2022-09-08T06:08:45.373Z',
								},
								type: 'DISCOUNT',
								expiredAt: '2022-09-09T11:59:10.754Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-08T07:59:10.839Z',
								updatedAt: '2022-09-08T07:59:10.839Z',
							},
							{
								paymentDetailId: '767034c6-7735-49e0-8efe-a8fc96fbb85c',
								paymentId: 'c1aaf000-89e5-406a-921a-991620a834ed',
								paymentType: 'DOCTOR_COMMISSION_FEE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 0,
								status: 'PAID_FULL',
								meta: {
									key: 'DOCTOR_COMMISSION_FEE',
									value: '',
									configId: 'DOCTOR_COMMISSION_FEE',
									createdAt: '2022-09-08T07:59:04.588Z',
									updatedAt: '2022-09-08T07:59:04.588Z',
								},
								type: 'DISCOUNT',
								expiredAt: '2022-09-09T11:59:10.754Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-08T07:59:10.839Z',
								updatedAt: '2022-09-08T07:59:10.839Z',
							},
							{
								paymentDetailId: '8ac04b6f-3630-463d-b791-01ae9ba92bcf',
								paymentId: 'c1aaf000-89e5-406a-921a-991620a834ed',
								paymentType: 'PATIENT_PWD_DISCOUNT',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 0,
								status: 'PAID_FULL',
								meta: {},
								type: 'DISCOUNT',
								expiredAt: '2022-09-09T11:59:10.754Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-08T07:59:10.839Z',
								updatedAt: '2022-09-08T07:59:10.839Z',
							},
							{
								paymentDetailId: '13432042-5a68-44d7-b721-f81d8bcddacd',
								paymentId: 'c1aaf000-89e5-406a-921a-991620a834ed',
								paymentType: 'BASE_PRICE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 500,
								status: 'PAID_FULL',
								meta: {},
								type: 'PAYMENT',
								expiredAt: '2022-09-09T11:59:10.754Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-08T07:59:10.839Z',
								updatedAt: '2022-09-08T07:59:10.839Z',
							},
						],
					},
				},
			},
		],
		myRole: 'patient',
	},
	{
		name: 'appointment',
		data: [
			{
				name: 'Rizki Rahmadi',
				date: '2022-09-22',
				desc: 'Maag',
				reason: 'Maag',
				startTime: '18:00',
				endTime: '18:30',
				detailDoctor: {
					name: 'Aditya Arya Tusan',
					title:
						'Psychiatry, Cardiology, Family Medicine, Critical Care (Intensivist)',
					age: 22,
					ageString: '22 yo',
				},
				raw: {
					transactionConsultationId: 'd529e10f-25ba-46a7-b5e7-0c3d20836460',
					doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
					patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
					patientInRelationId: null,
					partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
					consultationType: 'FACE_TO_FACE_CONSULTATION',
					scheduledStartAt: '2022-09-22T11:00:00.000Z',
					scheduledEndAt: '2022-09-22T11:30:00.000Z',
					isCanExtend: null,
					doctorJoinAt: null,
					doctorEndAt: null,
					patientJoinAt: null,
					patientEndAt: null,
					transactionStatus: 'APPROVED',
					lastUpdatedByUser: {
						action: 'login_success',
						userId: '01GCDSTJ8FWEPN6H6MTQBR206D',
						payload: {
							phoneNumber: '6382345',
						},
						userType: 'customer',
						sessionId:
							'576095c13d928bdaf03c1ae372c6e5835b5cbf21bf963c5165783d27bada67b9',
					},
					description: null,
					place: null,
					link: null,
					metaPatient: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						updatedAt: '2022-09-08T07:10:14.649Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					metaPatientInRelation: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
						updatedAt: '2022-09-08T07:10:14.649Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					metaDoctor: {
						user: {
							city: null,
							name: 'Aditya Arya Tusan',
							email: null,
							phone: '6382345',
							gender: 'male',
							status: 'active',
							userId: '01GCDSTJ8FWEPN6H6MTQBR206D',
							lastName: 'Tusan',
							nickName: 'Aditya',
							province: null,
							userType: 'customer',
							birthdate: '1999-11-30',
							createdAt: '2022-09-08T05:40:36.240Z',
							firstName: 'Aditya',
							updatedAt: '2022-09-08T08:31:31.839Z',
							middleName: 'Arya',
							profilePic: null,
						},
						title: 'SKG',
						doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
						partners: [
							{
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:01:45.057Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:15.723Z',
								pricePerSession: 500,
							},
							{
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:01:45.057Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:01:59.322Z',
								pricePerSession: 500,
							},
							{
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:53:12.891Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:20.032Z',
								pricePerSession: 500,
							},
							{
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:53:55.441Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:53:55.441Z',
								pricePerSession: null,
							},
						],
						createdAt: '2022-09-08T05:47:23.761Z',
						kycStatus: 'APPROVED',
						prcNumber: '',
						ptrNumber: '',
						schedules: [
							{
								endAt: '2022-01-01T15:00:00.970Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.841Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:08.456Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:08.456Z',
								scheduleId: 'a31ae4ad-a007-4a79-a76f-0e4b46321701',
							},
							{
								endAt: '2022-01-01T15:00:00.776Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.973Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:29.740Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:29.740Z',
								scheduleId: '54f7f208-0156-4a7b-abab-01d5a4ab58aa',
							},
							{
								endAt: '2022-01-01T15:00:00.351Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.695Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:53.389Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:53.389Z',
								scheduleId: '8a73f804-38a2-4c63-90d6-0274230d2926',
							},
							{
								endAt: '2022-01-01T15:00:00.079Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.689Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:00.869Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:00.869Z',
								scheduleId: 'f9642816-0af0-4b81-aec1-f6d84f0e004f',
							},
							{
								endAt: '2022-01-01T15:00:00.488Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.787Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:18.728Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:18.728Z',
								scheduleId: '4126c29f-9586-498a-b58b-03f45d8f5949',
							},
							{
								endAt: '2022-01-01T15:00:00.048Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.494Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:27.765Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:27.765Z',
								scheduleId: '4f7a2e8e-4afd-41bf-88f2-59fbcf1a7d1b',
							},
							{
								endAt: '2022-01-01T15:00:00.194Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.066Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:28.709Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:28.709Z',
								scheduleId: 'b59890d5-44a0-447b-9f31-ca137e27779a',
							},
							{
								endAt: '2022-01-01T15:00:00.814Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.197Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:36.439Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:36.439Z',
								scheduleId: 'cb76674b-12ef-4dbc-98aa-99edbb61bc25',
							},
							{
								endAt: '2022-01-01T15:00:00.683Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.952Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:44.409Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:44.409Z',
								scheduleId: '4a3b9e33-2601-4228-903e-79e2c1a171c0',
							},
							{
								endAt: '2022-01-01T15:00:00.303Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.810Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:52.482Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:52.482Z',
								scheduleId: '3459a911-20d6-4179-8873-115af073b8df',
							},
							{
								endAt: '2022-01-01T15:00:00.632Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.098Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:00.731Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:00.731Z',
								scheduleId: '53cbe7a8-8254-4b86-8d49-9153906af659',
							},
							{
								endAt: '2022-01-01T15:00:00.267Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.342Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:10.158Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:10.158Z',
								scheduleId: '368e3dcb-c87e-4382-93b5-f92124af68a0',
							},
							{
								endAt: '2022-01-01T15:00:00.511Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.547Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:23.678Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:23.678Z',
								scheduleId: '506cd274-9f8e-4296-a480-7949bde125e4',
							},
							{
								endAt: '2022-01-01T15:00:00.418Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.377Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:17.220Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:17.220Z',
								scheduleId: '4f2f93d0-8d2a-4a61-9742-db0e509dec5e',
							},
							{
								endAt: '2022-01-01T15:00:00.198Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.805Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:25.263Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:25.263Z',
								scheduleId: '5415d0c1-6a9d-440e-9df9-fc7166caab12',
							},
							{
								endAt: '2022-01-01T15:00:00.972Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.496Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:32.657Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:32.657Z',
								scheduleId: '05ac2913-2d60-4e02-b4b5-340448bab3ac',
							},
							{
								endAt: '2022-01-01T15:00:00.610Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.873Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:40.316Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:40.316Z',
								scheduleId: 'b4b36f6b-1c4b-4886-8645-ddcbd41671cc',
							},
							{
								endAt: '2022-01-01T15:00:00.149Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.634Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:48.087Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:48.087Z',
								scheduleId: '188cf832-1275-49bd-885f-240e390b8e6f',
							},
							{
								endAt: '2022-01-01T15:00:00.118Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.566Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:57.046Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:57.046Z',
								scheduleId: 'c703d009-2840-4730-a9d1-e4ad1dc6a917',
							},
							{
								endAt: '2022-01-01T15:00:00.184Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.850Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:05.067Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:03:05.067Z',
								scheduleId: 'c12eb042-bd87-41b1-9c12-255681814c02',
							},
							{
								endAt: '2022-01-01T15:00:00.691Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.931Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:23.318Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:23.318Z',
								scheduleId: '2ffbdaa9-c727-4de3-ade4-302d22731359',
							},
							{
								endAt: '2022-01-01T15:00:00.512Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.375Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:33.507Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:33.507Z',
								scheduleId: '22a089d9-af75-45cc-927e-d0845099ef05',
							},
							{
								endAt: '2022-01-01T15:00:00.268Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.661Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:43.515Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:43.515Z',
								scheduleId: '9d0fb904-4e28-42af-a4d4-060ad52e704b',
							},
							{
								endAt: '2022-01-01T15:00:00.388Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.083Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:54.009Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:54.009Z',
								scheduleId: '3cbb04c9-e894-45b3-9ab6-4cd9ca68c3dd',
							},
							{
								endAt: '2022-01-01T15:00:00.833Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.384Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:05.486Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:05.486Z',
								scheduleId: 'fc14c9b9-4b89-4667-af32-6c27bd1147d4',
							},
							{
								endAt: '2022-01-01T15:00:00.505Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.098Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:13.020Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:13.020Z',
								scheduleId: '1a17c05d-ef4b-4f58-b4b0-a03ec697dac5',
							},
							{
								endAt: '2022-01-01T15:00:00.599Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.683Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:21.254Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:21.254Z',
								scheduleId: 'f096a199-fe82-40c5-861c-cab046ddbdd7',
							},
							{
								endAt: '2022-01-01T15:00:00.376Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.414Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-14T02:27:52.427Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-14T02:27:52.427Z',
								scheduleId: '42d7db46-19a3-47d0-8af5-cf850aa25ca7',
							},
						],
						startWork: null,
						tinNumber: '',
						updatedAt: '2022-09-08T08:31:30.898Z',
						educations: [],
						experience: 2,
						orderScore: 2,
						specialists: [
							{
								icon: 'psychiatry',
								level: 1,
								createdAt: '2022-09-08T03:16:01.498Z',
								updatedAt: '2022-09-08T03:16:01.498Z',
								specialistId: '87bcd861-d18d-4e00-adb7-c913ba60f435',
								specialistName: 'Psychiatry',
								parentSpecialistId: 'dafe825a-cc64-4b00-8d7d-e7ed8adab9ca',
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									specialistId: '87bcd861-d18d-4e00-adb7-c913ba60f435',
								},
							},
							{
								icon: 'family_medicine',
								level: 2,
								createdAt: '2022-09-08T03:16:01.512Z',
								updatedAt: '2022-09-08T03:16:01.512Z',
								specialistId: '9baad78f-2237-4933-9c77-811cc886b878',
								specialistName: 'Cardiology',
								parentSpecialistId: '55df1bba-f1e7-4e57-982d-084327ef4aac',
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									specialistId: '9baad78f-2237-4933-9c77-811cc886b878',
								},
							},
							{
								icon: 'family_medicine',
								level: 1,
								createdAt: '2022-09-08T03:16:01.498Z',
								updatedAt: '2022-09-08T03:16:01.498Z',
								specialistId: '55df1bba-f1e7-4e57-982d-084327ef4aac',
								specialistName: 'Family Medicine',
								parentSpecialistId: 'dafe825a-cc64-4b00-8d7d-e7ed8adab9ca',
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									specialistId: '55df1bba-f1e7-4e57-982d-084327ef4aac',
								},
							},
							{
								icon: 'critical_care_intensivist',
								level: 2,
								createdAt: '2022-09-08T03:16:01.512Z',
								updatedAt: '2022-09-08T03:16:01.512Z',
								specialistId: '22fc908d-8b1e-4bd9-b7f0-7f4072c70d7f',
								specialistName: 'Critical Care (Intensivist)',
								parentSpecialistId: '87bcd861-d18d-4e00-adb7-c913ba60f435',
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									specialistId: '22fc908d-8b1e-4bd9-b7f0-7f4072c70d7f',
								},
							},
						],
						certificates: [],
						nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
						otherSpecialist: '',
						signatureImageUrl:
							'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
						schedulesInPartners: [
							{
								endAt: '2022-01-01T15:00:00.194Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.066Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:28.709Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:28.709Z',
								scheduleId: 'b59890d5-44a0-447b-9f31-ca137e27779a',
							},
							{
								endAt: '2022-01-01T15:00:00.388Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.083Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:54.009Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:54.009Z',
								scheduleId: '3cbb04c9-e894-45b3-9ab6-4cd9ca68c3dd',
							},
							{
								endAt: '2022-01-01T15:00:00.632Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.098Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:00.731Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:00.731Z',
								scheduleId: '53cbe7a8-8254-4b86-8d49-9153906af659',
							},
							{
								endAt: '2022-01-01T15:00:00.505Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.098Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:13.020Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:13.020Z',
								scheduleId: '1a17c05d-ef4b-4f58-b4b0-a03ec697dac5',
							},
							{
								endAt: '2022-01-01T15:00:00.814Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.197Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:36.439Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:36.439Z',
								scheduleId: 'cb76674b-12ef-4dbc-98aa-99edbb61bc25',
							},
							{
								endAt: '2022-01-01T15:00:00.267Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.342Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:10.158Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:10.158Z',
								scheduleId: '368e3dcb-c87e-4382-93b5-f92124af68a0',
							},
							{
								endAt: '2022-01-01T15:00:00.512Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.375Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:33.507Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:33.507Z',
								scheduleId: '22a089d9-af75-45cc-927e-d0845099ef05',
							},
							{
								endAt: '2022-01-01T15:00:00.418Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.377Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:17.220Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:17.220Z',
								scheduleId: '4f2f93d0-8d2a-4a61-9742-db0e509dec5e',
							},
							{
								endAt: '2022-01-01T15:00:00.833Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.384Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:05.486Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:05.486Z',
								scheduleId: 'fc14c9b9-4b89-4667-af32-6c27bd1147d4',
							},
							{
								endAt: '2022-01-01T15:00:00.376Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.414Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-14T02:27:52.427Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-14T02:27:52.427Z',
								scheduleId: '42d7db46-19a3-47d0-8af5-cf850aa25ca7',
							},
							{
								endAt: '2022-01-01T15:00:00.048Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.494Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:27.765Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:27.765Z',
								scheduleId: '4f7a2e8e-4afd-41bf-88f2-59fbcf1a7d1b',
							},
							{
								endAt: '2022-01-01T15:00:00.972Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.496Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:32.657Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:32.657Z',
								scheduleId: '05ac2913-2d60-4e02-b4b5-340448bab3ac',
							},
							{
								endAt: '2022-01-01T15:00:00.511Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.547Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:23.678Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:23.678Z',
								scheduleId: '506cd274-9f8e-4296-a480-7949bde125e4',
							},
							{
								endAt: '2022-01-01T15:00:00.118Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.566Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:57.046Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:57.046Z',
								scheduleId: 'c703d009-2840-4730-a9d1-e4ad1dc6a917',
							},
							{
								endAt: '2022-01-01T15:00:00.149Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.634Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:48.087Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:48.087Z',
								scheduleId: '188cf832-1275-49bd-885f-240e390b8e6f',
							},
							{
								endAt: '2022-01-01T15:00:00.268Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.661Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:43.515Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:43.515Z',
								scheduleId: '9d0fb904-4e28-42af-a4d4-060ad52e704b',
							},
							{
								endAt: '2022-01-01T15:00:00.599Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.683Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:21.254Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:21.254Z',
								scheduleId: 'f096a199-fe82-40c5-861c-cab046ddbdd7',
							},
							{
								endAt: '2022-01-01T15:00:00.079Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.689Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:00.869Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:00.869Z',
								scheduleId: 'f9642816-0af0-4b81-aec1-f6d84f0e004f',
							},
							{
								endAt: '2022-01-01T15:00:00.351Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.695Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:53.389Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:53.389Z',
								scheduleId: '8a73f804-38a2-4c63-90d6-0274230d2926',
							},
							{
								endAt: '2022-01-01T15:00:00.488Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.787Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:18.728Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:18.728Z',
								scheduleId: '4126c29f-9586-498a-b58b-03f45d8f5949',
							},
							{
								endAt: '2022-01-01T15:00:00.198Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.805Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:25.263Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:25.263Z',
								scheduleId: '5415d0c1-6a9d-440e-9df9-fc7166caab12',
							},
							{
								endAt: '2022-01-01T15:00:00.303Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.810Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:52.482Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:52.482Z',
								scheduleId: '3459a911-20d6-4179-8873-115af073b8df',
							},
							{
								endAt: '2022-01-01T15:00:00.970Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.841Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:08.456Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:08.456Z',
								scheduleId: 'a31ae4ad-a007-4a79-a76f-0e4b46321701',
							},
							{
								endAt: '2022-01-01T15:00:00.184Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.850Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:05.067Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:03:05.067Z',
								scheduleId: 'c12eb042-bd87-41b1-9c12-255681814c02',
							},
							{
								endAt: '2022-01-01T15:00:00.610Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.873Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:40.316Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:40.316Z',
								scheduleId: 'b4b36f6b-1c4b-4886-8645-ddcbd41671cc',
							},
							{
								endAt: '2022-01-01T15:00:00.691Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.931Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:23.318Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:23.318Z',
								scheduleId: '2ffbdaa9-c727-4de3-ade4-302d22731359',
							},
							{
								endAt: '2022-01-01T15:00:00.683Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.952Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:44.409Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:44.409Z',
								scheduleId: '4a3b9e33-2601-4228-903e-79e2c1a171c0',
							},
							{
								endAt: '2022-01-01T15:00:00.776Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.973Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:29.740Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:29.740Z',
								scheduleId: '54f7f208-0156-4a7b-abab-01d5a4ab58aa',
							},
						],
						specialityCertificate: null,
						availableConsultationTypes: [
							'TELEMEDICINE_VIDEO_CHAT_CONSULTATION',
							'TELEMEDICINE_CHAT_CONSULTATION',
							'CONSULT_NOW_VIDEO_CHAT_CONSULTATION',
							'CONSULT_NOW_CHAT_CONSULTATION',
							'FACE_TO_FACE_CONSULTATION',
						],
					},
					metaPartner: {
						email: null,
						phone: '08234567890',
						address:
							'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
						createdAt: '2022-09-08T07:01:21.769Z',
						partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
						updatedAt: '2022-09-08T07:05:30.929Z',
						geolocation: {
							latitude: 0,
							longitude: 0,
						},
						isPublished: true,
						partnerName: 'Klinik PPK 1 Gebang',
						partnerType: 'CLINIC',
						imageLogoURL: null,
						imageCoverURL:
							'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
						isConfirmedPartner: true,
					},
					createdByUserId: '01GCDNAV6270SMVQBEAQ90VY4G',
					metaCreatedByUser: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						updatedAt: '2022-09-08T07:10:14.649Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					createdAt: '2022-09-22T10:09:16.494Z',
					updatedAt: '2022-09-22T10:11:14.627Z',
					medicalRecord: {
						medicalRecordId: '0c3cd0b8-7819-4df9-80a4-8f58784d0c21',
						doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
						patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patientInRelationId: null,
						reasonOfConsultation: 'Maag',
						historyPhysicalFindings: null,
						diagnosis: null,
						planForManagement: null,
						transactionConsultationId: 'd529e10f-25ba-46a7-b5e7-0c3d20836460',
						uploadedFile: null,
						createdAt: '2022-09-22T10:09:16.510Z',
						updatedAt: '2022-09-22T10:09:16.510Z',
					},
					history: [
						{
							historyId: '2efb1498-b046-4e3d-9250-d7f055e85f58',
							transactionConsultationId: 'd529e10f-25ba-46a7-b5e7-0c3d20836460',
							description: 'Update transaction status',
							newData: {
								transactionStatus: 'APPROVED',
							},
							createdByUserId: '01GCDSTJ8FWEPN6H6MTQBR206D',
							metaCreatedByUser: {
								action: 'login_success',
								userId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								payload: {
									phoneNumber: '6382345',
								},
								userType: 'customer',
								sessionId:
									'576095c13d928bdaf03c1ae372c6e5835b5cbf21bf963c5165783d27bada67b9',
							},
							createdAt: '2022-09-22T10:11:14.636Z',
							updatedAt: '2022-09-22T10:11:14.636Z',
						},
						{
							historyId: 'dcd5c9c5-d72c-468b-802c-1fcc3564c14b',
							transactionConsultationId: 'd529e10f-25ba-46a7-b5e7-0c3d20836460',
							description: 'Update transaction status',
							newData: {
								transactionStatus: 'WAITING_APPROVAL',
							},
							createdByUserId: '01GCDNAV6270SMVQBEAQ90VY4G',
							metaCreatedByUser: {
								city: null,
								name: 'Rizki Rahmadi',
								email: null,
								phone: '63125',
								doctor: null,
								gender: 'male',
								status: 'active',
								userId: '01GCDNAV6270SMVQBEAQ90VY4G',
								patient: {
									pwId: null,
									pwIdUrl: null,
									createdAt: '2022-09-08T04:22:06.889Z',
									patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
									updatedAt: '2022-09-08T04:22:06.889Z',
									pwIdStatus: 'WAITING_APPROVAL',
								},
								lastName: 'Rahmadi',
								nickName: 'Iki',
								province: null,
								userType: 'customer',
								birthdate: '1999-03-11',
								createdAt: '2022-09-08T04:22:06.787Z',
								firstName: 'Rizki',
								updatedAt: '2022-09-08T07:10:14.649Z',
								middleName: null,
								profilePic: null,
								isEmailVerified: false,
								isPhoneVerified: true,
							},
							createdAt: '2022-09-22T10:09:49.048Z',
							updatedAt: '2022-09-22T10:09:49.048Z',
						},
					],
					payment: {
						paymentId: '3f0749f9-b0dd-48c7-9876-d4db162783cf',
						invoiceId: 'CI/20220922/5W8',
						transactionId: 'd529e10f-25ba-46a7-b5e7-0c3d20836460',
						transactionType: 'IN_PERSON',
						totalNeedToPay: 575,
						paymentStatus: 'PAID_FULL',
						buyerId: '01GCDNAV6270SMVQBEAQ90VY4G',
						metaBuyerDetail: {
							city: null,
							name: 'Rizki Rahmadi',
							email: null,
							phone: '63125',
							doctor: null,
							gender: 'male',
							status: 'active',
							userId: '01GCDNAV6270SMVQBEAQ90VY4G',
							patient: {
								pwId: null,
								pwIdUrl: null,
								createdAt: '2022-09-08T04:22:06.889Z',
								patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
								updatedAt: '2022-09-08T04:22:06.889Z',
								pwIdStatus: 'WAITING_APPROVAL',
							},
							lastName: 'Rahmadi',
							nickName: 'Iki',
							province: null,
							userType: 'customer',
							birthdate: '1999-03-11',
							createdAt: '2022-09-08T04:22:06.787Z',
							firstName: 'Rizki',
							updatedAt: '2022-09-08T07:10:14.649Z',
							middleName: null,
							profilePic: null,
							isEmailVerified: false,
							isPhoneVerified: true,
						},
						meta: null,
						expiredAt: '2022-09-23T14:09:16.675Z',
						createdAt: '2022-09-22T10:09:16.676Z',
						updatedAt: '2022-09-22T10:09:48.990Z',
						paymentDetails: [
							{
								paymentDetailId: '05834554-5896-4cf4-b6ca-9e02a0470727',
								paymentId: '3f0749f9-b0dd-48c7-9876-d4db162783cf',
								paymentType: 'BASE_PRICE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 500,
								status: 'PAID_FULL',
								meta: {
									consultationFee: 500,
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-23T14:09:16.675Z',
								createdAt: '2022-09-22T10:09:16.734Z',
								updatedAt: '2022-09-22T10:09:16.734Z',
							},
							{
								paymentDetailId: 'b1f72e64-1741-440d-810f-4c52b18d2f96',
								paymentId: '3f0749f9-b0dd-48c7-9876-d4db162783cf',
								paymentType: 'PATIENT_TRANSACTION_FEE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 75,
								status: 'PAID_FULL',
								meta: {
									key: 'PATIENT_TRANSACTION_FEE',
									value: '5%+50',
									configId: 'PATIENT_TRANSACTION_FEE',
									createdAt: '2022-09-08T06:08:45.373Z',
									updatedAt: '2022-09-08T06:08:45.373Z',
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-23T14:09:16.675Z',
								createdAt: '2022-09-22T10:09:16.735Z',
								updatedAt: '2022-09-22T10:09:16.735Z',
							},
							{
								paymentDetailId: '0a087909-7054-46c2-8ec3-bf1f0acefea6',
								paymentId: '3f0749f9-b0dd-48c7-9876-d4db162783cf',
								paymentType: 'PAYMENT_GATEWAY',
								paymentMethod: 'EWALLET',
								paymentVendorType: 'PAYMONGO',
								paymentVendorId: 'GCASH',
								externalId: null,
								totalPay: 575,
								status: 'PAID_FULL',
								meta: {
									data: {
										id: 'evt_TLnTXX1E1kyZxYXXY2mpH9dh',
										type: 'event',
										attributes: {
											data: {
												id: 'pay_CLiELd2Hkrg6eYPbhEUXdVsK',
												type: 'payment',
												attributes: {
													fee: 1438,
													taxes: [],
													amount: 57500,
													origin: 'api',
													payout: null,
													source: {
														id: 'src_3SRhxwdaNKfQ54N7ZEDBZtDW',
														type: 'gcash',
													},
													status: 'paid',
													billing: null,
													paid_at: 1663841379,
													refunds: [],
													currency: 'PHP',
													disputed: false,
													livemode: false,
													metadata: {
														paymentId: '3f0749f9-b0dd-48c7-9876-d4db162783cf',
													},
													access_url: null,
													created_at: 1663841379,
													net_amount: 56062,
													tax_amount: null,
													updated_at: 1663841379,
													description: null,
													available_at: 1664269200,
													payment_intent_id: null,
													statement_descriptor: 'PAYMONGO',
													balance_transaction_id:
														'bal_txn_Fd5yByTSL68wirKKEPBsqr8q',
													external_reference_number: null,
												},
											},
											type: 'payment.paid',
											livemode: false,
											created_at: 1663841379,
											updated_at: 1663841379,
											previous_data: {},
										},
									},
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-23T14:09:16.675Z',
								createdAt: '2022-09-22T10:09:16.732Z',
								updatedAt: '2022-09-22T10:09:48.978Z',
							},
						],
						paymentDetailsPartners: [
							{
								paymentDetailId: 'ce9830f3-1777-4da9-8152-bb0ea2f2bc8e',
								paymentId: '3f0749f9-b0dd-48c7-9876-d4db162783cf',
								paymentType: 'FINAL_PAYMENT',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 473,
								status: 'PAID_FULL',
								meta: {},
								type: 'PAYMENT',
								expiredAt: '2022-09-23T14:09:16.675Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-22T10:09:16.740Z',
								updatedAt: '2022-09-22T10:09:16.740Z',
							},
							{
								paymentDetailId: '688c0251-4129-4870-a139-634e7c2837a0',
								paymentId: '3f0749f9-b0dd-48c7-9876-d4db162783cf',
								paymentType: 'DOCTOR_TRANSACTION_FEE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 17,
								status: 'PAID_FULL',
								meta: {
									key: 'DOCTOR_TRANSACTION_FEE',
									value: '3%',
									configId: 'DOCTOR_TRANSACTION_FEE',
									createdAt: '2022-09-08T06:08:45.373Z',
									updatedAt: '2022-09-08T06:08:45.373Z',
								},
								type: 'DISCOUNT',
								expiredAt: '2022-09-23T14:09:16.675Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-22T10:09:16.740Z',
								updatedAt: '2022-09-22T10:09:16.740Z',
							},
							{
								paymentDetailId: '49512c77-7b0e-426f-aff9-dbb71ee18d77',
								paymentId: '3f0749f9-b0dd-48c7-9876-d4db162783cf',
								paymentType: 'DOCTOR_COMMISSION_FEE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 10,
								status: 'PAID_FULL',
								meta: {
									key: 'DOCTOR_COMMISSION_FEE',
									value: '2%',
									configId: 'DOCTOR_COMMISSION_FEE',
									createdAt: '2022-09-08T09:27:39.090Z',
									updatedAt: '2022-09-08T09:27:39.090Z',
								},
								type: 'DISCOUNT',
								expiredAt: '2022-09-23T14:09:16.675Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-22T10:09:16.740Z',
								updatedAt: '2022-09-22T10:09:16.740Z',
							},
							{
								paymentDetailId: '920fcd0d-54ea-4047-90f9-7e8072fc6569',
								paymentId: '3f0749f9-b0dd-48c7-9876-d4db162783cf',
								paymentType: 'PATIENT_PWD_DISCOUNT',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 0,
								status: 'PAID_FULL',
								meta: {},
								type: 'DISCOUNT',
								expiredAt: '2022-09-23T14:09:16.675Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-22T10:09:16.740Z',
								updatedAt: '2022-09-22T10:09:16.740Z',
							},
							{
								paymentDetailId: '1cae85ab-4009-462c-8392-e042f3fac6ab',
								paymentId: '3f0749f9-b0dd-48c7-9876-d4db162783cf',
								paymentType: 'BASE_PRICE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 500,
								status: 'PAID_FULL',
								meta: {},
								type: 'PAYMENT',
								expiredAt: '2022-09-23T14:09:16.675Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-22T10:09:16.740Z',
								updatedAt: '2022-09-22T10:09:16.740Z',
							},
						],
					},
				},
			},
			{
				name: 'Rizki Rahmadi',
				date: '2022-09-20',
				desc: 'Maag',
				reason: 'Maag',
				startTime: '13:00',
				endTime: '13:30',
				detailDoctor: {
					name: 'Aditya Arya Tusan',
					title:
						'Psychiatry, Cardiology, Family Medicine, Critical Care (Intensivist)',
					age: 22,
					ageString: '22 yo',
				},
				raw: {
					transactionConsultationId: '2cd70412-e78c-46fc-9930-82f0d09f7540',
					doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
					patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
					patientInRelationId: null,
					partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
					consultationType: 'FACE_TO_FACE_CONSULTATION',
					scheduledStartAt: '2022-09-20T06:00:00.000Z',
					scheduledEndAt: '2022-09-20T06:30:00.000Z',
					isCanExtend: null,
					doctorJoinAt: null,
					doctorEndAt: null,
					patientJoinAt: null,
					patientEndAt: null,
					transactionStatus: 'APPROVED',
					lastUpdatedByUser: {
						action: 'login_success',
						userId: '01GCDSTJ8FWEPN6H6MTQBR206D',
						payload: {
							phoneNumber: '6382345',
						},
						userType: 'customer',
						sessionId:
							'ad383d01080234254147d58a70ad2a07e8ed3ab842585e7d56f4b44fb005bd5a',
					},
					description: null,
					place: null,
					link: null,
					metaPatient: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						updatedAt: '2022-09-08T07:10:14.649Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					metaPatientInRelation: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
						updatedAt: '2022-09-08T07:10:14.649Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					metaDoctor: {
						user: {
							city: null,
							name: 'Aditya Arya Tusan',
							email: null,
							phone: '6382345',
							gender: 'male',
							status: 'active',
							userId: '01GCDSTJ8FWEPN6H6MTQBR206D',
							lastName: 'Tusan',
							nickName: 'Aditya',
							province: null,
							userType: 'customer',
							birthdate: '1999-11-30',
							createdAt: '2022-09-08T05:40:36.240Z',
							firstName: 'Aditya',
							updatedAt: '2022-09-08T08:31:31.839Z',
							middleName: 'Arya',
							profilePic: null,
						},
						title: 'SKG',
						doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
						partners: [
							{
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:01:45.057Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:15.723Z',
								pricePerSession: 500,
							},
							{
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:01:45.057Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:01:59.322Z',
								pricePerSession: 500,
							},
							{
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:53:12.891Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:20.032Z',
								pricePerSession: 500,
							},
							{
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:53:55.441Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:53:55.441Z',
								pricePerSession: null,
							},
						],
						createdAt: '2022-09-08T05:47:23.761Z',
						kycStatus: 'APPROVED',
						prcNumber: '',
						ptrNumber: '',
						schedules: [
							{
								endAt: '2022-01-01T15:00:00.970Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.841Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:08.456Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:08.456Z',
								scheduleId: 'a31ae4ad-a007-4a79-a76f-0e4b46321701',
							},
							{
								endAt: '2022-01-01T15:00:00.776Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.973Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:29.740Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:29.740Z',
								scheduleId: '54f7f208-0156-4a7b-abab-01d5a4ab58aa',
							},
							{
								endAt: '2022-01-01T15:00:00.351Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.695Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:53.389Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:53.389Z',
								scheduleId: '8a73f804-38a2-4c63-90d6-0274230d2926',
							},
							{
								endAt: '2022-01-01T15:00:00.079Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.689Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:00.869Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:00.869Z',
								scheduleId: 'f9642816-0af0-4b81-aec1-f6d84f0e004f',
							},
							{
								endAt: '2022-01-01T15:00:00.488Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.787Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:18.728Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:18.728Z',
								scheduleId: '4126c29f-9586-498a-b58b-03f45d8f5949',
							},
							{
								endAt: '2022-01-01T15:00:00.048Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.494Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:27.765Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:27.765Z',
								scheduleId: '4f7a2e8e-4afd-41bf-88f2-59fbcf1a7d1b',
							},
							{
								endAt: '2022-01-01T15:00:00.194Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.066Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:28.709Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:28.709Z',
								scheduleId: 'b59890d5-44a0-447b-9f31-ca137e27779a',
							},
							{
								endAt: '2022-01-01T15:00:00.814Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.197Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:36.439Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:36.439Z',
								scheduleId: 'cb76674b-12ef-4dbc-98aa-99edbb61bc25',
							},
							{
								endAt: '2022-01-01T15:00:00.683Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.952Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:44.409Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:44.409Z',
								scheduleId: '4a3b9e33-2601-4228-903e-79e2c1a171c0',
							},
							{
								endAt: '2022-01-01T15:00:00.303Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.810Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:52.482Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:52.482Z',
								scheduleId: '3459a911-20d6-4179-8873-115af073b8df',
							},
							{
								endAt: '2022-01-01T15:00:00.632Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.098Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:00.731Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:00.731Z',
								scheduleId: '53cbe7a8-8254-4b86-8d49-9153906af659',
							},
							{
								endAt: '2022-01-01T15:00:00.267Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.342Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:10.158Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:10.158Z',
								scheduleId: '368e3dcb-c87e-4382-93b5-f92124af68a0',
							},
							{
								endAt: '2022-01-01T15:00:00.511Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.547Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:23.678Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:23.678Z',
								scheduleId: '506cd274-9f8e-4296-a480-7949bde125e4',
							},
							{
								endAt: '2022-01-01T15:00:00.418Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.377Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:17.220Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:17.220Z',
								scheduleId: '4f2f93d0-8d2a-4a61-9742-db0e509dec5e',
							},
							{
								endAt: '2022-01-01T15:00:00.198Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.805Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:25.263Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:25.263Z',
								scheduleId: '5415d0c1-6a9d-440e-9df9-fc7166caab12',
							},
							{
								endAt: '2022-01-01T15:00:00.972Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.496Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:32.657Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:32.657Z',
								scheduleId: '05ac2913-2d60-4e02-b4b5-340448bab3ac',
							},
							{
								endAt: '2022-01-01T15:00:00.610Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.873Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:40.316Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:40.316Z',
								scheduleId: 'b4b36f6b-1c4b-4886-8645-ddcbd41671cc',
							},
							{
								endAt: '2022-01-01T15:00:00.149Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.634Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:48.087Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:48.087Z',
								scheduleId: '188cf832-1275-49bd-885f-240e390b8e6f',
							},
							{
								endAt: '2022-01-01T15:00:00.118Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.566Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:57.046Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:57.046Z',
								scheduleId: 'c703d009-2840-4730-a9d1-e4ad1dc6a917',
							},
							{
								endAt: '2022-01-01T15:00:00.184Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.850Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:05.067Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:03:05.067Z',
								scheduleId: 'c12eb042-bd87-41b1-9c12-255681814c02',
							},
							{
								endAt: '2022-01-01T15:00:00.691Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.931Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:23.318Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:23.318Z',
								scheduleId: '2ffbdaa9-c727-4de3-ade4-302d22731359',
							},
							{
								endAt: '2022-01-01T15:00:00.512Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.375Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:33.507Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:33.507Z',
								scheduleId: '22a089d9-af75-45cc-927e-d0845099ef05',
							},
							{
								endAt: '2022-01-01T15:00:00.268Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.661Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:43.515Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:43.515Z',
								scheduleId: '9d0fb904-4e28-42af-a4d4-060ad52e704b',
							},
							{
								endAt: '2022-01-01T15:00:00.388Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.083Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:54.009Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:54.009Z',
								scheduleId: '3cbb04c9-e894-45b3-9ab6-4cd9ca68c3dd',
							},
							{
								endAt: '2022-01-01T15:00:00.833Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.384Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:05.486Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:05.486Z',
								scheduleId: 'fc14c9b9-4b89-4667-af32-6c27bd1147d4',
							},
							{
								endAt: '2022-01-01T15:00:00.505Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.098Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:13.020Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:13.020Z',
								scheduleId: '1a17c05d-ef4b-4f58-b4b0-a03ec697dac5',
							},
							{
								endAt: '2022-01-01T15:00:00.599Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.683Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:21.254Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:21.254Z',
								scheduleId: 'f096a199-fe82-40c5-861c-cab046ddbdd7',
							},
							{
								endAt: '2022-01-01T15:00:00.376Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.414Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-14T02:27:52.427Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-14T02:27:52.427Z',
								scheduleId: '42d7db46-19a3-47d0-8af5-cf850aa25ca7',
							},
						],
						startWork: null,
						tinNumber: '',
						updatedAt: '2022-09-08T08:31:30.898Z',
						educations: [],
						experience: 2,
						orderScore: 2,
						specialists: [
							{
								icon: 'psychiatry',
								level: 1,
								createdAt: '2022-09-08T03:16:01.498Z',
								updatedAt: '2022-09-08T03:16:01.498Z',
								specialistId: '87bcd861-d18d-4e00-adb7-c913ba60f435',
								specialistName: 'Psychiatry',
								parentSpecialistId: 'dafe825a-cc64-4b00-8d7d-e7ed8adab9ca',
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									specialistId: '87bcd861-d18d-4e00-adb7-c913ba60f435',
								},
							},
							{
								icon: 'family_medicine',
								level: 2,
								createdAt: '2022-09-08T03:16:01.512Z',
								updatedAt: '2022-09-08T03:16:01.512Z',
								specialistId: '9baad78f-2237-4933-9c77-811cc886b878',
								specialistName: 'Cardiology',
								parentSpecialistId: '55df1bba-f1e7-4e57-982d-084327ef4aac',
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									specialistId: '9baad78f-2237-4933-9c77-811cc886b878',
								},
							},
							{
								icon: 'family_medicine',
								level: 1,
								createdAt: '2022-09-08T03:16:01.498Z',
								updatedAt: '2022-09-08T03:16:01.498Z',
								specialistId: '55df1bba-f1e7-4e57-982d-084327ef4aac',
								specialistName: 'Family Medicine',
								parentSpecialistId: 'dafe825a-cc64-4b00-8d7d-e7ed8adab9ca',
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									specialistId: '55df1bba-f1e7-4e57-982d-084327ef4aac',
								},
							},
							{
								icon: 'critical_care_intensivist',
								level: 2,
								createdAt: '2022-09-08T03:16:01.512Z',
								updatedAt: '2022-09-08T03:16:01.512Z',
								specialistId: '22fc908d-8b1e-4bd9-b7f0-7f4072c70d7f',
								specialistName: 'Critical Care (Intensivist)',
								parentSpecialistId: '87bcd861-d18d-4e00-adb7-c913ba60f435',
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									specialistId: '22fc908d-8b1e-4bd9-b7f0-7f4072c70d7f',
								},
							},
						],
						certificates: [],
						nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
						otherSpecialist: '',
						signatureImageUrl:
							'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
						schedulesInPartners: [
							{
								endAt: '2022-01-01T15:00:00.194Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.066Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:28.709Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:28.709Z',
								scheduleId: 'b59890d5-44a0-447b-9f31-ca137e27779a',
							},
							{
								endAt: '2022-01-01T15:00:00.388Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.083Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:54.009Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:54.009Z',
								scheduleId: '3cbb04c9-e894-45b3-9ab6-4cd9ca68c3dd',
							},
							{
								endAt: '2022-01-01T15:00:00.632Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.098Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:00.731Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:00.731Z',
								scheduleId: '53cbe7a8-8254-4b86-8d49-9153906af659',
							},
							{
								endAt: '2022-01-01T15:00:00.505Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.098Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:13.020Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:13.020Z',
								scheduleId: '1a17c05d-ef4b-4f58-b4b0-a03ec697dac5',
							},
							{
								endAt: '2022-01-01T15:00:00.814Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.197Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:36.439Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:36.439Z',
								scheduleId: 'cb76674b-12ef-4dbc-98aa-99edbb61bc25',
							},
							{
								endAt: '2022-01-01T15:00:00.267Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.342Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:10.158Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:10.158Z',
								scheduleId: '368e3dcb-c87e-4382-93b5-f92124af68a0',
							},
							{
								endAt: '2022-01-01T15:00:00.512Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.375Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:33.507Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:33.507Z',
								scheduleId: '22a089d9-af75-45cc-927e-d0845099ef05',
							},
							{
								endAt: '2022-01-01T15:00:00.418Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.377Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:17.220Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:17.220Z',
								scheduleId: '4f2f93d0-8d2a-4a61-9742-db0e509dec5e',
							},
							{
								endAt: '2022-01-01T15:00:00.833Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.384Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:05.486Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:05.486Z',
								scheduleId: 'fc14c9b9-4b89-4667-af32-6c27bd1147d4',
							},
							{
								endAt: '2022-01-01T15:00:00.376Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.414Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-14T02:27:52.427Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-14T02:27:52.427Z',
								scheduleId: '42d7db46-19a3-47d0-8af5-cf850aa25ca7',
							},
							{
								endAt: '2022-01-01T15:00:00.048Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.494Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:27.765Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:27.765Z',
								scheduleId: '4f7a2e8e-4afd-41bf-88f2-59fbcf1a7d1b',
							},
							{
								endAt: '2022-01-01T15:00:00.972Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.496Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:32.657Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:32.657Z',
								scheduleId: '05ac2913-2d60-4e02-b4b5-340448bab3ac',
							},
							{
								endAt: '2022-01-01T15:00:00.511Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.547Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:23.678Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:23.678Z',
								scheduleId: '506cd274-9f8e-4296-a480-7949bde125e4',
							},
							{
								endAt: '2022-01-01T15:00:00.118Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.566Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:57.046Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:57.046Z',
								scheduleId: 'c703d009-2840-4730-a9d1-e4ad1dc6a917',
							},
							{
								endAt: '2022-01-01T15:00:00.149Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.634Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:48.087Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:48.087Z',
								scheduleId: '188cf832-1275-49bd-885f-240e390b8e6f',
							},
							{
								endAt: '2022-01-01T15:00:00.268Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.661Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:43.515Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:43.515Z',
								scheduleId: '9d0fb904-4e28-42af-a4d4-060ad52e704b',
							},
							{
								endAt: '2022-01-01T15:00:00.599Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.683Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:21.254Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:21.254Z',
								scheduleId: 'f096a199-fe82-40c5-861c-cab046ddbdd7',
							},
							{
								endAt: '2022-01-01T15:00:00.079Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.689Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:00.869Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:00.869Z',
								scheduleId: 'f9642816-0af0-4b81-aec1-f6d84f0e004f',
							},
							{
								endAt: '2022-01-01T15:00:00.351Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.695Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:53.389Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:53.389Z',
								scheduleId: '8a73f804-38a2-4c63-90d6-0274230d2926',
							},
							{
								endAt: '2022-01-01T15:00:00.488Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.787Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:18.728Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:18.728Z',
								scheduleId: '4126c29f-9586-498a-b58b-03f45d8f5949',
							},
							{
								endAt: '2022-01-01T15:00:00.198Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.805Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:25.263Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:25.263Z',
								scheduleId: '5415d0c1-6a9d-440e-9df9-fc7166caab12',
							},
							{
								endAt: '2022-01-01T15:00:00.303Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.810Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:52.482Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:52.482Z',
								scheduleId: '3459a911-20d6-4179-8873-115af073b8df',
							},
							{
								endAt: '2022-01-01T15:00:00.970Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.841Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:08.456Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:08.456Z',
								scheduleId: 'a31ae4ad-a007-4a79-a76f-0e4b46321701',
							},
							{
								endAt: '2022-01-01T15:00:00.184Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.850Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:05.067Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:03:05.067Z',
								scheduleId: 'c12eb042-bd87-41b1-9c12-255681814c02',
							},
							{
								endAt: '2022-01-01T15:00:00.610Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.873Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:40.316Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:40.316Z',
								scheduleId: 'b4b36f6b-1c4b-4886-8645-ddcbd41671cc',
							},
							{
								endAt: '2022-01-01T15:00:00.691Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.931Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:23.318Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:23.318Z',
								scheduleId: '2ffbdaa9-c727-4de3-ade4-302d22731359',
							},
							{
								endAt: '2022-01-01T15:00:00.683Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.952Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:44.409Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:44.409Z',
								scheduleId: '4a3b9e33-2601-4228-903e-79e2c1a171c0',
							},
							{
								endAt: '2022-01-01T15:00:00.776Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.973Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:29.740Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:29.740Z',
								scheduleId: '54f7f208-0156-4a7b-abab-01d5a4ab58aa',
							},
						],
						specialityCertificate: null,
						availableConsultationTypes: [
							'TELEMEDICINE_VIDEO_CHAT_CONSULTATION',
							'TELEMEDICINE_CHAT_CONSULTATION',
							'CONSULT_NOW_VIDEO_CHAT_CONSULTATION',
							'CONSULT_NOW_CHAT_CONSULTATION',
							'FACE_TO_FACE_CONSULTATION',
						],
					},
					metaPartner: {
						email: null,
						phone: '08234567890',
						address:
							'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
						createdAt: '2022-09-08T07:01:21.769Z',
						partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
						updatedAt: '2022-09-08T07:05:30.929Z',
						geolocation: {
							latitude: 0,
							longitude: 0,
						},
						isPublished: true,
						partnerName: 'Klinik PPK 1 Gebang',
						partnerType: 'CLINIC',
						imageLogoURL: null,
						imageCoverURL:
							'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
						isConfirmedPartner: true,
					},
					createdByUserId: '01GCDNAV6270SMVQBEAQ90VY4G',
					metaCreatedByUser: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						updatedAt: '2022-09-08T07:10:14.649Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					createdAt: '2022-09-20T04:49:42.579Z',
					updatedAt: '2022-09-22T08:29:48.427Z',
					medicalRecord: {
						medicalRecordId: '20c6dc64-aba7-4eaa-a1ad-d83f88ac7cde',
						doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
						patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patientInRelationId: null,
						reasonOfConsultation: 'Maag',
						historyPhysicalFindings: null,
						diagnosis: null,
						planForManagement: null,
						transactionConsultationId: '2cd70412-e78c-46fc-9930-82f0d09f7540',
						uploadedFile: null,
						createdAt: '2022-09-20T04:49:42.593Z',
						updatedAt: '2022-09-20T04:49:42.593Z',
					},
					history: [
						{
							historyId: 'ebf8f67c-2dd5-4340-94f9-38df1cf79ff0',
							transactionConsultationId: '2cd70412-e78c-46fc-9930-82f0d09f7540',
							description: 'Update transaction status',
							newData: {
								transactionStatus: 'APPROVED',
							},
							createdByUserId: '01GCDSTJ8FWEPN6H6MTQBR206D',
							metaCreatedByUser: {
								action: 'login_success',
								userId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								payload: {
									phoneNumber: '6382345',
								},
								userType: 'customer',
								sessionId:
									'ad383d01080234254147d58a70ad2a07e8ed3ab842585e7d56f4b44fb005bd5a',
							},
							createdAt: '2022-09-22T08:29:48.446Z',
							updatedAt: '2022-09-22T08:29:48.446Z',
						},
						{
							historyId: '3ef1159b-b33a-4ccd-8419-bb7e680fd168',
							transactionConsultationId: '2cd70412-e78c-46fc-9930-82f0d09f7540',
							description: 'Update transaction status',
							newData: {
								transactionStatus: 'WAITING_APPROVAL',
							},
							createdByUserId: '01GCDNAV6270SMVQBEAQ90VY4G',
							metaCreatedByUser: {
								city: null,
								name: 'Rizki Rahmadi',
								email: null,
								phone: '63125',
								doctor: null,
								gender: 'male',
								status: 'active',
								userId: '01GCDNAV6270SMVQBEAQ90VY4G',
								patient: {
									pwId: null,
									pwIdUrl: null,
									createdAt: '2022-09-08T04:22:06.889Z',
									patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
									updatedAt: '2022-09-08T04:22:06.889Z',
									pwIdStatus: 'WAITING_APPROVAL',
								},
								lastName: 'Rahmadi',
								nickName: 'Iki',
								province: null,
								userType: 'customer',
								birthdate: '1999-03-11',
								createdAt: '2022-09-08T04:22:06.787Z',
								firstName: 'Rizki',
								updatedAt: '2022-09-08T07:10:14.649Z',
								middleName: null,
								profilePic: null,
								isEmailVerified: false,
								isPhoneVerified: true,
							},
							createdAt: '2022-09-20T04:50:29.689Z',
							updatedAt: '2022-09-20T04:50:29.689Z',
						},
					],
					payment: {
						paymentId: '4ab1052b-db0c-4553-b657-a6c941492862',
						invoiceId: 'CI/20220920/J65',
						transactionId: '2cd70412-e78c-46fc-9930-82f0d09f7540',
						transactionType: 'IN_PERSON',
						totalNeedToPay: 575,
						paymentStatus: 'PAID_FULL',
						buyerId: '01GCDNAV6270SMVQBEAQ90VY4G',
						metaBuyerDetail: {
							city: null,
							name: 'Rizki Rahmadi',
							email: null,
							phone: '63125',
							doctor: null,
							gender: 'male',
							status: 'active',
							userId: '01GCDNAV6270SMVQBEAQ90VY4G',
							patient: {
								pwId: null,
								pwIdUrl: null,
								createdAt: '2022-09-08T04:22:06.889Z',
								patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
								updatedAt: '2022-09-08T04:22:06.889Z',
								pwIdStatus: 'WAITING_APPROVAL',
							},
							lastName: 'Rahmadi',
							nickName: 'Iki',
							province: null,
							userType: 'customer',
							birthdate: '1999-03-11',
							createdAt: '2022-09-08T04:22:06.787Z',
							firstName: 'Rizki',
							updatedAt: '2022-09-08T07:10:14.649Z',
							middleName: null,
							profilePic: null,
							isEmailVerified: false,
							isPhoneVerified: true,
						},
						meta: null,
						expiredAt: '2022-09-21T08:49:43.057Z',
						createdAt: '2022-09-20T04:49:43.057Z',
						updatedAt: '2022-09-20T04:50:29.662Z',
						paymentDetails: [
							{
								paymentDetailId: '6492b155-92d1-495a-b0fd-6bc809054cf0',
								paymentId: '4ab1052b-db0c-4553-b657-a6c941492862',
								paymentType: 'BASE_PRICE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 500,
								status: 'PAID_FULL',
								meta: {
									consultationFee: 500,
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-21T08:49:43.057Z',
								createdAt: '2022-09-20T04:49:43.112Z',
								updatedAt: '2022-09-20T04:49:43.112Z',
							},
							{
								paymentDetailId: '0ae9bcf1-8a50-409d-9a54-0953f2f99b20',
								paymentId: '4ab1052b-db0c-4553-b657-a6c941492862',
								paymentType: 'PATIENT_TRANSACTION_FEE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 75,
								status: 'PAID_FULL',
								meta: {
									key: 'PATIENT_TRANSACTION_FEE',
									value: '5%+50',
									configId: 'PATIENT_TRANSACTION_FEE',
									createdAt: '2022-09-08T06:08:45.373Z',
									updatedAt: '2022-09-08T06:08:45.373Z',
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-21T08:49:43.057Z',
								createdAt: '2022-09-20T04:49:43.115Z',
								updatedAt: '2022-09-20T04:49:43.115Z',
							},
							{
								paymentDetailId: 'f51bc821-4bb3-4743-a276-e613daf17b71',
								paymentId: '4ab1052b-db0c-4553-b657-a6c941492862',
								paymentType: 'PAYMENT_GATEWAY',
								paymentMethod: 'EWALLET',
								paymentVendorType: 'PAYMONGO',
								paymentVendorId: 'GCASH',
								externalId: null,
								totalPay: 575,
								status: 'PAID_FULL',
								meta: {
									data: {
										id: 'evt_2VDPL2spRR9YYdtDs3h9u6E7',
										type: 'event',
										attributes: {
											data: {
												id: 'pay_hgzSsRrNr9PYLC678xQw6vmL',
												type: 'payment',
												attributes: {
													fee: 1438,
													taxes: [],
													amount: 57500,
													origin: 'api',
													payout: null,
													source: {
														id: 'src_wQDQhfJ12xuwarWxXBdZuMvx',
														type: 'gcash',
													},
													status: 'paid',
													billing: null,
													paid_at: 1663649411,
													refunds: [],
													currency: 'PHP',
													disputed: false,
													livemode: false,
													metadata: {
														paymentId: '4ab1052b-db0c-4553-b657-a6c941492862',
													},
													access_url: null,
													created_at: 1663649411,
													net_amount: 56062,
													tax_amount: null,
													updated_at: 1663649411,
													description: null,
													available_at: 1663837200,
													payment_intent_id: null,
													statement_descriptor: 'PAYMONGO',
													balance_transaction_id:
														'bal_txn_BfzMJo38wJ3dKrxrkxBnrBCA',
													external_reference_number: null,
												},
											},
											type: 'payment.paid',
											livemode: false,
											created_at: 1663649411,
											updated_at: 1663649411,
											previous_data: {},
										},
									},
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-21T08:49:43.057Z',
								createdAt: '2022-09-20T04:49:43.110Z',
								updatedAt: '2022-09-20T04:50:29.649Z',
							},
						],
						paymentDetailsPartners: [
							{
								paymentDetailId: '64912c69-56a9-4636-9ac1-4c9e5050dbde',
								paymentId: '4ab1052b-db0c-4553-b657-a6c941492862',
								paymentType: 'FINAL_PAYMENT',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 473,
								status: 'PAID_FULL',
								meta: {},
								type: 'PAYMENT',
								expiredAt: '2022-09-21T08:49:43.057Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-20T04:49:43.119Z',
								updatedAt: '2022-09-20T04:49:43.119Z',
							},
							{
								paymentDetailId: 'bcda5281-5a02-49a2-bab9-d28b548b416d',
								paymentId: '4ab1052b-db0c-4553-b657-a6c941492862',
								paymentType: 'DOCTOR_TRANSACTION_FEE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 17,
								status: 'PAID_FULL',
								meta: {
									key: 'DOCTOR_TRANSACTION_FEE',
									value: '3%',
									configId: 'DOCTOR_TRANSACTION_FEE',
									createdAt: '2022-09-08T06:08:45.373Z',
									updatedAt: '2022-09-08T06:08:45.373Z',
								},
								type: 'DISCOUNT',
								expiredAt: '2022-09-21T08:49:43.057Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-20T04:49:43.119Z',
								updatedAt: '2022-09-20T04:49:43.119Z',
							},
							{
								paymentDetailId: '48f36ff0-4729-4b64-b689-530e631dbcc7',
								paymentId: '4ab1052b-db0c-4553-b657-a6c941492862',
								paymentType: 'DOCTOR_COMMISSION_FEE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 10,
								status: 'PAID_FULL',
								meta: {
									key: 'DOCTOR_COMMISSION_FEE',
									value: '2%',
									configId: 'DOCTOR_COMMISSION_FEE',
									createdAt: '2022-09-08T09:27:39.090Z',
									updatedAt: '2022-09-08T09:27:39.090Z',
								},
								type: 'DISCOUNT',
								expiredAt: '2022-09-21T08:49:43.057Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-20T04:49:43.119Z',
								updatedAt: '2022-09-20T04:49:43.119Z',
							},
							{
								paymentDetailId: '1e9cf9b3-ff89-4279-8bb9-00182b0aa96a',
								paymentId: '4ab1052b-db0c-4553-b657-a6c941492862',
								paymentType: 'PATIENT_PWD_DISCOUNT',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 0,
								status: 'PAID_FULL',
								meta: {},
								type: 'DISCOUNT',
								expiredAt: '2022-09-21T08:49:43.057Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-20T04:49:43.119Z',
								updatedAt: '2022-09-20T04:49:43.119Z',
							},
							{
								paymentDetailId: 'f4d7b74c-d1e6-4865-a251-9d83f177f40b',
								paymentId: '4ab1052b-db0c-4553-b657-a6c941492862',
								paymentType: 'BASE_PRICE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 500,
								status: 'PAID_FULL',
								meta: {},
								type: 'PAYMENT',
								expiredAt: '2022-09-21T08:49:43.057Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-20T04:49:43.119Z',
								updatedAt: '2022-09-20T04:49:43.119Z',
							},
						],
					},
				},
			},
			{
				name: 'Rizki Rahmadi',
				date: '2022-09-13',
				desc: 'Pilek',
				reason: 'Pilek',
				startTime: '15:30',
				endTime: '16:00',
				detailDoctor: {
					name: 'Aditya Arya Tusan',
					title:
						'Psychiatry, Cardiology, Family Medicine, Critical Care (Intensivist)',
					age: 22,
					ageString: '22 yo',
				},
				raw: {
					transactionConsultationId: '4223755c-56a3-4a30-87f8-44384437fafe',
					doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
					patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
					patientInRelationId: null,
					partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
					consultationType: 'FACE_TO_FACE_CONSULTATION',
					scheduledStartAt: '2022-09-13T08:30:00.000Z',
					scheduledEndAt: '2022-09-13T09:00:00.000Z',
					isCanExtend: null,
					doctorJoinAt: null,
					doctorEndAt: null,
					patientJoinAt: null,
					patientEndAt: null,
					transactionStatus: 'COMPLETED',
					lastUpdatedByUser: {
						action: 'login_success',
						userId: '01GCDSTJ8FWEPN6H6MTQBR206D',
						payload: {
							phoneNumber: '6382345',
						},
						userType: 'customer',
						sessionId:
							'ea0972bb598345836aaae5ffc1b3a42f26bcc5ff43eaac7b36dfa1d383ccb7d9',
					},
					description: null,
					place: null,
					link: null,
					metaPatient: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						updatedAt: '2022-09-08T07:10:14.649Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					metaPatientInRelation: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
						updatedAt: '2022-09-08T07:10:14.649Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					metaDoctor: {
						user: {
							city: null,
							name: 'Aditya Arya Tusan',
							email: null,
							phone: '6382345',
							gender: 'male',
							status: 'active',
							userId: '01GCDSTJ8FWEPN6H6MTQBR206D',
							lastName: 'Tusan',
							nickName: 'Aditya',
							province: null,
							userType: 'customer',
							birthdate: '1999-11-30',
							createdAt: '2022-09-08T05:40:36.240Z',
							firstName: 'Aditya',
							updatedAt: '2022-09-08T08:31:31.839Z',
							middleName: 'Arya',
							profilePic: null,
						},
						title: 'SKG',
						doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
						partners: [
							{
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:01:45.057Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:15.723Z',
								pricePerSession: 500,
							},
							{
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:01:45.057Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:01:59.322Z',
								pricePerSession: 500,
							},
							{
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:53:12.891Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:20.032Z',
								pricePerSession: 500,
							},
							{
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:53:55.441Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:53:55.441Z',
								pricePerSession: null,
							},
						],
						createdAt: '2022-09-08T05:47:23.761Z',
						kycStatus: 'APPROVED',
						prcNumber: '',
						ptrNumber: '',
						schedules: [
							{
								endAt: '2022-01-01T15:00:00.970Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.841Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:08.456Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:08.456Z',
								scheduleId: 'a31ae4ad-a007-4a79-a76f-0e4b46321701',
							},
							{
								endAt: '2022-01-01T15:00:00.776Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.973Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:29.740Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:29.740Z',
								scheduleId: '54f7f208-0156-4a7b-abab-01d5a4ab58aa',
							},
							{
								endAt: '2022-01-01T15:00:00.457Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.095Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:45.000Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:45.000Z',
								scheduleId: '39074432-4205-402f-997c-aa50803c2393',
							},
							{
								endAt: '2022-01-01T15:00:00.351Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.695Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:53.389Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:53.389Z',
								scheduleId: '8a73f804-38a2-4c63-90d6-0274230d2926',
							},
							{
								endAt: '2022-01-01T15:00:00.079Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.689Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:00.869Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:00.869Z',
								scheduleId: 'f9642816-0af0-4b81-aec1-f6d84f0e004f',
							},
							{
								endAt: '2022-01-01T15:00:00.777Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.303Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:09.660Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:09.660Z',
								scheduleId: '51f82da8-9357-45fa-9bd5-e01957ca0bbf',
							},
							{
								endAt: '2022-01-01T15:00:00.488Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.787Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:18.728Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:18.728Z',
								scheduleId: '4126c29f-9586-498a-b58b-03f45d8f5949',
							},
							{
								endAt: '2022-01-01T15:00:00.048Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.494Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:27.765Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:27.765Z',
								scheduleId: '4f7a2e8e-4afd-41bf-88f2-59fbcf1a7d1b',
							},
							{
								endAt: '2022-01-01T15:00:00.339Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.285Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:56.277Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:56.277Z',
								scheduleId: '9e8417db-9105-410e-b740-5b218437c06d',
							},
							{
								endAt: '2022-01-01T15:00:00.194Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.066Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:28.709Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:28.709Z',
								scheduleId: 'b59890d5-44a0-447b-9f31-ca137e27779a',
							},
							{
								endAt: '2022-01-01T15:00:00.814Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.197Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:36.439Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:36.439Z',
								scheduleId: 'cb76674b-12ef-4dbc-98aa-99edbb61bc25',
							},
							{
								endAt: '2022-01-01T15:00:00.683Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.952Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:44.409Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:44.409Z',
								scheduleId: '4a3b9e33-2601-4228-903e-79e2c1a171c0',
							},
							{
								endAt: '2022-01-01T15:00:00.303Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.810Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:52.482Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:52.482Z',
								scheduleId: '3459a911-20d6-4179-8873-115af073b8df',
							},
							{
								endAt: '2022-01-01T15:00:00.632Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.098Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:00.731Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:00.731Z',
								scheduleId: '53cbe7a8-8254-4b86-8d49-9153906af659',
							},
							{
								endAt: '2022-01-01T15:00:00.267Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.342Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:10.158Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:10.158Z',
								scheduleId: '368e3dcb-c87e-4382-93b5-f92124af68a0',
							},
							{
								endAt: '2022-01-01T15:00:00.511Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.547Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:23.678Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:23.678Z',
								scheduleId: '506cd274-9f8e-4296-a480-7949bde125e4',
							},
							{
								endAt: '2022-01-01T15:00:00.418Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.377Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:17.220Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:17.220Z',
								scheduleId: '4f2f93d0-8d2a-4a61-9742-db0e509dec5e',
							},
							{
								endAt: '2022-01-01T15:00:00.198Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.805Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:25.263Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:25.263Z',
								scheduleId: '5415d0c1-6a9d-440e-9df9-fc7166caab12',
							},
							{
								endAt: '2022-01-01T15:00:00.972Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.496Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:32.657Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:32.657Z',
								scheduleId: '05ac2913-2d60-4e02-b4b5-340448bab3ac',
							},
							{
								endAt: '2022-01-01T15:00:00.610Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.873Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:40.316Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:40.316Z',
								scheduleId: 'b4b36f6b-1c4b-4886-8645-ddcbd41671cc',
							},
							{
								endAt: '2022-01-01T15:00:00.149Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.634Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:48.087Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:48.087Z',
								scheduleId: '188cf832-1275-49bd-885f-240e390b8e6f',
							},
							{
								endAt: '2022-01-01T15:00:00.118Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.566Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:57.046Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:57.046Z',
								scheduleId: 'c703d009-2840-4730-a9d1-e4ad1dc6a917',
							},
							{
								endAt: '2022-01-01T15:00:00.184Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.850Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:05.067Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:03:05.067Z',
								scheduleId: 'c12eb042-bd87-41b1-9c12-255681814c02',
							},
							{
								endAt: '2022-01-01T15:00:00.691Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.931Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:23.318Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:23.318Z',
								scheduleId: '2ffbdaa9-c727-4de3-ade4-302d22731359',
							},
							{
								endAt: '2022-01-01T15:00:00.512Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.375Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:33.507Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:33.507Z',
								scheduleId: '22a089d9-af75-45cc-927e-d0845099ef05',
							},
							{
								endAt: '2022-01-01T15:00:00.268Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.661Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:43.515Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:43.515Z',
								scheduleId: '9d0fb904-4e28-42af-a4d4-060ad52e704b',
							},
							{
								endAt: '2022-01-01T15:00:00.388Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.083Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:54.009Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:54.009Z',
								scheduleId: '3cbb04c9-e894-45b3-9ab6-4cd9ca68c3dd',
							},
							{
								endAt: '2022-01-01T15:00:00.833Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.384Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:05.486Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:05.486Z',
								scheduleId: 'fc14c9b9-4b89-4667-af32-6c27bd1147d4',
							},
							{
								endAt: '2022-01-01T15:00:00.505Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.098Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:13.020Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:13.020Z',
								scheduleId: '1a17c05d-ef4b-4f58-b4b0-a03ec697dac5',
							},
							{
								endAt: '2022-01-01T15:00:00.599Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.683Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:21.254Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:21.254Z',
								scheduleId: 'f096a199-fe82-40c5-861c-cab046ddbdd7',
							},
						],
						startWork: null,
						tinNumber: '',
						updatedAt: '2022-09-08T08:31:30.898Z',
						educations: [],
						experience: 2,
						orderScore: 2,
						specialists: [
							{
								icon: 'psychiatry',
								level: 1,
								createdAt: '2022-09-08T03:16:01.498Z',
								updatedAt: '2022-09-08T03:16:01.498Z',
								specialistId: '87bcd861-d18d-4e00-adb7-c913ba60f435',
								specialistName: 'Psychiatry',
								parentSpecialistId: 'dafe825a-cc64-4b00-8d7d-e7ed8adab9ca',
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									specialistId: '87bcd861-d18d-4e00-adb7-c913ba60f435',
								},
							},
							{
								icon: 'family_medicine',
								level: 2,
								createdAt: '2022-09-08T03:16:01.512Z',
								updatedAt: '2022-09-08T03:16:01.512Z',
								specialistId: '9baad78f-2237-4933-9c77-811cc886b878',
								specialistName: 'Cardiology',
								parentSpecialistId: '55df1bba-f1e7-4e57-982d-084327ef4aac',
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									specialistId: '9baad78f-2237-4933-9c77-811cc886b878',
								},
							},
							{
								icon: 'family_medicine',
								level: 1,
								createdAt: '2022-09-08T03:16:01.498Z',
								updatedAt: '2022-09-08T03:16:01.498Z',
								specialistId: '55df1bba-f1e7-4e57-982d-084327ef4aac',
								specialistName: 'Family Medicine',
								parentSpecialistId: 'dafe825a-cc64-4b00-8d7d-e7ed8adab9ca',
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									specialistId: '55df1bba-f1e7-4e57-982d-084327ef4aac',
								},
							},
							{
								icon: 'critical_care_intensivist',
								level: 2,
								createdAt: '2022-09-08T03:16:01.512Z',
								updatedAt: '2022-09-08T03:16:01.512Z',
								specialistId: '22fc908d-8b1e-4bd9-b7f0-7f4072c70d7f',
								specialistName: 'Critical Care (Intensivist)',
								parentSpecialistId: '87bcd861-d18d-4e00-adb7-c913ba60f435',
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									specialistId: '22fc908d-8b1e-4bd9-b7f0-7f4072c70d7f',
								},
							},
						],
						certificates: [],
						nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
						otherSpecialist: '',
						signatureImageUrl:
							'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
						schedulesInPartners: [
							{
								endAt: '2022-01-01T15:00:00.194Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.066Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:28.709Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:28.709Z',
								scheduleId: 'b59890d5-44a0-447b-9f31-ca137e27779a',
							},
							{
								endAt: '2022-01-01T15:00:00.388Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.083Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:54.009Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:54.009Z',
								scheduleId: '3cbb04c9-e894-45b3-9ab6-4cd9ca68c3dd',
							},
							{
								endAt: '2022-01-01T15:00:00.457Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.095Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:45.000Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:45.000Z',
								scheduleId: '39074432-4205-402f-997c-aa50803c2393',
							},
							{
								endAt: '2022-01-01T15:00:00.632Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.098Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:00.731Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:00.731Z',
								scheduleId: '53cbe7a8-8254-4b86-8d49-9153906af659',
							},
							{
								endAt: '2022-01-01T15:00:00.505Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.098Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:13.020Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:13.020Z',
								scheduleId: '1a17c05d-ef4b-4f58-b4b0-a03ec697dac5',
							},
							{
								endAt: '2022-01-01T15:00:00.814Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.197Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:36.439Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:36.439Z',
								scheduleId: 'cb76674b-12ef-4dbc-98aa-99edbb61bc25',
							},
							{
								endAt: '2022-01-01T15:00:00.339Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.285Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:56.277Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:56.277Z',
								scheduleId: '9e8417db-9105-410e-b740-5b218437c06d',
							},
							{
								endAt: '2022-01-01T15:00:00.777Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.303Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:09.660Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:09.660Z',
								scheduleId: '51f82da8-9357-45fa-9bd5-e01957ca0bbf',
							},
							{
								endAt: '2022-01-01T15:00:00.267Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.342Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:10.158Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:10.158Z',
								scheduleId: '368e3dcb-c87e-4382-93b5-f92124af68a0',
							},
							{
								endAt: '2022-01-01T15:00:00.512Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.375Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:33.507Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:33.507Z',
								scheduleId: '22a089d9-af75-45cc-927e-d0845099ef05',
							},
							{
								endAt: '2022-01-01T15:00:00.418Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.377Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:17.220Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:17.220Z',
								scheduleId: '4f2f93d0-8d2a-4a61-9742-db0e509dec5e',
							},
							{
								endAt: '2022-01-01T15:00:00.833Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.384Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:05.486Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:05.486Z',
								scheduleId: 'fc14c9b9-4b89-4667-af32-6c27bd1147d4',
							},
							{
								endAt: '2022-01-01T15:00:00.048Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.494Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:27.765Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:27.765Z',
								scheduleId: '4f7a2e8e-4afd-41bf-88f2-59fbcf1a7d1b',
							},
							{
								endAt: '2022-01-01T15:00:00.972Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.496Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:32.657Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:32.657Z',
								scheduleId: '05ac2913-2d60-4e02-b4b5-340448bab3ac',
							},
							{
								endAt: '2022-01-01T15:00:00.511Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.547Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:58:23.678Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:58:23.678Z',
								scheduleId: '506cd274-9f8e-4296-a480-7949bde125e4',
							},
							{
								endAt: '2022-01-01T15:00:00.118Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.566Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:57.046Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:57.046Z',
								scheduleId: 'c703d009-2840-4730-a9d1-e4ad1dc6a917',
							},
							{
								endAt: '2022-01-01T15:00:00.149Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.634Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:48.087Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:48.087Z',
								scheduleId: '188cf832-1275-49bd-885f-240e390b8e6f',
							},
							{
								endAt: '2022-01-01T15:00:00.268Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.661Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:43.515Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:43.515Z',
								scheduleId: '9d0fb904-4e28-42af-a4d4-060ad52e704b',
							},
							{
								endAt: '2022-01-01T15:00:00.599Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.683Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:04:21.254Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:04:21.254Z',
								scheduleId: 'f096a199-fe82-40c5-861c-cab046ddbdd7',
							},
							{
								endAt: '2022-01-01T15:00:00.079Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.689Z',
								weekday: 4,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:00.869Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:00.869Z',
								scheduleId: 'f9642816-0af0-4b81-aec1-f6d84f0e004f',
							},
							{
								endAt: '2022-01-01T15:00:00.351Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.695Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:53.389Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:53.389Z',
								scheduleId: '8a73f804-38a2-4c63-90d6-0274230d2926',
							},
							{
								endAt: '2022-01-01T15:00:00.488Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.787Z',
								weekday: 5,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:55:18.728Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:55:18.728Z',
								scheduleId: '4126c29f-9586-498a-b58b-03f45d8f5949',
							},
							{
								endAt: '2022-01-01T15:00:00.198Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.805Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:25.263Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:25.263Z',
								scheduleId: '5415d0c1-6a9d-440e-9df9-fc7166caab12',
							},
							{
								endAt: '2022-01-01T15:00:00.303Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.810Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:52.482Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:52.482Z',
								scheduleId: '3459a911-20d6-4179-8873-115af073b8df',
							},
							{
								endAt: '2022-01-01T15:00:00.970Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.841Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:08.456Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:08.456Z',
								scheduleId: 'a31ae4ad-a007-4a79-a76f-0e4b46321701',
							},
							{
								endAt: '2022-01-01T15:00:00.184Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.850Z',
								weekday: 6,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:05.067Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:03:05.067Z',
								scheduleId: 'c12eb042-bd87-41b1-9c12-255681814c02',
							},
							{
								endAt: '2022-01-01T15:00:00.610Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.873Z',
								weekday: 3,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:02:40.316Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T07:02:40.316Z',
								scheduleId: 'b4b36f6b-1c4b-4886-8645-ddcbd41671cc',
							},
							{
								endAt: '2022-01-01T15:00:00.691Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.931Z',
								weekday: 0,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T07:03:23.318Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T07:03:23.318Z',
								scheduleId: '2ffbdaa9-c727-4de3-ade4-302d22731359',
							},
							{
								endAt: '2022-01-01T15:00:00.683Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.952Z',
								weekday: 2,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:57:44.409Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:57:44.409Z',
								scheduleId: '4a3b9e33-2601-4228-903e-79e2c1a171c0',
							},
							{
								endAt: '2022-01-01T15:00:00.776Z',
								doctor: {
									title: 'SKG',
									doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
									createdAt: '2022-09-08T05:47:23.761Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T08:31:30.898Z',
									experience: 2020,
									nameOfSignature: 'Dr. Aditya Arya Tusan Aditya, SKG',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/e47457ee-7888-4506-a736-c60a71261037',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.973Z',
								weekday: 1,
								doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								createdAt: '2022-09-08T06:54:29.740Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:54:29.740Z',
								scheduleId: '54f7f208-0156-4a7b-abab-01d5a4ab58aa',
							},
						],
						specialityCertificate: null,
						availableConsultationTypes: [
							'TELEMEDICINE_VIDEO_CHAT_CONSULTATION',
							'TELEMEDICINE_CHAT_CONSULTATION',
							'CONSULT_NOW_VIDEO_CHAT_CONSULTATION',
							'CONSULT_NOW_CHAT_CONSULTATION',
							'FACE_TO_FACE_CONSULTATION',
						],
					},
					metaPartner: {
						email: null,
						phone: '08234567890',
						address:
							'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
						createdAt: '2022-09-08T07:01:21.769Z',
						partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
						updatedAt: '2022-09-08T07:05:30.929Z',
						geolocation: {
							latitude: 0,
							longitude: 0,
						},
						isPublished: true,
						partnerName: 'Klinik PPK 1 Gebang',
						partnerType: 'CLINIC',
						imageLogoURL: null,
						imageCoverURL:
							'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
						isConfirmedPartner: true,
					},
					createdByUserId: '01GCDNAV6270SMVQBEAQ90VY4G',
					metaCreatedByUser: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						updatedAt: '2022-09-08T07:10:14.649Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					createdAt: '2022-09-13T08:21:21.391Z',
					updatedAt: '2022-09-13T08:26:21.592Z',
					medicalRecord: {
						medicalRecordId: '5e2d7a4e-49b0-4da6-bcc5-bd1fdee8e80b',
						doctorId: '01GCDSTJ8FWEPN6H6MTQBR206D',
						patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patientInRelationId: null,
						reasonOfConsultation: 'Pilek',
						historyPhysicalFindings: 'Kehujanan\n',
						diagnosis: 'Influenza tipe B',
						planForManagement: 'Beli obat',
						transactionConsultationId: '4223755c-56a3-4a30-87f8-44384437fafe',
						uploadedFile: null,
						createdAt: '2022-09-13T08:21:21.406Z',
						updatedAt: '2022-09-13T08:23:30.500Z',
					},
					history: [
						{
							historyId: '8c3603d8-70c3-434b-8b08-4e03a72ddd87',
							transactionConsultationId: '4223755c-56a3-4a30-87f8-44384437fafe',
							description: 'Update transaction status',
							newData: {
								transactionStatus: 'COMPLETED',
							},
							createdByUserId: '01GCDSTJ8FWEPN6H6MTQBR206D',
							metaCreatedByUser: {
								action: 'login_success',
								userId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								payload: {
									phoneNumber: '6382345',
								},
								userType: 'customer',
								sessionId:
									'ea0972bb598345836aaae5ffc1b3a42f26bcc5ff43eaac7b36dfa1d383ccb7d9',
							},
							createdAt: '2022-09-13T08:26:21.603Z',
							updatedAt: '2022-09-13T08:26:21.603Z',
						},
						{
							historyId: 'ad1498e8-2e0f-4d02-9000-49f0d8217ab3',
							transactionConsultationId: '4223755c-56a3-4a30-87f8-44384437fafe',
							description: 'Update transaction status',
							newData: {
								transactionStatus: 'WAITING_APPROVAL',
							},
							createdByUserId: '01GCDNAV6270SMVQBEAQ90VY4G',
							metaCreatedByUser: {
								city: null,
								name: 'Rizki Rahmadi',
								email: null,
								phone: '63125',
								doctor: null,
								gender: 'male',
								status: 'active',
								userId: '01GCDNAV6270SMVQBEAQ90VY4G',
								patient: {
									pwId: null,
									pwIdUrl: null,
									createdAt: '2022-09-08T04:22:06.889Z',
									patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
									updatedAt: '2022-09-08T04:22:06.889Z',
									pwIdStatus: 'WAITING_APPROVAL',
								},
								lastName: 'Rahmadi',
								nickName: 'Iki',
								province: null,
								userType: 'customer',
								birthdate: '1999-03-11',
								createdAt: '2022-09-08T04:22:06.787Z',
								firstName: 'Rizki',
								updatedAt: '2022-09-08T07:10:14.649Z',
								middleName: null,
								profilePic: null,
								isEmailVerified: false,
								isPhoneVerified: true,
							},
							createdAt: '2022-09-13T08:22:05.129Z',
							updatedAt: '2022-09-13T08:22:05.129Z',
						},
						{
							historyId: 'f1a9c188-7976-49da-a436-a5cf68178c05',
							transactionConsultationId: '4223755c-56a3-4a30-87f8-44384437fafe',
							description: 'Update transaction status',
							newData: {
								transactionStatus: 'APPROVED',
							},
							createdByUserId: '01GCDSTJ8FWEPN6H6MTQBR206D',
							metaCreatedByUser: {
								action: 'login_success',
								userId: '01GCDSTJ8FWEPN6H6MTQBR206D',
								payload: {
									phoneNumber: '6382345',
								},
								userType: 'customer',
								sessionId:
									'ea0972bb598345836aaae5ffc1b3a42f26bcc5ff43eaac7b36dfa1d383ccb7d9',
							},
							createdAt: '2022-09-13T08:22:22.069Z',
							updatedAt: '2022-09-13T08:22:22.069Z',
						},
					],
					payment: {
						paymentId: '31466a72-8fc1-49c6-8268-8bb0165cf785',
						invoiceId: 'CI/20220913/SN5',
						transactionId: '4223755c-56a3-4a30-87f8-44384437fafe',
						transactionType: 'IN_PERSON',
						totalNeedToPay: 575,
						paymentStatus: 'PAID_FULL',
						buyerId: '01GCDNAV6270SMVQBEAQ90VY4G',
						metaBuyerDetail: {
							city: null,
							name: 'Rizki Rahmadi',
							email: null,
							phone: '63125',
							doctor: null,
							gender: 'male',
							status: 'active',
							userId: '01GCDNAV6270SMVQBEAQ90VY4G',
							patient: {
								pwId: null,
								pwIdUrl: null,
								createdAt: '2022-09-08T04:22:06.889Z',
								patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
								updatedAt: '2022-09-08T04:22:06.889Z',
								pwIdStatus: 'WAITING_APPROVAL',
							},
							lastName: 'Rahmadi',
							nickName: 'Iki',
							province: null,
							userType: 'customer',
							birthdate: '1999-03-11',
							createdAt: '2022-09-08T04:22:06.787Z',
							firstName: 'Rizki',
							updatedAt: '2022-09-08T07:10:14.649Z',
							middleName: null,
							profilePic: null,
							isEmailVerified: false,
							isPhoneVerified: true,
						},
						meta: null,
						expiredAt: '2022-09-14T12:21:21.656Z',
						createdAt: '2022-09-13T08:21:21.656Z',
						updatedAt: '2022-09-13T08:22:05.102Z',
						paymentDetails: [
							{
								paymentDetailId: 'f7aa5b48-1dc2-45c5-947b-a600795f9f47',
								paymentId: '31466a72-8fc1-49c6-8268-8bb0165cf785',
								paymentType: 'BASE_PRICE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 500,
								status: 'PAID_FULL',
								meta: {
									consultationFee: 500,
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-14T12:21:21.656Z',
								createdAt: '2022-09-13T08:21:21.724Z',
								updatedAt: '2022-09-13T08:21:21.724Z',
							},
							{
								paymentDetailId: '2fd6e368-3870-4f70-9f06-44340d795628',
								paymentId: '31466a72-8fc1-49c6-8268-8bb0165cf785',
								paymentType: 'PATIENT_TRANSACTION_FEE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 75,
								status: 'PAID_FULL',
								meta: {
									key: 'PATIENT_TRANSACTION_FEE',
									value: '5%+50',
									configId: 'PATIENT_TRANSACTION_FEE',
									createdAt: '2022-09-08T06:08:45.373Z',
									updatedAt: '2022-09-08T06:08:45.373Z',
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-14T12:21:21.656Z',
								createdAt: '2022-09-13T08:21:21.728Z',
								updatedAt: '2022-09-13T08:21:21.728Z',
							},
							{
								paymentDetailId: '141a2863-2dd1-4a4a-a42d-947ea5f339e0',
								paymentId: '31466a72-8fc1-49c6-8268-8bb0165cf785',
								paymentType: 'PAYMENT_GATEWAY',
								paymentMethod: 'EWALLET',
								paymentVendorType: 'PAYMONGO',
								paymentVendorId: 'GCASH',
								externalId: null,
								totalPay: 575,
								status: 'PAID_FULL',
								meta: {
									data: {
										id: 'evt_LjMSf6VizATuXPwb5F61oR6e',
										type: 'event',
										attributes: {
											data: {
												id: 'pay_mEbD2ZbeHkUvjvz7VPtuP4ke',
												type: 'payment',
												attributes: {
													fee: 1438,
													taxes: [],
													amount: 57500,
													origin: 'api',
													payout: null,
													source: {
														id: 'src_DnoNzBt8dynnLcypP6kGcf3W',
														type: 'gcash',
													},
													status: 'paid',
													billing: null,
													paid_at: 1663057311,
													refunds: [],
													currency: 'PHP',
													disputed: false,
													livemode: false,
													metadata: {
														paymentId: '31466a72-8fc1-49c6-8268-8bb0165cf785',
													},
													access_url: null,
													created_at: 1663057311,
													net_amount: 56062,
													tax_amount: null,
													updated_at: 1663057311,
													description: null,
													available_at: 1663232400,
													payment_intent_id: null,
													statement_descriptor: 'PAYMONGO',
													balance_transaction_id:
														'bal_txn_oGnK8UrF811abHeXnJ3YdyU4',
													external_reference_number: null,
												},
											},
											type: 'payment.paid',
											livemode: false,
											created_at: 1663057311,
											updated_at: 1663057311,
											previous_data: {},
										},
									},
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-14T12:21:21.656Z',
								createdAt: '2022-09-13T08:21:21.721Z',
								updatedAt: '2022-09-13T08:22:05.090Z',
							},
						],
						paymentDetailsPartners: [
							{
								paymentDetailId: '12154493-b41a-49aa-b300-2538517af3ab',
								paymentId: '31466a72-8fc1-49c6-8268-8bb0165cf785',
								paymentType: 'FINAL_PAYMENT',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 473,
								status: 'PAID_FULL',
								meta: {},
								type: 'PAYMENT',
								expiredAt: '2022-09-14T12:21:21.656Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-13T08:21:21.734Z',
								updatedAt: '2022-09-13T08:21:21.734Z',
							},
							{
								paymentDetailId: '18c8fee5-9f2b-4332-bd8d-81c96b7fd8fe',
								paymentId: '31466a72-8fc1-49c6-8268-8bb0165cf785',
								paymentType: 'DOCTOR_TRANSACTION_FEE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 17,
								status: 'PAID_FULL',
								meta: {
									key: 'DOCTOR_TRANSACTION_FEE',
									value: '3%',
									configId: 'DOCTOR_TRANSACTION_FEE',
									createdAt: '2022-09-08T06:08:45.373Z',
									updatedAt: '2022-09-08T06:08:45.373Z',
								},
								type: 'DISCOUNT',
								expiredAt: '2022-09-14T12:21:21.656Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-13T08:21:21.734Z',
								updatedAt: '2022-09-13T08:21:21.734Z',
							},
							{
								paymentDetailId: '085a87f8-f39e-4881-9bdf-18fd33c8f012',
								paymentId: '31466a72-8fc1-49c6-8268-8bb0165cf785',
								paymentType: 'DOCTOR_COMMISSION_FEE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 10,
								status: 'PAID_FULL',
								meta: {
									key: 'DOCTOR_COMMISSION_FEE',
									value: '2%',
									configId: 'DOCTOR_COMMISSION_FEE',
									createdAt: '2022-09-08T09:27:39.090Z',
									updatedAt: '2022-09-08T09:27:39.090Z',
								},
								type: 'DISCOUNT',
								expiredAt: '2022-09-14T12:21:21.656Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-13T08:21:21.734Z',
								updatedAt: '2022-09-13T08:21:21.734Z',
							},
							{
								paymentDetailId: '40918001-6259-4c8e-93fe-3cd665cb08f1',
								paymentId: '31466a72-8fc1-49c6-8268-8bb0165cf785',
								paymentType: 'PATIENT_PWD_DISCOUNT',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 0,
								status: 'PAID_FULL',
								meta: {},
								type: 'DISCOUNT',
								expiredAt: '2022-09-14T12:21:21.656Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-13T08:21:21.734Z',
								updatedAt: '2022-09-13T08:21:21.734Z',
							},
							{
								paymentDetailId: '1d0dd82d-5507-46c9-a941-dd40d4ffd5ad',
								paymentId: '31466a72-8fc1-49c6-8268-8bb0165cf785',
								paymentType: 'BASE_PRICE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 500,
								status: 'PAID_FULL',
								meta: {},
								type: 'PAYMENT',
								expiredAt: '2022-09-14T12:21:21.656Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-13T08:21:21.734Z',
								updatedAt: '2022-09-13T08:21:21.734Z',
							},
						],
					},
				},
			},
			{
				name: 'Rizki Rahmadi',
				date: '2022-09-08',
				desc: 'sick',
				reason: 'sick',
				startTime: '16:00',
				endTime: '16:30',
				detailDoctor: {
					name: 'Joe Keery',
					title: '',
					ageString: '',
				},
				raw: {
					transactionConsultationId: 'c0902419-b2e0-4e20-9743-75ccd60b4706',
					doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
					patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
					patientInRelationId: null,
					partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
					consultationType: 'FACE_TO_FACE_CONSULTATION',
					scheduledStartAt: '2022-09-08T09:00:00.000Z',
					scheduledEndAt: '2022-09-08T09:30:00.000Z',
					isCanExtend: null,
					doctorJoinAt: null,
					doctorEndAt: null,
					patientJoinAt: null,
					patientEndAt: null,
					transactionStatus: 'APPROVED',
					lastUpdatedByUser: {
						action: 'login_success',
						userId: '01GCDSV3QRE7ZXTKF13KEM78SP',
						payload: {
							phoneNumber: '6312345',
						},
						userType: 'customer',
						sessionId:
							'f0a84d1a52e29ff30f5bb258c70209f8a54ece612424dc759cc2757507c5e049',
					},
					description: null,
					place: null,
					link: null,
					metaPatient: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						updatedAt: '2022-09-08T07:10:14.649Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					metaPatientInRelation: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
						updatedAt: '2022-09-08T07:10:14.649Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					metaDoctor: {
						user: {
							city: null,
							name: 'Joe Keery',
							email: null,
							phone: '6381234',
							gender: 'male',
							status: 'active',
							userId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
							lastName: 'Keery',
							nickName: 'Joe',
							province: null,
							userType: 'customer',
							birthdate: null,
							createdAt: '2022-09-08T05:53:00.754Z',
							firstName: 'Joe',
							updatedAt: '2022-09-08T06:24:53.294Z',
							middleName: '',
							profilePic: null,
						},
						title: 'dr. Joe Keery, Sp. F. ',
						doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
						partners: [
							{
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:48:06.192Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:48:06.192Z',
								pricePerSession: null,
							},
							{
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:47:33.727Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:49:38.710Z',
								pricePerSession: 500,
							},
							{
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:48:02.199Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T06:50:50.821Z',
								pricePerSession: 500,
							},
						],
						createdAt: '2022-09-08T06:03:26.570Z',
						kycStatus: 'APPROVED',
						prcNumber: '',
						ptrNumber: '',
						schedules: [
							{
								endAt: '2022-01-01T15:00:00.658Z',
								startAt: '2022-01-01T16:00:00.531Z',
								weekday: 0,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:48:19.974Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:48:19.974Z',
								scheduleId: '2ffddb19-c73d-4991-9e21-6292c8a6d3a7',
							},
							{
								endAt: '2022-01-01T15:00:00.877Z',
								startAt: '2022-01-01T16:00:00.717Z',
								weekday: 1,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:48:31.587Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:48:31.587Z',
								scheduleId: 'b26b4633-1391-48f5-8d51-3450101c5cc7',
							},
							{
								endAt: '2022-01-01T15:00:00.765Z',
								startAt: '2022-01-01T16:00:00.899Z',
								weekday: 2,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:48:43.096Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:48:43.096Z',
								scheduleId: '93fe7e95-5bf4-40b9-a1df-a6f11724a838',
							},
							{
								endAt: '2022-01-01T15:00:00.501Z',
								startAt: '2022-01-01T16:00:00.704Z',
								weekday: 3,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:48:54.680Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:48:54.680Z',
								scheduleId: '512acb0c-78c1-465e-b888-dadb31768bde',
							},
							{
								endAt: '2022-01-01T15:00:00.642Z',
								startAt: '2022-01-01T16:00:00.994Z',
								weekday: 4,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:49:04.137Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:49:04.137Z',
								scheduleId: 'effc8673-de6d-4b36-b7c3-8f76a40598dd',
							},
							{
								endAt: '2022-01-01T15:00:00.621Z',
								startAt: '2022-01-01T16:00:00.890Z',
								weekday: 5,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:49:12.306Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:49:12.306Z',
								scheduleId: '0fff586c-299c-4b08-a015-fa8917f85445',
							},
							{
								endAt: '2022-01-01T15:00:00.552Z',
								startAt: '2022-01-01T16:00:00.594Z',
								weekday: 6,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:49:21.324Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:49:21.324Z',
								scheduleId: '4f5fb361-cfb9-4f4b-b885-fb57b35ebfdf',
							},
							{
								endAt: '2022-01-01T15:00:00.193Z',
								startAt: '2022-01-01T16:00:00.688Z',
								weekday: 0,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:49:50.419Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:49:50.419Z',
								scheduleId: '96d03175-120c-4de6-809a-9984d2b895c6',
							},
							{
								endAt: '2022-01-01T15:00:00.151Z',
								startAt: '2022-01-01T16:00:00.103Z',
								weekday: 1,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:49:59.678Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:49:59.678Z',
								scheduleId: '267d0e0d-e93e-4cff-99ce-7217c17c72b2',
							},
							{
								endAt: '2022-01-01T15:00:00.815Z',
								startAt: '2022-01-01T16:00:00.051Z',
								weekday: 2,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:50:08.538Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:50:08.538Z',
								scheduleId: '05af5b5c-e9f9-4eae-af8f-7798b7a77c8c',
							},
							{
								endAt: '2022-01-01T15:00:00.514Z',
								startAt: '2022-01-01T16:00:00.095Z',
								weekday: 3,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:50:16.576Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:50:16.576Z',
								scheduleId: '036a27b1-c7ef-4c15-a09a-bd4930740ed5',
							},
							{
								endAt: '2022-01-01T15:00:00.316Z',
								startAt: '2022-01-01T16:00:00.984Z',
								weekday: 4,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:50:24.164Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:50:24.164Z',
								scheduleId: '2713bd60-53be-4db0-b498-0f022f86aba0',
							},
							{
								endAt: '2022-01-01T15:00:00.413Z',
								startAt: '2022-01-01T16:00:00.151Z',
								weekday: 5,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:50:32.105Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:50:32.105Z',
								scheduleId: 'f745ce8f-f27d-45a1-90b5-cc0eebc81355',
							},
							{
								endAt: '2022-01-01T15:00:00.919Z',
								startAt: '2022-01-01T16:00:00.086Z',
								weekday: 6,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:50:40.960Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:50:40.960Z',
								scheduleId: '99c70c12-88ef-41f5-9a55-f9f2cfc41781',
							},
							{
								endAt: '2022-01-01T14:00:00.988Z',
								startAt: '2022-01-01T22:00:00.623Z',
								weekday: 1,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:51:26.346Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T06:51:26.346Z',
								scheduleId: '38cafd85-4378-43a3-a720-00b6ddac6398',
							},
							{
								endAt: '2022-01-01T14:00:00.554Z',
								startAt: '2022-01-01T22:00:00.438Z',
								weekday: 0,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:51:41.754Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T06:51:41.754Z',
								scheduleId: 'c86888ab-75c5-4c86-8a1b-943c0b587028',
							},
							{
								endAt: '2022-01-01T14:00:00.699Z',
								startAt: '2022-01-01T22:00:00.142Z',
								weekday: 2,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:51:49.906Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T06:51:49.906Z',
								scheduleId: '475b6f3a-aa77-4f83-a495-eb918e348eee',
							},
							{
								endAt: '2022-01-01T14:00:00.547Z',
								startAt: '2022-01-01T22:00:00.098Z',
								weekday: 3,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:51:58.977Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T06:51:58.977Z',
								scheduleId: '53be5bee-bf63-43e6-87a2-5502ab54cd5e',
							},
							{
								endAt: '2022-01-01T14:00:00.650Z',
								startAt: '2022-01-01T22:00:00.789Z',
								weekday: 4,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:52:08.167Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T06:52:08.167Z',
								scheduleId: '0a791048-cf5c-4196-9925-6aedf6f2b6a2',
							},
							{
								endAt: '2022-01-01T14:00:00.198Z',
								startAt: '2022-01-01T22:00:00.815Z',
								weekday: 5,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:52:15.824Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T06:52:15.824Z',
								scheduleId: '73c51491-b54b-49b2-bd97-880a977c5911',
							},
							{
								endAt: '2022-01-01T14:00:00.523Z',
								startAt: '2022-01-01T22:00:00.953Z',
								weekday: 6,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:52:24.653Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T06:52:24.653Z',
								scheduleId: '7474fb2a-3d11-4a58-969d-a3f53a66ef64',
							},
						],
						startWork: null,
						tinNumber: '',
						updatedAt: '2022-09-08T06:47:49.948Z',
						educations: [],
						experience: 5,
						orderScore: 2,
						specialists: [],
						certificates: [],
						nameOfSignature: 'dr. Joe Keery, Sp. F. ',
						otherSpecialist: '',
						signatureImageUrl:
							'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/ed196eae-be86-4ded-a9dd-defe1f7425f2',
						schedulesInPartners: [
							{
								endAt: '2022-01-01T15:00:00.815Z',
								doctor: {
									title: 'dr. Joe Keery, Sp. F. ',
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									createdAt: '2022-09-08T06:03:26.570Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T06:47:49.948Z',
									experience: 2017,
									nameOfSignature: 'dr. Joe Keery, Sp. F. ',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/ed196eae-be86-4ded-a9dd-defe1f7425f2',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.051Z',
								weekday: 2,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:50:08.538Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:50:08.538Z',
								scheduleId: '05af5b5c-e9f9-4eae-af8f-7798b7a77c8c',
							},
							{
								endAt: '2022-01-01T15:00:00.919Z',
								doctor: {
									title: 'dr. Joe Keery, Sp. F. ',
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									createdAt: '2022-09-08T06:03:26.570Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T06:47:49.948Z',
									experience: 2017,
									nameOfSignature: 'dr. Joe Keery, Sp. F. ',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/ed196eae-be86-4ded-a9dd-defe1f7425f2',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.086Z',
								weekday: 6,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:50:40.960Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:50:40.960Z',
								scheduleId: '99c70c12-88ef-41f5-9a55-f9f2cfc41781',
							},
							{
								endAt: '2022-01-01T15:00:00.514Z',
								doctor: {
									title: 'dr. Joe Keery, Sp. F. ',
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									createdAt: '2022-09-08T06:03:26.570Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T06:47:49.948Z',
									experience: 2017,
									nameOfSignature: 'dr. Joe Keery, Sp. F. ',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/ed196eae-be86-4ded-a9dd-defe1f7425f2',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.095Z',
								weekday: 3,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:50:16.576Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:50:16.576Z',
								scheduleId: '036a27b1-c7ef-4c15-a09a-bd4930740ed5',
							},
							{
								endAt: '2022-01-01T15:00:00.151Z',
								doctor: {
									title: 'dr. Joe Keery, Sp. F. ',
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									createdAt: '2022-09-08T06:03:26.570Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T06:47:49.948Z',
									experience: 2017,
									nameOfSignature: 'dr. Joe Keery, Sp. F. ',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/ed196eae-be86-4ded-a9dd-defe1f7425f2',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.103Z',
								weekday: 1,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:49:59.678Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:49:59.678Z',
								scheduleId: '267d0e0d-e93e-4cff-99ce-7217c17c72b2',
							},
							{
								endAt: '2022-01-01T15:00:00.413Z',
								doctor: {
									title: 'dr. Joe Keery, Sp. F. ',
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									createdAt: '2022-09-08T06:03:26.570Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T06:47:49.948Z',
									experience: 2017,
									nameOfSignature: 'dr. Joe Keery, Sp. F. ',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/ed196eae-be86-4ded-a9dd-defe1f7425f2',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.151Z',
								weekday: 5,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:50:32.105Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:50:32.105Z',
								scheduleId: 'f745ce8f-f27d-45a1-90b5-cc0eebc81355',
							},
							{
								endAt: '2022-01-01T15:00:00.658Z',
								doctor: {
									title: 'dr. Joe Keery, Sp. F. ',
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									createdAt: '2022-09-08T06:03:26.570Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T06:47:49.948Z',
									experience: 2017,
									nameOfSignature: 'dr. Joe Keery, Sp. F. ',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/ed196eae-be86-4ded-a9dd-defe1f7425f2',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.531Z',
								weekday: 0,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:48:19.974Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:48:19.974Z',
								scheduleId: '2ffddb19-c73d-4991-9e21-6292c8a6d3a7',
							},
							{
								endAt: '2022-01-01T15:00:00.552Z',
								doctor: {
									title: 'dr. Joe Keery, Sp. F. ',
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									createdAt: '2022-09-08T06:03:26.570Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T06:47:49.948Z',
									experience: 2017,
									nameOfSignature: 'dr. Joe Keery, Sp. F. ',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/ed196eae-be86-4ded-a9dd-defe1f7425f2',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.594Z',
								weekday: 6,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:49:21.324Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:49:21.324Z',
								scheduleId: '4f5fb361-cfb9-4f4b-b885-fb57b35ebfdf',
							},
							{
								endAt: '2022-01-01T15:00:00.193Z',
								doctor: {
									title: 'dr. Joe Keery, Sp. F. ',
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									createdAt: '2022-09-08T06:03:26.570Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T06:47:49.948Z',
									experience: 2017,
									nameOfSignature: 'dr. Joe Keery, Sp. F. ',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/ed196eae-be86-4ded-a9dd-defe1f7425f2',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.688Z',
								weekday: 0,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:49:50.419Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:49:50.419Z',
								scheduleId: '96d03175-120c-4de6-809a-9984d2b895c6',
							},
							{
								endAt: '2022-01-01T15:00:00.501Z',
								doctor: {
									title: 'dr. Joe Keery, Sp. F. ',
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									createdAt: '2022-09-08T06:03:26.570Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T06:47:49.948Z',
									experience: 2017,
									nameOfSignature: 'dr. Joe Keery, Sp. F. ',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/ed196eae-be86-4ded-a9dd-defe1f7425f2',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.704Z',
								weekday: 3,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:48:54.680Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:48:54.680Z',
								scheduleId: '512acb0c-78c1-465e-b888-dadb31768bde',
							},
							{
								endAt: '2022-01-01T15:00:00.877Z',
								doctor: {
									title: 'dr. Joe Keery, Sp. F. ',
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									createdAt: '2022-09-08T06:03:26.570Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T06:47:49.948Z',
									experience: 2017,
									nameOfSignature: 'dr. Joe Keery, Sp. F. ',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/ed196eae-be86-4ded-a9dd-defe1f7425f2',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.717Z',
								weekday: 1,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:48:31.587Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:48:31.587Z',
								scheduleId: 'b26b4633-1391-48f5-8d51-3450101c5cc7',
							},
							{
								endAt: '2022-01-01T15:00:00.621Z',
								doctor: {
									title: 'dr. Joe Keery, Sp. F. ',
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									createdAt: '2022-09-08T06:03:26.570Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T06:47:49.948Z',
									experience: 2017,
									nameOfSignature: 'dr. Joe Keery, Sp. F. ',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/ed196eae-be86-4ded-a9dd-defe1f7425f2',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.890Z',
								weekday: 5,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:49:12.306Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:49:12.306Z',
								scheduleId: '0fff586c-299c-4b08-a015-fa8917f85445',
							},
							{
								endAt: '2022-01-01T15:00:00.765Z',
								doctor: {
									title: 'dr. Joe Keery, Sp. F. ',
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									createdAt: '2022-09-08T06:03:26.570Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T06:47:49.948Z',
									experience: 2017,
									nameOfSignature: 'dr. Joe Keery, Sp. F. ',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/ed196eae-be86-4ded-a9dd-defe1f7425f2',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.899Z',
								weekday: 2,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:48:43.096Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:48:43.096Z',
								scheduleId: '93fe7e95-5bf4-40b9-a1df-a6f11724a838',
							},
							{
								endAt: '2022-01-01T15:00:00.316Z',
								doctor: {
									title: 'dr. Joe Keery, Sp. F. ',
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									createdAt: '2022-09-08T06:03:26.570Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T06:47:49.948Z',
									experience: 2017,
									nameOfSignature: 'dr. Joe Keery, Sp. F. ',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/ed196eae-be86-4ded-a9dd-defe1f7425f2',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.984Z',
								weekday: 4,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:50:24.164Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T06:50:24.164Z',
								scheduleId: '2713bd60-53be-4db0-b498-0f022f86aba0',
							},
							{
								endAt: '2022-01-01T15:00:00.642Z',
								doctor: {
									title: 'dr. Joe Keery, Sp. F. ',
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									createdAt: '2022-09-08T06:03:26.570Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T06:47:49.948Z',
									experience: 2017,
									nameOfSignature: 'dr. Joe Keery, Sp. F. ',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/ed196eae-be86-4ded-a9dd-defe1f7425f2',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T16:00:00.994Z',
								weekday: 4,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:49:04.137Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T06:49:04.137Z',
								scheduleId: 'effc8673-de6d-4b36-b7c3-8f76a40598dd',
							},
							{
								endAt: '2022-01-01T14:00:00.547Z',
								doctor: {
									title: 'dr. Joe Keery, Sp. F. ',
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									createdAt: '2022-09-08T06:03:26.570Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T06:47:49.948Z',
									experience: 2017,
									nameOfSignature: 'dr. Joe Keery, Sp. F. ',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/ed196eae-be86-4ded-a9dd-defe1f7425f2',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T22:00:00.098Z',
								weekday: 3,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:51:58.977Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T06:51:58.977Z',
								scheduleId: '53be5bee-bf63-43e6-87a2-5502ab54cd5e',
							},
							{
								endAt: '2022-01-01T14:00:00.699Z',
								doctor: {
									title: 'dr. Joe Keery, Sp. F. ',
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									createdAt: '2022-09-08T06:03:26.570Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T06:47:49.948Z',
									experience: 2017,
									nameOfSignature: 'dr. Joe Keery, Sp. F. ',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/ed196eae-be86-4ded-a9dd-defe1f7425f2',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T22:00:00.142Z',
								weekday: 2,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:51:49.906Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T06:51:49.906Z',
								scheduleId: '475b6f3a-aa77-4f83-a495-eb918e348eee',
							},
							{
								endAt: '2022-01-01T14:00:00.554Z',
								doctor: {
									title: 'dr. Joe Keery, Sp. F. ',
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									createdAt: '2022-09-08T06:03:26.570Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T06:47:49.948Z',
									experience: 2017,
									nameOfSignature: 'dr. Joe Keery, Sp. F. ',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/ed196eae-be86-4ded-a9dd-defe1f7425f2',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T22:00:00.438Z',
								weekday: 0,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:51:41.754Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T06:51:41.754Z',
								scheduleId: 'c86888ab-75c5-4c86-8a1b-943c0b587028',
							},
							{
								endAt: '2022-01-01T14:00:00.988Z',
								doctor: {
									title: 'dr. Joe Keery, Sp. F. ',
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									createdAt: '2022-09-08T06:03:26.570Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T06:47:49.948Z',
									experience: 2017,
									nameOfSignature: 'dr. Joe Keery, Sp. F. ',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/ed196eae-be86-4ded-a9dd-defe1f7425f2',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T22:00:00.623Z',
								weekday: 1,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:51:26.346Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T06:51:26.346Z',
								scheduleId: '38cafd85-4378-43a3-a720-00b6ddac6398',
							},
							{
								endAt: '2022-01-01T14:00:00.650Z',
								doctor: {
									title: 'dr. Joe Keery, Sp. F. ',
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									createdAt: '2022-09-08T06:03:26.570Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T06:47:49.948Z',
									experience: 2017,
									nameOfSignature: 'dr. Joe Keery, Sp. F. ',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/ed196eae-be86-4ded-a9dd-defe1f7425f2',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T22:00:00.789Z',
								weekday: 4,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:52:08.167Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T06:52:08.167Z',
								scheduleId: '0a791048-cf5c-4196-9925-6aedf6f2b6a2',
							},
							{
								endAt: '2022-01-01T14:00:00.198Z',
								doctor: {
									title: 'dr. Joe Keery, Sp. F. ',
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									createdAt: '2022-09-08T06:03:26.570Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T06:47:49.948Z',
									experience: 2017,
									nameOfSignature: 'dr. Joe Keery, Sp. F. ',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/ed196eae-be86-4ded-a9dd-defe1f7425f2',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T22:00:00.815Z',
								weekday: 5,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:52:15.824Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T06:52:15.824Z',
								scheduleId: '73c51491-b54b-49b2-bd97-880a977c5911',
							},
							{
								endAt: '2022-01-01T14:00:00.523Z',
								doctor: {
									title: 'dr. Joe Keery, Sp. F. ',
									doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
									createdAt: '2022-09-08T06:03:26.570Z',
									kycStatus: 'APPROVED',
									prcNumber: '',
									ptrNumber: '',
									startWork: null,
									tinNumber: '',
									updatedAt: '2022-09-08T06:47:49.948Z',
									experience: 2017,
									nameOfSignature: 'dr. Joe Keery, Sp. F. ',
									otherSpecialist: '',
									signatureImageUrl:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/ed196eae-be86-4ded-a9dd-defe1f7425f2',
									specialityCertificate: null,
								},
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T22:00:00.953Z',
								weekday: 6,
								doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								createdAt: '2022-09-08T06:52:24.653Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-08T06:52:24.653Z',
								scheduleId: '7474fb2a-3d11-4a58-969d-a3f53a66ef64',
							},
						],
						specialityCertificate: null,
						availableConsultationTypes: [
							'TELEMEDICINE_VIDEO_CHAT_CONSULTATION',
							'TELEMEDICINE_CHAT_CONSULTATION',
							'CONSULT_NOW_VIDEO_CHAT_CONSULTATION',
							'CONSULT_NOW_CHAT_CONSULTATION',
							'FACE_TO_FACE_CONSULTATION',
						],
					},
					metaPartner: {
						email: null,
						phone: '081234567890',
						address:
							'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
						createdAt: '2022-09-08T06:47:06.977Z',
						partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
						updatedAt: '2022-09-08T06:47:07.211Z',
						geolocation: {
							latitude: 0,
							longitude: 0,
						},
						isPublished: true,
						partnerName: 'Mitra Husada',
						partnerType: 'CLINIC',
						imageLogoURL: null,
						imageCoverURL:
							'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
						isConfirmedPartner: true,
					},
					createdByUserId: '01GCDNAV6270SMVQBEAQ90VY4G',
					metaCreatedByUser: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						updatedAt: '2022-09-08T07:10:14.649Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					createdAt: '2022-09-08T08:14:30.215Z',
					updatedAt: '2022-09-12T02:55:57.409Z',
					medicalRecord: {
						medicalRecordId: 'ccbec381-4382-4d52-b1cb-506b8277aaf8',
						doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
						patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patientInRelationId: null,
						reasonOfConsultation: 'sick',
						historyPhysicalFindings: null,
						diagnosis: null,
						planForManagement: null,
						transactionConsultationId: 'c0902419-b2e0-4e20-9743-75ccd60b4706',
						uploadedFile: null,
						createdAt: '2022-09-08T08:14:30.292Z',
						updatedAt: '2022-09-08T08:14:30.292Z',
					},
					history: [
						{
							historyId: '85146afa-ec32-41d9-a740-91a8bafd679b',
							transactionConsultationId: 'c0902419-b2e0-4e20-9743-75ccd60b4706',
							description: 'Update transaction status',
							newData: {
								transactionStatus: 'APPROVED',
							},
							createdByUserId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
							metaCreatedByUser: {
								action: 'login_success',
								userId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
								payload: {
									phoneNumber: '6381234',
								},
								userType: 'customer',
								sessionId:
									'1699ae200719ee1ec3efe71435ad586f79fb4a2585650b2d25a86b1887d748c4',
							},
							createdAt: '2022-09-08T08:49:35.017Z',
							updatedAt: '2022-09-08T08:49:35.017Z',
						},
						{
							historyId: '5d5cf85a-9330-439d-8179-a24dfc7c8f11',
							transactionConsultationId: 'c0902419-b2e0-4e20-9743-75ccd60b4706',
							description: 'Update transaction status',
							newData: {
								transactionStatus: 'WAITING_APPROVAL',
							},
							createdByUserId: '01GCDNAV6270SMVQBEAQ90VY4G',
							metaCreatedByUser: {
								city: null,
								name: 'Rizki Rahmadi',
								email: null,
								phone: '63125',
								doctor: null,
								gender: 'male',
								status: 'active',
								userId: '01GCDNAV6270SMVQBEAQ90VY4G',
								patient: {
									pwId: null,
									pwIdUrl: null,
									createdAt: '2022-09-08T04:22:06.889Z',
									patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
									updatedAt: '2022-09-08T04:22:06.889Z',
									pwIdStatus: 'WAITING_APPROVAL',
								},
								lastName: 'Rahmadi',
								nickName: 'Iki',
								province: null,
								userType: 'customer',
								birthdate: '1999-03-11',
								createdAt: '2022-09-08T04:22:06.787Z',
								firstName: 'Rizki',
								updatedAt: '2022-09-08T07:10:14.649Z',
								middleName: null,
								profilePic: null,
								isEmailVerified: false,
								isPhoneVerified: true,
							},
							createdAt: '2022-09-08T08:15:13.603Z',
							updatedAt: '2022-09-08T08:15:13.603Z',
						},
					],
					payment: {
						paymentId: '82262b86-0850-4abc-a909-88fe82116a09',
						invoiceId: 'CI/20220908/XED',
						transactionId: 'c0902419-b2e0-4e20-9743-75ccd60b4706',
						transactionType: 'IN_PERSON',
						totalNeedToPay: 575,
						paymentStatus: 'PAID_FULL',
						buyerId: '01GCDNAV6270SMVQBEAQ90VY4G',
						metaBuyerDetail: {
							city: null,
							name: 'Rizki Rahmadi',
							email: null,
							phone: '63125',
							doctor: null,
							gender: 'male',
							status: 'active',
							userId: '01GCDNAV6270SMVQBEAQ90VY4G',
							patient: {
								pwId: null,
								pwIdUrl: null,
								createdAt: '2022-09-08T04:22:06.889Z',
								patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
								updatedAt: '2022-09-08T04:22:06.889Z',
								pwIdStatus: 'WAITING_APPROVAL',
							},
							lastName: 'Rahmadi',
							nickName: 'Iki',
							province: null,
							userType: 'customer',
							birthdate: '1999-03-11',
							createdAt: '2022-09-08T04:22:06.787Z',
							firstName: 'Rizki',
							updatedAt: '2022-09-08T07:10:14.649Z',
							middleName: null,
							profilePic: null,
							isEmailVerified: false,
							isPhoneVerified: true,
						},
						meta: null,
						expiredAt: '2022-09-09T12:14:30.805Z',
						createdAt: '2022-09-08T08:14:30.820Z',
						updatedAt: '2022-09-08T08:15:13.523Z',
						paymentDetails: [
							{
								paymentDetailId: '4595cf49-42a4-4c90-a030-c460b2165ffb',
								paymentId: '82262b86-0850-4abc-a909-88fe82116a09',
								paymentType: 'BASE_PRICE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 500,
								status: 'PAID_FULL',
								meta: {
									consultationFee: 500,
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-09T12:14:30.805Z',
								createdAt: '2022-09-08T08:14:31.172Z',
								updatedAt: '2022-09-08T08:14:31.172Z',
							},
							{
								paymentDetailId: 'e06b3589-f5f9-4c97-b02b-0338730d0538',
								paymentId: '82262b86-0850-4abc-a909-88fe82116a09',
								paymentType: 'PATIENT_TRANSACTION_FEE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 75,
								status: 'PAID_FULL',
								meta: {
									key: 'PATIENT_TRANSACTION_FEE',
									value: '5%+50',
									configId: 'PATIENT_TRANSACTION_FEE',
									createdAt: '2022-09-08T06:08:45.373Z',
									updatedAt: '2022-09-08T06:08:45.373Z',
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-09T12:14:30.805Z',
								createdAt: '2022-09-08T08:14:31.211Z',
								updatedAt: '2022-09-08T08:14:31.211Z',
							},
							{
								paymentDetailId: '9d7624e9-17dd-4e45-b031-35a90060d0f2',
								paymentId: '82262b86-0850-4abc-a909-88fe82116a09',
								paymentType: 'PAYMENT_GATEWAY',
								paymentMethod: 'EWALLET',
								paymentVendorType: 'PAYMONGO',
								paymentVendorId: 'GCASH',
								externalId: null,
								totalPay: 575,
								status: 'PAID_FULL',
								meta: {
									data: {
										id: 'evt_oVnNycfvxCuKGHPtJLPLottN',
										type: 'event',
										attributes: {
											data: {
												id: 'pay_A24a2hpMHnfL7cCDPwe6cn3x',
												type: 'payment',
												attributes: {
													fee: 1438,
													taxes: [],
													amount: 57500,
													origin: 'api',
													payout: null,
													source: {
														id: 'src_jD2xVJkzq5LnMiiWUjmKokbN',
														type: 'gcash',
													},
													status: 'paid',
													billing: null,
													paid_at: 1662624896,
													refunds: [],
													currency: 'PHP',
													disputed: false,
													livemode: false,
													metadata: {
														paymentId: '82262b86-0850-4abc-a909-88fe82116a09',
													},
													access_url: null,
													created_at: 1662624896,
													net_amount: 56062,
													tax_amount: null,
													updated_at: 1662624896,
													description: null,
													available_at: 1662973200,
													payment_intent_id: null,
													statement_descriptor: 'PAYMONGO',
													balance_transaction_id:
														'bal_txn_KZfBT8DssLymf7mmJgdaXZhr',
													external_reference_number: null,
												},
											},
											type: 'payment.paid',
											livemode: false,
											created_at: 1662624896,
											updated_at: 1662624896,
											previous_data: {},
										},
									},
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-09T12:14:30.805Z',
								createdAt: '2022-09-08T08:14:31.126Z',
								updatedAt: '2022-09-08T08:15:13.440Z',
							},
						],
						paymentDetailsPartners: [
							{
								paymentDetailId: '76d1c3ba-0921-4c80-b0e0-b6eeca37a4f5',
								paymentId: '82262b86-0850-4abc-a909-88fe82116a09',
								paymentType: 'FINAL_PAYMENT',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 483,
								status: 'PAID_FULL',
								meta: {},
								type: 'PAYMENT',
								expiredAt: '2022-09-09T12:14:30.805Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-08T08:14:31.341Z',
								updatedAt: '2022-09-08T08:14:31.341Z',
							},
							{
								paymentDetailId: '776d082d-2a8b-4bac-9a46-d852d4af6c39',
								paymentId: '82262b86-0850-4abc-a909-88fe82116a09',
								paymentType: 'DOCTOR_TRANSACTION_FEE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 17,
								status: 'PAID_FULL',
								meta: {
									key: 'DOCTOR_TRANSACTION_FEE',
									value: '3%',
									configId: 'DOCTOR_TRANSACTION_FEE',
									createdAt: '2022-09-08T06:08:45.373Z',
									updatedAt: '2022-09-08T06:08:45.373Z',
								},
								type: 'DISCOUNT',
								expiredAt: '2022-09-09T12:14:30.805Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-08T08:14:31.341Z',
								updatedAt: '2022-09-08T08:14:31.341Z',
							},
							{
								paymentDetailId: '85c71e4d-551c-4bcc-b39a-dd39197bddb4',
								paymentId: '82262b86-0850-4abc-a909-88fe82116a09',
								paymentType: 'DOCTOR_COMMISSION_FEE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 0,
								status: 'PAID_FULL',
								meta: {
									key: 'DOCTOR_COMMISSION_FEE',
									value: '',
									configId: 'DOCTOR_COMMISSION_FEE',
									createdAt: '2022-09-08T07:59:04.588Z',
									updatedAt: '2022-09-08T07:59:04.588Z',
								},
								type: 'DISCOUNT',
								expiredAt: '2022-09-09T12:14:30.805Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-08T08:14:31.341Z',
								updatedAt: '2022-09-08T08:14:31.341Z',
							},
							{
								paymentDetailId: 'f471ebbe-6308-4d9f-b0ce-c2d8da85775b',
								paymentId: '82262b86-0850-4abc-a909-88fe82116a09',
								paymentType: 'PATIENT_PWD_DISCOUNT',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 0,
								status: 'PAID_FULL',
								meta: {},
								type: 'DISCOUNT',
								expiredAt: '2022-09-09T12:14:30.805Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-08T08:14:31.341Z',
								updatedAt: '2022-09-08T08:14:31.341Z',
							},
							{
								paymentDetailId: 'e44419dc-dd8a-4e03-ab35-68c8860bb925',
								paymentId: '82262b86-0850-4abc-a909-88fe82116a09',
								paymentType: 'BASE_PRICE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 500,
								status: 'PAID_FULL',
								meta: {},
								type: 'PAYMENT',
								expiredAt: '2022-09-09T12:14:30.805Z',
								partnerType: 'DOCTOR',
								createdAt: '2022-09-08T08:14:31.341Z',
								updatedAt: '2022-09-08T08:14:31.341Z',
							},
						],
					},
				},
			},
		],
		myRole: 'patient',
	},
	{
		name: 'consult-now',
		data: [
			{
				name: 'Rizki Rahmadi',
				date: '2022-09-14',
				desc: 'Lapar',
				reason: 'Lapar',
				startTime: '19:40',
				endTime: '20:10',
				detailDoctor: {
					name: 'Yuzuru Nishimiya',
					title: 'General Surgery, Radiology',
					img: 'https://qbit-tech.sgp1.digitaloceanspaces.com/profiles/068d2772-3339-40e0-86ed-cbed093fa373',
					age: 14,
					ageString: '14 yo',
				},
				raw: {
					transactionConsultationId: 'c1a7b585-2656-4379-88ea-1b04de46dda3',
					doctorId: '01GCFQTBV6C40MHRJX1RECQ48V',
					patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
					patientInRelationId: null,
					partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
					consultationType: 'CONSULT_NOW_CHAT_CONSULTATION',
					scheduledStartAt: '2022-09-14T12:40:00.667Z',
					scheduledEndAt: '2022-09-14T13:10:00.667Z',
					isCanExtend: null,
					doctorJoinAt: null,
					doctorEndAt: null,
					patientJoinAt: null,
					patientEndAt: null,
					transactionStatus: 'COMPLETED',
					lastUpdatedByUser: {
						action: 'login_success',
						userId: '01GCFQTBV6C40MHRJX1RECQ48V',
						payload: {
							phoneNumber: '630',
						},
						userType: 'customer',
						sessionId:
							'dae45ff062e5f223183db3caeed1aa5f7cd7449d2c491d53e40f822e7ca76e06',
					},
					description: null,
					place: null,
					link: null,
					metaPatient: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						updatedAt: '2022-09-08T07:10:14.649Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					metaPatientInRelation: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
						updatedAt: '2022-09-08T07:10:14.649Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					metaDoctor: {
						user: {
							city: null,
							name: 'Yuzuru Nishimiya',
							email: null,
							phone: '630',
							gender: 'male',
							status: 'active',
							userId: '01GCFQTBV6C40MHRJX1RECQ48V',
							lastName: 'Nishimiya',
							nickName: 'Yuzuru',
							province: null,
							userType: 'customer',
							birthdate: '2008-05-13',
							createdAt: '2022-09-08T23:44:01.383Z',
							firstName: 'Yuzuru',
							updatedAt: '2022-09-09T00:00:41.704Z',
							middleName: null,
							profilePic:
								'https://qbit-tech.sgp1.digitaloceanspaces.com/profiles/068d2772-3339-40e0-86ed-cbed093fa373',
						},
						title: 'M.D.',
						doctorId: '01GCFQTBV6C40MHRJX1RECQ48V',
						partners: [
							{
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								doctorId: '01GCFQTBV6C40MHRJX1RECQ48V',
								createdAt: '2022-09-08T23:51:58.330Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-09T00:09:46.701Z',
								pricePerSession: 199,
							},
							{
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								doctorId: '01GCFQTBV6C40MHRJX1RECQ48V',
								createdAt: '2022-09-08T23:54:58.060Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-09T00:09:33.638Z',
								pricePerSession: 300,
							},
							{
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								doctorId: '01GCFQTBV6C40MHRJX1RECQ48V',
								createdAt: '2022-09-08T23:52:07.812Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T23:52:07.812Z',
								pricePerSession: null,
							},
						],
						createdAt: '2022-09-08T23:51:18.468Z',
						kycStatus: 'APPROVED',
						prcNumber: '',
						ptrNumber: '',
						schedules: [
							{
								endAt: '2022-01-01T16:59:00.853Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.939Z',
								weekday: 0,
								doctorId: '01GCFQTBV6C40MHRJX1RECQ48V',
								createdAt: '2022-09-08T23:52:23.543Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T23:52:23.543Z',
								scheduleId: '8df69a2a-684c-461b-a92a-8265f464cec4',
							},
							{
								endAt: '2022-01-01T16:59:00.304Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.052Z',
								weekday: 1,
								doctorId: '01GCFQTBV6C40MHRJX1RECQ48V',
								createdAt: '2022-09-08T23:52:35.898Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T23:52:35.898Z',
								scheduleId: '708bec11-b571-4b3a-91fe-bdfa96dff5be',
							},
							{
								endAt: '2022-01-01T16:59:00.022Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.789Z',
								weekday: 2,
								doctorId: '01GCFQTBV6C40MHRJX1RECQ48V',
								createdAt: '2022-09-08T23:52:47.319Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T23:52:47.319Z',
								scheduleId: '0d5896e1-7069-421a-ad66-c214d1008855',
							},
							{
								endAt: '2022-01-01T16:59:00.384Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.593Z',
								weekday: 3,
								doctorId: '01GCFQTBV6C40MHRJX1RECQ48V',
								createdAt: '2022-09-08T23:52:58.755Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T23:52:58.755Z',
								scheduleId: 'fb3d3205-f37b-42ae-94be-e555f3c0a482',
							},
							{
								endAt: '2022-01-01T16:59:00.222Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.173Z',
								weekday: 4,
								doctorId: '01GCFQTBV6C40MHRJX1RECQ48V',
								createdAt: '2022-09-08T23:53:09.706Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T23:53:09.706Z',
								scheduleId: '7fd0b176-def2-4330-9f3a-31db30a3f355',
							},
							{
								endAt: '2022-01-01T16:59:00.124Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.976Z',
								weekday: 5,
								doctorId: '01GCFQTBV6C40MHRJX1RECQ48V',
								createdAt: '2022-09-08T23:53:21.353Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T23:53:21.353Z',
								scheduleId: 'ce87369c-6d48-4812-acee-52442b72bae3',
							},
							{
								endAt: '2022-01-01T16:59:00.571Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.755Z',
								weekday: 6,
								doctorId: '01GCFQTBV6C40MHRJX1RECQ48V',
								createdAt: '2022-09-08T23:53:31.417Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-08T23:53:31.417Z',
								scheduleId: '47a22ec0-4fe6-4b10-b136-af5c016b5ead',
							},
							{
								endAt: '2022-01-01T16:59:00.123Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.501Z',
								weekday: 0,
								doctorId: '01GCFQTBV6C40MHRJX1RECQ48V',
								createdAt: '2022-09-08T23:53:46.390Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T23:53:46.390Z',
								scheduleId: '1e684f9f-020f-4629-bc90-9caf5e8ea5e5',
							},
							{
								endAt: '2022-01-01T16:59:00.989Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.772Z',
								weekday: 1,
								doctorId: '01GCFQTBV6C40MHRJX1RECQ48V',
								createdAt: '2022-09-08T23:53:55.785Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T23:53:55.785Z',
								scheduleId: '4d474725-87f9-4a16-8470-a98ef6a4d924',
							},
							{
								endAt: '2022-01-01T16:59:00.772Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.005Z',
								weekday: 2,
								doctorId: '01GCFQTBV6C40MHRJX1RECQ48V',
								createdAt: '2022-09-08T23:54:04.900Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T23:54:04.900Z',
								scheduleId: '0c366126-5d36-4d3f-a27f-81616d26a3a3',
							},
							{
								endAt: '2022-01-01T16:59:00.053Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.621Z',
								weekday: 3,
								doctorId: '01GCFQTBV6C40MHRJX1RECQ48V',
								createdAt: '2022-09-08T23:54:13.897Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T23:54:13.897Z',
								scheduleId: '18c37415-74df-40ac-b47e-e0b4c90b1632',
							},
							{
								endAt: '2022-01-01T16:59:00.753Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.992Z',
								weekday: 4,
								doctorId: '01GCFQTBV6C40MHRJX1RECQ48V',
								createdAt: '2022-09-08T23:54:23.261Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T23:54:23.261Z',
								scheduleId: '4b02b011-58d4-44fa-9a6e-a8c9eb0350a6',
							},
							{
								endAt: '2022-01-01T16:59:00.854Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.436Z',
								weekday: 5,
								doctorId: '01GCFQTBV6C40MHRJX1RECQ48V',
								createdAt: '2022-09-08T23:54:32.694Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T23:54:32.694Z',
								scheduleId: 'b271bb2b-866f-41bb-bbfb-2886efbaea7e',
							},
							{
								endAt: '2022-01-01T16:59:00.822Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.420Z',
								weekday: 6,
								doctorId: '01GCFQTBV6C40MHRJX1RECQ48V',
								createdAt: '2022-09-08T23:54:41.936Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-08T23:54:41.936Z',
								scheduleId: '57bb3a9a-37cc-4630-b0fc-3960b53dfa58',
							},
							{
								endAt: '2022-01-01T16:59:00.638Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.531Z',
								weekday: 0,
								doctorId: '01GCFQTBV6C40MHRJX1RECQ48V',
								createdAt: '2022-09-08T23:55:10.239Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T23:55:10.239Z',
								scheduleId: '9dddbe5a-af1a-4d13-927e-2a3d37e5fbdb',
							},
							{
								endAt: '2022-01-01T16:59:00.655Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.995Z',
								weekday: 1,
								doctorId: '01GCFQTBV6C40MHRJX1RECQ48V',
								createdAt: '2022-09-08T23:55:19.312Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T23:55:19.312Z',
								scheduleId: '8ef2c1b3-8256-4be2-b87a-98743cf38761',
							},
							{
								endAt: '2022-01-01T16:59:00.024Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.636Z',
								weekday: 2,
								doctorId: '01GCFQTBV6C40MHRJX1RECQ48V',
								createdAt: '2022-09-08T23:55:27.452Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T23:55:27.452Z',
								scheduleId: '83dc4cda-64d0-4c6b-9712-35d4f1127555',
							},
							{
								endAt: '2022-01-01T16:59:00.116Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.638Z',
								weekday: 3,
								doctorId: '01GCFQTBV6C40MHRJX1RECQ48V',
								createdAt: '2022-09-08T23:55:35.832Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T23:55:35.832Z',
								scheduleId: 'bcc7db93-1921-4b32-9218-477117cf5d93',
							},
							{
								endAt: '2022-01-01T16:59:00.972Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.596Z',
								weekday: 4,
								doctorId: '01GCFQTBV6C40MHRJX1RECQ48V',
								createdAt: '2022-09-08T23:55:44.213Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T23:55:44.213Z',
								scheduleId: '17362202-dedf-49c2-b804-70ec972118dd',
							},
							{
								endAt: '2022-01-01T16:59:00.206Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.119Z',
								weekday: 5,
								doctorId: '01GCFQTBV6C40MHRJX1RECQ48V',
								createdAt: '2022-09-08T23:55:56.583Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T23:55:56.583Z',
								scheduleId: '8c39a037-d102-4e2b-8db6-900f9bf846e0',
							},
							{
								endAt: '2022-01-01T16:59:00.568Z',
								partner: {
									email: null,
									phone: '08234567890',
									address:
										'Jl. Sapta Marga No.105, Sapta Marga, Kec. Cakranegara, Kota Mataram, Nusa Tenggara Bar. 83121',
									createdAt: '2022-09-08T07:01:21.769Z',
									partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
									updatedAt: '2022-09-08T07:05:30.929Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Klinik PPK 1 Gebang',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/957ae4ef-7116-4344-8294-10ccae2bea02',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.035Z',
								weekday: 6,
								doctorId: '01GCFQTBV6C40MHRJX1RECQ48V',
								createdAt: '2022-09-08T23:56:05.715Z',
								partnerId: '4ad38158-622a-4ff6-b969-8067ae044c70',
								updatedAt: '2022-09-08T23:56:05.715Z',
								scheduleId: 'ab4300a7-1679-422f-9e28-7e0db8da1efd',
							},
						],
						startWork: null,
						tinNumber: '',
						updatedAt: '2022-09-08T23:57:27.117Z',
						educations: [],
						experience: 10,
						orderScore: 2,
						specialists: [
							{
								icon: 'general_surgery',
								level: 1,
								createdAt: '2022-09-08T03:16:01.498Z',
								updatedAt: '2022-09-08T03:16:01.498Z',
								specialistId: '3d25d57c-d0f4-4011-aef4-f300b5d3b9ea',
								specialistName: 'General Surgery',
								parentSpecialistId: 'dafe825a-cc64-4b00-8d7d-e7ed8adab9ca',
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCFQTBV6C40MHRJX1RECQ48V',
									specialistId: '3d25d57c-d0f4-4011-aef4-f300b5d3b9ea',
								},
							},
							{
								icon: 'radiology',
								level: 1,
								createdAt: '2022-09-08T03:16:01.498Z',
								updatedAt: '2022-09-08T03:16:01.498Z',
								specialistId: '7f794ac4-8e0f-4139-b4a9-b477dd3905cb',
								specialistName: 'Radiology',
								parentSpecialistId: 'dafe825a-cc64-4b00-8d7d-e7ed8adab9ca',
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCFQTBV6C40MHRJX1RECQ48V',
									specialistId: '7f794ac4-8e0f-4139-b4a9-b477dd3905cb',
								},
							},
						],
						certificates: [],
						nameOfSignature: 'Yuzuru Nishimiya, M.D.',
						otherSpecialist: '',
						signatureImageUrl:
							'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/9048ac7f-c5ce-4052-85c3-a68a6c83fcaa',
						specialityCertificate: null,
					},
					metaPartner: {
						partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
						isPublished: true,
						partnerName: 'Consult Now',
						partnerType: 'CONSULT_NOW',
						isConfirmedPartner: true,
					},
					createdByUserId: '01GCDNAV6270SMVQBEAQ90VY4G',
					metaCreatedByUser: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						updatedAt: '2022-09-08T07:10:14.649Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					createdAt: '2022-09-14T12:39:11.584Z',
					updatedAt: '2022-09-14T12:47:09.319Z',
					medicalRecord: {
						medicalRecordId: '2f8b912d-b8fd-440b-9b8a-4851b6dffaf1',
						doctorId: '01GCDTH9AHPD5RK8TJDR7B6ZHG',
						patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patientInRelationId: null,
						reasonOfConsultation: 'Lapar',
						historyPhysicalFindings: null,
						diagnosis: null,
						planForManagement: null,
						transactionConsultationId: 'c1a7b585-2656-4379-88ea-1b04de46dda3',
						uploadedFile: null,
						createdAt: '2022-09-14T12:39:11.594Z',
						updatedAt: '2022-09-14T12:39:11.594Z',
					},
					history: [
						{
							historyId: 'cc88d7a4-5bd5-4123-bd14-28be36c84d60',
							transactionConsultationId: 'c1a7b585-2656-4379-88ea-1b04de46dda3',
							description: 'Update transaction status',
							newData: {
								transactionStatus: 'WAITING_APPROVAL',
							},
							createdByUserId: '01GCDNAV6270SMVQBEAQ90VY4G',
							metaCreatedByUser: {
								city: null,
								name: 'Rizki Rahmadi',
								email: null,
								phone: '63125',
								doctor: null,
								gender: 'male',
								status: 'active',
								userId: '01GCDNAV6270SMVQBEAQ90VY4G',
								patient: {
									pwId: null,
									pwIdUrl: null,
									createdAt: '2022-09-08T04:22:06.889Z',
									patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
									updatedAt: '2022-09-08T04:22:06.889Z',
									pwIdStatus: 'WAITING_APPROVAL',
								},
								lastName: 'Rahmadi',
								nickName: 'Iki',
								province: null,
								userType: 'customer',
								birthdate: '1999-03-11',
								createdAt: '2022-09-08T04:22:06.787Z',
								firstName: 'Rizki',
								updatedAt: '2022-09-08T07:10:14.649Z',
								middleName: null,
								profilePic: null,
								isEmailVerified: false,
								isPhoneVerified: true,
							},
							createdAt: '2022-09-14T12:39:46.811Z',
							updatedAt: '2022-09-14T12:39:46.811Z',
						},
						{
							historyId: 'd1eaf4ce-9d7d-44f0-96cf-715ed44913f7',
							transactionConsultationId: 'c1a7b585-2656-4379-88ea-1b04de46dda3',
							description: 'Update transaction status',
							newData: {
								transactionStatus: 'APPROVED',
							},
							createdByUserId: '01GCFQTBV6C40MHRJX1RECQ48V',
							metaCreatedByUser: {
								action: 'login_success',
								userId: '01GCFQTBV6C40MHRJX1RECQ48V',
								payload: {
									phoneNumber: '630',
								},
								userType: 'customer',
								sessionId:
									'dae45ff062e5f223183db3caeed1aa5f7cd7449d2c491d53e40f822e7ca76e06',
							},
							createdAt: '2022-09-14T12:41:18.395Z',
							updatedAt: '2022-09-14T12:41:18.395Z',
						},
						{
							historyId: '9b8aa89d-3d3b-4c41-91fa-37e44d92f2c4',
							transactionConsultationId: 'c1a7b585-2656-4379-88ea-1b04de46dda3',
							description: 'Update transaction status',
							newData: {
								transactionStatus: 'COMPLETED',
							},
							createdByUserId: '01GCFQTBV6C40MHRJX1RECQ48V',
							metaCreatedByUser: {
								action: 'login_success',
								userId: '01GCFQTBV6C40MHRJX1RECQ48V',
								payload: {
									phoneNumber: '630',
								},
								userType: 'customer',
								sessionId:
									'dae45ff062e5f223183db3caeed1aa5f7cd7449d2c491d53e40f822e7ca76e06',
							},
							createdAt: '2022-09-14T12:47:09.327Z',
							updatedAt: '2022-09-14T12:47:09.327Z',
						},
					],
					payment: {
						paymentId: '50d461e1-dba7-4b3f-8854-7bcfa95efe16',
						invoiceId: 'CC/20220914/HEP',
						transactionId: 'c1a7b585-2656-4379-88ea-1b04de46dda3',
						transactionType: 'CONSULT_NOW',
						totalNeedToPay: 103,
						paymentStatus: 'PAID_FULL',
						buyerId: '01GCDNAV6270SMVQBEAQ90VY4G',
						metaBuyerDetail: {
							city: null,
							name: 'Rizki Rahmadi',
							email: null,
							phone: '63125',
							doctor: null,
							gender: 'male',
							status: 'active',
							userId: '01GCDNAV6270SMVQBEAQ90VY4G',
							patient: {
								pwId: null,
								pwIdUrl: null,
								createdAt: '2022-09-08T04:22:06.889Z',
								patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
								updatedAt: '2022-09-08T04:22:06.889Z',
								pwIdStatus: 'WAITING_APPROVAL',
							},
							lastName: 'Rahmadi',
							nickName: 'Iki',
							province: null,
							userType: 'customer',
							birthdate: '1999-03-11',
							createdAt: '2022-09-08T04:22:06.787Z',
							firstName: 'Rizki',
							updatedAt: '2022-09-08T07:10:14.649Z',
							middleName: null,
							profilePic: null,
							isEmailVerified: false,
							isPhoneVerified: true,
						},
						meta: null,
						expiredAt: '2022-09-15T16:39:11.789Z',
						createdAt: '2022-09-14T12:39:11.790Z',
						updatedAt: '2022-09-14T12:39:46.792Z',
						paymentDetails: [
							{
								paymentDetailId: '801dd610-6136-4b92-addd-7efc91b02cab',
								paymentId: '50d461e1-dba7-4b3f-8854-7bcfa95efe16',
								paymentType: 'BASE_PRICE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 50,
								status: 'PAID_FULL',
								meta: {
									consultationFee: 50,
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-15T16:39:11.789Z',
								createdAt: '2022-09-14T12:39:11.866Z',
								updatedAt: '2022-09-14T12:39:11.866Z',
							},
							{
								paymentDetailId: 'b8777d36-0984-4c59-b7ca-a71b82c60d79',
								paymentId: '50d461e1-dba7-4b3f-8854-7bcfa95efe16',
								paymentType: 'PATIENT_TRANSACTION_FEE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 53,
								status: 'PAID_FULL',
								meta: {
									key: 'PATIENT_TRANSACTION_FEE',
									value: '5%+50',
									configId: 'PATIENT_TRANSACTION_FEE',
									createdAt: '2022-09-08T06:08:45.373Z',
									updatedAt: '2022-09-08T06:08:45.373Z',
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-15T16:39:11.789Z',
								createdAt: '2022-09-14T12:39:11.868Z',
								updatedAt: '2022-09-14T12:39:11.868Z',
							},
							{
								paymentDetailId: '6172edff-0861-4651-b5af-3c1c2d2f4507',
								paymentId: '50d461e1-dba7-4b3f-8854-7bcfa95efe16',
								paymentType: 'PAYMENT_GATEWAY',
								paymentMethod: 'EWALLET',
								paymentVendorType: 'PAYMONGO',
								paymentVendorId: 'GCASH',
								externalId: null,
								totalPay: 103,
								status: 'PAID_FULL',
								meta: {
									data: {
										id: 'evt_6FknKJ2dR9Wv8DQSftw2z4Fa',
										type: 'event',
										attributes: {
											data: {
												id: 'pay_YDAm5DkGXCtHQwRrqrVgY6fJ',
												type: 'payment',
												attributes: {
													fee: 258,
													taxes: [],
													amount: 10300,
													origin: 'api',
													payout: null,
													source: {
														id: 'src_ZVmVJuKCVmQGs4Q4VafntqUK',
														type: 'gcash',
													},
													status: 'paid',
													billing: null,
													paid_at: 1663159173,
													refunds: [],
													currency: 'PHP',
													disputed: false,
													livemode: false,
													metadata: {
														paymentId: '50d461e1-dba7-4b3f-8854-7bcfa95efe16',
													},
													access_url: null,
													created_at: 1663159173,
													net_amount: 10042,
													tax_amount: null,
													updated_at: 1663159173,
													description: null,
													available_at: 1663578000,
													payment_intent_id: null,
													statement_descriptor: 'PAYMONGO',
													balance_transaction_id:
														'bal_txn_TL3bbfyzdYSWxcy5rM1Dpxh7',
													external_reference_number: null,
												},
											},
											type: 'payment.paid',
											livemode: false,
											created_at: 1663159173,
											updated_at: 1663159173,
											previous_data: {},
										},
									},
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-15T16:39:11.789Z',
								createdAt: '2022-09-14T12:39:11.864Z',
								updatedAt: '2022-09-14T12:39:46.782Z',
							},
						],
						paymentDetailsPartners: [],
					},
				},
			},
			{
				name: 'Rizki Rahmadi',
				date: '2022-09-14',
				desc: 'Dicakar kucing',
				reason: 'Dicakar kucing',
				startTime: '18:56',
				endTime: '19:26',
				detailDoctor: {
					name: 'Ana Rois Shofiyana',
					title: 'General Practitioner (GP)',
					age: 23,
					ageString: '23 yo',
				},
				raw: {
					transactionConsultationId: '9da47e50-c502-4d0c-b245-d4c75c1d5a46',
					doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
					patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
					patientInRelationId: null,
					partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
					consultationType: 'CONSULT_NOW_CHAT_CONSULTATION',
					scheduledStartAt: '2022-09-14T11:56:00.530Z',
					scheduledEndAt: '2022-09-14T12:26:00.530Z',
					isCanExtend: null,
					doctorJoinAt: null,
					doctorEndAt: null,
					patientJoinAt: null,
					patientEndAt: null,
					transactionStatus: 'COMPLETED',
					lastUpdatedByUser: {
						action: 'login_success',
						userId: '01GCFZABXGYENY1C7ZGRZHKCWT',
						payload: {
							phoneNumber: '6383822446612',
						},
						userType: 'customer',
						sessionId:
							'ccca1e3f57c90e6217ee544f920ea59787edd329db9080642e5fa7a1a098119d',
					},
					description: null,
					place: null,
					link: null,
					metaPatient: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						updatedAt: '2022-09-08T07:10:14.649Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					metaPatientInRelation: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
						updatedAt: '2022-09-08T07:10:14.649Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					metaDoctor: {
						user: {
							city: null,
							name: 'Ana Rois Shofiyana',
							email: null,
							phone: '6383822446612',
							gender: 'female',
							status: 'active',
							userId: '01GCFZABXGYENY1C7ZGRZHKCWT',
							lastName: 'Shofiyana',
							nickName: 'Ana',
							province: null,
							userType: 'customer',
							birthdate: '1999-05-14',
							createdAt: '2022-09-09T01:55:05.781Z',
							firstName: 'Ana',
							updatedAt: '2022-09-09T11:22:32.902Z',
							middleName: 'Rois',
							profilePic: null,
						},
						title: 'S.Pd',
						doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
						partners: [
							{
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:14:18.292Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-09T11:18:48.250Z',
								pricePerSession: 120,
							},
							{
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:10:42.110Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-09T11:16:53.265Z',
								pricePerSession: 100,
							},
							{
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:10:49.878Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-09T11:10:49.878Z',
								pricePerSession: null,
							},
						],
						createdAt: '2022-09-09T11:10:00.227Z',
						kycStatus: 'APPROVED',
						prcNumber: '123456789001',
						ptrNumber: '123456789002',
						schedules: [
							{
								endAt: '2022-01-01T16:59:00.901Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.448Z',
								weekday: 0,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:12:18.480Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-09T11:12:18.480Z',
								scheduleId: '1641cff8-f159-40e9-9408-60607849221e',
							},
							{
								endAt: '2022-01-01T16:59:00.176Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.960Z',
								weekday: 1,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:12:37.581Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-09T11:12:37.581Z',
								scheduleId: '2af563cb-0f70-44ff-b997-6b087bf53e78',
							},
							{
								endAt: '2022-01-01T16:59:00.579Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.988Z',
								weekday: 2,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:13:05.748Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-09T11:13:05.748Z',
								scheduleId: 'c08105a7-db25-4796-b434-49a5b4f9cc67',
							},
							{
								endAt: '2022-01-01T16:59:00.883Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.301Z',
								weekday: 3,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:13:17.749Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-09T11:13:17.749Z',
								scheduleId: '548098d1-8526-43e9-b1cb-6a450253d125',
							},
							{
								endAt: '2022-01-01T16:59:00.288Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.124Z',
								weekday: 4,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:13:30.932Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-09T11:13:30.932Z',
								scheduleId: '850a0aa1-8638-4244-898b-878cf320f46c',
							},
							{
								endAt: '2022-01-01T16:59:00.413Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.530Z',
								weekday: 5,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:13:43.570Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-09T11:13:43.570Z',
								scheduleId: 'b3c928f6-3be7-4eea-8f11-95950ab23cad',
							},
							{
								endAt: '2022-01-01T16:59:00.406Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.863Z',
								weekday: 6,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:13:55.514Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-09T11:13:55.514Z',
								scheduleId: 'bcfbdbf1-adf3-4539-ae3a-9a72ca7569e2',
							},
							{
								endAt: '2022-01-01T11:00:00.253Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.523Z',
								weekday: 0,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:14:41.001Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-09T11:14:41.001Z',
								scheduleId: 'c69a3d4e-fcff-476c-8178-38176730b08b',
							},
							{
								endAt: '2022-01-01T11:00:00.895Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.149Z',
								weekday: 1,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:14:58.210Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-09T11:14:58.210Z',
								scheduleId: 'fda0dabc-0d7e-43ad-b761-cd8b7a737996',
							},
							{
								endAt: '2022-01-01T11:00:00.117Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.020Z',
								weekday: 2,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:15:11.927Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-09T11:15:11.927Z',
								scheduleId: 'ca409137-69c0-4514-ae8f-efa2dc85f5bf',
							},
							{
								endAt: '2022-01-01T11:00:00.090Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.784Z',
								weekday: 3,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:15:25.191Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-09T11:15:25.191Z',
								scheduleId: '29ace700-028e-42db-8fae-6f2b3e48f347',
							},
							{
								endAt: '2022-01-01T11:00:00.604Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.535Z',
								weekday: 4,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:15:36.873Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-09T11:15:36.873Z',
								scheduleId: 'febc2a36-aee9-4524-9a07-ba9aad3aa628',
							},
							{
								endAt: '2022-01-01T11:00:00.272Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.097Z',
								weekday: 5,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:15:49.307Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-09T11:15:49.307Z',
								scheduleId: '105c1d42-1398-43b9-820a-9fb8777d597f',
							},
							{
								endAt: '2022-01-01T11:00:00.193Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.406Z',
								weekday: 6,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:15:58.339Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-09T11:15:58.339Z',
								scheduleId: '8b139e68-aa4b-46d1-bb1b-af5d2391def1',
							},
							{
								endAt: '2022-01-01T16:00:00.641Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.003Z',
								weekday: 0,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:17:05.069Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-09T11:17:05.069Z',
								scheduleId: '772d38d8-26fc-4cf1-8ea6-dc38ff368201',
							},
							{
								endAt: '2022-01-01T16:00:00.237Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.319Z',
								weekday: 1,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:17:17.005Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-09T11:17:17.005Z',
								scheduleId: '561dbfde-24fd-4079-80fb-5eaa6f665a74',
							},
							{
								endAt: '2022-01-01T16:00:00.008Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.031Z',
								weekday: 2,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:17:32.927Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-09T11:17:32.927Z',
								scheduleId: '07eea797-e393-49d0-9283-1823e0624d6a',
							},
							{
								endAt: '2022-01-01T16:00:00.187Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.962Z',
								weekday: 3,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:18:00.844Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-09T11:18:00.844Z',
								scheduleId: '44b7ee81-2384-4534-a759-10de09961544',
							},
							{
								endAt: '2022-01-01T16:00:00.776Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.426Z',
								weekday: 4,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:18:12.134Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-09T11:18:12.134Z',
								scheduleId: 'b6506f8d-40f0-4634-903c-16f5c26482ca',
							},
							{
								endAt: '2022-01-01T16:00:00.205Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.321Z',
								weekday: 5,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:18:24.315Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-09T11:18:24.315Z',
								scheduleId: '4d585d59-42d0-43ba-82f1-590c0fee3b50',
							},
							{
								endAt: '2022-01-01T16:00:00.707Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.456Z',
								weekday: 6,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:18:35.743Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-09T11:18:35.743Z',
								scheduleId: '6d02667b-7629-4e66-88bb-92129dd611a4',
							},
						],
						startWork: null,
						tinNumber: '123456789003',
						updatedAt: '2022-09-09T11:59:26.663Z',
						educations: [],
						experience: 13,
						orderScore: 2,
						specialists: [
							{
								icon: 'general_practitioner',
								level: 0,
								createdAt: '2022-09-08T03:16:01.491Z',
								updatedAt: '2022-09-08T03:16:01.491Z',
								specialistId: 'dafe825a-cc64-4b00-8d7d-e7ed8adab9ca',
								specialistName: 'General Practitioner (GP)',
								parentSpecialistId: null,
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
									specialistId: 'dafe825a-cc64-4b00-8d7d-e7ed8adab9ca',
								},
							},
						],
						certificates: [],
						nameOfSignature: 'Dr. Ana Rois Shofiyana S.Pd',
						otherSpecialist: '',
						signatureImageUrl:
							'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/cdef65b1-80ee-41a0-8d80-a9c002bedeaa',
						specialityCertificate: null,
					},
					metaPartner: {
						partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
						isPublished: true,
						partnerName: 'Consult Now',
						partnerType: 'CONSULT_NOW',
						isConfirmedPartner: true,
					},
					createdByUserId: '01GCDNAV6270SMVQBEAQ90VY4G',
					metaCreatedByUser: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						updatedAt: '2022-09-08T07:10:14.649Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					createdAt: '2022-09-14T11:50:23.347Z',
					updatedAt: '2022-09-14T12:00:48.328Z',
					medicalRecord: {
						medicalRecordId: '50f37c82-3b19-47af-b186-6d9d3e8dd62c',
						doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
						patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patientInRelationId: null,
						reasonOfConsultation: 'Dicakar kucing',
						historyPhysicalFindings: null,
						diagnosis: null,
						planForManagement: null,
						transactionConsultationId: '9da47e50-c502-4d0c-b245-d4c75c1d5a46',
						uploadedFile: null,
						createdAt: '2022-09-14T11:50:23.356Z',
						updatedAt: '2022-09-14T11:50:23.356Z',
					},
					history: [
						{
							historyId: 'd43c39d8-60ae-4b07-acc2-5e07543266e2',
							transactionConsultationId: '9da47e50-c502-4d0c-b245-d4c75c1d5a46',
							description: 'Update transaction status',
							newData: {
								transactionStatus: 'WAITING_APPROVAL',
							},
							createdByUserId: '01GCDNAV6270SMVQBEAQ90VY4G',
							metaCreatedByUser: {
								city: null,
								name: 'Rizki Rahmadi',
								email: null,
								phone: '63125',
								doctor: null,
								gender: 'male',
								status: 'active',
								userId: '01GCDNAV6270SMVQBEAQ90VY4G',
								patient: {
									pwId: null,
									pwIdUrl: null,
									createdAt: '2022-09-08T04:22:06.889Z',
									patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
									updatedAt: '2022-09-08T04:22:06.889Z',
									pwIdStatus: 'WAITING_APPROVAL',
								},
								lastName: 'Rahmadi',
								nickName: 'Iki',
								province: null,
								userType: 'customer',
								birthdate: '1999-03-11',
								createdAt: '2022-09-08T04:22:06.787Z',
								firstName: 'Rizki',
								updatedAt: '2022-09-08T07:10:14.649Z',
								middleName: null,
								profilePic: null,
								isEmailVerified: false,
								isPhoneVerified: true,
							},
							createdAt: '2022-09-14T11:51:12.844Z',
							updatedAt: '2022-09-14T11:51:12.844Z',
						},
						{
							historyId: 'a41de174-79bc-45e8-ac19-36a8eb4c2a85',
							transactionConsultationId: '9da47e50-c502-4d0c-b245-d4c75c1d5a46',
							description: 'Update transaction status',
							newData: {
								transactionStatus: 'COMPLETED',
							},
							createdByUserId: '01GCFZABXGYENY1C7ZGRZHKCWT',
							metaCreatedByUser: {
								action: 'login_success',
								userId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								payload: {
									phoneNumber: '6383822446612',
								},
								userType: 'customer',
								sessionId:
									'ccca1e3f57c90e6217ee544f920ea59787edd329db9080642e5fa7a1a098119d',
							},
							createdAt: '2022-09-14T12:00:48.338Z',
							updatedAt: '2022-09-14T12:00:48.338Z',
						},
						{
							historyId: '51bd876f-760e-4fb0-88c4-2a8adbe47d9c',
							transactionConsultationId: '9da47e50-c502-4d0c-b245-d4c75c1d5a46',
							description: 'Update transaction status',
							newData: {
								transactionStatus: 'APPROVED',
							},
							createdByUserId: '01GCFZABXGYENY1C7ZGRZHKCWT',
							metaCreatedByUser: {
								action: 'login_success',
								userId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								payload: {
									phoneNumber: '6383822446612',
								},
								userType: 'customer',
								sessionId:
									'ccca1e3f57c90e6217ee544f920ea59787edd329db9080642e5fa7a1a098119d',
							},
							createdAt: '2022-09-14T11:57:19.178Z',
							updatedAt: '2022-09-14T11:57:19.178Z',
						},
					],
					payment: {
						paymentId: '5f4b36dd-e72c-4988-9c0f-be49cf004f6c',
						invoiceId: 'CC/20220914/XEF',
						transactionId: '9da47e50-c502-4d0c-b245-d4c75c1d5a46',
						transactionType: 'CONSULT_NOW',
						totalNeedToPay: 103,
						paymentStatus: 'PAID_FULL',
						buyerId: '01GCDNAV6270SMVQBEAQ90VY4G',
						metaBuyerDetail: {
							city: null,
							name: 'Rizki Rahmadi',
							email: null,
							phone: '63125',
							doctor: null,
							gender: 'male',
							status: 'active',
							userId: '01GCDNAV6270SMVQBEAQ90VY4G',
							patient: {
								pwId: null,
								pwIdUrl: null,
								createdAt: '2022-09-08T04:22:06.889Z',
								patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
								updatedAt: '2022-09-08T04:22:06.889Z',
								pwIdStatus: 'WAITING_APPROVAL',
							},
							lastName: 'Rahmadi',
							nickName: 'Iki',
							province: null,
							userType: 'customer',
							birthdate: '1999-03-11',
							createdAt: '2022-09-08T04:22:06.787Z',
							firstName: 'Rizki',
							updatedAt: '2022-09-08T07:10:14.649Z',
							middleName: null,
							profilePic: null,
							isEmailVerified: false,
							isPhoneVerified: true,
						},
						meta: null,
						expiredAt: '2022-09-15T15:50:23.517Z',
						createdAt: '2022-09-14T11:50:23.518Z',
						updatedAt: '2022-09-14T11:51:12.822Z',
						paymentDetails: [
							{
								paymentDetailId: 'b6bd6bf2-c42e-41e0-a185-cc564d0251c4',
								paymentId: '5f4b36dd-e72c-4988-9c0f-be49cf004f6c',
								paymentType: 'BASE_PRICE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 50,
								status: 'PAID_FULL',
								meta: {
									consultationFee: 50,
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-15T15:50:23.517Z',
								createdAt: '2022-09-14T11:50:23.581Z',
								updatedAt: '2022-09-14T11:50:23.581Z',
							},
							{
								paymentDetailId: 'eedb963c-da13-4ef7-bccb-fede0b4a4c39',
								paymentId: '5f4b36dd-e72c-4988-9c0f-be49cf004f6c',
								paymentType: 'PATIENT_TRANSACTION_FEE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 53,
								status: 'PAID_FULL',
								meta: {
									key: 'PATIENT_TRANSACTION_FEE',
									value: '5%+50',
									configId: 'PATIENT_TRANSACTION_FEE',
									createdAt: '2022-09-08T06:08:45.373Z',
									updatedAt: '2022-09-08T06:08:45.373Z',
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-15T15:50:23.517Z',
								createdAt: '2022-09-14T11:50:23.582Z',
								updatedAt: '2022-09-14T11:50:23.582Z',
							},
							{
								paymentDetailId: '0319eb33-4b21-48dd-bb7c-5310ec66368b',
								paymentId: '5f4b36dd-e72c-4988-9c0f-be49cf004f6c',
								paymentType: 'PAYMENT_GATEWAY',
								paymentMethod: 'EWALLET',
								paymentVendorType: 'PAYMONGO',
								paymentVendorId: 'GCASH',
								externalId: null,
								totalPay: 103,
								status: 'PAID_FULL',
								meta: {
									data: {
										id: 'evt_BKMA3jsDLc9grGPNgwuTSV4w',
										type: 'event',
										attributes: {
											data: {
												id: 'pay_B298LrAaL5PjFGYZr8auRQuY',
												type: 'payment',
												attributes: {
													fee: 258,
													taxes: [],
													amount: 10300,
													origin: 'api',
													payout: null,
													source: {
														id: 'src_wxLthgZEy6QsnJw35XxPwxN7',
														type: 'gcash',
													},
													status: 'paid',
													billing: null,
													paid_at: 1663156253,
													refunds: [],
													currency: 'PHP',
													disputed: false,
													livemode: false,
													metadata: {
														paymentId: '5f4b36dd-e72c-4988-9c0f-be49cf004f6c',
													},
													access_url: null,
													created_at: 1663156253,
													net_amount: 10042,
													tax_amount: null,
													updated_at: 1663156253,
													description: null,
													available_at: 1663578000,
													payment_intent_id: null,
													statement_descriptor: 'PAYMONGO',
													balance_transaction_id:
														'bal_txn_pz1k9NdCvwjxB7Mghbu7a9b1',
													external_reference_number: null,
												},
											},
											type: 'payment.paid',
											livemode: false,
											created_at: 1663156253,
											updated_at: 1663156253,
											previous_data: {},
										},
									},
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-15T15:50:23.517Z',
								createdAt: '2022-09-14T11:50:23.578Z',
								updatedAt: '2022-09-14T11:51:12.811Z',
							},
						],
						paymentDetailsPartners: [],
					},
				},
			},
			{
				name: 'Rizki Rahmadi',
				date: '2022-09-13',
				desc: null,
				reason: null,
				startTime: '20:15',
				endTime: '20:45',
				detailDoctor: {
					name: 'Ana Rois Shofiyana',
					title: 'General Practitioner (GP)',
					age: 23,
					ageString: '23 yo',
				},
				raw: {
					transactionConsultationId: 'baefb1e8-3795-45dc-a662-3edf19e9b5be',
					doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
					patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
					patientInRelationId: null,
					partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
					consultationType: 'CONSULT_NOW_CHAT_CONSULTATION',
					scheduledStartAt: '2022-09-13T13:15:57.997Z',
					scheduledEndAt: '2022-09-13T13:45:57.997Z',
					isCanExtend: null,
					doctorJoinAt: null,
					doctorEndAt: null,
					patientJoinAt: null,
					patientEndAt: null,
					transactionStatus: 'APPROVED',
					lastUpdatedByUser: {
						action: 'login_success',
						userId: '01GCFZABXGYENY1C7ZGRZHKCWT',
						payload: {
							phoneNumber: '6383822446612',
						},
						userType: 'customer',
						sessionId:
							'2dd7aa8a80c235d4c3d9b6b56e2d0c9e61357512c391e3947fe3aacfeb0a8e7f',
					},
					description: null,
					place: null,
					link: null,
					metaPatient: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						updatedAt: '2022-09-08T07:10:14.649Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					metaPatientInRelation: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
						updatedAt: '2022-09-08T07:10:14.649Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					metaDoctor: {
						user: {
							city: null,
							name: 'Ana Rois Shofiyana',
							email: null,
							phone: '6383822446612',
							gender: 'female',
							status: 'active',
							userId: '01GCFZABXGYENY1C7ZGRZHKCWT',
							lastName: 'Shofiyana',
							nickName: 'Ana',
							province: null,
							userType: 'customer',
							birthdate: '1999-05-14',
							createdAt: '2022-09-09T01:55:05.781Z',
							firstName: 'Ana',
							updatedAt: '2022-09-09T11:22:32.902Z',
							middleName: 'Rois',
							profilePic: null,
						},
						title: 'S.Pd',
						doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
						partners: [
							{
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:14:18.292Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-09T11:18:48.250Z',
								pricePerSession: 120,
							},
							{
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:10:42.110Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-09T11:16:53.265Z',
								pricePerSession: 100,
							},
							{
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:10:49.878Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-09T11:10:49.878Z',
								pricePerSession: null,
							},
						],
						createdAt: '2022-09-09T11:10:00.227Z',
						kycStatus: 'APPROVED',
						prcNumber: '123456789001',
						ptrNumber: '123456789002',
						schedules: [
							{
								endAt: '2022-01-01T16:59:00.901Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.448Z',
								weekday: 0,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:12:18.480Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-09T11:12:18.480Z',
								scheduleId: '1641cff8-f159-40e9-9408-60607849221e',
							},
							{
								endAt: '2022-01-01T16:59:00.176Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.960Z',
								weekday: 1,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:12:37.581Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-09T11:12:37.581Z',
								scheduleId: '2af563cb-0f70-44ff-b997-6b087bf53e78',
							},
							{
								endAt: '2022-01-01T16:59:00.579Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.988Z',
								weekday: 2,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:13:05.748Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-09T11:13:05.748Z',
								scheduleId: 'c08105a7-db25-4796-b434-49a5b4f9cc67',
							},
							{
								endAt: '2022-01-01T16:59:00.883Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.301Z',
								weekday: 3,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:13:17.749Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-09T11:13:17.749Z',
								scheduleId: '548098d1-8526-43e9-b1cb-6a450253d125',
							},
							{
								endAt: '2022-01-01T16:59:00.288Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.124Z',
								weekday: 4,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:13:30.932Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-09T11:13:30.932Z',
								scheduleId: '850a0aa1-8638-4244-898b-878cf320f46c',
							},
							{
								endAt: '2022-01-01T16:59:00.413Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.530Z',
								weekday: 5,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:13:43.570Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-09T11:13:43.570Z',
								scheduleId: 'b3c928f6-3be7-4eea-8f11-95950ab23cad',
							},
							{
								endAt: '2022-01-01T16:59:00.406Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.863Z',
								weekday: 6,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:13:55.514Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-09T11:13:55.514Z',
								scheduleId: 'bcfbdbf1-adf3-4539-ae3a-9a72ca7569e2',
							},
							{
								endAt: '2022-01-01T11:00:00.253Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.523Z',
								weekday: 0,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:14:41.001Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-09T11:14:41.001Z',
								scheduleId: 'c69a3d4e-fcff-476c-8178-38176730b08b',
							},
							{
								endAt: '2022-01-01T11:00:00.895Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.149Z',
								weekday: 1,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:14:58.210Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-09T11:14:58.210Z',
								scheduleId: 'fda0dabc-0d7e-43ad-b761-cd8b7a737996',
							},
							{
								endAt: '2022-01-01T11:00:00.117Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.020Z',
								weekday: 2,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:15:11.927Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-09T11:15:11.927Z',
								scheduleId: 'ca409137-69c0-4514-ae8f-efa2dc85f5bf',
							},
							{
								endAt: '2022-01-01T11:00:00.090Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.784Z',
								weekday: 3,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:15:25.191Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-09T11:15:25.191Z',
								scheduleId: '29ace700-028e-42db-8fae-6f2b3e48f347',
							},
							{
								endAt: '2022-01-01T11:00:00.604Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.535Z',
								weekday: 4,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:15:36.873Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-09T11:15:36.873Z',
								scheduleId: 'febc2a36-aee9-4524-9a07-ba9aad3aa628',
							},
							{
								endAt: '2022-01-01T11:00:00.272Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.097Z',
								weekday: 5,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:15:49.307Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-09T11:15:49.307Z',
								scheduleId: '105c1d42-1398-43b9-820a-9fb8777d597f',
							},
							{
								endAt: '2022-01-01T11:00:00.193Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.406Z',
								weekday: 6,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:15:58.339Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-09T11:15:58.339Z',
								scheduleId: '8b139e68-aa4b-46d1-bb1b-af5d2391def1',
							},
							{
								endAt: '2022-01-01T16:00:00.641Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.003Z',
								weekday: 0,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:17:05.069Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-09T11:17:05.069Z',
								scheduleId: '772d38d8-26fc-4cf1-8ea6-dc38ff368201',
							},
							{
								endAt: '2022-01-01T16:00:00.237Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.319Z',
								weekday: 1,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:17:17.005Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-09T11:17:17.005Z',
								scheduleId: '561dbfde-24fd-4079-80fb-5eaa6f665a74',
							},
							{
								endAt: '2022-01-01T16:00:00.008Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.031Z',
								weekday: 2,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:17:32.927Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-09T11:17:32.927Z',
								scheduleId: '07eea797-e393-49d0-9283-1823e0624d6a',
							},
							{
								endAt: '2022-01-01T16:00:00.187Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.962Z',
								weekday: 3,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:18:00.844Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-09T11:18:00.844Z',
								scheduleId: '44b7ee81-2384-4534-a759-10de09961544',
							},
							{
								endAt: '2022-01-01T16:00:00.776Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.426Z',
								weekday: 4,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:18:12.134Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-09T11:18:12.134Z',
								scheduleId: 'b6506f8d-40f0-4634-903c-16f5c26482ca',
							},
							{
								endAt: '2022-01-01T16:00:00.205Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.321Z',
								weekday: 5,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:18:24.315Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-09T11:18:24.315Z',
								scheduleId: '4d585d59-42d0-43ba-82f1-590c0fee3b50',
							},
							{
								endAt: '2022-01-01T16:00:00.707Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.456Z',
								weekday: 6,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:18:35.743Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-09T11:18:35.743Z',
								scheduleId: '6d02667b-7629-4e66-88bb-92129dd611a4',
							},
						],
						startWork: null,
						tinNumber: '123456789003',
						updatedAt: '2022-09-09T11:59:26.663Z',
						educations: [],
						experience: 13,
						orderScore: 2,
						specialists: [
							{
								icon: 'general_practitioner',
								level: 0,
								createdAt: '2022-09-08T03:16:01.491Z',
								updatedAt: '2022-09-08T03:16:01.491Z',
								specialistId: 'dafe825a-cc64-4b00-8d7d-e7ed8adab9ca',
								specialistName: 'General Practitioner (GP)',
								parentSpecialistId: null,
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
									specialistId: 'dafe825a-cc64-4b00-8d7d-e7ed8adab9ca',
								},
							},
						],
						certificates: [],
						nameOfSignature: 'Dr. Ana Rois Shofiyana S.Pd',
						otherSpecialist: '',
						signatureImageUrl:
							'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/cdef65b1-80ee-41a0-8d80-a9c002bedeaa',
						specialityCertificate: null,
					},
					metaPartner: {
						partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
						isPublished: true,
						partnerName: 'Consult Now',
						partnerType: 'CONSULT_NOW',
						isConfirmedPartner: true,
					},
					createdByUserId: '01GCDNAV6270SMVQBEAQ90VY4G',
					metaCreatedByUser: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						updatedAt: '2022-09-08T07:10:14.649Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					createdAt: '2022-09-13T13:15:58.080Z',
					updatedAt: '2022-09-13T13:17:08.908Z',
					medicalRecord: {
						medicalRecordId: '8da91c23-d7bb-4355-aafa-bd43e05a0848',
						doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
						patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patientInRelationId: null,
						reasonOfConsultation: null,
						historyPhysicalFindings: 'A',
						diagnosis: null,
						planForManagement: null,
						transactionConsultationId: 'baefb1e8-3795-45dc-a662-3edf19e9b5be',
						uploadedFile: null,
						createdAt: '2022-09-13T13:15:58.090Z',
						updatedAt: '2022-09-13T13:24:56.100Z',
					},
					history: [
						{
							historyId: '25dfdcb1-65b6-404c-85b0-fa0024ebbe76',
							transactionConsultationId: 'baefb1e8-3795-45dc-a662-3edf19e9b5be',
							description: 'Update transaction status',
							newData: {
								transactionStatus: 'APPROVED',
							},
							createdByUserId: '01GCFZABXGYENY1C7ZGRZHKCWT',
							metaCreatedByUser: {
								action: 'login_success',
								userId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								payload: {
									phoneNumber: '6383822446612',
								},
								userType: 'customer',
								sessionId:
									'2dd7aa8a80c235d4c3d9b6b56e2d0c9e61357512c391e3947fe3aacfeb0a8e7f',
							},
							createdAt: '2022-09-13T13:17:08.921Z',
							updatedAt: '2022-09-13T13:17:08.921Z',
						},
						{
							historyId: '17d60e00-ac7c-41a0-9888-217786264ba9',
							transactionConsultationId: 'baefb1e8-3795-45dc-a662-3edf19e9b5be',
							description: 'Update transaction status',
							newData: {
								transactionStatus: 'WAITING_APPROVAL',
							},
							createdByUserId: '01GCDNAV6270SMVQBEAQ90VY4G',
							metaCreatedByUser: {
								city: null,
								name: 'Rizki Rahmadi',
								email: null,
								phone: '63125',
								doctor: null,
								gender: 'male',
								status: 'active',
								userId: '01GCDNAV6270SMVQBEAQ90VY4G',
								patient: {
									pwId: null,
									pwIdUrl: null,
									createdAt: '2022-09-08T04:22:06.889Z',
									patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
									updatedAt: '2022-09-08T04:22:06.889Z',
									pwIdStatus: 'WAITING_APPROVAL',
								},
								lastName: 'Rahmadi',
								nickName: 'Iki',
								province: null,
								userType: 'customer',
								birthdate: '1999-03-11',
								createdAt: '2022-09-08T04:22:06.787Z',
								firstName: 'Rizki',
								updatedAt: '2022-09-08T07:10:14.649Z',
								middleName: null,
								profilePic: null,
								isEmailVerified: false,
								isPhoneVerified: true,
							},
							createdAt: '2022-09-13T13:16:41.239Z',
							updatedAt: '2022-09-13T13:16:41.239Z',
						},
					],
					payment: {
						paymentId: '769c7a53-7d8b-49e6-a020-3c2132823924',
						invoiceId: 'CC/20220913/894',
						transactionId: 'baefb1e8-3795-45dc-a662-3edf19e9b5be',
						transactionType: 'CONSULT_NOW',
						totalNeedToPay: 103,
						paymentStatus: 'PAID_FULL',
						buyerId: '01GCDNAV6270SMVQBEAQ90VY4G',
						metaBuyerDetail: {
							city: null,
							name: 'Rizki Rahmadi',
							email: null,
							phone: '63125',
							doctor: null,
							gender: 'male',
							status: 'active',
							userId: '01GCDNAV6270SMVQBEAQ90VY4G',
							patient: {
								pwId: null,
								pwIdUrl: null,
								createdAt: '2022-09-08T04:22:06.889Z',
								patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
								updatedAt: '2022-09-08T04:22:06.889Z',
								pwIdStatus: 'WAITING_APPROVAL',
							},
							lastName: 'Rahmadi',
							nickName: 'Iki',
							province: null,
							userType: 'customer',
							birthdate: '1999-03-11',
							createdAt: '2022-09-08T04:22:06.787Z',
							firstName: 'Rizki',
							updatedAt: '2022-09-08T07:10:14.649Z',
							middleName: null,
							profilePic: null,
							isEmailVerified: false,
							isPhoneVerified: true,
						},
						meta: null,
						expiredAt: '2022-09-14T17:15:58.209Z',
						createdAt: '2022-09-13T13:15:58.211Z',
						updatedAt: '2022-09-13T13:16:41.219Z',
						paymentDetails: [
							{
								paymentDetailId: '58b4e4cd-52c1-4193-83e8-df2d9ab899e6',
								paymentId: '769c7a53-7d8b-49e6-a020-3c2132823924',
								paymentType: 'BASE_PRICE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 50,
								status: 'PAID_FULL',
								meta: {
									consultationFee: 50,
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-14T17:15:58.209Z',
								createdAt: '2022-09-13T13:15:58.274Z',
								updatedAt: '2022-09-13T13:15:58.274Z',
							},
							{
								paymentDetailId: '30c1d191-b659-4bf3-a4c2-8e9d535d2811',
								paymentId: '769c7a53-7d8b-49e6-a020-3c2132823924',
								paymentType: 'PATIENT_TRANSACTION_FEE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 53,
								status: 'PAID_FULL',
								meta: {
									key: 'PATIENT_TRANSACTION_FEE',
									value: '5%+50',
									configId: 'PATIENT_TRANSACTION_FEE',
									createdAt: '2022-09-08T06:08:45.373Z',
									updatedAt: '2022-09-08T06:08:45.373Z',
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-14T17:15:58.209Z',
								createdAt: '2022-09-13T13:15:58.276Z',
								updatedAt: '2022-09-13T13:15:58.276Z',
							},
							{
								paymentDetailId: '28e4a453-23b3-48ac-897a-4aac032dc615',
								paymentId: '769c7a53-7d8b-49e6-a020-3c2132823924',
								paymentType: 'PAYMENT_GATEWAY',
								paymentMethod: 'EWALLET',
								paymentVendorType: 'PAYMONGO',
								paymentVendorId: 'GCASH',
								externalId: null,
								totalPay: 103,
								status: 'PAID_FULL',
								meta: {
									data: {
										id: 'evt_PdwG4zqNHPXGHcSADmC96w1i',
										type: 'event',
										attributes: {
											data: {
												id: 'pay_KQ9TRSk9r3mUFYyp8eixEDvY',
												type: 'payment',
												attributes: {
													fee: 258,
													taxes: [],
													amount: 10300,
													origin: 'api',
													payout: null,
													source: {
														id: 'src_bYL6kNbH737Vk9Mki8FFWsiN',
														type: 'gcash',
													},
													status: 'paid',
													billing: null,
													paid_at: 1663074971,
													refunds: [],
													currency: 'PHP',
													disputed: false,
													livemode: false,
													metadata: {
														paymentId: '769c7a53-7d8b-49e6-a020-3c2132823924',
													},
													access_url: null,
													created_at: 1663074971,
													net_amount: 10042,
													tax_amount: null,
													updated_at: 1663074971,
													description: null,
													available_at: 1663318800,
													payment_intent_id: null,
													statement_descriptor: 'PAYMONGO',
													balance_transaction_id:
														'bal_txn_EweskYRxFU1eo6rRe5bZ24ru',
													external_reference_number: null,
												},
											},
											type: 'payment.paid',
											livemode: false,
											created_at: 1663074971,
											updated_at: 1663074971,
											previous_data: {},
										},
									},
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-14T17:15:58.209Z',
								createdAt: '2022-09-13T13:15:58.269Z',
								updatedAt: '2022-09-13T13:16:41.208Z',
							},
						],
						paymentDetailsPartners: [],
					},
				},
			},
			{
				name: 'Rizki Rahmadi',
				date: '2022-09-13',
				desc: null,
				reason: null,
				startTime: '20:03',
				endTime: '20:33',
				detailDoctor: {
					name: 'Ana Rois Shofiyana',
					title: 'General Practitioner (GP)',
					age: 23,
					ageString: '23 yo',
				},
				raw: {
					transactionConsultationId: '2ed65d59-99ae-4955-a37d-878c842367ea',
					doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
					patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
					patientInRelationId: null,
					partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
					consultationType: 'CONSULT_NOW_VIDEO_CHAT_CONSULTATION',
					scheduledStartAt: '2022-09-13T13:03:25.752Z',
					scheduledEndAt: '2022-09-13T13:33:25.752Z',
					isCanExtend: null,
					doctorJoinAt: null,
					doctorEndAt: null,
					patientJoinAt: null,
					patientEndAt: null,
					transactionStatus: 'COMPLETED',
					lastUpdatedByUser: {
						action: 'login_success',
						userId: '01GCFZABXGYENY1C7ZGRZHKCWT',
						payload: {
							phoneNumber: '6383822446612',
						},
						userType: 'customer',
						sessionId:
							'2dd7aa8a80c235d4c3d9b6b56e2d0c9e61357512c391e3947fe3aacfeb0a8e7f',
					},
					description: null,
					place: null,
					link: null,
					metaPatient: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						updatedAt: '2022-09-08T07:10:14.649Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					metaPatientInRelation: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
						updatedAt: '2022-09-08T07:10:14.649Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					metaDoctor: {
						user: {
							city: null,
							name: 'Ana Rois Shofiyana',
							email: null,
							phone: '6383822446612',
							gender: 'female',
							status: 'active',
							userId: '01GCFZABXGYENY1C7ZGRZHKCWT',
							lastName: 'Shofiyana',
							nickName: 'Ana',
							province: null,
							userType: 'customer',
							birthdate: '1999-05-14',
							createdAt: '2022-09-09T01:55:05.781Z',
							firstName: 'Ana',
							updatedAt: '2022-09-09T11:22:32.902Z',
							middleName: 'Rois',
							profilePic: null,
						},
						title: 'S.Pd',
						doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
						partners: [
							{
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:14:18.292Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-09T11:18:48.250Z',
								pricePerSession: 120,
							},
							{
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:10:42.110Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-09T11:16:53.265Z',
								pricePerSession: 100,
							},
							{
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:10:49.878Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-09T11:10:49.878Z',
								pricePerSession: null,
							},
						],
						createdAt: '2022-09-09T11:10:00.227Z',
						kycStatus: 'APPROVED',
						prcNumber: '123456789001',
						ptrNumber: '123456789002',
						schedules: [
							{
								endAt: '2022-01-01T16:59:00.901Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.448Z',
								weekday: 0,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:12:18.480Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-09T11:12:18.480Z',
								scheduleId: '1641cff8-f159-40e9-9408-60607849221e',
							},
							{
								endAt: '2022-01-01T16:59:00.176Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.960Z',
								weekday: 1,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:12:37.581Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-09T11:12:37.581Z',
								scheduleId: '2af563cb-0f70-44ff-b997-6b087bf53e78',
							},
							{
								endAt: '2022-01-01T16:59:00.579Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.988Z',
								weekday: 2,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:13:05.748Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-09T11:13:05.748Z',
								scheduleId: 'c08105a7-db25-4796-b434-49a5b4f9cc67',
							},
							{
								endAt: '2022-01-01T16:59:00.883Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.301Z',
								weekday: 3,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:13:17.749Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-09T11:13:17.749Z',
								scheduleId: '548098d1-8526-43e9-b1cb-6a450253d125',
							},
							{
								endAt: '2022-01-01T16:59:00.288Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.124Z',
								weekday: 4,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:13:30.932Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-09T11:13:30.932Z',
								scheduleId: '850a0aa1-8638-4244-898b-878cf320f46c',
							},
							{
								endAt: '2022-01-01T16:59:00.413Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.530Z',
								weekday: 5,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:13:43.570Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-09T11:13:43.570Z',
								scheduleId: 'b3c928f6-3be7-4eea-8f11-95950ab23cad',
							},
							{
								endAt: '2022-01-01T16:59:00.406Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.863Z',
								weekday: 6,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:13:55.514Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-09T11:13:55.514Z',
								scheduleId: 'bcfbdbf1-adf3-4539-ae3a-9a72ca7569e2',
							},
							{
								endAt: '2022-01-01T11:00:00.253Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.523Z',
								weekday: 0,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:14:41.001Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-09T11:14:41.001Z',
								scheduleId: 'c69a3d4e-fcff-476c-8178-38176730b08b',
							},
							{
								endAt: '2022-01-01T11:00:00.895Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.149Z',
								weekday: 1,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:14:58.210Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-09T11:14:58.210Z',
								scheduleId: 'fda0dabc-0d7e-43ad-b761-cd8b7a737996',
							},
							{
								endAt: '2022-01-01T11:00:00.117Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.020Z',
								weekday: 2,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:15:11.927Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-09T11:15:11.927Z',
								scheduleId: 'ca409137-69c0-4514-ae8f-efa2dc85f5bf',
							},
							{
								endAt: '2022-01-01T11:00:00.090Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.784Z',
								weekday: 3,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:15:25.191Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-09T11:15:25.191Z',
								scheduleId: '29ace700-028e-42db-8fae-6f2b3e48f347',
							},
							{
								endAt: '2022-01-01T11:00:00.604Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.535Z',
								weekday: 4,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:15:36.873Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-09T11:15:36.873Z',
								scheduleId: 'febc2a36-aee9-4524-9a07-ba9aad3aa628',
							},
							{
								endAt: '2022-01-01T11:00:00.272Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.097Z',
								weekday: 5,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:15:49.307Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-09T11:15:49.307Z',
								scheduleId: '105c1d42-1398-43b9-820a-9fb8777d597f',
							},
							{
								endAt: '2022-01-01T11:00:00.193Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.406Z',
								weekday: 6,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:15:58.339Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-09T11:15:58.339Z',
								scheduleId: '8b139e68-aa4b-46d1-bb1b-af5d2391def1',
							},
							{
								endAt: '2022-01-01T16:00:00.641Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.003Z',
								weekday: 0,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:17:05.069Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-09T11:17:05.069Z',
								scheduleId: '772d38d8-26fc-4cf1-8ea6-dc38ff368201',
							},
							{
								endAt: '2022-01-01T16:00:00.237Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.319Z',
								weekday: 1,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:17:17.005Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-09T11:17:17.005Z',
								scheduleId: '561dbfde-24fd-4079-80fb-5eaa6f665a74',
							},
							{
								endAt: '2022-01-01T16:00:00.008Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.031Z',
								weekday: 2,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:17:32.927Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-09T11:17:32.927Z',
								scheduleId: '07eea797-e393-49d0-9283-1823e0624d6a',
							},
							{
								endAt: '2022-01-01T16:00:00.187Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.962Z',
								weekday: 3,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:18:00.844Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-09T11:18:00.844Z',
								scheduleId: '44b7ee81-2384-4534-a759-10de09961544',
							},
							{
								endAt: '2022-01-01T16:00:00.776Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.426Z',
								weekday: 4,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:18:12.134Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-09T11:18:12.134Z',
								scheduleId: 'b6506f8d-40f0-4634-903c-16f5c26482ca',
							},
							{
								endAt: '2022-01-01T16:00:00.205Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.321Z',
								weekday: 5,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:18:24.315Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-09T11:18:24.315Z',
								scheduleId: '4d585d59-42d0-43ba-82f1-590c0fee3b50',
							},
							{
								endAt: '2022-01-01T16:00:00.707Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.456Z',
								weekday: 6,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:18:35.743Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-09T11:18:35.743Z',
								scheduleId: '6d02667b-7629-4e66-88bb-92129dd611a4',
							},
						],
						startWork: null,
						tinNumber: '123456789003',
						updatedAt: '2022-09-09T11:59:26.663Z',
						educations: [],
						experience: 13,
						orderScore: 2,
						specialists: [
							{
								icon: 'general_practitioner',
								level: 0,
								createdAt: '2022-09-08T03:16:01.491Z',
								updatedAt: '2022-09-08T03:16:01.491Z',
								specialistId: 'dafe825a-cc64-4b00-8d7d-e7ed8adab9ca',
								specialistName: 'General Practitioner (GP)',
								parentSpecialistId: null,
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
									specialistId: 'dafe825a-cc64-4b00-8d7d-e7ed8adab9ca',
								},
							},
						],
						certificates: [],
						nameOfSignature: 'Dr. Ana Rois Shofiyana S.Pd',
						otherSpecialist: '',
						signatureImageUrl:
							'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/cdef65b1-80ee-41a0-8d80-a9c002bedeaa',
						specialityCertificate: null,
					},
					metaPartner: {
						partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
						isPublished: true,
						partnerName: 'Consult Now',
						partnerType: 'CONSULT_NOW',
						isConfirmedPartner: true,
					},
					createdByUserId: '01GCDNAV6270SMVQBEAQ90VY4G',
					metaCreatedByUser: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						updatedAt: '2022-09-08T07:10:14.649Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					createdAt: '2022-09-13T13:03:25.818Z',
					updatedAt: '2022-09-13T13:12:42.336Z',
					medicalRecord: {
						medicalRecordId: '3eebc6d3-f36c-4223-ab1f-7961488106b7',
						doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
						patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patientInRelationId: null,
						reasonOfConsultation: null,
						historyPhysicalFindings: 'A',
						diagnosis: 'A',
						planForManagement: 'A',
						transactionConsultationId: '2ed65d59-99ae-4955-a37d-878c842367ea',
						uploadedFile: null,
						createdAt: '2022-09-13T13:03:25.827Z',
						updatedAt: '2022-09-13T13:07:36.745Z',
					},
					history: [
						{
							historyId: 'aa4652a4-5087-4a3e-9f24-12e91024b24e',
							transactionConsultationId: '2ed65d59-99ae-4955-a37d-878c842367ea',
							description: 'Update transaction status',
							newData: {
								transactionStatus: 'COMPLETED',
							},
							createdByUserId: '01GCFZABXGYENY1C7ZGRZHKCWT',
							metaCreatedByUser: {
								action: 'login_success',
								userId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								payload: {
									phoneNumber: '6383822446612',
								},
								userType: 'customer',
								sessionId:
									'2dd7aa8a80c235d4c3d9b6b56e2d0c9e61357512c391e3947fe3aacfeb0a8e7f',
							},
							createdAt: '2022-09-13T13:12:42.347Z',
							updatedAt: '2022-09-13T13:12:42.347Z',
						},
						{
							historyId: '6b9e6450-e0dd-49af-8603-c8eedafaf85d',
							transactionConsultationId: '2ed65d59-99ae-4955-a37d-878c842367ea',
							description: 'Update transaction status',
							newData: {
								transactionStatus: 'APPROVED',
							},
							createdByUserId: '01GCFZABXGYENY1C7ZGRZHKCWT',
							metaCreatedByUser: {
								action: 'login_success',
								userId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								payload: {
									phoneNumber: '6383822446612',
								},
								userType: 'customer',
								sessionId:
									'2dd7aa8a80c235d4c3d9b6b56e2d0c9e61357512c391e3947fe3aacfeb0a8e7f',
							},
							createdAt: '2022-09-13T13:05:42.118Z',
							updatedAt: '2022-09-13T13:05:42.118Z',
						},
						{
							historyId: 'd7ab0952-3346-4c35-84a9-1c162c7dd6a2',
							transactionConsultationId: '2ed65d59-99ae-4955-a37d-878c842367ea',
							description: 'Update transaction status',
							newData: {
								transactionStatus: 'WAITING_APPROVAL',
							},
							createdByUserId: '01GCDNAV6270SMVQBEAQ90VY4G',
							metaCreatedByUser: {
								city: null,
								name: 'Rizki Rahmadi',
								email: null,
								phone: '63125',
								doctor: null,
								gender: 'male',
								status: 'active',
								userId: '01GCDNAV6270SMVQBEAQ90VY4G',
								patient: {
									pwId: null,
									pwIdUrl: null,
									createdAt: '2022-09-08T04:22:06.889Z',
									patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
									updatedAt: '2022-09-08T04:22:06.889Z',
									pwIdStatus: 'WAITING_APPROVAL',
								},
								lastName: 'Rahmadi',
								nickName: 'Iki',
								province: null,
								userType: 'customer',
								birthdate: '1999-03-11',
								createdAt: '2022-09-08T04:22:06.787Z',
								firstName: 'Rizki',
								updatedAt: '2022-09-08T07:10:14.649Z',
								middleName: null,
								profilePic: null,
								isEmailVerified: false,
								isPhoneVerified: true,
							},
							createdAt: '2022-09-13T13:04:07.615Z',
							updatedAt: '2022-09-13T13:04:07.615Z',
						},
					],
					payment: {
						paymentId: '6327c5c2-6148-4766-878f-3bb8c9f2d453',
						invoiceId: 'CC/20220913/CK9',
						transactionId: '2ed65d59-99ae-4955-a37d-878c842367ea',
						transactionType: 'CONSULT_NOW',
						totalNeedToPay: 103,
						paymentStatus: 'PAID_FULL',
						buyerId: '01GCDNAV6270SMVQBEAQ90VY4G',
						metaBuyerDetail: {
							city: null,
							name: 'Rizki Rahmadi',
							email: null,
							phone: '63125',
							doctor: null,
							gender: 'male',
							status: 'active',
							userId: '01GCDNAV6270SMVQBEAQ90VY4G',
							patient: {
								pwId: null,
								pwIdUrl: null,
								createdAt: '2022-09-08T04:22:06.889Z',
								patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
								updatedAt: '2022-09-08T04:22:06.889Z',
								pwIdStatus: 'WAITING_APPROVAL',
							},
							lastName: 'Rahmadi',
							nickName: 'Iki',
							province: null,
							userType: 'customer',
							birthdate: '1999-03-11',
							createdAt: '2022-09-08T04:22:06.787Z',
							firstName: 'Rizki',
							updatedAt: '2022-09-08T07:10:14.649Z',
							middleName: null,
							profilePic: null,
							isEmailVerified: false,
							isPhoneVerified: true,
						},
						meta: null,
						expiredAt: '2022-09-14T17:03:26.224Z',
						createdAt: '2022-09-13T13:03:26.225Z',
						updatedAt: '2022-09-13T13:04:07.595Z',
						paymentDetails: [
							{
								paymentDetailId: 'd34f6e31-22ba-458e-b7c4-7d5efd7069bc',
								paymentId: '6327c5c2-6148-4766-878f-3bb8c9f2d453',
								paymentType: 'BASE_PRICE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 50,
								status: 'PAID_FULL',
								meta: {
									consultationFee: 50,
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-14T17:03:26.224Z',
								createdAt: '2022-09-13T13:03:26.299Z',
								updatedAt: '2022-09-13T13:03:26.299Z',
							},
							{
								paymentDetailId: 'fccdf15c-f01b-4b1b-aa1f-eaaefa9b1a55',
								paymentId: '6327c5c2-6148-4766-878f-3bb8c9f2d453',
								paymentType: 'PATIENT_TRANSACTION_FEE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 53,
								status: 'PAID_FULL',
								meta: {
									key: 'PATIENT_TRANSACTION_FEE',
									value: '5%+50',
									configId: 'PATIENT_TRANSACTION_FEE',
									createdAt: '2022-09-08T06:08:45.373Z',
									updatedAt: '2022-09-08T06:08:45.373Z',
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-14T17:03:26.224Z',
								createdAt: '2022-09-13T13:03:26.302Z',
								updatedAt: '2022-09-13T13:03:26.302Z',
							},
							{
								paymentDetailId: '284d74bc-9731-4227-b9f4-2bf06d0555a1',
								paymentId: '6327c5c2-6148-4766-878f-3bb8c9f2d453',
								paymentType: 'PAYMENT_GATEWAY',
								paymentMethod: 'EWALLET',
								paymentVendorType: 'PAYMONGO',
								paymentVendorId: 'GCASH',
								externalId: null,
								totalPay: 103,
								status: 'PAID_FULL',
								meta: {
									data: {
										id: 'evt_jLUcaJ1fZBfg74YQqGBtn2YW',
										type: 'event',
										attributes: {
											data: {
												id: 'pay_PhU8XSFMcESrn8eZKPpiZdkz',
												type: 'payment',
												attributes: {
													fee: 258,
													taxes: [],
													amount: 10300,
													origin: 'api',
													payout: null,
													source: {
														id: 'src_9ffWA8t97KEQsgYJwEHhYe4J',
														type: 'gcash',
													},
													status: 'paid',
													billing: null,
													paid_at: 1663074228,
													refunds: [],
													currency: 'PHP',
													disputed: false,
													livemode: false,
													metadata: {
														paymentId: '6327c5c2-6148-4766-878f-3bb8c9f2d453',
													},
													access_url: null,
													created_at: 1663074228,
													net_amount: 10042,
													tax_amount: null,
													updated_at: 1663074228,
													description: null,
													available_at: 1663318800,
													payment_intent_id: null,
													statement_descriptor: 'PAYMONGO',
													balance_transaction_id:
														'bal_txn_uuKcf3JHoNjFTWnHMGzmgSzh',
													external_reference_number: null,
												},
											},
											type: 'payment.paid',
											livemode: false,
											created_at: 1663074228,
											updated_at: 1663074228,
											previous_data: {},
										},
									},
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-14T17:03:26.224Z',
								createdAt: '2022-09-13T13:03:26.295Z',
								updatedAt: '2022-09-13T13:04:07.584Z',
							},
						],
						paymentDetailsPartners: [],
					},
				},
			},
			{
				name: 'Rizki Rahmadi',
				date: '2022-09-13',
				desc: 'Sakit perut makan geprek',
				reason: 'Sakit perut makan geprek',
				startTime: '19:13',
				endTime: '19:43',
				detailDoctor: {
					name: 'Ana Rois Shofiyana',
					title: 'General Practitioner (GP)',
					age: 23,
					ageString: '23 yo',
				},
				raw: {
					transactionConsultationId: 'ae55f1a3-aa3a-4c1f-a3e7-d67a61bbc00e',
					doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
					patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
					patientInRelationId: null,
					partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
					consultationType: 'CONSULT_NOW_VIDEO_CHAT_CONSULTATION',
					scheduledStartAt: '2022-09-13T12:13:21.091Z',
					scheduledEndAt: '2022-09-13T12:43:21.091Z',
					isCanExtend: null,
					doctorJoinAt: null,
					doctorEndAt: null,
					patientJoinAt: null,
					patientEndAt: null,
					transactionStatus: 'COMPLETED',
					lastUpdatedByUser: {
						action: 'login_success',
						userId: '01GCFZABXGYENY1C7ZGRZHKCWT',
						payload: {
							phoneNumber: '6383822446612',
						},
						userType: 'customer',
						sessionId:
							'3169109f9fa9f337ce760c2c0abab44b973334c50a2a17e9a2e8575e0ee713d0',
					},
					description: null,
					place: null,
					link: null,
					metaPatient: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						updatedAt: '2022-09-08T07:10:14.649Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					metaPatientInRelation: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
						updatedAt: '2022-09-08T07:10:14.649Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					metaDoctor: {
						user: {
							city: null,
							name: 'Ana Rois Shofiyana',
							email: null,
							phone: '6383822446612',
							gender: 'female',
							status: 'active',
							userId: '01GCFZABXGYENY1C7ZGRZHKCWT',
							lastName: 'Shofiyana',
							nickName: 'Ana',
							province: null,
							userType: 'customer',
							birthdate: '1999-05-14',
							createdAt: '2022-09-09T01:55:05.781Z',
							firstName: 'Ana',
							updatedAt: '2022-09-09T11:22:32.902Z',
							middleName: 'Rois',
							profilePic: null,
						},
						title: 'S.Pd',
						doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
						partners: [
							{
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:14:18.292Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-09T11:18:48.250Z',
								pricePerSession: 120,
							},
							{
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:10:42.110Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-09T11:16:53.265Z',
								pricePerSession: 100,
							},
							{
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:10:49.878Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-09T11:10:49.878Z',
								pricePerSession: null,
							},
						],
						createdAt: '2022-09-09T11:10:00.227Z',
						kycStatus: 'APPROVED',
						prcNumber: '123456789001',
						ptrNumber: '123456789002',
						schedules: [
							{
								endAt: '2022-01-01T16:59:00.901Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.448Z',
								weekday: 0,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:12:18.480Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-09T11:12:18.480Z',
								scheduleId: '1641cff8-f159-40e9-9408-60607849221e',
							},
							{
								endAt: '2022-01-01T16:59:00.176Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.960Z',
								weekday: 1,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:12:37.581Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-09T11:12:37.581Z',
								scheduleId: '2af563cb-0f70-44ff-b997-6b087bf53e78',
							},
							{
								endAt: '2022-01-01T16:59:00.579Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.988Z',
								weekday: 2,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:13:05.748Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-09T11:13:05.748Z',
								scheduleId: 'c08105a7-db25-4796-b434-49a5b4f9cc67',
							},
							{
								endAt: '2022-01-01T16:59:00.883Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.301Z',
								weekday: 3,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:13:17.749Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-09T11:13:17.749Z',
								scheduleId: '548098d1-8526-43e9-b1cb-6a450253d125',
							},
							{
								endAt: '2022-01-01T16:59:00.288Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.124Z',
								weekday: 4,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:13:30.932Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-09T11:13:30.932Z',
								scheduleId: '850a0aa1-8638-4244-898b-878cf320f46c',
							},
							{
								endAt: '2022-01-01T16:59:00.413Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.530Z',
								weekday: 5,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:13:43.570Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-09T11:13:43.570Z',
								scheduleId: 'b3c928f6-3be7-4eea-8f11-95950ab23cad',
							},
							{
								endAt: '2022-01-01T16:59:00.406Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Consult Now',
									partnerType: 'CONSULT_NOW',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T17:00:00.863Z',
								weekday: 6,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:13:55.514Z',
								partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
								updatedAt: '2022-09-09T11:13:55.514Z',
								scheduleId: 'bcfbdbf1-adf3-4539-ae3a-9a72ca7569e2',
							},
							{
								endAt: '2022-01-01T11:00:00.253Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.523Z',
								weekday: 0,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:14:41.001Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-09T11:14:41.001Z',
								scheduleId: 'c69a3d4e-fcff-476c-8178-38176730b08b',
							},
							{
								endAt: '2022-01-01T11:00:00.895Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.149Z',
								weekday: 1,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:14:58.210Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-09T11:14:58.210Z',
								scheduleId: 'fda0dabc-0d7e-43ad-b761-cd8b7a737996',
							},
							{
								endAt: '2022-01-01T11:00:00.117Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.020Z',
								weekday: 2,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:15:11.927Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-09T11:15:11.927Z',
								scheduleId: 'ca409137-69c0-4514-ae8f-efa2dc85f5bf',
							},
							{
								endAt: '2022-01-01T11:00:00.090Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.784Z',
								weekday: 3,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:15:25.191Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-09T11:15:25.191Z',
								scheduleId: '29ace700-028e-42db-8fae-6f2b3e48f347',
							},
							{
								endAt: '2022-01-01T11:00:00.604Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.535Z',
								weekday: 4,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:15:36.873Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-09T11:15:36.873Z',
								scheduleId: 'febc2a36-aee9-4524-9a07-ba9aad3aa628',
							},
							{
								endAt: '2022-01-01T11:00:00.272Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.097Z',
								weekday: 5,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:15:49.307Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-09T11:15:49.307Z',
								scheduleId: '105c1d42-1398-43b9-820a-9fb8777d597f',
							},
							{
								endAt: '2022-01-01T11:00:00.193Z',
								partner: {
									email: null,
									phone: '081234567890',
									address:
										'Jalan Bung Karno No.53 Pagesangan Timur, Pagutan Bar., Kota Mataram, Nusa Tenggara Bar. 83127',
									createdAt: '2022-09-08T06:47:06.977Z',
									partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
									updatedAt: '2022-09-08T06:47:07.211Z',
									geolocation: {
										latitude: 0,
										longitude: 0,
									},
									isPublished: true,
									partnerName: 'Mitra Husada',
									partnerType: 'CLINIC',
									imageLogoURL: null,
									imageCoverURL:
										'https://qbit-tech.sgp1.digitaloceanspaces.com/partners/3c945cc9-470b-48de-b9d5-930c5b7deb8b',
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.406Z',
								weekday: 6,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:15:58.339Z',
								partnerId: 'b8382f80-ecee-4c92-9d2b-7fb932403145',
								updatedAt: '2022-09-09T11:15:58.339Z',
								scheduleId: '8b139e68-aa4b-46d1-bb1b-af5d2391def1',
							},
							{
								endAt: '2022-01-01T16:00:00.641Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.003Z',
								weekday: 0,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:17:05.069Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-09T11:17:05.069Z',
								scheduleId: '772d38d8-26fc-4cf1-8ea6-dc38ff368201',
							},
							{
								endAt: '2022-01-01T16:00:00.237Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.319Z',
								weekday: 1,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:17:17.005Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-09T11:17:17.005Z',
								scheduleId: '561dbfde-24fd-4079-80fb-5eaa6f665a74',
							},
							{
								endAt: '2022-01-01T16:00:00.008Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.031Z',
								weekday: 2,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:17:32.927Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-09T11:17:32.927Z',
								scheduleId: '07eea797-e393-49d0-9283-1823e0624d6a',
							},
							{
								endAt: '2022-01-01T16:00:00.187Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.962Z',
								weekday: 3,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:18:00.844Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-09T11:18:00.844Z',
								scheduleId: '44b7ee81-2384-4534-a759-10de09961544',
							},
							{
								endAt: '2022-01-01T16:00:00.776Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.426Z',
								weekday: 4,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:18:12.134Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-09T11:18:12.134Z',
								scheduleId: 'b6506f8d-40f0-4634-903c-16f5c26482ca',
							},
							{
								endAt: '2022-01-01T16:00:00.205Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.321Z',
								weekday: 5,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:18:24.315Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-09T11:18:24.315Z',
								scheduleId: '4d585d59-42d0-43ba-82f1-590c0fee3b50',
							},
							{
								endAt: '2022-01-01T16:00:00.707Z',
								partner: {
									email: null,
									phone: null,
									address: null,
									createdAt: '2022-09-08T03:16:18.401Z',
									partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
									updatedAt: '2022-09-08T03:16:18.401Z',
									geolocation: null,
									isPublished: true,
									partnerName: 'Online Clinic',
									partnerType: 'ONLINE_CLINIC',
									imageLogoURL: null,
									imageCoverURL: null,
									isConfirmedPartner: true,
								},
								startAt: '2022-01-01T23:00:00.456Z',
								weekday: 6,
								doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								createdAt: '2022-09-09T11:18:35.743Z',
								partnerId: '1cf3ab44-4745-4696-bb4b-ab8bd8ef7a6b',
								updatedAt: '2022-09-09T11:18:35.743Z',
								scheduleId: '6d02667b-7629-4e66-88bb-92129dd611a4',
							},
						],
						startWork: null,
						tinNumber: '123456789003',
						updatedAt: '2022-09-09T11:59:26.663Z',
						educations: [],
						experience: 13,
						orderScore: 2,
						specialists: [
							{
								icon: 'general_practitioner',
								level: 0,
								createdAt: '2022-09-08T03:16:01.491Z',
								updatedAt: '2022-09-08T03:16:01.491Z',
								specialistId: 'dafe825a-cc64-4b00-8d7d-e7ed8adab9ca',
								specialistName: 'General Practitioner (GP)',
								parentSpecialistId: null,
								specialistAliasName: null,
								DoctorSpecialistModel: {
									doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
									specialistId: 'dafe825a-cc64-4b00-8d7d-e7ed8adab9ca',
								},
							},
						],
						certificates: [],
						nameOfSignature: 'Dr. Ana Rois Shofiyana S.Pd',
						otherSpecialist: '',
						signatureImageUrl:
							'https://qbit-tech.sgp1.digitaloceanspaces.com/doctors/cdef65b1-80ee-41a0-8d80-a9c002bedeaa',
						specialityCertificate: null,
					},
					metaPartner: {
						partnerId: 'ec3ab37b-aeaf-4e08-a239-ffbedbd806ac',
						isPublished: true,
						partnerName: 'Consult Now',
						partnerType: 'CONSULT_NOW',
						isConfirmedPartner: true,
					},
					createdByUserId: '01GCDNAV6270SMVQBEAQ90VY4G',
					metaCreatedByUser: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						updatedAt: '2022-09-08T07:10:14.649Z',
						middleName: null,
						profilePic: null,
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					createdAt: '2022-09-13T12:13:21.131Z',
					updatedAt: '2022-09-13T12:24:09.369Z',
					medicalRecord: {
						medicalRecordId: '27b04590-2759-47f7-a06d-b4c995ca3893',
						doctorId: '01GCFZABXGYENY1C7ZGRZHKCWT',
						patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patientInRelationId: null,
						reasonOfConsultation: 'Sakit perut makan geprek',
						historyPhysicalFindings: null,
						diagnosis: null,
						planForManagement: null,
						transactionConsultationId: 'ae55f1a3-aa3a-4c1f-a3e7-d67a61bbc00e',
						uploadedFile: null,
						createdAt: '2022-09-13T12:13:21.138Z',
						updatedAt: '2022-09-13T12:13:21.138Z',
					},
					history: [
						{
							historyId: 'e457c6fb-e858-46e3-a5bc-72fa2c7ca1ff',
							transactionConsultationId: 'ae55f1a3-aa3a-4c1f-a3e7-d67a61bbc00e',
							description: 'Update transaction status',
							newData: {
								transactionStatus: 'COMPLETED',
							},
							createdByUserId: '01GCFZABXGYENY1C7ZGRZHKCWT',
							metaCreatedByUser: {
								action: 'login_success',
								userId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								payload: {
									phoneNumber: '6383822446612',
								},
								userType: 'customer',
								sessionId:
									'3169109f9fa9f337ce760c2c0abab44b973334c50a2a17e9a2e8575e0ee713d0',
							},
							createdAt: '2022-09-13T12:24:09.378Z',
							updatedAt: '2022-09-13T12:24:09.378Z',
						},
						{
							historyId: '1fd93863-a3fb-48ca-9c31-04e28c4019ea',
							transactionConsultationId: 'ae55f1a3-aa3a-4c1f-a3e7-d67a61bbc00e',
							description: 'Update transaction status',
							newData: {
								transactionStatus: 'WAITING_APPROVAL',
							},
							createdByUserId: '01GCDNAV6270SMVQBEAQ90VY4G',
							metaCreatedByUser: {
								city: null,
								name: 'Rizki Rahmadi',
								email: null,
								phone: '63125',
								doctor: null,
								gender: 'male',
								status: 'active',
								userId: '01GCDNAV6270SMVQBEAQ90VY4G',
								patient: {
									pwId: null,
									pwIdUrl: null,
									createdAt: '2022-09-08T04:22:06.889Z',
									patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
									updatedAt: '2022-09-08T04:22:06.889Z',
									pwIdStatus: 'WAITING_APPROVAL',
								},
								lastName: 'Rahmadi',
								nickName: 'Iki',
								province: null,
								userType: 'customer',
								birthdate: '1999-03-11',
								createdAt: '2022-09-08T04:22:06.787Z',
								firstName: 'Rizki',
								updatedAt: '2022-09-08T07:10:14.649Z',
								middleName: null,
								profilePic: null,
								isEmailVerified: false,
								isPhoneVerified: true,
							},
							createdAt: '2022-09-13T12:14:10.865Z',
							updatedAt: '2022-09-13T12:14:10.865Z',
						},
						{
							historyId: '4051d705-0ee1-4ee3-b960-83325833ffd3',
							transactionConsultationId: 'ae55f1a3-aa3a-4c1f-a3e7-d67a61bbc00e',
							description: 'Update transaction status',
							newData: {
								transactionStatus: 'APPROVED',
							},
							createdByUserId: '01GCFZABXGYENY1C7ZGRZHKCWT',
							metaCreatedByUser: {
								action: 'login_success',
								userId: '01GCFZABXGYENY1C7ZGRZHKCWT',
								payload: {
									phoneNumber: '6383822446612',
								},
								userType: 'customer',
								sessionId:
									'3169109f9fa9f337ce760c2c0abab44b973334c50a2a17e9a2e8575e0ee713d0',
							},
							createdAt: '2022-09-13T12:15:36.270Z',
							updatedAt: '2022-09-13T12:15:36.270Z',
						},
					],
					payment: {
						paymentId: 'adbcabdc-8726-4de4-8c43-814e8b72b9d3',
						invoiceId: 'CC/20220913/B4K',
						transactionId: 'ae55f1a3-aa3a-4c1f-a3e7-d67a61bbc00e',
						transactionType: 'CONSULT_NOW',
						totalNeedToPay: 103,
						paymentStatus: 'PAID_FULL',
						buyerId: '01GCDNAV6270SMVQBEAQ90VY4G',
						metaBuyerDetail: {
							city: null,
							name: 'Rizki Rahmadi',
							email: null,
							phone: '63125',
							doctor: null,
							gender: 'male',
							status: 'active',
							userId: '01GCDNAV6270SMVQBEAQ90VY4G',
							patient: {
								pwId: null,
								pwIdUrl: null,
								createdAt: '2022-09-08T04:22:06.889Z',
								patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
								updatedAt: '2022-09-08T04:22:06.889Z',
								pwIdStatus: 'WAITING_APPROVAL',
							},
							lastName: 'Rahmadi',
							nickName: 'Iki',
							province: null,
							userType: 'customer',
							birthdate: '1999-03-11',
							createdAt: '2022-09-08T04:22:06.787Z',
							firstName: 'Rizki',
							updatedAt: '2022-09-08T07:10:14.649Z',
							middleName: null,
							profilePic: null,
							isEmailVerified: false,
							isPhoneVerified: true,
						},
						meta: null,
						expiredAt: '2022-09-14T16:13:21.380Z',
						createdAt: '2022-09-13T12:13:21.381Z',
						updatedAt: '2022-09-13T12:14:10.847Z',
						paymentDetails: [
							{
								paymentDetailId: '7d2a328f-fa55-4dd2-b049-6da1465b9120',
								paymentId: 'adbcabdc-8726-4de4-8c43-814e8b72b9d3',
								paymentType: 'BASE_PRICE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 50,
								status: 'PAID_FULL',
								meta: {
									consultationFee: 50,
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-14T16:13:21.380Z',
								createdAt: '2022-09-13T12:13:21.430Z',
								updatedAt: '2022-09-13T12:13:21.430Z',
							},
							{
								paymentDetailId: '4e2b47ff-3f46-4d10-86e5-cdd7bf234215',
								paymentId: 'adbcabdc-8726-4de4-8c43-814e8b72b9d3',
								paymentType: 'PATIENT_TRANSACTION_FEE',
								paymentMethod: null,
								paymentVendorType: null,
								paymentVendorId: null,
								externalId: null,
								totalPay: 53,
								status: 'PAID_FULL',
								meta: {
									key: 'PATIENT_TRANSACTION_FEE',
									value: '5%+50',
									configId: 'PATIENT_TRANSACTION_FEE',
									createdAt: '2022-09-08T06:08:45.373Z',
									updatedAt: '2022-09-08T06:08:45.373Z',
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-14T16:13:21.380Z',
								createdAt: '2022-09-13T12:13:21.432Z',
								updatedAt: '2022-09-13T12:13:21.432Z',
							},
							{
								paymentDetailId: '94318ed1-7cd7-457b-8320-edc211167290',
								paymentId: 'adbcabdc-8726-4de4-8c43-814e8b72b9d3',
								paymentType: 'PAYMENT_GATEWAY',
								paymentMethod: 'EWALLET',
								paymentVendorType: 'PAYMONGO',
								paymentVendorId: 'GCASH',
								externalId: null,
								totalPay: 103,
								status: 'PAID_FULL',
								meta: {
									data: {
										id: 'evt_GBNWAoDQ4vBcPsdi2JXWDgR8',
										type: 'event',
										attributes: {
											data: {
												id: 'pay_qCkHoC5G7PDZJoQRQk1Gjzyv',
												type: 'payment',
												attributes: {
													fee: 258,
													taxes: [],
													amount: 10300,
													origin: 'api',
													payout: null,
													source: {
														id: 'src_RNUZppci4iHRiXVf3B1Hme7T',
														type: 'gcash',
													},
													status: 'paid',
													billing: null,
													paid_at: 1663071227,
													refunds: [],
													currency: 'PHP',
													disputed: false,
													livemode: false,
													metadata: {
														paymentId: 'adbcabdc-8726-4de4-8c43-814e8b72b9d3',
													},
													access_url: null,
													created_at: 1663071227,
													net_amount: 10042,
													tax_amount: null,
													updated_at: 1663071227,
													description: null,
													available_at: 1663318800,
													payment_intent_id: null,
													statement_descriptor: 'PAYMONGO',
													balance_transaction_id:
														'bal_txn_omMK732RR2rbSxJcYY55c8aU',
													external_reference_number: null,
												},
											},
											type: 'payment.paid',
											livemode: false,
											created_at: 1663071227,
											updated_at: 1663071227,
											previous_data: {},
										},
									},
								},
								type: 'PAYMENT',
								expiredAt: '2022-09-14T16:13:21.380Z',
								createdAt: '2022-09-13T12:13:21.428Z',
								updatedAt: '2022-09-13T12:14:10.837Z',
							},
						],
						paymentDetailsPartners: [],
					},
				},
			},
		],
		myRole: 'patient',
	},
	{
		name: 'personal-event',
		data: [
			{
				name: 'Kasi makan ucing',
				date: '2022-09-30',
				desc: '',
				startTime: '07:00',
				endTime: '07:15',
				raw: {
					eventId: 'c963be51-4738-4318-98e3-54be943c45f9',
					title: 'Kasi makan ucing',
					description: '',
					eventStartAt: '2022-09-30T00:00:49.000Z',
					eventEndAt: '2022-09-30T00:15:54.000Z',
					isBlocking: null,
					createdByUserId: '01GCDNAV6270SMVQBEAQ90VY4G',
					metaCreatedByUser: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						updatedAt: '2022-09-27T02:28:30.402Z',
						middleName: null,
						profilePic:
							'https://qbit-tech.sgp1.digitaloceanspaces.com/profiles/5ea8e837-b46a-4257-9fc0-a69cbdafbaa7',
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					ownerId: '01GCDNAV6270SMVQBEAQ90VY4G',
					metaOwner: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						updatedAt: '2022-09-27T02:28:30.402Z',
						middleName: null,
						profilePic:
							'https://qbit-tech.sgp1.digitaloceanspaces.com/profiles/5ea8e837-b46a-4257-9fc0-a69cbdafbaa7',
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					isPrivate: true,
					localEventId: null,
					createdAt: '2022-09-30T04:43:01.785Z',
					updatedAt: '2022-09-30T04:43:01.785Z',
				},
			},
		],
		myRole: 'patient',
	},
	{
		name: 'personal-event',
		data: [
			{
				name: 'Kasi makan ucing',
				date: '2022-09-30',
				desc: '',
				startTime: '07:00',
				endTime: '07:15',
				raw: {
					eventId: 'c963be51-4738-4318-98e3-54be943c45f9',
					title: 'Kasi makan ucing',
					description: '',
					eventStartAt: '2022-09-30T00:00:49.000Z',
					eventEndAt: '2022-09-30T00:15:54.000Z',
					isBlocking: null,
					createdByUserId: '01GCDNAV6270SMVQBEAQ90VY4G',
					metaCreatedByUser: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						updatedAt: '2022-09-27T02:28:30.402Z',
						middleName: null,
						profilePic:
							'https://qbit-tech.sgp1.digitaloceanspaces.com/profiles/5ea8e837-b46a-4257-9fc0-a69cbdafbaa7',
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					ownerId: '01GCDNAV6270SMVQBEAQ90VY4G',
					metaOwner: {
						city: null,
						name: 'Rizki Rahmadi',
						email: null,
						phone: '63125',
						doctor: null,
						gender: 'male',
						status: 'active',
						userId: '01GCDNAV6270SMVQBEAQ90VY4G',
						patient: {
							pwId: null,
							pwIdUrl: null,
							createdAt: '2022-09-08T04:22:06.889Z',
							patientId: '01GCDNAV6270SMVQBEAQ90VY4G',
							updatedAt: '2022-09-08T04:22:06.889Z',
							pwIdStatus: 'WAITING_APPROVAL',
						},
						lastName: 'Rahmadi',
						nickName: 'Iki',
						province: null,
						userType: 'customer',
						birthdate: '1999-03-11',
						createdAt: '2022-09-08T04:22:06.787Z',
						firstName: 'Rizki',
						updatedAt: '2022-09-27T02:28:30.402Z',
						middleName: null,
						profilePic:
							'https://qbit-tech.sgp1.digitaloceanspaces.com/profiles/5ea8e837-b46a-4257-9fc0-a69cbdafbaa7',
						isEmailVerified: false,
						isPhoneVerified: true,
					},
					isPrivate: true,
					localEventId: null,
					createdAt: '2022-09-30T04:43:01.785Z',
					updatedAt: '2022-09-30T04:43:01.785Z',
				},
			},
		],
		myRole: 'secretary',
	},
];

export function findEventSettingByType(type?: string) {
	return dataTypeEvent.find((item) => item.type === type);
}

export function getScheduleType(
	item:
		| TransactionConsultationProperties
		| PersonalConsultationProperties
		| TransactionHealthServiceProps
		| PersonalEvent,
): EScheduleTypes | undefined {
	if (!item) {
		return undefined;
	}
	if ((item as any).transactionConsultationId) {
		item = item as TransactionConsultationProperties;
		if (
			item.consultationType ===
				EConsultationType.TELEMEDICINE_CHAT_CONSULTATION ||
			item.consultationType ===
				EConsultationType.TELEMEDICINE_VIDEO_CHAT_CONSULTATION
		) {
			return EScheduleTypes.telemedicine;
		} else if (
			item.consultationType ===
				EConsultationType.CONSULT_NOW_CHAT_CONSULTATION ||
			item.consultationType ===
				EConsultationType.CONSULT_NOW_VIDEO_CHAT_CONSULTATION
		) {
			return EScheduleTypes['consult-now'];
		} else if (
			item.consultationType === EConsultationType.FACE_TO_FACE_CONSULTATION
		) {
			return EScheduleTypes.appointment;
		}
	} else if ((item as any).transactionHealthServiceId) {
		return EScheduleTypes['medical-service'];
	} else if ((item as any).personalConsultationId) {
		item = item as PersonalConsultationProperties;
		if (
			item.consultationType ===
				EConsultationType.TELEMEDICINE_CHAT_CONSULTATION ||
			item.consultationType ===
				EConsultationType.TELEMEDICINE_VIDEO_CHAT_CONSULTATION
		) {
			return EScheduleTypes['telemedicine-personal'];
		} else if (
			item.consultationType === EConsultationType.FACE_TO_FACE_CONSULTATION
		) {
			return EScheduleTypes['appointment-personal'];
		}
	} else if ((item as any).eventId) {
		item = item as PersonalEvent;
		if (item.isBlocking) {
			return EScheduleTypes['personal-event-blocked'];
		} else {
			return EScheduleTypes['personal-event'];
		}
	}
	return undefined;
}
