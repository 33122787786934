import { Redirect, Route } from 'react-router-dom';
import { useAuth } from '../context/auth.context';

interface Props {
	exact?: boolean;
	path: string;
}

const PrivateRoute: React.FC<Props> = ({ children, ...rest }) => {
	const { isLoggedIn } = useAuth();

	return (
		<Route
			{...rest}
			render={({ location, match }: any) => {
				if (isLoggedIn === false) {
					return (
						<Redirect
							to={{
								pathname: '/',
								state: { from: location },
							}}
						/>
					);
				} else {
					return children;
				}
			}}
		/>
	);
};

export default PrivateRoute;
