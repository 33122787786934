import { useEffect, useState } from 'react';
import { apiGetEmergencyContacts } from '../../../../api/emergencyContact';
import { apiGetMedicalId } from '../../../../api/medicalId';
import { theme } from '../../../../assets/theme';
import { EmergencyContactProperties } from '../../../../types/emergencyContact.type';
import { MedicalIDProperties } from '../../../../types/medicalID.type';
import CustomScrollDiv from '../../../CustomScrollDiv';
import Spinner from '../../../Spinner';
import SidebarMenuHeader from './Components/SidebarMenuHeader';

interface Props {
	patientId?: string;
	onBack: Function;
}

export default function SidebarMedicalId(props: Props) {
	const [medicalId, setMedicalId] = useState<MedicalIDProperties>();
	const [emergencyContacts, setEmergencyContacts] = useState<
		EmergencyContactProperties[]
	>([]);

	useEffect(() => {
		(async () => {
			const res = await apiGetMedicalId(props.patientId);
			setMedicalId(res);
		})();

		(async () => {
			if (!props.patientId) return;

			const res = await apiGetEmergencyContacts(props.patientId);
			if (res) {
				setEmergencyContacts(res);
			}
		})();
	}, []);
	return (
		<div className="w-full flex flex-col">
			<SidebarMenuHeader onBack={props.onBack} title="Medical ID" />
			<CustomScrollDiv className="relative flex overflow-auto w-full h-full">
				{medicalId ? (
					<div className="w-full">
						<MedicalItem
							label="Medical Conditions"
							value={medicalId?.medicalConditions}
						/>
						<div>
							<MedicalItem
								label="Allergies & Reactions"
								value={medicalId?.allergiesAndReactions}
							/>
							<MedicalItem label="Medications" value={medicalId?.medications} />
							<MedicalItem label="Blood Type" value={medicalId?.bloodType} />
							<MedicalItem
								label="Weight"
								value={
									medicalId?.weightInKg
										? `${medicalId.weightInKg} kg`
										: undefined
								}
							/>
							<MedicalItem
								label="Height"
								value={
									medicalId?.heightInCm
										? `${medicalId.heightInCm} cm`
										: undefined
								}
							/>
						</div>
						{emergencyContacts.length > 0 && (
							<div className="mt-4">
								<p className="m-0 text-4">Emergency Contacts</p>
								{emergencyContacts.map((contact, index) => (
									<EmergencyContactItem
										key={index}
										label={contact.relation}
										name={contact.name}
										contact={contact.phone}
									/>
								))}
							</div>
						)}
					</div>
				) : (
					<div className="py-8 w-min mx-auto">
						<Spinner size={25} color={theme.colors.primary} />
					</div>
				)}
			</CustomScrollDiv>
		</div>
	);
}

function MedicalItem(props: { label: string; value?: string }) {
	return (
		<div className="py-4 border-b-solid border-b border-gray-10">
			<p className="m-0 text-black-60 text-4">{props.label}</p>
			<p className={'m-0 text-4' + (!props.value ? ' text-black-80' : '')}>
				{props.value ?? 'None'}
			</p>
		</div>
	);
}

function EmergencyContactItem(props: {
	label?: string;
	name?: string;
	contact?: string;
}) {
	return (
		<div className="py-3 border-b-solid border-b border-gray-10">
			<p className="m-0 text-black-60 text-4">{props.label ?? 'Unknown'}</p>
			<p className="m-0 text-4">{props.name ?? 'Unknown'}</p>
			<p className="m-0 text-4 text-blue">{props.contact ?? 'None'}</p>
		</div>
	);
}
