import { useEffect, useState } from 'react';
import { ArrowLeft } from 'react-feather';
import { useHistory, useParams } from 'react-router-dom';
import Separator from '../../../Separator';
import PreviewDoctorDocument from '../../Components/PreviewDocument';
import DoctorDocumentDetail from './DoctorDocumentDetail';
import DoctorDocumentList from './DoctorDocumentList';

const activePath = '/app/doctor-documents/history';

interface Props {
	title?: string;
}

interface Params {
	documentType: string;
	docId: string;
}

export default function MainDoctorDocumentHistory(props: Props) {
	const history = useHistory();
	const { documentType, docId } = useParams<Params>();

	const [state, setState] = useState<'list' | 'detail' | 'preview'>();
	const [title, setTitle] = useState<string>();
	const [selectedDoctorDocumentId, setSelectedDoctorDocumentId] =
		useState<string>();

	useEffect(() => {
		if (documentType !== 'history') return;

		if (!docId) {
			setTitle(props.title);
			setState('list');
			return;
		} else {
			setTitle('Detail');
			setState('detail');
		}

		setSelectedDoctorDocumentId(docId);
	}, [documentType, docId]);

	function toDetail(docId: string) {
		history.push(`${activePath}/${docId}`);
	}

	function changeState(state: 'list' | 'detail' | 'preview') {
		switch (state) {
			case 'list':
				setTitle(props.title);
				break;
			default:
				setTitle(state[0].toUpperCase() + state.slice(1));
		}
		setState(state);
	}

	function onBack() {
		history.goBack();
	}

	return (
		<div className="flex-1 flex flex-col">
			<div className="relative px-6 py-5.5 flex items-center">
				{state !== 'preview' && (
					<button
						onClick={onBack}
						className="border-none bg-transparent p-0 absolute flex items-center gap-x-1 cursor-pointer"
					>
						<ArrowLeft width={20} height={20} />
						<p className="m-0 font-bold text-3.5">Back</p>
					</button>
				)}
				<h1 className="font-semibold text-6 m-0 w-full text-center">{title}</h1>
			</div>
			<Separator />
			{state === 'list' ? (
				<DoctorDocumentList toDetail={toDetail} />
			) : state === 'detail' ? (
				<DoctorDocumentDetail
					doctorDocumentId={selectedDoctorDocumentId}
					toPreview={() => changeState('preview')}
				/>
			) : state === 'preview' ? (
				<PreviewDoctorDocument
					docId={selectedDoctorDocumentId!}
					type="doctor-document"
					buttonType="back"
					btnSuffix="Doctor Document"
					onClickBtn={() => changeState('detail')}
				/>
			) : undefined}
		</div>
	);
}
