import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import AppLayout from '../../../../layout/AppLayout';
import PageHeader from '../../../../components/PageHeader';
import {
	ClockCircleOutlined,
	ExclamationCircleOutlined as IconConsultNow,
	RightOutlined,
	WarningOutlined,
} from '@ant-design/icons';
import {
	iconTelemedicine as IconTelemedicine,
	iconAppointment as IconInPerson,
} from '../../../../assets/icons';
import {
	Button,
	Card,
	Divider,
	message,
	Row,
	Spin,
	Typography,
	Col,
	Modal,
	Result,
	InputNumber,
} from 'antd';
import moment from 'moment';
import { EPartnerType } from '../../../../types/partner.type';
import {
	apiCreateUpdatePartnerDoctor,
	apiDeletePartnerDoctor,
	apiGetPartnerDoctors,
} from '../../../../api/partnerDoctor';
import COLORS from '../../../../assets/globalColors';
import { PracticeDataItem } from '../../../../constants/clinic';
import { useAuth } from '../../../../context/auth.context';

const activePath = '/app/setting/clinics-and-practice/add-clinic';
const { Text } = Typography;

type IDataAvailabilityItem = {
	weekday: number;
	title: string;
	data?: {
		scheduleId: string;
		text: string;
		startAt: Date | string;
		endAt: Date | string;
	}[];
};

export default function DetailClinicScreen() {
	const { userId } = useAuth();
	const history = useHistory();
	const location = useLocation();
	const payload = location.state as unknown as PracticeDataItem;

	const [selected, setSelected] = useState<PracticeDataItem>(
		payload
			? payload
			: {
					title: '',
					partnerId: '',
					type: 'appointment',
					price: 0,
					availableDay: [],
					schedules: [],
			  },
	);

	const [showEditPrice, setShowEditPrice] = useState<boolean>(false);
	const [editPriceValue, setEditPriceValue] = useState<number>(
		selected && selected.price ? selected.price : 0,
	);
	// allowEditPrice === true -> consult-now sedang active, allowEditPrice === false -> consult now sedang inactive
	const [allowEditPrice, setAllowEditPrice] = useState<boolean>(true);

	const [dataAvailability, setDataAvailability] = useState<
		Array<IDataAvailabilityItem>
	>([]);

	const [showDeactivate, setShowDeactivate] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [showModalConfirmation, setShowModalConfirmation] =
		useState<boolean>(false);

	// API
	const fetchListSchedules = async () => {
		try {
			setIsLoading(true);
			const res = await apiGetPartnerDoctors(userId, selected.partnerId);

			console.log('res::', res);

			if (res) {
				const availableDay: number[] = [];
				const schedules: {
					scheduleId: string;
					weekday: number;
					startAt: string | Date;
					endAt: string | Date;
				}[] = [];
				for (const result of res.results) {
					if (result.schedules) {
						for (const sch of result.schedules) {
							if (!availableDay.includes(sch.weekday)) {
								availableDay.push(sch.weekday);
							}
							schedules.push({
								scheduleId: sch.scheduleId || '',
								weekday: sch.weekday,
								startAt: sch.startAt,
								endAt: sch.endAt,
							});
						}
					}
				}

				const tempSelected = { ...selected, availableDay, schedules };
				if (
					tempSelected.isEnabled !== undefined &&
					tempSelected.isEnabled === false
				) {
					setAllowEditPrice(false);
				}

				setSelected({
					...selected,
					availableDay,
					schedules,
				});

				let temp = Array.from({ length: 7 }, (v, i) => ({
					weekday: i,
					title: moment().isoWeekday(i).format('dddd'),
					data: schedules
						.filter((sch) => sch.weekday === i)
						.map((sch) => ({
							scheduleId: sch.scheduleId,
							text:
								moment(sch.startAt).format('HH:mm') +
								'-' +
								moment(sch.endAt).format('HH:mm'),
							startAt: sch.startAt,
							endAt: sch.endAt,
						})),
					// get from schedules
				}));
				console.log('data availability::', temp);

				setDataAvailability(
					Array.from({ length: 7 }, (v, i) => ({
						weekday: i,
						title: moment().isoWeekday(i).format('dddd'),
						data: schedules
							.filter((sch) => sch.weekday === i)
							.map((sch) => ({
								scheduleId: sch.scheduleId,
								text:
									moment.utc(sch.startAt).format('HH:mm') +
									'-' +
									moment.utc(sch.endAt).format('HH:mm'),
								startAt: sch.startAt,
								endAt: sch.endAt,
							})),
					})),
				);
			}
			console.log('selected::', selected);
			setIsLoading(false);
		} catch (err) {
			setIsLoading(false);
			message.error('ERROR: Failed to load schedules');
		}
	};

	const handleSavePrice = async () => {
		try {
			setIsLoading(true);
			await apiCreateUpdatePartnerDoctor({
				partnerId: payload.partnerId,
				doctorId: userId || '',
				pricePerSession: editPriceValue,
			});

			setSelected({ ...selected, price: editPriceValue });
			message.success('SUCCESS: Price has been changed successfully');
			setIsLoading(false);
		} catch (err) {
			setIsLoading(false);
			message.success('ERROR: Failed to save price');
		}
	};

	const inactivateFeature = async (
		feature: 'telemedicine' | 'consult-now' | 'appointment',
	) => {
		try {
			setIsLoading(true);
			setShowDeactivate(false);
			setAllowEditPrice(false);
			await apiDeletePartnerDoctor(
				feature === 'telemedicine'
					? EPartnerType.ONLINE_CLINIC
					: feature === 'consult-now'
					? EPartnerType.CONSULT_NOW
					: feature,
				userId || '',
			);
			fetchListSchedules();
			message.success('SUCCESS: Clinic successfully deactivated');
			setShowDeactivate(false);
			setIsLoading(false);
		} catch (err) {
			setIsLoading(false);
			message.error('ERROR: Failed to deactivate clinic');
		}
	};

	const deleteClinic = async (clinicId: string) => {
		setIsLoading(true);
		try {
			const res = await apiDeletePartnerDoctor(clinicId, userId || '');
			if (res) {
				setIsLoading(false);
				setShowModalConfirmation(false);
				history.goBack();
				message.success('SUCCESS: Clinic successfully deleted!');
			}
		} catch {
			setIsLoading(false);
			message.error('ERROR: Please delete clinic again!');
		}
	};

	useEffect(() => {
		if (userId) {
			fetchListSchedules();
		}
	}, [userId]);

	// Helper Functions
	const generateTitle = (type: string) => {
		if (type === 'telemedicine') {
			return 'Telemedicine';
		} else if (type === 'consult-now') {
			return 'Consult Now';
		} else {
			return 'In Person';
		}
	};

	// Components
	const LocationAndPrice = () => {
		let backgroundColor: any;
		let icon: any;
		const title =
			selected.type !== 'appointment' ? 'Medeasy App' : selected.title || '-';
		const location =
			selected.type !== 'appointment' ? 'Online' : selected.location || '-';
		const price = selected.price || '0';

		if (selected.type === 'consult-now') {
			backgroundColor = COLORS.yellow;
			icon = <IconConsultNow style={{ fontSize: 10, color: 'white' }} />;
		} else if (selected.type === 'telemedicine') {
			backgroundColor = COLORS.primary;
			icon = <IconTelemedicine width={10} height={10} />;
		} else {
			backgroundColor = COLORS.blue;
			icon = <IconInPerson width={10} height={10} />;
		}

		return (
			<>
				<Row style={{ width: '100%' }}>
					<Col style={{ marginRight: 12 }}>
						<div
							style={{
								width: 24,
								height: 24,
								backgroundColor: backgroundColor,
								borderRadius: '50%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							{icon}
						</div>
					</Col>
					<Col>
						<Row>
							<Text style={{ fontWeight: 600, fontSize: 18 }}>{title}</Text>
						</Row>
						<Row>
							<Text
								style={{
									fontSize: 14,
									color: COLORS.primary_black_70,
								}}
							>
								{location}
							</Text>
						</Row>
						<Row>
							<Text style={{ fontWeight: 600, fontSize: 16 }}>
								Price: ₱ {price}
							</Text>
						</Row>
					</Col>
					<Col
						style={{
							position: 'absolute',
							right: 0,
							marginRight: 24,
							display: 'flex',
							alignItems: 'center',
						}}
					>
						{allowEditPrice && (
							<Button
								style={{
									borderColor: COLORS.primary,
									color: COLORS.primary,
									fontSize: 14,
								}}
								onClick={() => {
									setShowEditPrice(true);
								}}
								type="ghost"
							>
								Edit Price
							</Button>
						)}
						{!allowEditPrice && (
							<Button
								style={{ color: COLORS.ash_600, fontSize: 14 }}
								disabled={true}
							>
								Edit Price
							</Button>
						)}
					</Col>
				</Row>
				<Divider style={{ marginBottom: 10 }} />
			</>
		);
	};

	const DayAndTime = (
		day: string,
		time: string[],
		originalData: IDataAvailabilityItem,
	) => {
		return (
			<>
				<Row
					onClick={() => {
						if (allowEditPrice) {
							history.push('/app/setting/clinics-and-practice/details/edit', {
								partnerId: selected.partnerId,
								type: generateTitle(selected.type),
								availability: originalData,
							});
						}
					}}
					style={
						allowEditPrice
							? { width: '100%', cursor: 'pointer' }
							: { width: '100%', cursor: 'not-allowed' }
					}
				>
					<Col>
						<Row>
							<Text style={{ fontWeight: 600, fontSize: 16 }}>{day}</Text>
						</Row>
						<Row>
							<ClockCircleOutlined
								style={{
									fontSize: 13,
									color: COLORS.primary_black_50,
									marginRight: 5,
								}}
							/>
							<Text
								style={{
									color: COLORS.primary_black_50,
									fontSize: 14,
								}}
							>
								{' '}
								{time.length > 0 ? time.join(' | ') : 'No Schedule'}
							</Text>
						</Row>
					</Col>
					<RightOutlined
						style={{
							marginRight: 17,
							marginTop: 10,
							position: 'absolute',
							right: 0,
							color: COLORS.ash_600,
						}}
						height={14}
						width={7}
					/>
				</Row>
				<Divider style={{ marginTop: 10, marginBottom: 10 }} />
			</>
		);
	};

	const ModalIcon = () => {
		return (
			<Row align="middle" justify="center" style={{ width: '100%' }}>
				<div
					style={{
						backgroundColor: '#F1F3F5',
						borderRadius: '50%',
						width: 64,
						height: 64,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<WarningOutlined style={{ fontSize: 28 }} />
				</div>
			</Row>
		);
	};

	// Component Group
	const DayAndTimeList = () => {
		const output: any = [];
		for (let day of dataAvailability) {
			const title = day.title;
			const time: string[] = [];
			if (day.data) {
				for (let dayTime of day.data) {
					time.push(dayTime.text);
				}
			}
			output.push(DayAndTime(title, time, day));
		}
		return output;
	};

	return (
		<AppLayout activePath={activePath}>
			<Card style={{ paddingBottom: 110 }}>
				<PageHeader
					title={
						selected ? `${generateTitle(selected.type)} Setting` : 'Setting'
					}
					onClick={() => history.goBack()}
				/>
				<Divider />
				{isLoading && <CustomSpin spinning={isLoading} />}
				{!isLoading && (
					<>
						<LocationAndPrice />
						<DayAndTimeList />
					</>
				)}
				{!isLoading &&
					selected &&
					selected.type === 'consult-now' &&
					allowEditPrice === true && (
						<Row justify="center">
							<Button
								style={{
									backgroundColor: COLORS.ash_300,
									borderRadius: 24,
									marginTop: 12,
								}}
								onClick={() => {
									setShowDeactivate(true);
								}}
							>
								<Text
									style={{
										fontWeight: 700,
										fontSize: 14,
										color: COLORS.black,
									}}
								>
									Deactivate Consult Now
								</Text>
							</Button>
						</Row>
					)}
				{!isLoading && selected && selected.type === 'appointment' && (
					<Row justify="center">
						<Button
							style={{
								backgroundColor: COLORS.white,
								borderRadius: 12,
								marginTop: 12,
								border: `1px solid ${COLORS.primary}`,
							}}
							onClick={() => {
								setShowModalConfirmation(true);
							}}
						>
							<Text
								style={{
									fontWeight: 700,
									fontSize: 14,
									color: COLORS.red,
								}}
							>
								Delete Clinic
							</Text>
						</Button>
					</Row>
				)}

				{/* Edit Price Modal */}
				<Modal
					centered
					closable={false}
					open={showEditPrice}
					onOk={() => {
						handleSavePrice();
						setShowEditPrice(false);
					}}
					onCancel={() => {
						setShowEditPrice(false);
					}}
					footer={
						<>
							<Row gutter={16} style={{ width: '100%' }}>
								<Col span={12}>
									<Button
										onClick={() => {
											setShowEditPrice(false);
										}}
										ghost
										style={{
											width: '100%',
											color: '#1D2B36',
											backgroundColor: '#F1F3F5',
											fontSize: 14,
											fontWeight: 700,
										}}
									>
										Cancel
									</Button>
								</Col>
								<Col span={12}>
									<Button
										type="primary"
										style={{
											width: '100%',
											fontSize: 14,
											fontWeight: 700,
										}}
										onClick={() => {
											setShowEditPrice(false);
											handleSavePrice();
										}}
									>
										Save
									</Button>
								</Col>
							</Row>
						</>
					}
				>
					<Row justify="center">
						<Text
							style={{
								fontWeight: 600,
								fontSize: 20,
								marginTop: 12,
								marginBottom: 4,
							}}
						>
							Set Consultation Price
						</Text>
					</Row>
					<Row justify="center">
						<Text style={{ fontSize: 14 }}>Enter your consultation fee</Text>
					</Row>
					<Row justify="center" style={{ marginTop: 24 }}>
						<InputNumber
							prefix="₱"
							style={{ width: '100%' }}
							controls={false}
							defaultValue={selected.price}
							min={0}
							onPressEnter={() => {
								setShowEditPrice(false);
								handleSavePrice();
							}}
							onChange={(val) => {
								console.log('new price::', val);
								setEditPriceValue(val ? val : 0);
							}}
						/>
					</Row>
				</Modal>

				{/* Inactivate Consult Now Confirmation Modal */}
				{showDeactivate && (
					<Modal
						centered
						closable={false}
						open={showDeactivate}
						onOk={() => {
							inactivateFeature('consult-now');
						}}
						onCancel={() => {
							setShowDeactivate(false);
						}}
						footer={
							<>
								<Row gutter={16} style={{ width: '100%' }}>
									<Col span={12}>
										<Button
											onClick={() => {
												setShowDeactivate(false);
											}}
											ghost
											style={{
												width: '100%',
												color: '#1D2B36',
												backgroundColor: '#F1F3F5',
												fontSize: 14,
												fontWeight: 700,
											}}
										>
											Not Now
										</Button>
									</Col>
									<Col span={12}>
										<Button
											type="primary"
											onClick={() => {
												inactivateFeature('consult-now');
											}}
											style={{
												width: '100%',
												fontSize: 14,
												fontWeight: 700,
											}}
										>
											Yes
										</Button>
									</Col>
								</Row>
							</>
						}
					>
						<Result
							icon={<ModalIcon />}
							title="Confirmation"
							subTitle="Are you sure to deactivate Consult Now? You will not receive a consult now request from a Patient again."
						/>
					</Modal>
				)}

				{/* Delete Clinic Confirmation Modal */}
				<Modal
					centered
					closable={false}
					open={showModalConfirmation}
					onOk={() => {
						deleteClinic(selected.partnerId);
					}}
					onCancel={() => {
						setShowModalConfirmation(false);
					}}
					footer={
						<>
							<Row gutter={16} style={{ width: '100%' }}>
								<Col span={12}>
									<Button
										onClick={() => {
											setShowModalConfirmation(false);
										}}
										ghost
										style={{
											width: '100%',
											color: '#1D2B36',
											backgroundColor: '#F1F3F5',
											fontSize: 14,
											fontWeight: 700,
										}}
									>
										Not Now
									</Button>
								</Col>
								<Col span={12}>
									<Button
										type="primary"
										onClick={() => {
											deleteClinic(selected.partnerId);
										}}
										style={{
											width: '100%',
											fontSize: 14,
											fontWeight: 700,
										}}
									>
										Yes
									</Button>
								</Col>
							</Row>
						</>
					}
				>
					<Result
						icon={<ModalIcon />}
						title="Confirmation"
						subTitle="Are you sure to delete this clinic?"
					/>
				</Modal>
			</Card>
		</AppLayout>
	);
}

// Custom Components
const CustomSpin = styled(Spin)`
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0);
`;
