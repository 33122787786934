import { message } from 'antd';
import { httpRequest } from '../helpers/api';
import { getErrorMessage } from '../helpers/errorHandler';
import { ApiResponseDefault } from '../types/apiResponse.type';
import { DrugMedicineProperties } from '../types/drugMedicine';

export async function apiGetDrugMedicine(drugMedicineId: string) {
	try {
		const res = await httpRequest.get<
			ApiResponseDefault<DrugMedicineProperties>
		>(`drug-medicines/${drugMedicineId}`);
		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}
