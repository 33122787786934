import React from 'react';
import { IUser, initialUser } from '../types/user.type';
import {
	getToken,
	getUser,
	removeToken,
	removeUser,
	saveToken,
	saveUser,
} from '../helpers/auth';
import { message } from 'antd';
import { getErrorMessage } from '../helpers/errorHandler';
import { DoctorSecretaryProperties } from '../types/doctorSecretaries.type';
import { AuthByPhoneLoginResponse } from '../types/authByPhone.type';
import { apiConfirmOTP, apiGetMyProfile } from '../api/auth';
import { EKycStatus } from '../types/doctor.type';

type IResAfterSignIn = { token: string; user?: IUser; isNewAccount?: boolean };

interface AuthContextType {
	isLoading?: boolean;
	isLoadingPartner?: boolean;
	token?: string;
	user?: IUser;
	userId?: string;
	isLoggedIn?: boolean;
	isDoctor?: boolean;
	isPatient?: boolean;
	isSecretary?: boolean;
	doctorKYCStatus?: EKycStatus;
	secretaryManagedDoctors: DoctorSecretaryProperties[];
	setIsLoading: (val: boolean) => void;
	init: () => void;
	signin: (
		sessionId: string,
		otp: string,
		callback?: (res: IResAfterSignIn) => void,
	) => void;
	// saveProfile: (data: IUser) => void;
	signout: (callback?: VoidFunction) => void;

	setToken: (token: string) => void;
	getProfile: (
		token?: string,
		callback?: (res: IUser) => void,
	) => Promise<IUser | null>;
}

let AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
	let [isLoading, setIsLoading] = React.useState<boolean>(true);
	let [user, setUser] = React.useState<IUser | undefined>(getUser());
	let [token, setToken] = React.useState<string | undefined>(undefined);
	let [secretaryManagedDoctors, setSecretaryManagedDoctors] = React.useState<
		DoctorSecretaryProperties[]
	>([]);

	const init = async () => {
		const existingToken = await getToken();
		if (existingToken) {
			setToken(existingToken);
			const existingUser = await getUser();
			if (existingUser) {
				setUser(existingUser);
			}
			getProfile(existingToken);
		}
	};

	const signin = async (
		sessionId: string,
		otp: string,
		callback?: (res: IResAfterSignIn) => void,
	) => {
		setIsLoading(true);
		try {
			const resLogin: AuthByPhoneLoginResponse['data']['payload'] =
				await apiConfirmOTP(sessionId, otp);
			const resProfile = await getProfile(resLogin.token);

			const res = {
				token: resLogin.token,
				user: resProfile || undefined,
			};

			handleAfterLogin(res);
			message.success(`Login successfully.`);
			setIsLoading(false);

			if (callback) {
				callback({ ...res, isNewAccount: resLogin.isNewAccount });
			}
		} catch (err) {
			setIsLoading(false);
			message.error('Login failed. ' + getErrorMessage(err));
		} finally {
			setIsLoading(false);
		}
	};

	const getProfile = async (
		token?: string,
		callback?: (res: IUser) => void,
	) => {
		try {
			setIsLoading(true);
			let resultUser: IUser = await apiGetMyProfile(token);

			if (resultUser.status === 'inactive') {
				message.error('Inactive account');
				signout(() => {
					setIsLoading(false);
				});
				return null;
			}

			// console.info('resultUser', resultUser);
			setUser(resultUser);
			setIsLoading(false);

			if (callback) {
				callback(resultUser);
			}

			return resultUser;
		} catch (err) {
			message.error(getErrorMessage(err));
			setIsLoading(false);

			return null;
		}
	};

	// const saveProfile = async (data: IUser) => {
	//   if (!user) {
	//     return;
	//   }
	//   setIsLoading(true);

	//   /*
	//   const formData = new FormData();
	//   formData.append('name', data.name)
	//   formData.append('email', data.email)
	//   formData.append('phone', data.phone)
	//   formData.append('userType', data.userType)
	//   formData.append('status', data.status)

	//   if (data.image) {
	//     formData.append('image', data.image);
	//   }
	//   */

	//   const formData = {
	//     name: data.name,
	//     email: data.email,
	//     phone: data.phone,
	//     userType: data.userType,
	//     status: data.status,

	//     firstName: data.firstName,
	//     middleName: data.middleName,
	//     lastName: data.lastName,
	//   };

	//   const imageData = new FormData();
	//   if (data.image) imageData.append("image", data.image);

	//   try {
	//     /*
	//     const resultUpdate = await axios.patch<IHttpResponse<IUser>>(
	//       process.env.REACT_APP_BASE_URL + '/user/' + user.userId,
	//       formData,
	//       {
	//         headers: {
	//           Authorization: 'Bearer ' + token,
	//           'Content-Type': 'multipart/form-data',
	//         },
	//       }
	//     );
	//     */
	//     const resultUpdate = await httpRequest.patch<IUser>(
	//       "/user/" + user?.userId,
	//       formData
	//     );
	//     if (data.image) {
	//       await httpRequest.put("/user/" + user?.userId + "/photo", imageData, {
	//         headers: {
	//           "Content-Type": "multipart/form-data",
	//         },
	//       });
	//     }
	//     setUser(resultUpdate.data);
	//     setIsLoading(false);
	//     message.success("Update profile successfully");
	//   } catch (err) {
	//     message.error(getErrorMessage(err));
	//     setIsLoading(false);
	//   }
	// };

	const handleAfterLogin = (data: IResAfterSignIn) => {
		saveToken(data.token);
		setToken(data.token);
		if (data.user) {
			saveUser(data.user);
		}
		setUser(data.user);
	};

	let signout = async (callback?: VoidFunction) => {
		await removeToken();
		await removeUser();
		setToken(undefined);
		setUser(undefined);

		if (callback) {
			callback();
		}
	};

	let value = {
		isLoading,
		user,
		userId: user?.userId,
		token,
		isLoggedIn:
			token === undefined || user === undefined
				? undefined
				: token && user
				? true
				: false,
		isDoctor: user?.doctor ? true : false,
		isPatient: !user?.doctor,
		isSecretary:
			secretaryManagedDoctors && secretaryManagedDoctors.length > 0
				? true
				: false,
		doctorKYCStatus: user?.doctor?.kycStatus,
		secretaryManagedDoctors,
		setIsLoading,
		init,
		signin,
		signout,
		setToken,
		getProfile,
	};

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
	return React.useContext(AuthContext);
}
