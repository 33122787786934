import { createRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import AppLayout from '../../../../layout/AppLayout';
import PageHeader from '../../../../components/PageHeader';
import {
	Button,
	Card,
	Divider,
	Row,
	Spin,
	Typography,
	Col,
	Upload,
	Form,
	Radio,
	Modal,
	UploadProps,
	UploadFile,
	RadioChangeEvent,
	message,
	Result,
} from 'antd';
import {
	DoctorProperties,
	SpecialistProperties,
	UploadSignatureResponse,
	initialDoctor,
} from '../../../../types/doctor.type';
import { httpRequest } from '../../../../helpers/api';
import {
	ApiResponseDefault,
	ApiResponsePagination,
} from '../../../../types/apiResponse.type';
import { iconDeleteOutlineColorize as IconDeleteOutline } from '../../../../assets/icons';
import { UploadOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/lib/upload';
import { QuerySpecialist } from '../../../../types/specialist.type';
import {
	IconSpecializationRegisterForm,
	IconUserOutlined,
} from '../../../../assets/images';
import { ReactSketchCanvas } from 'react-sketch-canvas';
import { generateQueryString } from '../../../../helpers/generateQueryString';
import ModalSpecialization from '../../../../components/Webapp/DoctorInformation/ModalSpecialization';
import ModalSubSpecialization from '../../../../components/Webapp/DoctorInformation/ModalSubSpecialization';
import { useAuth } from '../../../../context/auth.context';
import FormInput, { EFormType } from '../../../../components/Form/FormInput';
import { getInitialMiddleName } from '../../../../helpers/name';
import {
	validateAge,
	validateBirthdate,
	validateName,
} from '../../../../helpers/validation';
import moment from 'moment';

const { Text } = Typography;

const activePath = '/app/setting/doctor-information/edit';

export default function EditDoctorInformation() {
	const history = useHistory();
	const { userId, user } = useAuth();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
	const [doctor, setDoctor] = useState<DoctorProperties>({ ...initialDoctor });

	const [showSpecializationModal, setShowSpecializationModal] =
		useState<boolean>(false);
	const [showSubSpecializationModal, setShowSubSpecializationModal] =
		useState<any>({});
	const [specializationList, setSpecializationList] = useState<
		SpecialistProperties[]
	>([]);
	const [groupingSpecialist, setGroupingSpecialist] = useState<any>([]);

	const [radioValue, setRadioValue] = useState<number>(1);

	const inAppSignatureRef: any = createRef();
	const [exportImage, setExportImage] = useState<any>(null);

	const fetchList = async () => {
		setIsLoading(true);
		try {
			const res = await httpRequest.get<ApiResponseDefault<DoctorProperties>>(
				'/doctors/' + userId,
			);
			if (res) {
				setDoctor(res.data.payload);
			}
			setIsLoading(false);
		} catch (error) {
			console.info('error fetch doctor ::: ', error);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchList();
	}, [userId]);

	useEffect(() => {
		const fetchSpecialization = async (query: QuerySpecialist) => {
			try {
				const res = await httpRequest.get<
					ApiResponsePagination<SpecialistProperties>
				>('/specialists/' + generateQueryString(query));
				if (res.data.payload.results) {
					setSpecializationList(res.data.payload.results);
				}
			} catch (error) {
				console.info('error fetch specialization ::: ', error);
			}
		};
		fetchSpecialization({
			sort: 'specialistName:ASC',
		});
	}, []);

	useEffect(() => {
		let newGroupSpecialists = doctor?.specialists?.reduce(function (
			storage: any,
			item: any,
		) {
			const data = {
				specialistId: item.specialistId || '',
				specialistName: item.specialistName || '',
				subSpecialists:
					item.subSpecialistId || item.subSpecialistName
						? [
								{
									subSpecialistId: item.subSpecialistId || '',
									subSpecialistName: item.subSpecialistName || '',
								},
						  ]
						: [],
			};

			let group = item['specialistId'];
			if (!group) {
				group = item['specialistName'];
			}

			storage[group] = storage[group] || {};
			const storageLength = Object.keys(storage[group]).length;
			if (storageLength > 0) {
				storage[group] = {
					...storage[group],
					subSpecialists: [
						...(storage[group].subSpecialists || []),
						...(data.subSpecialists || []),
					],
				};
			} else {
				storage[group] = data;
			}
			return storage;
		}, {});

		setGroupingSpecialist(newGroupSpecialists);
	}, [doctor]);

	const handleConfirmSpecialization = (items: SpecialistProperties[]) => {
		const selectedData = items.map((item) => ({
			specialistId: item.specialistId ?? '',
			specialistName: item.specialistName ?? '',
		}));

		setDoctor((old) => ({
			...old,
			specialists: [...(old.specialists ?? []), ...selectedData],
		}));

		setShowSpecializationModal(false);
	};

	const handleConfirmSubSpecialization = (
		items: SpecialistProperties[],
		parent: SpecialistProperties,
	) => {
		const selectedData = items.map((item) => ({
			specialistId: parent.specialistId ?? '',
			specialistName: parent.specialistName ?? '',
			subSpecialistId: item.specialistId ?? '',
			subSpecialistName: item.specialistName ?? '',
		}));

		console.log('selectedData=>>>>>>', selectedData);

		setDoctor((old) => ({
			...old,
			specialists: [...(old.specialists ?? []), ...selectedData],
		}));

		setShowSubSpecializationModal((prev: any) => ({
			...prev,
			[parent.specialistName]: false,
		}));
	};

	const handleRemoveItemSpecialization = (name: string) => {
		const newSpecialitites = doctor?.specialists?.filter(
			(specialist) =>
				specialist.specialistName !== name &&
				specialist.subSpecialistName !== name,
		);
		setDoctor((old) => ({
			...old,
			specialists: newSpecialitites,
		}));
	};

	//Helper Function
	const saveInAppSignature = async () => {
		if (radioValue === 1 && inAppSignatureRef.current) {
			const image = await inAppSignatureRef.current.exportImage('png');
			setExportImage(image);
			message.success('in App Signature Save');
		}
	};

	const onRadioChange = (e: RadioChangeEvent) => {
		setRadioValue(e.target.value);
	};

	const exportAsPng = async () => {
		console.log(exportImage);
		console.log('converting to File...');
		const file = await convertPngToFile(exportImage);
		console.log('processed file is:::', file);
		return file;
	};

	const convertPngToFile = async (url: string) => {
		const res = await fetch(url);
		const data = await res.blob();
		const metadata = {
			type: 'image/jpeg',
		};
		const name = `${Math.random().toString()}.jpg`;
		return new File([data], name, metadata);
	};

	// Upload Certificate

	const getBase64 = (file: RcFile): Promise<string> =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result as string);
			reader.onerror = (error) => reject(error);
		});

	// Upload Signature
	const [previewSignatureOpen, setPreviewSignatureOpen] = useState(false);
	const [previewSignatureImage, setPreviewSignatureImage] = useState('');
	const [previewSignatureTitle, setPreviewSignatureTitle] = useState('');
	const [fileSignature, setFileSignature] = useState<any>([]);

	const handleSignatureCancel = () => setPreviewSignatureOpen(false);

	const handleSignaturePreview = async (file: UploadFile) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj as RcFile);
		}

		setPreviewSignatureImage(file.url ?? (file.preview as string));
		setPreviewSignatureOpen(true);
		setPreviewSignatureTitle(
			file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1),
		);
		console.log('fileList:::', fileSignature);
	};

	const handleSignatureChange: UploadProps['onChange'] = ({
		fileList: newFileList,
	}) => {
		setFileSignature(newFileList);
		console.log('fileSignature:::', newFileList);
	};

	const onSubmit = async () => {
		setLoadingSubmit(true);

		// update user name
		let name = '';
		if (doctor.user?.firstName) {
			name = doctor.user.firstName;
		}
		if (doctor.user?.middleName) {
			name = name + ' ' + doctor.user.middleName;
		}
		if (doctor.user?.lastName) {
			name = name + ' ' + doctor.user.lastName;
		}

		let signatureName = '';
		signatureName = `${
			doctor.user?.firstName ? doctor.user.firstName + ' ' : ''
		}${doctor.user?.middleName ? doctor.user.middleName + ' ' : ''}${
			doctor.user?.lastName ? doctor.user.lastName + ' ' : ''
		}${doctor.title || ''}`;

		// post doctor info (without certificates & signature)
		try {
			const resUser = await httpRequest.patch(
				`${process.env.REACT_APP_BASE_URL}/user/${userId}`,
				{
					...doctor.user,
					name: name,
				},
			);
			console.log('ini resUser::', resUser);

			const resDoctor = await httpRequest.patch(
				`${process.env.REACT_APP_BASE_URL}/doctors/${userId}`,
				{
					...doctor,
					nameOfSignature: signatureName,
				},
			);
			console.log('ini resDoctor::', resDoctor);
		} catch (err) {
			message.error('Failed to post to /doctors');
		}

		// put signature
		let signatureImage;
		if (exportImage || fileSignature?.length !== 0) {
			if (radioValue === 1) {
				console.log('exporting as png...');
				signatureImage = await exportAsPng();
			} else {
				signatureImage = fileSignature[0].originFileObj;
			}
			console.log('UPLOAD SIGNATURE - 1. image signature ::: ', signatureImage);
			console.log('UPLOAD SIGNATURE - 2. user ::: ', user);

			let data = new FormData();
			data.append('image', signatureImage);

			try {
				const resUploadSignature = await httpRequest.put<
					ApiResponseDefault<UploadSignatureResponse>
				>('/doctors/' + user?.userId + '/upload-signature', data, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				});

				console.log('UPLOAD SIGNATURE - 3. result ::: ', resUploadSignature);

				if (resUploadSignature?.data?.payload) {
					console.log('UPLOAD SIGNATURE - 3. result ::: ', resUploadSignature);
				}
			} catch (error) {
				message.error('error upload signature');
				console.log('error upload signature ::: ', error);
				setLoadingSubmit(false);
			}
		}

		fetchList();
		setLoadingSubmit(false);
		history.goBack();
	};

	const uploadSignatureButton = (
		<>
			<Button
				type="primary"
				block
				ghost
				icon={<UploadOutlined />}
				style={{
					backgroundColor: '#ffffff',
					color: '#D81F64',
					width: '100%',
				}}
			>
				Upload Signature
			</Button>
		</>
	);

	const ProfileSection = (title: string, children: any) => {
		return (
			<Row>
				<Col span={8}>
					<Text style={{ fontWeight: 600, fontSize: 16, color: '#405261' }}>
						{title}
					</Text>
				</Col>
				<Col style={{ flex: 1 }}>{children()}</Col>
			</Row>
		);
	};

	const profileForm = () => {
		return (
			<CustomForm layout="vertical">
				<FormInput
					required
					label="Name"
					placeholder="Name"
					value={doctor.user?.firstName}
					onChange={(e) =>
						setDoctor((prev: any) => ({
							...prev,
							user: { ...prev.user, firstName: e },
						}))
					}
					validation="alphabet-only"
				/>
				<FormInput
					label="Middle Initial"
					placeholder="Middle Initial"
					value={doctor.user?.middleName}
					onChange={(e) =>
						setDoctor((prev: any) => ({
							...prev,
							user: { ...prev.user, middleName: getInitialMiddleName(e) },
						}))
					}
					validation="alphabet-only"
				/>
				<FormInput
					label="Last Name"
					placeholder="Last Name"
					value={doctor.user?.lastName}
					onChange={(e) =>
						setDoctor((prev: any) => ({
							...prev,
							user: { ...prev.user, lastName: e },
						}))
					}
					validation="alphabet-only"
				/>
				<FormInput
					required
					label="Doctor's Title"
					placeholder="Doctor's Title"
					value={doctor.title}
					onChange={(e) => setDoctor((prev: any) => ({ ...prev, title: e }))}
				/>
				<FormInput
					required
					label="Nickname"
					placeholder="Nickname"
					value={doctor.user?.nickName}
					onChange={(e) =>
						setDoctor((prev: any) => ({
							...prev,
							user: { ...prev.user, nickName: e },
						}))
					}
				/>
				<FormInput
					required
					label="Birthdate"
					classInput="w-full text-4 border border-solid border-ash-800 rounded-4 py-2.5 px-3"
					placeholder="YYYY/MM/DD"
					type={EFormType.DATE_PICKER}
					value={doctor.user?.birthdate}
					onChangeDate={(date: any) =>
						setDoctor((prev: any) => ({
							...prev,
							user: {
								...prev.user,
								birthdate: date ? moment(date).format('YYYY-MM-DD') : '',
							},
						}))
					}
					validation="birthdate"
				/>
				<FormInput
					required
					label="Gender"
					type={EFormType.GENDER}
					value={doctor.user?.gender}
					onChange={(e) =>
						setDoctor((prev: any) => ({
							...prev,
							user: {
								...prev.user,
								gender: e,
							},
						}))
					}
				/>
				<FormInput
					required
					label="Experience"
					placeholder="Experience"
					value={doctor.experience}
					onChange={(e) =>
						setDoctor((prev: any) => ({
							...prev,
							experience: e as unknown as number,
						}))
					}
					validation="number-only"
				/>
			</CustomForm>
		);
	};

	const specializationForm = () => {
		return (
			<CustomForm layout="vertical">
				<Form.Item style={{ margin: 0 }} label="Specialization">
					{doctor?.specialists?.length === 0 ? (
						<Result
							style={{ padding: 0 }}
							icon={<IconSpecializationRegisterForm />}
							title="Specialization Empty"
							subTitle="The specialization you enter will appear here."
							extra={
								<Button
									type="primary"
									ghost
									onClick={() => {
										setShowSpecializationModal(true);
									}}
								>
									Choose
								</Button>
							}
						></Result>
					) : (
						<>
							<div>
								{Object.keys(groupingSpecialist)?.map(
									(specialistId: any, index) => {
										const specialist = groupingSpecialist[specialistId];

										return (
											<div key={index}>
												<div
													style={{
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'space-between',
													}}
												>
													<Text>{specialist.specialistName}</Text>
													<Button
														style={{ display: 'flex', alignItems: 'center' }}
														onClick={() =>
															handleRemoveItemSpecialization(
																specialist.specialistName,
															)
														}
													>
														<IconDeleteOutline width={20} />
													</Button>
												</div>
												<Divider style={{ marginTop: 10, marginBottom: 10 }} />

												<div
													style={{
														paddingLeft: 25,
													}}
												>
													{specialist?.subSpecialists?.map((sub: any) => (
														<>
															<div
																style={{
																	display: 'flex',
																	justifyContent: 'space-between',
																	alignItems: 'center',
																}}
															>
																<Text>{sub?.subSpecialistName}</Text>
																<Button
																	style={{
																		display: 'flex',
																		alignItems: 'center',
																	}}
																	onClick={() =>
																		handleRemoveItemSpecialization(
																			sub.subSpecialistName,
																		)
																	}
																>
																	<IconDeleteOutline width={20} />
																</Button>
															</div>
															<Divider
																style={{ marginTop: 10, marginBottom: 10 }}
															/>
														</>
													))}
													<Button
														type="ghost"
														style={{ borderColor: '#D81F64', fontSize: 14 }}
														onClick={() =>
															setShowSubSpecializationModal({
																...showSubSpecializationModal,
																[specialist.specialistName]: true,
															})
														}
													>
														<Text style={{ color: '#D81F64' }}>
															Add Sub Specialization
														</Text>
													</Button>
													<Divider
														style={{ marginTop: 10, marginBottom: 10 }}
													/>
												</div>
												<ModalSubSpecialization
													subSpecializaionList={specializationList}
													data={doctor?.specialists ?? []}
													onConfirm={(data: SpecialistProperties[]) =>
														handleConfirmSubSpecialization(data, specialist)
													}
													showModal={
														showSubSpecializationModal[
															specialist.specialistName
														]
													}
													closeModal={() =>
														setShowSubSpecializationModal((prev: any) => ({
															...prev,
															[specialist.specialistName]: false,
														}))
													}
												/>
											</div>
										);
									},
								)}
								<Button
									type="ghost"
									style={{
										width: '100%',
										borderColor: '#D81F64',
										marginTop: doctor?.educations?.length !== 0 ? 20 : 0,
									}}
									onClick={() => setShowSpecializationModal(true)}
								>
									<Text
										style={{
											fontSize: 14,
											fontWeight: 700,
											color: '#D81F64',
											marginLeft: 8,
										}}
									>
										Add Specialization
									</Text>
								</Button>
							</div>
						</>
					)}
				</Form.Item>
				<FormInput
					label="Other Sub Specialization"
					placeholder="Other Sub Specialization"
					value={doctor.otherSpecialist}
					onChange={(e) =>
						setDoctor((prev: any) => ({
							...prev,
							otherSpecialist: e,
						}))
					}
				/>
			</CustomForm>
		);
	};

	const taxInformationForm = () => {
		return (
			<CustomForm layout="vertical">
				<FormInput
					required
					label="PRC Number"
					placeholder="PRC Number"
					value={doctor.prcNumber}
					onChange={(e) =>
						setDoctor((prev: any) => ({
							...prev,
							prcNumber: e,
						}))
					}
					validation="number-only"
				/>
				<FormInput
					required
					label="PTR Number"
					placeholder="PTR Number"
					value={doctor.ptrNumber}
					onChange={(e) =>
						setDoctor((prev: any) => ({
							...prev,
							ptrNumber: e,
						}))
					}
					validation="number-only"
				/>
				<FormInput
					required
					label="Tax Identification Number (TIN)"
					placeholder="Tax Identification Number (TIN)"
					value={doctor.tinNumber}
					onChange={(e) =>
						setDoctor((prev: any) => ({
							...prev,
							tinNumber: e,
						}))
					}
					validation="number-only"
				/>
			</CustomForm>
		);
	};

	const signatureForm = () => {
		return (
			<CustomForm layout="vertical">
				<FormInput
					label="Name of Signature"
					placeholder="Name of Signature"
					disabledInput
					value={`${doctor.user?.firstName ? doctor.user.firstName + ' ' : ''}${
						doctor.user?.middleName ? doctor.user.middleName + ' ' : ''
					}${doctor.user?.lastName ? doctor.user.lastName + ' ' : ''}${
						doctor.title || ''
					}`}
				/>
				<Form.Item label="Signature">
					<Row>
						<Text
							type="secondary"
							style={{ textAlign: 'justify', paddingBottom: 16 }}
						>
							You can choose wether create signature in app or upload signature
							image
						</Text>
					</Row>
					<Row>
						<Radio.Group
							onChange={onRadioChange}
							value={radioValue}
							style={{ paddingBottom: 15 }}
						>
							<Radio value={1}>In App Signature</Radio>
							<Radio value={2}>Upload Image</Radio>
						</Radio.Group>
					</Row>
					<>
						{radioValue === 1 && (
							<>
								<Row>
									<Col style={{ flex: 1 }}>
										<ReactSketchCanvas
											ref={inAppSignatureRef}
											strokeWidth={5}
											height={'220px'}
											strokeColor="black"
											exportWithBackgroundImage={false}
										/>
									</Col>
									<Col>
										<Button
											onClick={() => {
												inAppSignatureRef.current.clearCanvas();
												setExportImage('');
											}}
											style={{
												backgroundColor: '#F1F3F5',
												color: 'black',
												zIndex: 1,
												position: 'absolute',
												top: 12,
												right: 10,
											}}
										>
											Clear
										</Button>
									</Col>
								</Row>
								<Row>
									<Button
										type="ghost"
										style={{ borderColor: '#D81F64', marginTop: 10, flex: 1 }}
										onClick={() => saveInAppSignature()}
									>
										Save Signature
									</Button>
								</Row>
							</>
						)}
						{radioValue === 2 && (
							<>
								{(!fileSignature ||
									(fileSignature && fileSignature.length === 0)) && (
									<Result
										icon={<IconUserOutlined />}
										title="Signature Empty"
										subTitle="If you have entered your signature, your signature will appear here."
									/>
								)}
								<CustomUpload
									listType="picture-card"
									fileList={fileSignature}
									maxCount={1}
									onPreview={handleSignaturePreview}
									onChange={handleSignatureChange}
									name="file"
									accept=".jpg,.jpeg,.png"
									beforeUpload={(file) => {
										const isLtMaxSize = file.size / 1024 / 1024 < 1;
										if (!isLtMaxSize) {
											message.error(`Image must smaller than ${1}MB!`);
										}
										return false;
									}}
								>
									{uploadSignatureButton}
								</CustomUpload>
								<Modal
									open={previewSignatureOpen}
									title={previewSignatureTitle}
									footer={null}
									onCancel={handleSignatureCancel}
								>
									<img
										alt="example"
										style={{ flex: 1 }}
										src={previewSignatureImage}
									/>
								</Modal>
							</>
						)}
					</>
				</Form.Item>
			</CustomForm>
		);
	};

	return (
		<AppLayout activePath={activePath}>
			<Card style={{ paddingBottom: 110 }}>
				<PageHeader title="Edit Profile" onClick={() => history.goBack()} />
				<Divider />

				{isLoading && <CustomSpin spinning={isLoading} />}
				{!isLoading && doctor && (
					<div style={{ marginBottom: 80 }}>
						{ProfileSection('Identify', profileForm)}
						<Divider />
						{ProfileSection('Specialization', specializationForm)}
						<Divider />
						{ProfileSection('Tax Information', taxInformationForm)}
						<Divider />
						{ProfileSection('Signature', signatureForm)}
						<Divider />
						<Button
							loading={loadingSubmit}
							type="primary"
							size="large"
							style={{
								borderRadius: 15,
								fontSize: 14,
								position: 'absolute',
								right: 25,
							}}
							disabled={
								!doctor.user?.firstName ||
								!doctor.user?.nickName ||
								!doctor.title ||
								!doctor.experience ||
								!doctor.user?.birthdate ||
								!doctor.user?.gender ||
								!doctor.prcNumber ||
								!doctor.ptrNumber ||
								!doctor.tinNumber ||
								(!!doctor.user.firstName &&
									!validateName(doctor.user.firstName)) ||
								(!!doctor.user.middleName &&
									!validateName(doctor.user.middleName)) ||
								(!!doctor.user.lastName &&
									!validateName(doctor.user.lastName)) ||
								(!!doctor.user.birthdate &&
									!validateBirthdate(doctor.user.birthdate)) ||
								doctor.experience <= 0 ||
								(doctor?.specialists?.length ?? 0) <= 0 ||
								(!!doctor.prcNumber && !validateAge(doctor.prcNumber)) ||
								(!!doctor.ptrNumber && !validateAge(doctor.ptrNumber)) ||
								(!!doctor.tinNumber && !validateAge(doctor.tinNumber))
							}
							onClick={onSubmit}
						>
							Save Changes
						</Button>
					</div>
				)}
			</Card>
			<ModalSpecialization
				specializaionList={specializationList}
				data={doctor?.specialists ?? []}
				onConfirm={(data: SpecialistProperties[]) =>
					handleConfirmSpecialization(data)
				}
				showModal={showSpecializationModal}
				setShowModal={setShowSpecializationModal}
			/>
		</AppLayout>
	);
}

// Custom Components

const CustomSpin = styled(Spin)`
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0);
`;

const CustomForm = styled(Form)`
	.ant-form-item-label {
		font-size: 16px;
	}
	.ant-input {
		border-radius: 15px;
		padding: 10px 15px;
	}
	.ant-input-number {
		border-radius: 15px;
		padding: 10px 15px;
	}
`;

const CustomUpload = styled(Upload)`
	.ant-upload {
		width: 100%;
		border: none;
		background-color: white;
	}

	.ant-upload-list-picture-card-container {
		height: 222px;
		width: 100%;
	}

	.ant-upload-list-picture-card .ant-upload-list-item {
		height: 222px;
		width: 100%;
	}
`;
