import { Button, Col, Tag, Typography } from 'antd';
import React, { useMemo } from 'react';
import { iconBankAccount as IconBankAccount } from '../../assets/icons';
import {
	UserBankAccountProperties,
	EBankStatus,
} from '../../types/bankAccount.type';

const { Text } = Typography;

interface IProps {
	data: UserBankAccountProperties;
	onPress: () => void;
	buttonText: string;
}

const BankAccountListItem = (props: IProps) => {
	const status = useMemo(() => {
		let bgColor: string;
		let color: string;
		let text: string;
		switch (props.data.status) {
			case EBankStatus.WAITING_FOR_APPROVAL:
				bgColor = '#F9FAFA';
				color = '#93A1B0';
				text = 'Unverified';
				break;
			case EBankStatus.REJECTED:
				bgColor = '#FCF1E3';
				color = '#DA7B11';
				text = 'Rejected';
				break;
			case EBankStatus.APPROVED:
				bgColor = '#D7F4EB';
				color = '#268E6C';
				text = 'Verified';
				break;
		}

		return (
			<Tag
				style={{
					color: color,
					backgroundColor: bgColor,
					border: `1px solid ${color}`,
					marginLeft: 8,
					borderRadius: 12,
				}}
			>
				{text}
			</Tag>
		);
	}, [props.data.status]);

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				border: '1px solid #DCDCDC',
				padding: '12px 14px',
				borderRadius: 12,
				marginBottom: 12,
			}}
		>
			<Col style={{ display: 'flex', flex: 1 }}>
				<IconBankAccount
					width={18}
					height={14}
					style={{ marginRight: 12, marginTop: 4 }}
				/>
				<div
					style={{
						display: 'flex',
						flex: 1,
						flexDirection: 'column',
						marginRight: 20,
					}}
				>
					<Text style={{ fontSize: 16, fontWeight: 500 }}>
						{props.data?.bankName}
						{status}
					</Text>
					<Text style={{ fontSize: 16, fontWeight: 400, color: '#777777' }}>
						{props.data?.bankAccountNumber}
					</Text>
					<Text style={{ fontSize: 16, fontWeight: 400, color: '#777777' }}>
						{props.data?.bankAccountOwner}
					</Text>
					{props.data?.rejectReason && (
						<Text
							style={{
								width: '100%',
								backgroundColor: '#FEF5F9',
								color: '#D7373F',
								fontSize: 14,
								border: '1px solid #D7373F',
								borderRadius: 8,
								padding: '0px 12px',
								marginTop: 8,
							}}
						>
							{props.data?.rejectReason}
						</Text>
					)}
				</div>
			</Col>
			<Col>
				<Button
					onClick={() => props.onPress?.()}
					size="large"
					style={{
						borderRadius: 12,
						fontSize: 14,
						fontWeight: 700,
						width: 84,
						height: 32,
					}}
					type="ghost"
				>
					{props?.buttonText}
				</Button>
			</Col>
		</div>
	);
};

export default BankAccountListItem;
