import { SearchOutlined } from '@ant-design/icons';
import {
	Button,
	Carousel,
	Col,
	Input,
	Pagination,
	Row,
	Spin,
	Typography,
} from 'antd';
import styled from 'styled-components';
import COLORS from '../../../assets/globalColors';
import useFetchList from '../../../hooks/useFetchList';
import AppLayout from '../../../layout/AppLayout';
import { ArticleProps } from '../../../types/article.type';
import DEFAULT_IMAGE from '../../../assets/default-image.jpeg';
import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { useHistory } from 'react-router-dom';
import ArticleItem from '../../../components/Webapp/Articles/ArticleItem';
import SidebarArticle from '../../../components/Webapp/Articles/SidebarArticle';
import { BannerProps } from '../../../types/banner.type';
import ScrollUp from '../../../components/ScrollUp';
import { useAuth } from '../../../context/auth.context';
import moment from 'moment';

export function openInNewTab(href: string) {
	Object.assign(document.createElement('a'), {
		target: '_blank',
		rel: 'noopener noreferrer',
		href: href,
	}).click();
}

export default function Articles() {
	const history = useHistory();
	const { isPatient, isLoggedIn, isDoctor } = useAuth();
	// const [isLoadingPromo, setIsLoadingPromo] = useState(false);
	const [searchTopic, setSearchTopic] = useState<string>('');
	const [searchFormValue, setSearchFormValue] = useState<string>('');
	const [searchKeyTopic, _] = useDebounce(searchTopic, 500);
	const [searchKey, __] = useDebounce(searchFormValue, 500);
	// const [promoDealData, setPromoDealData] = useState<
	//   Partial<VoucherProps[]> | undefined
	// >([]);
	const {
		data: articles,
		isLoading,
		pagination,
		setQuery,
		changePage,
		fetchList: fetchArticleList,
	} = useFetchList<ArticleProps>({
		endpoint: '/articles',
		limit: 10,
		initialQuery: {
			isPublished: 'true',
			targetReaders: isPatient ? 'PATIENT' : 'DOCTOR',
			sort: 'publishedAt:DESC',
		},
	});

	const {
		data: popularArticles,
		fetchList: fetchPopularArticleList,
	} = useFetchList<ArticleProps>({
		endpoint: '/articles',
		initialQuery: {
			isPublished: 'true',
			targetReaders: isPatient ? 'PATIENT' : 'DOCTOR',
			sort: 'publishedAt:DESC',
			isFromRSS: 'false'
		},
	});

	const filteredPopularArticles = () => {
		const isMoreThanTwoDaysAgo = (publishedAt: any) => {
			const twoDaysAgo = moment().subtract(2, 'days');
			const articleDate = moment(publishedAt);
		  
			return articleDate.isBefore(twoDaysAgo);
		};

		const filteredArticles = popularArticles.filter(
			(article) => !isMoreThanTwoDaysAgo(article.publishedAt)
		);

		const fromRSSArticles = articles.filter(
			(article) => article.isFromRSS === true
		);

		return filteredArticles.concat(fromRSSArticles);
	}

	const { data: banners, isLoading: isLoadingBanner } =
		useFetchList<BannerProps>({
			endpoint: '/banners',
			limit: 3,
			initialQuery: {
				isPublished: true,
				target: isDoctor ? 'DOCTOR' : 'PATIENT',
			},
		});

	useEffect(() => {
		console.log(searchKeyTopic.replace('#', ''));

		setQuery((oldQuery) => ({
			...oldQuery,
			search: searchKey === '' ? null : searchKey,
			filterTopicNames:
				searchKeyTopic === '' ? null : searchKeyTopic.replace('#', ''),
		}));
		// setIsLoading2(true);
	}, [searchKey, searchKeyTopic]);

	useEffect(() => {
		setSearchFormValue('');
		setSearchTopic('');
	}, []);

	useEffect(() => {
		fetchPopularArticleList();
		fetchArticleList();
	}, []);

	const handleValue = searchFormValue === '' ? searchTopic : searchFormValue;
	return (
		<AppLayout activePath={isLoggedIn ? '/app/articles' : '/articles'}>
			<div className={isLoggedIn ? 'wrapper-login' : 'container mt-12'}>
				{!isLoggedIn && (
					<Row gutter={12} className="w-full">
						<Col className="flex-1">
							<Input
								prefix={<SearchOutlined />}
								style={{ height: 42, color: '#93A1B0' }}
								placeholder="Search articles, topic or category"
								onChange={(e) => {
									if (e.target.value.includes('#')) {
										setSearchTopic(e.target.value);
										setSearchFormValue('');
									} else {
										setSearchFormValue(e.target.value);
										setSearchTopic('');
									}
								}}
								value={handleValue}
							/>
						</Col>
						<Col>
							<Button style={{ width: 120, height: 42 }} type="primary">
								search
							</Button>
						</Col>
					</Row>
				)}

				{!isLoading && articles.length === 0 && (
					<Typography.Title
						level={4}
						style={{ color: COLORS.gray_100 }}
						className="font-semibold my-10"
					>
						No Article
					</Typography.Title>
				)}

				<Row className={isLoggedIn ? '' : 'mt-10'}>
					<Typography.Title
						level={4}
						style={{ color: COLORS.gray_100 }}
						className="font-semibold"
					>
						Popular Article
					</Typography.Title>
				</Row>
				<Row gutter={48}>
					<Col span={24} lg={isLoggedIn ? 24 : 16}>
						{isLoading ? (
							<div className="text-center my-10">
								<Spin spinning />
							</div>
						) : (
							<CustomCarousel autoplay dotPosition="bottom">
								{filteredPopularArticles()
									.filter((_, idx) => idx < 6)
									.map((article) => (
										<ArticleBannerItem
											className="relative cursor-pointer"
											onClick={() =>
												history.push(`/articles/${article.articleId}`)
											}
										>
											<img
												src={
													article.images && article.images.length > 0
														? article.images[0]
														: DEFAULT_IMAGE
												}
												alt={article.title}
												style={{ height: isLoggedIn ? 400 : 500 }}
											/>
											<div
												className="absolute z-10"
												style={{ bottom: 60, left: 24 }}
											>
												<Typography.Text className="text-white text-6 font-bold block">
													{article.title}
												</Typography.Text>
												{article.topics.length > 0 && (
													<Typography.Text className="text-white text-3.5">
														{article.topics.map(
															(topic) => `#${topic.topicName}`,
														)}
													</Typography.Text>
												)}
											</div>
										</ArticleBannerItem>
									))}
							</CustomCarousel>
						)}

						<div className="mt-12">
							<Typography.Title
								level={4}
								style={{ color: COLORS.gray_100, marginBottom: 24 }}
								className="font-semibold block"
							>
								New Article
							</Typography.Title>
							{isLoading ? (
								<div className="text-center my-10">
									<Spin spinning />
								</div>
							) : (
								<Row gutter={[24, 43]}>
									{articles.map((article) => (
										<Col span={24} key={article.articleId}>
											<ArticleItem article={article} />
										</Col>
									))}
								</Row>
							)}

							<Row
								style={{ margin: isLoggedIn ? '20px 0px' : '48px 0px 80px' }}
								className="justify-center"
							>
								<CustomPagination
									current={pagination.page}
									pageSize={pagination.perPage}
									onChange={changePage}
									total={pagination.totalData}
								/>
							</Row>
						</div>
					</Col>
					{!isLoggedIn && (
						<Col span={24} lg={8}>
							<div className="top-27 sticky">
								<SidebarArticle banners={banners} isLoading={isLoadingBanner} />
							</div>
						</Col>
					)}
				</Row>
			</div>
			<ScrollUp />
		</AppLayout>
	);
}

const CustomPagination = styled(Pagination)`
	.ant-pagination-item,
	.ant-pagination-next,
	.ant-pagination-prev {
		width: 48px !important;
		height: 48px !important;
		line-height: 48px;
	}

	.ant-pagination-item {
		border-color: ${COLORS.primary};
		a {
			color: ${COLORS.primary};
		}
	}

	.ant-pagination-item-ellipsis {
		color: ${COLORS.primary} !important;
	}
	.ant-pagination-options {
		display: none;
	}

	.ant-pagination-item-active {
		background-color: ${COLORS.primary};
		a {
			color: #fff;
		}
	}
`;
const CustomCarousel = styled(Carousel)`
	img {
		display: block;
		/* height: 500px; */
		object-fit: cover;
		border-radius: 10px;
		width: 100%;
	}
	/* .slick-dots {
    gap: 2px;
  } */
	ul.slick-dots.slick-dots-bottom {
		margin-left: 0;
		margin-right: 0;
		bottom: 26px;
	}
	.slick-dots li button {
		width: 14px;
		height: 14px;
		border: 2px solid #ffffff !important;
		background-color: transparent;
		border-radius: 50%;
		opacity: 1;
		&::before {
			content: '';
		}
	}
	.slick-dots li.slick-active button {
		width: 14px;
		height: 14px;
		border: 2px solid #ffffff;
		background-color: #fff;
		border-radius: 50%;
		&::before {
			content: '';
		}
	}
`;
const ArticleBannerItem = styled.div`
	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-image: linear-gradient(
			to bottom,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 1) 150%
		);
	}
`;
