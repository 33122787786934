import { useEffect, useState } from 'react';
import { Search } from 'react-feather';
import { theme } from '../../../../assets/theme';
import useFetchList from '../../../../hooks/useFetchList';
import FormInput from '../../../Form/FormInput2';
import CustomScrollDiv from '../../../CustomScrollDiv';
import { Collapse, Spin } from 'antd';
import { ICDProperties } from '../../../../types/icd.type';
import { CaretRightOutlined } from '@ant-design/icons';
import { useDebounce } from 'use-debounce';
import EmptyList from '../../../EmptyList';

const { Panel } = Collapse;

const ListICD = ({ parentId }: { parentId?: string | null }) => {
	const [search, setSearch] = useState('');
	const [debouncedSearch] = useDebounce(search, 500);

	const {
		data: icds,
		isLoading,
		setSearch: setFetchSearch,
	} = useFetchList<ICDProperties>({
		endpoint: 'icd',
		initialQuery: {
			limit: 100,
			parentId: parentId || null,
			search: debouncedSearch,
		},
	});

	useEffect(() => {
		if (debouncedSearch && debouncedSearch.length > 2) {
			setFetchSearch(debouncedSearch);
		} else {
			setFetchSearch('');
		}
	}, [debouncedSearch, setFetchSearch]);

	return (
		<div className="flex-1 flex flex-col">
			{!parentId && (
				<div className="p-3">
					<FormInput
						prefix={
							<Search width={20} height={20} color={theme.colors.charcoal200} />
						}
						placeholder={'Search code or title disease'}
						value={search}
						onChange={setSearch}
					/>
				</div>
			)}
			<div className="flex-1 min-h-0">
				<CustomScrollDiv className="h-full min-h-0">
					<Collapse accordion ghost expandIcon={customExpandIcon}>
						{isLoading ? (
							<Spin style={{ display: 'flex', justifyContent: 'center' }} />
						) : icds.length === 0 ? (
							<EmptyList
								icon={
									<Search
										width={50}
										height={50}
										color={theme.colors.charcoal200}
									/>
								}
								title="No Results Found"
								description="Try a different search term."
							/>
						) : (
							icds.map((icd) => (
								<Panel header={`${icd.id}. ${icd.value}`} key={icd.id}>
									<ListICD parentId={icd.id} />
								</Panel>
							))
						)}
					</Collapse>
				</CustomScrollDiv>
			</div>
		</div>
	);
};

const customExpandIcon = (panelProps: any) => {
	const { isActive } = panelProps;
	return (
		<CaretRightOutlined
			style={{
				transform: isActive ? 'rotate(90deg)' : 'rotate(0deg)',
				transition: 'transform 0.2s ease',
			}}
		/>
	);
};

export default ListICD;
