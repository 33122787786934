import { useEffect, useState } from 'react';
import { ArrowLeft } from 'react-feather';
import { useHistory, useParams } from 'react-router-dom';
import { apiUpdateLabRequest } from '../../../../api/labRequest';
import { apiCreateTemplateLabRequest } from '../../../../api/templateLabRequest';
import { useAuth } from '../../../../context/auth.context';
import { LabRequestProperties } from '../../../../types/labRequest.type';
import Separator from '../../../Separator';
import Steps from '../../../Steps';
import PreviewDocument from '../../Components/PreviewDocument';
import FormCreateLabRequest from './FormCreateLabRequest';
import { httpRequest } from '../../../../helpers/api';
import { ApiResponseDefault } from '../../../../types/apiResponse.type';
import { getName } from '../../../../helpers/name';
import { calculateAge } from '../../../../helpers/datetime';
import ModalInfoPatient from '../CreateRx/Components/Modal/ModalInfoPatient';
import { ICDProperties } from '../../../../types/icd.type';

const steps = ['Fill Form', 'Preview'];

export default function MainCreateLabRequest() {
	const history = useHistory();
	const { user } = useAuth();

	const [title, setTitle] = useState('Create Lab Request');
	const [step, setStep] = useState(0);
	const [isConfirmed, setIsConfirmed] = useState(false);
	const [isEditing, setIsEditing] = useState(false);

	const [labReq, setLabReq] = useState<LabRequestProperties>();
	const [templateName, setTemplateName] = useState<string>();
	const [icds, setIcds] = useState<ICDProperties[]>();

	const [searchPhone, setSearchPhone] = useState<any>();
	const [isPhoneDisable, setIsPhoneDisable] = useState(false);
	const [patientProfile, setPatientProfile] = useState<{
		[key: string]: any;
	}>();
	const [isModalInfoPatientVisible, setModalInfoPatientVisible] =
		useState(false);
	const [foundPatient, setFoundPatient] = useState<any>();
	const [foundRelatives, setFoundRelatives] = useState<any[]>([]);

	const getPathId = () => {
		const path = window.location.pathname;
		const pathSegments = path.split('/');
		const id = pathSegments[pathSegments.length - 1];
		return id;
	};

	const templateId = getPathId();

	async function apiFindUserByPhone(phone: string) {
		const res = await httpRequest.post<ApiResponseDefault<any>>(
			'/user/find-by-phone',
			{ phone },
		);

		if (res && res.data && res.data.payload && !res.data.payload.doctor) {
			return res.data.payload;
		} else {
			return Promise.reject({
				code: '',
			});
		}
	}

	useEffect(() => {
		setSearchPhone(patientProfile?.phone);
	}, [patientProfile?.phone]);

	useEffect(() => {
		if (!searchPhone || (searchPhone && searchPhone?.length < 9) || isEditing) {
			return;
		}

		const timeout = setTimeout(() => {
			apiFindUserByPhone('+63' + searchPhone)
				.then((res) => {
					const foundData = {
						patientId: res.userId,
						patientName: getName(res),
						age: calculateAge(res.birthdate),
						gender: res.gender,
					};

					if (res.patient?.relatives && res.patient.relatives.length > 0) {
						const relativesData: any[] = [];
						res.patient.relatives.map((item: any) =>
							relativesData.push({
								patientId: res.userId,
								patientName: getName(item),
								age: calculateAge(item.birthdate),
								gender: item.gender,
							}),
						);
						setFoundRelatives(relativesData);
						setFoundPatient(foundData);

						setPatientProfile({
							...patientProfile,
							patientId: res.userId,
						});

						setModalInfoPatientVisible(true);
					} else {
						setPatientProfile({
							...patientProfile,
							...foundData,
							patientId: res.userId,
						});

						setIsPhoneDisable(true);
					}
				})
				.catch((err) => {
					console.info('error', err);
					setPatientProfile({
						...patientProfile,
						patientId: undefined,
					});
				});
		}, 1000);

		return () => {
			clearTimeout(timeout);
		};
	}, [searchPhone]);

	useEffect(() => {
		if (!isEditing) return;

		let _title = title;
		if (_title.includes('Create')) {
			_title = _title.replace('Create', 'Edit');
		}
		setTitle(_title);
	}, [isEditing]);

	function setNextStep() {
		const _step = step + 1;
		if (_step === 1) {
			setTitle('Preview Lab Request');
			setStep(_step);
		}
	}

	function onBack() {
		const _step = step - 1;
		if (_step >= 0) {
			if (_step === 0) {
				setTitle(`${isEditing ? 'Edit' : 'Create'} Lab Request`);
			}
			setStep(_step);
		} else {
			history.goBack();
		}
	}

	async function confirmLabReq() {
		if (!labReq?.labRequestId) return;

		let isSuccess = false;

		const res = await apiUpdateLabRequest(labReq.labRequestId, {
			isTemporary: false,
		});
		if (res) {
			isSuccess = true;
		}

		if (templateName && labReq.labRequestId && labReq.procedures) {
			isSuccess = false;
			const res = await apiCreateTemplateLabRequest({
				doctorId: user?.userId,
				templateName,
				saveFromId: labReq.labRequestId,
				templateProcedures: labReq.procedures,
				specialInstructions: labReq.specialInstructions,
				assessmentDiagnosis: labReq.assessmentDiagnosis,
			});
			if (res) {
				isSuccess = true;
			}
		}

		if (isSuccess) {
			setTitle('Result');
			setIsConfirmed(true);
		}
	}

	console.log(labReq);

	return (
		<div>
			<div className="relative px-6 py-5.5 flex items-center">
				{!isConfirmed && (
					<button
						onClick={onBack}
						className="border-none bg-transparent p-0 absolute flex items-center gap-x-1 cursor-pointer"
					>
						<ArrowLeft width={20} height={20} />
						<p className="m-0 font-bold text-3.5">Back</p>
					</button>
				)}
				<h1 className="font-semibold text-6 m-0 w-full text-center">{title}</h1>
			</div>
			{!isConfirmed && (
				<>
					<Separator style={{ height: 2 }} />
					<div className="px-13 py-3.5">
						<Steps steps={steps} activeStepIndex={step} />
					</div>
				</>
			)}
			<Separator />
			<>
				{step === 0 ? (
					<FormCreateLabRequest
						labRequest={labReq}
						setLabRequest={setLabReq}
						step={step}
						setNextStep={setNextStep}
						setIsEditing={() => setIsEditing(true)}
						searchPhone={searchPhone}
						setSearchPhone={setSearchPhone}
						patientProfile={patientProfile}
						setPatientProfile={setPatientProfile}
						isPhoneDisable={isPhoneDisable}
						setIsPhoneDisable={setIsPhoneDisable}
						icds={icds}
						setIcds={setIcds}
					/>
				) : step === 1 ? (
					<PreviewDocument
						docId={labReq?.labRequestId!}
						type="lab-request"
						btnSuffix={!isConfirmed ? 'Lab Request' : 'Rx and Lab Request'}
						buttonType={!isConfirmed ? 'confirm' : 'back'}
						onClickBtn={!isConfirmed ? confirmLabReq : history.goBack}
						isShowSaveAsTemplate={!isConfirmed}
						templateName={templateName}
						setTemplateName={setTemplateName}
					/>
				) : undefined}
			</>
			<ModalInfoPatient
				isVisible={isModalInfoPatientVisible}
				setVisibility={setModalInfoPatientVisible}
				dataPatient={foundPatient}
				dataRelatives={foundRelatives}
				actionHandler={(data) => {
					setPatientProfile({ ...patientProfile, ...data });
					setIsPhoneDisable(true);
					setModalInfoPatientVisible(false);
				}}
			/>
		</div>
	);
}
