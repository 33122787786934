import { httpRequest } from '../helpers/api';
import { generateQueryString } from '../helpers/generateQueryString';
import { PartnerDoctorProperties } from '../types/partnerDoctor.type';
import { ApiPaginationResults, ApiResponseSuccess } from '../types/api.type';

export async function apiGetPartnerDoctors(
	doctorId?: string,
	partnerId?: string,
): Promise<ApiPaginationResults<PartnerDoctorProperties>> {
	const res: ApiResponseSuccess<ApiPaginationResults<PartnerDoctorProperties>> =
		await httpRequest.get(
			'/partner-doctors' + generateQueryString({ doctorId, partnerId }),
		);

	if (res && res.data && res.data.payload) {
		return res.data.payload;
	} else {
		return { results: [] };
	}
}

export async function apiBulkCreatePartnerDoctor(
	data: PartnerDoctorProperties[],
): Promise<ApiPaginationResults<PartnerDoctorProperties>> {
	const res: ApiResponseSuccess<ApiPaginationResults<PartnerDoctorProperties>> =
		await httpRequest.post('/partner-doctors/bulk', { bulk: data });

	if (res && res.data && res.data.payload) {
		return res.data.payload;
	} else {
		return { results: [] };
	}
}

export async function apiCreateUpdatePartnerDoctor(
	data: PartnerDoctorProperties,
): Promise<PartnerDoctorProperties | null> {
	const res: ApiResponseSuccess<PartnerDoctorProperties> =
		await httpRequest.put('/partner-doctors', data);

	if (res && res.data && res.data.payload) {
		return res.data.payload;
	} else {
		return null;
	}
}

export async function apiDeletePartnerDoctor(
	partnerId: string,
	doctorId: string,
): Promise<boolean> {
	const res: ApiResponseSuccess<boolean> = await httpRequest.delete(
		'/partner-doctors/' + partnerId + '/' + doctorId,
	);

	if (res && res.data) {
		return true;
	} else {
		return false;
	}
}
