import { httpRequest } from '../helpers/api';
import { ApiPaginationResults, ApiResponseSuccess } from '../types/api.type';
import { TransactionHealthServiceProps } from '../types/transactionHealthService.type';

export async function apiGetTransactionHealthService(
	id: string,
): Promise<TransactionHealthServiceProps> {
	const res: ApiResponseSuccess<TransactionHealthServiceProps> =
		await httpRequest.get('transaction-health-services/' + id);

	if (res?.data?.payload) {
		return res?.data?.payload;
	} else {
		return Promise.reject({
			code: '',
		});
	}
}

export async function apiGetListTransactionHealthService(
	queryString?: string,
): Promise<ApiPaginationResults<TransactionHealthServiceProps>> {
	try {
		const res: ApiResponseSuccess<
			ApiPaginationResults<TransactionHealthServiceProps>
		> = await httpRequest.get('transaction-health-services' + queryString);

		if (res?.data?.payload) {
			return res?.data?.payload;
		} else {
			return { results: [] };
		}
	} catch (err) {
		return Promise.reject(err);
	}
}
