import { Card, Col, Row, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';
import COLORS from '../../../assets/globalColors';
import { iconPreResult as IconPreResult } from '../../../assets/icons';

export default function PreResult() {
	return (
		<CardResult>
			<IconPreResult />
			<Row>
				<Typography.Text
					style={{ fontWeight: 700, fontSize: 20, textAlign: 'center' }}
				>
					The results will appear here
				</Typography.Text>
			</Row>
			<Row>
				<Typography.Text style={{ fontSize: 16, textAlign: 'center' }}>
					Fill in all the risk factors to get the results
				</Typography.Text>
			</Row>
		</CardResult>
	);
}

const CardResult = styled(Card)`
	border-radius: 20px;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	display: flex;
	flex-direction: column;
	align-items: center;

	div.ant-card-body {
		padding: 20px 0px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
`;
