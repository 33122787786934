import moment from 'moment';
import { useEffect, useState } from 'react';
import { ArrowLeft, ChevronRight, PlusCircle, Search } from 'react-feather';
import { theme } from '../../../../assets/theme';
import { getName } from '../../../../helpers/name';
import { useAuth } from '../../../../context/auth.context';
import useFetchList from '../../../../hooks/useFetchList';
import {
	DoctorDocumentProperties,
	EMetaType,
} from '../../../../types/doctorDocument.type';
import { TemplateDoctorDocumentProperties } from '../../../../types/doctorDocumentTemplate.type';
import { Button } from '../../../Button/Button/Button';
import FormInput from '../../../Form/FormInput2';
import Separator from '../../../Separator';
import Spinner from '../../../Spinner';
import { ReactComponent as Clinical } from '../../../../assets/icons/clinical.svg';
import { useHistory } from 'react-router-dom';

interface Props {
	onClickAddTemplate: () => void;
	onBack: () => void;
	setState: React.Dispatch<
		React.SetStateAction<'main' | 'createTemplate' | 'createOwnDocument'>
	>;
}

export default function ListOwnTemplate(props: Props) {
	const { user } = useAuth();
	const history = useHistory();
	const [search, setSearch] = useState<string>();

	const {
		data: templateList,
		isLoading: isLoadingTemplate,
		pagination: paginationTemplate,
		changePage: changePageTemplate,
		setSearch: setSearchTemplate,
	} = useFetchList<TemplateDoctorDocumentProperties>({
		endpoint: '/template-doctor-documents',
		initialQuery: {
			doctorId: user?.userId,
		},
	});

	function handleNextPageTemplate() {
		if (!isLoadingTemplate && paginationTemplate.next) {
			changePageTemplate(paginationTemplate.page + 1);
		}
	}

	const {
		data: docList,
		isLoading: isLoadingDocList,
		pagination: paginationDocList,
		changePage: changePageDocList,
		setSearch: setSearchDoc,
	} = useFetchList<DoctorDocumentProperties>({
		endpoint: '/doctor-documents',
		initialQuery: {
			doctorId: user?.userId,
			isTemporary: 'false',
			filterTypes: [EMetaType.OTHERS],
		},
	});

	function handleNextPageDocList() {
		if (!isLoadingDocList && paginationDocList.next) {
			changePageDocList(paginationDocList.page + 1);
		}
	}

	useEffect(() => {
		setSearchTemplate(search);
		setSearchDoc(search);
	}, [search]);

	const isLoading = isLoadingDocList || isLoadingTemplate;

	return (
		<div>
			<div className="relative px-6 py-5.5 flex items-center">
				<button
					onClick={props.onBack}
					className="border-none bg-transparent p-0 absolute flex items-center gap-x-1 cursor-pointer"
				>
					<ArrowLeft width={20} height={20} />
					<p className="m-0 font-bold text-3.5">Back</p>
				</button>
				<h1 className="font-semibold text-6 m-0 w-full text-center">
					Own Template
				</h1>
				<div className="absolute right-6">
					<Button
						type="SOLID"
						onClick={() => props.setState('createOwnDocument')}
					>
						Create
					</Button>
				</div>
			</div>
			<Separator />
			<div className="p-3">
				<FormInput
					prefix={
						<div className="flex">
							<Search width={20} height={20} color={theme.colors.charcoal200} />
						</div>
					}
					placeholder="Search template name"
					value={search}
					onChange={setSearch}
				/>
			</div>
			{!isLoading ? (
				<div>
					<div className="flex gap-x-2 items-center bg-ash-300 px-4 py-3">
						<div className="flex">
							<Clinical color={theme.colors.primary} />
						</div>
						<div>
							<p className="m-0 font-medium">My Template</p>
							<p className="m-0 text-charcoal-300 text-3.5">
								Use pre made template for your Documents
							</p>
						</div>
					</div>
					{templateList.length > 0 && (
						<>
							<div className="px-3">
								{templateList.map((template, index) => (
									<Item
										key={template.templateDoctorDocumentId}
										title={template.templateName}
										createdAt={template.createdAt}
										isUseBottomSeparator={
											index < templateList.length - 1 || docList.length === 0
										}
									/>
								))}
								<div className="p-3">
									<Button
										type="OUTLINE"
										onClick={props.onClickAddTemplate}
										className="flex items-center gap-x-1 max-w-sm mx-auto"
									>
										<PlusCircle
											width={20}
											height={20}
											color={theme.colors.primary}
										/>
										Add Template
									</Button>
								</div>
							</div>
							{docList.length > 0 && <Separator style={{ height: 2 }} />}
						</>
					)}
					{docList.length > 0 && (
						<div className="px-3">
							{docList.map((doc, index) => (
								<Item
									key={doc.doctorDocumentId}
									title={getName(doc.metaPatient)}
									createdAt={doc.createdAt}
									isUseBottomSeparator={index < docList.length - 1}
									onClick={() =>
										history.push(
											`/app/doctor-documents/history/${doc.doctorDocumentId}`,
										)
									}
								/>
							))}
							{!!paginationDocList.next && (
								<div className="p-3">
									<Button
										type="OUTLINE"
										onClick={handleNextPageDocList}
										className="flex items-center gap-x-1 max-w-sm mx-auto"
									>
										Load More
									</Button>
								</div>
							)}
						</div>
					)}
				</div>
			) : (
				<div className="py-4 mx-auto w-fit">
					<Spinner size={25} color={theme.colors.primary} />
				</div>
			)}
		</div>
	);
}

function Item(props: {
	title?: string;
	createdAt?: Date | string;
	isUseBottomSeparator?: boolean;
	onClick?: any;
}) {
	return (
		<>
			<div className="flex gap-x-2 py-4 cursor-pointer" onClick={props.onClick}>
				<div className="flex-1">
					<p className="m-0">{props.title}</p>
					<div className="mt-4">
						<p className="m-0 text-3.5 text-black-60">Created at</p>
						<p className="m-0 mt-2 text-4">
							{moment(props.createdAt).format('MMMM DD, YYYY')}
						</p>
					</div>
				</div>
				<div>
					<ChevronRight width={20} height={20} color={theme.colors.black30} />
				</div>
			</div>
			{props.isUseBottomSeparator && <Separator />}
		</>
	);
}
