import clsx from 'clsx';
import './styles.less';

export default function ToggleBtn({
	value,
	clickHandler,
	disabled,
	classNames,
}: any) {
	return (
		<label className={clsx('toggle-btn', classNames)}>
			<input
				type="checkbox"
				checked={value}
				onClick={disabled ? null : clickHandler}
			/>
			<span
				className={clsx('slider', {
					disabled: disabled,
				})}
			/>
		</label>
	);
}
