import { isNumber } from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { Calendar, CheckCircle, Circle } from 'react-feather';
import {
	apiCreateDoctorDocument,
	apiDeleteDoctorDocument,
	apiUpdateDoctorDocument,
} from '../../../../api/doctorDocument';
import { theme } from '../../../../assets/theme';
import { getName } from '../../../../helpers/name';
import { getBirthdate, getGender } from '../../../../helpers/profile';
import {
	DoctorDocumentProperties,
	EMetaType,
	MetaClearence,
} from '../../../../types/doctorDocument.type';
import { IUser } from '../../../../types/user.type';
import IconButton from '../../../Button/IconButton';
import CustomScrollDiv from '../../../CustomScrollDiv';
import FormInput, { EFormType } from '../../../Form/FormInput2';
import ButtonRemoveSave from './Components/ButtonRemoveSave';
import SidebarMenuHeader from './Components/SidebarMenuHeader';
import { useAuth } from '../../../../context/auth.context';

interface Props {
	patientProfile?: IUser;
	transactionConsultationId?: string;
	clearance?: DoctorDocumentProperties;
	setClearance: React.Dispatch<
		React.SetStateAction<DoctorDocumentProperties | undefined>
	>;
	onBack: Function;
}

export default function SidebarClearance(props: Props) {
	const { user } = useAuth();

	const [isLoading, setIsLoading] = useState(false);

	const [clearance, setClearance] = useState(props.clearance);
	const [patientProfile, setPatientProfile] = useState({
		patientName: getName(props.patientProfile),
		age: props.patientProfile
			? moment().diff(getBirthdate(props.patientProfile), 'years', false)
			: undefined,
		gender: getGender(props.patientProfile),
	});

	async function removeClearance() {
		if (!props.clearance?.doctorDocumentId) {
			props.onBack();
			return;
		}

		setIsLoading(true);
		const res = await apiDeleteDoctorDocument(props.clearance.doctorDocumentId);
		if (res?.isSuccess) {
			props.setClearance(undefined);
			props.onBack();
		} else {
			setIsLoading(false);
		}
	}

	async function saveClearance() {
		setIsLoading(true);

		const patientName = patientProfile.patientName
			? patientProfile.patientName.split(' ')
			: [];
		const birthdate = new Date();
		birthdate.setUTCFullYear(
			birthdate.getUTCFullYear() - (patientProfile.age ?? 0),
		);

		let isSuccess = false;
		let data: DoctorDocumentProperties = {
			metaPatient: {
				firstName: patientName[0],
				middleName:
					patientName.length > 2 ? patientName.slice(1, -1).join(' ') : '',
				lastName:
					patientName.length > 1 ? patientName[patientName.length - 1] : '',
				birthdate,
				gender: patientProfile.gender.toLowerCase(),
				patientId: props.patientProfile?.userId,
			},
			validUntil: clearance?.validUntil ?? new Date().toISOString(),
			price: clearance?.price ?? 0,
			meta: clearance?.meta,
			isTemporary: true,
		};
		let doctorDocumentId = '';
		if (clearance?.doctorDocumentId) {
			const res = await apiUpdateDoctorDocument(
				clearance.doctorDocumentId,
				data,
			);
			if (res) {
				if (res.doctorDocumentId) {
					doctorDocumentId = res.doctorDocumentId;
				}
				props.setClearance(res);
				isSuccess = true;
			}
		} else {
			data = {
				...data,
				transactionConsultationId: props.transactionConsultationId,
				doctorId: user?.userId,
				type: EMetaType.CLEARANCE,
				patientId: props.patientProfile?.userId,
			};

			const res = await apiCreateDoctorDocument(data);
			if (res) {
				if (res.doctorDocumentId) {
					doctorDocumentId = res.doctorDocumentId;
				}
				props.setClearance(res);
				isSuccess = true;
			}
		}

		if (isSuccess) {
			props.onBack();
		} else {
			setIsLoading(false);
		}
	}

	return (
		<div className="relative w-full flex flex-col">
			<SidebarMenuHeader onBack={props.onBack} title="Clearance" />
			<CustomScrollDiv className="relative flex overflow-auto w-full h-full">
				<div className="w-full">
					<div className="flex-1 pb-6">
						<FormInput
							label="Patient Name"
							value={patientProfile.patientName}
							onChange={(val) =>
								setPatientProfile((old) => ({ ...old, patientName: val }))
							}
						/>
						<FormInput
							label="Valid Until"
							value={clearance?.validUntil}
							onChange={(val) =>
								setClearance((old) => ({ ...old, validUntil: val }))
							}
							type={EFormType.DATE_PICKER}
						/>
						<FormInput
							label="Age"
							value={patientProfile.age?.toString()}
							onChange={(val) => {
								const age = Number(val);
								if (isNumber(age) && !isNaN(age)) {
									setPatientProfile((old) => ({ ...old, age }));
								}
							}}
							suffix={
								<p className="m-0 whitespace-nowrap text-black-60">Years old</p>
							}
						/>
						<div>
							<p className="m-0">Gender</p>
							<div className="flex gap-x-3">
								{['Male', 'Female'].map((gender) => (
									<button
										key={gender}
										onClick={() =>
											setPatientProfile((old) => ({
												...old,
												gender: gender.toLowerCase(),
											}))
										}
										className="flex-1 py-2.5 px-3.5 flex items-center gap-x-2.5 bg-transparent border border-ash-800 rounded-4 border-solid cursor-pointer"
									>
										{patientProfile.gender.toLowerCase() ===
										gender.toLowerCase() ? (
											<CheckCircle
												width={20}
												height={20}
												color={theme.colors.primary}
											/>
										) : (
											<Circle
												width={20}
												height={20}
												color={theme.colors.black40}
											/>
										)}
										<p className="m-0">{gender}</p>
									</button>
								))}
							</div>
						</div>
						<FormInput
							label="Clearence title"
							type={EFormType.MULTI_LINE}
							rows={3}
							value={(clearance?.meta as MetaClearence)?.title}
							onChange={(val) =>
								setClearance(
									(old) =>
										({
											...old,
											meta: {
												...old?.meta,
												title: val,
											} as MetaClearence,
										}) as any,
								)
							}
						/>
						<FormInput
							label="Comorbid Conditions"
							type={EFormType.MULTI_LINE}
							rows={3}
							value={(clearance?.meta as MetaClearence)?.comorbidConditions}
							onChange={(val) =>
								setClearance(
									(old) =>
										({
											...old,
											meta: {
												...old?.meta,
												comorbidConditions: val,
											} as MetaClearence,
										}) as any,
								)
							}
						/>
						<FormInput
							label="Procedure"
							type={EFormType.MULTI_LINE}
							rows={3}
							value={(clearance?.meta as MetaClearence)?.procedures}
							onChange={(val) =>
								setClearance(
									(old) =>
										({
											...old,
											meta: {
												...old?.meta,
												procedures: val,
											} as MetaClearence,
										}) as any,
								)
							}
						/>
						<FormInput
							label="Symptoms"
							type={EFormType.MULTI_LINE}
							rows={3}
							value={(clearance?.meta as MetaClearence)?.symptoms}
							onChange={(val) =>
								setClearance(
									(old) =>
										({
											...old,
											meta: {
												...old?.meta,
												symptoms: val,
											} as MetaClearence,
										}) as any,
								)
							}
						/>
						<FormInput
							label="Functional Capacity"
							type={EFormType.MULTI_LINE}
							rows={3}
							value={(clearance?.meta as MetaClearence)?.functionalCapacity}
							onChange={(val) =>
								setClearance(
									(old) =>
										({
											...old,
											meta: {
												...old?.meta,
												functionalCapacity: val,
											} as MetaClearence,
										}) as any,
								)
							}
						/>
						<FormInput
							label="Chest Xray"
							type={EFormType.MULTI_LINE}
							rows={3}
							value={(clearance?.meta as MetaClearence)?.chestXRay}
							onChange={(val) =>
								setClearance(
									(old) =>
										({
											...old,
											meta: {
												...old?.meta,
												chestXRay: val,
											} as MetaClearence,
										}) as any,
								)
							}
						/>
						<FormInput
							label="12-L ECG"
							type={EFormType.MULTI_LINE}
							rows={3}
							value={(clearance?.meta as MetaClearence)?.lecg12}
							onChange={(val) =>
								setClearance(
									(old) =>
										({
											...old,
											meta: {
												...old?.meta,
												lecg12: val,
											} as MetaClearence,
										}) as any,
								)
							}
						/>
						<FormInput
							label="Blood tests"
							type={EFormType.MULTI_LINE}
							rows={3}
							value={(clearance?.meta as MetaClearence)?.bloodTest}
							onChange={(val) =>
								setClearance(
									(old) =>
										({
											...old,
											meta: {
												...old?.meta,
												bloodTest: val,
											} as MetaClearence,
										}) as any,
								)
							}
						/>
						<FormInput
							label="Other tests"
							type={EFormType.MULTI_LINE}
							rows={3}
							value={(clearance?.meta as MetaClearence)?.otherTest}
							onChange={(val) =>
								setClearance(
									(old) =>
										({
											...old,
											meta: {
												...old?.meta,
												otherTest: val,
											} as MetaClearence,
										}) as any,
								)
							}
						/>
						<FormInput
							label="Patient Stratified"
							type={EFormType.MULTI_LINE}
							rows={3}
							value={(clearance?.meta as MetaClearence)?.patientStratified}
							onChange={(val) =>
								setClearance(
									(old) =>
										({
											...old,
											meta: {
												...old?.meta,
												patientStratified: val,
											} as MetaClearence,
										}) as any,
								)
							}
						/>
						<FormInput
							label="Recommendations"
							type={EFormType.MULTI_LINE}
							rows={3}
							value={(clearance?.meta as MetaClearence)?.recommendation}
							onChange={(val) =>
								setClearance(
									(old) =>
										({
											...old,
											meta: {
												...old?.meta,
												recommendation: val,
											} as MetaClearence,
										}) as any,
								)
							}
						/>
						<div>
							<p className="m-0 text-4">Document Price</p>
							<div className="flex items-center gap-x-2">
								<IconButton
									onClick={() =>
										setClearance((old) => ({ ...old, price: 1 }) as any)
									}
								>
									{!!clearance?.price ? (
										<CheckCircle
											width={20}
											height={20}
											color={theme.colors.primary}
										/>
									) : (
										<Circle
											width={20}
											height={20}
											color={theme.colors.ash800}
										/>
									)}
								</IconButton>
								<div className="flex-1">
									<FormInput
										prefix="₱"
										value={clearance?.price}
										onChange={(val) =>
											setClearance((old) => {
												const oldPrice = old?.price;
												let price: number | undefined = Number(val);
												if (!isNumber(price) || isNaN(price)) {
													price = oldPrice;
												}
												return { ...old, price } as any;
											})
										}
									/>
								</div>
							</div>
							<div className="flex items-center gap-x-2">
								<IconButton
									onClick={() =>
										setClearance((old) => ({ ...old, price: undefined }) as any)
									}
								>
									{!clearance?.price ? (
										<CheckCircle
											width={20}
											height={20}
											color={theme.colors.primary}
										/>
									) : (
										<Circle
											width={20}
											height={20}
											color={theme.colors.ash800}
										/>
									)}
								</IconButton>
								<p className="m-0 text-4">Free / No need to pay</p>
							</div>
						</div>
					</div>
				</div>
			</CustomScrollDiv>
			<div className="py-3 px-4 border-t-solid border-t border-gray-10">
				<ButtonRemoveSave
					isEnableRemoveBtn={!isLoading}
					isEnableSaveBtn={!isLoading}
					onRemove={removeClearance}
					onSave={saveClearance}
				/>
			</div>
		</div>
	);
}
