import React, { useMemo, useState } from 'react';
import { Card, Divider, Form, Radio } from 'antd';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import PageHeader from '../../../../components/PageHeader';
import AppLayout from '../../../../layout/AppLayout';
import PreResult from '../../../../components/Webapp/MedicalCalculator/PreResult';
import Score from '../../../../components/Webapp/MedicalCalculator/Score';
import FormQuestion from '../../../../components/Webapp/MedicalCalculator/FormQuestion';

const activePath = '/app/medical-calculator';

export default function DiabetesRiskFindRisc() {
	const history = useHistory();

	const [form] = Form.useForm();

	const values = Form.useWatch([], form);

	const [value, setValue] = useState<any>(0);
	const [isChecked, setChecked] = useState<any>(false);

	const dataIndicator = useMemo(
		() => [
			{
				title: 'Low',
				width: 20,
				color: '#7FD53B',
				start: 0,
				startValue: 0,
			},
			{
				title: 'Slightly Elevated',
				width: 20,
				color: '#EFC222',
				start: 16,
				startValue: 6,
			},
			{
				title: 'Moderate',
				width: 20,
				color: '#FF931B',
				start: 28,
				startValue: 11,
			},
			{
				title: 'High',
				width: 20,
				color: '#E96831',
				start: 62,
				startValue: 14,
			},
			{
				title: 'Very High',
				width: 20,
				color: '#E93131',
				start: 80,
				startValue: 20,
			},
		],
		[],
	);

	const dataGenderOption = useMemo(
		() => [
			{ label: 'Male', value: 'male' },
			{ label: 'Female', value: 'female' },
		],
		[],
	);

	const dataAgeOption = useMemo(
		() => [
			{ label: '>64 Years', value: 4 },
			{ label: '55 - 64 Years', value: 3 },
			{ label: '45 - 54 Years', value: 2 },
			{ label: '<45 Years', value: 0 },
		],
		[],
	);

	const dataBodyMassOption = useMemo(
		() => [
			{ label: 'Higher than 30 Kg/m2', value: 3 },
			{ label: '25 - 30 Kg/m2', value: 1 },
			{ label: 'Lower than 25 Kg/m2', value: 0 },
		],
		[],
	);

	const dataWaistCircumferenceMenOption = useMemo(
		() => [
			{ label: '>102 cm', value: 4 },
			{ label: '94 - 102 cm', value: 3 },
			{ label: '<94 cm', value: 0 },
		],
		[],
	);

	const dataWaistCircumferenceWomanOption = useMemo(
		() => [
			{ label: '>88 cm', value: 4 },
			{ label: '80 - 88 cm', value: 3 },
			{ label: '<80 cm', value: 0 },
		],
		[],
	);

	const dataWaistCircumferenceNoGender = useMemo(
		() => [{ label: 'Choose gender first' }],
		[],
	);

	const dataEatVegetablesOption = useMemo(
		() => [
			{ label: 'Every day', value: 0 },
			{ label: 'Not every day', value: 1 },
		],
		[],
	);

	const dataFamilyDiagnosedOption = useMemo(
		() => [
			{ label: 'Yes: parent, brother, sister or own child', value: 5 },
			{
				label:
					'Yes: grandparent, aunt, uncle, or first cousin (but no own parent, brother, sister or child)',
				value: 3,
				className: 'min-w-max',
			},
			{ label: 'No', value: 0 },
		],
		[],
	);

	const dataQuestions = useMemo(
		() => [
			{
				row: 1,
				formItems: [
					{
						name: 'gender',
						label: 'Gender',
						type: 'select',
						options: dataGenderOption,
					},
					{
						name: 'age',
						label: 'Age',
						type: 'select',
						options: dataAgeOption,
					},
				],
			},
			{
				row: 2,
				formItems: [
					{
						name: 'body_mass_idx',
						label: 'Body-mass index',
						type: 'select',
						options: dataBodyMassOption,
					},
					{
						name: 'patient_circumference',
						label: 'What is your patient’s waist circumference?',
						type: 'select',
						options: !form.getFieldValue('gender')
							? dataWaistCircumferenceNoGender
							: form.getFieldValue('gender') === 'male'
							? dataWaistCircumferenceMenOption
							: dataWaistCircumferenceWomanOption,
					},
				],
			},
			{
				row: 3,
				formItems: [
					{
						name: 'physically_active',
						label:
							'Is your patient physically active for more than 30 minutes every day?',
						type: 'radio',
						options: { yesValue: 0, noValue: 2 },
					},
					{
						name: 'patient_eat',
						label: 'How often does your patient eat vegetables and fruits?',
						type: 'select',
						options: dataEatVegetablesOption,
					},
				],
			},
			{
				row: 4,
				formItems: [
					{
						name: 'medication_high_blood',
						label:
							'Has your patient ever taken medication for high blood pressure on a regular basis?',
						type: 'radio',
						options: { yesValue: 2, noValue: 0 },
					},
					{
						name: 'high_blood_glucose',
						label:
							'Have you ever been found to have high blood glucose (eg in a health examination, during an illness, during pregnancy)',
						type: 'radio',
						options: { yesValue: 5, noValue: 0 },
					},
				],
			},
			{
				row: 5,
				formItems: [
					{
						name: 'diagnosed_diabetes',
						label:
							'Have any of the members of your immediate family or other relatives been diagnosed with diabetes (type 1 or type 2)?',
						type: 'select',
						options: dataFamilyDiagnosedOption,
					},
				],
			},
		],
		[values],
	);

	const checkHandler = (values: any) => {
		if (isChecked) {
			form.resetFields();
			setChecked(false);
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth',
			});
			return;
		}

		setChecked(true);
		const res = calculateFindRisc({ ...values });
		setValue(res);
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	};

	const calculateFindRisc = (values: any) => {
		const { gender, ...other } = values;
		const results = Object.values(other).reduce(
			(acc: any, curr) => acc + curr,
			0,
		);

		return results;
	};

	const resultValue = useMemo(() => {
		const score = value;
		if (score >= dataIndicator?.[dataIndicator?.length - 1]?.startValue) {
			return {
				score,
				item: dataIndicator[dataIndicator?.length - 1],
				pointer: 80,
			};
		}

		const filtered = dataIndicator?.filter((item) => score > item?.startValue);
		const itemSelected = filtered?.[filtered?.length - 1];
		const itemIndex = dataIndicator?.findIndex(
			(itm) => itm?.start === itemSelected?.start,
		);
		const nextValue = dataIndicator?.[itemIndex + 1];
		const pointer =
			(score / nextValue?.startValue) *
			(itemSelected?.width + itemSelected?.start);

		return {
			score,
			item: itemSelected,
			pointer,
		};
	}, [value, dataIndicator]);

	const chanceValue = () => {
		switch (resultValue?.item?.title) {
			case 'Low':
				return (1 / 100) * 100;
			case 'Slightly Elevated':
				return (1 / 25) * 100;
			case 'Moderate':
				return (1 / 6) * 100;
			case 'High':
				return (1 / 3) * 100;
			case 'Very High':
				return (1 / 2) * 100;
			default:
				return 0;
		}
	};

	const estimated = useMemo(
		() => ({
			title: 'Chance of developing diabetes within 10 Years',
			value: chanceValue().toFixed(1) + '%',
		}),
		[resultValue],
	);

	return (
		<AppLayout activePath={activePath}>
			<Card style={{ minHeight: 550 }}>
				<PageHeader
					title="Findrisc Calculator"
					onClick={() => history.goBack()}
				/>

				<Divider />

				{isChecked ? (
					<Score
						dataIndicator={dataIndicator}
						estimated={estimated}
						resultValue={resultValue}
					/>
				) : (
					<PreResult />
				)}

				<FormQuestion
					checkHandler={checkHandler}
					dataQuestions={dataQuestions}
					form={form}
					isChecked={isChecked}
				/>
			</Card>
		</AppLayout>
	);
}

const RadioBtnCustom = styled(Radio.Button)`
	border: none;

	&:not(:checked) {
		background-color: #f1f3f5;
	}
`;
