import React, { useState, useEffect, useMemo, useCallback } from 'react';
import AppLayout from '../../layout/AppLayout';
import {
	Typography,
	Card,
	Divider,
	Avatar,
	List,
	Row,
	Col,
	message,
	PageHeader,
	Button
} from 'antd';
import {
	UserOutlined,
	CheckCircleOutlined,
	LaptopOutlined,
	HomeOutlined,
	WarningOutlined,
	InfoCircleOutlined,
	ExclamationCircleOutlined as IconConsultNow,
} from '@ant-design/icons';
import {
	DoctorProperties,
	SpecialistProperties,
} from '../../types/doctor.type';
import { httpRequest } from '../../helpers/api';
import { IPayloadPagination, IHttpResponse } from '../../helpers/pagination';
import { useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
	iconCheck as IconCheck,
	iconTelemedicine as IconTelemedicine,
	iconAppointment as IconInPerson,
} from '../../assets/icons';
import { PartnerDoctorProperties } from '../../types/partnerDoctor.type';
import useSchedulesNew from '../../hooks/useSchedulesNew';
import { useAuth } from '../../context/auth.context';
import { apiGetPartnerDoctors } from '../../api/partnerDoctor';
import { EPartnerType } from '../../types/partner.type';
import { PracticeDataItem, listDefaultClinics } from '../../constants/clinic';
import { apiGetAppConfig } from '../../api/appConfig';
import { render } from 'react-dom';
import moment from 'moment';
import Icon from '@ant-design/icons/lib/components/Icon';
import { createTimeArray } from '../../helpers/datetime';
import useBookConsultation from '../../hooks/useBookConsultation';
import { DoctorPartnerScheduleProperties } from '../../types/doctorPartnerSchedule';
import { daysOfWeek } from '../../helpers/constants';
import _ from 'lodash';
import Item from 'antd/lib/list/Item';

type IDataAvailabilityItem = {
	weekday: number;
	title: string;
	data?: {
		scheduleId: string;
		text: string;
		startAt: Date | string;
		endAt: Date | string;
	}[];
	address?: string;
};

export default function DoctorDirectoryDetailScreen() {
	const activePath = '/app/doctor-directory-details';
	const location = useLocation();
	const history = useHistory();

	const { Text, Title } = Typography;

	const data: DoctorProperties[] = [
		location.state as unknown as DoctorProperties,
	];
	const dataSpecial: SpecialistProperties[] = [
		location.state as unknown as SpecialistProperties,
	];
	const [partners, setPartners] = useState<PartnerDoctorProperties[]>([]);

	const [isLoading, setIsLoading] = useState<boolean>(true);

	const [price, setPrice] = useState<number | undefined>(undefined);
	const [pricesByPartner, setPricesByPartner] = useState<
		Record<string, number[]>
	>({});
	const [priceConsultNow, setPriceConsultNow] = useState<number | undefined>(
		undefined,
	);

	const payload = location.state as unknown as PracticeDataItem;
	const [selected, setSelected] = useState<PracticeDataItem>(
		payload
			? payload
			: {
					title: '',
					partnerId: '',
					type: 'appointment',
					price: 0,
					availableDay: [],
					schedules: [],
			  },
	);
	const { schedules: semiProcessedSchedules } = useSchedulesNew({
		userId: data[0].doctorId ?? '',
	});

	const [dataDoctor, setDataDoctor] =
		useState<IPayloadPagination<DoctorProperties>>();
	const [dataPartnerDoctors, setDataPartnerDoctors] = useState<
		PartnerDoctorProperties[]
	>([]);
	const [currentConsultNowInfos, setCurrentConsultNowInfos] = useState<
		PartnerDoctorProperties[]
	>([]);
	const [currentTelemedicineInfos, setCurrentTelemedicineInfos] = useState<
		PartnerDoctorProperties[]
	>([]);
	const [currentInPersonInfos, setCurrentInPersonInfos] = useState<
		PartnerDoctorProperties[]
	>([]);

	const {
		setCurrentPartnerDoctors,
		setCurrentPartnerType,
		setDoctorInfo,
		clearDataOrder,
		setCurrentPartnerDoctorInClinics,
		setSelectedClinic,
		setPartnerInfo,
		setTelemedicinePrice,
		setConsultNowPrice,
		setInPersonPrice,
	} = useBookConsultation();

	const icons = {
		Telemedicine: <LaptopOutlined />,
		'Consult Now': <CheckCircleOutlined />,
		'In-Person': <HomeOutlined />,
	};

	//
	function handleBookNow(params: {
		partnerType: EPartnerType;
		id?: string;
		consultType?: 'chat' | 'telemedicine';
	}) {
		setCurrentPartnerDoctors([]);
		clearDataOrder();
		setCurrentPartnerType(params.partnerType);
		setDoctorInfo(data[0]);
		setCurrentPartnerDoctors(dataPartnerDoctors);
		if (
			[EPartnerType.CLINIC, EPartnerType.HOSPITAL].includes(params.partnerType)
		) {
			setCurrentPartnerDoctorInClinics(currentInPersonInfos);

			const found = currentInPersonInfos.find(
				(item) => item.partner?.partnerId === params.id,
			);

			setSelectedClinic(found);
			setPartnerInfo(found?.partner);
			setInPersonPrice(found?.pricePerSession);
		} else if (params.partnerType === EPartnerType.CONSULT_NOW) {
			setSelectedClinic(currentConsultNowInfos[0]);
			setPartnerInfo(currentConsultNowInfos[0].partner);
			setConsultNowPrice(currentConsultNowInfos[0].pricePerSession);
		} else if (params.partnerType === EPartnerType.ONLINE_CLINIC) {
			setPartnerInfo(currentTelemedicineInfos[0].partner);
			setTelemedicinePrice(currentTelemedicineInfos[0].pricePerSession);
		}
	}

	const fetchDoctorProfile = useCallback(async (doctorId: string) => {
		setIsLoading(true);
		try {
			const res = await httpRequest.get<
				IHttpResponse<IPayloadPagination<DoctorProperties>>
			>(`/doctors/${doctorId}`);

			const resPartnerDoctors = await httpRequest.get<
				IHttpResponse<IPayloadPagination<PartnerDoctorProperties>>
			>(`/partner-doctors` + `?doctorId=${doctorId}`);
			if (res?.data?.payload) {
				setDataDoctor(res.data.payload);
			}

			if (resPartnerDoctors?.data?.payload) {
				setDataPartnerDoctors(resPartnerDoctors.data.payload.results);

				const inPersonSchedules = resPartnerDoctors.data.payload.results.filter(
					(resItem) =>
						[EPartnerType.CLINIC, EPartnerType.HOSPITAL].includes(
							resItem.partner?.partnerType as EPartnerType,
						),
				);

				const telemedicineSchedules =
					resPartnerDoctors.data.payload.results.filter((resItem) =>
						[EPartnerType.ONLINE_CLINIC].includes(
							resItem.partner?.partnerType as EPartnerType,
						),
					);

				const consultNowSchedules =
					resPartnerDoctors.data.payload.results.filter((resItem) =>
						[EPartnerType.CONSULT_NOW].includes(
							resItem.partner?.partnerType as EPartnerType,
						),
					);

				setCurrentInPersonInfos(inPersonSchedules);
				setCurrentTelemedicineInfos(telemedicineSchedules);
				setCurrentConsultNowInfos(consultNowSchedules);

				console.log('In Person Schedules:', inPersonSchedules);
				console.log('Telemedicine Schedules:', telemedicineSchedules);
				console.log('Consult Now Schedules:', consultNowSchedules);
			}
		} catch (err) {
			message.error('ERROR: Failed to load doctor profile');
			console.error(err);
		} finally {
			setIsLoading(false);
		}
	}, []);

	const consultNowAvailability = useMemo(() => {
		let consultNowScheduleType: any[] = [];

		if (dataDoctor && Array.isArray(dataDoctor.results)) {
			consultNowScheduleType = dataDoctor.results
				.map((doctor) => {
					return doctor.schedulesInPartners?.filter(
						(partner) =>
							partner.partner?.partnerType === EPartnerType.CONSULT_NOW &&
							new Date(partner.startAt).getTime() <
								new Date(partner.endAt).getTime(),
					);
				})
				.flat();
		}

		return consultNowScheduleType;
	}, [dataDoctor]);

	const telemedicineAvailability = useMemo(() => {
		let telemedicineScheduleType: any[] = [];

		if (dataDoctor && Array.isArray(dataDoctor.results)) {
			telemedicineScheduleType = dataDoctor.results
				.map((doctor) => {
					return doctor.schedulesInPartners?.filter(
						(partner) =>
							partner.partner?.partnerType === EPartnerType.ONLINE_CLINIC &&
							new Date(partner.startAt).getTime() <
								new Date(partner.endAt).getTime(),
					);
				})
				.flat();
		}
		console.log('Telemedicine Schedule Type:', telemedicineScheduleType);
		return telemedicineScheduleType;
	}, [dataDoctor]);

	const inPersonAvailability = useMemo(() => {
		let inPersonScheduleType: any[] = [];

		if (dataDoctor && Array.isArray(dataDoctor.results)) {
			inPersonScheduleType = dataDoctor.results
				.map((doctor) => {
					return doctor.schedulesInPartners?.filter(
						(partner) =>
							[
								EPartnerType.CLINIC,
								EPartnerType.HOSPITAL,
								EPartnerType.LABORATORY,
							].includes(partner.partner?.partnerType as EPartnerType) &&
							new Date(partner.startAt).getTime() <
								new Date(partner.endAt).getTime(),
					);
				})
				.flat();
		}
		console.log('In Person Schedule Type:', inPersonScheduleType);
		return inPersonScheduleType;
	}, [dataDoctor]);

	const generateTimeInterval = (
		schedulesInPartners: DoctorPartnerScheduleProperties[],
		partnerTypes: EPartnerType[],
	) => {
		if (schedulesInPartners && schedulesInPartners.length > 0) {
			let newIntervals: ScheduleTimeItemProps[] = [];

			for (const iterator of schedulesInPartners) {
				if (
					iterator.partner &&
					partnerTypes.includes(iterator.partner.partnerType)
				) {
					const startAt = moment(iterator.startAt).format('HH');
					const endAt = moment(iterator.endAt).format('HH');

					const times = createTimeArray(30, Number(startAt), Number(endAt));

					const found = newIntervals.find(
						(item) => item.weekday === iterator.weekday,
					);

					if (found) {
						newIntervals = newIntervals.map((item) => {
							if (item.weekday === found.weekday) {
								const times = [...item.times, ...found.times];
								const uniqueTime = _.uniqBy(times, 'times');

								return {
									...item,
									times: uniqueTime,
								};
							} else {
								return item;
							}
						});
					} else {
						newIntervals.push({
							week: daysOfWeek[iterator.weekday],
							weekday: iterator.weekday,
							times,
						});
					}
				}
			}

			return newIntervals?.filter(
				(item) => item.times && item.times.length > 0,
			);
		}
	};

	const description = (experiences: any) => {
		return (
			<Row>
				<Text type="secondary" style={{ fontSize: 14 }}>
					{experiences !== undefined ? experiences : 0} Years Experiences
				</Text>
			</Row>
		);
	};

	const newIntervalsConsultNow = useMemo(() => {
		if (
			consultNowAvailability &&
			currentConsultNowInfos &&
			currentConsultNowInfos.length > 0
		) {
			return (
				generateTimeInterval(currentConsultNowInfos[0].schedules ?? [], [
					EPartnerType.CONSULT_NOW,
				]) ?? []
			);
		}
	}, [consultNowAvailability, currentConsultNowInfos]);

	const newIntervalsTelemedicine = useMemo(() => {
		if (
			telemedicineAvailability &&
			currentTelemedicineInfos &&
			currentTelemedicineInfos.length > 0
		) {
			return (
				generateTimeInterval(currentTelemedicineInfos[0].schedules ?? [], [
					EPartnerType.ONLINE_CLINIC,
				]) ?? []
			);
		}
	}, [telemedicineAvailability, currentTelemedicineInfos]);

	const newIntervalsInPerson = useMemo(() => {
		if (
			inPersonAvailability &&
			currentInPersonInfos &&
			currentInPersonInfos.length > 0
		) {
			let newDataInPersons: {
				partnerName: string;
				address: string;
				pricePerSession: string;
				partnerType: EPartnerType;
				schedule: ScheduleTimeItemProps[];
				id: string;
			}[] = [];

			for (const iterator of currentInPersonInfos) {
				const schedule =
					generateTimeInterval(iterator.schedules ?? [], [
						EPartnerType.CLINIC,
						EPartnerType.HOSPITAL,
					]) ?? [];

				if (schedule.length > 0) {
					newDataInPersons.push({
						partnerName: iterator.partner?.partnerName ?? '-',
						address: iterator.partner?.address ?? '-',
						pricePerSession: String(iterator.pricePerSession),
						schedule: schedule,
						partnerType: iterator.partner?.partnerType ?? EPartnerType.CLINIC,
						id: iterator.partnerId,
					});
				}
			}

			return newDataInPersons;
		}
	}, [inPersonAvailability, currentInPersonInfos]);

	const nullInPersonPrice = useMemo(() => {
		if (newIntervalsInPerson && newIntervalsInPerson.length > 0) {
			const found = newIntervalsInPerson?.find((item) => {
				return item.pricePerSession;
			});
			return !!found;
		}
	}, [newIntervalsInPerson]);

	const renderTelemedicineAvailability = () => {
		if (newIntervalsTelemedicine && newIntervalsTelemedicine.length > 0) {
			const icon = (
				<div
					style={{
						width: 24,
						height: 24,
						backgroundColor: '#D81F64',
						borderRadius: '50%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						marginRight: 8,
					}}
				>
					<IconTelemedicine width={10} height={10} style={{ fontSize: 16, color: 'white' }} />
				</div>
			);

			return (
				<>
					<Row style={{ alignItems: 'center' }}>
						<Col>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								{icon}
								<Text strong style={{ fontSize: 16, marginLeft: 8 }}>
									Telemedicine Schedule - Price per Session: ₱{currentTelemedicineInfos[0].pricePerSession}
								</Text>
							</div>
						</Col>
					</Row>
					{newIntervalsTelemedicine.map((item, index) => (
						<Row key={index} gutter={[16, 8]}>
							<Col span={12}>
								<Text type="secondary" style={{ fontSize: 14 }}>
									{item.week}
								</Text>
							</Col>
							<Col span={12}>
								<Text style={{ fontSize: 14 }}>
									{item.times.map((time, timeIndex, arr) => (
										<React.Fragment key={timeIndex}>
											{time.times}
											{timeIndex < arr.length - 1 ? ' . ' : ''}
										</React.Fragment>
									))}
								</Text>
							</Col>
						</Row>
					))}
				</>
			);
		} else {
			return (
				<Row>
					<Text>-</Text>
				</Row>
			);
		}
	};

	const renderConsultNowAvailability = () => {
		if (newIntervalsConsultNow && newIntervalsConsultNow.length > 0) {
			const icon = (
				<div
					style={{
						width: 24,
						height: 24,
						backgroundColor: '#EFD31A',
						borderRadius: '50%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						marginRight: 8,
					}}
				>
					<IconConsultNow style={{ fontSize: 14, color: 'white' }} />
				</div>
			);

			return (
				<>
					<Row style={{ alignItems: 'center' }}>
						<Col>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								{icon}
								<Text strong style={{ fontSize: 16 }}>
									Consult Now Schedule - Price per Session: ₱{currentConsultNowInfos[0].pricePerSession}
								</Text>
							</div>
						</Col>
					</Row>
				</>
			);
		} else {
			return (
				<Row>
					<Text>-</Text>
				</Row>
			);
		}
	};

	const renderInPersonAvailability = () => {
		if (newIntervalsInPerson && newIntervalsInPerson.length > 0) {
			const icon = (
				<div
					style={{
						width: 24,
						height: 24,
						backgroundColor: '#2B9CDC',
						borderRadius: '50%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						marginRight: 8,
					}}
				>
					<IconInPerson width={10} height={10} style={{ fontSize: 14, color: 'white' }} />
				</div>
			);

			return (
				<>
					<Row style={{ alignItems: 'center' }}>
						<Col>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								{icon}
								<Text strong style={{ fontSize: 16 }}>
									In Person Schedule
								</Text>
							</div>
						</Col>
					</Row>
					{newIntervalsInPerson.map((item, index) => (
						<Row key={index} gutter={[16, 8]}>
							<Col span={24}>
								<Text strong style={{ fontSize: 16 }}>
								{item.partnerName} - Price per Session: ₱{item.pricePerSession === 'null' ? 0 : item.pricePerSession ?? 0}
								</Text>
							</Col>
							{item.schedule.map((schedule, scheduleIndex) => (
								<Row key={scheduleIndex} gutter={[16, 8]}>
									<Col span={12}>
										<Text type="secondary" style={{ fontSize: 14 }}>
											{schedule.week}
										</Text>
									</Col>
									<Col span={12}>
										<Text style={{ fontSize: 14 }}>
											{schedule.times.map((time, timeIndex, arr) => (
												<React.Fragment key={timeIndex}>
													{time.times}
													{timeIndex < arr.length - 1 ? ' . ' : ''}
												</React.Fragment>
											))}
										</Text>
									</Col>
								</Row>
							))}
						</Row>
					))}
				</>
			);
		} else {
			return (
				<Row>
					<Text>-</Text>
				</Row>
			);
		}
	};

	const getConsultationAvailability = () => {
		let output = [];
		let mode: any = [];
		if (
			data[0].availableConsultationTypes === undefined ||
			data[0]['availableConsultationTypes'].length === 0
		) {
			return <Text>-</Text>;
		} else {
			for (let consultation of data[0].availableConsultationTypes) {
				if (consultation === 'FACE_TO_FACE_CONSULTATION') {
					if (mode.includes('In Person') === false) {
						mode.push('In Person');
						output.push(
							<>
								<IconCheck />
								<Text style={{ paddingLeft: 8, paddingRight: 8 }}>
									In Person
								</Text>
							</>,
						);
					}
				} else {
					if (mode.includes('Telemedicine') === false) {
						mode.push('Telemedicine');
						output.push(
							<>
								<IconCheck />
								<Text style={{ paddingLeft: 8, paddingRight: 8 }}>
									Telemedicine
								</Text>
							</>,
						);
					}
				}
			}
		}
		return output;
	};

	const getPartners = () => {
		let output = [];
		for (let partner of partners) {
			if (
				partner.partner?.partnerName !== 'Online Clinic' &&
				partner.partner?.partnerName !== 'Consult Now'
			) {
				output.push(
					<Row>
						<Text style={{ fontSize: 14 }}>{partner.partner?.partnerName}</Text>
					</Row>,
				);
			}
		}

		if (output.length === 0) {
			return (
				<Row>
					<Text>-</Text>
				</Row>
			);
		} else {
			return output;
		}
	};

	const getCertificates = () => {
		let output = [];
		if (
			data[0].certificates !== undefined &&
			data[0]['certificates']!.length > 0
		) {
			for (let certificate of data[0].certificates!) {
				if (certificate.name) {
					output.push(
						<Row>
							<Text style={{ fontSize: 14 }}>Passed, {certificate.name!}</Text>
						</Row>,
					);
				}
			}
			return output;
		} else {
			return (
				<Row>
					<Text>-</Text>
				</Row>
			);
		}
	};

	const getEducation = () => {
		let output = [];
		if (data[0].educations !== undefined && data[0]['educations'].length > 0) {
			for (let education of data[0].educations) {
				output.push(
					<>
						<Row>
							<Text strong style={{ fontSize: 14 }}>
								{education.stage}
							</Text>
						</Row>
						<Row>
							<Text style={{ fontSize: 14 }}>{education.institutionName}</Text>
						</Row>
					</>,
				);
			}
			return output;
		} else {
			return (
				<Row>
					<Text>-</Text>
				</Row>
			);
		}
	};

	const getSpecialty = () => {
		if (dataSpecial[0] && Array.isArray(dataSpecial[0].specialists)) {
			const specialistNames = dataSpecial[0].specialists
				.filter((specialist) => specialist.specialistName)
				.map((specialist, index) => (
					<Row key={specialist.id || index}>
						<Text style={{ fontSize: 14 }}>{specialist.specialistName}</Text>
					</Row>
				));

			if (specialistNames.length > 0) {
				return specialistNames;
			} else {
				return (
					<Row>
						<Text type="secondary">No Specialty</Text>
					</Row>
				);
			}
		} else {
			return (
				<Row>
					<Text type="secondary">No Specialty</Text>
				</Row>
			);
		}
	};

	const getSubSpecialty = () => {
		if (dataSpecial[0] && Array.isArray(dataSpecial[0].specialists)) {
			const subSpecialtyNames = dataSpecial[0].specialists
				.filter((specialist) => specialist.subSpecialistName)
				.map((specialist, index) => (
					<Row key={specialist.id || index}>
						<Text style={{ fontSize: 14 }}>{specialist.subSpecialistName}</Text>
					</Row>
				));

			if (subSpecialtyNames.length > 0) {
				return subSpecialtyNames;
			} else {
				return (
					<Row>
						<Text type="secondary">No Subspecialty</Text>
					</Row>
				);
			}
		} else {
			return (
				<Row>
					<Text type="secondary">No Subspecialty</Text>
				</Row>
			);
		}
	};

	useEffect(() => {
		if (data[0].doctorId) {
			fetchDoctorProfile(data[0].doctorId);
		}

		console.log('ID Doctor:', data[0].doctorId);
		console.log('dataSpecial', dataSpecial);
		console.log('data spesialiti', dataSpecial[0].specialists);
		if (dataSpecial[0] && Array.isArray(dataSpecial[0].specialists)) {
			const specialistNames = dataSpecial[0].specialists.map(
				(specialist) => specialist.specialistName,
			);
			console.log('Specialist Names:', specialistNames);
		}
		const availability = consultNowAvailability;
		console.log('Consult Now Availability:', availability);
	}, []);

	return (
		<AppLayout activePath={activePath}>
			{location.state && (
				<Card>
					<CustomHeader
						className="site-page-header"
						onBack={() => history.goBack()}
						title="Back"
						subTitle="Detail Doctor"
					/>
					<Divider style={{ marginTop: 24, marginBottom: 24 }} />
					<div>
						<List
							itemLayout="horizontal"
							dataSource={data!}
							renderItem={(item) => (
								<List.Item>
									<List.Item.Meta
										key={item.doctorId}
										avatar={<Avatar size={48} icon={<UserOutlined />} />}
										title={
											<Text style={{ fontWeight: 600, fontSize: 16 }}>
												{item.nameOfSignature}
											</Text>
										}
										description={description(item.experience)}
									/>
								</List.Item>
							)}
						/>
						<CustomDivider />
						<Row>
							<Text type="secondary" style={{ fontSize: 14 }}>
								Specialty
							</Text>
						</Row>
						<Text style={{ fontSize: 14 }}>{getSpecialty()}</Text>
						<Row>
							<Text type="secondary" style={{ fontSize: 14 }}>
								Sub-Specialty
							</Text>
						</Row>
						<Text style={{ fontSize: 14 }}>{getSubSpecialty()}</Text>
						<CustomDivider />
						<Row>
							<Text type="secondary" style={{ fontSize: 14 }}>
								Consultation Availability
							</Text>
						</Row>
						<Row>
							<Text style={{ fontSize: 14 }}>
								{getConsultationAvailability()}
							</Text>
						</Row>
						<CustomDivider/>
						{renderConsultNowAvailability()}
						<CustomDivider/>
						{renderTelemedicineAvailability()}
						<CustomDivider/>
						{renderInPersonAvailability()}
						<CustomDivider/>
						<Row style={{ paddingTop: 16 }}>
							<Text type="secondary" style={{ fontSize: 14 }}>
								Medical Doctor Practice
							</Text>
						</Row>
						{getPartners()}
						<CustomDivider />
						<Row>
							<Text type="secondary" style={{ fontSize: 14 }}>
								Certifications
							</Text>
						</Row>
						{getCertificates()}
						<Row style={{ paddingTop: 16 }}>
							<Text type="secondary" style={{ fontSize: 14 }}>
								Education
							</Text>
						</Row>
						{getEducation()}
					</div>
				</Card>
			)}
		</AppLayout>
	);
}

type ScheduleTimeItemProps = {
	weekday: number;
	week: string;
	times: {
		times: string;
		timeGroup: string;
	}[];
};

const CustomHeader = styled(PageHeader)`
	padding: 0;

	.ant-page-header-heading-sub-title {
		margin-right: 12px;
		margin-bottom: 0;
		color: rgba(0, 0, 0, 0.85);
		font-weight: 600;
		font-size: 26px;
		line-height: 32px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		position: absolute;
		left: 50%;
		transform: translate(-50%, 0);
		font-size: 25px;
	}

	.ant-page-header-heading-title {
		margin-right: 12px;
		color: #748494;
		font-size: 14px;
		line-height: 1.5715;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		font-weight: 700;
	}
`;

const CustomDivider = styled(Divider)`
	margin-top: 12px;
	margin-bottom: 12px;
`;
