import React from 'react';
import AppLayout from '../../../layout/AppLayout';
import MainScreen from '../../../components/AppCalculator/IndexMainScreenAppCalculator';
import styled from 'styled-components';

const activePath = '/app/medical-calculator';

export default function MedicalCalculatorScreen() {
	return (
		<AppLayout activePath={activePath}>
			<ScreenWrapper>
				<MainScreen />
			</ScreenWrapper>
		</AppLayout>
	);
}

const ScreenWrapper = styled.div`
	border-radius: 12px;
	overflow: hidden;
`;
