const dueDate = [
	{
		weekId: 1,
		imageUrl: undefined,
		sizeOf: undefined,
		description:
			"At 1 week pregnant, you're actually not pregnant yet. As your pregnancy is calculated from the first day of your last menstruation, your baby does not yet exist, and your body is preparing for the ovulation during which you'll get pregnant.",
	},
	{
		weekId: 2,
		imageUrl: undefined,
		sizeOf: undefined,
		description:
			"At 2 weeks pregnant, you're technically not pregnant yet. Right now there is a lone egg and a whole bunch of anxious sperm eager to fertilize the egg. Your uterus and the entire body are preparing for a big day of ovulation - the stage when you'll get pregnant.",
	},
	{
		weekId: 3,
		imageUrl: undefined,
		sizeOf: undefined,
		description:
			"At 3 week pregnant is the week when the implantation happens. Your body releases chorionic gonadotropin (hCG), which causes an increased production of estrogen and progesterone, and prevents new eggs in the ovaries from ripening. Very soon you'll start experiencing the first symptoms of pregnancy: missed period, nausea, breast changes.",
	},
	{
		weekId: 4,
		imageUrl: require('../assets/icons/pregnancy-tracker/poppy-seed.png'),
		sizeOf: 'poppy seed',
		description:
			"While you've been adjusting to being pregnant, the new life inside you has been busy. The fertilized egg is implanting itself into the side of your uterus.",
	},
	{
		weekId: 5,
		imageUrl: require('../assets/icons/pregnancy-tracker/sesame-seed.png'),
		sizeOf: 'sesame seed',
		description:
			'The placenta and the beginnings of the umbilical cord are developing in order to channel essential nutrients and oxygen from your body to the embryo.',
	},
	{
		weekId: 6,
		imageUrl: require('../assets/icons/pregnancy-tracker/sweet-pea.png'),
		sizeOf: 'sweet pea',
		description:
			'This week, the areas that will become the eyes and ears have started to project as bumps, and other tiny buds are forming that will eventually grow into arms and legs.',
	},
	{
		weekId: 7,
		imageUrl: require('../assets/icons/pregnancy-tracker/blueberry.png'),
		sizeOf: 'blueberry',
		description:
			'As the week progresses, the digestive system and lungs are also developing. Tiny facial features are also starting to take shape, and each little arm now has a paddle-shaped hand attached to it.',
	},
	{
		weekId: 8,
		imageUrl: require('../assets/icons/pregnancy-tracker/bean.png'),
		sizeOf: 'bean',
		description:
			'This week, hands and feet are forming tiny fingers and toes, and those arms are able to flex at the elbows and wrists. At this point, eyes begin to develop pigment, and genitals are forming too.',
	},
	{
		weekId: 9,
		imageUrl: require('../assets/icons/pregnancy-tracker/cherry.png'),
		sizeOf: 'cherry',
		description:
			'Tiny facial features continue to develop this week, including a more prominent nose and eyelids. Up top, the head is large compared to the body, while down below, little toes are now visible.',
	},
	{
		weekId: 10,
		imageUrl: require('../assets/icons/pregnancy-tracker/prune.png'),
		sizeOf: 'prune',
		description:
			"All the baby's major body organs have started to form.Baby's nose, mouth, and eyes are taking shape. Tooth buds and caps are becoming recognizable.",
	},
	{
		weekId: 11,
		imageUrl: require('../assets/icons/pregnancy-tracker/lychee.png'),
		sizeOf: 'lychee',
		description:
			"Your baby's facial features are slowly maturing, with the ears now moving toward their final position on the sides of the head, and the eyes set wide apart with eyelids fused shut.",
	},
	{
		weekId: 12,
		imageUrl: require('../assets/icons/pregnancy-tracker/rambutan.png'),
		sizeOf: 'rambutan',
		description:
			"Your baby's hands are a little more developed than the feet, and the arms are longer than the legs. The little nailbeds that recently developed are starting to sprout fingernails and toenails, which will continue to grow to the tips of the fingers and toes.",
	},
	{
		weekId: 13,
		imageUrl: require('../assets/icons/pregnancy-tracker/star-apple.png'),
		sizeOf: 'star apple',
		description:
			"Your baby's intestines have moved back into the abdomen from the umbilical cord, now that there's enough room to accommodate them, and some of the larger bones, including those of the skull, are beginning to harden.",
	},
	{
		weekId: 14,
		imageUrl: require('../assets/icons/pregnancy-tracker/guava.png'),
		sizeOf: 'guava',
		description:
			"The senses of smell and taste are also developing, and your baby's skin is thickening too. You'll have to wait and see if your baby will be born with a full head of hair or not, but right now, hair follicles are forming under the surface of the skin.",
	},
	{
		weekId: 15,
		imageUrl: require('../assets/icons/pregnancy-tracker/nectarine.png'),
		sizeOf: 'nectarine',
		description:
			"The senses of smell and taste are also developing, and your baby's skin is thickening too. You'll have to wait and see if your baby will be born with a full head of hair or not, but right now, hair follicles are forming under the surface of the skin.",
	},
	{
		weekId: 16,
		imageUrl: require('../assets/icons/pregnancy-tracker/avocado.png'),
		sizeOf: 'avocado',
		description:
			"Your baby's ears are closer to reaching their final position, and it is possible that your baby can hear sounds at this point, or in the next few weeks. Don't be shy about talking and singing to your little one.",
	},
	{
		weekId: 17,
		imageUrl: require('../assets/icons/pregnancy-tracker/pear.png'),
		sizeOf: 'pear',
		description:
			'This week, your little one starts to develop a layer of brown fat under the skin, and will add more layers as your pregnancy progresses. This added fat provides energy and will help keep your baby warm after birth — right now, your uterus is doing a nice job of that!',
	},
	{
		weekId: 18,
		imageUrl: require('../assets/icons/pregnancy-tracker/pepper.png'),
		sizeOf: 'pepper',
		description:
			'This week, your little one is developing a unique characteristic: fingerprints. Pads of fat accumulating on the fingertips and toes will turn into distinguishing swirling lines.',
	},
	{
		weekId: 19,
		imageUrl: require('../assets/icons/pregnancy-tracker/tomato.png'),
		sizeOf: 'tomato',
		description:
			"At 19 weeks pregnant, your baby has hit several developmental milestones. If you're carrying a girl, her little reproductive system is already well established. If you're having a boy, his testicles have formed and have been secreting testosterone since about week 10 of your pregnancy, and the external genitals are continuing to grow.",
	},
	{
		weekId: 20,
		imageUrl: require('../assets/icons/pregnancy-tracker/artichoke.png'),
		sizeOf: 'artichoke',
		description:
			"Your baby is looking more babylike than ever, as facial features including the nose take shape. His sucking reflex is coming along, and it's possible that he may suck his thumb this week or sometime soon.",
	},
	{
		weekId: 21,
		imageUrl: require('../assets/icons/pregnancy-tracker/carrots.png'),
		sizeOf: 'carrots',
		description:
			"At around 21 weeks, your little one's fingers and toes are completely formed, complete with little fingerprints and toe prints. If you were to have an ultrasound this week, you may even catch your baby sucking on a thumb.",
	},
	{
		weekId: 22,
		imageUrl: require('../assets/icons/pregnancy-tracker/papaya.png'),
		sizeOf: 'papaya',
		description:
			"Your baby's brain is rapidly developing, and nerve endings are forming. By about now your baby has developed a sense of touch, which means he might be experimenting with this new sense by stroking a body part that he happens to reach or sucking his thumb.",
	},
	{
		weekId: 23,
		imageUrl: require('../assets/icons/pregnancy-tracker/grapefruit.png'),
		sizeOf: 'grapefruit',
		description:
			"Your baby is probably getting plenty of shut-eye. Most of her snooze time — about 80 percent of it, actually — will be spent in what's called rapid eye movement sleep, or REM sleep. During REM sleep your baby's eyes move and her brain is very active.",
	},
	{
		weekId: 24,
		imageUrl: require('../assets/icons/pregnancy-tracker/eggplant.png'),
		sizeOf: 'eggplant',
		description:
			"You may notice periods when your baby's movement levels seem to increase, such as before bedtime. Likewise, you might also notice other times when these movements seem less frequent, which could occur when your baby is resting or sleeping.",
	},
	{
		weekId: 25,
		imageUrl: require('../assets/icons/pregnancy-tracker/corn.png'),
		sizeOf: 'corn',
		description:
			"In your baby's brain, the cortex is forming layers. Most of the action is still controlled by other brain areas that developed much earlier. In addition to the brain, other parts of the nervous system are continuing to develop. It's this important system that will help your baby learn to take in information from the outside world, process it, and react.",
	},
	{
		weekId: 26,
		imageUrl: require('../assets/icons/pregnancy-tracker/lettuce.png'),
		sizeOf: 'lettuce',
		description:
			"Your baby's lungs are hard at work, getting ready to take those first breaths once he's born. The lungs are now starting to produce surfactant, which is a substance that helps the lungs inflate properly with each breath.",
	},
	{
		weekId: 27,
		imageUrl: require('../assets/icons/pregnancy-tracker/cauliflower.png'),
		sizeOf: 'cauliflower',
		description:
			"Your baby's tiny lungs and liver and her immune system still have a way to go before they're completely developed but she/he steadily gaining weight and fat. At this point, your baby looks like the fully formed infant you'll see at birth, only smaller.",
	},
	{
		weekId: 28,
		imageUrl: require('../assets/icons/pregnancy-tracker/pomelo.png'),
		sizeOf: 'pomelo',
		description:
			"Your baby's brain is still developing, but the central nervous system has developed enough to allow your baby to begin to control his body temperature.",
	},
	{
		weekId: 29,
		imageUrl: require('../assets/icons/pregnancy-tracker/acorn-squash.png'),
		sizeOf: 'acorn squash',
		description:
			'Although the major body and organ development work is nearly complete, her lungs may not be mature enough for life outside the womb just yet.',
	},
	{
		weekId: 30,
		imageUrl: require('../assets/icons/pregnancy-tracker/cabbage.png'),
		sizeOf: 'cabbage',
		description:
			"The fine hair that's been covering your baby's skin, which is called lanugo, starts to disappear around this time. You'll find out how much of your baby's lanugo sheds when he's born; some babies are born with a little still remaining on their shoulders, back, or ears.",
	},
	{
		weekId: 31,
		imageUrl: require('../assets/icons/pregnancy-tracker/coconut.png'),
		sizeOf: 'coconut',
		description:
			"Your little one's brain is maturing rapidly. As a result, your baby may start to regulate her own body temperature, meaning she's no longer entirely reliant on the amniotic fluid for warmth.",
	},
	{
		weekId: 32,
		imageUrl: require('../assets/icons/pregnancy-tracker/jicama.png'),
		sizeOf: 'jicama',
		description:
			"More and more fat is forming under your baby's skin. This has slowly turned your baby's skin from see-through to its current, opaque state. That's not all: Your baby's toenails have been growing and are now visible.",
	},
	{
		weekId: 33,
		imageUrl: require('../assets/icons/pregnancy-tracker/pineapple.png'),
		sizeOf: 'pineapple',
		description:
			"At this point in your pregnancy, your baby's brain is working hard, with all five senses working now! She can now hear and even see some of what's happening outside the womb.",
	},
	{
		weekId: 34,
		imageUrl: require('../assets/icons/pregnancy-tracker/butternut-squash.png'),
		sizeOf: 'butternut squash',
		description:
			"At this weeks pregnant, your baby is getting bigger, and there's less room in your womb. As a result, you may notice that his movements feel different possibly a little less forceful now but you'll still sense his wiggles and stretches.",
	},
	{
		weekId: 35,
		imageUrl: require('../assets/icons/pregnancy-tracker/honeydew-melon.png'),
		sizeOf: 'honeydew melon',
		description:
			"Your baby's arms and legs are growing chubbier, and her skin is becoming pink and smooth. She's getting ready for your cuddles!",
	},
	{
		weekId: 36,
		imageUrl: require('../assets/icons/pregnancy-tracker/swiss-chard.png'),
		sizeOf: 'swiss chard',
		description:
			"At this point, his skull bones are developed, but they haven't yet fused together. This means they can move and overlap, allowing the head and body to pass through your cervix and pelvis a little more easily.",
	},
	{
		weekId: 37,
		imageUrl: require('../assets/icons/pregnancy-tracker/romaine-lettuce.png'),
		sizeOf: 'romaine lettuce',
		description:
			"Your baby has now shed most of the lanugo, the fine body hair that covered her little body while in your uterus. She/he's now able to make grasping motions with her/his fingers. Plus, she/he may also respond to bright lights in the outside world by moving or turning toward the light.",
	},
	{
		weekId: 38,
		imageUrl: require('../assets/icons/pregnancy-tracker/rhubarb.png'),
		sizeOf: 'rhubarb',
		description:
			"Your little one is close to making his debut, but he's still making the most of each day and is busy growing and developing. For example, in these final few weeks, his brain is still growing. In fact, his brain can grow as much as one third in size in the time when week 35.",
	},
	{
		weekId: 39,
		imageUrl: require('../assets/icons/pregnancy-tracker/small-watermelon.png'),
		sizeOf: 'small-watermelon',
		description:
			"Your baby's lungs and brain are still developing and will continue to develop after she's born. In fact, her brain won't reach its full size for about another two years, and the lungs might not be mature until around the age of 3.",
	},
	{
		weekId: 40,
		imageUrl: require('../assets/icons/pregnancy-tracker/pumpkin.png'),
		sizeOf: 'pumpkin',
		description:
			"Your baby's head has likely dropped lower into your pelvis, and his body is curled up tightly. He doesn't have much choice — it's pretty crowded in there.",
	},
	{
		weekId: 41,
		imageUrl: require('../assets/icons/pregnancy-tracker/pumpkin.png'),
		sizeOf: 'pumpkin',
		description:
			'At week 41 of pregnancy, you might be dying out of the desire to give birth and see your baby. But rest assured that plenty of moms-to-be go past their due date and everything turns out just fine.',
	},
	{
		weekId: 42,
		imageUrl: require('../assets/icons/pregnancy-tracker/pumpkin.png'),
		sizeOf: 'pumpkin',
		description:
			"When a pregnancy lasts for 42 weeks or more it is referred to as a post-term pregnancy. While not many studies exist that prove why some women's pregnancy lasts for 42 weeks, medical experts believe that factors such as hormones, genetics, and even obesity can be the cause.",
	},
];

export { dueDate };
