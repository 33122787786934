import { useEffect, useState } from 'react';
import { ArrowLeft } from 'react-feather';
import { useHistory, useParams } from 'react-router-dom';
import { LabRequestProperties } from '../../../../types/labRequest.type';
import { Button } from '../../../Button/Button/Button';
import Separator from '../../../Separator';
import PreviewDocument from '../../Components/PreviewDocument';
import DetailLabRequestHistory from './DetailLabRequestHistory';
import ListLabRequestHistory from './ListLabRequestHistory';
import { ApiResponseDefault } from '../../../../types/apiResponse.type';
import { httpRequest } from '../../../../helpers/api';

export default function MainLabRequestHistory() {
	const history = useHistory();

	// get document to detail id
	const { docId } = useParams<any>();
	console.log(docId);

	const [step, setStep] = useState(0);
	const [title, setTitle] = useState('Lab Request History');

	const [selectedLabRequest, setSelectedLabRequest] =
		useState<LabRequestProperties>();

	function nextStep() {
		const _step = step + 1;
		if (_step === 1) {
			setTitle('Detail');
			setStep(_step);
		} else if (_step === 2) {
			setTitle('Result');
			setStep(_step);
		}
	}

	function onBack() {
		const _step = step - 1;
		if (_step >= 0) {
			if (_step === 0) {
				setTitle('Lab Request History');
				history.push('/app/rx-and-lab-request/lab-request-history/');
			} else if (_step === 1) {
				setTitle('Detail');
			}
			setStep(_step);
		} else {
			history.goBack();
		}
	}

	function createLabReq() {
		history.push('/app/rx-and-lab-request/create-lab-request');
	}

	async function goToDetailLabRequest() {
		const response = await httpRequest.get<
			ApiResponseDefault<LabRequestProperties>
		>(`lab-requests/${docId}`);
		setSelectedLabRequest(response.data.payload);
		nextStep();
	}

	useEffect(() => {
		if (docId) {
			goToDetailLabRequest();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [docId]);

	return (
		<div className="flex-1 flex flex-col">
			<div className="relative px-6 py-5.5 flex items-center">
				<button
					onClick={onBack}
					className="border-none bg-transparent p-0 absolute flex items-center gap-x-1 cursor-pointer"
				>
					<ArrowLeft width={20} height={20} />
					<p className="m-0 font-bold text-3.5">Back</p>
				</button>
				<h1 className="font-semibold text-6 m-0 w-full text-center">{title}</h1>
				{step === 0 && (
					<div className="w-24 absolute right-6">
						<Button type="SOLID" onClick={createLabReq}>
							Create
						</Button>
					</div>
				)}
			</div>
			<Separator />
			{step === 0 ? (
				<ListLabRequestHistory
					setSelectedLabRequest={setSelectedLabRequest}
					nextStep={nextStep}
				/>
			) : step === 1 ? (
				<DetailLabRequestHistory
					labRequest={selectedLabRequest!}
					nextStep={nextStep}
				/>
			) : step === 2 ? (
				<PreviewDocument
					docId={selectedLabRequest!.labRequestId!}
					type="lab-request"
					buttonType="none"
				/>
			) : undefined}
		</div>
	);
}
