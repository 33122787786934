import React from 'react';
import styled from 'styled-components';
import AppLayout from '../../../layout/AppLayout';
import Bmi from '../../../components/AppCalculator/Bmi/IndexBmi';

const activePath = '/app/medical-calculator';

export default function MedicalCalculatorBmi() {
	return (
		<AppLayout activePath={activePath}>
			<ScreenWrapper>
				<Bmi />
			</ScreenWrapper>
		</AppLayout>
	);
}

const ScreenWrapper = styled.div`
	border-radius: 12px;
	overflow: hidden;
`;
