import React, { useMemo, useState } from 'react';
import { Layout, Modal, Typography, Space } from 'antd';
import AppFooter from './AppFooter';
import AppHeader from './AppHeader';
import Sidebar from './Sidebar';
import Notification from './Notification';
import { ArticleProps } from '../types/article.type';
import { useLocation } from 'react-router-dom';
import SidebarArticleWithFunction from '../components/Webapp/Articles/SidebarArticleWithFunction';
import AlertDoctorKYCStatus from '../components/Alert/AlertDoctorKYCStatus';

const { Content } = Layout;

type Props = {
	children?: React.ReactNode;
	activePath?: string;
	relatedArticles?: ArticleProps[];
};
const { Title, Text } = Typography;
const AppLayout = React.forwardRef<HTMLElement, Props>(
	({ children, activePath }, ref) => {
		const [showComingSoon, setShowComingSoon] = useState(false);

		const isInsideWebapp = useMemo(
			() => activePath?.startsWith('/app'),
			[activePath],
		);
		const isInsideConsultation = useMemo(
			() => activePath?.startsWith('/app/consultation'),
			[activePath],
		);
		const location = useLocation();

		let excludeActivePath = [
			'/app/promo-deals',
			// '/app/medical-event/id',
			// '/app/voucher/id',
			// '/app/own-content',
			// '/app/referral',
		];

		return (
			<React.Fragment>
				<Layout ref={ref} className="min-w-screen min-h-screen">
					<AppHeader activePath={activePath} isInsideWebapp={isInsideWebapp} />

					<Content className={isInsideWebapp ? 'bg-gray-10' : undefined}>
						<div
							className={isInsideWebapp ? 'webapp-content-layout' : undefined}
						>
							{isInsideWebapp && !isInsideConsultation && (
								<Sidebar activePath={activePath} />
							)}
							<div className={isInsideWebapp ? 'webapp-content' : undefined}>
								{isInsideWebapp && <AlertDoctorKYCStatus />}
								{children}
							</div>
							{isInsideWebapp &&
								!isInsideConsultation &&
								!excludeActivePath.includes(activePath || '') && (
									<div
										className="flex flex-col sticky top-6 h-fit"
										style={{ rowGap: 32, width: 350 }}
									>
										<Notification />
										<SidebarArticleWithFunction />
									</div>
								)}
						</div>
					</Content>
					{!isInsideWebapp && !isInsideConsultation && (
						<AppFooter setShowComingSoon={setShowComingSoon} />
					)}
				</Layout>

				<Modal
					centered={true}
					open={showComingSoon}
					footer={null}
					onCancel={() => setShowComingSoon(false)}
				>
					<div className="center modal-padding">
						<Space direction="vertical">
							<img className="logo-large" src="/Logo.svg" alt="Medeasy Logo" />
							<Title level={3} className="no-margin">
								Coming Soon
							</Title>
							<Text>
								Download the Medeasy app on your smartphone to access complete
								features and receive rewards.
							</Text>
						</Space>
					</div>
				</Modal>
			</React.Fragment>
		);
	},
);

export default AppLayout;
