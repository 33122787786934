import React, { useState } from 'react';
import { Modal, Button, Typography } from 'antd';
import styled from 'styled-components';
import BmiCalculatorModalDesc from '../assets/BmiCalculatorModalDesc.svg';

const { Title, Text } = Typography;

const whatisbmi =
	'Body Mass Index is a way to calculate ideal body weight based on height and weight. It is also distinguished by age.';

const whatisbmiCalculator =
	'BMI Calculator is a tool to identify whether your weight is in the ideal category or not. This calculator can be used by anyone aged 20 years and over. Please note that this BMI Calculator cannot be applied to pregnant women and children due to different BMI standards.';

const notesBmiData = [
	'Normal BMI is within the range of 18.5 - 24.9',
	'If your BMI more than 24.9, then you are overweight',
	'If your BMI under 18.5, then you are underweight',
	'If your BMI reached 30, you should immediately take treatment to prevent the onset of disease',
	'BMI Calculator cannot be applied to pregnant and children because of different BMI standardization',
];

export default function ModalBmiCalculator() {
	const [visible, setVisible] = useState<boolean>(true);

	const closeHandler = () => {
		setVisible(false);
	};

	const notesBmiCalculator = notesBmiData.map((note, index) => {
		return (
			<ul key={index} style={NoteItemStyles}>
				<li
					style={{
						fontSize: 28,
						marginTop: -14,
					}}
				></li>
				<Text style={{ ...TextStyles, marginLeft: -8 }}>{note}</Text>
			</ul>
		);
	});

	return (
		<>
			<Modal
				// title="BMI Calculator"
				open={visible}
				closable={false}
				footer={null}
				bodyStyle={{ padding: 24 }}
				width={464}
				mask={false}
			>
				<Wrapper>
					<HeaderContainer>
						<img
							style={{ width: 68 }}
							src={BmiCalculatorModalDesc}
							alt="React Logo"
						/>
						<Title level={5} style={{ ...TitleStyles, marginBottom: 0 }}>
							BMI Calculator
						</Title>
					</HeaderContainer>
					<WhatisBmiContainer>
						<Title level={5} style={{ ...TitleStyles, fontSize: 18 }}>
							What is BMI?
						</Title>
						<Text style={TextStyles}>{whatisbmi}</Text>
					</WhatisBmiContainer>
					<WhatisBmiCalculatorContainer>
						<Title level={5} style={{ ...TitleStyles, fontSize: 18 }}>
							What is BMI Calculator?
						</Title>
						<Text style={TextStyles}>{whatisbmiCalculator}</Text>
					</WhatisBmiCalculatorContainer>
					<NotesBmiCalculatorContainer>
						<Title level={5} style={{ ...TitleStyles, fontSize: 18 }}>
							Things to be noted on the BMI Calculator
						</Title>
						{notesBmiCalculator}
					</NotesBmiCalculatorContainer>
					<FooterContainer>
						<Button
							style={{ width: 208, borderRadius: 12 }}
							size="large"
							type="primary"
							onClick={closeHandler}
						>
							Close
						</Button>
					</FooterContainer>
				</Wrapper>
			</Modal>
		</>
	);
}

const TitleStyles: React.CSSProperties = {
	// border: "1px solid black",
	display: 'block',
	fontSize: 24,
	fontWeight: 600,
	margin: '0 auto',
	marginBottom: 16,
};

const TextStyles: React.CSSProperties = {
	fontSize: 16,
	fontWeight: 400,
	color: '#777777',
	letterSpacing: 0.1,
	display: 'block',
	lineHeight: '1.6',
};

const NoteItemStyles: React.CSSProperties = {
	// border: "1px solid black",
	margin: 0,
	display: 'flex',
	alignItems: 'flex-start',
	color: '#D81F64',
	padding: 0,
	paddingLeft: 24,
	overflow: 'hidden',
	marginBottom: 2,
};

const Wrapper = styled.div`
	// border: 1px solid red;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 24px;
	justify-content: space-between;
`;

const HeaderContainer = styled.div`
	// border: 1px solid black;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 24px;
	// height: 10px;
`;

const WhatisBmiContainer = styled.div`
	// border: 1px solid black;
	// height: 10px;
`;

const WhatisBmiCalculatorContainer = styled.div`
	// border: 1px solid black;
	// height: 10px;
`;

const NotesBmiCalculatorContainer = styled.div`
	// border: 1px solid black;
	// height: 100px;
`;

const FooterContainer = styled.div`
	// border: 1px solid black;
	display: flex;
	position: relative;
	width: 100%;
	justify-content: flex-end;
	left: 12px;
	top: 12px;
`;
