import { message } from 'antd';
import { httpRequest } from '../helpers/api';
import { getErrorMessage } from '../helpers/errorHandler';
import {
	ApiResponseDefault,
	ApiResponsePagination,
} from '../types/apiResponse.type';
import { generateQueryString } from '../helpers/generateQueryString';
import { ApiResponseSuccess, ApiPaginationResults } from '../types/api.type';
import { RxPrescriptionProperties } from '../types/rxPrescription.type';

export async function apiGetListRxPrescriptionByConsultation(
	transactionConsultationId: string,
): Promise<ApiPaginationResults<RxPrescriptionProperties> | null> {
	const res = await httpRequest.get<
		ApiResponseDefault<ApiPaginationResults<RxPrescriptionProperties>>
	>(
		'/rx-prescriptions' +
			generateQueryString({
				transactionConsultationIds: [transactionConsultationId],
			}),
	);
	if (res && res.data && res.data.payload) {
		return res.data.payload;
	} else {
		return null;
	}
}

export async function apiGetRxPrescriptions(
	transactionConsultationIds: string[],
) {
	if (!transactionConsultationIds) return;

	try {
		const res = await httpRequest.get<
			ApiResponsePagination<RxPrescriptionProperties>
		>(
			`rx-prescriptions?transactionConsultationIds=${transactionConsultationIds.join(
				',',
			)}`,
		);
		return res.data.payload.results;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}

export async function apiGetRxPrescription(rxPrescriptionId: string) {
	try {
		const res = await httpRequest.get<
			ApiResponseDefault<RxPrescriptionProperties>
		>(`rx-prescriptions/${rxPrescriptionId}`);
		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}

export async function apiCreateRxPrescription(data: RxPrescriptionProperties) {
	try {
		const res = await httpRequest.post<
			ApiResponseDefault<RxPrescriptionProperties>
		>(`rx-prescriptions`, data);
		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}

export async function apiUpdateRxPrescription(
	rxPrescriptionId: string,
	data: RxPrescriptionProperties,
) {
	try {
		const res = await httpRequest.patch<
			ApiResponseDefault<RxPrescriptionProperties>
		>(`rx-prescriptions/${rxPrescriptionId}`, data);
		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}

export async function apiDeleteRxPrescription(rxPrescriptionId: string) {
	try {
		const res = await httpRequest.delete<
			ApiResponseDefault<{ isSuccess: boolean }>
		>(`rx-prescriptions/${rxPrescriptionId}`);
		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}
