import { PartnerProperties } from '../types/partner.type';

export type Props = {
	partnerId: string;
	title: string; // 'Medeasy App';
	type: 'telemedicine' | 'consult-now' | 'appointment'; // ;
	isEnabled?: boolean; // if telemedicine and consult now
	isOnline?: boolean; // if telemedicine and consult now
	location?: string; // if appointment
	price: number;
	availableDay: number[];
	schedules: {
		scheduleId: string;
		weekday: number;
		startAt: Date | string;
		endAt: Date | string;
	}[];
};

export type PracticeDataItem = {
	partnerId: string;
	partner?: PartnerProperties;
	title: string; // 'Medeasy App';
	type: 'telemedicine' | 'consult-now' | 'appointment'; // ;
	isEnabled?: boolean;
	isOnline?: boolean; // if telemedicine and consult now
	location?: string; // if appointment
	price: number;
	availableDay: number[];
	schedules: {
		scheduleId: string;
		weekday: number;
		startAt: Date | string;
		endAt: Date | string;
	}[];
};

export const listDefaultClinics: Props[] = [
	{
		partnerId: 'consult-now',
		title: 'Medeasy App',
		type: 'consult-now',
		isEnabled: false,
		isOnline: true,
		price: 0,
		availableDay: [],
		schedules: [],
	},
	{
		partnerId: 'telemedicine',
		title: 'Medeasy App',
		type: 'telemedicine',
		isEnabled: false,
		isOnline: true,
		price: 0,
		availableDay: [],
		schedules: [],
	},
];
