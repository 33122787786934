import { isNumber } from 'lodash';
import { useEffect, useState } from 'react';
import { DrugMedicineProperties } from '../../../../../types/drugMedicine';
import {
	initialRxPrescriptionItem,
	RxPrescriptionItem,
} from '../../../../../types/rxPrescription.type';
import { Button } from '../../../../Button/Button/Button';
import CustomScrollDiv from '../../../../CustomScrollDiv';
import FormInput, { EFormType } from '../../../../Form/FormInput2';
import SidebarMenuHeader from '../Components/SidebarMenuHeader';
import { EPrescriptionState } from './SidebarPrescription';

interface Props {
	medicine?: DrugMedicineProperties;
	isSelectFromList?: boolean;
	onNext: ({ state, dto }: { state: EPrescriptionState; dto?: any }) => void;
	onBack: () => void;
}

export default function SidebarPrescriptionMedicineDetail(props: Props) {
	const [prescriptionItem, setPrescriptionItem] = useState<RxPrescriptionItem>(
		initialRxPrescriptionItem,
	);

	useEffect(() => {
		if (!props.medicine) return;

		setPrescriptionItem((old) => ({
			...old,
			drugMedicineDetail: props.medicine!,
			drugMedicineId: props.medicine!.drugMedicineId!,
		}));
	}, [props.medicine]);

	function onSave() {
		props.onNext({
			state: EPrescriptionState.LIST_MEDICINES,
			dto: {
				prescriptionItems: [prescriptionItem],
			},
		});
	}

	return (
		<div className="w-full flex flex-col h-full">
			<SidebarMenuHeader onBack={props.onBack} title="Add Medicine" />
			<CustomScrollDiv className="flex overflow-auto w-full flex-1 min-h-0">
				<div className="w-full">
					<FormInput
						label="Name"
						value={prescriptionItem.drugMedicineDetail.drugMedicineName}
						onChange={
							!props.isSelectFromList
								? (val) =>
										setPrescriptionItem((old) => ({
											...old,
											drugMedicineDetail: {
												...old.drugMedicineDetail,
												drugMedicineName: val,
											},
										}))
								: undefined
						}
						inputClassName="mt-1 font-medium text-5"
						withoutBorder={props.isSelectFromList}
						withoutHorizontalPadding={props.isSelectFromList}
						withoutVerticalPadding={props.isSelectFromList}
					/>
					<FormInput
						label="Dosage"
						value={prescriptionItem.drugMedicineDetail.dose}
						onChange={
							!props.isSelectFromList
								? (val) =>
										setPrescriptionItem((old) => ({
											...old,
											drugMedicineDetail: {
												...old.drugMedicineDetail,
												dose: val,
											},
										}))
								: undefined
						}
						withoutBorder={props.isSelectFromList}
						withoutHorizontalPadding={props.isSelectFromList}
						withoutVerticalPadding={props.isSelectFromList}
					/>
					<FormInput
						label="Quantity"
						value={prescriptionItem.quantity}
						onChange={(val) => {
							const qty = Number(val);
							if (isNumber(qty) && !isNaN(qty)) {
								setPrescriptionItem((old) => ({ ...old, quantity: qty }));
							}
						}}
					/>
					<FormInput
						label="Sig."
						type={EFormType.MULTI_LINE}
						rows={4}
						value={prescriptionItem.sig}
						onChange={(val) =>
							setPrescriptionItem((old) => ({ ...old, sig: val }))
						}
					/>
				</div>
			</CustomScrollDiv>
			<div className="py-2 px-4">
				<Button
					onClick={onSave}
					type="SOLID"
					isDisable={
						!prescriptionItem.quantity ||
						!prescriptionItem.sig ||
						!prescriptionItem.drugMedicineDetail.dose ||
						!prescriptionItem.drugMedicineDetail.drugMedicineName
					}
				>
					Save
				</Button>
			</div>
		</div>
	);
}
