import { Button, Row, Typography } from 'antd';
import COLORS from '../../../assets/globalColors';

const { Text } = Typography;

type Props = {
	referralCode: string;
};

export default function SubmittedReferral({ referralCode }: Props) {
	return (
		<>
			<Row>
				<Text style={{ fontSize: 16, marginBottom: 4 }}>
					You have used the Referral Code, Your Referral Code is
				</Text>
			</Row>
			<Row>
				<Button
					style={{
						background: COLORS.ash_300,
						width: '100%',
						height: 60,
						borderRadius: 12,
					}}
					disabled
				>
					<Text
						style={{
							color: COLORS.black,
							fontSize: 26,
							fontWeight: 600,
						}}
					>
						{referralCode}
					</Text>
				</Button>
			</Row>
		</>
	);
}
