export default function Separator(props: {
	className?: string;
	style?: Object;
}) {
	return (
		<div
			className={
				'h-px w-full bg-ash-400' +
				(props.className ? ' ' + props.className : '')
			}
			style={props.style}
		/>
	);
}
