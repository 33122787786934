import { Col, Divider, Image, Row, Typography } from 'antd';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import COLORS from '../../../assets/globalColors';
import AppLayout from '../../../layout/AppLayout';
import DEFAULT_IMAGE from '../../../assets/default-image.jpeg';
import RenderHTML from '../../../components/RenderHTML';
import useFetchList from '../../../hooks/useFetchList';
import { BannerProps } from '../../../types/banner.type';
import SidebarArticle from '../../../components/Webapp/Articles/SidebarArticle';
import BottomPromoAndDeals from '../../../components/Webapp/Articles/BottomPromoAndDeals';
import PageHeader from '../../../components/PageHeader';
import { useAuth } from '../../../context/auth.context';

const activePath = '/promo-deals';

export default function OwnContent() {
	const history = useHistory();
	const { isDoctor } = useAuth();
	const content: BannerProps = (history?.location?.state as any)?.content;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (!content) {
			history.goBack();
			return;
		}
	}, [content]);

	const { data: banners, isLoading: isLoadingBanner } =
		useFetchList<BannerProps>({
			endpoint: '/banners',
			//   limit: 3,
			initialQuery: {
				isPublished: true,
				target: isDoctor ? 'DOCTOR' : 'PATIENT',
			},
		});

	return (
		<AppLayout activePath={'/own-content'}>
			<div className={'container mt-12'}>
				{/* {isLoggedIn && (
          <>
            <PageHeader title="" onClick={() => history.goBack()} />
            <Divider style={{ marginTop: 0 }} />
          </>
        )} */}
				<Row gutter={[24, 24]} style={{ marginBottom: 100 }}>
					<Col span={24} lg={16}>
						<Typography.Title
							level={4}
							style={{ color: COLORS.gray_100 }}
							className="font-semibold"
						>
							{content?.title}
						</Typography.Title>
						<Image
							src={content?.bannerImageUrl}
							alt={content?.title}
							style={{ borderRadius: 10 }}
							fallback={DEFAULT_IMAGE}
							preview={false}
							width="100%"
							height={350}
						/>
						<div style={{ marginTop: 30, marginBottom: 30 }}>
							<RenderHTML content={content.content || ''} />
						</div>
						{/* {isLoggedIn && (
              <BottomPromoAndDeals
                isLoading={isLoadingBanner}
                banners={banners}
              />
            )} */}
					</Col>
					<Col span={24} lg={8}>
						<div className="top-27 sticky">
							<SidebarArticle />
						</div>
					</Col>
				</Row>
			</div>

			{/* {!isLoggedIn && (
        <BottomPromoAndDeals isLoading={isLoadingBanner} banners={banners} />
      )} */}
			<BottomPromoAndDeals isLoading={isLoadingBanner} banners={banners} />
		</AppLayout>
	);
}
