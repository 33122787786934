import { useEffect, useMemo, useState } from 'react';
import AppLayout from '../../../layout/AppLayout';
import COLORS from '../../../assets/globalColors';
import {
	iconAppointment,
	iconPersonalEvent,
	iconTelemedicine,
	iconInformationCircle as IconInformationCircle,
} from '../../../assets/icons';
import './styles.less';
import clsx from 'clsx';
import FormPersonal from './FormPersonal';
import FormTelemedicine from './FormTelemedicine';
import FormInPersoneAppointment from './FormInPersonAppointment';
import { useHistory, useParams } from 'react-router-dom';
import { apiGetPersonalEvent } from '../../../api/personalEvent';
import { apiGetPersonalConsultation } from '../../../api/personalConsultation';
import { useAuth } from '../../../context/auth.context';
import { EConsultationType } from '../../../types/transactionConsultation.type';
import {
	AddScheduleProps,
	initialAddSchedulePersonalAppointmentProps,
	initialAddSchedulePersonalProps,
	initialAddScheduleTelemedicineProps,
} from '../../../types/addSchedule.type';
import { DoctorSecretaryProperties } from '../../../types/doctorSecretaries.type';
import { httpRequest } from '../../../helpers/api';
import { ApiResponsePagination } from '../../../types/apiResponse.type';
import { Row, Select, Typography, message } from 'antd';
import moment from 'moment';
import ToggleBtn from '../../../components/ToggleBtn';
import { validateAge, validateName } from '../../../helpers/validation';
import { Button } from '../../../components/Button/Button/Button';
import axios from 'axios';
import _ from 'lodash';
import { title } from 'process';

const { Text } = Typography;
const { Option } = Select;

export default function AddSchedule() {
	const activePath = '/app/schedule/new';

	const { id, type } = useParams() as { id: string; type: string };

	const history = useHistory();

	const { user, isDoctor, isSecretary } = useAuth();

	const [activeTab, setActiveTab] = useState<number>(0);
	const [dataForm, setDataForm] = useState<AddScheduleProps>(
		initialAddSchedulePersonalProps,
	);
	const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

	const [getListDoctor, setGetListDoctor] = useState<boolean>(false);
	const [listDoctor, setListDoctor] = useState<DoctorSecretaryProperties[]>();
	const [isForDoctor, setForDoctor] = useState<boolean>(false);
	const [selectedDoctor, setSelectedDoctor] = useState<string>();

	const [event, setEvent] = useState({
		title: '',
		description: '',
		startTime: '',
		endTime: '',
	});

	const fetchDetailAppointment = async () => {
		try {
			const res = await apiGetPersonalConsultation(id);
			if (res) {
				setSelectedDoctor(res.doctorId);

				type === '1'
					? setDataForm({
							startDate: moment(res.eventStartAt).format('YYYY-MM-DD'),
							startTime: moment(res.eventStartAt).format('HH-mm'),
							endTime: moment(res.eventEndAt).format('HH-mm'),
							patientName: res.metaPatient?.patientName,
							age: Number(res.metaPatient?.patientAge),
							sex: res.metaPatient?.patientGender,
							description: res.description,
							via: res.place,
							links: res.link,
					  })
					: setDataForm({
							startDate: moment(res.eventStartAt).format('YYYY-MM-DD'),
							startTime: moment(res.eventStartAt).format('HH-mm'),
							endTime: moment(res.eventEndAt).format('HH-mm'),
							patientName: res.metaPatient?.patientName,
							age: Number(res.metaPatient?.patientAge),
							sex: res.metaPatient?.patientGender,
							place: res.place,
							description: res.description,
					  });
			}
		} catch (error) {
			console.log(error);
		}
	};

	const fetchDetailPersonalEvent = async () => {
		try {
			const res = await apiGetPersonalEvent(id);

			if (res) {
				setDataForm({
					title: res.title,
					description: res.description,
					startDate: moment(res.eventStartAt).format('YYYY-MM-DD'),
					startTime: moment(res.eventStartAt).format('HH-mm'),
					endDate: moment(res.eventEndAt).format('YYYY-MM-DD'),
					endTime: moment(res.eventEndAt).format('HH-mm'),
					isBlocking: res.isBlocking,
				});
			}
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (id) {
			if (type === '1' || type === '2') {
				fetchDetailAppointment();
			}
			if (type === '0') {
				fetchDetailPersonalEvent();
			}
		}
	}, [id, type]);

	useEffect(() => {
		activeTab === 0
			? setDataForm(initialAddSchedulePersonalProps)
			: activeTab === 1
			? setDataForm(initialAddScheduleTelemedicineProps)
			: setDataForm(initialAddSchedulePersonalAppointmentProps);
	}, [activeTab]);

	const handleSubmit = async () => {
		try {
			setLoadingSubmit(true);

			if (id) {
				if (type === '0') {
					await submitPersonalEvent();
					await handleAddEvent(dataForm);
				} else if (type === '1') {
					await submitTelemedicine();
					await handleAddEvent(dataForm);
				} else if (type === '2') {
					await submitInPersonAppointment();
					await handleAddEvent(dataForm);
				}
			} else {
				if (activeTab === 0) {
					await submitPersonalEvent();
					await handleAddEvent(dataForm);
				} else if (activeTab === 1) {
					await submitTelemedicine();
					await handleAddEvent(dataForm);
				} else if (activeTab === 2) {
					await submitInPersonAppointment();
					await handleAddEvent(dataForm);
				}
			}
		} catch (e) {
			console.log('ERROR', e);
		} finally {
			setLoadingSubmit(false);
		}
	};

  const addEventToGoogleCalendar = async (event: any) => {
    const accessToken = localStorage.getItem('googleAccessToken');
    if (!accessToken) {
      console.error('Google access token not found');
      return;
    }

    const googleEvent = {
      summary: event.title,
      description: event.description,
      start: {
        dateTime: moment(event.startDate).toISOString(),
      },
      end: {
        dateTime: moment(event.endDate).toISOString(),
      },
    };

    try {
      const resp = await axios.post(
        `https://www.googleapis.com/calendar/v3/calendars/primary/events`,
        googleEvent,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      console.log('Event added to Google Calendar:', resp.data);
    } catch (error) {
      console.error('Error adding event to Google Calendar', error);
    }
  };

	const handleAddEvent = async (event: any) => {
		try {
			await addEventToGoogleCalendar(event);
			console.log('Event added to Google Calendar');
		} catch (error) {
			console.error('Error adding event:', error);
		}
	}

	const submitPersonalEvent = async () => {
		let doctor;
		if (isSecretary && isForDoctor) {
			doctor = listDoctor!.find((e) => {
				return e.doctorId === selectedDoctor;
			});
			console.log('doctor found:', doctor);
		}

		const startTime = dataForm.startTime ? dataForm.startTime : '0:0';
		const endTime = dataForm.endTime ? dataForm.endTime : '23:59';

		const endData = id
			? {
					title: dataForm.title,
					description: dataForm.description,
					eventStartAt: moment(
						dataForm.startDate + ' ' + startTime,
						'YYYY-MM-DD HH:mm',
					).toDate(),
					eventEndAt: moment(
						dataForm.endDate + ' ' + endTime,
						'YYYY-MM-DD HH:mm',
					).toDate(),
					isBlocking: dataForm.isBlocking,
					createdByUserId: user?.userId,
					metaCreatedByUser: user,
			  }
			: {
					title: dataForm.title,
					description: dataForm.description,
					eventStartAt: moment(
						dataForm.startDate + ' ' + startTime,
						'YYYY-MM-DD HH:mm',
					).toDate(),
					eventEndAt: moment(
						dataForm.endDate + ' ' + endTime,
						'YYYY-MM-DD HH:mm',
					).toDate(),
					isBlocking: dataForm.isBlocking,
					createdByUserId: user?.userId,
					metaCreatedByUser: user,
					ownerId:
						isSecretary && isForDoctor && selectedDoctor
							? doctor?.doctorId
							: user?.userId,
					metaOwner:
						isSecretary && isForDoctor && selectedDoctor
							? doctor?.doctor
							: user,
					isPrivate: true,
			  };

		console.log(endData);
		try {
			if (id) {
				const res = await httpRequest.patch('/personal-events/' + id, endData);
				message.success('Success in editing new personal event');
				console.log('SUCCESS EDIT PERSONAL EVENT');
				console.log(res);
			} else {
				const res = await httpRequest.post('/personal-events', endData);
				message.success('Success in submitting new personal event');
				console.log('SUCCESS SUBMIT PERSONAL EVENT');
				console.log(res);
			}
			history.goBack();
		} catch (e) {
			message.error('ERROR: Failed to submit new personal event');
			console.log('ERROR SUBMIT PERSONAL EVENT');
			console.log(e);
		}
	};

	const submitTelemedicine = async () => {
		let doctor;
		if (isSecretary && isForDoctor) {
			doctor = listDoctor!.find((e) => {
				return e.doctorId === selectedDoctor;
			});
			console.log('doctor found:', doctor);
		}

		const startTime = dataForm.startTime ? dataForm.startTime : '0:0';
		const endTime = dataForm.endTime ? dataForm.endTime : '23:59';

		const endData = id
			? {
					scheduledStartAt: moment(
						dataForm.startDate + ' ' + startTime,
						'YYYY-MM-DD HH:mm',
					).toDate(),
					scheduledEndAt: moment(
						dataForm.startDate + ' ' + endTime,
						'YYYY-MM-DD HH:mm',
					).toDate(),
					reasonOfConsultation: dataForm.description,
					place: dataForm.via,
					link: dataForm.links,
					description: dataForm.description,
					metaPatient: {
						patientName: dataForm.patientName,
						patientAge: dataForm.age,
						patientGender: dataForm.sex,
					},
					metaCreatedByUser: user,
			  }
			: {
					doctorId:
						isSecretary && isForDoctor && selectedDoctor
							? doctor?.doctor.doctorId
							: user?.userId,
					consultationType:
						EConsultationType.TELEMEDICINE_VIDEO_CHAT_CONSULTATION,
					scheduledStartAt: moment(
						dataForm.startDate + ' ' + startTime,
						'YYYY-MM-DD HH:mm',
					).toDate(),
					scheduledEndAt: moment(
						dataForm.startDate + ' ' + endTime,
						'YYYY-MM-DD HH:mm',
					).toDate(),
					reasonOfConsultation: dataForm.description,
					place: dataForm.via,
					link: dataForm.links,
					description: dataForm.description,
					metaPatient: {
						patientName: dataForm.patientName,
						patientAge: dataForm.age,
						patientGender: dataForm.sex,
					},
					metaCreatedByUser: user,
					metaDoctor:
						isSecretary && isForDoctor && selectedDoctor
							? doctor?.doctor
							: user,
			  };

		console.log(endData);
		try {
			if (id) {
				const res = await httpRequest.patch(
					'/transaction-consults/' + id,
					endData,
				);
				message.success('Success in editing new telemedicine');
				console.log('SUCCESS EDIT TELEMEDICINE');
				console.log(res);
			} else {
				const res = await httpRequest.post('/transaction-consults', endData);
				message.success('Success in submitting new telemedicine');
				console.log('SUCCESS SUBMIT TELEMEDICINE');
				console.log(res);
			}
			history.goBack();
		} catch (e) {
			message.error('ERROR: Failed to submit new telemedicine');
			console.log('ERROR SUBMIT TELEMEDICINE');
			console.log(e);
		}
	};

	const submitInPersonAppointment = async () => {
		let doctor;
		if (isSecretary && isForDoctor) {
			doctor = listDoctor!.find((e) => {
				return e.doctorId === selectedDoctor;
			});
			console.log('doctor found:', doctor);
		}

		const startTime = dataForm.startTime ? dataForm.startTime : '0:0';
		const endTime = dataForm.endTime ? dataForm.endTime : '23:59';

		const endData = id
			? {
					scheduledStartAt: moment(
						dataForm.startDate + ' ' + startTime,
						'YYYY-MM-DD HH:mm',
					).toDate(),
					scheduledEndAt: moment(
						dataForm.startDate + ' ' + endTime,
						'YYYY-MM-DD HH:mm',
					).toDate(),
					reasonOfConsultation: dataForm.description,
					description: dataForm.description,
					place: dataForm.place,
					metaPatient: {
						patientName: dataForm.patientName,
						patientAge: dataForm.age,
						patientGender: dataForm.sex,
					},
					metaCreatedByUser: user,
			  }
			: {
					doctorId:
						isSecretary && isForDoctor && selectedDoctor
							? doctor?.doctor?.doctorId
							: user?.userId,
					consultationType: EConsultationType.FACE_TO_FACE_CONSULTATION,
					scheduledStartAt: moment(
						dataForm.startDate + ' ' + startTime,
						'YYYY-MM-DD HH:mm',
					).toDate(),
					scheduledEndAt: moment(
						dataForm.startDate + ' ' + endTime,
						'YYYY-MM-DD HH:mm',
					).toDate(),
					reasonOfConsultation: dataForm.description,
					description: dataForm.description,
					place: dataForm.place,
					metaPatient: {
						patientName: dataForm.patientName,
						patientAge: dataForm.age,
						patientGender: dataForm.sex,
					},
					metaCreatedByUser: user,
					metaDoctor:
						isSecretary && isForDoctor && selectedDoctor
							? doctor?.doctor
							: user,
			  };

		console.log(endData);
		try {
			if (id) {
				const res = await httpRequest.patch(
					'/transaction-consults/' + id,
					endData,
				);
				message.success('Success in editing new in person');
				console.log('SUCCESS EDIT IN PERSON');
				console.log(res);
			} else {
				const res = await httpRequest.post('/transaction-consults/', endData);
				message.success('Success in submitting new in person');
				console.log('SUCCESS SUBMIT IN PERSON');
				console.log(res);
			}
			history.goBack();
		} catch (e) {
			console.log('id', id);
			message.error('ERROR: Failed to submit new in person');
			console.log('ERROR SUBMIT IN PERSON');
			console.log(e);
		}
	};

	const secretaryDoctorList = async () => {
		try {
			setGetListDoctor(true);

			const res = await httpRequest.get<
				ApiResponsePagination<DoctorSecretaryProperties>
			>('/doctor-secretaries/secretary/me');

			setListDoctor(res.data.payload.results);

			console.log('SUCCESS: Success fetch from /doctor-secretaries');
			console.log(res.data.payload.results);
		} catch (e) {
			console.log('ERROR: Failed to fetch /doctor-secretaries');
			console.log(e);
		} finally {
			setGetListDoctor(false);
		}
	};

	const renderListDoctor = () => {
		return (
			<>
				<Row style={{ paddingTop: 10, fontSize: 16 }}>
					<Text>Select Doctor</Text>
				</Row>
				<Row style={{ paddingTop: 10, paddingBottom: 10 }}>
					<Select
						showArrow
						value={selectedDoctor}
						onChange={setSelectedDoctor}
						style={{ width: '100%' }}
					>
						{listDoctor?.map((e) => {
							return (
								<Option key={e.doctorId} value={e.doctorId}>
									{e.doctor.nameOfSignature}
								</Option>
							);
						})}
					</Select>
				</Row>
			</>
		);
	};

	const renderAddForDoctor = useMemo(
		() => (
			<div className="for-doctor">
				<div className="content-container">
					<div className="title-plc">
						<h5>As Secretary</h5>
						<IconInformationCircle className="icon-information" />
					</div>
					<p>Add Schedule for Doctor</p>
				</div>
				<ToggleBtn
					classNames="toggle"
					value={isForDoctor}
					clickHandler={() => {
						setForDoctor(!isForDoctor);
						secretaryDoctorList();
					}}
				/>
			</div>
		),
		[isForDoctor],
	);

	const onChangeHandler = (val: any, field: string) => {
		setDataForm((prev: any) => ({ ...prev, [field]: val }));
	};

	function onSelectTimeStart(time: Date) {
		const pickedTime = new Date(time);
		const hours = pickedTime.getHours();
		const minutes = pickedTime.getMinutes();
		setDataForm((prev: any) => ({
			...prev,
			['startTime']: `${hours}:${minutes}`,
		}));
		onChangeHandler(hours, 'startTimeHours');
		onChangeHandler(minutes, 'startTimeMinutes');
		onChangeHandler('', 'endTime');
	}

	function onSelectTimeEnd(time: Date) {
		const pickedTime = new Date(time);
		const hours = pickedTime.getHours();
		const minutes = pickedTime.getMinutes();
		if (
			dataForm?.startTimeHours == hours &&
			dataForm?.startTimeMinutes == minutes
		) {
			return minutes + 1;
		}

		setDataForm((prev: any) => ({
			...prev,
			['endTime']: `${hours}:${minutes}`,
		}));
		onChangeHandler(hours, 'endTimeHours');
		onChangeHandler(minutes, 'endTimeMinutes');
	}

	function disabledHoursAtEndTime() {
		if (dataForm.startTime) {
			const _hours = dataForm.startTime.split(':')[0];
			const hours = parseInt(_hours, 10);
			const disabledHours = [];
			for (let i = 0; i < hours; i++) {
				disabledHours.push(i);
			}
			return disabledHours;
		} else {
			return [];
		}
	}

	function disabledMinutesAtEndTime() {
		if (dataForm.startTime && dataForm.endTimeHours) {
			const _hours = dataForm.startTime.split(':')[0];
			const hours = parseInt(_hours, 10);
			if (hours === dataForm.endTimeHours) {
				const _minutes = dataForm.startTime.split(':')[1];
				const minutes = parseInt(_minutes, 10);
				const disabledMinutes = [];
				for (let i = 0; i <= minutes; i++) {
					disabledMinutes.push(i);
				}
				return disabledMinutes;
			} else {
				return [];
			}
		} else {
			return [];
		}
	}

	const formatDate = (dates: string) => {
		const date = new Date(dates);

		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
		const formattedDate = `${year}-${month}-${day}`;
		return formattedDate;
	};

	const formatTime = (dates: string) => {
		const date = new Date(dates);

		const formattedTime = date.toLocaleString('en-US', {
			hour: '2-digit',
			minute: '2-digit',
		});

		return formattedTime;
	};

	const isDisable = useMemo(() => {
		if (id) {
			if (type === '0') {
				return !dataForm.startDate || !dataForm.endDate || !dataForm.title;
			} else if (type === '1') {
				return (
					!dataForm?.startDate ||
					!dataForm?.patientName ||
					(dataForm?.age && dataForm.age <= 0) ||
					!dataForm?.sex ||
					!dataForm?.via ||
					(!!dataForm?.patientName && !validateName(dataForm.patientName)) ||
					(!!dataForm?.age && !validateAge(dataForm.age.toString()))
				);
			} else {
				return (
					!dataForm?.startDate ||
					!dataForm?.patientName ||
					(dataForm?.age && dataForm.age <= 0) ||
					!dataForm?.sex ||
					!dataForm?.place ||
					(!!dataForm?.patientName && !validateName(dataForm.patientName)) ||
					(!!dataForm?.age && !validateAge(dataForm.age.toString()))
				);
			}
		} else {
			if (activeTab === 0) {
				return !dataForm.startDate || !dataForm.endDate || !dataForm.title;
			} else if (activeTab === 1) {
				return (
					!dataForm?.startDate ||
					!dataForm?.patientName ||
					(dataForm?.age && dataForm.age <= 0) ||
					!dataForm?.sex ||
					!dataForm?.via ||
					(!!dataForm?.patientName && !validateName(dataForm.patientName)) ||
					(!!dataForm?.age && !validateAge(dataForm.age.toString()))
				);
			} else {
				return (
					!dataForm?.startDate ||
					!dataForm?.patientName ||
					(dataForm?.age && dataForm.age <= 0) ||
					!dataForm?.sex ||
					!dataForm?.place ||
					(!!dataForm?.patientName && !validateName(dataForm.patientName)) ||
					(!!dataForm?.age && !validateAge(dataForm.age.toString()))
				);
			}
		}
	}, [dataForm, type, activeTab]);

	const dataTab = useMemo(() => {
		if (id) {
			if (type === '0') {
				return [
					{
						title: 'Personal Event',
						color: COLORS?.greenApp,
						icon: iconPersonalEvent,
					},
				];
			} else if (type === '1') {
				return [
					{
						title: 'Telemedicine',
						color: COLORS?.primary,
						icon: iconTelemedicine,
					},
				];
			} else {
				return [
					{
						title: 'In Person Appointment',
						color: COLORS?.blue,
						icon: iconAppointment,
					},
				];
			}
		} else if (isDoctor || isSecretary) {
			return [
				{
					title: 'Personal Event',
					color: COLORS?.greenApp,
					icon: iconPersonalEvent,
				},
				{
					title: 'Telemedicine',
					color: COLORS?.primary,
					icon: iconTelemedicine,
				},
				{
					title: 'In Person Appointment',
					color: COLORS?.blue,
					icon: iconAppointment,
				},
			];
		} else {
			return [
				{
					title: 'Personal Event',
					color: COLORS?.greenApp,
					icon: iconPersonalEvent,
				},
			];
		}
	}, [isDoctor, isSecretary]);

	const tab = ({ item, index, activeTab }: any) => {
		const IconItem = item?.icon;
		const styleItem = {
			bg: { backgroundColor: activeTab === index ? item?.color : '#DCDCDC' },
			border: {
				borderColor: activeTab === index ? item?.color : 'transparent',
			},
		};
		const setActive = () => setActiveTab(index);

		return (
			<div className="item-tab">
				<div
					className="item-content"
					style={styleItem?.border}
					onClick={setActive}
				>
					<div className="icon-container" style={styleItem?.bg}>
						<IconItem />
					</div>
					<p className={clsx({ inactive: activeTab !== index })}>
						{item?.title}
					</p>
				</div>
			</div>
		);
	};

	const ItemTab = ({ item, index, activeTab }: any) =>
		tab({ item, index, activeTab });

	const renderNavtop = useMemo(() => {
		return (
			<div className="nav-top">
				{dataTab?.map((item, index) => (
					<ItemTab
						key={item.title}
						item={item}
						index={index}
						activeTab={activeTab}
					/>
				))}
			</div>
		);
	}, [dataTab, activeTab]);

	const renderFormContent = useMemo(() => {
		if (id) {
			if (type === '0') {
				return (
					<FormPersonal
						dataForm={dataForm}
						onChangeHandler={onChangeHandler}
						onSelectTimeStart={onSelectTimeStart}
						onSelectTimeEnd={onSelectTimeEnd}
						disabledHoursAtEndTime={disabledHoursAtEndTime}
						disabledMinutesAtEndTime={disabledMinutesAtEndTime}
					/>
				);
			} else if (type === '1') {
				return (
					<FormTelemedicine
						dataForm={dataForm}
						onChangeHandler={onChangeHandler}
						onSelectTimeStart={onSelectTimeStart}
						onSelectTimeEnd={onSelectTimeEnd}
						disabledHoursAtEndTime={disabledHoursAtEndTime}
						disabledMinutesAtEndTime={disabledMinutesAtEndTime}
					/>
				);
			} else {
				return (
					<FormInPersoneAppointment
						dataForm={dataForm}
						onChangeHandler={onChangeHandler}
						onSelectTimeStart={onSelectTimeStart}
						onSelectTimeEnd={onSelectTimeEnd}
						disabledHoursAtEndTime={disabledHoursAtEndTime}
						disabledMinutesAtEndTime={disabledMinutesAtEndTime}
					/>
				);
			}
		}

		switch (activeTab) {
			case 1:
				return (
					<FormTelemedicine
						dataForm={dataForm}
						onChangeHandler={onChangeHandler}
						onSelectTimeStart={onSelectTimeStart}
						onSelectTimeEnd={onSelectTimeEnd}
						disabledHoursAtEndTime={disabledHoursAtEndTime}
						disabledMinutesAtEndTime={disabledMinutesAtEndTime}
					/>
				);
			case 2:
				return (
					<FormInPersoneAppointment
						dataForm={dataForm}
						onChangeHandler={onChangeHandler}
						onSelectTimeStart={onSelectTimeStart}
						onSelectTimeEnd={onSelectTimeEnd}
						disabledHoursAtEndTime={disabledHoursAtEndTime}
						disabledMinutesAtEndTime={disabledMinutesAtEndTime}
					/>
				);
			case 0:
			default:
				return (
					<FormPersonal
						dataForm={dataForm}
						onChangeHandler={onChangeHandler}
						onSelectTimeStart={onSelectTimeStart}
						onSelectTimeEnd={onSelectTimeEnd}
						disabledHoursAtEndTime={disabledHoursAtEndTime}
						disabledMinutesAtEndTime={disabledMinutesAtEndTime}
					/>
				);
		}
	}, [dataForm, activeTab]);

	return (
		<>
			<AppLayout activePath={activePath}>
				<div className="add-schedule-screen">
					<div className="header-section">
						<h4>{id ? 'Edit' : 'Add'} Schedule</h4>
						{(isDoctor || isSecretary) && renderNavtop}
					</div>
					{isSecretary && renderAddForDoctor}
					{isSecretary && isForDoctor && !getListDoctor && renderListDoctor()}
					<div className="form-plc">{renderFormContent}</div>

					<div className="pb-12">
						<Button
							type="SOLID"
							isLoading={loadingSubmit}
							onClick={handleSubmit}
							isDisable={isDisable}
						>
							{!id ? 'Submit' : 'Edit'}
						</Button>
					</div>
				</div>
			</AppLayout>
		</>
	);
}