import React, { useEffect, useMemo, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import moment from 'moment';
import useSchedulesNew from '../../hooks/useSchedulesNew';
// import clsx from "clsx";

import 'react-calendar/dist/Calendar.css';
import './styles.less';

import ItemGroupAgenda from './ItemGroup';

import { dataTypeEvent } from '../../constants';
import { useAuth } from '../../context/auth.context';
import { Spin } from 'antd';

export default function CalendarAgenda({ disabledDates, eventItems }: any) {
	const agendaRef: any = useRef();
	const { isDoctor, isSecretary, isPatient, userId } = useAuth();
	const [selectedDate, setSelectedDate] = useState<null | string>(null);
	const [upcomingIndex, setUpcomingIndex] = useState<number>(0);
	const [globalMarkedDates, setGlobalMarkedDates] = useState<any[]>([]);

	const { isLoading, fetchList } = useSchedulesNew({
		userId: userId || '',
	});

	const parseDateSelection: any = useMemo(() => {
		let parsedData: any = {},
			detailItems: any = [];

		if (Array.isArray(disabledDates)) {
			// Result: Object with Date as key and states as value
			parsedData = {
				...disabledDates.reduce((a: any, value: any) => {
					return {
						...a,
						[value]: {
							selected: false,
							disabled: true,
							disableTouchEvent: true,
						},
					};
				}, {}),
			};
		}

		if (Array.isArray(eventItems)) {
			// Iterate each category of eventItems
			for (let i = 0; i < eventItems?.length; i++) {
				let subItem = eventItems[i];
				for (let j = 0; j < subItem?.data?.length; j++) {
					let itemDate = subItem?.data?.[j]?.date;
					const isBlocking = subItem?.data?.[j]?.isBlockingEvent;
					let dots = [...(parsedData?.[itemDate]?.dots || [])];
					if (!isBlocking) {
						dots = [
							...dots,
							dataTypeEvent.find((dot: any) => dot?.type === subItem?.name),
						];
					}
					parsedData = {
						...parsedData,
						[`${itemDate}`]: {
							...parsedData[itemDate],
							selected: isBlocking ? true : false,
							marked: isBlocking ? false : true,
							selectedColor: isBlocking ? '#D5DCE1' : undefined,
							dots,
						},
					};

					let foundIndex = detailItems?.findIndex(
						(item: any) => item?.date === itemDate,
					);
					if (foundIndex !== -1) {
						detailItems[foundIndex] = {
							...detailItems?.[foundIndex],
							data: [
								...(detailItems?.[foundIndex]?.data || []),
								{
									...subItem?.data?.[j],
									settings: dataTypeEvent.find(
										(dot: any) => dot?.type === subItem?.name,
									),
								},
							],
						};
					} else {
						detailItems = [
							...detailItems,
							{
								date: itemDate,
								data: [
									...(detailItems?.data || []),
									{
										...subItem?.data?.[j],
										settings: dataTypeEvent.find(
											(dot: any) => dot?.type === subItem?.name,
										),
									},
								],
							},
						];
					}
				}
			}
		}

		detailItems = detailItems?.sort((a: any, b: any) => {
			if (a?.date < b?.date) {
				return -1;
			} else if (a?.date > b?.date) {
				return 1;
			} else {
				return 0;
			}
		});

		if (selectedDate) {
			detailItems = detailItems?.filter(
				(item: any) => item?.date >= selectedDate,
			);
			parsedData[`${selectedDate}`] = {
				...parsedData?.[`${selectedDate}`],
				selected: true,
				selectedColor: '#D62464',
			};
		}
		console.log('parsedData-====>>>>', parsedData);
		return { data: parsedData, detail: detailItems };
	}, [selectedDate, disabledDates, eventItems]);

	const parsedOnlyDates = useMemo(
		() => Object?.keys(parseDateSelection?.data),
		[parseDateSelection?.data],
	);

	const markedDates = ({ date }: any) => {
		if (
			Object?.keys(parseDateSelection?.data)?.find(
				(x) => x === moment(date).format('YYYY-MM-DD'),
			)
		) {
			let temp = Object?.keys(parseDateSelection?.data)?.find(
				(x) => x === moment(date).format('YYYY-MM-DD'),
			);
			if (!globalMarkedDates.includes(temp)) {
				globalMarkedDates.push(temp);
				globalMarkedDates.sort(function (a, b) {
					if (a < b) {
						return -1;
					} else if (b < a) {
						return 1;
					} else {
						return 0;
					}
				});
			}
			if (
				parseDateSelection?.data?.[moment(date).format('YYYY-MM-DD')]?.disabled
			) {
				return 'marked-custom disabled';
			}
			return 'marked-custom';
		}

		return '';
	};

	const addDots = () => {
		let markedDates = document.getElementsByClassName('marked-custom');
		console.log('globalMarkedDates', globalMarkedDates);
		for (let i = 0; i < markedDates?.length; i++) {
			if (markedDates[i].querySelector('.dots-container')) {
				markedDates[i].removeChild(
					markedDates[i].getElementsByClassName('dots-container')[0],
				);
			}
		}
		for (let i = 0; i < markedDates?.length; i++) {
			const dotsContainer = document.createElement('div');
			markedDates[i].appendChild(dotsContainer).classList.add('dots-container');

			if (parseDateSelection?.data?.[`${globalMarkedDates?.[i]}`]) {
				for (
					let j = 0;
					j <
					parseDateSelection?.data?.[`${globalMarkedDates?.[i]}`].dots?.length;
					j += 1
				) {
					if (
						parseDateSelection?.data?.[`${globalMarkedDates?.[i]}`].dots?.[j]
					) {
						const dots = document.createElement('span');

						if (dots) {
							dots.style.backgroundColor =
								parseDateSelection?.data?.[`${globalMarkedDates?.[i]}`].dots?.[
									j
								]?.color;
							markedDates[i]
								.querySelector('.dots-container')
								?.appendChild(dots)
								.classList.add('dots-marked');
						}
					}
				}
			}
		}
	};

	useEffect(() => {
		if (agendaRef?.current && selectedDate) {
			const found = parseDateSelection?.detail?.findIndex(
				(item: any) => item?.date === selectedDate,
			);

			const upcoming = parseDateSelection?.detail?.findIndex(
				(item: any) => item?.date > moment(new Date()).format('YYYY-MM-DD'),
			);
			if (upcoming !== -1) {
				setUpcomingIndex(upcoming);
			}

			const nextDay = parseDateSelection?.detail?.findIndex(
				(item: any) => item?.date > selectedDate,
			);

			if (found === -1) {
				if (nextDay !== -1) {
					agendaRef?.current?.scrollToIndex({
						animated: true,
						index: nextDay,
					});
				}
			} else {
				agendaRef?.current?.scrollToIndex({
					animated: true,
					index: found,
				});
			}
		}
	}, [selectedDate]);

	const selectDateHandler = async (day: any) => {
		if (!day) {
			return;
		}
		setSelectedDate(moment(day).format('YYYY-MM-DD'));
	};

	useEffect(() => {
		setSelectedDate(moment(new Date()).format('YYYY-MM-DD'));
		return () => setSelectedDate(null);
	}, []);

	useEffect(() => {
		if (eventItems || disabledDates) addDots();
	}, [eventItems, disabledDates]);

	return (
		<div className="calendar-agenda">
			<Calendar
				tileClassName={markedDates}
				onActiveStartDateChange={addDots}
				onChange={selectDateHandler}
			/>

			<div className="list-agenda">
				{parseDateSelection?.detail?.map((item: any, index: number) => (
					<ItemGroupAgenda
						item={item}
						index={index}
						upcomingIndex={upcomingIndex}
						isDoctor={isDoctor}
						isSecretary={isSecretary}
						isPatient={isPatient}
						fetchList={fetchList}
					/>
				))}

				{isLoading && (
					<div className="flex justify-center w-full py-6">
						<Spin spinning />
					</div>
				)}
			</div>
		</div>
	);
}
