import {
	Button,
	Card,
	Divider,
	message,
	Row,
	Spin,
	Tree,
	Typography,
} from 'antd';
import type { DataNode } from 'antd/es/tree';
import { useMemo, useState } from 'react';
import { Info, Search } from 'react-feather';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { apiBulkCreatePartnerDoctor } from '../../../../api/partnerDoctor';
import COLORS from '../../../../assets/globalColors';
import ModalAddClinic from '../../../../components/ClinicAndPractice/ModalAddClinic';
import EmptyList from '../../../../components/EmptyList';
import FormInput from '../../../../components/Form/FormInput2';
import PageHeader from '../../../../components/PageHeader';
import { useAuth } from '../../../../context/auth.context';
import useFetchList from '../../../../hooks/useFetchList';
import AppLayout from '../../../../layout/AppLayout';
import {
	EPartnerType,
	PartnerProperties,
} from '../../../../types/partner.type';
import { PartnerDoctorProperties } from '../../../../types/partnerDoctor.type';

const activePath = '/app/setting/clinics-and-practice/add-clinic';
const { Text, Paragraph } = Typography;

export default function AddClinicScreen() {
	const history = useHistory();
	const { userId } = useAuth();

	const [isShowModalAddClinic, setIsShowModalAddClinic] =
		useState<boolean>(false);

	const [selected, setSelected] = useState<string[]>([]); // partnerIds

	const [treeData, setTreeData] = useState<DataNode[]>([]);

	// API
	const { isLoading: isLoadingListDoctorClinic, data: listDoctorClinic } =
		useFetchList<PartnerDoctorProperties>({
			endpoint: 'partner-doctors',
			limit: 100,
			initialQuery: {
				doctorId: userId,
			},
		});

	const {
		isLoading: isLoadingListClinic,
		data: listClinic,
		changePage,
		pagination,
		search,
		setSearch,
	} = useFetchList<PartnerProperties>({
		endpoint: 'partners',
		limit: 10,
		initialQuery: {
			isPublished: 'active',
			includePartnerTypes: [
				EPartnerType.CLINIC,
				EPartnerType.HOSPITAL,
				EPartnerType.LABORATORY,
			],
		},
		pushData: true,
		primaryKey: 'partnerId',
	});

	// List Clinic Item Component
	const ListClinicItem = (clinic: PartnerProperties) => {
		const partnerName = clinic.partnerName || '-';
		const partnerAddress = clinic.address || '-';
		return (
			<>
				<Row>
					<Text style={{ fontSize: 16 }}>{partnerName}</Text>
				</Row>
				<Row>
					<Text style={{ fontSize: 14, color: '#777777' }}>
						{partnerAddress}
					</Text>
				</Row>
			</>
		);
	};

	//////
	const sort = () => {
		if (!isLoadingListClinic && !isLoadingListDoctorClinic) {
			const tempNotSelected = listClinic.filter(
				(clinic) =>
					!listDoctorClinic.find((dc) => dc.partnerId === clinic.partnerId),
			);

			// create and set treeData
			const tempTreeData = [...tempNotSelected].map((clinic) => {
				return {
					title: ListClinicItem(clinic),
					key: clinic.partnerId,
				};
			});
			setTreeData(tempTreeData);
		}
	};

	const save = async () => {
		try {
			// Filter out clinics yang dia belum punya from all selected clinics
			const partnerDoctorClinics = listDoctorClinic.map((e) => e.partnerId);
			const tempSelected = selected.filter((clinic) => {
				if (!partnerDoctorClinics.includes(clinic)) {
					return clinic;
				}
			});
			const dataWillSend = tempSelected.map((val) => ({
				partnerId: val,
				doctorId: userId || '',
			}));
			const response = await apiBulkCreatePartnerDoctor(dataWillSend);
			history.goBack();
			message.success('SUCCESS: Clinics has been added successfully');
		} catch (err) {
			message.error('ERROR: Failed to save clinics');
		}
	};

	useMemo(() => {
		sort();
	}, [listClinic, listDoctorClinic]);

	// Tree Items
	const onCheck = (checkedKeys: any, info: any) => {
		setSelected([...checkedKeys.checked]);
	};

	const ListClinics = () => {
		return (
			<CustomTree
				checkable
				selectable={false}
				checkStrictly={true}
				defaultCheckedKeys={selected}
				onCheck={onCheck}
				treeData={treeData}
			/>
		);
	};

	const ComponentAddClinicButton = () => {
		return (
			<Button
				type="ghost"
				style={{
					borderColor: '#D81F64',
					borderRadius: 12,
					fontSize: 14,
					width: 146,
					height: 42,
					position: 'absolute',
					left: '50%',
					transform: 'translate(-50%, 0)',
					marginTop: 24,
				}}
				onClick={() => setIsShowModalAddClinic(true)}
			>
				<Text style={{ fontSize: 14, fontWeight: 700, color: '#D81F64' }}>
					Add New Clinic
				</Text>
			</Button>
		);
	};

	return (
		<AppLayout activePath={activePath}>
			<Card style={{ paddingBottom: 110 }}>
				<PageHeader
					title="Add Clinic"
					onClick={() => history.goBack()}
					rightmostChild={
						<Button
							style={{
								fontSize: 14,
								width: 84,
								height: 32,
								borderRadius: 10,
							}}
							type="primary"
							onClick={save}
						>
							Save
						</Button>
					}
				/>
				<Divider />
				{(isLoadingListDoctorClinic || isLoadingListClinic) && <CustomSpin />}
				<FormInput
					prefix={
						<div className="flex">
							<Search width={20} height={20} color={COLORS.charcoal_200} />
						</div>
					}
					placeholder="Search clinic name or address"
					value={search}
					onChange={setSearch}
				/>
				{!isLoadingListDoctorClinic &&
					!isLoadingListClinic &&
					(listClinic.length === 0 ? (
						<EmptyList
							icon={<Info />}
							title="Clinic Empty"
							description="You can add clinic by yourself."
						/>
					) : (
						<>
							<ListClinics />
							<ComponentAddClinicButton />
						</>
					))}
				<ModalAddClinic
					visible={isShowModalAddClinic}
					setVisible={setIsShowModalAddClinic}
				/>
			</Card>
		</AppLayout>
	);
}

// Custom Components
const CustomSpin = styled(Spin)`
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0);
`;

const CustomTree = styled(Tree)`
	.ant-tree-treenode {
		width: 100%;
		padding: 16px 0 16px 0;
		box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
		align-items: center;
	}

	.ant-tree-list-holder-inner {
		padding: 0 0 0 20px;
	}

	.ant-tree-checkbox-inner {
		width: 20px;
		height: 20px;
		border: 2px solid #a5b2bd;
		border-radius: 3px;
	}

	.ant-tree-checkbox-inner::after {
		border-color: #d81f64;
	}

	.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
		border-color: #d81f64;
		background-color: white;
	}

	.ant-tree-switcher {
		position: absolute;
		right: 0;
	}

	.ant-tree-switcher_close .ant-tree-switcher-icon svg {
		transform: rotate(-180deg);
	}
`;
