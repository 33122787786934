type Props = {
	firstName?: string;
	middleName?: string;
	lastName?: string;
	nickName?: string;
	title?: string;
	profilePic?: string;
	profilePictureUrl?: string;
	imageUrl?: string;
	user?: Props;
};

export function getProfilePicture(profile?: Props): string | undefined {
	if (profile) {
		if (profile.profilePic) {
			return profile.profilePic;
		} else if (profile.profilePictureUrl) {
			return profile.profilePictureUrl;
		} else if (profile.imageUrl) {
			return profile.imageUrl;
		} else if (profile.user) {
			return getProfilePicture(profile.user);
		} else {
			return undefined;
		}
	} else {
		return undefined;
	}
}
