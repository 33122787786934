import { Card, Divider, Row, Typography, Button } from 'antd';
import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import PageHeader from '../../PageHeader';
import ModalInfoPregnancy from '../../Webapp/PregnancyTracker/ModalInfoPregnancy';
import PregnancyInput from '../../Webapp/PregnancyTracker/PregnancyInput';
import PregnancyResult from '../../Webapp/PregnancyTracker/PregnancyResult';

const activePath = '/app/medical-calculator';

const { Text } = Typography;

export default function PregnancyDueDate() {
	const [isModalInfoPrenancy, setisModalInfoPrenancy] = useState(true);
	const [isChecked, setChecked] = useState<any>(false);
	const history = useHistory();
	// const { userId, user } = useAuth();
	const [dataForm, setDataForm] = useState<any>({
		scanDate: new Date().toISOString(),
		weekAge: 1,
		dayAge: 0,
	});

	const handleChangeWeek = (value: string) => {
		if (typeof value !== 'string') {
			return;
		}
		setDataForm({ ...dataForm, weekAge: parseInt(value) });
	};

	const handleChangeDay = (value: string) => {
		if (typeof value !== 'string') {
			return;
		}
		setDataForm({ ...dataForm, dayAge: parseInt(value) });
	};

	const dataWeek = useMemo(() => {
		const week = Array.from({ length: 42 }, (_, i) => ({
			value: (i + 1).toString(),
			label: `${(i + 1).toString()} Week${i + 1 > 1 ? 's' : ''}`,
		}));
		return week;
	}, []);

	const dataDay = useMemo(() => {
		const day = Array.from({ length: 7 }, (_, i) => ({
			value: i.toString(),
			label: `${i.toString()} Day${i > 1 ? 's' : ''}`,
		}));
		return day;
	}, []);

	const checkHandler = () => {
		if (isChecked) {
			setDataForm({
				scanDate: new Date().toISOString(),
				weekAge: 1,
				dayAge: 0,
			});
			setChecked(false);
			return;
		}

		setChecked(true);
	};

	return (
		<>
			<Card>
				<PageHeader
					title="Pregnancy Due Date"
					onClick={() => history.goBack()}
				/>
				<Divider />
				{isChecked ? (
					<PregnancyResult pregnancyData={dataForm} />
				) : (
					<PregnancyInput
						dataDay={dataDay}
						dataWeek={dataWeek}
						handleChangeWeek={handleChangeWeek}
						handleChangeDay={handleChangeDay}
						checkHandler={checkHandler}
					/>
				)}
				<Divider />
				<Row className="justify-end">
					<Button
						type="primary"
						size="large"
						className="rounded-3 bg-primary"
						style={{ width: 180 }}
						onClick={checkHandler}
					>
						<Text style={{ fontSize: 14, fontWeight: 700, color: '#fff' }}>
							Calculate
						</Text>
					</Button>
				</Row>
			</Card>
			<ModalInfoPregnancy
				isVisible={isModalInfoPrenancy}
				setVisibility={setisModalInfoPrenancy}
			/>
		</>
	);
}
