import { useEffect, useState } from 'react';
import {
	RxPrescriptionItem,
	RxPrescriptionProperties,
} from '../../../../../types/rxPrescription.type';
import { IUser } from '../../../../../types/user.type';
import SidebarPrescriptionAddMedicine from './SidebarPrescriptionAddMedicine';
import SidebarPrescriptionListMedicines from './SidebarPrescriptionListMedicines';
import SidebarPrescriptionMedicineDetail from './SidebarPrescriptionMedicineDetail';
import SidebarPrescriptionMedicineSelected from './SidebarPrescriptionMedicineSelected';
import SidebarPrescriptionTemplateDetail from './SidebarPrescriptionTemplateDetail';

interface Props {
	transactionConsultationId?: string;
	patientProfile?: IUser;
	rxPrescription?: RxPrescriptionProperties;
	setRxPrescription: React.Dispatch<
		React.SetStateAction<RxPrescriptionProperties | undefined>
	>;
	onBack: () => void;
}

export enum EPrescriptionState {
	LIST_MEDICINES = 'LIST_MEDICINES',
	ADD_MEDICINE = 'ADD_MEDICINE',
	TEMPLATE_DETAIL = 'TEMPLATE_DETAIL',
	MEDICINE_SELECTED = 'MEDICINE_SELECTED',
	MEDICINE_DETAIL = 'MEDICINE_DETAIL',
}

export default function SidebarPrescription(props: Props) {
	const [nav, setNav] = useState<EPrescriptionState[]>([
		EPrescriptionState.LIST_MEDICINES,
	]);

	const [rxPrescription, setRxPrescription] =
		useState<RxPrescriptionProperties>();
	const [dto, setDto] = useState<any>();

	useEffect(() => {
		if (!props.rxPrescription) return;

		setRxPrescription(props.rxPrescription);
	}, [props.rxPrescription]);

	useEffect(() => {
		if (!dto?.prescriptionItems) return;

		setRxPrescription((old) => {
			if (old?.prescriptionItems) {
				const prescriptionItems = [...old.prescriptionItems];
				for (const item of dto.prescriptionItems as RxPrescriptionItem[]) {
					const index = prescriptionItems.findIndex(
						(pitem) => pitem.drugMedicineId === item.drugMedicineId,
					);
					if (index >= 0) {
						prescriptionItems[index].quantity += item.quantity;
						prescriptionItems[index].sig = item.sig;
					} else {
						prescriptionItems.push(item);
					}
				}
				return {
					...old,
					prescriptionItems,
				};
			} else {
				return { ...old, prescriptionItems: dto.prescriptionItems };
			}
		});
	}, [dto]);

	function addNav({ state, dto }: { state: EPrescriptionState; dto?: any }) {
		setDto(dto);
		setNav((oldNav) => {
			const newNav = [...oldNav];
			const index = oldNav.indexOf(state);
			if (index >= 0) {
				newNav.splice(index + 1, oldNav.length - index - 1);
			} else {
				newNav.push(state);
			}
			return newNav;
		});
	}

	function onBack(opt?: { clearDto?: boolean }) {
		if (opt?.clearDto) {
			setDto(undefined);
		}
		if (nav.length > 1) {
			setNav((old) => [...old.slice(0, -1)]);
		} else {
			props.onBack();
		}
	}

	switch (nav.at(-1)) {
		case EPrescriptionState.LIST_MEDICINES:
			return (
				<SidebarPrescriptionListMedicines
					transactionConsultationId={props.transactionConsultationId}
					patientProfile={props.patientProfile}
					rxPrescription={rxPrescription}
					setRxPrescription={setRxPrescription}
					saveRxPrescription={props.setRxPrescription}
					onNext={addNav}
					onBack={onBack}
				/>
			);
		case EPrescriptionState.ADD_MEDICINE:
			return (
				<SidebarPrescriptionAddMedicine onClick={addNav} onBack={onBack} />
			);
		case EPrescriptionState.TEMPLATE_DETAIL:
			return (
				<SidebarPrescriptionTemplateDetail
					onNext={addNav}
					onBack={onBack}
					templateRxPrescription={dto.templateRxPrescription}
				/>
			);
		case EPrescriptionState.MEDICINE_SELECTED:
			return (
				<SidebarPrescriptionMedicineSelected
					onNext={addNav}
					onBack={onBack}
					medicine={dto.selectedMedicine}
				/>
			);
		case EPrescriptionState.MEDICINE_DETAIL:
			return (
				<SidebarPrescriptionMedicineDetail
					onNext={addNav}
					onBack={onBack}
					medicine={dto.selectedMedicine}
					isSelectFromList={dto.selectFromList}
				/>
			);
	}

	return <></>;
}
