import { Check } from 'react-feather';
import { theme } from '../assets/theme';
import Separator from './Separator';

interface Props {
	steps: string[];
	activeStepIndex: number;
}

export default function Steps(props: Props) {
	return (
		<div className="relative flex items-center max-w-xl mx-auto">
			<div className="w-full relative z-10">
				<div className="flex items-center justify-between">
					{props.steps.map((step, index) => (
						<ItemStep
							key={index}
							stepNumber={index + 1}
							step={step}
							isFinished={index < props.activeStepIndex}
							isActive={index === props.activeStepIndex}
						/>
					))}
				</div>
			</div>
			<div className="absolute w-full px-2 pb-6">
				<Separator style={{ height: 2 }} />
			</div>
		</div>
	);
}

function ItemStep(props: {
	stepNumber: number;
	step: string;
	isFinished: boolean;
	isActive: boolean;
}) {
	return (
		<div>
			<div
				className={
					'mx-auto w-8 h-8 rounded-full flex items-center justify-center ' +
					(props.isFinished || props.isActive
						? 'bg-primary text-white'
						: 'bg-white text-charcoal-200 border border-solid border-charcoal-200')
				}
			>
				{props.isFinished ? (
					<Check width={18} height={18} color={theme.colors.white} />
				) : (
					props.stepNumber
				)}
			</div>
			<p
				className={
					'm-0 mt-1 text-3' +
					(!(props.isFinished || props.isActive) ? ' text-charcoal-200' : '')
				}
			>
				{props.step}
			</p>
		</div>
	);
}
