import { Col, Row, Typography, DatePicker, Select } from 'antd';
import moment from 'moment';
import React from 'react';
import './styles.less';
const { Text } = Typography;

type IProps = {
	handleChangeWeek: any;
	dataWeek: {
		label: string;
		value: string;
	}[];
	handleChangeDay: any;
	dataDay: {
		label: string;
		value: string;
	}[];
	checkHandler: () => void;
};

export default function PregnancyInput(props: IProps) {
	return (
		<>
			<Row style={{ marginBottom: 24 }}>
				<Col className="mb-3">
					<Text className="text-5 font-semibold">
						When do you have an ultrasound pregnancy scan?
					</Text>
				</Col>
				<Col span={24} className="pregnancy-date">
					<DatePicker
						className="w-full px-3 py-1"
						style={{ borderRadius: 16 }}
						defaultValue={moment()}
						format={'DD/MM/YYYY'}
						disabledDate={(current) => current.isSameOrAfter(moment())}
					/>
				</Col>
			</Row>
			<div style={{ paddingBottom: 200 }}>
				<Row className="mb-3">
					<Text className="text-5 font-semibold">
						What is the result of the ultrasound pregnancy scan?
					</Text>
				</Row>{' '}
				<Row gutter={12} className="select-wrapper">
					<Col span={12}>
						<Select
							defaultValue="1"
							onChange={props.handleChangeWeek}
							style={{
								width: '100%',
								fontSize: 16,
							}}
							size="large"
							options={props.dataWeek}
						/>
					</Col>
					<Col span={12}>
						<Select
							defaultValue="0"
							onChange={props.handleChangeDay}
							style={{
								width: '100%',
								fontSize: 16,
							}}
							size="large"
							options={props.dataDay}
						/>
					</Col>
				</Row>
			</div>
		</>
	);
}
