import { AxiosInstance } from 'axios';
import React from 'react';
import { useDebounce } from 'use-debounce';
import { httpRequest } from '../helpers/api';
import { generateQueryString } from '../helpers/generateQueryString';
import {
	IHttpResponse,
	INITIAL_PAGINATION,
	INITIAL_QUERY,
	IPagination,
	IPayloadPagination,
	IQuery,
} from '../helpers/pagination';
import _ from 'lodash';

type Props = {
	endpoint: string;
	initialQuery?: Object;
	limit?: number;
	pushData?: boolean;
	apiRequest?: AxiosInstance;
	primaryKey?: string;
};

type IFetchQuery = IQuery & { [key: string]: any };

const DEFAULT_LIMIT = 25;

export default function useFetchList<DataType>(props: Props) {
	const apiRequest: AxiosInstance = props.apiRequest || httpRequest;
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [data, setData] = React.useState<Array<DataType>>([]);
	const [pagination, setPagination] = React.useState<IPagination>({
		...INITIAL_PAGINATION,
	});
	const [query, setQuery] = React.useState<IFetchQuery>({
		...INITIAL_QUERY,
		limit: props.limit || DEFAULT_LIMIT,
		...props.initialQuery,
	});

	const [search, setSearch] = React.useState<string>();
	const [searchValue] = useDebounce(search, 500);

	const fetchList = async (newQuery?: any) => {
		try {
			setIsLoading(true);

			let objectQuery = {
				...query,
			};
			if (newQuery) {
				objectQuery = {
					...objectQuery,
					...newQuery,
				};
			}

			// console.log(objectQuery);

			if (objectQuery?.search) {
				objectQuery.search = encodeURIComponent(
					(objectQuery.search as string).trim(),
				).replace(/(_|%|\\)/g, '\\$1');
			}

			const res = await apiRequest.get<
				IHttpResponse<IPayloadPagination<DataType>>
			>(`${props.endpoint}${generateQueryString(objectQuery)}`);

			setPagination((oldVal) => {
				return {
					...oldVal,
					perPage: props.limit || DEFAULT_LIMIT,
					prev: res.data.payload.prev,
					next: res.data.payload.next,
					totalData: res.data.payload.count,
					countPage: Math.ceil(
						res.data.payload.count / (props.limit || DEFAULT_LIMIT),
					),
				};
			});

			if (props.pushData) {
				setData((prev) => {
					let list = [...prev, ...res.data.payload.results];
					if (props.primaryKey) {
						list = list.reverse();
						list = _.uniqBy(list, props.primaryKey as any).reverse();
					}
					return list;
				});
			} else {
				setData(res.data.payload.results);
			}
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	React.useEffect(() => {
		fetchList();
	}, [query]);

	React.useEffect(() => {
		setData([]);
		setQuery((e: IFetchQuery) => {
			return { ...e, search: searchValue as string };
		});
	}, [searchValue]);

	const changePage = (page: any, size?: number) => {
		setPagination((oldVal) => {
			return {
				...oldVal,
				page,
				size,
			};
		});
		setQuery((oldVal) => {
			return {
				...oldVal,
				offset: (page - 1) * (size || DEFAULT_LIMIT),
				limit: size,
			};
		});
	};

	const changeLimit = (current: number, perPage: number) => {
		setPagination((oldVal) => {
			return {
				...oldVal,
				perPage,
			};
		});

		setQuery((oldVal) => {
			return {
				...oldVal,
				limit: perPage,
				offset: 0,
			};
		});
	};

	function resetQuery() {
		setQuery({
			...INITIAL_QUERY,
			limit: props.limit || DEFAULT_LIMIT,
			...props.initialQuery,
		});
	}

	return {
		DEFAULT_LIMIT,
		isLoading,
		data,
		pagination,
		query,
		search,
		setData,
		setPagination,
		setQuery,
		setSearch,
		changePage,
		fetchList,
		setIsLoading,
		changeLimit,
		resetQuery,
	};
}
