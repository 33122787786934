import { message } from 'antd';
import { httpRequest } from '../helpers/api';
import { getErrorMessage } from '../helpers/errorHandler';
import { generateQueryString } from '../helpers/generateQueryString';
import { ApiPaginationResults, ApiResponseSuccess } from '../types/api.type';
import { TwilioConversationsResponseProperties } from '../types/waitingRoom.type';

export async function apiGetTwilioConversations(
	transactionConsultationId: string[],
) {
	try {
		const res: ApiResponseSuccess<
			ApiPaginationResults<TwilioConversationsResponseProperties>
		> = await httpRequest.get(
			'/twilio/conversations' +
				generateQueryString({
					transactionConsultationIds: [transactionConsultationId],
				}),
		);

		return res.data.payload.results;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
		console.log(error);
	}
}
