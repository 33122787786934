import { generateFullName } from '../utils/name';

type Props = {
	patientName?: string;
	doctorName?: string;

	firstName?: string;
	middleName?: string;
	lastName?: string;
	nickName?: string;

	title?: string;

	user?: Props;
};

export function getName(profile?: Props): string {
	// console.info('===> getName ===> profile', profile);
	if (profile) {
		if (profile.patientName) {
			return profile.patientName;
		} else if (profile.doctorName) {
			return profile.doctorName;
		} else if (profile.firstName || profile.middleName || profile.lastName) {
			let textName = generateFullName({
				firstName: profile.firstName,
				middleName: profile.middleName,
				lastName: profile.lastName,
			});
			if (profile.title) {
				textName += ', ' + profile.title;
			}
			return textName;
		} else if (profile.nickName) {
			return profile.nickName;
		} else if (profile.user) {
			return getName(profile.user);
		} else {
			return '';
		}
	} else {
		return '';
	}
}

export function getInitialName(profile?: Props): string {
	const name = getName(profile);
	return name
		.split(' ')
		.map((n) => n.slice(0, 1))
		.join('');
}

export function getInitialMiddleName(middleName: string) {
	return middleName
		?.split(' ')
		?.map((name) => name[0])
		?.join(' ');
}
