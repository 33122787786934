import React from 'react';
import { Col, Row, Typography } from 'antd';
import { iconBankAccount as IconBankAccount } from '../../assets/icons';
import styled from 'styled-components';

const { Text } = Typography;

const BankAccountChooseItem = ({
	item,
	selectedBankAccount,
	setSelectedBankAccount,
}: any) => {
	return (
		<Row
			key={item.userBankAccountId}
			onClick={() => setSelectedBankAccount(item)}
			style={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				padding: '12px 16px',
				border: '1px solid #DCDCDC',
				borderRadius: 8,
				marginBottom: 12,
			}}
		>
			<Col span={11} style={{ display: 'flex', alignItems: 'flex-start' }}>
				<div>
					<IconBankAccount
						width={18}
						height={14}
						style={{ marginTop: 4, marginRight: 11 }}
					/>
				</div>
				<Text style={{ fontSize: 16, fontWeight: 500 }}>{item.bankName}</Text>
			</Col>
			<Col
				span={9}
				style={{
					display: 'flex',
					flexDirection: 'column',
					marginLeft: 20,
					marginRight: 20,
				}}
			>
				<Text style={{ fontSize: 16, fontWeight: 400, color: '#777777' }}>
					{item.bankAccountNumber}
				</Text>
				<Text style={{ fontSize: 16, fontWeight: 400, color: '#777777' }}>
					{item.bankAccountOwner}
				</Text>
			</Col>
			<Col>
				{selectedBankAccount?.userBankAccountId === item.userBankAccountId ? (
					<SelectedEllips />
				) : (
					<UnselectedEllips />
				)}
			</Col>
		</Row>
	);
};

export default BankAccountChooseItem;

const SelectedEllips = styled.div`
	width: 16px;
	height: 16px;
	border: 5px solid #d81f64;
	border-radius: 10px;
`;

const UnselectedEllips = styled.div`
	width: 16px;
	height: 16px;
	border: 3px solid #a5b2bd;
	border-radius: 10px;
`;
