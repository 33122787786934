import { Card, Col, Row, Typography } from 'antd';
import React, { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import COLORS from '../assets/globalColors';
import { IconConnect, IconMuchMore, IconTrack } from '../assets/images';
import HomeHero from '../components/HomeHero';
import JoinDoctorBanner from '../components/JoinDoctorBanner';
import ScrollUp from '../components/ScrollUp';
import useWindowSize from '../hooks/useWindowSize';
import AppLayout from '../layout/AppLayout';

const chooseItem = [
	{
		title: 'Connect',
		image: <IconConnect />,
		description:
			'Conveniently book your consultations and medical work-ups with our partner doctors and clinics',
	},
	{
		title: 'Keep Track',
		image: <IconTrack />,
		description:
			'Manage all schedule of your medical appointments and other events in one place',
	},
	{
		title: 'Much More',
		image: <IconMuchMore />,
		description:
			'Medeasy will always develop features so the patients get the best service',
	},
];

const BENEFIT = [
	{
		title: 'Joining Reward',
		image: '/images/join-reward-icon.png',
		description:
			'Sign up and get more than P100 worth of voucher and reward points which you can use for purchases within Medeasy.',
	},
	{
		title: 'Member Reward',
		image: '/images/member-reward-icon.png',
		description:
			'Earn reward points by simply using Medeasy - daily log in, buying points, booking services, and sharing medical news and events',
	},
	{
		title: 'Referral Reward',
		image: '/images/referral-reward-icon.png',
		description:
			'Get rewards when a friend or family signs up and completes a transaction using your referral code',
	},
];

const SERVICES = [
	{
		title: 'Doctor’s Consultation',
		image: '/icons/icon-consultation.svg',
		description:
			'We offer fast, reliable, and confidential consultations from the comfort of your own home.',
	},
	{
		title: 'Health Support',
		image: '/icons/icon-health-support.svg',
		description:
			'Our health support provides you with valuable insights into your health and well-being.',
	},
	{
		title: 'Medical Service',
		image: '/icons/icon-medical-service.svg',

		description:
			'Our medical service allows you to choose between in-clinic or home-based medical services.',
	},
	{
		title: 'Promo and Deals',
		image: '/icons/icon-box-promo.svg',

		description:
			'You have access to a wide range of deals and promotions on your healthcare needs.',
	},
	{
		title: 'Medeasy Store',
		image: '/icons/icon-store.svg',
		isComingSoon: true,
		description:
			'Our store shall offer health and wellness products at the best prices, purchase with cash or points.',
	},
];

const { Meta } = Card;
const { Title, Text } = Typography;
const Application: React.FC = () => {
	const { isMobile } = useWindowSize();

	const rootRef = useRef<HTMLDivElement>(null);

	return (
		<AppLayout ref={rootRef} activePath={'/our-apps'}>
			<HomeHero />

			<section id="choose-medeasy" className="relative">
				<div className="container">
					<Title
						level={3}
						style={{
							color: COLORS.white,
							marginBottom: 20,
							lineHeight: '44.16px',
							zIndex: 10,
						}}
						className="center"
					>
						Why we choose Medeasy?
					</Title>
					<Row gutter={[30, 30]} style={{ marginTop: 60, padding: '0px 20px' }}>
						{chooseItem.map((item) => (
							<Col span={24} lg={8}>
								<div className="flex gap-x-4">
									<div style={{ width: 56, height: 56 }}>
										{item.image && item.image}
									</div>

									<div>
										<Typography.Text className="block text-6 font-semibold text-white">
											{item.title}
										</Typography.Text>
										<Typography.Text className="block text-3.5 text-white">
											{item.description}
										</Typography.Text>
									</div>
								</div>
							</Col>
						))}
					</Row>
				</div>
			</section>

			<section id="features-app">
				<div className="container">
					<Title
						className="center"
						level={3}
						style={{ color: '#424242', fontSize: isMobile ? 32 : 42 }}
					>
						Medeasy will always develop features so <br /> the patients get the
						best service
					</Title>
					<Row
						gutter={[28, { xs: 20, lg: 60 }]}
						justify="center"
						style={{
							marginLeft: 'auto',
							marginRight: 'auto',
							marginTop: isMobile ? 40 : 80,
						}}
					>
						{SERVICES.map((item, idx) => (
							<Col span={24} md={12} lg={8} key={idx}>
								<Card bordered={false} className="card-service">
									<img src={item.image} alt={item.title} />
									{/* <Meta title={item.title} description={item.description} /> */}
									<Typography.Text className="text-6 flex items-center gap-x-3 text-medium text-primary block mb-3">
										{item.title}{' '}
										{item.isComingSoon && (
											<span className="text-white text-2.5 bg-primary block py-1 px-2 font-semibold rounded-full">
												COMING SOON
											</span>
										)}
									</Typography.Text>
									<Typography.Text className="text-3.5 text-gray-100 block">
										{item.description}
									</Typography.Text>
								</Card>
							</Col>
						))}
					</Row>
				</div>
			</section>

			<section id="perks-and-benefit" style={{ padding: '72px 0px' }}>
				<div className="container center">
					<Title level={3} style={{ color: COLORS.black, marginBottom: 20 }}>
						Enjoy perks when you use Medeasy!
					</Title>
					<Text className="text-6" style={{ color: COLORS.gray_60 }}>
						Earn reward points upon signing up, using app features, and
						referring your friends.
					</Text>
					<Row
						gutter={[32, 52]}
						justify="center"
						className="content"
						style={{ marginLeft: 'auto', marginRight: 'auto' }}
					>
						{BENEFIT.map((item) => (
							<Col key={item.title} flex="280px">
								<Card
									bordered={false}
									className="center card-hug"
									cover={
										<img
											className="card-icon"
											alt={item.title}
											src={item.image}
										/>
									}
								>
									<Meta title={item.title} description={item.description} />
								</Card>
							</Col>
						))}
					</Row>
				</div>
			</section>

			<JoinDoctorBanner />

			<ScrollUp />
		</AppLayout>
	);
};

export default Application;
