import { useMemo, useState } from 'react';
import { DatePicker, Menu, Radio, TimePicker } from 'antd';
import clsx from 'clsx';
import moment from 'moment';
import Separator from '../Separator';
import COLORS from '../../assets/globalColors';
import {
	validateAge,
	validateBirthdate,
	validateEmail,
	validateName,
	validatePhoneNumber,
	validateUsername,
} from '../../helpers/validation';

export enum EFormType {
	SINGLE_LINE = 'SINGLE_LINE',
	MULTI_LINE = 'MULTI_LINE',
	GENDER = 'GENDER',
	DATE_PICKER = 'DATE_PICKER',
	TIME_PICKER = 'TIME_PICKER',
	TIME_PICKER_RANGE = 'TIME_PICKER_RANGE',
}

interface Props {
	label?: string;
	type?: EFormType;
	topSeparator?: boolean;
	bottomSeparator?: boolean;
	rows?: number;
	value?: any;
	placeholder?: string;
	onSelect?: (val: any) => void;
	onChange?: (val: string) => void;
	disabledInput?: boolean;
	disabled?: (date: any) => any;
	disabledHours?: () => number[];
	disabledMinutes?: () => number[];
	onChangeDate?: any;
	onChangeTime?: any;
	onChangeGender?: any;
	className?: any;
	classInput?: any;
	rangePlaceholder?: [string, string];
	rangeValue?: [any, any];
	rangeSeparator?: any;
	required?: boolean;
	validation?:
		| 'username'
		| 'email'
		| 'phone'
		| 'number-only'
		| 'alphabet-only'
		| 'birthdate';
}

const defaultYearFormat = 'YYYY-MM-DD';
const defaultTimeFormat = 'HH:mm';
const optionGender = [
	{
		key: '1',
		label: 'Male',
	},
	{
		key: '2',
		label: 'Female',
	},
];

export default function FormInput(props: Props) {
	const [isChanged, setIsChanged] = useState<boolean>(false);

	function onChange(
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) {
		props.onChange?.(event.currentTarget.value);
		if (!isChanged) {
			setIsChanged(true);
		}
	}

	const menu = (
		<Menu
			selectable
			selectedKeys={[
				`${optionGender.find((item) => item.label === props.value)?.key}`,
			]}
			onSelect={(val) => {
				props.onChangeGender?.(
					optionGender.find((item) => item.key === val.key)?.label,
				);
			}}
			items={optionGender}
		/>
	);

	const renderInput = useMemo(() => {
		switch (props.type) {
			case EFormType.MULTI_LINE:
				return (
					<textarea
						rows={props.rows}
						value={props.value}
						onChange={onChange}
						placeholder={props.placeholder}
						className={clsx(
							'block m-0 w-full text-4 border border-solid border-ash-800 rounded-4 py-2.5 px-3',
							props.classInput,
						)}
					/>
				);
			case EFormType.DATE_PICKER:
				return (
					<DatePicker
						className={props.classInput}
						placeholder={props.placeholder}
						format={'YYYY/MM/DD'}
						value={
							props.value ? moment(props.value, defaultYearFormat) : undefined
						}
						onChange={(_, dateString) => {
							props.onChangeDate?.(dateString);
							if (!isChanged) {
								setIsChanged(true);
							}
						}}
						disabledDate={props.disabled}
						onSelect={props.onSelect}
					/>
				);
			case EFormType.TIME_PICKER:
				return (
					<TimePicker
						className={props.classInput}
						placeholder={props.placeholder}
						format="HH:mm"
						value={
							props.value ? moment(props.value, defaultTimeFormat) : undefined
						}
						onChange={(_, timeString) => {
							props.onChangeDate?.(timeString);
							if (!isChanged) {
								setIsChanged(true);
							}
						}}
						onSelect={props.onSelect}
						disabledHours={props.disabledHours}
						disabledMinutes={props.disabledMinutes}
						// onSelect={(e) => console.log(e)}
						// disabledTime={(date: any) => {
						//   console.log(new Date(date));
						//   return date;
						// }}
					/>
				);
			case EFormType.TIME_PICKER_RANGE:
				return (
					<TimePicker.RangePicker
						className={props.classInput}
						placeholder={props.rangePlaceholder}
						format="HH:mm"
						value={props.rangeValue}
						onChange={(_, formatString) => {
							props.onChangeTime?.(formatString);
							if (!isChanged) {
								setIsChanged(true);
							}
						}}
						separator={props.rangeSeparator}
					/>
				);
			case EFormType.GENDER:
				return (
					<Radio.Group
						value={props.value}
						defaultValue={''}
						onChange={(e) => props.onChange?.(e.target.value)}
					>
						<Radio checked value="male">
							Male
						</Radio>
						<Radio value="female">Female</Radio>
					</Radio.Group>
				);
			case EFormType.SINGLE_LINE:
			default:
				return (
					<input
						value={props.value}
						onChange={onChange}
						disabled={props.disabledInput}
						placeholder={props.placeholder}
						className={clsx(
							'm-0 w-full text-4 border border-solid border-ash-800 rounded-4 py-2.5 px-3',
							props.classInput,
						)}
					/>
				);
		}
	}, [
		props.value,
		props.type,
		props.rows,
		props.placeholder,
		props.onChangeDate,
		props.onChangeTime,
		props.onSelect,
		props.disabled,
		props.disabledHours,
		props.disabledMinutes,
	]);

	const renderError = useMemo(() => {
		if (!props.value) {
			if (props.required && isChanged) {
				return (
					<span className="text-4" style={{ color: COLORS.red }}>
						{props.label} cannot empty
					</span>
				);
			} else {
				return null;
			}
		} else if (
			props.validation === 'username' &&
			!validateUsername(props.value)
		) {
			return (
				<span className="text-4" style={{ color: COLORS.red }}>
					Incorrect username
				</span>
			);
		} else if (props.validation === 'email' && !validateEmail(props.value)) {
			return (
				<span className="text-4" style={{ color: COLORS.red }}>
					Invalid e-mail
				</span>
			);
		} else if (
			props.validation === 'phone' &&
			validatePhoneNumber(props.value).length > 0
		) {
			return (
				<div>
					{validatePhoneNumber(props.value).map((errorMessage, key) => (
						<span key={key} style={{ color: COLORS.red }}>
							{errorMessage}
						</span>
					))}
				</div>
			);
		} else if (
			props.validation === 'number-only' &&
			!validateAge(props.value)
		) {
			return (
				<span className="text-4" style={{ color: COLORS.red }}>
					Can only contain numbers
				</span>
			);
		} else if (
			props.validation === 'alphabet-only' &&
			!validateName(props.value)
		) {
			return (
				<span className="text-4" style={{ color: COLORS.red }}>
					Cannot contain numbers or special characters
				</span>
			);
		} else if (
			props.validation === 'birthdate' &&
			validateBirthdate(props.value)
		) {
			return (
				<span className="text-4" style={{ color: COLORS.red }}>
					Minimum 14th years old
				</span>
			);
		}
	}, [props.value]);

	return (
		<>
			{props.topSeparator && <Separator style={{ margin: 0 }} />}
			<div className={clsx('py-2', props.className)}>
				{props.label && (
					<p className="m-0 text-4">
						{props.label}{' '}
						{props.required && <span style={{ color: COLORS.red }}>*</span>}
					</p>
				)}
				{renderInput}
				{renderError}
			</div>
			{props.bottomSeparator && <Separator style={{ margin: 0 }} />}
		</>
	);
}
