import FormInput, { EFormType } from '../../../../components/Form/FormInput';
import FormInput2, {
	EFormType as EFormType2,
} from '../../../../components/Form/FormInput2';
import moment from 'moment';
import './styles.less';

export default function FormTelemedicine({
	dataForm,
	onChangeHandler,
	onSelectTimeStart,
	onSelectTimeEnd,
	disabledHoursAtEndTime,
	disabledMinutesAtEndTime,
}: any) {
	return (
		<div className="form-telemedicine-event">
			<div className="flex">
				<FormInput
					required
					className="flex-1"
					classInput="w-full text-4 border border-solid border-ash-800 rounded-4 py-2.5 px-3"
					label="Date"
					placeholder="YYYY/MM/DD"
					value={dataForm?.startDate}
					type={EFormType.DATE_PICKER}
					onChangeDate={(date: any) => {
						onChangeHandler(
							date ? moment(date).format('YYYY-MM-DD') : '',
							'startDate',
						);
					}}
				/>
				<div className="flex flex-1">
					<FormInput
						className="flex-1 ml-2"
						classInput="w-full text-4 border border-solid border-ash-800 rounded-4 py-2.5 px-3"
						label="From"
						placeholder="08:00"
						value={dataForm?.startTime}
						type={EFormType.TIME_PICKER}
						onChangeTime={(time: any) => {
							console.log(time);
							onChangeHandler(
								time ? moment(time).format('HH:mm') : '',
								'startTime',
							);
						}}
						onSelect={(e) => onSelectTimeStart(e)}
					/>
					<FormInput
						className="flex-1 ml-2"
						classInput="w-full text-4 border border-solid border-ash-800 rounded-4 py-2.5 px-3"
						label="To"
						placeholder="15:00"
						value={dataForm?.endTime}
						type={EFormType.TIME_PICKER}
						onChangeTime={(time: any) => {
							onChangeHandler(
								time ? moment(time).format('HH:mm') : '',
								'endTime',
							);
						}}
						onSelect={(e) => onSelectTimeEnd(e)}
						disabledHours={() => disabledHoursAtEndTime()}
						disabledMinutes={() => disabledMinutesAtEndTime()}
					/>
				</div>
			</div>
			<FormInput
				required
				label="Patient Name"
				placeholder="Input patient name"
				value={dataForm?.patientName}
				onChange={(val) => onChangeHandler(val, 'patientName')}
				validation="alphabet-only"
			/>
			<div className="flex gap-x-2">
				<FormInput
					required
					className="flex-1"
					label="Age"
					placeholder="Input age"
					value={dataForm?.age}
					onChange={(val) => onChangeHandler(val, 'age')}
				/>
				<FormInput2
					required
					label="Sex"
					name="gender"
					type={EFormType2.ITEM_PICKER_H}
					items={['MALE', 'FEMALE']}
					value={dataForm?.sex}
					onChange={(val: string) => onChangeHandler(val, 'sex')}
				/>
			</div>
			<FormInput
				type={EFormType.MULTI_LINE}
				label="Description"
				value={dataForm?.description}
				placeholder="Ex: Respiratory complaints"
				onChange={(val) => onChangeHandler(val, 'description')}
			/>
			<FormInput
				required
				label="Via"
				placeholder="Ex: Docquityput "
				value={dataForm?.via}
				onChange={(val) => onChangeHandler(val, 'via')}
			/>
			<FormInput
				type={EFormType.MULTI_LINE}
				className="mb-4"
				label="Links"
				value={dataForm?.links}
				placeholder="Ex: Docquity.com/12145"
				onChange={(val) => onChangeHandler(val, 'links')}
			/>
		</div>
	);
}
