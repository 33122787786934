import React, { useState } from 'react';
import { Modal, Button, Typography } from 'antd';
import styled from 'styled-components';
import MensCalendarModalDesc from '../assets/MensCalendarModalDesc.svg';

const { Title, Text } = Typography;

const whatisMensCalculator =
	'The menstruation calculator determines when your next period is due based on a 28 day menstrual cycle. All you need to do is enter the date when your last menstrual bleeding began, and the calculator will tell you when to expect your next period. Menstruation typically occurs in 28 day cycles so most women get their period every 28 days. However, some women have longer cycles and may only get their period every 40 days, while other have shorter menstrual cycles and may get their periods as often as every 21 days';

export default function ModalMensCalendar() {
	const [visible, setVisible] = useState<boolean>(true);

	const closeHandler = () => {
		setVisible(false);
	};

	return (
		<>
			<Modal
				open={visible}
				closable={false}
				footer={null}
				bodyStyle={{ padding: 24 }}
				width={464}
				mask={false}
			>
				<Wrapper>
					<HeaderContainer>
						<img
							style={{ width: 68 }}
							src={MensCalendarModalDesc}
							alt="React Logo"
						/>
						<Title level={5} style={{ ...TitleStyles, marginBottom: 0 }}>
							Menstruation Calculator
						</Title>
					</HeaderContainer>
					<WhatisMensCalContainer>
						<Title level={5} style={{ ...TitleStyles, fontSize: 18 }}>
							What is Menstruation Calculator?
						</Title>
						<Text style={TextStyles}>{whatisMensCalculator}</Text>
					</WhatisMensCalContainer>
					<FooterContainer>
						<Button
							style={{ width: 208, borderRadius: 12 }}
							size="large"
							type="primary"
							onClick={closeHandler}
						>
							Close
						</Button>
					</FooterContainer>
				</Wrapper>
			</Modal>
		</>
	);
}

const TitleStyles: React.CSSProperties = {
	// border: "1px solid black",
	display: 'block',
	fontSize: 24,
	fontWeight: 600,
	margin: '0 auto',
	marginBottom: 16,
};

const TextStyles: React.CSSProperties = {
	fontSize: 16,
	fontWeight: 400,
	color: '#777777',
	letterSpacing: 0.1,
	display: 'block',
	lineHeight: '1.6',
};

const Wrapper = styled.div`
	// border: 1px solid red;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 24px;
`;

const HeaderContainer = styled.div`
	// border: 1px solid black;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 24px;
`;

const WhatisMensCalContainer = styled.div``;

const FooterContainer = styled.div`
	// border: 1px solid black;
	display: flex;
	position: relative;
	width: 100%;
	justify-content: flex-end;
	left: 12px;
	top: 12px;
`;
