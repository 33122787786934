import { useMemo, useState } from 'react';
import { ChevronDown } from 'react-feather';
import { DrugMedicineProperties } from '../../../../types/drugMedicine';
import Separator from '../../../Separator';

interface Props {
	drug: DrugMedicineProperties;
}

export default function DetailFormulary(props: Props) {
	const formularySections = useMemo(() => {
		const sections = [];
		if (props.drug) {
			for (const [key, val] of Object.entries(props.drug)) {
				let title = '';
				switch (key) {
					case 'administration':
						title = 'Administration';
						break;
					case 'adverseDrugReactions':
						title = 'Adverse Drug Reactions';
						break;
					case 'compositions':
						title = 'Compositions';
						break;
					case 'contraIndications':
						title = 'Contra Indications';
						break;
					case 'description':
						title = 'Description';
						break;
					case 'dose':
						title = 'Dose';
						break;
					case 'doseNote':
						title = 'Dose Note';
						break;
					case 'indications':
						title = 'Indications';
						break;
					case 'pregnancyCategory':
						title = 'Pregnancy Category';
						break;
				}
				if (title && typeof val === 'string' && val.length > 0) {
					sections.push({
						title,
						description: val,
					});
				}
			}
		}
		return sections;
	}, [props.drug]);

	return (
		<div>
			<div className="p-4 bg-primary-10">
				<p className="m-0 text-5 font-bold text-primary">
					{props.drug.drugMedicineName}
				</p>
			</div>
			<div className="p-3">
				{formularySections.map((formulary, index) => (
					<div key={formulary.title}>
						<ItemFormulary
							title={formulary.title}
							description={formulary.description}
						/>
						{index < formularySections.length - 1 && <Separator />}
					</div>
				))}
			</div>
		</div>
	);
}

function ItemFormulary(props: { title: string; description: string }) {
	const [isExpanded, setIsExpanded] = useState(false);

	return (
		<div className="px-4">
			<button
				onClick={() => setIsExpanded((oldState) => !oldState)}
				className="py-4 w-full block p-0 bg-transparent border-none flex items-center justify-between cursor-pointer"
			>
				<p className="m-0 font-semibold">{props.title}</p>
				<ChevronDown
					width={20}
					height={20}
					className={'transition-transform' + (isExpanded ? ' rotate-180' : '')}
				/>
			</button>
			{isExpanded && (
				<div className="pb-4">
					<p className="m-0 text-4">{props.description}</p>
				</div>
			)}
		</div>
	);
}
