// import Config from 'react-native-config';

/**
 * ======= AUTHENTICATION =======
 */
export enum EAvailableLogin {
	EMAIL = 'EMAIL',
	PHONE_OTP = 'PHONE_OTP',
	FACEBOOK = 'FACEBOOK',
	GOOGLE = 'GOOGLE',
	APPLE = 'APPLE',
}

export const AVAILABLE_LOGIN = [
	// EAvailableLogin.EMAIL,
	EAvailableLogin.PHONE_OTP,
	// EAvailableLogin.FACEBOOK,
	// EAvailableLogin.GOOGLE,
	// EAvailableLogin.APPLE, // will added in the future
];

// export const AVAILABLE_LOGIN = Config.AVAILABLE_LOGIN
//   ? Config.AVAILABLE_LOGIN.split(',')
//   : ['EMAIL'];

export const MUST_BE_LOGIN_FIRST = false;
// export const MUST_BE_LOGIN_FIRST = true;

/**
 * ======= DEFAULT =======
 */
export const DEFAULT_IMAGE = require('../assets/default-image.jpeg');
export const DEFAULT_IMAGE_USER = require('../assets/default-user.png');

export const convertTextToCapitalize = (text: string, separator: string) => {
	const arr = text.toLowerCase().split(separator);

	for (var i = 0; i < arr.length; i++) {
		arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
	}
	return arr.join(' ');
};

export const daysOfWeek = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
];
export const daysOfWeekShort = [
	'Sun',
	'Mon',
	'Tue',
	'Wed',
	'Thu',
	'Fri',
	'Sat',
];

export const DEFAULT_CURRENCY = '₱';

export const CONSULT_NOW_PRICE = 'CONSULT_NOW_PRICE';

export const PATIENT_HOME_SERVICE_FEE = 'PATIENT_HOME_SERVICE_FEE';
export const MAX_POINT_MEDICAL_SERVICE = 50;
export const MAX_POINT_CONSULTATION = 25;
export const MEDICALSERVICE_HOME_TRANSPORT_FEE =
	'MEDICALSERVICE_HOME_TRANSPORT_FEE';
