import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { EMetaType } from '../../../../types/doctorDocument.type';
import MainCreateDoctorDocument from '../CreateDoctorDocument/MainCreateDoctorDocument';
import FormCreateTemplateOwnTemplate from './FormCreateTemplateOwnTemplate';
import ListOwnTemplate from './ListOwnTemplate';

interface Params {
	docId?: string;
}

export default function MainOwnTemplate() {
	const history = useHistory();
	const { docId } = useParams<Params>();

	const [state, setState] = useState<
		'main' | 'createTemplate' | 'createOwnDocument'
	>('main');

	useEffect(() => {
		if (!docId) return;

		setState('createOwnDocument');
	}, [docId]);

	function onBack() {
		if (state === 'main') {
			history.goBack();
		} else {
			setState('main');
		}
	}
	return (
		<div>
			{state === 'main' ? (
				<ListOwnTemplate
					onClickAddTemplate={() => setState('createTemplate')}
					onBack={onBack}
					setState={setState}
				/>
			) : state === 'createOwnDocument' ? (
				<MainCreateDoctorDocument
					name="Own Template"
					docId={docId}
					type={EMetaType.OTHERS}
					onBack={onBack}
				/>
			) : state === 'createTemplate' ? (
				<FormCreateTemplateOwnTemplate onBack={onBack} />
			) : undefined}
		</div>
	);
}
