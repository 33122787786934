import React, { useMemo, useState } from 'react';
import { Card, Divider, Row, Typography, Col, Form, InputNumber } from 'antd';
import { useHistory } from 'react-router-dom';
import PageHeader from '../../../../components/PageHeader';
import AppLayout from '../../../../layout/AppLayout';

const activePath = '/app/medical-calculator';

const { Text } = Typography;

export default function DiabetesRiskHbA1C() {
	const history = useHistory();

	const [ifcc, setIfcc] = useState<number | string>();
	const [ngsp, setNgsp] = useState<number | string>();
	const [dl, setDl] = useState<number | string>();
	const [l, setL] = useState<number | string>();

	const data = useMemo(
		() => [
			{
				title: 'HbA1C',
				input: [
					{
						label: 'in mmol/mol (IFCC)',
						field: 'ifcc',
					},
					{
						label: 'in % (DCCT/NGSP)',
						field: 'ngsp',
					},
				],
			},
			{
				title: 'Average Blood Glucose',
				input: [
					{
						label: 'in mg/dL',
						field: 'dl',
						max: 414,
					},
					{
						label: 'in mmol/L',
						field: 'l',
					},
				],
			},
		],
		[],
	);

	const onChangeIffc = (ifcc: any) => {
		setIfcc(ifcc);
		let ngspFormula = (0.0915 * Number(ifcc) + 2.15).toFixed(1);
		setNgsp(Number(ngspFormula) < 0 ? '' : ngspFormula.toString());
		let dlFormula = (28.7 * Number(ngspFormula) - 46.7).toFixed(1);
		setDl(Number(dlFormula) < 0 ? '' : dlFormula.toString());
		let lFormula = (Number(dlFormula) * 0.0555).toFixed(1);
		setL(Number(lFormula) < 0 ? '' : lFormula.toString());
	};

	const onChangeNgsp = (ngsp: any) => {
		setNgsp(ngsp);
		let ifccFormula = (10.93 * Number(ngsp) - 23.5).toFixed(1);
		setIfcc(Number(ifccFormula) < 0 ? '' : ifccFormula.toString());
		let dlFormula = (28.7 * Number(ngsp) - 46.7).toFixed(1);
		setDl(Number(dlFormula) < 0 ? '' : dlFormula.toString());
		let lFormula = (Number(dlFormula) * 0.0555).toFixed(1);
		setL(Number(lFormula) < 0 ? '' : lFormula.toString());
	};

	const onChangeDl = (dl: any) => {
		setDl(dl);
		let ngspFormula = ((46.7 + Number(dl)) / 28.7).toFixed(1);
		setNgsp(Number(ngspFormula) < 0 ? '' : ngspFormula.toString());
		let ifccFormula = (10.93 * Number(ngspFormula) - 23.5).toFixed(1);
		setIfcc(Number(ifccFormula) < 0 ? '' : ifccFormula.toString());
		let lFormula = (Number(dl) * 0.0555).toFixed(1);
		setL(Number(lFormula) < 0 ? '' : lFormula.toString());
	};

	const onChangeL = (l: any) => {
		setL(l);
		let dlFormula = (Number(l) / 0.0555).toFixed(1);
		setDl(Number(dlFormula) < 0 ? '' : dlFormula.toString());
		let ngspFormula = ((46.7 + Number(dlFormula)) / 28.7).toFixed(1);
		setNgsp(Number(ngspFormula) < 0 ? '' : ngspFormula.toString());
		let ifccFormula = (10.93 * Number(ngspFormula) - 23.5).toFixed(1);
		setIfcc(Number(ifccFormula) < 0 ? '' : ifccFormula.toString());
	};

	const handleChangeText = (field: string, val: any) => {
		switch (field) {
			case 'ifcc':
				if (val <= 150) {
					onChangeIffc(val);
				} else {
					setNgsp('');
					setDl('');
					setL('');
				}
				break;
			case 'ngsp':
				if (val <= 16) {
					onChangeNgsp(val);
				} else {
					setIfcc('');
					setDl('');
					setL('');
				}
				break;
			case 'dl':
				if (val <= 414) {
					onChangeDl(val);
				} else {
					setNgsp('');
					setIfcc('');
					setL('');
				}
				break;
			case 'l':
				if (val <= 23) {
					onChangeL(val);
				} else {
					setNgsp('');
					setDl('');
					setIfcc('');
				}
				break;
		}
	};

	const handleValue = (field: string) => {
		switch (field) {
			case 'ifcc':
				return ifcc;
			case 'ngsp':
				return ngsp;
			case 'dl':
				return dl;
			case 'l':
				return l;
		}
	};

	return (
		<AppLayout activePath={activePath}>
			<Card style={{ minHeight: 400 }}>
				<PageHeader title="HbA1C Calculator" onClick={() => history.goBack()} />

				<Divider />
				<Form name="basic">
					<Row gutter={16}>
						{data?.map((item) => (
							<Col span={12} key={item.title}>
								<Row style={{ marginBottom: 12 }}>
									<Text style={{ fontSize: 18, fontWeight: 500 }}>
										{item.title}
									</Text>
								</Row>
								{item.input.map((el) => (
									<div
										key={el.field}
										style={{ display: 'flex', flexDirection: 'column' }}
									>
										<label style={{ marginBottom: 8 }}>{el.label}</label>
										<Form.Item key={el.field}>
											<InputNumber
												type="number"
												placeholder="Input"
												min={0}
												size="large"
												style={{ width: '50%' }}
												value={handleValue(el.field)}
												onChange={(value) => {
													if (!value || isNaN(Number(value))) {
														setIfcc('');
														setNgsp('');
														setDl('');
														setL('');
													} else {
														handleChangeText(el.field, value);
													}
												}}
											/>
										</Form.Item>
									</div>
								))}
							</Col>
						))}
					</Row>
				</Form>
			</Card>
		</AppLayout>
	);
}
