import { CheckSquare, Square } from 'react-feather';
import { theme } from '../../../../../../assets/theme';
import IconButton from '../../../../../Button/IconButton';
import Separator from '../../../../../Separator';

interface Props {
	service: {
		healthServiceId?: string | undefined;
		title: string;
		isSelected: boolean;
	};
	useBottomItemSeparator?: boolean;
	onSelect: (healthServiceId?: string) => void;
}

export default function ItemLabRequestProcedure(props: Props) {
	return (
		<div>
			<div className="py-2 flex items-center gap-x-3">
				<IconButton
					onClick={() => props.onSelect(props.service.healthServiceId)}
				>
					{props.service.isSelected ? (
						<CheckSquare width={20} height={20} color={theme.colors.primary} />
					) : (
						<Square width={20} height={20} color={theme.colors.ash800} />
					)}
				</IconButton>
				<p className="m-0 text-4">{props.service.title}</p>
			</div>
			{props.useBottomItemSeparator && <Separator />}
		</div>
	);
}
