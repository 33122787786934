import { useEffect, useMemo, useState } from 'react';
import { Search } from 'react-feather';
import { theme } from '../../../../../assets/theme';
import useFetchList from '../../../../../hooks/useFetchList';
import { IndividualHealthServiceProperties } from '../../../../../types/individualHealthService.type';
import { Button } from '../../../../Button/Button/Button';
import CustomScrollDiv from '../../../../CustomScrollDiv';
import FormInput from '../../../../Form/FormInput2';
import Separator from '../../../../Separator';
import Spinner from '../../../../Spinner';
import SidebarMenuHeader from '../Components/SidebarMenuHeader';
import ItemLabRequestProcedure from './Components/ItemLabRequestProcedure';
import { ELabRequestState } from './SidebarLabRequest';

interface Props {
	selectedHealthServices?: IndividualHealthServiceProperties[];
	onNext: ({ nav, dto }: { nav: ELabRequestState; dto?: any }) => void;
	onBack: () => void;
}

export default function SidebarLabRequestListProcedures(props: Props) {
	const [selectedHealthServicesId, setSelectedHealthServicesId] = useState<
		string[]
	>([]);

	useEffect(() => {
		if (!props.selectedHealthServices) return;

		const selectedHealthServicesId: string[] = [];
		for (const service of props.selectedHealthServices) {
			if (service.healthServiceId) {
				selectedHealthServicesId.push(service.healthServiceId);
			}
		}
		setSelectedHealthServicesId(selectedHealthServicesId);
	}, [props.selectedHealthServices]);

	const {
		data: healthServices,
		isLoading,
		search,
		pagination,
		setSearch,
		changePage,
	} = useFetchList<IndividualHealthServiceProperties>({
		endpoint: '/health-service/individuals',
		initialQuery: {
			isPublished: true,
		},
		primaryKey: 'healthServiceId',
		pushData: true,
	});

	function handleNextPage() {
		if (!isLoading && pagination.next) {
			changePage(pagination.page + 1);
		}
	}

	const sectionData = useMemo(() => {
		const section: {
			title: string;
			data: IndividualHealthServiceProperties[];
		}[] = [];
		if (healthServices) {
			healthServices.forEach((service) => {
				const title = service.category?.categoryName;
				const index = section.findIndex((s) => s.title === title);
				if (index >= 0) {
					section[index].data.push(service);
				} else if (title && service) {
					section.push({
						title: title,
						data: [service],
					});
				}
			});
		}
		return section;
	}, [healthServices]);

	function onSelectProcedure(healthServiceId?: string) {
		if (!healthServiceId) return;

		const index = selectedHealthServicesId.indexOf(healthServiceId);
		if (index >= 0) {
			setSelectedHealthServicesId((old) => {
				const newData = [...old];
				newData.splice(index, 1);
				return newData;
			});
		} else {
			setSelectedHealthServicesId((old) => [...old, healthServiceId]);
		}
	}

	function onSaveProcedures() {
		const selectedHealthServices: IndividualHealthServiceProperties[] = [];
		for (const serviceId of selectedHealthServicesId) {
			const selectedHealthService = healthServices.find(
				(service) => service.healthServiceId === serviceId,
			);
			if (selectedHealthService) {
				selectedHealthServices.push(selectedHealthService);
			}
		}
		props.onNext({
			nav: ELabRequestState.FORM,
			dto: { selectedHealthServices },
		});
	}

	return (
		<div className="w-full flex flex-col h-full">
			<SidebarMenuHeader onBack={props.onBack} title="Lab Request" />
			<div className="flex flex-1 min-h-0">
				<div className="flex flex-col w-full min-h-0 flex-1">
					<div className="pb-2">
						<FormInput
							placeholder="Search lab request"
							onChange={() => {}}
							prefix={
								<Search
									width={20}
									height={20}
									color={theme.colors.charcoal200}
								/>
							}
						/>
					</div>
					<CustomScrollDiv className="relative flex overflow-auto w-full h-full">
						<div className="w-full">
							{!isLoading ? (
								sectionData.map((section, index) => (
									<LabRequestProcedure
										key={section.title}
										medicalServiceCategory={section.title}
										medicalServices={section.data.map((service) => ({
											healthServiceId: service.healthServiceId,
											title: service.healthServiceName,
											isSelected:
												!!service.healthServiceId &&
												selectedHealthServicesId.indexOf(
													service.healthServiceId,
												) >= 0,
										}))}
										onSelectProcedure={onSelectProcedure}
										useBottomSeparator={index !== sectionData.length - 1}
										useBottomItemSeparator
										lastItemWithoutBottomSeparator
									/>
								))
							) : (
								<div className="p-4 w-fit mx-auto">
									<Spinner size={25} color={theme.colors.primary} />
								</div>
							)}
						</div>
					</CustomScrollDiv>
					<Separator />
					<div className="py-2 px-4">
						<Button onClick={onSaveProcedures} type="SOLID">
							Save
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
}

function LabRequestProcedure(props: {
	medicalServiceCategory: string;
	medicalServices: {
		healthServiceId?: string;
		title: string;
		isSelected: boolean;
	}[];
	useBottomItemSeparator?: boolean;
	lastItemWithoutBottomSeparator?: boolean;
	useBottomSeparator?: boolean;
	onSelectProcedure: (healthServiceId?: string) => void;
}) {
	return (
		<div>
			<p className="m-0 font-semibold text-4">
				{props.medicalServiceCategory.toUpperCase()}
			</p>
			<div>
				{props.medicalServices.map((service, index) => (
					<ItemLabRequestProcedure
						key={service.healthServiceId}
						service={service}
						onSelect={props.onSelectProcedure}
						useBottomItemSeparator={
							props.useBottomItemSeparator &&
							(index !== props.medicalServices.length - 1 ||
								!props.lastItemWithoutBottomSeparator)
						}
					/>
				))}
			</div>
			{props.useBottomSeparator && (
				<div className="py-2">
					<Separator style={{ height: 6 }} />
				</div>
			)}
		</div>
	);
}
