/* eslint-disable react-hooks/exhaustive-deps */
import { message } from 'antd';
import { useEffect } from 'react';
import { createGlobalState } from 'react-hooks-global-state';
import {
	apiDeleteDoctorDocument,
	apiGetListDoctorDocumentByConsultation,
	apiUpdateDoctorDocument,
} from '../api/doctorDocument';
import {
	DoctorDocumentProperties,
	EMetaType,
} from '../types/doctorDocument.type';

type IInitialState = {
	isLoadingInitial?: boolean;
	isLoadingAction?: boolean;
	doctorDocumentMedicalCertificate?: DoctorDocumentProperties;
	doctorDocumentClearance?: DoctorDocumentProperties;
	doctorDocumentFitToWork?: DoctorDocumentProperties;
	doctorDocumentOthers?: DoctorDocumentProperties;
};

const initialState: IInitialState = {
	isLoadingInitial: true,
	isLoadingAction: false,
	doctorDocumentMedicalCertificate: undefined,
	doctorDocumentClearance: undefined,
	doctorDocumentFitToWork: undefined,
	doctorDocumentOthers: undefined,
};

const { useGlobalState } = createGlobalState(initialState);

type Props = {
	transactionConsultationId?: string;
	lazy?: boolean;
};

export default function useConsultationDoctorDocument(props: Props) {
	const [
		doctorDocumentMedicalCertificate,
		setDoctorDocumentMedicalCertificate,
	] = useGlobalState('doctorDocumentMedicalCertificate');
	const [doctorDocumentClearance, setDoctorDocumentClearance] = useGlobalState(
		'doctorDocumentClearance',
	);
	const [doctorDocumentFitToWork, setDoctorDocumentFitToWork] = useGlobalState(
		'doctorDocumentFitToWork',
	);
	const [doctorDocumentOthers, setDoctorDocumentOthers] = useGlobalState(
		'doctorDocumentOthers',
	);

	useEffect(() => {
		init();
	}, [props.transactionConsultationId]);

	const init = async () => {
		if (!props.lazy && props.transactionConsultationId) {
			await fetchListDoctorDocumentByConsultation(
				props.transactionConsultationId,
			);
		} else {
			resetDocumentData();
		}
	};

	const resetDocumentData = () => {
		setDoctorDocumentMedicalCertificate(undefined);
		setDoctorDocumentClearance(undefined);
		setDoctorDocumentFitToWork(undefined);
		setDoctorDocumentOthers(undefined);
	};

	const fetchListDoctorDocumentByConsultation = async (
		transactionConsultationId: string,
	) => {
		try {
			const res = await apiGetListDoctorDocumentByConsultation(
				transactionConsultationId,
			);

			if (res) {
				for (const doc of res.results) {
					handleSetDoctorDocument(doc, doc.type!);
				}
				return res?.results;
			}
		} catch (error) {
			console.log(
				'error fetchListDoctorDocumentByConsultation=====>',
				JSON.stringify(error),
			);
			return error;
		}
	};

	const updateDoctorDocument = async (
		doctorDocumentId: string,
		data: Partial<DoctorDocumentProperties>,
	) => {
		try {
			const res = await apiUpdateDoctorDocument(doctorDocumentId, data);
			console.log('updateDetailDoctorDocument=======>', JSON.stringify(res));
			if (res) {
				handleSetDoctorDocument(res, res.type!);
				message.success('Doctor Document updated!');
				return res;
			}
		} catch (error) {
			return error;
		}
	};

	const deleteDoctorDocument = async (
		doctorDocumentId: string,
		type: EMetaType,
	) => {
		try {
			const res = await apiDeleteDoctorDocument(doctorDocumentId);
			console.log('deleteDetailDoctorDocument=======>', JSON.stringify(res));
			if (res) {
				handleSetDoctorDocument(undefined, type);
				message.success('Doctor Document deleted!');
				return res;
			}
		} catch (error) {
			// message.error('Failed to get data. ' + getErrorMessage(error));
			// setIsLoading(false);
			return error;
		}
	};

	const handleSetDoctorDocument = (
		doc: DoctorDocumentProperties | undefined,
		type: EMetaType,
	) => {
		if (type === EMetaType.MEDICAL_CERTIFICATE) {
			setDoctorDocumentMedicalCertificate(doc);
		} else if (type === EMetaType.CLEARANCE) {
			setDoctorDocumentClearance(doc);
		} else if (type === EMetaType.FIT_TO_WORK) {
			setDoctorDocumentFitToWork(doc);
		} else if (type === EMetaType.OTHERS) {
			setDoctorDocumentOthers(doc);
		}
	};

	return {
		doctorDocumentMedicalCertificate,
		doctorDocumentClearance,
		doctorDocumentFitToWork,
		doctorDocumentOthers,
		fetchListDoctorDocumentByConsultation,
		// createDoctorDocument,
		updateDoctorDocument,
		deleteDoctorDocument,
	};
}
