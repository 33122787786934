import {
	Button,
	Col,
	Divider,
	Input,
	message,
	Modal,
	Radio,
	RadioChangeEvent,
	Row,
	Space,
	Typography,
} from 'antd';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { apiRejectConsultation } from '../../../api/transactionConsultation';
import { iconApproveConfirmation as IconApproveConfirmation } from '../../../assets/icons';
import './styles.less';
import { useAuth } from '../../../context/auth.context';
import { IUser } from '../../../types/user.type';

type Props = {
	isVisible: boolean;
	setVisibility: (state: boolean) => void;
	isLoading?: boolean;
	transactionConsultationId: string;
	setFetchTrue: React.Dispatch<React.SetStateAction<boolean>>;
};

const { Text, Paragraph } = Typography;

export default function ModalRejectConsultation(props: Props) {
	const { user } = useAuth();
	const [selectedReason, setSelectedReason] = useState<any>();
	const [reasonInput, setReasonInput] = useState('');
	const [disabledTextarea, setDisabledTextare] = useState(true);

	const onChange = (e: RadioChangeEvent) => {
		setSelectedReason(e.target.value);
	};
	function cancelReject() {
		props.setVisibility(false);
	}

	async function rejectConsulHandler() {
		if (!selectedReason) {
			message.error('Select a reason!');
			return;
		}

		let reasonTemp;
		if (selectedReason === 4) {
			reasonTemp = reasonInput;
		} else {
			reasonTemp = selectedReason;
		}
		const data = {
			lastUpdatedByUser: user as IUser,
			reason: reasonTemp,
		};
		const res = await apiRejectConsultation(
			props.transactionConsultationId,
			data,
		);

		if (res.code !== 'success') {
			message.error('Failed to reject event');
			return;
		}

		setSelectedReason('');
		setReasonInput('');
		props.setFetchTrue(true);
		message.success('Success reject event');
		props.setVisibility(false);
	}

	return (
		<Modal
			className="modal"
			open={props.isVisible}
			footer={null}
			closable={false}
		>
			<div className="flex flex-col items-center" style={{ marginBottom: 20 }}>
				<div className="icon">
					<IconApproveConfirmation />
				</div>
				<Text style={{ marginBottom: 10 }} className="text-5 font-semibold">
					Confirmation
				</Text>
				<Paragraph style={{ marginBottom: 0, textAlign: 'center' }}>
					Are you sure to reject this consultation?
				</Paragraph>
			</div>
			<Row style={{ marginBottom: 12 }}>
				<Text style={{ fontSize: 16, fontWeight: 500 }}>
					Cancellation Reason
				</Text>
			</Row>
			<Row>
				<Col span={24}>
					<Radio.Group onChange={onChange} value={selectedReason}>
						<Space direction="vertical" style={{ width: '100%' }}>
							<Radio value={'I have medical emergency'}>
								I have medical emergency
							</Radio>
							<Radio value={'I am sick'}>I am sick</Radio>
							<Radio value={'I have a personal event to attend'}>
								I have a personal event to attend
							</Radio>
							<Radio
								value={4}
								onChange={(e) => setDisabledTextare(!e.target.checked)}
							>
								<Input.TextArea
									disabled={disabledTextarea}
									cols={55}
									style={{ borderRadius: 16, verticalAlign: 'top' }}
									rows={4}
									placeholder="Input your reason"
									value={reasonInput}
									onChange={(e) => setReasonInput(e.target.value)}
								/>
							</Radio>
						</Space>
					</Radio.Group>
				</Col>
			</Row>
			<Divider style={{ margin: '12px 0px' }} />

			<Row gutter={24} className="mt-2.5">
				<Col span={12}>
					<Button
						type="text"
						size="large"
						onClick={cancelReject}
						className="text-3.5 text-secondary rounded-3 bg-gray-10 border-0 w-full font-bold"
					>
						<Text style={{ fontSize: 14, fontWeight: 700 }}>Cancel</Text>
					</Button>
				</Col>
				<Col span={12}>
					<Button
						type="primary"
						size="large"
						onClick={rejectConsulHandler}
						className="rounded-3 bg-primary w-full"
					>
						<Text style={{ fontSize: 14, fontWeight: 700, color: '#fff' }}>
							Yes
						</Text>
					</Button>
				</Col>
			</Row>
		</Modal>
	);
}
