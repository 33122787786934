import React, { useState, useEffect } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import AppLayout from '../../../../layout/AppLayout';
import PageHeader from '../../../../components/PageHeader';
import {
	Button,
	Card,
	Divider,
	message,
	Row,
	Spin,
	Typography,
	Result,
	Modal,
	Col,
} from 'antd';
import {
	ExclamationCircleFilled,
	ExclamationCircleOutlined as IconConsultNow,
	InfoCircleOutlined,
	WarningOutlined,
} from '@ant-design/icons';
import {
	iconTelemedicine as IconTelemedicine,
	iconAppointment as IconInPerson,
} from '../../../../assets/icons';
import {
	listDefaultClinics,
	PracticeDataItem,
} from '../../../../constants/clinic';
import {
	apiCreateUpdatePartnerDoctor,
	apiDeletePartnerDoctor,
	apiGetPartnerDoctors,
} from '../../../../api/partnerDoctor';
import { apiGetAppConfig } from '../../../../api/appConfig';
import { CONSULT_NOW_PRICE } from '../../../../helpers/constants';
import { EPartnerType } from '../../../../types/partner.type';
import EmptyList from '../../../../components/EmptyList';
import { Info } from 'react-feather';
import { useAuth } from '../../../../context/auth.context';

const activePath = '/app/setting/clinics-and-practice';
const { Text, Paragraph } = Typography;

export default function ClinicsAndPracticeSetting() {
	const history = useHistory();
	const { userId } = useAuth();

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [activate, setActivate] = useState<boolean>(false);
	const [clinics, setClinics] = useState<PracticeDataItem[]>([]);

	const [telemedicine, setTelemedicine] = useState<PracticeDataItem[]>([]);
	const [consultNow, setConsultNow] = useState<PracticeDataItem[]>([]);
	const [inPerson, setInPerson] = useState<PracticeDataItem[]>([]);

	const [showActivate, setShowActivate] = useState<boolean>(false);
	const [showDeactivate, setShowDeactivate] = useState<boolean>(false);

	// API
	const fetchListClinic = async () => {
		setIsLoading(true);
		let tmpList = [...listDefaultClinics];

		try {
			const res = await apiGetPartnerDoctors(userId);
			const consultNowPrice = await apiGetAppConfig(CONSULT_NOW_PRICE);

			if (res && res.results) {
				console.info('res.results from apiGetPartnerDoctors:', res.results);
				let telemedicine: PracticeDataItem[] = [];
				let consultNow: PracticeDataItem[] = [];
				let inPerson: PracticeDataItem[] = [];

				// mapping for online clinics
				const findTelemedicine = res.results.find(
					(resItem) =>
						resItem.partner?.partnerType === EPartnerType.ONLINE_CLINIC,
				);
				const findConsultNow = res.results.find(
					(resItem) =>
						resItem.partner?.partnerType === EPartnerType.CONSULT_NOW,
				);

				tmpList = tmpList.map((item) => {
					if (item.type === 'telemedicine') {
						if (findTelemedicine) {
							item = {
								...item,
								title: 'Telemedicine',
								isOnline: true,
								isEnabled: true,
								partnerId: findTelemedicine.partnerId,
								price: findTelemedicine.pricePerSession || 0,
							};
						} else {
							item = {
								...item,
								title: 'Telemedicine',
								isOnline: true,
								isEnabled: false,
								partnerId: 'telemedicine',
								price: 0,
							};
						}
					} else if (item.type === 'consult-now') {
						if (findConsultNow) {
							item = {
								...item,
								title: 'Consult Now',
								isOnline: true,
								isEnabled: true,
								partnerId: findConsultNow.partnerId,
								price: item.price ? item.price : Number(consultNowPrice?.value),
								schedules: item.schedules,
							};
							setActivate(true);
						} else {
							item = {
								...item,
								title: 'Consult Now',
								isOnline: true,
								isEnabled: false,
								partnerId: 'consult-now',
								price: Number(consultNowPrice?.value) || 0,
								schedules: [],
							};
							setActivate(false);
						}
					}
					return item;
				});

				// mapping data for offline clinics
				const mappedResults: PracticeDataItem[] = res.results
					.filter(
						(item) =>
							item.partner?.partnerType !== EPartnerType.ONLINE_CLINIC &&
							item.partner?.partnerType !== EPartnerType.CONSULT_NOW,
					)
					.map((item) => ({
						partnerId: item.partnerId,
						title: item.partner?.partnerName || '-',
						type: 'appointment',
						isEnabled: true,
						location: item.partner?.address,
						price: item.pricePerSession || 0,
						availableDay: [] as number[],
						schedules: [],
					}));
				tmpList = tmpList.concat(mappedResults);

				// update data
				tmpList = tmpList.map((item) => {
					const partnerDoctor = res.results.find(
						(resPartnerDoctor) => resPartnerDoctor.partnerId === item.partnerId,
					);

					if (partnerDoctor) {
						item.partnerId = partnerDoctor.partnerId;
						if (!item.title) {
							item.title = partnerDoctor.partner?.partnerName || '';
						}
						item.price = partnerDoctor.pricePerSession || 0;
						item.location = partnerDoctor.partner?.address || '';
						if (partnerDoctor.schedules) {
							item.schedules = [
								...partnerDoctor.schedules.map((sch) => ({
									...sch,
									scheduleId: sch.scheduleId || '',
								})),
							];
							partnerDoctor.schedules.forEach((sch) => {
								if (!item.availableDay.includes(sch.weekday)) {
									item.availableDay.push(sch.weekday);
								}
							});
						}
					}

					if (item.type === 'telemedicine') {
						telemedicine.push(item);
					} else if (item.type === 'appointment') {
						inPerson.push(item);
					} else {
						consultNow.push(item);
					}

					return item;
				});
				setTelemedicine(telemedicine);
				setConsultNow(consultNow);
				setInPerson(inPerson);
				setClinics(tmpList);
				console.log('===> tmpList', JSON.stringify(tmpList));
			}
			setIsLoading(false);
		} catch (err) {
			setIsLoading(false);
			console.log('ERROR: fetchListClinic:', err);
			message.error('Failed to get clinics!');
		}
	};

	const activateFeature = async (
		feature: 'telemedicine' | 'consult-now' | 'appointment',
	) => {
		try {
			const consultNowPrice = await apiGetAppConfig(CONSULT_NOW_PRICE);

			await apiCreateUpdatePartnerDoctor({
				partnerId:
					feature === 'telemedicine'
						? EPartnerType.ONLINE_CLINIC
						: feature === 'consult-now'
						? EPartnerType.CONSULT_NOW
						: feature,
				pricePerSession: Number(consultNowPrice?.value),
				doctorId: userId || '',
			});

			fetchListClinic();
			message.success('SUCCESS: Clinic successfully activated');
			setShowActivate(false);
		} catch (err) {
			message.error('ERROR: Failed to activate clinic');
		}
	};

	const inactivateFeature = async (
		feature: 'telemedicine' | 'consult-now' | 'appointment',
	) => {
		try {
			await apiDeletePartnerDoctor(
				feature === 'telemedicine'
					? EPartnerType.ONLINE_CLINIC
					: feature === 'consult-now'
					? EPartnerType.CONSULT_NOW
					: feature,
				userId || '',
			);
			fetchListClinic();
			message.success('SUCCESS: Clinic successfully deactivated');
			setShowDeactivate(false);
		} catch (err) {
			message.error('ERROR: Failed to deactivate clinic');
		}
	};

	useEffect(() => {
		if (userId) {
			fetchListClinic();
		}
	}, [userId]);

	// Helper Functions
	const concatenate = (text: string) => {
		if (text.length > 47) {
			return text.slice(0, 46) + '...';
		} else {
			return text;
		}
	};

	// Activate/Deactivate Consult Now
	const activateConsultNow = async () => {
		setActivate(true);
		await activateFeature('consult-now');
		fetchListClinic();
	};

	const deactivateConsultNow = async () => {
		setActivate(false);
		await inactivateFeature('consult-now');
		fetchListClinic();
	};

	const ModalIcon = () => {
		return (
			<Row align="middle" justify="center" style={{ width: '100%' }}>
				<div
					style={{
						backgroundColor: '#F1F3F5',
						borderRadius: '50%',
						width: 64,
						height: 64,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<WarningOutlined style={{ fontSize: 28 }} />
				</div>
			</Row>
		);
	};

	// Components
	const ComponentTitle = (title: string) => {
		let backgroundColor: any;
		let icon: any;

		if (title === 'Consult Now') {
			backgroundColor = '#EFD31A';
			icon = <IconConsultNow style={{ fontSize: 10, color: 'white' }} />;
		} else if (title === 'Telemedicine') {
			backgroundColor = '#D81F64';
			icon = <IconTelemedicine width={10} height={10} />;
		} else if (title === 'In Person Appointment') {
			backgroundColor = '#2B9CDC';
			icon = <IconInPerson width={10} height={10} />;
		}

		return (
			<Row align="middle">
				<div
					style={{
						width: 24,
						height: 24,
						backgroundColor: backgroundColor,
						borderRadius: '50%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					{icon}
				</div>
				<Text
					style={{
						fontWeight: 600,
						fontSize: 18,
						marginLeft: 5,
						marginRight: 5,
					}}
				>
					{title}
				</Text>
				<InfoCircleOutlined style={{ color: '#AEAEAE', fontSize: 9 }} />
				{title === 'Consult Now' && activate === false && (
					<Button
						type="ghost"
						style={{
							borderColor: '#D81F64',
							position: 'absolute',
							right: 0,
							marginRight: 24,
						}}
						onClick={() => {
							setShowActivate(true);
							setShowDeactivate(false);
						}}
					>
						<Text
							style={{
								fontSize: 14,
								fontWeight: 700,
								color: '#D81F64',
							}}
						>
							Activate
						</Text>
					</Button>
				)}
				{title === 'Consult Now' && activate === true && (
					<Button
						style={{
							borderColor: '#FEF5F9',
							backgroundColor: '#FEF5F9',
							position: 'absolute',
							right: 0,
							marginRight: 24,
						}}
						onClick={() => {
							setShowActivate(false);
							setShowDeactivate(true);
						}}
					>
						<Text
							style={{
								fontSize: 14,
								fontWeight: 700,
								color: '#D81F64',
							}}
						>
							Activated
						</Text>
					</Button>
				)}
			</Row>
		);
	};

	const ComponentDescription = (description: string) => {
		return (
			<Row
				align="middle"
				style={{
					width: '100%',
					border: '1px solid #E5E9EC',
					backgroundColor: '#F9FAFA',
					borderRadius: 8,
					padding: 8,
					marginTop: 20,
				}}
			>
				<Paragraph style={{ fontSize: 14, margin: 0 }}>{description}</Paragraph>
			</Row>
		);
	};

	const ComponentDay = (days: number[]) => {
		if (days.length > 0) {
			const sorted = days.sort();
			const output = sorted.map((i) => {
				return (
					<div
						style={{
							border: '1px solid #D5DCE1',
							borderRadius: 8,
							paddingLeft: 12,
							paddingRight: 12,
							marginRight: 4,
							marginTop: 20,
						}}
					>
						<Text style={{ fontSize: 14 }}>
							{moment().isoWeekday(i).format('ddd')}
						</Text>
					</div>
				);
			});
			return <Row>{[...output]}</Row>;
		} else {
			return (
				<Row>
					<div
						style={{
							border: '1px solid #D5DCE1',
							borderRadius: 8,
							paddingLeft: 12,
							paddingRight: 12,
							marginRight: 4,
							marginTop: 20,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<ExclamationCircleFilled
							style={{
								fontSize: 9.5,
								marginBottom: 3,
								marginRight: 5,
							}}
						/>
						<Text style={{ fontSize: 14 }}>Schedule is not set</Text>
					</div>
				</Row>
			);
		}
	};

	const ComponentCard = (
		name: string,
		location: string,
		price: string,
		onClick: () => void,
		days: number[],
	) => {
		return (
			<>
				<div
					style={{
						width: '100%',
						border: '1px solid #D5DCE1',
						borderRadius: 12,
						padding: 12,
						marginBottom: 16,
					}}
				>
					<Row style={{ width: '100%' }} align="middle">
						<Text style={{ fontWeight: 600, fontSize: 16 }}>{name}</Text>
					</Row>
					<Row style={{ width: '100%' }} align="middle">
						<Text type="secondary" style={{ fontSize: 14 }}>
							{concatenate(location)}
						</Text>
						<Button
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								backgroundColor: '#F1F3F5',
								position: 'absolute',
								right: 0,
								marginRight: 35,
							}}
							onClick={onClick}
						>
							<Text style={{ fontSize: 14, fontWeight: 700 }}>Edit</Text>
						</Button>
					</Row>
					<Row style={{ width: '100%' }} align="middle">
						<Text style={{ fontWeight: 600, fontSize: 16 }}>
							Price : ₱{price}
						</Text>
					</Row>
					{ComponentDay(days)}
				</div>
			</>
		);
	};

	const ComponentAddClinicButton = () => {
		return (
			<Button
				type="ghost"
				style={{
					borderColor: '#D81F64',
					position: 'absolute',
					left: '50%',
					transform: 'translate(-50%, 0)',
				}}
				onClick={() => {
					history.push('/app/setting/clinics-and-practice/add-clinic');
				}}
			>
				<Text style={{ fontSize: 14, fontWeight: 700, color: '#D81F64' }}>
					Add Clinic
				</Text>
			</Button>
		);
	};

	// Component Groups
	const ConsultNow = () => {
		const title = 'Consult Now';
		const description =
			'Activate Consult Now to receive instant consultation requests from patients';
		const edit = () => {
			// setEditConsultNow(true);
			history.push('/app/setting/clinics-and-practice/details');
		};

		let output = consultNow.map((item) => {
			let onlineOrLocation = '';
			if (
				item?.hasOwnProperty('isOnline') ||
				item?.hasOwnProperty('location')
			) {
				onlineOrLocation = item?.hasOwnProperty('isOnline')
					? item?.isOnline
						? 'Online'
						: 'Offline'
					: item?.location || '-';
			}
			const price = item.price.toString();
			const availableDay = item.availableDay;
			return ComponentCard(
				'Medeasy App',
				onlineOrLocation,
				price,
				() => {
					history.push('/app/setting/clinics-and-practice/details', item);
				},
				availableDay,
			);
		});
		return (
			<>
				{ComponentTitle(title)}
				{ComponentDescription(description)}
				<Divider style={{ marginTop: 8, marginBottom: 8 }} />
				{output}
				<Divider />
			</>
		);
	};

	const Telemedicine = () => {
		const title = 'Telemedicine';
		const description =
			'Set your telemedicine schedule and patients can automatically book a consultation with you';
		const edit = () => {
			history.push('/app/setting/clinics-and-practice/details', telemedicine);
			// setEditTelemedicine(true);
		};

		let output = telemedicine.map((item) => {
			let onlineOrLocation = '';
			if (
				item?.hasOwnProperty('isOnline') ||
				item?.hasOwnProperty('location')
			) {
				onlineOrLocation = item?.hasOwnProperty('isOnline')
					? item?.isOnline
						? 'Online'
						: 'Offline'
					: item?.location || '-';
			}
			const price = item.price.toString();
			const availableDay = item.availableDay;
			return ComponentCard(
				'Medeasy App',
				onlineOrLocation,
				price,
				() => {
					history.push('/app/setting/clinics-and-practice/details', item);
				},
				availableDay,
			);
		});

		return (
			<>
				{ComponentTitle('Telemedicine')}
				{ComponentDescription(description)}
				<Divider style={{ marginTop: 8, marginBottom: 8 }} />
				{output}
				<Divider />
			</>
		);
	};

	const InPersonAppointment = () => {
		const title = 'In Person Appointment';
		const description =
			'Sync your clinic schedule here so patients can book in person consultations in your clinic.';
		const edit = () => {
			history.push('/app/setting/clinics-and-practice/details', inPerson);
			// setEditInPerson(true);
		};
		let output: any;
		if (inPerson.length > 0) {
			output = inPerson.map((item) => {
				const partnerName = item.title || item.partner?.partnerName || '-';
				const partnerLocation = item.location || item.partner?.address || '-';
				let onlineOrLocation = '';
				if (
					item?.hasOwnProperty('isOnline') ||
					item?.hasOwnProperty('location')
				) {
					onlineOrLocation = item?.hasOwnProperty('isOnline')
						? item?.isOnline
							? 'Online'
							: 'Offline'
						: item?.location || '-';
				}
				const price = item.price.toString();
				const availableDay = item.availableDay;
				console.log(item, 'result item');
				return ComponentCard(
					partnerName,
					partnerLocation,
					price,
					() => {
						history.push('/app/setting/clinics-and-practice/details', item);
					},
					availableDay,
				);
			});
		} else {
			output = (
				<>
					<EmptyList
						icon={<Info color="#AEAEAE" width={30} height={30} />}
						title="No Clinic Available"
						description={`Please add a clinic first to be able to set practice schedule`}
					/>
					<ComponentAddClinicButton />
				</>
			);
		}

		return (
			<>
				{ComponentTitle(title)}
				{ComponentDescription(description)}
				<Divider style={{ marginTop: 8, marginBottom: 8 }} />
				{output}
			</>
		);
	};

	return (
		<AppLayout activePath={activePath}>
			<Card style={{ paddingBottom: 110 }}>
				<PageHeader
					title="Clinics and Practice Settings"
					onClick={() => history.goBack()}
				/>
				<Divider />
				{isLoading && <CustomSpin spinning={isLoading} />}
				{!isLoading && consultNow.length > 0 && <ConsultNow />}
				{!isLoading && telemedicine.length > 0 && <Telemedicine />}
				{!isLoading && inPerson && <InPersonAppointment />}
				{!isLoading && inPerson.length > 0 && <ComponentAddClinicButton />}
				{!isLoading && showActivate && (
					<Modal
						centered
						closable={false}
						open={showActivate}
						onOk={activateConsultNow}
						onCancel={() => {
							setShowActivate(false);
						}}
						footer={
							<>
								<Row gutter={16} style={{ width: '100%' }}>
									<Col span={12}>
										<Button
											onClick={() => {
												setShowActivate(false);
											}}
											ghost
											style={{
												width: '100%',
												color: '#1D2B36',
												backgroundColor: '#F1F3F5',
												fontSize: 14,
												fontWeight: 700,
											}}
										>
											Not Now
										</Button>
									</Col>
									<Col span={12}>
										<Button
											type="primary"
											onClick={activateConsultNow}
											style={{
												width: '100%',
												fontSize: 14,
												fontWeight: 700,
											}}
										>
											Yes
										</Button>
									</Col>
								</Row>
							</>
						}
					>
						<Result
							icon={<ModalIcon />}
							title="Confirmation"
							subTitle="Are you sure you want to activate Consult Now?"
						/>
					</Modal>
				)}
				{!isLoading && showDeactivate && (
					<Modal
						centered
						closable={false}
						open={showDeactivate}
						onOk={deactivateConsultNow}
						onCancel={() => {
							setShowDeactivate(false);
						}}
						footer={
							<>
								<Row gutter={16} style={{ width: '100%' }}>
									<Col span={12}>
										<Button
											onClick={() => {
												setShowDeactivate(false);
											}}
											ghost
											style={{
												width: '100%',
												color: '#1D2B36',
												backgroundColor: '#F1F3F5',
												fontSize: 14,
												fontWeight: 700,
											}}
										>
											Not Now
										</Button>
									</Col>
									<Col span={12}>
										<Button
											type="primary"
											onClick={deactivateConsultNow}
											style={{
												width: '100%',
												fontSize: 14,
												fontWeight: 700,
											}}
										>
											Yes
										</Button>
									</Col>
								</Row>
							</>
						}
					>
						<Result
							icon={<ModalIcon />}
							title="Confirmation"
							subTitle="Are you sure to deactivate Consult Now? You will not receive a consult now request from a Patient again."
						/>
					</Modal>
				)}
			</Card>
		</AppLayout>
	);
}

// Custom Components
const CustomSpin = styled(Spin)`
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0);
`;
