import { Button, Col, Modal, Row, Spin, Typography } from 'antd';
import { useEffect, useState } from 'react';
import {
	apiAddPartner,
	apiDeletePartner,
	apiGetSecretary,
} from '../../../api/doctorSecretaries';
import { apiGetPartnerDoctors } from '../../../api/partnerDoctor';
import { EPartnerType, PartnerProperties } from '../../../types/partner.type';
import './styles.less';
import { iconApproveConfirmation as IconApproveConfirmation } from '../../../assets/icons';
import SecretaryAccessList from './SecretaryAccessList';

const { Text, Paragraph } = Typography;

type Props = {
	isVisible: boolean;
	setVisibility: (state: boolean) => void;
	data: { doctorId?: string; secretaryId?: string };
	isLoadingApprove: boolean;
	approveSecretaryHandler: () => void;
};

export default function ModalApproveSecretary(props: Props) {
	const [isLoading, setIsLoading] = useState(true);
	const [isLoadingToggle, setIsLoadingToggle] = useState(false);
	const [partnersTelemedicine, setPartnersTelemedicine] =
		useState<(PartnerProperties & { active: boolean })[]>();
	const [partnersInPerson, setPartnersInPerson] =
		useState<(PartnerProperties & { active: boolean })[]>();

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			if (!props.data.doctorId || !props.data.secretaryId) return;
			try {
				const resPartner = await apiGetPartnerDoctors(props.data.doctorId);
				console.log('partner', resPartner);

				const telemedicine: PartnerProperties[] = [];
				const inPerson: PartnerProperties[] = [];

				resPartner.results.forEach((res) => {
					switch (res.partner?.partnerType) {
						case EPartnerType.CLINIC:
						case EPartnerType.HOSPITAL:
							inPerson.push(res.partner);
							break;
						case EPartnerType.ONLINE_CLINIC:
							telemedicine.push(res.partner);
							break;
					}
				});

				const resDoctorSecretaries = await apiGetSecretary(props.data.doctorId);

				if (resDoctorSecretaries.code !== 'success') {
					return;
				}

				const activeTelemedicine: string[] = [];
				const activeInPerson: string[] = [];
				resDoctorSecretaries.payload.results.forEach((res: any) => {
					res.partners.forEach((partner: any) => {
						switch (partner.partnerType) {
							case EPartnerType.CLINIC:
							case EPartnerType.HOSPITAL:
								activeInPerson.push(partner.partnerId);
								break;
							case EPartnerType.ONLINE_CLINIC:
								activeTelemedicine.push(partner.partnerId);
								break;
						}
					});
				});

				setPartnersTelemedicine(
					telemedicine.map((t) => {
						return {
							...t,
							active: activeTelemedicine.indexOf(t.partnerId) >= 0,
						};
					}),
				);
				setPartnersInPerson(
					inPerson.map((i) => {
						return { ...i, active: activeInPerson.indexOf(i.partnerId) >= 0 };
					}),
				);
			} catch (err) {
				console.error('ERROR ', err);
			} finally {
				setIsLoading(false);
			}
		})();
	}, [props.data]);

	async function togglePartner(
		partnerId: string,
		type: 'TELEMEDICINE' | 'INPERSON',
		status: boolean,
	) {
		if (!props.data.doctorId || !props.data.secretaryId) return;

		setIsLoadingToggle(true);
		if (status) {
			const res = await apiAddPartner(
				props.data.doctorId,
				props.data.secretaryId,
				{ partnerId },
			);

			if (res.code !== 'success') {
				setIsLoadingToggle(false);
				return;
			}
		} else {
			const res = await apiDeletePartner(
				props.data.doctorId,
				props.data.secretaryId,
				partnerId,
			);

			if (res.code !== 'success') {
				setIsLoadingToggle(false);
				return;
			}
		}

		if (type === 'TELEMEDICINE') {
			setPartnersTelemedicine(
				(prev) =>
					prev?.map((p) => {
						return {
							...p,
							active: p.partnerId === partnerId ? status : p.active,
						};
					}),
			);
		} else if (type === 'INPERSON') {
			setPartnersInPerson(
				(prev) =>
					prev?.map((p) => {
						return {
							...p,
							active: p.partnerId === partnerId ? status : p.active,
						};
					}),
			);
		}

		setIsLoadingToggle(false);
	}

	return (
		<Modal
			className="modal"
			open={props.isVisible}
			footer={null}
			closable={false}
		>
			<div className="flex flex-col items-center mb-6">
				<div className="icon">
					<IconApproveConfirmation />
				</div>

				<Text style={{ marginBottom: 10 }} className="text-5 font-semibold">
					Approval Confirmation
				</Text>
				<Paragraph style={{ marginBottom: 0 }}>
					Please select acces permission for this secretary
				</Paragraph>
			</div>
			<div className="modal-body">
				{isLoading ? (
					<Spin spinning={isLoading} />
				) : (
					<SecretaryAccessList
						isLoadingToggle={isLoadingToggle}
						partnersTelemedicine={partnersTelemedicine}
						partnersInPerson={partnersInPerson}
						togglePartner={togglePartner}
					/>
				)}

				<Row gutter={24} style={{ marginTop: '48px' }}>
					<Col span={12}>
						<Button
							type="text"
							size="large"
							className="text-3.5 text-secondary rounded-3 bg-gray-10 border-0 w-full font-bold"
							onClick={() => props.setVisibility(false)}
						>
							Cancel
						</Button>
					</Col>
					<Col span={12}>
						<Button
							type="primary"
							size="large"
							style={{ color: '#fff' }}
							className="text-3.5 text-white rounded-3 bg-primary w-full font-bold"
							onClick={props.approveSecretaryHandler}
						>
							Approve
						</Button>
					</Col>
				</Row>
			</div>
		</Modal>
	);
}
