import { Collapse } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

const { Panel } = Collapse;

const collapseDatas = [
	{
		header: 'Cardio Risk',
		content: [
			{
				title: 'ACC/AHA Risk Calculator',
				desc: 'Calculate your 10-year risk of heart disease or stroke using the ASCVD algorithm published in 2013 ACC/AHA Guideline on the Assessment of Cardiovascular Risk',
				path: '/app/medical-calculator/cardio-risk/aha',
			},
			{
				title: 'Framingham Risk Score',
				desc: 'The Framingham Risk Score is a sex-specific algorithm used to estimate the 10-year cardiovascular risk of an individual',
				path: '/app/medical-calculator/cardio-risk/framingham',
			},
			{
				title: 'Revised Cardiac Risk Index',
				desc: 'Estimates risk of cardiac complications after noncardiac surgery',
				path: '/app/medical-calculator/cardio-risk/revised-cardiac',
			},
		],
		// content: text,
	},

	{
		header: 'Diabetes Risk',
		content: [
			{
				title: 'Findrisc Calculator',
				desc: 'A prediction tool that can identify patients at risk of developing diabetes without need for laboratory testing.',
				path: '/app/medical-calculator/diabetes-risk/find-risc',
			},
			{
				title: 'HbA1C',
				desc: 'Is your average blood glucose (Sugar) levels for the last two to three months.',
				path: '/app/medical-calculator/diabetes-risk/hba1c',
			},
		],
		// content: text,
	},
];

export default function CalculatorsListCollapse() {
	const history = useHistory();
	const CollapseList = collapseDatas.map((data, index) => {
		return (
			<Panel
				key={index + 1}
				header={<TitleContainer>{data?.header}</TitleContainer>}
				style={CollapseStyles}
			>
				{data.content?.map((item, index) => {
					return (
						<div
							key={index}
							style={{ marginLeft: 14, marginBottom: 12, cursor: 'pointer' }}
							onClick={() => history.push(item.path)}
						>
							<TitleContainer>{item.title}</TitleContainer>
							<TextContainer>{item.desc}</TextContainer>
						</div>
					);
				})}
			</Panel>
		);
	});

	return (
		<div style={{ marginTop: 24 }}>
			<Collapse
				bordered={false}
				expandIconPosition="end"
				expandIcon={({ isActive }) => (
					<DownOutlined rotate={isActive ? 180 : 0} />
				)}
			>
				{CollapseList}
			</Collapse>
		</div>
	);
}

const CollapseStyles: React.CSSProperties = {
	backgroundColor: 'red',
	marginBottom: 12,
	overflow: 'hidden',
	background: '#F8FAFB',
	border: '0px',
	borderRadius: '12px',
};

const TitleContainer = styled.div`
	font-weight: 600;
`;

const TextContainer = styled.div`
	font-size: 16;
	font-weight: 400;
	color: #777777;
	letter-space: 01;
	line-height: 1.2;
`;
