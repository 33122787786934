import { Alert, Button, Col, message, Row, Spin, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { Copy } from 'react-feather';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { apiGetReferral } from '../../../api/userRefferal';
import COLORS from '../../../assets/globalColors';
import {
	iconInformationCirclePrimary as IconInformationCircle,
	iconRectangle as IconRectangle,
	iconReward as IconReward,
	iconShareCode as IconShareCode,
} from '../../../assets/icons';
import BottomPromoAndDeals from '../../../components/Webapp/Articles/BottomPromoAndDeals';
import SidebarArticle from '../../../components/Webapp/Articles/SidebarArticle';
import { useAuth } from '../../../context/auth.context';
import useFetchList from '../../../hooks/useFetchList';
import AppLayout from '../../../layout/AppLayout';
import { BannerProps } from '../../../types/banner.type';

const { Text } = Typography;
export default function ReferralPromoDeals() {
	const history = useHistory();
	const { isLoggedIn, userId, isDoctor } = useAuth();
	const [referralCode, setReferralCode] = useState<string>();
	const [isLoadingGetReferralCode, setIsLoadingGetReferralCode] =
		useState(false);

	const copyHandler = () => {
		if (!referralCode) return;

		navigator.clipboard.writeText(referralCode);

		message.success('Referral code copied to clipboard');
	};

	useEffect(() => {
		if (!userId || userId.length === 0) return;

		(async () => {
			setIsLoadingGetReferralCode(true);
			const referralCode = await apiGetReferral({ userId });
			setReferralCode(referralCode);
			setIsLoadingGetReferralCode(false);
		})();
	}, [userId]);

	function shareCodeHandler() {
		if (!referralCode || referralCode.length === 0) return;

		// Check if navigator.share is supported by the browser
		if (navigator.share) {
			navigator
				.share({
					text: `Let's join MedEasy Now! Use my referral code *${referralCode}* to get the reward. https://medeasyapp.page.link/download`,
					url: 'https://medeasyapp.page.link/download',
					title: 'MedEasy',
				})
				.then(() => {
					console.log('Sharing successfull');
				})
				.catch(() => {
					console.log('Sharing failed');
				});
		} else {
			// Browser not support
			navigator.clipboard.writeText(
				`Let's join MedEasy Now! Use my referral code *${referralCode}* to get the reward. https://medeasyapp.page.link/download`,
			);
			message.success('Copied to clipboard');
			console.log('Sorry! Your browser does not support Web Share API');
		}
	}

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const { data: banners, isLoading: isLoadingBanner } =
		useFetchList<BannerProps>({
			endpoint: '/banners',
			initialQuery: {
				isPublished: true,
				target: isDoctor ? 'DOCTOR' : 'PATIENT',
			},
		});

	const howto = [
		{
			title: 'Invite your friends',
			description: 'Share your referral code to your friends',
		},
		{
			title: 'Input Referral Code',
			description: 'Ask your friend to enter your referral code',
		},
		{
			title: 'You get the point',
			description: 'Then you get Points',
		},
	];

	const ComponentHowTo = ({
		data,
		index,
	}: {
		data: { title: string; description: string };
		index: number;
	}) => {
		return (
			<Row key={index} gutter={12} align="middle" style={{ marginBottom: 20 }}>
				<Col>
					<Row
						style={{
							background: COLORS.white,
							height: 45,
							width: 45,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							border: '1px solid',
							borderColor: COLORS.black_30,
							borderRadius: '50%',
						}}
					>
						<Text
							style={{
								fontSize: 22,
								fontWeight: 600,
								color: COLORS.primary,
							}}
						>
							{index + 1}
						</Text>
					</Row>
				</Col>
				<Col>
					<Row>
						<Text
							style={{
								color: COLORS.black,
								fontSize: 20,
							}}
						>
							{data.title}
						</Text>
					</Row>
					<Row>
						<Text
							style={{
								color: COLORS.black_70,
								fontSize: 18,
							}}
						>
							{data.description}
						</Text>
					</Row>
				</Col>
			</Row>
		);
	};

	const ComponentMyReferral = () => {
		return (
			<ReferralCodeContainer>
				<IconRectangle className="rectangle" />
				<div className="dot left"></div>
				<div className="dot right"></div>
				<Col span={16}>
					<Row>
						<Text
							style={{ fontSize: 14, color: COLORS.white, marginBottom: 4 }}
						>
							My Referral Code
						</Text>
					</Row>
					<Row style={{ marginBottom: 22 }}>
						{isLoadingGetReferralCode ? (
							<Spin />
						) : (
							<Button
								type="text"
								style={{
									padding: 0,
								}}
								className="flex items-center justify-between"
								onClick={copyHandler}
							>
								<Text
									style={{
										fontSize: 32,
										fontWeight: 600,
										color: COLORS.white,
										cursor: 'pointer',
									}}
								>
									{referralCode}
								</Text>{' '}
								<Copy style={{ marginLeft: 8, color: COLORS.white }} />
							</Button>
						)}
					</Row>
					<Row>
						<Button
							type="primary"
							onClick={shareCodeHandler}
							style={{
								background: COLORS.white,
								borderRadius: 8,
								paddingRight: 10,
								paddingLeft: 10,
							}}
							className="flex items-center gap-3 justify-between"
						>
							<IconShareCode width={15} height={15} />
							<Text style={{ fontSize: 14 }}>Share Code</Text>
						</Button>
					</Row>
				</Col>
				<Col span={6} style={{ marginRight: 8 }}>
					<IconReward />
				</Col>
			</ReferralCodeContainer>
		);
	};

	return (
		<AppLayout activePath={'/referral'}>
			<div className={'container mt-12'} style={{ marginBottom: 100 }}>
				{isLoggedIn ? (
					<Row gutter={[24, 24]}>
						<Col span={24} lg={16}>
							<Typography.Title
								level={4}
								style={{ color: COLORS.gray_100, marginBottom: 24 }}
								className="font-semibold"
							>
								Invite Friends and you will get Points
							</Typography.Title>
							<Row className="justify-between" style={{ marginBottom: 24 }}>
								<Col>
									{howto.map((data, index) => (
										<ComponentHowTo key={index} data={data} index={index} />
									))}
								</Col>
								<Col>
									<ComponentMyReferral />
								</Col>
							</Row>
							<Row
								align="middle"
								gutter={10}
								style={{
									background: COLORS.gray_10,
									padding: 12,
									borderRadius: 12,
								}}
							>
								<Col className="pt-1">
									<IconInformationCircle width={15} height={15} />
								</Col>
								<Col>
									<Text style={{ color: COLORS.black_70, fontSize: 16 }}>
										For more information about Referral please{' '}
										<Link
											to="#"
											className="font-bold"
											style={{ textDecoration: 'underline' }}
										>
											see here
										</Link>
									</Text>
								</Col>
							</Row>
						</Col>
						<Col span={24} lg={8}>
							<div className="top-27 sticky">
								<SidebarArticle />
							</div>
						</Col>
					</Row>
				) : (
					<Alert message="Login first to get your referral code" type="error" />
				)}
			</div>

			<BottomPromoAndDeals isLoading={isLoadingBanner} banners={banners} />
		</AppLayout>
	);
}

const ReferralCodeContainer = styled(Row)`
	background: linear-gradient(93.13deg, #e41462 -15%, #ffda74 99.39%);
	padding: 20px;
	border-radius: 16px;
	position: relative;
	overflow: hidden;
	width: 350px;

	.rectangle {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		margin: auto;
		height: 100%;
	}

	.dot {
		position: absolute;
		background-color: #fff;
		height: 22px;
		width: 22px;
		border-radius: 50%;
	}

	.dot.right {
		right: -11px;
		top: 50%;
		z-index: 2;
	}

	.dot.left {
		left: -11px;
		top: 50%;
		z-index: 2;
	}
`;
