import React, { forwardRef, useMemo } from 'react';
import moment from 'moment';
import Detail from './Detail';

type refType = {};

interface IProps {
	isDoctor?: boolean;
	isSecretary?: boolean;
	isPatient?: boolean;
	item?: any;
	index?: any;
	upcomingIndex?: number;
	fetchList?: () => Promise<void>;
}

const ItemGroup = forwardRef<refType, IProps>(
	(
		{ isDoctor, isSecretary, isPatient, item, index, upcomingIndex, fetchList },
		ref,
	) => {
		const isToday = useMemo(
			() =>
				item?.date &&
				moment(item?.date, 'YYYY-MM-DD').format('DD MMM YYYY') ===
					moment(new Date()).format('DD MMM YYYY'),
			[item?.date],
		);

		const isUpcoming = useMemo(
			() =>
				moment(item?.date, 'YYYY-MM-DD').format('DD MMM YYYY') >
				moment(new Date()).format('DD MMM YYYY'),
			[item?.date],
		);

		return (
			<div className="item-group">
				{item?.data?.length > 1 && <div className="line" />}
				{isToday ? (
					<h3 className="date-header">
						{`Today, ${
							item?.date &&
							moment(item?.date, 'YYYY-MM-DD').format('DD MMM YYYY')
						}`}
					</h3>
				) : (
					<>
						<h3 className="date-header">
							{isUpcoming
								? index === upcomingIndex && 'Upcoming Schedule'
								: item?.date &&
								  moment(item?.date, 'YYYY-MM-DD').format('DD MMM YYYY')}
						</h3>

						{isUpcoming && (
							<p className="date">
								{item?.date &&
									moment(item?.date, 'YYYY-MM-DD').format('DD MMM YYYY')}
							</p>
						)}
					</>
				)}
				<div>
					{item?.data?.map((detail: any, index: number) => (
						<Detail
							key={index}
							isDoctor={isDoctor}
							isSecretary={isSecretary}
							isPatient={isPatient}
							item={detail}
							index={index}
							itemGroup={item}
							fetchList={fetchList}
						/>
					))}
				</div>
			</div>
		);
	},
);
export default ItemGroup;
