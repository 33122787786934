import React from 'react';
import { Alert } from 'antd';
import { EKycStatus } from '../../types/doctor.type';
import { useAuth } from '../../context/auth.context';

export default function AlertDoctorKYCStatus() {
	const { doctorKYCStatus } = useAuth();

	if (doctorKYCStatus === EKycStatus.WAITING_APPROVAL) {
		return (
			<Alert
				type="warning"
				message="Your account is still waiting approval."
				description="You can use full features if your account has been approved."
				showIcon
				style={{ marginBottom: 20 }}
			/>
		);
	} else if (doctorKYCStatus === EKycStatus.REJECTED) {
		return (
			<Alert
				type="error"
				message="Your account is rejected."
				description="Please fix your data and resubmit your registration data."
				showIcon
				style={{ marginBottom: 20 }}
			/>
		);
	}
	return <></>;
}
