import { PartnerProperties } from './partner.type';

export type DrugMedicineDoseProperties = {
	doseId?: string;
	drugMedicineId?: string;
	dose: string;
	description?: string;
	createdAt?: string;
	updatedAt?: string;
};

export type DrugMedicineCategoryModelProperties = {
	drugMedicineId: string;
	categoryId: string;
};

export type DrugMedicineCategoryProperties = {
	categoryId: string;
	categoryName: string;
	isPublished: boolean;
	createdAt: string;
	updatedAt: string;
	drugMedicineCategoryModel: DrugMedicineCategoryModelProperties;
};

export type DrugMedicineProperties = {
	drugMedicineId?: string;
	drugMedicineName?: string;
	drugMedicineGenericName?: string;
	sku?: string;
	description?: string;
	isPublished?: boolean;
	compositions?: string;
	indications?: string;
	contraIndications?: string;
	dose?: string;
	doseNote?: string;
	note?: string;
	vendorId?: string;
	vendorName?: string;
	adverseDrugReactions?: string;
	pregnancyCategory?: string;
	administration?: string;
	createdByUserId?: string;
	metaCreatedByUser?: string;
	createdAt?: string;
	updatedAt?: string;
	categories?: DrugMedicineCategoryProperties[];
	doses?: DrugMedicineDoseProperties[];
	points?: number;
	partner?: PartnerProperties;
};

export type EditDrugMedicineProperties = {
	quantity?: number;
	sig?: string;
} & DrugMedicineProperties;

export const initialDrugMedicine: DrugMedicineProperties = {
	drugMedicineId: undefined,
	drugMedicineName: '',
	drugMedicineGenericName: undefined,
	sku: undefined,
	description: undefined,
	compositions: undefined,
	indications: undefined,
	contraIndications: undefined,
	dose: undefined,
	doseNote: undefined,
	note: undefined,
	vendorId: undefined,
	vendorName: undefined,
	isPublished: undefined,
	categories: undefined,
	doses: undefined,
	administration: undefined,
	adverseDrugReactions: undefined,
	createdByUserId: undefined,
	metaCreatedByUser: undefined,
	pregnancyCategory: undefined,
	createdAt: undefined,
	updatedAt: undefined,
};
