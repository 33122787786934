import { Card, Typography } from 'antd';

const { Text, Title } = Typography;

type Props = {
	deltaWeight: number;
	idealWeight: number;
	message: string;
};

export default function ResultSectionSuggestions(Props: Props) {
	const { deltaWeight, idealWeight, message } = Props;
	return (
		<>
			<Card style={SectionSuggestionContainerStyles}>
				<Title level={5} style={centering}>
					Your ideal body weight
				</Title>
				<Title level={3} style={IdealBodyWeightStyles}>
					{idealWeight} kg
				</Title>
				{deltaWeight > 0 ? (
					<Text
						style={centering}
					>{`You need to ${message} your weight by ${deltaWeight} kg`}</Text>
				) : (
					<Text style={centering}>Your current body weight is ideal</Text>
				)}
			</Card>
		</>
	);
}

const centering: React.CSSProperties = {
	textAlign: 'center',
};

const SectionSuggestionContainerStyles: React.CSSProperties = {
	borderRadius: 12,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
};

const IdealBodyWeightStyles: React.CSSProperties = {
	fontWeight: 500,
	marginTop: 0,
	textAlign: 'center',
};
