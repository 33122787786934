import React from 'react';

interface GlobalContextType {
	isShowModalChooseRole: boolean;
	setIsShowModalChooseRole: (val: boolean) => void;
	isShowSignInModal: boolean;
	setIsShowSignInModal: (val: boolean) => void;
}

let GlobalContext = React.createContext<GlobalContextType>(null!);

export function GlobalProvider({ children }: { children: React.ReactNode }) {
	let [isShowModalChooseRole, setIsShowModalChooseRole] =
		React.useState<boolean>(false);
	const [isShowSignInModal, setIsShowSignInModal] =
		React.useState<boolean>(false);

	let value = {
		isShowModalChooseRole,
		setIsShowModalChooseRole,
		isShowSignInModal,
		setIsShowSignInModal,
	};

	return (
		<GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
	);
}

export function useGlobal() {
	return React.useContext(GlobalContext);
}
