import { Image } from 'antd';
import { useHistory } from 'react-router-dom';
import { openInNewTab } from '../../../screens/app/Articles';
import { BannerProps } from '../../../types/banner.type';
import DEFAULT_IMAGE from '../../../assets/default-image.jpeg';

export const handleClickPromoDealItem = (
	bannerType: string,
	data: BannerProps,
	history: any,
) => {
	switch (bannerType) {
		case 'ARTICLE':
			history.push(`/articles/${data.relatedContentId}`, {
				bannerId: data?.bannerId,
			});
			break;
		case 'VOUCHER':
			history.push(`/voucher/${data.relatedContentId}`, {
				bannerId: data?.bannerId,
			});
			break;

		case 'MEDICAL_EVENT':
			history.push(`/medical-event/${data.relatedContentId}`, {
				content: data,
				bannerId: data?.bannerId,
			});
			break;
		case 'OWN_CONTENT':
			history.push('/own-content', {
				content: data,
				bannerId: data?.bannerId,
			});
			break;

		case 'REFER_A_FRIEND':
			history.push('/referral');
			break;
		case 'URL':
			openInNewTab(data.relatedContentUrl || '');
			break;
		default:
			break;
	}
};

const PromoDealItem = ({
	banner,
	isDetail,
	bgVariant,
}: {
	banner: BannerProps;
	isDetail?: boolean;
	bgVariant?: 'white' | 'gray';
}) => {
	const history = useHistory();

	return (
		<Image
			src={banner.bannerImageUrl ? banner.bannerImageUrl : DEFAULT_IMAGE}
			alt={banner.bannerImageUrl}
			style={{
				borderRadius: 10,
				cursor: 'pointer',
			}}
			fallback={DEFAULT_IMAGE}
			preview={false}
			onClick={() =>
				handleClickPromoDealItem(banner.bannerType, banner, history)
			}
			width={isDetail ? '100%' : 302}
			height={isDetail ? (bgVariant === 'white' ? 150 : 212) : 145}
		/>
	);
};

export default PromoDealItem;
