import { useEffect } from 'react';
import {
	getToken,
	getUser,
	removeToken,
	removeUser,
	saveToken,
	saveUser,
} from '../helpers/auth';
import { createGlobalState } from 'react-hooks-global-state';
import { IUser, initialUser } from '../types/user.type';
import { httpRequest } from '../helpers/api';
import { IHttpResponse } from '../helpers/pagination';
import { message } from 'antd';
import { getErrorMessage } from '../helpers/errorHandler';
import axios from 'axios';
import { DoctorSecretaryProperties } from '../types/doctorSecretaries.type';

type IAuthData = {
	isLoading?: boolean;
	token: string;
	user: IUser;
	lastFetched?: Date | null;
	// registerRole?: ERegisterRoleType | undefined;
	isSecretary?: boolean;
	secretaryManagedDoctors: DoctorSecretaryProperties[];
	isShowChooseRoleModal: boolean;
};

const initialState: IAuthData = {
	isLoading: true,
	token: '',
	user: { ...initialUser },
	lastFetched: null,
	// registerRole: undefined,
	isSecretary: false,
	secretaryManagedDoctors: [],
	isShowChooseRoleModal: false,
};
const { useGlobalState } = createGlobalState(initialState);

export default function useAuth() {
	const [isLoading, setIsLoading] = useGlobalState('isLoading');
	const [token, setToken] = useGlobalState('token');
	const [user, setUser] = useGlobalState('user');
	const [isSecretary, setIsSecretary] = useGlobalState('isSecretary');
	const [secretaryManagedDoctors, setSecretaryManagedDoctors] = useGlobalState(
		'secretaryManagedDoctors',
	);
	const [showChooseRoleModal, setShowChooseRoleModal] = useGlobalState(
		'isShowChooseRoleModal',
	);

	// console.info("isLoading", isLoading);

	useEffect(() => {
		init();
	}, []);

	const init = async () => {
		const tokenString = await getToken();
		const user = await getUser();
		if (tokenString) {
			setToken(tokenString);
		}
		if (user) {
			setUser(user);
		}
		setIsLoading(false);
	};

	const getProfile = async (dtToken?: string) => {
		try {
			setIsLoading(true);
			const resultUser = await axios.get<IHttpResponse<IUser>>(
				process.env.REACT_APP_BASE_URL + '/user/' + (user.userId || 'me'),
				{
					headers: {
						Authorization: 'Bearer ' + (dtToken || token),
					},
				},
			);
			setUser(resultUser.data.payload);
			setIsLoading(false);
		} catch (err) {
			message.error(getErrorMessage(err));
			setIsLoading(false);
		}
	};

	const saveProfile = async (data: IUser) => {
		setIsLoading(true);

		const formData = {
			name: data.name,
			email: data.email,
			phone: data.phone,
			userType: data.userType,
			status: data.status,

			firstName: data.firstName,
			middleName: data.middleName,
			lastName: data.lastName,
		};

		const imageData = new FormData();
		if (data.profilePic) imageData.append('image', data.profilePic);

		try {
			const resultUpdate = await httpRequest.patch<IHttpResponse<IUser>>(
				'/user/' + user.userId,
				formData,
			);
			if (data.profilePic) {
				await httpRequest.put('/user/' + user.userId + '/photo', imageData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				});
			}
			setUser(resultUpdate.data.payload);
			getProfile();
			setIsLoading(false);
			message.success('Update profile successfully');
		} catch (err) {
			message.error(getErrorMessage(err));
			setIsLoading(false);
		}
	};

	const login = async (data: { email: string; password: string }) => {
		try {
			const resultAuthLogin = await axios.post<
				IHttpResponse<{
					token: string;
				}>
			>(process.env.REACT_APP_BASE_URL + '/auth/signin', data);

			const resultUser = await axios.get<IHttpResponse<IUser>>(
				process.env.REACT_APP_BASE_URL + '/user/me',
				{
					headers: {
						Authorization: 'Bearer ' + resultAuthLogin.data.payload.token,
					},
				},
			);

			if (resultUser.data.payload.userType === 'admin') {
				handleAfterLogin({
					token: resultAuthLogin.data.payload.token,
					user: resultUser.data.payload,
				});
				message.success('Login successfully.');
				// message.success(`Hi, ${resultUser.data.payload.name}.`);
				setIsLoading(false);
			} else {
				logout();
				message.error('Please login with available admin account.');
				setIsLoading(false);
			}
		} catch (err) {
			message.error('Login failed. ' + getErrorMessage(err));
			setIsLoading(false);
		}
	};

	const handleAfterLogin = async (data: { token: string; user?: IUser }) => {
		await saveToken(data?.token);
		setToken(data?.token);

		if (data?.user) {
			await saveUser(data.user);
			console.log('user saved!:::', data.user);
		}
	};

	const logout = async () => {
		setUser({ ...initialUser });
		setToken('');
		await removeToken();
		await removeUser();

		// message.success('Logout successfully');
	};
	return {
		isLoading,
		isLoggedIn: !isLoading ? (token ? true : false) : undefined,
		token,
		user,
		userId: user?.userId,
		isDoctor: !!user?.doctor,
		doctorKYCStatus: user?.doctor?.kycStatus,
		isSecretary,
		isPatient: !!(user?.patient && !user?.doctor),
		secretaryManagedDoctors,
		handleAfterLogin,
		login,
		logout,
		getProfile,
		saveProfile,
		setUser,
		setIsSecretary,
		setSecretaryManagedDoctors,
		showChooseRoleModal,
		setShowChooseRoleModal,
	};
}
