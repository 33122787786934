import { useEffect } from 'react';
import { createGlobalState } from 'react-hooks-global-state';
import { httpRequest } from '../helpers/api';
import { SpecialistProperties } from '../types/doctor.type';
import { QuerySpecialist } from '../types/specialist.type';
import { generateQueryString } from '../helpers/generateQueryString';
import { ApiResponsePagination } from '../types/apiResponse.type';

type IAuthData = {
	isLoading?: boolean;
	specializations: SpecialistProperties[];
};

const initialState: IAuthData = {
	isLoading: true,
	specializations: [],
};
const { useGlobalState } = createGlobalState(initialState);

export default function useSpecialization() {
	const [isLoading, setIsLoading] = useGlobalState('isLoading');
	const [specializations, setSpecializations] =
		useGlobalState('specializations');

	useEffect(() => {
		fetchSpecialization({
			sort: 'specialistName:ASC',
		});
	}, []);

	const fetchSpecialization = async (query: QuerySpecialist) => {
		setIsLoading(true);
		try {
			const res = await httpRequest.get<
				ApiResponsePagination<SpecialistProperties>
			>('/specialists/' + generateQueryString(query));
			if (res.data.payload.results) {
				setSpecializations(res.data.payload.results);
			}
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			console.info('error fetch specialization ::: ', error);
		}
	};

	return {
		isLoading,
		specializations,
		fetchSpecialization,
	};
}
