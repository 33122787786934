const moment = (dateStr: string) => {
	const time = new Date(dateStr);
	const now = new Date();

	// If time elapsed is more than a year
	if (now.getFullYear() - time.getFullYear() > 0) {
		const output = `${now.getFullYear() - time.getFullYear()} year(s) ago`;
		return output;
	} else if (now.getMonth() - time.getMonth() > 0) {
		const output = `${now.getMonth() - time.getMonth()} month(s) ago`;
		return output;
	} else if (now.getDate() - time.getDate() > 1) {
		const output = `${now.getDate() - time.getDate()} day(s) ago`;
		return output;
	} else if (now.getDate() - time.getDate() === 1) {
		const output = `Yesterday, at ${time.getHours()}.${time.getMinutes()}`;
		return output;
	} else {
		const output = `Today, at ${time.getHours()}.${time.getMinutes()}`;
		return output;
	}
};

const Moment = {
	moment,
};

export default Moment;
