import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { theme } from '../../../../assets/theme';
import { calculateAge } from '../../../../helpers/datetime';
import { getName } from '../../../../helpers/name';
import { getBirthdate, getGender } from '../../../../helpers/profile';
import { LabRequestProperties } from '../../../../types/labRequest.type';
import { Button } from '../../../Button/Button/Button';
import EmptyList from '../../../EmptyList';
import Separator from '../../../Separator';
import { ReactComponent as LabRequest } from '../../../../assets/icons/doc-multi-choice-with-pen.svg';
import { Ellips } from '../../../../screens/app/BalanceScreen';
import { Typography } from 'antd';
import { ICDItemList } from '../../ICD/ICDItemList';

interface Props {
	labRequest: LabRequestProperties;
	nextStep: () => void;
}

export default function DetailLabRequestHistory(props: Props) {
	const history = useHistory();

	function editLabRequest() {
		history.push(
			`/app/rx-and-lab-request/create-lab-request?docId=${props.labRequest.labRequestId}`,
		);
	}

	return (
		<div className="flex-1 flex flex-col">
			<div className="py-6 px-3 flex-1">
				<div className="flex gap-x-3">
					<div className="w-1/4">
						<p className="m-0 font-semibold text-4">Lab Request Information</p>
					</div>
					<div className="flex-1 flex gap-x-3">
						<div className="flex-1">
							<p className="m-0 text-3.5 text-gray-60">Prescribed on</p>
							{/* <p className="m-0 mt-2">
                {moment(props.labRequest.createdAt).format('MMMM DD, YYYY')}
              </p> */}
							<div className="flex items-center gap-x-1 mt-3">
								<p className="m-0 text-4">
									{moment(props.labRequest?.createdAt).format('MMMM DD, YYYY')}
								</p>
								<Ellips />
								<Typography.Text style={{ fontSize: 14 }}>
									{props?.labRequest?.createdAt &&
										moment(props?.labRequest?.createdAt).format('H:mm:ss')}
								</Typography.Text>
							</div>
						</div>
						<div className="flex-1">
							<p className="m-0 text-3.5 text-gray-60">Valid Until</p>
							<p className="m-0 mt-2">
								{moment(props.labRequest.validUntil).format('MMMM DD, YYYY')}
							</p>
						</div>
					</div>
				</div>
				<div className="py-5">
					<Separator />
				</div>
				<div className="flex gap-x-3">
					<div className="w-1/4">
						<p className="m-0 font-semibold text-4">Patient Information</p>
					</div>
					<div className="flex-1">
						<div>
							<p className="m-0 text-3.5 text-gray-60">Patient ID</p>
							<p className="m-0 mt-2">{props.labRequest.patientId || '-'}</p>
						</div>
						<div className="mt-3">
							<p className="m-0 text-3.5 text-gray-60">Patient Name</p>
							<p className="m-0 mt-2">
								{getName(props.labRequest.metaPatient)}
							</p>
						</div>
						<div className="mt-3">
							<p className="m-0 text-3.5 text-gray-60">Age</p>
							<p className="m-0 mt-2">
								{calculateAge(getBirthdate(props.labRequest.metaPatient))} yrs.
								old
							</p>
						</div>
						<div className="mt-3">
							<p className="m-0 text-3.5 text-gray-60">Gender</p>
							<p className="m-0 mt-2">
								{getGender(props.labRequest.metaPatient)}
							</p>
						</div>
						<div className="mt-3">
							<p className="m-0 text-3.5 text-gray-60">Phone</p>
							<p className="m-0 mt-2">
								{props.labRequest.metaPatient.phone || '-'}
							</p>
						</div>
					</div>
				</div>
				<div className="py-5">
					<Separator />
				</div>
				<div className="flex gap-x-3">
					<div className="w-1/4">
						<p className="m-0 font-semibold text-4">ICD 10</p>
					</div>
					<div className="flex-1">
						{props.labRequest.icds?.map((item, index) => (
							<div key={item.id}>
								<div className={index === 0 ? 'pb-3' : 'py-3'}>
									<ICDItemList id={item.id} value={item.value} />
								</div>
								<Separator />
							</div>
						))}
					</div>
				</div>
				<div className="py-5">
					<Separator />
				</div>
				<div className="flex gap-x-3">
					<div className="w-1/4">
						<p className="m-0 font-semibold text-4">Detail Lab Request</p>
					</div>
					<div className="flex-1">
						<div>
							<p className="m-0 text-3.5 text-gray-60">Diagnose</p>
							<p className="m-0 mt-2">{props.labRequest.assessmentDiagnosis}</p>
						</div>
						<div className="mt-3">
							<p className="m-0 text-3.5 text-gray-60">
								Diagnostic Procedures(s)
							</p>
							<div className="mt-2">
								{props.labRequest.procedures &&
								props.labRequest.procedures.length >= 0 ? (
									props.labRequest.procedures.map((procedure) => (
										<p key={procedure.healthServiceId} className="m-0">
											{procedure.metaHealthService.healthServiceName}
										</p>
									))
								) : (
									<EmptyList
										icon={
											<LabRequest
												width={48}
												height={48}
												color={theme.colors.black50}
											/>
										}
										title="Lab Request Empty"
										description="Lab requests that you add will appear here"
									/>
								)}
							</div>
						</div>
						<div className="mt-3">
							<p className="m-0 text-3.5 text-gray-60">Special Instructions</p>
							<p
								className={
									'm-0 mt-2' +
									(!props.labRequest.specialInstructions
										? ' text-black-70'
										: '')
								}
							>
								{props.labRequest.specialInstructions ?? 'None'}
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className="py-3 px-4">
				<div className="flex gap-x-2.5 max-w-sm ml-auto">
					<Button type="TRANSPARENT" onClick={editLabRequest}>
						Edit
					</Button>
					<Button type="SOLID" onClick={props.nextStep}>
						Preview
					</Button>
				</div>
			</div>
		</div>
	);
}
