import React from 'react';
import { Row, Button, Typography, Col } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import styled from 'styled-components';

interface PageHeaderProps {
	title: string;
	onClick?: () => void;
	rightmostChild?: any; // masukin component
}

export default function PageHeader({
	title,
	onClick,
	rightmostChild,
}: PageHeaderProps) {
	const { Title } = Typography;
	return (
		<Row
			justify="start"
			align="middle"
			style={{ position: 'relative', height: 60 }}
		>
			<CustomButton type="text" icon={<ArrowLeftOutlined />} onClick={onClick}>
				Back
			</CustomButton>
			<Title
				style={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					// width: 141,
				}}
				level={5}
			>
				{title}
			</Title>
			{rightmostChild && (
				<div
					style={{
						width: 'auto',
						height: 'auto',
						position: 'absolute',
						right: 0,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					{rightmostChild}
				</div>
			)}
		</Row>
	);
}

const CustomButton = styled(Button)`
	width: 81px;
	height: 42px;
	font-size: 14px;
	font-weight: 700;
	color: #1d2b36;
	border-radius: 12px;
	margin-right: 5px;
`;
