import { createGlobalState } from 'react-hooks-global-state';
import { GeneralPatientProperties } from '../types/generalPatient.type';
import { AddressProps } from '../types/address.type';
import { DoctorProperties, SpecialistProperties } from '../types/doctor.type';
import { DoctorDocumentProperties } from '../types/doctorDocument.type';
import { DoctorPartnerScheduleProperties } from '../types/doctorPartnerSchedule';
import { PackageMedicalService } from '../types/medicalService.type';
import { EPartnerType, PartnerProperties } from '../types/partner.type';
import { PartnerDoctorProperties } from '../types/partnerDoctor.type';

export enum EConsultationType {
	FACE_TO_FACE_CONSULTATION = 'FACE_TO_FACE_CONSULTATION',
	TELEMEDICINE_VIDEO_CHAT_CONSULTATION = 'TELEMEDICINE_VIDEO_CHAT_CONSULTATION',
	TELEMEDICINE_CHAT_CONSULTATION = 'TELEMEDICINE_CHAT_CONSULTATION',
	CONSULT_NOW_VIDEO_CHAT_CONSULTATION = 'CONSULT_NOW_VIDEO_CHAT_CONSULTATION',
	CONSULT_NOW_CHAT_CONSULTATION = 'CONSULT_NOW_CHAT_CONSULTATION',
}

type BookConsultationState = {
	isLoadingFetchDoctor: boolean;
	isLoadingOrderConsultation: boolean;
	currentPartnerDoctors: PartnerDoctorProperties[];
	currentPartnerDoctorInClinics: PartnerDoctorProperties[];
	selectedClinic: PartnerDoctorProperties | undefined;
	currentPartnerType: EPartnerType;
	selectedDate: string;
	selectedTime: string | undefined;
	reasonOfConsultation: string | undefined;
	unavailableWeek: string[];
	doctorInfo: DoctorProperties | undefined;
	partnerInfo: PartnerProperties | undefined;
	selectedRelative: GeneralPatientProperties | undefined;
	filterSelectedSpecialists: SpecialistProperties[];
	filterDoctor: {
		priceStart: string;
		priceEnd: string;
		availability: string[];
	};
	medServicePackageInfo: PackageMedicalService | undefined;
	totalNeedToPay: number;
	telemedicinePrice?: number;
	inPersonPrice?: number;
	medicalServicePrice?: number;
	consultNowPrice?: number;
	noNeedPayment: boolean;
	doctorDocInfo: DoctorDocumentProperties | undefined;
	doctorDocPrice?: number;

	selectedAddress?: AddressProps;
	currentPartnerMedicalServiceSchedules: DoctorPartnerScheduleProperties[];
	healthServicePrice: number;
};

const initialState: BookConsultationState = {
	isLoadingFetchDoctor: false,
	isLoadingOrderConsultation: false,
	currentPartnerDoctors: [],
	currentPartnerDoctorInClinics: [],
	selectedClinic: undefined,
	currentPartnerType: EPartnerType.ONLINE_CLINIC,
	selectedDate: '',
	selectedTime: undefined,
	reasonOfConsultation: undefined,
	unavailableWeek: [],
	doctorInfo: undefined,
	partnerInfo: undefined,
	selectedRelative: undefined,
	filterSelectedSpecialists: [],
	filterDoctor: {
		priceStart: '',
		priceEnd: '',
		availability: [],
	},
	medServicePackageInfo: undefined,
	totalNeedToPay: 0,
	telemedicinePrice: undefined,
	inPersonPrice: undefined,
	medicalServicePrice: undefined,
	consultNowPrice: undefined,
	noNeedPayment: false,
	doctorDocInfo: undefined,
	doctorDocPrice: undefined,

	selectedAddress: undefined,
	currentPartnerMedicalServiceSchedules: [],
	healthServicePrice: 0,
};

const { useGlobalState } = createGlobalState(initialState);

export default function useBookConsultation() {
	const [isLoadingFetchDoctor, setIsLoadingFetchDoctor] = useGlobalState(
		'isLoadingFetchDoctor',
	);
	const [isLoadingOrderConsultation, setIsLoadingOrderConsultation] =
		useGlobalState('isLoadingOrderConsultation');
	const [currentPartnerDoctors, setCurrentPartnerDoctors] = useGlobalState(
		'currentPartnerDoctors',
	);
	const [currentPartnerDoctorInClinics, setCurrentPartnerDoctorInClinics] =
		useGlobalState('currentPartnerDoctorInClinics');
	const [selectedClinic, setSelectedClinic] = useGlobalState('selectedClinic');
	const [currentPartnerType, setCurrentPartnerType] =
		useGlobalState('currentPartnerType');
	const [selectedDate, setSelectedDate] = useGlobalState('selectedDate');
	const [selectedTime, setSelectedTime] = useGlobalState('selectedTime');
	const [reasonOfConsultation, setReasonOfConsultation] = useGlobalState(
		'reasonOfConsultation',
	);
	const [unavailableWeek, setUnavailableWeek] =
		useGlobalState('unavailableWeek');
	const [doctorInfo, setDoctorInfo] = useGlobalState('doctorInfo');
	const [partnerInfo, setPartnerInfo] = useGlobalState('partnerInfo');
	const [filterSelectedSpecialists, setFilterSelectedSpecialists] =
		useGlobalState('filterSelectedSpecialists');
	const [filterDoctor, setFilterDoctor] = useGlobalState('filterDoctor');
	const [selectedRelative, setSelectedRelative] =
		useGlobalState('selectedRelative');
	const [medServicePackageInfo, setMedServicePackageInfo] = useGlobalState(
		'medServicePackageInfo',
	);
	const [totalNeedToPay, setTotalNeedToPay] = useGlobalState('totalNeedToPay');
	const [telemedicinePrice, setTelemedicinePrice] =
		useGlobalState('telemedicinePrice');
	const [inPersonPrice, setInPersonPrice] = useGlobalState('inPersonPrice');
	const [medicalServicePrice, setMedicalServicePrice] = useGlobalState(
		'medicalServicePrice',
	);
	const [consultNowPrice, setConsultNowPrice] =
		useGlobalState('consultNowPrice');
	const [noNeedPayment, setNoNeedPayment] = useGlobalState('noNeedPayment');
	const [doctorDocInfo, setDoctorDocInfo] = useGlobalState('doctorDocInfo');
	const [doctorDocPrice, setDoctorDocPrice] = useGlobalState('doctorDocPrice');
	const [selectedAddress, setSelectedAddress] =
		useGlobalState('selectedAddress');
	const [
		currentPartnerMedicalServiceSchedules,
		setCurrentPartnerMedicalServiceSchedules,
	] = useGlobalState('currentPartnerMedicalServiceSchedules');
	const [healthServicePrice, setHealthServicePrice] =
		useGlobalState('healthServicePrice');

	const clearDataOrder = (
		excludes?: ('selectedAddress' | 'transportFee')[],
	) => {
		setPartnerInfo(undefined);
		setDoctorInfo(undefined);
		setUnavailableWeek([]);
		setReasonOfConsultation(undefined);
		setSelectedTime(undefined);
		setSelectedClinic(undefined);
		setSelectedDate('');
		setSelectedRelative(undefined);
		setCurrentPartnerDoctorInClinics([]);
		setCurrentPartnerDoctors([]);
		setMedicalServicePrice(undefined);
		setMedServicePackageInfo(undefined);
		setNoNeedPayment(false);
		setDoctorDocInfo(undefined);
		!excludes?.includes('selectedAddress') && setSelectedAddress(undefined);
		setHealthServicePrice(0);
		setDoctorDocPrice(undefined);
	};

	const setFilterSpecialist = (newSpecialist: SpecialistProperties) => {
		// console.info('setFilterSpecialist ::: ', JSON.stringify(newSpecialist))
		setFilterSelectedSpecialists((oldValue) => {
			const found = oldValue.find(
				(item) => item.specialistId === newSpecialist.specialistId,
			);

			if (!found) {
				console.info('not found');
				return oldValue.concat({ ...newSpecialist }).sort((a, b) => {
					return a.selected === b.selected ? 0 : a.selected ? -1 : 1;
				});
			} else {
				console.info('found', JSON.stringify(oldValue));
				return oldValue.map((item) =>
					item.specialistId === found.specialistId
						? { ...item, selected: !item.selected }
						: item,
				);
			}
		});
	};

	const resetFilterSpecialist = () => {
		setFilterSelectedSpecialists([]);
		setFilterDoctor({
			priceStart: '',
			priceEnd: '',
			availability: [],
		});
	};

	return {
		currentPartnerDoctors,
		currentPartnerDoctorInClinics,
		currentPartnerType,
		selectedDate,
		selectedTime,
		selectedClinic,
		reasonOfConsultation,
		unavailableWeek,
		doctorInfo,
		partnerInfo,
		filterSelectedSpecialists,
		filterDoctor,
		selectedRelative,
		medServicePackageInfo,
		totalNeedToPay,
		telemedicinePrice,
		inPersonPrice,
		medicalServicePrice,
		consultNowPrice,
		noNeedPayment,
		doctorDocInfo,
		doctorDocPrice,
		selectedAddress,
		currentPartnerMedicalServiceSchedules,
		healthServicePrice,

		setCurrentPartnerDoctors,
		setCurrentPartnerDoctorInClinics,
		setCurrentPartnerType,
		setSelectedDate,
		setSelectedTime,
		setSelectedClinic,
		setReasonOfConsultation,
		setUnavailableWeek,
		setDoctorInfo,
		setPartnerInfo,
		clearDataOrder,
		setFilterSpecialist,
		resetFilterSpecialist,
		setFilterDoctor,
		setSelectedRelative,
		setMedServicePackageInfo,
		setTotalNeedToPay,
		setTelemedicinePrice,
		setInPersonPrice,
		setMedicalServicePrice,
		setConsultNowPrice,
		setNoNeedPayment,
		setDoctorDocInfo,
		setDoctorDocPrice,
		setSelectedAddress,
		setCurrentPartnerMedicalServiceSchedules,
		setHealthServicePrice,
	};
}
