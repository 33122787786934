import { Col, Divider, Modal, Row, Button, Typography } from 'antd';
import { useState } from 'react';
import { iconDeleteOutline as IconDeleteOutline } from '../../../assets/icons';

const { Text, Paragraph } = Typography;

type Props = {
	isVisible: boolean;
	setVisibility: React.Dispatch<React.SetStateAction<boolean>>;
	removeSecretaryHandler: () => Promise<void>;
};

export default function ModalRemoveSecretary(props: Props) {
	const [isLoading, setIsLoading] = useState(false);
	function cancelRemove() {
		props.setVisibility(false);
	}

	async function removeSecretaryHandler() {
		setIsLoading(true);
		await props.removeSecretaryHandler();
		setIsLoading(false);
	}

	return (
		<Modal
			className="modal"
			open={props.isVisible}
			footer={null}
			closable={false}
		>
			<div className="flex flex-col items-center">
				<div className="icon">
					<IconDeleteOutline width={22} height={24} />
				</div>
				<Text style={{ marginBottom: 10 }} className="text-5 font-semibold">
					Remove Secretary
				</Text>
				<Paragraph style={{ marginBottom: 0 }}>
					This secretary won't be able to see and manage your schedule anymore.
				</Paragraph>
			</div>
			<Divider style={{ margin: '12px 0px' }} />
			<Row gutter={24} className="mt-2.5">
				<Col span={12}>
					<Button
						type="text"
						size="large"
						onClick={cancelRemove}
						className="text-3.5 text-secondary rounded-3 bg-gray-10 border-0 w-full font-bold"
					>
						Cancel
					</Button>
				</Col>
				<Col span={12}>
					<Button
						type="primary"
						size="large"
						loading={isLoading}
						style={{
							color: '#fff',
						}}
						onClick={removeSecretaryHandler}
						className="text-3.5 text-white rounded-3 bg-primary w-full font-bold"
					>
						Remove
					</Button>
				</Col>
			</Row>
		</Modal>
	);
}
