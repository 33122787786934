import { useEffect, useState } from 'react';
import { Plus, Trash2 } from 'react-feather';
import { theme } from '../../../../assets/theme';
import {
	PrescriptionTemplateItemProperties,
	TemplateRxPrescriptionProperties,
} from '../../../../types/templateRxPrescription.type';
import IconButton from '../../../Button/IconButton';
import Separator from '../../../Separator';
import { ReactComponent as Logo } from '../../../../assets/icons/logo.svg';
import { ReactComponent as Medicines } from '../../../../assets/icons/medicines.svg';
import EmptyList from '../../../EmptyList';
import {
	apiCreateTemplateRxPrescription,
	apiDeleteTemplateRxPrescription,
	apiUpdateTemplateRxPrescription,
} from '../../../../api/templateRxPrescription';
import { useAuth } from '../../../../context/auth.context';
import { Button } from '../../../Button/Button/Button';
import FormInput from '../../../Form/FormInput2';
import ModalAddMedicine from '../CreateRx/Components/Modal/ModalAddMedicine';
import {
	RxPrescriptionItem,
	RxPrescriptionProperties,
} from '../../../../types/rxPrescription.type';
import { useHistory } from 'react-router-dom';
import { Modal, Typography, Button as ButtonAntd } from 'antd';
import COLORS from '../../../../assets/globalColors';

const { Text } = Typography;

interface Props {
	step: number;
	template?: Partial<TemplateRxPrescriptionProperties>;
	setTemplate: React.Dispatch<
		React.SetStateAction<Partial<TemplateRxPrescriptionProperties> | undefined>
	>;
	setNextStep: () => void;
	onBack: () => void;
}

export default function FormCreateRxTemplate(props: Props) {
	const { user } = useAuth();
	const history = useHistory();

	const [isLoadingSave, setIsLoadingSave] = useState(false);
	const [isLoadingDelete, setIsLoadingDelete] = useState(false);
	const [showModalAddMedicine, setShowModalAddMedicine] = useState(false);
	const [showModalSave, setShowModalSave] = useState<boolean>(false);

	const [templateName, setTemplateName] = useState<string>();
	const [prescription, setPrescription] = useState<RxPrescriptionProperties>();

	useEffect(() => {
		if (!props.template?.templatePrescriptionItems) return;

		if (props.template.templateName) {
			setTemplateName(props.template.templateName);
		}

		const prescriptionItems: RxPrescriptionItem[] = [];
		for (const item of props.template.templatePrescriptionItems) {
			const pitem: RxPrescriptionItem = {
				drugMedicineDetail: item.drugMedicineDetail!,
				drugMedicineId: item.drugMedicineId,
				quantity: item.quantity,
				sig: item.sig,
			};
			prescriptionItems.push(pitem);
		}
		setPrescription((old) => ({ ...old, prescriptionItems }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.template]);

	function deleteItem(index: number) {
		const newPrescription = [...prescription!.prescriptionItems!];
		newPrescription.splice(index, 1);
		setPrescription((old) => ({
			...old,
			prescriptionItems: newPrescription,
		}));
	}

	async function saveTemplate() {
		if (
			!templateName ||
			!prescription?.prescriptionItems ||
			prescription.prescriptionItems.length === 0
		)
			return;

		setIsLoadingSave(true);

		let isSuccess = false;

		const templatePrescriptionItems: PrescriptionTemplateItemProperties[] = [];
		for (const item of prescription.prescriptionItems) {
			const tpitem: PrescriptionTemplateItemProperties = {
				drugMedicineId: item.drugMedicineId,
				quantity: item.quantity,
				sig: item.sig,
				drugMedicineDetail: item.drugMedicineDetail,
			};
			templatePrescriptionItems.push(tpitem);
		}

		const handleSubmitData = async () => {
			if (props.template?.templateRxPrescriptionId) {
				const res = await apiUpdateTemplateRxPrescription(
					props.template.templateRxPrescriptionId,
					{
						doctorId: user?.userId,
						templateName: templateName,
						templatePrescriptionItems,
						templateRxPrescriptionId: props.template.templateRxPrescriptionId,
					},
				);
				if (res) {
					props.setTemplate(res);
					isSuccess = true;
					return res.templateRxPrescriptionId;
				}
			} else {
				const res = await apiCreateTemplateRxPrescription({
					doctorId: user?.userId || '',
					templateName: templateName,
					templatePrescriptionItems,
				});
				if (res) {
					props.setTemplate(res);
					isSuccess = true;

					return res.templateRxPrescriptionId;
				}
			}
		};

		const data = await handleSubmitData();

		if (isSuccess) {
			props.onBack();
			return data;
		} else {
			setIsLoadingSave(false);
			return data;
		}
	}

	async function deleteTemplate() {
		if (!props.template?.templateRxPrescriptionId) return;

		setIsLoadingDelete(true);

		const res = await apiDeleteTemplateRxPrescription(
			props.template.templateRxPrescriptionId,
		);

		if (res) {
			props.onBack();
		} else {
			setIsLoadingDelete(false);
		}
	}

	return (
		<div>
			<form className="flex-1">
				<div className="py-6 px-3">
					<div>
						<div className="flex gap-x-3">
							<div className="pt-2 w-1/4">
								<p className="m-0 font-semibold text-4">Information</p>
							</div>
							<div className="flex-1">
								<FormInput
									required
									label="Template Name"
									placeholder="Template name"
									value={templateName}
									onChange={setTemplateName}
								/>
							</div>
						</div>
						<div className="py-4">
							<Separator />
						</div>
						<div className="flex gap-x-3">
							<div className="w-1/4">
								<p className="m-0 font-semibold text-4">Prescription</p>
							</div>
							<div className="flex-1">
								<div className="flex justify-between">
									<p className="m-0 text-4">
										Medicines <span style={{ color: COLORS.red }}>*</span>
									</p>
									<div>
										<button
											onClick={() => setShowModalAddMedicine(true)}
											type="button"
											className="border-none bg-transparent p-0 flex items-center gap-x-1 text-primary text-4 cursor-pointer"
										>
											<Plus width={24} height={24} />
											Add Medicine
										</button>
									</div>
								</div>
								<div className="mt-2">
									{prescription?.prescriptionItems &&
									prescription.prescriptionItems.length > 0 ? (
										prescription.prescriptionItems.map((item, index) => (
											<div key={item.drugMedicineDetail?.drugMedicineId}>
												<div className="py-3">
													<PrescriptionItem
														name={item.drugMedicineDetail?.drugMedicineName}
														dosage={item.drugMedicineDetail?.dose}
														qty={item.quantity}
														sig={item.sig}
														hasPoint={
															!!item.drugMedicineDetail.points &&
															!!item.drugMedicineDetail.partner
														}
														onDeleteItem={() => deleteItem(index)}
													/>
												</div>
												{index < prescription.prescriptionItems!.length - 1 && (
													<Separator />
												)}
											</div>
										))
									) : (
										<EmptyList
											icon={<Medicines color={theme.colors.black50} />}
											title="Prescription empty"
											description="You can add perscription from template or add medication manually"
										/>
									)}
								</div>
							</div>
						</div>
					</div>
					<div className="h-6" />
				</div>
			</form>
			<Separator />
			<div className="py-3 px-4 flex justify-between">
				<div className="w-44">
					{!!props.template?.templateRxPrescriptionId && (
						<Button
							type="SOLIDASH"
							onClick={deleteTemplate}
							isLoading={isLoadingDelete}
							isDisable={
								!props.template.templateRxPrescriptionId || isLoadingSave
							}
						>
							Delete
						</Button>
					)}
				</div>
				<div className="w-44">
					<Button
						onClick={() => setShowModalSave(true)}
						type="SOLID"
						isLoading={isLoadingSave}
						isDisable={
							!templateName ||
							!prescription?.prescriptionItems ||
							prescription.prescriptionItems.length === 0 ||
							isLoadingDelete
						}
					>
						Next
					</Button>
				</div>
			</div>
			<ModalAddMedicine
				isShow={showModalAddMedicine}
				onCloseModal={() => setShowModalAddMedicine(false)}
				setPrescription={setPrescription}
				isHideTemplate
			/>
			<Modal
				open={showModalSave}
				title={
					<div style={{ textAlign: 'center' }}>
						<Text strong>Use this template?</Text>
					</div>
				}
				onCancel={() => setShowModalSave(false)}
				footer={false}
			>
				<div className="flex p-3 justify-between">
					<div className="w-44">
						<Button
							type="SOLIDASH"
							onClick={async () => {
								await saveTemplate().then((res) => {
									history.push(
										`/app/rx-and-lab-request/create-rx?templateRxPrescriptionId=${res}`,
									);
								});
							}}
						>
							Use Template
						</Button>
					</div>
					<div className="w-44">
						<Button type="SOLID" onClick={saveTemplate}>
							Save
						</Button>
					</div>
				</div>
			</Modal>
		</div>
	);
}

function PrescriptionItem(props: {
	name?: string;
	dosage?: string;
	qty: number;
	sig?: string;
	hasPoint?: boolean;
	onDeleteItem: () => void;
}) {
	return (
		<div className="flex gap-x-2 items-center">
			<div className="flex-1">
				<div className="flex gap-x-1 items-center">
					<p className="m-0 line-clamp-1 text-4.5 font-medium">{props.name}</p>
					{props.hasPoint && <Logo width={20} height={20} />}
				</div>
				<div className="mt-2 flex gap-x-2.5">
					<p className="flex-1 m-0 text-3.5 text-black-70">{props.dosage}</p>
					<p className="flex-1 m-0 text-3.5 text-black-70">
						{props.qty ? `qty. ${props.qty}` : ''}
					</p>
				</div>
				<div>
					<p className="m-0 mt-2 text-3.5 text-black-70">{props.sig}</p>
				</div>
			</div>
			<IconButton onClick={props.onDeleteItem}>
				<Trash2 width={24} height={24} color={theme.colors.primary} />
			</IconButton>
		</div>
	);
}
