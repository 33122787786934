import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AppLayout from '../../../../layout/AppLayout';
import PageHeader from '../../../../components/PageHeader';
import { Button, Card, Divider, Row, Spin, Typography } from 'antd';
import {
	EPartnerType,
	PartnerProperties,
} from '../../../../types/partner.type';
import { apiGetPartnerDoctors } from '../../../../api/partnerDoctor';
import {
	apiAddPartner,
	apiDeleteDoctorAndSecretary,
	apiDeletePartner,
	apiGetSecretary,
} from '../../../../api/doctorSecretaries';
import ModalRemoveSecretary from '../../../../components/Webapp/Secretaries/ModalRemoveSecretary';
import SecretaryAccessList from '../../../../components/Webapp/Secretaries/SecretaryAccessList';
import ModalTogglePartner from '../../../../components/Webapp/Secretaries/ModalTogglePartner';
import { useAuth } from '../../../../context/auth.context';

const activePath = '/app/setting';
const { Text, Paragraph } = Typography;

export default function DetailSecretary() {
	const history = useHistory();
	const location: { state: any } = useLocation();
	const secretary: {
		secretaryId: string;
		secretaryName: string;
		partnerName: string;
		phone: string;
	} = location.state;

	const { userId } = useAuth();
	const [isLoading, setIsLoading] = useState(true);
	const [isLoadingToggle, setIsLoadingToggle] = useState(false);
	const [partnersTelemedicine, setPartnersTelemedicine] =
		useState<(PartnerProperties & { active: boolean })[]>();
	const [partnersInPerson, setPartnersInPerson] =
		useState<(PartnerProperties & { active: boolean })[]>();
	const [selectedPartner, setSelectedPartner] = useState<{
		partnerId: string;
		partnerName: string;
		active: boolean;
		type: 'TELEMEDICINE' | 'INPERSON';
	}>();
	const [isModalRemoveSecretaryVisible, setIsModalRemoveSecretaryVisible] =
		useState(false);
	const [isModalTogglePartner, setIsModalTogglePartner] = useState(false);

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			if (!userId || !secretary.secretaryId) return;
			try {
				const resPartner = await apiGetPartnerDoctors(userId);

				const telemedicine: PartnerProperties[] = [];
				const inPerson: PartnerProperties[] = [];

				resPartner.results.forEach((res) => {
					switch (res.partner?.partnerType) {
						case EPartnerType.CLINIC:
						case EPartnerType.HOSPITAL:
							inPerson.push(res.partner);
							break;
						case EPartnerType.ONLINE_CLINIC:
							telemedicine.push(res.partner);
							break;
					}
				});

				const resSecretaries = await apiGetSecretary(userId);

				if (resSecretaries.code !== 'success') {
					return;
				}

				const activeTelemedicine: string[] = [];
				const activeInPerson: string[] = [];
				resSecretaries.payload.results.forEach((res: any) => {
					res.partners.forEach((partner: PartnerProperties) => {
						switch (partner.partnerType) {
							case EPartnerType.CLINIC:
							case EPartnerType.HOSPITAL:
								activeInPerson.push(partner.partnerId);
								break;
							case EPartnerType.ONLINE_CLINIC:
								activeTelemedicine.push(partner.partnerId);
								break;
						}
					});
				});

				setPartnersTelemedicine(
					telemedicine.map((t) => {
						return {
							...t,
							active: activeTelemedicine.indexOf(t.partnerId) >= 0,
						};
					}),
				);
				setPartnersInPerson(
					inPerson.map((i) => {
						return { ...i, active: activeInPerson.indexOf(i.partnerId) >= 0 };
					}),
				);
			} catch (err) {
				console.error('ERROR ', err);
			} finally {
				setIsLoading(false);
			}
		})();
	}, [secretary, userId]);

	async function removeSecretaryHandler() {
		if (!userId) return;

		const res = await apiDeleteDoctorAndSecretary(
			userId,
			secretary.secretaryId,
		);

		if (res.code !== 'success') return;

		setIsModalRemoveSecretaryVisible(false);
		history.push('/app/setting/secretaries');
	}

	function togglePartnerConfirmDialog(
		partnerId: string,
		type: 'TELEMEDICINE' | 'INPERSON',
		status: boolean,
		partnerName?: string,
	) {
		setSelectedPartner({
			partnerId,
			partnerName: partnerName ? partnerName : '',
			active: !status,
			type,
		});
		setIsModalTogglePartner(true);
	}

	function cancelTogglePartnerDialog() {
		setSelectedPartner(undefined);
		setIsModalTogglePartner(false);
	}

	async function togglePartner(
		partnerId: string,
		type: 'TELEMEDICINE' | 'INPERSON',
		status: boolean,
	) {
		if (!userId || !secretary.secretaryId) return;

		setIsLoadingToggle(true);

		if (status) {
			const res = await apiAddPartner(userId, secretary.secretaryId, {
				partnerId,
			});
			if (res.code !== 'success') {
				setIsLoadingToggle(false);
				return;
			}
		} else {
			const res = await apiDeletePartner(
				userId,
				secretary.secretaryId,
				partnerId,
			);
			if (res.code !== 'success') {
				setIsLoadingToggle(false);
				return;
			}
		}

		if (type === 'TELEMEDICINE') {
			setPartnersTelemedicine(
				(prev) =>
					prev?.map((p) => {
						return {
							...p,
							active: p.partnerId === partnerId ? status : p.active,
						};
					}),
			);
		} else if (type === 'INPERSON') {
			setPartnersInPerson(
				(prev) =>
					prev?.map((p) => {
						return {
							...p,
							active: p.partnerId === partnerId ? status : p.active,
						};
					}),
			);
		}

		setIsLoadingToggle(false);
	}

	return (
		<AppLayout activePath={activePath}>
			<Card style={{ paddingBottom: 110 }}>
				<PageHeader title="Detail Secretary" onClick={() => history.goBack()} />
				<Divider />
				<div style={{ maxWidth: '60%' }}>
					<div className="mb-7">
						<div className="mb-2">
							<Row>
								<Text className="text-black-60">Name</Text>
							</Row>
							<Row>
								<Text className="text-black-10">{secretary.secretaryName}</Text>
							</Row>
						</div>
						<div className="mb-2">
							<Row>
								<Text className="text-black-60">Secretary's Workplace</Text>
							</Row>
							<Row>
								<Text className="text-black-10">{secretary.partnerName}</Text>
							</Row>
						</div>
						<div>
							<Row>
								<Text className="text-black-60">Telp. Number</Text>
							</Row>
							<Row>
								<Text className="text-black-10">{secretary.phone}</Text>
							</Row>
						</div>
					</div>
					<div>
						{isLoading ? (
							<Spin spinning={isLoading} />
						) : (
							<SecretaryAccessList
								isLoadingToggle={isLoadingToggle}
								partnersTelemedicine={partnersTelemedicine}
								partnersInPerson={partnersInPerson}
								togglePartner={togglePartnerConfirmDialog}
							/>
						)}
					</div>
				</div>{' '}
				<div className="flex justify-center mt-10">
					<Button
						type="text"
						className="text-3.5 font-bold"
						style={{ color: '#D7373F' }}
						onClick={() => setIsModalRemoveSecretaryVisible(true)}
					>
						Remove Secretary
					</Button>
				</div>
				<ModalRemoveSecretary
					isVisible={isModalRemoveSecretaryVisible}
					setVisibility={setIsModalRemoveSecretaryVisible}
					removeSecretaryHandler={removeSecretaryHandler}
				/>
				<ModalTogglePartner
					isVisible={isModalTogglePartner}
					setVisibility={setIsModalTogglePartner}
					selectedPartner={selectedPartner}
					confirm={togglePartner}
					cancel={cancelTogglePartnerDialog}
				/>
			</Card>
		</AppLayout>
	);
}
