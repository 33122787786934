import { Col, Image, Row, Spin, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { Tooltip, Button, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import DEFAULT_IMAGE from '../../../assets/default-image.jpeg';
import COLORS from '../../../assets/globalColors';
import { ReactComponent as IconEllipse1 } from '../../../assets/icons/icon-ellipse-1.svg';
import { ReactComponent as IconEllipse2 } from '../../../assets/icons/icon-ellipse-2.svg';
import {
	IconButtonAppStore,
	IconButtonGooglePlay,
} from '../../../assets/images';
import RenderHTML from '../../../components/RenderHTML';
import BottomPromoAndDeals from '../../../components/Webapp/Articles/BottomPromoAndDeals';
import SidebarArticle from '../../../components/Webapp/Articles/SidebarArticle';
import { useAuth } from '../../../context/auth.context';
import { httpRequest } from '../../../helpers/api';
import useFetchList from '../../../hooks/useFetchList';
import AppLayout from '../../../layout/AppLayout';
import { BannerProps } from '../../../types/banner.type';
import { BaseResponseProps2 } from '../../../types/config.type';
import { VoucherProps } from '../../../types/voucher.type';

const activePath = '/promo-deals';

export default function DetailVoucher() {
	const history = useHistory();
	const { isLoggedIn, isDoctor } = useAuth();
	const { voucherId } = useParams() as { voucherId: string };
	const [voucher, setVoucher] = useState<VoucherProps | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const copyToClipboard = (text: any) => {
		navigator.clipboard.writeText(text);
		message.success('Voucher code copied to clipboard');
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const { data: banners, isLoading: isLoadingBanner } =
		useFetchList<BannerProps>({
			endpoint: '/banners',
			//   limit: 3,
			initialQuery: {
				isPublished: true,
				target: isDoctor ? 'DOCTOR' : 'PATIENT',
			},
		});

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			try {
				const res = await httpRequest.get<BaseResponseProps2<VoucherProps>>(
					`/vouchers/${voucherId}?isPublished=true`,
				);

				if (res && res.data && res.data.payload) {
					setVoucher(res.data.payload);
				}
				setIsLoading(false);
			} catch (error) {
				setIsLoading(false);
				console.log(error);
			}
		})();
	}, [voucherId]);

	console.log(voucher);

	return (
		<AppLayout activePath={'/voucher'}>
			<div className={'container mt-12'}>
				{isLoading && (
					<div className="text-center my-10">
						<Spin spinning />
					</div>
				)}

				{!isLoading && (
					<Row gutter={[24, 24]} style={{ marginBottom: 100 }}>
						<Col span={24} lg={16}>
							<Image
								src={voucher?.imageUrl}
								alt={voucher?.voucherCode}
								style={{ borderRadius: 10 }}
								fallback={DEFAULT_IMAGE}
								preview={false}
								width="100%"
								height={350}
							/>
							<div style={{ marginTop: 30, marginBottom: 30 }}>
								<Typography.Title
									level={4}
									style={{ color: COLORS.gray_100 }}
									className="font-semibold"
								>
									{voucher?.voucherName}
								</Typography.Title>
								<div className="mb-3">
									<Typography.Title
										level={5}
										style={{ color: COLORS.gray_100, marginBottom: 0 }}
										className="font-semibold"
									>
										Detail
									</Typography.Title>
									<Typography.Text
										style={{ color: '#201D1D' }}
										className="text-4.5"
									>
										{voucher?.description}
									</Typography.Text>
								</div>
								<div className="mb-6">
									<Typography.Title
										level={5}
										style={{ color: COLORS.gray_100, marginBottom: 0 }}
										className="font-semibold"
									>
										Term and Condition
									</Typography.Title>
									<Typography.Text
										style={{ color: '#201D1D' }}
										className="text-4.5"
									>
										<RenderHTML content={voucher?.termAndCondition || '-'} />
									</Typography.Text>
								</div>
								<div
									style={{
										border: '1px solid #DCDCDC',
										boxShadow:
											'0px 10px 15px -3px rgba(152, 166, 192, 0.1), 0px 4px 6px -2px rgba(43, 54, 75, 0.05)',
										borderRadius: 18,
										height: 200,
										padding: '0px 24px',
									}}
									className="flex items-center relative overflow-hidden"
								>
									<IconEllipse1
										className="absolute top-0"
										style={{ left: '-20px' }}
									/>
									<IconEllipse2
										className="absolute top-0"
										style={{ right: '-10px' }}
									/>
									<div className="flex items-center justify-between w-full z-10">
										<div className="flex flex-col gap-y-2 w-1/2">
											<Typography.Text
												className="block text-primary font-semibold"
												style={{ fontSize: 32, lineHeight: '120%' }}
											>
												Discount Code
											</Typography.Text>

											<Typography.Text
												className="block text-4.5"
												style={{ color: '#535353' }}
											>
												Get a special discount by using our promo code when
												making a purchase!
											</Typography.Text>

											<Typography.Text className="text-primary font-medium text-4.5">
												Apply this code at checkout page
											</Typography.Text>
											<span
												style={{
													display: 'flex',
													justifyContent: 'space-between',
													alignItems: 'center',
													background: '#c61b5b',
													padding: '0.1rem 0.4rem',
													borderRadius: '10px',
													marginLeft: '0.1rem',
													margin: '1px',
													color: 'white',
													width: 'fit-content',
												}}
											>
												<span>{voucher?.voucherCode}</span>
												<Tooltip title="Copy code">
													<Button
														type="text"
														icon={<CopyOutlined style={{ color: 'white' }} />}
														onClick={() =>
															copyToClipboard(voucher?.voucherCode)
														}
													/>
												</Tooltip>
											</span>
										</div>
										<div className="flex flex-col gap-y-3">
											<Typography.Text className="text-primary font-medium text-4.5">
												Download Now
											</Typography.Text>
											<div className="flex gap-x-3">
												<a
													className="cursor-pointer"
													href="https://play.google.com/store/apps/details?id=com.medeasyapp&hl=en&gl=US"
													target="_blank"
													rel="noreferrer"
												>
													<IconButtonGooglePlay />
												</a>

												<a
													className="cursor-pointer"
													href="https://apps.apple.com/id/app/medeasy-ph/id6443653585?l=id"
													target="_blank"
													rel="noreferrer"
												>
													<IconButtonAppStore />
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</Col>
						<Col span={24} lg={8}>
							<div className="top-27 sticky">
								<SidebarArticle />
							</div>
						</Col>
					</Row>
				)}
			</div>

			<BottomPromoAndDeals isLoading={isLoadingBanner} banners={banners} />
		</AppLayout>
	);
}
