import { useState, useEffect } from 'react';
import { Modal, Typography, Row, Button, message } from 'antd';
import styled from 'styled-components';
import { IconRoleDoctor, IconRolePatient } from '../../assets/images';
import MessageOTP from './MessageOTP';
import { useOTPCountdown } from '../../hooks/useOTPCountdown';
import { AuthByPhoneSendOTPResponse } from '../../types/authByPhone.type';
import OtpInput from 'react18-input-otp';
import { apiSendOTP } from '../../api/auth';
import { getErrorMessage } from '../../helpers/errorHandler';
import { useAuth } from '../../context/auth.context';

const { Text, Paragraph, Title } = Typography;

interface SignInModalOTPProps {
	role: string;
	sessionId: string;
	otp: string;
	phoneNumber: string;
	showSignInModal: boolean;
	onConfirm: ({
		isNewAccount,
	}: {
		isNewAccount: boolean;
		isDoctor: boolean;
	}) => void;
	onCancel: () => void;
	onChange?: () => void;
}

function SignInModalOTP({
	role,
	sessionId,
	otp,
	showSignInModal,
	phoneNumber,
	onCancel,
	onChange,
	onConfirm,
}: SignInModalOTPProps) {
	// const [userOTP, setUserOTP] = useState<string>("");
	const [userOTP, setUserOTP] = useState<string>(otp);
	useEffect(() => {
		setUserOTP(otp);
	}, [otp]);

	const { signin } = useAuth();
	const [count, setCounter] = useState(2);
	const { alertOTP, timeLeft, previewTimer, resetTime } = useOTPCountdown(
		60,
		count,
	);

	const resendOTP = async (phoneNumber: string) => {
		try {
			const res: AuthByPhoneSendOTPResponse['data']['payload'] =
				await apiSendOTP(phoneNumber);
			if (res.otp) {
				setUserOTP(res.otp);
			}
		} catch (err) {
			message.error(
				'ERROR: Resend OTP failed, please try again! ' + getErrorMessage(err),
			);
		}
	};

	const confirmOTP = async (sessionId: string, otp: string) => {
		try {
			signin(sessionId, otp, (res) => {
				onConfirm({
					isNewAccount: !!res.isNewAccount,
					isDoctor: !!res.user?.doctor,
				});
			});
		} catch (err) {
			message.error(
				'ERROR: Login failed, please try again! ' + getErrorMessage(err),
			);
		}
	};

	const onClick = () => {
		confirmOTP(sessionId, userOTP);
	};

	return (
		<CustomModal
			centered
			visible={showSignInModal}
			footer={null}
			onCancel={onCancel}
		>
			<Row>{role === 'Patient' ? <IconRolePatient /> : <IconRoleDoctor />}</Row>
			<Row>
				<CustomModalTitle level={2}>Sign in as {role}</CustomModalTitle>
			</Row>
			<Row>
				<Text
					style={{
						fontSize: '18px',
						lineHeight: '20px',
						textAlign: 'center',
					}}
				>
					Enter 6 digit verification code that we send to this number
					<Text strong> +63 {phoneNumber}</Text>
				</Text>
			</Row>
			<Row>
				<CustomOtpInput
					value={userOTP}
					onChange={(e: any) => setUserOTP(e)}
					numInputs={6}
				/>
			</Row>
			<Row>
				<MessageOTP
					alertOTP={alertOTP}
					timeLeft={timeLeft}
					previewTimer={previewTimer(timeLeft)}
					setCounter={() => setCounter((prev) => prev + 1)}
					resetTime={() => resetTime()}
					resendOTP={() => resendOTP(phoneNumber)}
				/>
			</Row>
			<Button
				type="primary"
				onClick={onClick}
				block
				style={{ margin: '6px 0 6px 0' }}
			>
				Confirm
			</Button>
		</CustomModal>
	);
}

export default SignInModalOTP;

const CustomModalTitle = styled(Title)`
	font-weight: 700 !important;
	color: black !important;
	margin-bottom: 0 !important;
`;

const CustomModal = styled(Modal)`
	.ant-modal-title {
		font-size: 36px;
		font-weight: 700;
		color: black;
	}
	.ant-modal-body {
		display: grid;
		gap: 20px;
		display: flex;
		flex: 1;
		flex-direction: column;
		justify-content: center !important;
		align-items: center !important;
		padding: 3.75rem 3rem 3rem 3rem;
		max-width: 700px;
	}
	div.ant-typography {
		font-weight: 400;
		font-size: 16px;
		line-height: 22px;
		color: #748494;
	}

	.strong-primary {
		font-weight: 600 !important;
		color: #d81f64 !important;
	}
`;

const CustomOtpInput = styled(OtpInput)`
	input {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin: 8px 3px;
		gap: 10px;
		width: 75px !important;
		height: 95px !important;
		background: #ffffff;
		border: 1px solid #d5dce1;
		border-radius: 10px;
	}
`;
