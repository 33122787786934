import { isNumber } from 'lodash';
import { useState } from 'react';
import { CheckCircle, CheckSquare, Circle, Square } from 'react-feather';
import {
	apiCreateDoctorDocument,
	apiCreateTemplateDoctorDocument,
	apiDeleteDoctorDocument,
	apiUpdateDoctorDocument,
} from '../../../../api/doctorDocument';
import { theme } from '../../../../assets/theme';
import {
	DoctorDocumentProperties,
	EMetaType,
	MetaOthers,
} from '../../../../types/doctorDocument.type';
import { IUser } from '../../../../types/user.type';
import IconButton from '../../../Button/IconButton';
import CustomScrollDiv from '../../../CustomScrollDiv';
import FormInput, { EFormType } from '../../../Form/FormInput2';
import ButtonRemoveSave from './Components/ButtonRemoveSave';
import SidebarMenuHeader from './Components/SidebarMenuHeader';
import { useAuth } from '../../../../context/auth.context';

interface Props {
	patientProfile?: IUser;
	transactionConsultationId?: string;
	ownTemplate?: DoctorDocumentProperties;
	setOwnTemplate: React.Dispatch<
		React.SetStateAction<DoctorDocumentProperties | undefined>
	>;
	onBack: () => void;
}

export default function SidebarOwnTemplate(props: Props) {
	const { user } = useAuth();

	const [isLoading, setIsLoading] = useState(false);

	const [ownTemplate, setOwnTemplate] = useState(props.ownTemplate);
	const [templateName, setTemplateName] = useState<string>();

	async function removeOwnTemplate() {
		if (!props.ownTemplate?.doctorDocumentId) {
			props.onBack();
			return;
		}

		setIsLoading(true);
		const res = await apiDeleteDoctorDocument(
			props.ownTemplate.doctorDocumentId,
		);
		if (res?.isSuccess) {
			props.setOwnTemplate(undefined);
			props.onBack();
		} else {
			setIsLoading(false);
		}
	}

	async function saveOwnTemplate() {
		setIsLoading(true);

		let isSuccess = false;
		let data: DoctorDocumentProperties = {
			metaPatient: {
				firstName: props.patientProfile?.firstName,
				middleName: props.patientProfile?.middleName,
				lastName: props.patientProfile?.lastName,
				birthdate: props.patientProfile?.birthdate,
				gender: props.patientProfile?.gender?.toLowerCase(),
				patientId: props.patientProfile?.userId,
			},
			validUntil: ownTemplate?.validUntil ?? new Date().toISOString(),
			price: ownTemplate?.price ?? 0,
			meta: ownTemplate?.meta,
			isTemporary: true,
		};
		let doctorDocumentId = '';
		if (ownTemplate?.doctorDocumentId) {
			const res = await apiUpdateDoctorDocument(
				ownTemplate.doctorDocumentId,
				data,
			);
			if (res) {
				if (res.doctorDocumentId) {
					doctorDocumentId = res.doctorDocumentId;
				}
				props.setOwnTemplate(res);
				isSuccess = true;
			}
		} else {
			data = {
				...data,
				transactionConsultationId: props.transactionConsultationId,
				doctorId: user?.userId,
				type: EMetaType.OTHERS,
				patientId: props.patientProfile?.userId,
			};

			const res = await apiCreateDoctorDocument(data);
			if (res) {
				if (res.doctorDocumentId) {
					doctorDocumentId = res.doctorDocumentId;
				}
				props.setOwnTemplate(res);
				isSuccess = true;
			}
		}

		if (templateName && doctorDocumentId) {
			isSuccess = false;
			const res = await apiCreateTemplateDoctorDocument({
				doctorId: user?.userId || '',
				type: EMetaType.OTHERS,
				templateName: templateName,
				saveFromId: doctorDocumentId,
				price: ownTemplate?.price,
				meta: ownTemplate?.meta,
			});
			if (res) {
				isSuccess = true;
			}
		}

		if (isSuccess) {
			props.onBack();
		} else {
			setIsLoading(false);
		}
	}

	return (
		<div className="w-full flex flex-col">
			<SidebarMenuHeader onBack={props.onBack} title="Create Document" />
			<CustomScrollDiv className="relative flex overflow-auto w-full h-full">
				<div className="w-full">
					<div className="flex-1 pb-6">
						<div>
							<p className="m-0 text-4 text-charcoal-300">
								Use this document if you want to create custom doctor documents
							</p>
						</div>
						<div className="mt-5">
							<p className="m-0 text-4">Patient Information</p>
							<div className="mt-3 flex gap-x-2">
								<div className="w-15 h-15 rounded-full bg-charcoal-300 flex items-center justify-center">
									<span className="font-bold text-white">JS</span>
								</div>
								<div className="flex-1 flex flex-col justify-center">
									<p className="m-0 font-medium">Joel Scott Osteen</p>
									<p className="m-0 text-charcoal-200 text-3.5">Male • 46 yo</p>
								</div>
							</div>
						</div>
						<div>
							<FormInput
								label="Document title"
								type={EFormType.MULTI_LINE}
								rows={2}
								value={(ownTemplate?.meta as MetaOthers)?.title}
								onChange={(val) =>
									setOwnTemplate(
										(old) =>
											({
												...old,
												meta: {
													...old?.meta,
													title: val,
												} as MetaOthers,
											}) as any,
									)
								}
							/>
							<FormInput
								label="Custom Content"
								type={EFormType.MULTI_LINE}
								rows={7}
								value={(ownTemplate?.meta as MetaOthers)?.content}
								onChange={(val) =>
									setOwnTemplate(
										(old) =>
											({
												...old,
												meta: {
													...old?.meta,
													content: val,
												} as MetaOthers,
											}) as any,
									)
								}
							/>
						</div>
						<div>
							<p className="m-0 text-4">Document Price</p>
							<div className="flex items-center gap-x-2">
								<IconButton
									onClick={() =>
										setOwnTemplate((old) => ({ ...old, price: 1 }) as any)
									}
								>
									{!!ownTemplate?.price ? (
										<CheckCircle
											width={20}
											height={20}
											color={theme.colors.primary}
										/>
									) : (
										<Circle
											width={20}
											height={20}
											color={theme.colors.ash800}
										/>
									)}
								</IconButton>
								<div className="flex-1">
									<FormInput
										prefix="₱"
										value={ownTemplate?.price}
										onChange={(val) =>
											setOwnTemplate((old) => {
												const oldPrice = old?.price;
												let price: number | undefined = Number(val);
												if (!isNumber(price) || isNaN(price)) {
													price = oldPrice;
												}
												return { ...old, price } as any;
											})
										}
									/>
								</div>
							</div>
							<div className="flex items-center gap-x-2">
								<IconButton
									onClick={() =>
										setOwnTemplate(
											(old) => ({ ...old, price: undefined }) as any,
										)
									}
								>
									{!ownTemplate?.price ? (
										<CheckCircle
											width={20}
											height={20}
											color={theme.colors.primary}
										/>
									) : (
										<Circle
											width={20}
											height={20}
											color={theme.colors.ash800}
										/>
									)}
								</IconButton>
								<p className="m-0 text-4">Free / No need to pay</p>
							</div>
						</div>
					</div>
				</div>
			</CustomScrollDiv>
			<div className="border-t-solid border-t border-gray-10 py-3 px-4">
				<div className="flex gap-x-2">
					<IconButton
						onClick={() =>
							setTemplateName((old) => (old !== undefined ? undefined : ''))
						}
					>
						{templateName !== undefined ? (
							<CheckSquare color={theme.colors.primary} />
						) : (
							<Square color={theme.colors.ash800} />
						)}
					</IconButton>
					<p className="m-0 text-4">Save this document as template</p>
				</div>
				{templateName !== undefined && (
					<FormInput
						label="Template Name"
						type={EFormType.SINGLE_LINE}
						placeholder="Write here"
						value={templateName}
						onChange={setTemplateName}
					/>
				)}
				<div className="mt-4">
					<ButtonRemoveSave
						isEnableRemoveBtn={!isLoading}
						isEnableSaveBtn={!isLoading}
						onRemove={removeOwnTemplate}
						onSave={saveOwnTemplate}
					/>
				</div>
			</div>
		</div>
	);
}
