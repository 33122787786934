import { message } from 'antd';
import { httpRequest } from '../helpers/api';
import { getErrorMessage } from '../helpers/errorHandler';
import {
	ApiResponseDefault,
	ApiResponsePagination,
} from '../types/apiResponse.type';
import { ApiPaginationResults } from '../types/api.type';
import { generateQueryString } from '../helpers/generateQueryString';
import { LabRequestProperties } from '../types/labRequest.type';

export async function apiGetListLabRequestByConsultation(
	transactionConsultationId: string,
) {
	try {
		const res = await httpRequest.get<
			ApiResponseDefault<ApiPaginationResults<LabRequestProperties>>
		>(
			'/lab-requests' +
				generateQueryString({
					transactionConsultationIds: [transactionConsultationId],
				}),
		);
		if (res && res.data && res.data.payload) {
			return res.data.payload;
		} else {
			return null;
		}
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}

export async function apiGetLabRequest(labRequestId: string) {
	try {
		const res = await httpRequest.get<ApiResponseDefault<LabRequestProperties>>(
			`lab-requests/${labRequestId}`,
		);
		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}

export async function apiGetLabRequestFromTrxId(
	transactionConsultationIds: string[],
) {
	if (!transactionConsultationIds) return;

	try {
		const res = await httpRequest.get<
			ApiResponsePagination<LabRequestProperties>
		>(
			`lab-requests?transactionConsultationIds=${transactionConsultationIds.join(
				',',
			)}`,
		);
		return res.data.payload.results;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}

export async function apiCreateLabRequest(data: LabRequestProperties) {
	try {
		const res = await httpRequest.post<
			ApiResponseDefault<LabRequestProperties>
		>(`lab-requests`, data);
		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}

export async function apiUpdateLabRequest(
	labRequestId: string,
	data: LabRequestProperties,
) {
	try {
		const res = await httpRequest.patch<
			ApiResponseDefault<LabRequestProperties>
		>(`lab-requests/${labRequestId}`, data);
		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}

export async function apiDeleteLabRequest(labRequestId: string) {
	try {
		const res = await httpRequest.delete<
			ApiResponseDefault<{ isSuccess: boolean }>
		>(`lab-requests/${labRequestId}`);
		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}
