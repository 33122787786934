import { Spin, Button, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { CheckSquare, Square } from 'react-feather';
import { PartnerProperties } from '../../../types/partner.type';
import './styles.less';

type Props = {
	isLoadingToggle?: boolean;
	partnersTelemedicine: (PartnerProperties & { active: boolean })[] | undefined;
	partnersInPerson: (PartnerProperties & { active: boolean })[] | undefined;
	togglePartner?: (
		partnerId: string,
		type: 'TELEMEDICINE' | 'INPERSON',
		status: boolean,
		partnerName?: string,
	) => void;
};

const { Text, Paragraph } = Typography;

export default function SecretaryAccessList(props: Props) {
	const [selectedPartnerId, setSelectedPartnerId] = useState<string>();

	useEffect(() => {
		if (!props.isLoadingToggle) {
			setSelectedPartnerId(undefined);
		}
	}, [props.isLoadingToggle]);

	function togglePartner(
		partnerId: string,
		type: 'TELEMEDICINE' | 'INPERSON',
		status: boolean,
		partnerName: string,
	) {
		if (!props.togglePartner) return;

		setSelectedPartnerId(partnerId);
		props.togglePartner(partnerId, type, status, partnerName);
	}

	return (
		<>
			{props.partnersTelemedicine && props.partnersTelemedicine.length > 0 ? (
				<div className="mb-4">
					<Text
						style={{ marginBottom: 8 }}
						className="text-4.5 font-semibold text-black-100"
					>
						Telemedicine Consultation Access
					</Text>
					<Paragraph
						className="text-3.5"
						style={{ marginBottom: 12, color: '#556575' }}
					>
						Allow the secretary to change the practice schedules and accept
						telemedicine requests.
					</Paragraph>
					{props.partnersTelemedicine.map((p) => (
						<Button
							disabled={!props.togglePartner || props.isLoadingToggle}
							key={p.partnerId}
							onClick={() =>
								togglePartner(
									p.partnerId,
									'TELEMEDICINE',
									!p.active,
									p.partnerName,
								)
							}
							style={{
								padding: '0px 0px 8px',
								borderBottom: '1px solid #d5dce1',
								width: '100%',
							}}
							className="flex items-center mb-2"
						>
							{props.isLoadingToggle && p.partnerId === selectedPartnerId ? (
								<Spin />
							) : p.active ? (
								<CheckSquare width={18} height={18} className="text-primary" />
							) : (
								<Square width={18} height={18} className="text-ash-800" />
							)}
							<Text style={{ marginLeft: 8, fontSize: 16 }}>
								{p.partnerName}
							</Text>
						</Button>
					))}
				</div>
			) : undefined}
			{props.partnersInPerson && props.partnersInPerson.length > 0 ? (
				<div>
					<Text
						style={{ marginBottom: 8 }}
						className="text-4.5 font-semibold text-black-100"
					>
						In-Person Consultation Access
					</Text>
					<Paragraph
						className="text-3.5"
						style={{ marginBottom: 12, color: '#556575' }}
					>
						Allow the secretary to change the practice schedule and accept
						requests for in person consultation at the clinic.
					</Paragraph>
					{props.partnersInPerson.map((p) => (
						<Button
							disabled={!props.togglePartner || props.isLoadingToggle}
							key={p.partnerId}
							onClick={() =>
								togglePartner(p.partnerId, 'INPERSON', !p.active, p.partnerName)
							}
							style={{
								padding: '0px 0px 8px',
								borderBottom: '1px solid #d5dce1',
								width: '100%',
							}}
							className="flex items-center mb-2"
						>
							{props.isLoadingToggle && p.partnerId === selectedPartnerId ? (
								<Spin />
							) : p.active ? (
								<CheckSquare width={18} height={18} className="text-primary" />
							) : (
								<Square width={18} height={18} className="text-ash-800" />
							)}
							<Text style={{ marginLeft: 8, fontSize: 16 }}>
								{p.partnerName}
							</Text>
						</Button>
					))}
				</div>
			) : undefined}
		</>
	);
}
