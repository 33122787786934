import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { httpRequest } from '../../../helpers/api';
import { getErrorMessage } from '../../../helpers/errorHandler';
import { ApiResponseDefault } from '../../../types/apiResponse.type';
import { EPointCategory, PointProperties } from '../../../types/point.type';
import Card from '../../Card';
import { ReactComponent as Spinner } from '../../../assets/icons/spinner.svg';
import { theme } from '../../../assets/theme';
import { message } from 'antd';
import { useAuth } from '../../../context/auth.context';
import { apiPostPointReward } from '../../../types/pointReward';

export default function CardPoint() {
	const { user } = useAuth();
	const history = useHistory();
	const [currentPoint, setCurrentPoint] = useState<number>();
	const [pendingPoint, setPendingPoint] = useState<number>();

	const dailyCheckIn = async () => {
		const res = await apiPostPointReward({
			category: EPointCategory.DAILY_CHECK_IN,
			filters: {
				maxNumber: 1,
				dayInterval: 1,
			},
		});

		if (res) {
			message.success('Success to check-in daily');
			window.location.reload();
		}
	};

	useEffect(() => {
		if (!user?.userId) return;

		(async () => {
			try {
				await dailyCheckIn();

				const res = await httpRequest.get<ApiResponseDefault<PointProperties>>(
					`/points/${user.userId}`,
				);

				setCurrentPoint(res.data.payload.currentAmount || 0);
				setPendingPoint(res.data.payload.pendingAmount || 0);
			} catch (err) {
				const error = getErrorMessage(err);
				console.error(error);
				message.error(error);
			}
		})();
	}, [user?.userId]);

	return (
		<Card className="w-full p-4">
			<div className="relative">
				<CustomButton onClick={() => history.push('app/balance', { tab: '1' })}>
					View Detail
				</CustomButton>
				<IconSquareRounded>
					<img src="/icons/star-circle-outline-red.svg" alt="Star" />
				</IconSquareRounded>
				<div className="mt-3">
					{currentPoint !== undefined || pendingPoint !== undefined ? (
						<>
							<CardTitleBig>
								{currentPoint ? currentPoint?.toFixed(2) : currentPoint}{' '}
								<CardTitleSmall>
									{' '}
									+ {pendingPoint ? pendingPoint.toFixed(2) : pendingPoint} (
									Pending )
								</CardTitleSmall>
							</CardTitleBig>
						</>
					) : (
						<Spinner width={27.5} height={27.5} color={theme.colors.primary} />
					)}
					<TextGray70>Medeasy Points</TextGray70>
				</div>
			</div>
		</Card>
	);
}

const CustomButton = styled.button`
	background-color: transparent;
	border: none;
	padding: 12px;
	cursor: pointer;
	color: #748494;
	&:hover {
		color: ${({ theme }) => theme.colors.primary};
	}
	position: absolute;
	top: 0;
	right: 0;
`;

const IconSquareRounded = styled.div`
	width: fit-content;
	padding: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${({ theme }) => theme.colors.primary10};
	border-radius: 12px;
`;

const CardTitleBig = styled.span`
	margin: 0;
	font-weight: 600;
	font-size: 24px;
	color: ${({ theme }) => theme.colors.gray100};
`;

const CardTitleSmall = styled.span`
	margin: 0;
	font-weight: 600;
	font-size: 12px;
	color: ${({ theme }) => theme.colors.gray100};
`;

const TextGray70 = styled.p`
	margin: 0;
	color: ${({ theme }) => theme.colors.gray70};
`;
