export default function BtnTeleconferenceCtr(props: {
	bg?: string;
	children: any;
	onClick: any;
}) {
	return (
		<button
			onClick={props.onClick}
			className={
				'border-none w-16 h-16 flex items-center justify-center rounded-full cursor-pointer ' +
				(props.bg ? props.bg : 'bg-white')
			}
		>
			{props.children}
		</button>
	);
}
