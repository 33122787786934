import { Card, Typography, Button } from 'antd';
import styled from 'styled-components';

const { Text, Title } = Typography;

const ProblemExamplesData = [
	'Obesity',
	'Hypertension',
	'Heart Disease',
	'Ostearthrithis',
];

export default function ResultSectionProblems() {
	const ProblemExamples = ProblemExamplesData.map((disease, index) => {
		return (
			<ul key={index} style={ProblemItemStyles}>
				<li style={{ fontSize: 28 }}></li>
				<Text style={{ marginLeft: -8 }}>{disease}</Text>
			</ul>
		);
	});

	return (
		<>
			<Card style={SectionProblemContainerStyles}>
				<Title level={5}>Overweight leads to various health problem</Title>
				<Text>
					Being overweight puts you at risk for many serious health conditions :
				</Text>
				<HealthProblemExamplesContainer>
					{ProblemExamples}
				</HealthProblemExamplesContainer>
			</Card>
		</>
	);
}

const SectionProblemContainerStyles: React.CSSProperties = {
	borderRadius: 12,
	display: 'flex',
	flexDirection: 'column',
};

const ProblemItemStyles: React.CSSProperties = {
	// border: "1px solid black",
	margin: 0,
	display: 'flex',
	alignItems: 'center',
	color: '#D81F64',
	padding: 0,
	paddingLeft: 24,
	height: 24,
};

const HealthProblemExamplesContainer = styled.div`
	// border: 1px solid black;
	margin-top: 24px;
	margin-bottom: 24px;
	width: 100%;
	display: grid;
	grid-template-rows: repeat(2, minmax(0, 1fr));
	grid-template-columns: repeat(2, minmax(0, 1fr));
	gap: 8px;
`;
