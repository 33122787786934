import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

import AppButtonTwoSide from '../Button/AppButtonTwoSide';

import {
	EConsultationType,
	ETransactionStatusType,
	TransactionConsultationProperties,
} from '../../types/transactionConsultation.type';

import { DetailItemAgenda, EScheduleTypes } from '../../types/schedule.type';
import { PersonalConsultationProperties } from '../../types/personalConsultation.type';
import { PersonalEvent } from '../../types/personalEvent.type';

import { previewText } from '../../utils/commons';
import COLORS from '../../assets/globalColors';
import {
	iconChevron as IconChevron,
	iconLocation as IconLocation,
} from '../../assets/icons';
import AppButton from '../Button/AppButton';
import { getName } from '../../helpers/name';
import ModalAcceptConsultation from '../Webapp/DetailSchedule/ModalAcceptConsultation';
import ModalRejectConsultation from '../Webapp/DetailSchedule/ModalRejectConsultation';
import { httpRequest } from '../../helpers/api';
import { ApiResponseDefault } from '../../types/apiResponse.type';
import { message } from 'antd';

interface IProps {
	isNotTouchable?: boolean;
	isCollapseDetail?: boolean;
	isDoctor?: boolean;
	isSecretary?: boolean;
	isPatient?: boolean;
	userId?: string;

	item?: any;
	index?: any;
	itemGroup?: any;
	hideAction?: boolean;
	fetchList?: () => Promise<void>;
}

export default function Detail({
	isNotTouchable,
	isCollapseDetail,
	isDoctor,
	isSecretary,
	isPatient,
	userId,
	item,
	index,
	itemGroup,
	hideAction,
	fetchList,
}: IProps) {
	const history = useHistory();

	const [isShowDetail, setShowDetail] = useState(
		isCollapseDetail
			? false
			: item?.raw?.transactionStatus &&
			  (item?.raw?.transactionStatus?.includes('WAITING') ||
					item?.raw?.transactionStatus?.includes('APPROVED'))
			? true
			: false,
	);
	const [isLoading, setIsLoading] = useState(false);
	const [isModalAcceptConsulVisible, setIsModalAcceptConsulVisible] =
		useState(false);
	const [isModalRejectConsulVisible, setIsModalRejectConsulVisible] =
		useState(false);
	console.log('ini items->>>', item);

	const isSecre = userId === item?.raw?.patientId ? false : isSecretary;
	const isDoc = userId === item?.raw?.patientId ? false : isDoctor;

	const isNowTheDay = useMemo(() => {
		const now = new Date();
		const schedule = new Date(item?.date);

		return (
			now.getFullYear() === schedule.getFullYear() &&
			now.getMonth() === schedule.getMonth() &&
			now.getDate() === schedule.getDate()
		);
	}, [isShowDetail, item]);

	const IconItem = useMemo(() => item?.settings?.icon, [item?.settings?.icon]);

	const styleP = { color: COLORS.charcoal_400 };
	const stylePNoMargin = { color: COLORS.charcoal_400, marginBottom: 0 };
	const styleMarker = useMemo(() => {
		return {
			backgroundColor: item?.isBlockingEvent
				? '#DCDCDC'
				: item?.settings?.color || '#DCDCDC',
		};
	}, [item]);

	function handleOpenTelemedicine() {
		if (item?.settings?.type === 'telemedicine') {
			history.push('Teleconference', {
				transactionConsultationId: item?.raw?.transactionConsultationId,
				consultationType: item?.raw?.consultationType,
			});
		}
	}

	function goToDetail(e: any) {
		console.info('goToDetail item', JSON.stringify(item));

		let type;
		let id;
		if (item.raw?.transactionConsultationId) {
			id = item.raw.transactionConsultationId;
			if (
				(item.raw as TransactionConsultationProperties)?.consultationType ===
					EConsultationType.TELEMEDICINE_VIDEO_CHAT_CONSULTATION ||
				(item.raw as TransactionConsultationProperties)?.consultationType ===
					EConsultationType.TELEMEDICINE_CHAT_CONSULTATION
			) {
				type = EScheduleTypes.telemedicine;
			} else if (
				(item.raw as TransactionConsultationProperties)?.consultationType ===
					EConsultationType.CONSULT_NOW_CHAT_CONSULTATION ||
				(item.raw as TransactionConsultationProperties)?.consultationType ===
					EConsultationType.CONSULT_NOW_VIDEO_CHAT_CONSULTATION
			) {
				type = EScheduleTypes['consult-now'];
			} else if (
				(item.raw as TransactionConsultationProperties)?.consultationType ===
				EConsultationType.FACE_TO_FACE_CONSULTATION
			) {
				type = EScheduleTypes.appointment;
			}
		} else if (item.raw?.transactionHealthServiceId) {
			id = item.raw?.transactionHealthServiceId;
			type = EScheduleTypes['medical-service'];
		} else if (item.raw?.personalConsultationId) {
			id = item.raw?.personalConsultationId;
			if (
				(item.raw as PersonalConsultationProperties)?.consultationType ===
					EConsultationType.TELEMEDICINE_VIDEO_CHAT_CONSULTATION ||
				(item.raw as PersonalConsultationProperties)?.consultationType ===
					EConsultationType.TELEMEDICINE_CHAT_CONSULTATION
			) {
				type = EScheduleTypes['telemedicine-personal'];
			} else if (
				(item.raw as PersonalConsultationProperties)?.consultationType ===
				EConsultationType.FACE_TO_FACE_CONSULTATION
			) {
				type = EScheduleTypes['appointment-personal'];
			}
		} else if (item.raw?.eventId) {
			if ((item.raw as PersonalEvent)?.isBlocking) {
				type = EScheduleTypes['personal-event-blocked'];
			} else {
				type = EScheduleTypes['personal-event'];
			}
			id = item.raw?.eventId;
		}

		if (type && id) {
			history.push('schedule/details', {
				type: type,
				id: id,
				from: '',
			});
		}
	}

	async function afterAction() {
		setIsLoading(true);
		fetchList && (await fetchList());
		setIsLoading(false);
	}

	function expandDetail(e: any) {
		e.stopPropagation();
		setShowDetail(!isShowDetail);
	}

	async function updateTransactionConsult(
		id: string,
		data: Partial<TransactionConsultationProperties>,
	) {
		try {
			const res = await httpRequest.patch<
				ApiResponseDefault<TransactionConsultationProperties>
			>(`transaction-consults/${id}`, data);
			if (res.data.payload) {
				message.success('Success approve event!');
				setIsModalAcceptConsulVisible(false);
			}
		} catch (err) {
			console.log(err);
			message.error('ERROR: Failed to approve event! Please try again');
		} finally {
			afterAction();
		}
	}

	return (
		<>
			<div className="detail-card" onClick={goToDetail}>
				{index === itemGroup?.data?.length - 1 && <div className="line-item" />}
				<div className="icon-container" style={styleMarker}>
					<IconItem />
				</div>
				<div className="content-container">
					<div className="header-content">
						<p style={styleP}>{item?.settings?.name}</p>
						<div className="time-n-more">
							{(item?.startTime || item?.endTime) && (
								<p style={styleP}>{`${item?.startTime} - ${item?.endTime}`}</p>
							)}
							<div
								className={clsx('chevron-container', {
									'is-open': isShowDetail,
								})}
								onClick={expandDetail}
							>
								<IconChevron />
							</div>
						</div>
					</div>
					<p className="title-item">
						{item?.detailDoctor?.name ||
							(item.raw?.metaPatient && getName(item.raw?.metaPatient)) ||
							item?.name}
					</p>
					{isShowDetail && (
						<>
							{item?.detailPatient && (
								<div className="detail" style={{ marginBottom: 10 }}>
									<p style={stylePNoMargin}>
										{item?.detailPatient?.gender ||
											item?.raw?.metaPatient?.patientGender ||
											'-'}
									</p>
									<div className="ellip-separator" />
									<p style={stylePNoMargin}>
										{item?.detailPatient?.ageString ||
											item?.raw?.metaPatient?.patientAge ||
											'-'}
									</p>
								</div>
							)}
							{item?.desc && (
								<p style={styleP}>{previewText(item?.desc, 70)}</p>
							)}
							{item?.location && (
								<div className="detail location-container">
									<IconLocation />
									<p style={styleP} className="location">
										{item?.location}
										{item?.location === 'Home Service'
											? ` by ${item?.detailLocation}`
											: ''}
									</p>
								</div>
							)}
							{!item?.isExpire && !hideAction && (
								<>
									{(isDoc || isSecre) &&
										item?.raw?.transactionStatus ===
											ETransactionStatusType.WAITING_APPROVAL && (
											<AppButtonTwoSide
												classNames="btn-action"
												textLeft="Reject"
												btnLoadingLeft={isLoading}
												onPressLeft={() => setIsModalRejectConsulVisible(true)}
												textRight="Approve"
												btnLoadingRight={isLoading}
												onPressRight={() => setIsModalAcceptConsulVisible(true)}
												btnTypeRight="outlined"
											/>
										)}
								</>
							)}
							{(isDoc || isPatient) &&
							item?.settings?.type === 'telemedicine' &&
							(item?.raw as TransactionConsultationProperties)
								?.transactionStatus === ETransactionStatusType.APPROVED ? (
								<AppButton
									type="outlined"
									classNames={clsx('btn-telemedicine', {
										'is-past': !isNowTheDay,
									})}
									classText={clsx({ 'text-white': !isNowTheDay })}
									onPress={() => {
										if (isNowTheDay) {
											handleOpenTelemedicine();
										} else {
											// Toast.show({
											//   type: "error",
											//   text1: `Unable to ${
											//     isDoctor ? "start" : "join"
											//   } telemedicine`,
											//   text2: `This telemedicine are scheduled at ${moment(
											//     item.date
											//   ).format("DD MMM YYYY")}`,
											// });
										}
									}}
								>
									{`${isDoctor ? 'Start' : 'Join'} Telemedicine`}
								</AppButton>
							) : undefined}
						</>
					)}
				</div>
				<ModalAcceptConsultation
					isVisible={isModalAcceptConsulVisible}
					setVisibility={setIsModalAcceptConsulVisible}
					actionHandler={() => {
						updateTransactionConsult(
							item?.raw?.transactionConsultationId || '',
							{
								transactionStatus: ETransactionStatusType.APPROVED,
							},
						);
					}}
				/>
				<ModalRejectConsultation
					isVisible={isModalRejectConsulVisible}
					setVisibility={setIsModalRejectConsulVisible}
					transactionConsultationId={item?.raw?.transactionConsultationId || ''}
					setFetchTrue={afterAction}
				/>
			</div>
		</>
	);
}
