import { DoctorProperties } from './doctor.type';
import { PartnerProperties } from './partner.type';
import { IUser } from './user.type';

export type VerifyResponseProperties = {
	isExist: boolean;
	doctorId: string;
};

export type RegisterDoctorSecretaryProperties = {
	invitationCode: string;
	partnerId?: string;
	metaPartner?: {
		partnerName: string;
	};
};

export type AddSecretaryProperties = {
	partnerId: string;
};

export type DoctorSecretaryProperties = {
	doctorId: string;
	doctor: DoctorProperties;
	status: EDoctorSecretaryStatus;
};

export type SecretaryProperties = {
	secretaryId: string;
	secretary: IUser;
	partners: PartnerProperties[];
	status: EDoctorSecretaryStatus;
};

export enum EDoctorSecretaryStatus {
	WAITING_APPROVAL = 'WAITING_APPROVAL',
	APPROVED = 'APPROVED',
	REJECTED = 'REJECTED',
}

export type ApiDoctorSecretaryQuery = {
	search?: string;
	limit?: number;
	offset?: number;
	status?: EDoctorSecretaryStatus;
};
