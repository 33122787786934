import React, { useMemo, useState } from 'react';
import { Card, Divider, Form } from 'antd';
import { useHistory } from 'react-router-dom';
import PageHeader from '../../../../components/PageHeader';
import AppLayout from '../../../../layout/AppLayout';
import PreResult from '../../../../components/Webapp/MedicalCalculator/PreResult';
import FormQuestion from '../../../../components/Webapp/MedicalCalculator/FormQuestion';

const activePath = '/app/medical-calculator';

export default function CardioRiskACCRisk() {
	const history = useHistory();

	const [form] = Form.useForm();

	const [resultValue, setResultValue] = useState<any>({});
	const [estimated, setEstimated] = useState<any>({});

	const [isChecked, setChecked] = useState<any>(false);

	const isComingSoon = true;

	const dataIndicator = useMemo(
		() => [
			{
				title: 'Low',
				width: 10,
				color: '#7FD53B',
				start: 0,
				startValue: 0,
			},
			{
				title: 'Intermediate',
				width: 10,
				color: '#EFC222',
				start: 11,
				startValue: 11,
			},
			{
				title: 'High',
				width: 80,
				color: '#E93131',
				start: 20,
				startValue: 20,
			},
		],
		[],
	);

	const dataGenderOption = useMemo(
		() => [
			{ label: 'Male', value: 'male' },
			{ label: 'Female', value: 'female' },
		],
		[],
	);

	const dataRaceOption = useMemo(
		() => [
			{ label: 'Asian', value: 'asian' },
			{ label: 'American', value: 'american' },
		],
		[],
	);

	const dataQuestions = useMemo(
		() => [
			{
				row: 1,
				formItems: [
					{
						label: 'Gender',
						name: 'gender',
						type: 'select',
						options: dataGenderOption,
					},
					{
						label: 'Age',
						name: 'age',
						min: 30,
						type: 'inputNumber',
					},
					{
						label: 'Race',
						name: 'race',
						type: 'select',
						options: dataRaceOption,
					},
				],
			},
			{
				row: 2,
				formItems: [
					{
						label: 'Total cholestrol',
						name: 'total_cholestrol',
						type: 'input',
						suffix: 'mg/dL',
					},
					{
						label: 'HDL cholestrol',
						name: 'hdl_cholestrol',
						type: 'input',
						suffix: 'mg/dL',
					},
					{
						label: 'Systolic blood pressure',
						name: 'systolic_blood_pressure',
						type: 'input',
						suffix: 'mmHg',
					},
				],
			},
			{
				row: 3,
				formItems: [
					{
						label: 'Smoker?',
						name: 'smoker',
						type: 'radio',
					},
					{
						label: 'Diabetes?',
						name: 'diabetes',
						type: 'radio',
					},
					{
						label: 'Treatment for high blood pressure?',
						name: 'systolic_bp_treat',
						type: 'radio',
					},
				],
			},
		],
		[],
	);

	const checkHandler = (values: any) => {
		if (isChecked) {
			form.resetFields();
			setChecked(false);
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth',
			});
			return;
		}
		setChecked(true);
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	};

	// const handleResult = (value: any) => {
	// 	if (value?.riskPercent >= 20) {
	// 		return {
	// 			score: value?.score,
	// 			item: dataIndicator[dataIndicator?.length - 1],
	// 			pointer: 80,
	// 		};
	// 	}
	// 	const filtered = dataIndicator?.filter(
	// 		(item) => value.riskPercent >= item?.startValue,
	// 	);
	// 	const itemSelected = filtered?.[filtered?.length - 1];
	// 	const itemIndex = dataIndicator?.findIndex(
	// 		(itm) => itm?.start === itemSelected?.start,
	// 	);
	// 	const nextValue = dataIndicator?.[itemIndex + 1];
	// 	const pointer =
	// 		(value.riskPercent / nextValue?.startValue) *
	// 		(itemSelected?.width + itemSelected?.start);

	// 	return {
	// 		pointer: value.riskPercent,
	// 		score: value.score,
	// 		item: itemSelected,
	// 		// pointer,
	// 	};
	// };

	return (
		<AppLayout activePath={activePath}>
			<Card style={{ minHeight: 550 }}>
				<PageHeader
					title="ACC/AHA Risk Calculator"
					onClick={() => history.goBack()}
				/>

				<Divider />

				{/* {isChecked ? (
          <Score
            dataIndicator={dataIndicator}
            estimated={estimated}
            resultValue={resultValue}
          />
        ) : (
          <PreResult />
        )} */}
				<PreResult />

				<FormQuestion
					checkHandler={checkHandler}
					dataQuestions={dataQuestions}
					form={form}
					isChecked={isChecked}
					isComingSoon={isComingSoon}
				/>
			</Card>
		</AppLayout>
	);
}
