import { theme } from '../../assets/theme';
import Separator from '../Separator';

interface Props {
	tabs: string[];
	selectedTabIndex: number;
	useBottomSeparator?: boolean;
	setSelectedTabIndex: (tab: number) => void;
}

export default function Tab(props: Props) {
	return (
		<div className="w-full">
			<div className="flex">
				{props.tabs.map((tab, index) => (
					<button
						key={tab}
						onClick={() => props.setSelectedTabIndex(index)}
						className="bg-transparent border-none p-0 flex-1 flex flex-col items-center justify-center cursor-pointer"
					>
						<div className="pt-4 pb-3">
							<p
								className={
									'm-0 font-medium text-4 ' +
									(props.selectedTabIndex === index
										? 'text-black-100'
										: 'text-ash-800')
								}
							>
								{tab}
							</p>
						</div>
						<Separator
							style={{
								height: 4,
								backgroundColor:
									props.selectedTabIndex === index
										? theme.colors.primary
										: 'transparent',
							}}
						/>
					</button>
				))}
			</div>

			{props.useBottomSeparator && <Separator />}
		</div>
	);
}
