import { useState } from 'react';
import { apiUpdateMedicalNotes } from '../../../../api/medicalRecord';
import { MedicalRecordProperties } from '../../../../types/medicalRecord.type';
import CustomScrollDiv from '../../../CustomScrollDiv';
import FormInput, { EFormType } from '../../../Form/FormInput';
import ButtonRemoveSave from './Components/ButtonRemoveSave';
import SidebarMenuHeader from './Components/SidebarMenuHeader';
import { EConsultSidebarContent } from './SidebarConsultationDefault';

interface Props {
	medicalRecordId?: string;
	title: string;
	value?: string;
	consultSidebarContent?: EConsultSidebarContent;
	onBack: () => void;
	setMedicalNotes: React.Dispatch<
		React.SetStateAction<MedicalRecordProperties>
	>;
}

export default function SidebarMedicalNotes(props: Props) {
	const [isLoading, setIsLoading] = useState(false);
	const [value, setValue] = useState(props.value);

	async function removeMedicalNote() {
		if (!props.value) {
			props.onBack();
			return;
		}
		if (!props.medicalRecordId) return;

		let updatedValue: { [key: string]: string | undefined } = {};
		switch (props.consultSidebarContent) {
			case EConsultSidebarContent.HISTORY_PHYSICAL_FINDINGS:
				updatedValue['historyPhysicalFindings'] = '';
				break;
			case EConsultSidebarContent.ASSESSMENT_DIAGNOSIS:
				updatedValue['diagnosis'] = '';
				break;
			case EConsultSidebarContent.PLAN_FOR_MANAGEMENT:
				updatedValue['planForManagement'] = '';
				break;
			default:
				return;
		}

		setIsLoading(true);
		const res = await apiUpdateMedicalNotes(
			props.medicalRecordId,
			updatedValue,
		);
		if (res) {
			props.setMedicalNotes(res);
			props.onBack();
		}
		setIsLoading(false);
	}

	async function saveMedicalNote() {
		if (props.value === value) {
			props.onBack();
			return;
		}
		if (!props.medicalRecordId) return;

		let updatedValue: { [key: string]: string | undefined } = {};
		switch (props.consultSidebarContent) {
			case EConsultSidebarContent.HISTORY_PHYSICAL_FINDINGS:
				updatedValue['historyPhysicalFindings'] = value;
				break;
			case EConsultSidebarContent.ASSESSMENT_DIAGNOSIS:
				updatedValue['diagnosis'] = value;
				break;
			case EConsultSidebarContent.PLAN_FOR_MANAGEMENT:
				updatedValue['planForManagement'] = value;
				break;
			default:
				return;
		}

		setIsLoading(true);
		const res = await apiUpdateMedicalNotes(
			props.medicalRecordId,
			updatedValue,
		);
		if (res) {
			props.setMedicalNotes(res);
			props.onBack();
		}
		setIsLoading(false);
	}
	return (
		<div className="w-full flex flex-col">
			<SidebarMenuHeader onBack={props.onBack} title={props.title} />
			<CustomScrollDiv className="relative flex overflow-auto w-full h-full">
				<div className="w-full">
					<div className="flex-1 pb-6">
						<div>
							<p className="m-0 text-4 text-charcoal-300">
								This document only for doctor, patient would not be able to see
								this documents
							</p>
						</div>
						<div className="flex-1">
							<FormInput
								label={props.title}
								value={value}
								type={EFormType.MULTI_LINE}
								rows={4}
								onChange={setValue}
							/>
						</div>
					</div>
				</div>
			</CustomScrollDiv>
			<div className="py-3 px-4 border-t-solid border-t border-gray-10">
				<ButtonRemoveSave
					isEnableRemoveBtn={!isLoading && !!value}
					isEnableSaveBtn={!isLoading && !!value}
					onRemove={removeMedicalNote}
					onSave={saveMedicalNote}
				/>
			</div>
		</div>
	);
}
