import React from 'react';
import Header from '../Header';
import CalendarMensLog from './CalendarMenstruationLog';

type Props = { backBtn: () => void };

export default function IndexMenstruation(Props: Props) {
	return (
		<div style={{ minHeight: '100vh' }}>
			<Header title="Log Period" backBtnHandler={() => Props.backBtn()} />
			<CalendarMensLog />
		</div>
	);
}
