import { DoctorProperties } from './doctor.type';
import { LabRequestProperties } from './labRequest.type';
import { MedicalRecordProperties } from './medicalRecord.type';
import { PartnerProperties } from './partner.type';
import { PatientProperties } from './patient.type';
import { PatientInRelationProperties } from './patientInRelation.type';
import { initialPayment, PaymentProperties } from './payment.type';
import { RxPrescriptionProperties } from './rxPrescription.type';

export enum ETransactionRejectReasonType {
	MEDICAL_EMERGENCY = 'MEDICAL_EMERGENCY',
	SICK = 'SICK',
	PERSONAL_EVENT_ATTEND = 'PERSONAL_EVENT_ATTEND',
	OTHERS = 'OTHERS',
}

export enum ETransactionStatusType {
	WAITING_PAYMENT = 'WAITING_PAYMENT', // trx created and waiting payment

	WAITING_APPROVAL = 'WAITING_APPROVAL', // after user pay, waiting approval from doctor/secretary

	APPROVED = 'APPROVED', // approved by doctor/secretary
	REJECTED = 'REJECTED', // rejected by doctor/secretary
	REJECTED_CHANGE_DOCTOR = 'REJECTED_CHANGE_DOCTOR', // user change doctor after rejected by doctor/secretary
	REJECTED_REFUND = 'REJECTED_REFUND', // user ask for refund after rejected by doctor/secretary
	REJECTED_CHANGE_SCHEDULE = 'REJECTED_CHANGE_SCHEDULE', // doctor suggest to change schedule
	EXPIRED = 'EXPIRED',
	CANCELED_BY_SYSTEM = 'CANCELED_BY_SYSTEM', // if user not pay over the limited time
	CANCELED_BY_USER = 'CANCELED_BY_USER', // if user cancel the transaction
	COMPLETED = 'COMPLETED', // if consultation has been done
	CANCELED_BY_DOCTOR_OR_SECRETARY = 'CANCELED_BY_DOCTOR_OR_SECRETARY', // cancelled by doctor or secretary
}

export enum EConsultationType {
	FACE_TO_FACE_CONSULTATION = 'FACE_TO_FACE_CONSULTATION',

	TELEMEDICINE_CHAT_CONSULTATION = 'TELEMEDICINE_CHAT_CONSULTATION',
	TELEMEDICINE_VIDEO_CHAT_CONSULTATION = 'TELEMEDICINE_VIDEO_CHAT_CONSULTATION',

	CONSULT_NOW_CHAT_CONSULTATION = 'CONSULT_NOW_CHAT_CONSULTATION',
	CONSULT_NOW_VIDEO_CHAT_CONSULTATION = 'CONSULT_NOW_VIDEO_CHAT_CONSULTATION',
}

export type TransactionConsultationProperties = {
	transactionConsultationId: string;
	personalConsultationId?: string;
	doctorId: string;
	patientId: string;
	patientInRelationId?: string;
	partnerId: string;
	consultationType: EConsultationType;
	scheduledStartAt?: Date;
	scheduledEndAt?: Date;
	isCanExtend?: boolean;
	doctorJoinAt?: string;
	doctorEndAt?: string;
	patientJoinAt?: string;
	patientEndAt?: string;
	transactionStatus: ETransactionStatusType;
	description: string;
	reasonOfConsultation?: string;

	place?: string;
	link?: string;

	medicalRecord?: MedicalRecordProperties;
	// rxPrescription?: RxPrescriptionProperties;
	// labRequest?: LabRequestProperties;

	metaPatient?: PatientProperties;
	metaPatientInRelation?: PatientInRelationProperties;
	metaDoctor?: DoctorProperties;
	metaPartner?: PartnerProperties;

	eventStartAt?: string;
	eventEndAt?: string;

	payment: PaymentProperties;

	history?: any;

	createdAt?: Date | string;
	updatedAt?: Date | string;
};

export const initialTransactionConsultation: TransactionConsultationProperties =
	{
		transactionConsultationId: '',
		doctorId: '',
		patientId: '',
		patientInRelationId: '',
		partnerId: '',
		consultationType: EConsultationType.TELEMEDICINE_VIDEO_CHAT_CONSULTATION,
		scheduledStartAt: new Date(),
		scheduledEndAt: new Date(),
		isCanExtend: false,
		doctorJoinAt: '',
		doctorEndAt: '',
		patientJoinAt: '',
		patientEndAt: '',
		transactionStatus: ETransactionStatusType.WAITING_PAYMENT,
		description: '',
		reasonOfConsultation: '',

		place: '',
		link: '',

		eventStartAt: '',
		eventEndAt: '',

		payment: initialPayment,

		createdAt: '',
		updatedAt: '',
	};
