import { isNumber, template } from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { Calendar, CheckCircle, Circle } from 'react-feather';
import {
	apiCreateDoctorDocument,
	apiDeleteDoctorDocument,
	apiUpdateDoctorDocument,
} from '../../../../api/doctorDocument';
import { theme } from '../../../../assets/theme';
import { getName } from '../../../../helpers/name';
import { getBirthdate, getGender } from '../../../../helpers/profile';
import {
	DoctorDocumentProperties,
	EMetaType,
	MetaMedicalCertificate,
} from '../../../../types/doctorDocument.type';
import { IUser } from '../../../../types/user.type';
import IconButton from '../../../Button/IconButton';
import CustomScrollDiv from '../../../CustomScrollDiv';
import FormInput, { EFormType } from '../../../Form/FormInput2';
import ButtonRemoveSave from './Components/ButtonRemoveSave';
import SidebarMenuHeader from './Components/SidebarMenuHeader';
import { useAuth } from '../../../../context/auth.context';

interface Props {
	patientProfile?: IUser;
	transactionConsultationId?: string;
	medicalCertificate?: DoctorDocumentProperties;
	setMedicalCertificate: React.Dispatch<
		React.SetStateAction<DoctorDocumentProperties | undefined>
	>;
	onBack: () => void;
}

export default function SidebarMedicalCertificate(props: Props) {
	const { user } = useAuth();

	const [isLoading, setIsLoading] = useState(false);

	const [medicalCertificate, setMedicalCertificate] = useState(
		props.medicalCertificate,
	);
	const [patientProfile, setPatientProfile] = useState({
		patientName: getName(props.patientProfile),
		age: props.patientProfile
			? moment().diff(getBirthdate(props.patientProfile), 'years', false)
			: undefined,
		gender: getGender(props.patientProfile),
	});

	async function removeMedicalCertificate() {
		if (!props.medicalCertificate?.doctorDocumentId) {
			props.onBack();
			return;
		}

		setIsLoading(true);
		const res = await apiDeleteDoctorDocument(
			props.medicalCertificate.doctorDocumentId,
		);
		if (res?.isSuccess) {
			props.setMedicalCertificate(undefined);
			props.onBack();
		} else {
			setIsLoading(false);
		}
	}

	async function saveMedicalCertificate() {
		setIsLoading(true);

		const patientName = patientProfile.patientName
			? patientProfile.patientName.split(' ')
			: [];
		const birthdate = new Date();
		birthdate.setUTCFullYear(
			birthdate.getUTCFullYear() - (patientProfile.age ?? 0),
		);

		let isSuccess = false;
		let data: DoctorDocumentProperties = {
			metaPatient: {
				firstName: patientName[0],
				middleName:
					patientName.length > 2 ? patientName.slice(1, -1).join(' ') : '',
				lastName:
					patientName.length > 1 ? patientName[patientName.length - 1] : '',
				birthdate,
				gender: patientProfile.gender.toLowerCase(),
				patientId: props.patientProfile?.userId,
			},
			validUntil: medicalCertificate?.validUntil ?? new Date().toISOString(),
			price: medicalCertificate?.price ?? 0,
			meta: medicalCertificate?.meta,
			isTemporary: true,
		};
		let doctorDocumentId = '';
		if (medicalCertificate?.doctorDocumentId) {
			const res = await apiUpdateDoctorDocument(
				medicalCertificate.doctorDocumentId,
				data,
			);
			if (res) {
				if (res.doctorDocumentId) {
					doctorDocumentId = res.doctorDocumentId;
				}
				props.setMedicalCertificate(res);
				isSuccess = true;
			}
		} else {
			data = {
				...data,
				transactionConsultationId: props.transactionConsultationId,
				doctorId: user?.userId,
				type: EMetaType.MEDICAL_CERTIFICATE,
				patientId: props.patientProfile?.userId,
			};

			const res = await apiCreateDoctorDocument(data);
			if (res) {
				if (res.doctorDocumentId) {
					doctorDocumentId = res.doctorDocumentId;
				}
				props.setMedicalCertificate(res);
				isSuccess = true;
			}
		}

		if (isSuccess) {
			props.onBack();
		} else {
			setIsLoading(false);
		}
	}

	return (
		<div className="relative w-full flex flex-col">
			<SidebarMenuHeader onBack={props.onBack} title="Medical Certificate" />
			<CustomScrollDiv className="relative flex overflow-auto w-full h-full">
				<div className="w-full">
					<div className="flex-1 pb-6">
						<FormInput
							label="Patient Name"
							value={patientProfile.patientName}
							onChange={(val) =>
								setPatientProfile((old) => ({ ...old, patientName: val }))
							}
						/>
						<FormInput
							label="Valid Until"
							value={medicalCertificate?.validUntil}
							onChange={(val) =>
								setMedicalCertificate((old) => ({ ...old, validUntil: val }))
							}
							type={EFormType.DATE_PICKER}
						/>
						<FormInput
							label="Age"
							value={patientProfile.age?.toString()}
							onChange={(val) => {
								const age = Number(val);
								if (isNumber(age) && !isNaN(age)) {
									setPatientProfile((old) => ({ ...old, age }));
								}
							}}
							suffix={
								<p className="m-0 whitespace-nowrap text-black-60">Years old</p>
							}
						/>
						<div>
							<p className="m-0">Gender</p>
							<div className="flex gap-x-3">
								{['Male', 'Female'].map((gender) => (
									<button
										key={gender}
										onClick={() =>
											setPatientProfile((old) => ({
												...old,
												gender: gender.toLowerCase(),
											}))
										}
										className="flex-1 py-2.5 px-3.5 flex items-center gap-x-2.5 bg-transparent border border-ash-800 rounded-4 border-solid cursor-pointer"
									>
										{patientProfile.gender.toLowerCase() ===
										gender.toLowerCase() ? (
											<CheckCircle
												width={20}
												height={20}
												color={theme.colors.primary}
											/>
										) : (
											<Circle
												width={20}
												height={20}
												color={theme.colors.black40}
											/>
										)}
										<p className="m-0">{gender}</p>
									</button>
								))}
							</div>
						</div>
						<FormInput
							label="Address"
							type={EFormType.MULTI_LINE}
							rows={3}
							value={
								(medicalCertificate?.meta as MetaMedicalCertificate)?.address
							}
							onChange={(val) =>
								setMedicalCertificate(
									(old) =>
										({
											...old,
											meta: {
												...old?.meta,
												address: val,
											} as MetaMedicalCertificate,
										}) as any,
								)
							}
						/>
						<FormInput
							label="Clinic / Hospital"
							type={EFormType.MULTI_LINE}
							rows={3}
							value={
								(medicalCertificate?.meta as MetaMedicalCertificate)?.clinicName
							}
							onChange={(val) =>
								setMedicalCertificate(
									(old) =>
										({
											...old,
											meta: {
												...old?.meta,
												clinicName: val,
											} as MetaMedicalCertificate,
										}) as any,
								)
							}
						/>
						<FormInput
							label="Diagnosis"
							type={EFormType.MULTI_LINE}
							rows={3}
							value={
								(medicalCertificate?.meta as MetaMedicalCertificate)?.diagnosis
							}
							onChange={(val) =>
								setMedicalCertificate(
									(old) =>
										({
											...old,
											meta: {
												...old?.meta,
												diagnosis: val,
											} as MetaMedicalCertificate,
										}) as any,
								)
							}
						/>
						<FormInput
							label="Medical attention /rest for"
							value={
								(medicalCertificate?.meta as MetaMedicalCertificate)
									?.medicalAttentionInDays
							}
							onChange={(val) =>
								setMedicalCertificate((old) => {
									const medicalAttentionInDays = Number(val);
									if (
										isNumber(medicalAttentionInDays) &&
										!isNaN(medicalAttentionInDays)
									) {
										return {
											...old,
											meta: {
												...old?.meta,
												medicalAttentionInDays,
											} as MetaMedicalCertificate,
										} as any;
									} else {
										return old;
									}
								})
							}
							suffix={
								<p className="m-0 whitespace-nowrap text-black-60">days</p>
							}
						/>
						<div>
							<p className="m-0 text-4">Document Price</p>
							<div className="flex items-center gap-x-2">
								<IconButton
									onClick={() =>
										setMedicalCertificate(
											(old) => ({ ...old, price: 1 }) as any,
										)
									}
								>
									{!!medicalCertificate?.price ? (
										<CheckCircle
											width={20}
											height={20}
											color={theme.colors.primary}
										/>
									) : (
										<Circle
											width={20}
											height={20}
											color={theme.colors.ash800}
										/>
									)}
								</IconButton>
								<div className="flex-1">
									<FormInput
										prefix="₱"
										value={medicalCertificate?.price}
										onChange={(val) =>
											setMedicalCertificate((old) => {
												const oldPrice = old?.price;
												let price: number | undefined = Number(val);
												if (!isNumber(price) || isNaN(price)) {
													price = oldPrice;
												}
												return { ...old, price } as any;
											})
										}
									/>
								</div>
							</div>
							<div className="flex items-center gap-x-2">
								<IconButton
									onClick={() =>
										setMedicalCertificate(
											(old) => ({ ...old, price: undefined }) as any,
										)
									}
								>
									{!medicalCertificate?.price ? (
										<CheckCircle
											width={20}
											height={20}
											color={theme.colors.primary}
										/>
									) : (
										<Circle
											width={20}
											height={20}
											color={theme.colors.ash800}
										/>
									)}
								</IconButton>
								<p className="m-0 text-4">Free / No need to pay</p>
							</div>
						</div>
					</div>
				</div>
			</CustomScrollDiv>
			<div className="py-3 px-4 border-t-solid border-t border-gray-10">
				<ButtonRemoveSave
					isEnableRemoveBtn={!isLoading}
					isEnableSaveBtn={!isLoading}
					onRemove={removeMedicalCertificate}
					onSave={saveMedicalCertificate}
				/>
			</div>
		</div>
	);
}
