import React, { useContext, useState } from 'react';
import 'antd/dist/antd.min.css';
import './styles.less';
import { Card, Badge, Button, Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import styled from 'styled-components';
import {
	DatesContext,
	DateType,
} from '../../../screens/app/Menstruation/Index';
import CalendarLayout from './CalendarLayout';

const dateToISOString = (date: Date) => {
	const myDate = new Date(new Date(date).setHours(12));
	return myDate.toISOString().replace('-', '/').split('T')[0].replace('-', '/');
};

const CalendarMensLog = () => {
	const [edit, setEdit] = useState<boolean>(false);
	const [periodList, setPeriodList] = useState<any[]>([]);
	const datesContext = useContext(DatesContext);
	const { dates } = datesContext;

	const sortDatesPeriod = () => {
		periodList.sort(
			(date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		);
	};

	const addDatesPeriod = (date: Date) => {
		const dateString = dateToISOString(date);
		if (!periodList.includes(dateString)) {
			periodList.push(dateString);
		}
		sortDatesPeriod();
	};

	const deleteDatesPeriod = (date: string) => {
		setPeriodList(periodList.filter((period) => period !== date));
		sortDatesPeriod();
	};

	const editHandler = () => {
		setEdit(!edit);
	};

	const saveChanges = () => {
		const mensStart = new Date(periodList[0]);
		const mensEnd = new Date(periodList[periodList.length - 1]);
		var datesCopy: DateType = { ...dates };
		if (datesCopy.mensStart) {
			datesCopy.mensStart[0] = mensStart;
		}
		if (datesCopy.mensEnd) {
			datesCopy.mensEnd[0] = mensEnd;
		}
		console.log(mensEnd);
		editHandler();
	};

	const onPanelChange = (value: any) => {
		if (edit) {
			(async (state: boolean) => {
				setEdit(state);
			})(false).then(() => setEdit(true));
		}
	};

	const checkBoxHandler = (e: CheckboxChangeEvent, date?: Date) => {
		console.log(e.target.checked);
		console.log(date);
		e.target.checked && addDatesPeriod(date ?? new Date());
		!e.target.checked && deleteDatesPeriod(dateToISOString(date ?? new Date()));
	};

	const fertileIcon = <FertileIcon>&#9829;</FertileIcon>;

	const checkBox = (value: Date, check: boolean) => {
		const date = new Date(value);

		if (check) {
			addDatesPeriod(date);
		}

		return (
			<CheckBoxContainer>
				<Checkbox
					defaultChecked={check}
					onChange={(e) => checkBoxHandler(e, date)}
					className="check-box-style"
					style={CheckBoxStyle}
				/>
			</CheckBoxContainer>
		);
	};

	const Marks = (
		<>
			<Badge color="#D81F64" text="Period" />
			<Badge color="#FDDAE7" text="Predict" />
			<Badge color="#268E6C" text="Fertile" />
			<div style={{ display: 'inline-block' }}>
				<span style={{ fontSize: 13, color: '#268E6C' }}>&#9829;</span>{' '}
				Ovulation
			</div>
		</>
	);

	const SaveEdit = (
		<Button
			size="large"
			type="primary"
			style={{ marginLeft: 10 }}
			onClick={saveChanges}
		>
			Save
		</Button>
	);

	const dateCellRender = (value: any) => {
		const nowDate = new Date();
		const datesNorm = new Date(dateToISOString(value));
		const date = ('0' + datesNorm.getDate()).slice(-2);

		if (edit) {
			// menstruation
			for (let i = 0; i < 3; i++) {
				if (
					datesNorm >= new Date(dates?.mensStart?.at(i) ?? new Date()) &&
					datesNorm <= new Date(dates?.mensEnd?.at(i) ?? new Date())
				) {
					if (
						nowDate >= new Date(dates?.mensStart?.at(i) ?? new Date()) &&
						nowDate <= new Date(dates?.mensEnd?.at(i) ?? new Date())
					) {
						return checkBox(value, true);
					}
					if (i === 0) {
						return checkBox(value, true);
					}
				}
			}
			return checkBox(value, false);
		}

		if (!edit) {
			// menstruation
			for (let i = 0; i < 3; i++) {
				if (
					datesNorm >= new Date(dates?.mensStart?.at(i) ?? new Date()) &&
					datesNorm <= new Date(dates?.mensEnd?.at(i) ?? new Date())
				) {
					if (
						nowDate >= new Date(dates?.mensStart?.at(i) ?? new Date()) &&
						nowDate <= new Date(dates?.mensEnd?.at(i) ?? new Date())
					) {
						return (
							<Marker style={{ backgroundColor: '#D81F64', color: 'white' }}>
								{date}
							</Marker>
						);
					}
					return (
						<Marker style={{ backgroundColor: '#FDDAE7', zIndex: -1 }}></Marker>
					);
				}
			}
			// Ovulation
			for (let i = 0; i < 3; i++) {
				const date1 = dateToISOString(datesNorm);
				const date2 = dateToISOString(
					new Date(dates?.nextOvul?.at(i) ?? new Date()),
				);
				if (date1 === date2) {
					return (
						<Marker style={{ backgroundColor: '#268E6C1A' }}>
							{fertileIcon}
						</Marker>
					);
				}
			}
			// Fertile window
			for (let i = 0; i < 3; i++) {
				if (
					datesNorm >= new Date(dates?.fertileStart?.at(i) ?? new Date()) &&
					datesNorm <= new Date(dates?.fertileEnd?.at(i) ?? new Date())
				) {
					return <Marker style={{ backgroundColor: '#268E6C1A' }}></Marker>;
				}
			}
		}
	};

	return (
		<>
			<Card style={Wrapper}>
				<SignMarks>{Marks}</SignMarks>
				<CalendarLayout
					dateCellRender={dateCellRender}
					onPanelChange={onPanelChange}
				/>
			</Card>
			<Card style={FooterWrapper}>
				<Button size="large" style={EditBtnStyles} onClick={editHandler}>
					{!edit ? 'Edit Period Date' : 'Cancel'}
				</Button>
				{edit && SaveEdit}
			</Card>
		</>
	);
};

export default CalendarMensLog;

const Wrapper: React.CSSProperties = {
	paddingBottom: 0,
	borderRadius: 0,
};

const EditBtnStyles: React.CSSProperties = {
	border: '1px solid #D81F64',
};

const FooterWrapper: React.CSSProperties = {
	borderBottomLeftRadius: 12,
	borderBottomRightRadius: 12,
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'end',
};

const CheckBoxStyle: React.CSSProperties = {
	// border: "1px solid black",
	height: '100%',
	// fontSize: 50,
};

const CheckBoxContainer = styled.div`
	// border: 1px solid black;
	// background-color: red;
	// border-radius: 50%;
	height: 52px;
	position: absolute;
	width: 100%;
	top: 0;
	padding-top: 24px;
	z-index: 7;
`;

const SignMarks = styled.div`
	// border: 1px solid black;
	padding-left: 12px;
	display: flex;
	gap: 40px;
	margin-bottom: 26px;
	margin-top: 26px;
`;

const Marker = styled.div`
	border: 2px solid rgba(0, 0, 0, 0);
	// display: none;
	position: absolute;
	top: 0px;
	width: 52px;
	height: 52px;
	border-radius: 50%;
	background-color: red;
	// z-index: -1;
	font-size: 16px;
	color: #268e6c;
`;

const FertileIcon = styled.div`
	// border: 1px solid black;
	padding-top: 16px;
	display: absolute;
	font-size: 13px;
`;
