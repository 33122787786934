import React, { useState, useEffect } from 'react';
import { Card, Typography, Input, Divider } from 'antd';
import { SearchOutlined, RightOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import HealthSupportsList from './AppToolsList';
import CalculatorsList from './CalculatorsListCollapse';
import { useHistory } from 'react-router-dom';
import EmptyList from '../EmptyList';
import { iconEmptySecretary as IconEmpty } from '../../assets/icons';

const { Title } = Typography;

const calculatorsData = [
	{
		title: 'BMI Calculator',
		desc: 'Body Mass Index is a way to calculate ideal body weight based on height and weight. It is also distinguished by age.',
		path: '/app/medical-calculator/bmi',
	},
	{
		title: 'Pregnancy Due Date',
		desc: 'Ultrasound (or sonogram) scans are totally painless and safe. They use high-frequency sound waves to get an image of the fetus to check fetal development and the progress of your pregnancy.',
		path: '/app/medical-calculator/pregnancy',
	},
	{
		title: 'Menstruation Calendar',
		desc: 'The menstruation calculator determines when your next period is due based on a 28 day menstrual cycle. All you need to do is enter the date when your last menstrual bleeding began, and the calculator will tell you when to expect your next period.',
		path: '/app/medical-calculator/menstruation',
	},
	{
		title: 'ACC/AHA Risk Calculator',
		desc: 'Calculate your 10-year risk of heart disease or stroke using the ASCVD algorithm published in 2013 ACC/AHA Guideline on the Assessment of Cardiovascular Risk',
		path: '/app/medical-calculator/cardio-risk/aha',
	},
	{
		title: 'Framingham Risk Score',
		desc: 'The Framingham Risk Score is a sex-specific algorithm used to estimate the 10-year cardiovascular risk of an individual',
		path: '/app/medical-calculator/cardio-risk/framingham',
	},
	{
		title: 'Revised Cardiac Risk Index',
		desc: 'Estimates risk of cardiac complications after noncardiac surgery',
		path: '/app/medical-calculator/cardio-risk/revised-cardiac',
	},
	{
		title: 'Findrisc Calculator',
		desc: 'A prediction tool that can identify patients at risk of developing diabetes without need for laboratory testing.',
		path: '/app/medical-calculator/diabetes-risk/find-risc',
	},
	{
		title: 'HbA1C',
		desc: 'Is your average blood glucose (Sugar) levels for the last two to three months.',
		path: '/app/medical-calculator/diabetes-risk/hba1c',
	},
];

export default function IndexMainScreenAppCalculator() {
	// const goTo = (e: React.MouseEvent<Element, MouseEvent>, goTo: number) => {
	//   Props.goToBtnHandler(goTo);
	// };
	const history = useHistory();
	const [search, setSearch] = useState<string>('');
	const [onSearch, setOnSearch] = useState<boolean>(false);
	const [resultsSearch, setresultsSearch] = useState<any>();

	useEffect(() => {
		if (!search) {
			setOnSearch(false);
		} else {
			setOnSearch(true);
		}
	}, [search]);

	const searchHandler = (e: any) => {
		setSearch(e.target.value);
		const results = calculatorsData.filter((data) => {
			if (e.target.value === '') return data;
			return data.title.toLowerCase().includes(e.target.value.toLowerCase());
		});
		setresultsSearch(results);
	};

	const ResultsList =
		resultsSearch?.length > 0 ? (
			resultsSearch?.map((item: any, index: any) => {
				return (
					<div
						key={index}
						style={ResultItemContainer}
						onClick={() => history.push(item.path)}
					>
						<div>
							<TitleContainer>{item.title}</TitleContainer>
							<TextContainer>{item.desc}</TextContainer>
						</div>
						<ResultItemIconContainer>
							<RightOutlined />
						</ResultItemIconContainer>
					</div>
				);
			})
		) : (
			<EmptyList
				icon={<IconEmpty />}
				title="Calculator Not Found"
				description={`Calculator with this input ${search} is not available`}
			/>
		);

	return (
		<>
			<Card style={HeaderCardWrapperStyles}>
				<Title level={5} style={TitleStyles}>
					Medical Calculator
				</Title>
			</Card>
			<Card style={BodyCardWrapper}>
				<Input
					size="large"
					placeholder="Search in medical calculator"
					style={SearchInputStyles}
					onChange={(e) => searchHandler(e)}
					prefix={<SearchOutlined style={SearchIconStyles} />}
				/>
				<div style={{ display: `${onSearch ? 'none' : 'block'}` }}>
					<Title level={5} style={{ ...TitleStyles, fontSize: 20 }}>
						Health Supports
					</Title>
					<HealthSupportsContainer>
						<HealthSupportsList />
					</HealthSupportsContainer>
					<Divider />
					<Title level={5} style={{ ...TitleStyles, fontSize: 20 }}>
						Calculators
					</Title>
					<CalculatorsList />
				</div>
				<ResultsContainer
					style={{
						display: `${onSearch ? 'block' : 'none'}`,
					}}
				>
					{ResultsList}
				</ResultsContainer>
			</Card>
		</>
	);
}

const HeaderCardWrapperStyles = {
	// border: "1px solid black",
	borderRadius: '12px',
	borderBottomLeftRadius: '0px',
	borderBottomRightRadius: '0px',
	display: 'flex',
	width: '100%',
	alignItems: 'center',
	justifyContent: 'start',
};

const TitleStyles = {
	// border: "1px solid black",
	display: 'inline-block',
	fontSize: 24,
	fontWeight: 600,
	marginBottom: 0,
	margin: '0 auto',
};

const BodyCardWrapper = {
	borderBottomLeftRadius: 12,
	borderBottomRightRadius: 12,
};

const SearchInputStyles = {
	// border: "1px solid black",
	borderRadius: 12,
	height: 48,
	marginBottom: 24,
};

const SearchIconStyles = {
	color: 'gray',
	marginRight: 10,
};

const ResultItemContainer = {
	marginBottom: 24,
	borderBottom: '1px solid whitesmoke',
	paddingBottom: 12,
	display: 'flex',
};

const HealthSupportsContainer = styled.div`
	// border: 1px solid black;
	margin-top: 24px;
	margin-bottom: 24px;
	display: flex;
	gap: 24px;
	flex-wrap: wrap;
	/* justify-content: center;
  align-items: center;
  width: 100%; */
`;

const TitleContainer = styled.div`
	font-weight: 600;
`;

const TextContainer = styled.div`
	font-size: 16;
	font-weight: 400;
	color: #777777;
	letter-space: 01;
	line-height: 1.2;
`;

const ResultsContainer = styled.div`
	margin-top: 12;
`;

const ResultItemIconContainer = styled.div`
	padding-left: 12px;
	padding-top: 20px;
	display: 'flex';
	justify-content: 'center';
	align-items: 'center';
	color: '#777777';
`;
