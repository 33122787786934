import { createGlobalState } from 'react-hooks-global-state';
import useAuth from './useAuth';
import useSchedulesNew from './useSchedulesNew';
import { httpRequest } from '../helpers/api';

const initialState: any = {
	integratedSchedule: {},
	loadingIntegrate: false,
};

const { useGlobalState } = createGlobalState(initialState);

export default function useSyncCalendar() {
	const [integratedSchedule, setIntegratedSchedule] =
		useGlobalState('integratedSchedule');
	const [loadingIntegrate, setLoadingIntegrate] =
		useGlobalState('loadingIntegrate');

	const { user } = useAuth();
	const { fetchList } = useSchedulesNew({
		userId: user?.userId || '',
	});

	// /personal-events/sync-calender
	const addSyncCalendar = async (data: any) => {
		setLoadingIntegrate(true);
		try {
			const resSync = await httpRequest.put(
				'/personal-events/sync-calender',
				data,
			);

			if (!(resSync?.data as any)?.payload) {
				const error = 'SyncCalendar ===> httpRequest.post failed';
				console.error(error);
				throw error;
			}

			console.log('resSync===>', JSON.stringify(resSync));
			fetchList();
		} catch (error) {
			console.info('error addAddress====> ', error);
		} finally {
			setLoadingIntegrate(false);
		}
	};

	return {
		// state
		integratedSchedule,
		loadingIntegrate,
		// handler
		setIntegratedSchedule,
		setLoadingIntegrate,
		addSyncCalendar,
	};
}
