import { Modal } from 'antd';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CheckSquare, ChevronRight, Info, Search, Square } from 'react-feather';
import { theme } from '../../../../../../assets/theme';
import useFetchList from '../../../../../../hooks/useFetchList';
import { IndividualHealthServiceProperties } from '../../../../../../types/individualHealthService.type';
import {
	LabRequestItem,
	LabRequestProperties,
} from '../../../../../../types/labRequest.type';
import { Button } from '../../../../../Button/Button/Button';
import IconButton from '../../../../../Button/IconButton';
import CustomScrollDiv from '../../../../../CustomScrollDiv';
import EmptyList from '../../../../../EmptyList';
import FormInput from '../../../../../Form/FormInput2';
import Separator from '../../../../../Separator';
import Spinner from '../../../../../Spinner';
import Tab from '../../../../../Tab/Tab';
// import useAuth from "../../../../../../hooks/useAuth";
import { LabRequestTemplateProperties } from '../../../../../../types/templateLabRequest';
import { useAuth } from '../../../../../../context/auth.context';

interface Props {
	isShow: boolean;
	selectedHealthServices?: LabRequestItem[];
	onCloseModal: () => void;
	addProcedures: (procedures: IndividualHealthServiceProperties[]) => void;
	setLabRequest?: React.Dispatch<
		React.SetStateAction<LabRequestProperties | undefined>
	>;
	labRequest?: LabRequestProperties | undefined;
}

const tabs = ['All Lab Request', 'Template'];

export default function ModalAddProcedure(props: Props) {
	const { userId } = useAuth();
	const footerElement = useRef<HTMLDivElement>(null);
	const [selectedTabIndex, setSelectedTabIndex] = useState(0);
	const [modalAddNewLabReq, setModalAddNewLabelReq] = useState(false);
	const [labRequestItem, setLabRequestItem] = useState<
		IndividualHealthServiceProperties[]
	>([]);
	const [selectedHealthServices, setSelectedHealthServices] = useState<
		IndividualHealthServiceProperties[]
	>([]);

	const getPathId = () => {
		const path = window.location.pathname;
		const pathSegments = path.split('/');
		const id = pathSegments[pathSegments.length - 1];
		return id;
	};

	const templateId = getPathId();

	useEffect(() => {
		if (!props.isShow) return;

		const selectedHealthServices = props.selectedHealthServices?.map(
			(p) => p.metaHealthService,
		) as IndividualHealthServiceProperties[];
		if (selectedHealthServices) {
			setSelectedHealthServices(selectedHealthServices);
		} else {
			setSelectedHealthServices([]);
		}
	}, [props.isShow]);

	const {
		data: healthServices,
		isLoading,
		search,
		pagination,
		setSearch,
		changePage,
	} = useFetchList<IndividualHealthServiceProperties>({
		endpoint: '/health-service/individuals',
		initialQuery: {
			limit: 1000000,
			isPublished: true,
		},
		primaryKey: 'healthServiceId',
		pushData: true,
	});

	const { data: templates, isLoading: isLoadingTemplates } =
		useFetchList<LabRequestTemplateProperties>({
			endpoint: 'template-lab-request',
			initialQuery: {
				limit: 1000000,
				doctorId: userId,
			},
			primaryKey: 'templateLabRequestId',
			pushData: true,
		});

	function handleNextPage() {
		if (!isLoading && pagination.next) {
			changePage(pagination.page + 1);
		}
	}

	const sectionData = useMemo(() => {
		const section: {
			title: string;
			data: IndividualHealthServiceProperties[];
		}[] = [];
		if (healthServices) {
			healthServices.forEach((service) => {
				const title = service.category?.categoryName;
				const index = section.findIndex((s) => s.title === title);
				if (index >= 0) {
					section[index].data.push(service);
				} else if (title && service) {
					section.push({
						title: title,
						data: [service],
					});
				}
			});
		}
		return section;
	}, [healthServices]);

	const handleObserver = useCallback((entries) => {
		const target = entries[0];
		if (target.isIntersecting) {
			handleNextPage();
		}
	}, []);

	useEffect(() => {
		if (footerElement.current) {
			const element = footerElement.current;

			const option = {
				root: null,
				rootMargin: '20px',
				threshold: 0,
			};
			const observer = new IntersectionObserver(handleObserver, option);
			observer.observe(element);

			return () => {
				observer.unobserve(element);
				observer.disconnect();
			};
		}
	}, [handleObserver]);

	useEffect(() => {
		const isTemplateRequestIdAvailable = templates.some(
			(template) => template.templateLabRequestId === templateId,
		);
		if (isTemplateRequestIdAvailable == true) {
			props.setLabRequest!({
				...props.labRequest,
				assessmentDiagnosis: templates[0].assessmentDiagnosis,
				specialInstructions: templates[0].specialInstructions,
				procedures: templates[0].templateProcedures?.map((temp) => ({
					healthServiceId: temp.healthServiceId,
					metaHealthService: temp.metaHealthService,
				})),
			});
		}
	}, [templates]);

	function onSelectHealthService(item: IndividualHealthServiceProperties) {
		setSelectedHealthServices((old) => {
			const oldData = [...old];
			const index = oldData.findIndex(
				(d) => d.healthServiceId === item.healthServiceId,
			);
			if (index >= 0) {
				oldData.splice(index, 1);
			} else {
				oldData.push(item);
			}
			return oldData;
		});
	}

	function saveHealthServices() {
		props.addProcedures(selectedHealthServices);
		props.onCloseModal();
	}

	function saveNewLabReq() {
		props.addProcedures(labRequestItem);
		setLabRequestItem([]);
		setModalAddNewLabelReq(false);
	}

	const openModalAddNewLabelReq = () => {
		setModalAddNewLabelReq(true);
		props.onCloseModal();
	};

	const generateRandomId = () => {
		return Math.random().toString(36).substr(2, 9);
	};

	return (
		<>
			<Modal
				open={props.isShow}
				onCancel={props.onCloseModal}
				footer={null}
				title={<p className="m-0 text-center font-semibold">Add Lab Request</p>}
				bodyStyle={{ padding: 0 }}
				wrapClassName="modal-h-full"
			>
				<div className="flex-1 min-h-0 flex flex-col">
					<div className="py-1 px-3">
						<FormInput
							prefix={
								<Search
									width={20}
									height={20}
									color={theme.colors.charcoal200}
								/>
							}
							placeholder="Search procedures"
							value={search}
							onChange={setSearch}
						/>
					</div>
					<Tab
						tabs={tabs}
						selectedTabIndex={selectedTabIndex}
						setSelectedTabIndex={setSelectedTabIndex}
						useBottomSeparator
					/>
					{!isLoading ? (
						selectedTabIndex === 0 ? (
							<CustomScrollDiv className="px-3 flex-1 min-h-0 mt-4">
								{!isLoading ? (
									healthServices.length > 0 ? (
										sectionData.map((section, index) => (
											<div key={section.title}>
												<div className="px-4">
													<p className="m-0 text-4 font-semibold">
														{section.title}
													</p>
													<div>
														{section.data.map((procedure, index) => (
															<div key={procedure.healthServiceId}>
																<div
																	onClick={() =>
																		onSelectHealthService(procedure)
																	}
																	style={{ cursor: 'pointer' }}
																	className="flex gap-x-3 py-3 items-center"
																>
																	<IconButton onClick={() => {}}>
																		{selectedHealthServices.findIndex(
																			(h) =>
																				h.healthServiceId ===
																				procedure.healthServiceId,
																		) >= 0 ? (
																			<CheckSquare
																				width={20}
																				height={20}
																				color={theme.colors.primary}
																			/>
																		) : (
																			<Square
																				width={20}
																				height={20}
																				color={theme.colors.ash800}
																			/>
																		)}
																	</IconButton>
																	<p className="m-0">
																		{procedure.healthServiceName}
																	</p>
																</div>
																{index < section.data.length - 1 && (
																	<Separator />
																)}
															</div>
														))}
													</div>
												</div>
												{index < sectionData.length - 1 && (
													<div className="py-3">
														<Separator style={{ height: 6 }} />
													</div>
												)}
												<div ref={footerElement} />
											</div>
										))
									) : (
										<EmptyList
											icon={<Info />}
											title="Empty Data"
											description=""
										/>
									)
								) : (
									<div className="w-fit mx-auto py-4">
										<Spinner size={25} color={theme.colors.primary} />
									</div>
								)}
							</CustomScrollDiv>
						) : selectedTabIndex === 1 ? (
							<CustomScrollDiv className="px-3 flex-1 min-h-0 mt-4">
								{!isLoadingTemplates ? (
									templates.length > 0 && healthServices.length > 0 ? (
										templates.map((item, index) => (
											<ItemTemplate
												key={index}
												title={(item as any)?.templateName ?? ''}
												numberOfMedicine={
													(item as any)?.templateProcedures?.length ?? 0
												}
												onClick={() => {
													props.setLabRequest!({
														...props.labRequest,
														assessmentDiagnosis: item.assessmentDiagnosis,
														specialInstructions: item.specialInstructions,
														procedures: item.templateProcedures?.map(
															(temp) => ({
																healthServiceId: temp.healthServiceId,
																metaHealthService: temp.metaHealthService,
															}),
														),
													});
													props.onCloseModal();
												}}
											/>
										))
									) : (
										<EmptyList
											icon={<Info />}
											title="Empty Data"
											description=""
										/>
									)
								) : (
									<div className="w-fit mx-auto py-4">
										<Spinner size={25} color={theme.colors.primary} />
									</div>
								)}
							</CustomScrollDiv>
						) : undefined
					) : (
						<div className="py-4 w-fit mx-auto">
							<Spinner size={25} color={theme.colors.primary} />
						</div>
					)}

					{healthServices.length > 0 && (
						<div className="flex p-3 justify-between">
							<div className="w-44">
								<Button
									type="SOLIDASH"
									onClick={() => openModalAddNewLabelReq()}
								>
									Add New Lab Request
								</Button>
							</div>
							<div className="w-44">
								<Button type="SOLID" onClick={saveHealthServices}>
									Save
								</Button>
							</div>
						</div>
					)}
				</div>
			</Modal>
			<Modal
				open={modalAddNewLabReq}
				onOk={saveNewLabReq}
				onCancel={() => setModalAddNewLabelReq(false)}
				title={
					<p className="m-0 text-center font-semibold">Add New Lab Request</p>
				}
			>
				<FormInput
					placeholder="Enter new Lab Request"
					value={
						labRequestItem.length > 0
							? labRequestItem[0]?.healthServiceName || ''
							: ''
					}
					onChange={(val) =>
						setLabRequestItem([
							{
								healthServiceId: generateRandomId(),
								healthServiceName: val,
							},
						])
					}
				/>
			</Modal>
		</>
	);
}

function ItemTemplate(props: {
	title: string;
	numberOfMedicine: number;
	onClick: () => void;
}) {
	return (
		<button
			onClick={props.onClick}
			className="border-none bg-transparent items-center cursor-pointer p-0 text-left w-full flex gap-x-2 p-3"
		>
			<div className="flex-1">
				<p className="m-0">{props.title}</p>
			</div>
			<div>
				<ChevronRight width={20} height={20} color={theme.colors.black30} />
			</div>
		</button>
	);
}
