import React, { useEffect, useState } from 'react';
import { Card, Divider, Button, Spin } from 'antd';
import AppLayout from '../../../../layout/AppLayout';
import PageHeader from '../../../../components/PageHeader';
import { UserBankAccountProperties } from '../../../../types/bankAccount.type';
import { useHistory } from 'react-router-dom';
import BankAccountListItem from '../../../../components/BankAccount/BankAccountListItem';
import ModalAddEditBankAccount from '../../../../components/BankAccount/ModalAddEditBankAccount';
import useFetchList from '../../../../hooks/useFetchList';
import EmptyList from '../../../../components/EmptyList';
import { Info } from 'react-feather';
import styled from 'styled-components';
import { useAuth } from '../../../../context/auth.context';
import { httpRequest } from '../../../../helpers/api';
import { ApiResponsePagination } from '../../../../types/apiResponse.type';
import { generateQueryString } from '../../../../helpers/generateQueryString';

const activePath = '/app/setting/bank-account';

export default function BankAccountScreen() {
	const { userId } = useAuth();
	const history = useHistory();

	const [isShowModalAddEdit, setIsShowModalAddEdit] = useState<boolean>(false);

	const [tmpData, setTmpData] = useState<UserBankAccountProperties>();

	const [isLoading, setIsLoading] = useState(true);
	const [bankAccounts, setBankAccounts] = useState<UserBankAccountProperties[]>(
		[],
	);

	const bankAccountsFetchList = async () => {
		setIsLoading(true);

		try {
			const res = await httpRequest.get<
				ApiResponsePagination<UserBankAccountProperties>
			>(
				'/user-bank-accounts' +
					generateQueryString({
						userId: userId,
						limit: 1000000,
					}),
			);
			console.log(res);
			if (res?.data?.payload?.results) {
				setBankAccounts(res.data.payload?.results);
			}
		} catch (error) {
			console.info('error fetch doctor ::: ', error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (userId) {
			bankAccountsFetchList();
		}
	}, [userId]);

	return (
		<AppLayout activePath={activePath}>
			<Card style={{ paddingBottom: 80 }}>
				<PageHeader
					title="Bank Account"
					onClick={() => history.goBack()}
					rightmostChild={
						<Button
							onClick={() => {
								setTmpData(undefined);
								setIsShowModalAddEdit(true);
							}}
							type="primary"
							style={{
								padding: '20px 14px',
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'center',
								alignItems: 'center',
								borderRadius: 12,
								fontSize: 16,
							}}
						>
							Add New
						</Button>
					}
				/>
				<Divider />
				{isLoading && <CustomSpin spinning={isLoading} />}
				{!isLoading &&
					(bankAccounts.length === 0 ? (
						<EmptyList
							icon={<Info />}
							title="Bank Account Empty"
							description="Please add your bank account"
						/>
					) : (
						<>
							{bankAccounts.map((item) => (
								<BankAccountListItem
									key={item.userBankAccountId}
									data={item}
									onPress={() => {
										setTmpData(item);
										setIsShowModalAddEdit(true);
									}}
									buttonText={'Edit'}
								/>
							))}
						</>
					))}
				<ModalAddEditBankAccount
					visible={isShowModalAddEdit}
					setVisible={setIsShowModalAddEdit}
					data={tmpData}
					fetchList={bankAccountsFetchList}
				/>
			</Card>
		</AppLayout>
	);
}

const CustomSpin = styled(Spin)`
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0);
	margin: 20px 0;
`;
