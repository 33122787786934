import { BaseResponseProps } from './config.type';
import {
	IndividualHealthServiceProperties,
	PackageHealthServiceProperties,
} from './individualHealthService.type';
import { PartnerProperties } from './partner.type';
import { PatientProperties } from './patient.type';
import { initialPayment, PaymentProperties } from './payment.type';
import { ETransactionStatusType } from './transactionConsultation.type';

export enum EAvailableServiceTypes {
	HOME_SERVICE = 'HOME_SERVICE',
	IN_CLINIC = 'IN_CLINIC',
}

export type TransactionHealthServiceProps = {
	transactionHealthServiceId: string;
	patientId: string;
	patientInRelationId?: string;
	partnerId: string;
	prescriptionId?: string;
	serviceType: EAvailableServiceTypes;
	noteFromPatient?: string;
	noteFromLab?: string;
	transactionStatus: ETransactionStatusType;
	lastUpdatedByUser?: any;
	scheduledStartAt?: Date;
	scheduledEndAt?: Date;
	realStartAt?: Date;
	realEndAt?: Date;

	metaPartner?: PartnerProperties;
	metaPatient?: PatientProperties;
	metaPatientInRelation?: {
		patientInRelationId: string;
		patientId: string;
		firstName?: string;
		middleName?: string;
		lastName?: string;
		nickName?: string;
		relation?: string;
		gender?: string;
		email?: string;
		phone?: string;
		pwId?: string;
		pwIdStatus?: string; //ePWIDstatus,
	};
	metaAddress?: {
		addressId: string;
		label: string;
		geolocation: {};
		addressDetail: string;
	};

	createdAt?: Date;
	updatedAt?: Date;
	transactionHealthServiceItems: TransactionHealthServiceItemProps[];

	payment?: PaymentProperties;
};

export interface TransactionHealthServiceItemProps {
	id?: string;
	transactionHealthServiceId: string;
	healthServiceId?: string;
	noteFromPatient?: string;
	noteFromLab?: string;
	metaHealthService?:
		| IndividualHealthServiceProperties
		| PackageHealthServiceProperties;
	createdAt?: string;
	updatedAt?: string;
}

export interface FetchAllTransactionHealthServiceResponse
	extends BaseResponseProps {
	payload: {
		count: number;
		prev: string;
		next: string;
		results: TransactionHealthServiceProps[];
	};
}

export const initialTransactionHealthService: TransactionHealthServiceProps = {
	transactionHealthServiceId: '',
	patientId: '',
	patientInRelationId: '',
	partnerId: '',
	prescriptionId: '',
	serviceType: EAvailableServiceTypes.HOME_SERVICE,
	noteFromPatient: '',
	noteFromLab: '',
	transactionStatus: ETransactionStatusType.WAITING_PAYMENT,
	lastUpdatedByUser: '',
	scheduledStartAt: new Date(),
	scheduledEndAt: new Date(),
	realStartAt: new Date(),
	realEndAt: new Date(),
	createdAt: new Date(),
	updatedAt: new Date(),
	transactionHealthServiceItems: [],
	payment: initialPayment,
};
