import { httpRequest } from '../helpers/api';
import { getErrorMessage } from '../helpers/errorHandler';
import { EPointCategory } from '../types/point.type';
import { ApiResponseDefault } from './apiResponse.type';
// import httpRequest from './httpRequest';
// import {ApiResponseDefault} from './types/core';

export async function apiPostPointReward(params: {
	category: EPointCategory;
	relatedActionId?: string;
	isRelatedActionIdUnique?: boolean;
	metaRelatedAction?: any;
	filters?: {
		maxNumber?: number;
		dayInterval?: number;
	};
}) {
	try {
		console.log('REWARD');

		const res = await httpRequest.post<ApiResponseDefault<any>>(
			`/base-rewards/${params.relatedActionId || ''}`,
			{
				category: params.category,
				isRelatedActionIdUnique: params.relatedActionId
					? params.isRelatedActionIdUnique
					: undefined,
				meta: params.metaRelatedAction,
				filters: params.filters,
			},
		);

		console.log('RES REWARD', res.data);

		if (res && res.data && res.data.payload) {
			return true;
		} else {
			return false;
		}
	} catch (error) {
		if (getErrorMessage(error).includes('You cannot get this reward again')) {
			//
		} else {
			console.error('ERROR PointReward ', error);
		}
		return false;
	}
}
