import { message } from 'antd';
import { isNumber } from 'lodash';
import { useState } from 'react';
import { ArrowLeft, CheckCircle, Circle } from 'react-feather';
import { theme } from '../../../../assets/theme';
import { httpRequest } from '../../../../helpers/api';
import { getErrorMessage } from '../../../../helpers/errorHandler';
import { useAuth } from '../../../../context/auth.context';
import { ApiResponseDefault } from '../../../../types/apiResponse.type';
import {
	DoctorDocumentProperties,
	EMetaType,
	MetaOthers,
} from '../../../../types/doctorDocument.type';
import { TemplateDoctorDocumentProperties } from '../../../../types/doctorDocumentTemplate.type';
import { Button } from '../../../Button/Button/Button';
import IconButton from '../../../Button/IconButton';
import FormInput, { EFormType } from '../../../Form/FormInput2';
import Separator from '../../../Separator';
import COLORS from '../../../../assets/globalColors';

const forms = [
	{
		formTitle: 'Detail Document',
		type: 'DoctorDocument',
		forms: [
			{
				label: 'Document title',
				name: 'title',
				type: EFormType.MULTI_LINE,
				showWhenType: [EMetaType.OTHERS],
				isRequired: true,
			},
			{
				label: 'Custom Content',
				name: 'content',
				type: EFormType.MULTI_LINE,
				showWhenType: [EMetaType.OTHERS],
				rows: 12,
				isRequired: true,
			},
		],
	},
];

interface Props {
	templateDoctorDocumentId?: string;
	onBack: () => void;
}

export default function FormCreateTemplateOwnTemplate(props: Props) {
	const { user } = useAuth();

	const [isLoading, setIsLoading] = useState(false);

	const [ownTemplate, setOwnTemplate] = useState<{
		[key: string]: any;
	}>();

	async function saveOwnTemplate() {
		if (!ownTemplate?.title || !ownTemplate?.content) {
			message.warning('Warning: Data is not yet complete!');
			return;
		}

		setIsLoading(true);

		try {
			const params: TemplateDoctorDocumentProperties = {
				doctorId: user?.userId || '',
				templateName: ownTemplate?.title,
				saveFromId: user?.userId,
				price: ownTemplate?.price || 0,
				type: EMetaType.OTHERS,
				meta: {
					title: ownTemplate?.title,
					content: ownTemplate?.content,
				} as MetaOthers,
			};

			const url =
				'/template-doctor-documents/' +
				(props.templateDoctorDocumentId ? props.templateDoctorDocumentId : '');

			let res: { data: ApiResponseDefault<TemplateDoctorDocumentProperties> };

			if (props.templateDoctorDocumentId) {
				res = await httpRequest.patch<
					ApiResponseDefault<TemplateDoctorDocumentProperties>
				>(url, params);
			} else {
				res = await httpRequest.post<
					ApiResponseDefault<TemplateDoctorDocumentProperties>
				>(url, params);
			}

			setOwnTemplate(res?.data?.payload as any);

			props.onBack();
		} catch (err) {
			const error = getErrorMessage(err);
			console.error(error);
			message.error(error);
			setIsLoading(false);
		}
	}

	return (
		<div>
			<div className="relative px-6 py-5.5 flex items-center">
				<button
					onClick={props.onBack}
					className="border-none bg-transparent p-0 absolute flex items-center gap-x-1 cursor-pointer"
				>
					<ArrowLeft width={20} height={20} />
					<p className="m-0 font-bold text-3.5">Back</p>
				</button>
				<h1 className="font-semibold text-6 m-0 w-full text-center">
					Create Template
				</h1>
			</div>
			<Separator />
			<form>
				<div className="py-6 px-3">
					{forms.map((formsData, index) => (
						<div key={index}>
							<div className="flex gap-x-3">
								<div className="pt-2 w-1/4">
									<p className="m-0 font-semibold text-4">
										{formsData.formTitle}
									</p>
								</div>
								<div className="flex-1">
									{formsData.forms.map((form) => {
										// if (!!form?.showWhenType) {
										//   if (!form?.showWhenType.includes(EMetaType.OTHERS)) {
										//     return <></>;
										//   }
										// }

										return (
											<FormInput
												key={form.label}
												label={form.label}
												type={form.type}
												// items={form?.items}
												required={form.isRequired}
												// withoutHorizontalPadding={!!form.items}
												// withoutVerticalPadding={!!form.items}
												value={ownTemplate?.[form.name]}
												// suffix={form.suffix}
												// formType={form.formType}
												// min={form.min}
												rows={form.rows}
												onChange={(val) =>
													setOwnTemplate((old) => ({
														...old,
														[form.name]: val,
													}))
												}
											/>
										);
									})}
								</div>
							</div>
							{index < forms.length - 1 && (
								<div className="my-1">
									<Separator />
								</div>
							)}
						</div>
					))}
					<div className="flex gap-x-3">
						<div className="w-1/4" />
						<div className="flex-1">
							<p className="m-0 text-4">
								Document Price <span style={{ color: COLORS.red }}>*</span>
							</p>
							<div className="flex items-center gap-x-2">
								<IconButton
									onClick={() =>
										setOwnTemplate((old) => ({ ...old, price: 1 }) as any)
									}
								>
									{!!ownTemplate?.price ? (
										<CheckCircle
											width={20}
											height={20}
											color={theme.colors.primary}
										/>
									) : (
										<Circle
											width={20}
											height={20}
											color={theme.colors.ash800}
										/>
									)}
								</IconButton>
								<div className="flex-1">
									<FormInput
										prefix="₱"
										value={ownTemplate?.price}
										onChange={(val) =>
											setOwnTemplate((old) => {
												const oldPrice = old?.price;
												let price = 0;
												if (val.at(-1) === '.') {
													if (!oldPrice?.toString().includes('.')) {
														price = val as any;
													}
												}
												if (!price) {
													price = Number(val);
													if (!isNumber(price) || isNaN(price)) {
														price = oldPrice;
													}
												}

												return { ...old, price } as any;
											})
										}
									/>
								</div>
							</div>
							<div className="flex items-center gap-x-2">
								<IconButton
									onClick={() =>
										setOwnTemplate(
											(old) => ({ ...old, price: undefined }) as any,
										)
									}
								>
									{!ownTemplate?.price ? (
										<CheckCircle
											width={20}
											height={20}
											color={theme.colors.primary}
										/>
									) : (
										<Circle
											width={20}
											height={20}
											color={theme.colors.ash800}
										/>
									)}
								</IconButton>
								<p className="m-0 text-4">Free / No need to pay</p>
							</div>
						</div>
					</div>
				</div>
				<Separator />
				<div className="py-3 px-4">
					<div className="w-44 ml-auto">
						<Button
							type="SOLID"
							onClick={saveOwnTemplate}
							isLoading={isLoading}
						>
							Save Template
						</Button>
					</div>
				</div>
			</form>
		</div>
	);
}
