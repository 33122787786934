import CustomScrollDiv from '../../../../CustomScrollDiv';
import SidebarMenuHeader from '../Components/SidebarMenuHeader';
import { ReactComponent as InfoCircle } from '../../../../../assets/icons/information-circle.svg';
import { theme } from '../../../../../assets/theme';
import { useMemo, useState } from 'react';
import Separator from '../../../../Separator';
import Tab from '../../../../Tab/Tab';
import { CheckCircle, ChevronDown, Circle } from 'react-feather';
import FormInput from '../../../../Form/FormInput2';
import { Button } from '../../../../Button/Button/Button';
import { EPrescriptionState } from './SidebarPrescription';
import IconButton from '../../../../Button/IconButton';
import { DrugMedicineProperties } from '../../../../../types/drugMedicine';

interface Props {
	medicine: DrugMedicineProperties;
	onNext: ({ state, dto }: { state: EPrescriptionState; dto?: any }) => void;
	onBack: () => void;
}

const tabs = ['Dosage List', 'Formulary'];

export default function SidebarPrescriptionMedicineSelected(props: Props) {
	const [selectedTab, setSelectedTab] = useState(0);
	const [selectedItem, setSelectedItem] = useState<number>();
	const [customDosage, setCustomDosage] = useState<string>();

	function changeTab(tab: number) {
		setSelectedTab(tab);
	}

	function onClickNext() {
		if (selectedTab < 1) {
			setSelectedTab((old) => old + 1);
		} else {
			props.onNext({
				state: EPrescriptionState.MEDICINE_DETAIL,
				dto: {
					selectedMedicine: {
						...props.medicine,
						dose:
							selectedItem === -1
								? customDosage
								: selectedItem !== undefined && selectedItem >= 0
								? props.medicine.doses?.[selectedItem].dose
								: undefined,
					} as DrugMedicineProperties,
					selectFromList: true,
				},
			});
		}
	}

	return (
		<div className="w-full flex flex-col h-full">
			<SidebarMenuHeader
				onBack={props.onBack}
				title={props.medicine.drugMedicineName ?? ''}
			/>
			{/* <div className="border-solid border border-ash-400 bg-ash-200 rounded-2 p-2 flex items-center gap-x-1">
				<InfoCircle width={14} height={14} color={theme.colors.primary} />
				<p className="m-0 text-3.5">
					You will get Medeasy Points if you prescribe this medicine
				</p>
			</div> */}
			<Tab
				tabs={tabs}
				selectedTabIndex={selectedTab}
				setSelectedTabIndex={changeTab}
				useBottomSeparator
			/>
			<CustomScrollDiv className="flex overflow-y-auto overflow-x-hidden w-full flex-1 min-h-0">
				<div className="w-full">
					{selectedTab === 0 ? (
						<>
							{props.medicine.doses?.map((dosage, index) => (
								<ItemDosage
									key={index}
									dosage={dosage.dose}
									isSelected={selectedItem === index}
									onSelected={() => setSelectedItem(index)}
									useBottomSeparator
								/>
							))}
							<ItemDosage
								dosage="Custom dosage"
								isSelected={selectedItem === -1}
								onSelected={() => setSelectedItem(-1)}
								onChange={setCustomDosage}
								valueCustomDosage={customDosage}
								isCustomDosage
								useBottomSeparator
							/>
						</>
					) : (
						<FormularyList medicine={props.medicine} />
					)}
				</div>
			</CustomScrollDiv>

			<Separator />
			<div className="py-2 px-4">
				<Button
					onClick={onClickNext}
					type="SOLID"
					isDisable={
						selectedItem === undefined ||
						(selectedItem === -1 &&
							(customDosage === undefined || customDosage.length === 0))
					}
				>
					Next
				</Button>
			</div>
		</div>
	);
}

function ItemDosage(props: {
	dosage: string;
	isSelected: boolean;
	isCustomDosage?: boolean;
	valueCustomDosage?: string;
	useBottomSeparator?: boolean;
	onSelected: () => void;
	onChange?: (val: string) => void;
}) {
	return (
		<>
			<div className="w-full py-4 flex gap-x-2">
				<IconButton onClick={props.onSelected}>
					{props.isSelected ? (
						<CheckCircle color={theme.colors.primary} />
					) : (
						<Circle color={theme.colors.ash800} />
					)}
				</IconButton>
				<div className="w-full">
					<p className="m-0">{props.dosage}</p>
					{props.isCustomDosage && props.isSelected && (
						<FormInput
							placeholder="Input dosage"
							value={props.valueCustomDosage}
							onChange={props.onChange!}
						/>
					)}
				</div>
			</div>
			{props.useBottomSeparator && <Separator />}
		</>
	);
}

function FormularyList(props: { medicine: DrugMedicineProperties }) {
	const formularySections = useMemo(() => {
		const sections = [];
		if (props.medicine) {
			for (const [key, val] of Object.entries(props.medicine)) {
				let title = '';
				switch (key) {
					case 'administration':
						title = 'Administration';
						break;
					case 'adverseDrugReactions':
						title = 'Adverse Drug Reactions';
						break;
					case 'compositions':
						title = 'Compositions';
						break;
					case 'contraIndications':
						title = 'Contra Indications';
						break;
					case 'description':
						title = 'Description';
						break;
					case 'dose':
						title = 'Dose';
						break;
					case 'doseNote':
						title = 'Dose Note';
						break;
					case 'indications':
						title = 'Indications';
						break;
					case 'pregnancyCategory':
						title = 'Pregnancy Category';
						break;
				}
				if (title && typeof val === 'string' && val.length > 0) {
					sections.push({
						title,
						description: val,
					});
				}
			}
		}
		return sections;
	}, [props.medicine]);

	return (
		<div>
			{formularySections.map((section) => (
				<ItemFormulary
					key={section.title}
					title={section.title}
					description={section.description}
				/>
			))}
		</div>
	);
}

function ItemFormulary(props: { title: string; description: string }) {
	const [isExpanded, setIsExpanded] = useState(false);

	return (
		<div>
			<button
				onClick={() => setIsExpanded((oldState) => !oldState)}
				className="py-4 w-full block p-0 bg-transparent border-none flex items-center justify-between cursor-pointer"
			>
				<p className="m-0 font-semibold">{props.title}</p>
				<ChevronDown
					width={20}
					height={20}
					className={'transition-transform' + (isExpanded ? ' rotate-180' : '')}
				/>
			</button>
			{isExpanded && (
				<div>
					<p className="m-0 text-4">{props.description}</p>
				</div>
			)}
		</div>
	);
}
