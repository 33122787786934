import AppLayout from '../../../layout/AppLayout';
import React, { useEffect, useState } from 'react';
import { MenuOutlined, SearchOutlined } from '@ant-design/icons';
import {
	Card,
	Divider,
	Typography,
	Tabs,
	Row,
	Tag,
	Input,
	Spin,
	Button,
} from 'antd';
import moment from 'moment';

import {
	iconInformationCircleSmall as IconInformationCircleSmall,
	iconStar as IconStar,
	iconEmptySecretary as IconEmpty,
} from '../../../assets/icons';
import styled from 'styled-components';
import useFetchList from '../../../hooks/useFetchList';
import {
	BalanceHistoryProperties,
	BalanceProperties,
} from '../../../types/balance.type';
import {
	PointHistoryProperties,
	PointProperties,
} from '../../../types/point.type';
import { httpRequest } from '../../../helpers/api';
import { ApiResponseDefault } from '../../../types/apiResponse.type';
import ModalTransactionFilter from '../../../components/Balance/ModalTransactionFilter';
import ModalDetail from '../../../components/Balance/ModalDetail';
import { useHistory, useLocation } from 'react-router-dom';
import ModalRedeem from '../../../components/Balance/ModalRedeem';
import EmptyList from '../../../components/EmptyList';
import { EKycStatus } from '../../../types/doctor.type';
import { useAuth } from '../../../context/auth.context';

const activePath = '/app/balance';
const { Title, Text } = Typography;

export default function BalanceScreen() {
	const { user, doctorKYCStatus } = useAuth();
	const history = useHistory();
	const location = useLocation();
	const initialData = location.state as unknown as {
		tab: string;
	};

	const [activeTab, setActiveTab] = useState<string>(
		initialData?.tab ? initialData.tab : '0',
	);

	const [currentBalance, setCurrentBalance] = useState<number>(0);
	const [currentPoint, setCurrentPoint] = useState<number>(0);
	const [pendingPoint, setPendingPoint] = useState<number>(0);

	const [isShowModalRedeem, setIsShowModalRedeem] =
		React.useState<boolean>(false);
	const [isShowModalDetail, setIsShowModalDetail] =
		React.useState<boolean>(false);
	const [isShowModalFilter, setIsShowModalFilter] =
		React.useState<boolean>(false);

	const [filterList, setFilterList] = React.useState<any>({});
	const [tmpData, setTmpData] = React.useState<any>({});
	const [search, setSearch] = useState<string>('');

	// Fetch API
	const {
		isLoading: isLoadingBalanceHistory,
		data: dataBalanceHistory,
		fetchList: fetchListBalanceHistory,
		setQuery: setQueryBalanceHistory,
	} = useFetchList<BalanceHistoryProperties>({
		endpoint: `balances/${user?.userId}/history`,
	});

	const {
		isLoading: isLoadingPointHistory,
		data: dataPointHistory,
		fetchList: fetchListPointHistory,
		setQuery: setQueryPointHistory,
	} = useFetchList<PointHistoryProperties>({
		endpoint: `points/${user?.userId}/history`,
	});

	React.useEffect(() => {
		activeTab === '0'
			? setQueryBalanceHistory(filterList)
			: setQueryPointHistory(filterList);
	}, [filterList]);

	const getCurrentBalance = async () => {
		try {
			const res = await httpRequest.get<ApiResponseDefault<BalanceProperties>>(
				`balances/${user?.userId}`,
			);
			if (res && res.data && res.data.payload) {
				setCurrentBalance(res.data.payload.currentAmount);
			}
		} catch (err) {
			console.error(err);
		}
	};

	const getCurrentPoint = async () => {
		try {
			const res = await httpRequest.get<ApiResponseDefault<PointProperties>>(
				`points/${user?.userId}`,
			);
			if (res && res.data && res.data.payload) {
				setCurrentPoint(res.data.payload.currentAmount);
				setPendingPoint(res.data.payload.pendingAmount);
			}
		} catch (err) {
			console.error(err);
		}
	};

	React.useEffect(() => {
		getCurrentBalance();
		getCurrentPoint();
	}, []);

	React.useEffect(() => {
		setFilterList({});
		setSearch('');
		setQueryBalanceHistory({});
		setQueryPointHistory({});
	}, [activeTab]);

	// Helper Functions
	function parseCategoryToTitle(category: string) {
		return category
			.toLowerCase()
			.split('_')
			.map((cat) => cat[0].toUpperCase() + cat.slice(1))
			.join(' ');
	}

	// Components
	const HeaderComponent = () => {
		return (
			<>
				<HeaderContainer>
					{/* <CircleBackground /> */}
					<CircleBackground2 />
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
						}}
					>
						<div style={{ display: 'flex', paddingTop: 6 }}>
							<Text style={{ fontSize: 16, color: '#fff', marginRight: 6 }}>
								{activeTab === '0' ? 'Professional Fee' : 'Medeasy Points'}
							</Text>
							{activeTab === '1' && (
								<Button
									style={{
										backgroundColor: 'transparent',
										padding: 0,
										paddingBottom: 2,
									}}
									onClick={() => history.push('/app/balance/point-information')}
								>
									<IconInformationCircleSmall />
								</Button>
							)}
						</div>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<div style={{ paddingTop: 4 }}>
								<IconStar />
							</div>
							<Text
								style={{
									fontSize: 24,
									fontWeight: 'bold',
									color: '#fff',
									marginLeft: 6,
								}}
							>
								{activeTab === '0' ? currentBalance : currentPoint?.toFixed(2)}
							</Text>
							{activeTab === '1' && pendingPoint > 0 && (
								<Text
									style={{
										fontSize: 14,
										fontWeight: 'bold',
										color: 'rgba(255, 255, 255, 0.80)',
										marginLeft: 6,
									}}
								>
									+ {pendingPoint} (Pending)
								</Text>
							)}
						</div>
					</div>
					<div>
						<Button
							disabled={doctorKYCStatus !== EKycStatus.APPROVED}
							onClick={() =>
								activeTab === '0'
									? history.push('/app/balance/withdraw')
									: setIsShowModalRedeem(true)
							}
							style={{ height: 40, paddingTop: 6, borderRadius: 12 }}
						>
							<Text
								style={{ fontSize: 14, color: '#d81f64', fontWeight: 'bold' }}
							>
								{activeTab === '0' ? 'Withdraw' : 'Redeem'}
							</Text>
						</Button>
					</div>
				</HeaderContainer>
			</>
		);
	};

	const SearchComponent = (
		<Row
			style={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				marginBottom: 12,
			}}
		>
			<Input
				size="middle"
				placeholder="Search"
				prefix={<SearchOutlined style={{ color: '#93A1B0' }} />}
				allowClear
				onChange={(e) => setSearch(e.target.value)}
				style={{ flex: 1, borderRadius: 15, padding: '8px 14px' }}
			/>
			<Button onClick={() => setIsShowModalFilter(true)}>
				<MenuOutlined style={{ color: '#929292', cursor: 'pointer' }} />
			</Button>
		</Row>
	);

	const statusColor = (status: string) => {
		let bgColor = '';
		let color = '';

		if (status === 'PENDING' || status === 'WAITING_APPROVAL') {
			bgColor = '#FCF1E3';
			color = '#DA7B11';
		} else if (status === 'EXPIRED' || status === 'REJECTED') {
			bgColor = '#FEF5F9';
			color = '#D81F64';
		} else if (status === 'AVAILABLE' || status === 'APPROVED') {
			bgColor = '#D7F4EB';
			color = '#268E6C';
		}

		return { bgColor: bgColor, color: color };
	};

	// Component Group - Telemedicine
	const balanceNPointItem = (item: any) => {
		const title = item.category
			? parseCategoryToTitle(item.category)
			: 'Unknown';
		const isNegative = Number(item.amount) < 0;

		return (
			<>
				<Button
					onClick={() => {
						setTmpData(item);
						setIsShowModalDetail(true);
					}}
					style={{
						display: 'flex',
						flex: 1,
						justifyContent: 'space-between',
						alignItems: 'center',
						padding: '16px 0',
						borderBottom: '1px solid #F1F3F5',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-start',
						}}
					>
						<Text style={{ fontSize: 16, fontWeight: 400 }}>{title}</Text>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<Text style={{ fontSize: 14, fontWeight: 400, color: '#929292' }}>
								{' '}
								{item?.createdAt &&
									moment(item?.createdAt).format('MMM DD, YYYY')}
							</Text>
							<Ellips />
							<Text style={{ fontSize: 14, fontWeight: 400, color: '#929292' }}>
								{item?.createdAt && moment(item?.createdAt).format('H:mm:ss')}
							</Text>
						</div>
					</div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}
					>
						{item.status &&
							(item.status === 'PENDING' || item.status === 'EXPIRED') && (
								<Tag
									style={{
										color: statusColor(item.status).color,
										backgroundColor: statusColor(item.status).bgColor,
										border: `1px solid ${statusColor(item.status).color}`,
										marginRight: 0,
									}}
								>
									{item.status}
								</Tag>
							)}
						<Text
							style={{
								fontSize: 14,
								fontWeight: 400,
								color: isNegative ? '#D82148' : '#00C897',
								marginTop: 4,
							}}
						>
							{!isNegative && '+'}
							{activeTab === '0' && '₱'}
							{item.amount}
							{activeTab === '1' && ' Points'}
						</Text>
					</div>
				</Button>
			</>
		);
	};

	// Tab Item Children
	const professionalFee = () => {
		return (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<HeaderComponent />
				{SearchComponent}
				{isLoadingBalanceHistory ? (
					<Spin spinning={isLoadingBalanceHistory} style={{ marginTop: 40 }} />
				) : (
					dataBalanceHistory
						.filter(
							(item) =>
								item.category &&
								parseCategoryToTitle(item.category)
									.toLowerCase()
									.includes(search.toLowerCase()),
						)
						.map((item) => balanceNPointItem(item))
				)}
				{!isLoadingBalanceHistory &&
					dataBalanceHistory
						.filter(
							(item) =>
								item.category &&
								parseCategoryToTitle(item.category)
									.toLowerCase()
									.includes(search.toLowerCase()),
						)
						.map((item) => balanceNPointItem(item))?.length === 0 && (
						<EmptyList icon={<IconEmpty />} title="No Professional Fee Yet" />
					)}
			</div>
		);
	};

	const points = () => {
		return (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<HeaderComponent />
				{SearchComponent}
				{isLoadingPointHistory ? (
					<Spin spinning={isLoadingPointHistory} style={{ marginTop: 40 }} />
				) : (
					dataPointHistory
						.filter(
							(item) =>
								item.category &&
								parseCategoryToTitle(item.category)
									.toLowerCase()
									.includes(search.toLowerCase()),
						)
						.map((item) => balanceNPointItem(item))
				)}
				{!isLoadingBalanceHistory &&
					dataPointHistory
						.filter(
							(item) =>
								item.category &&
								parseCategoryToTitle(item.category)
									.toLowerCase()
									.includes(search.toLowerCase()),
						)
						.map((item) => balanceNPointItem(item))?.length === 0 && (
						<EmptyList icon={<IconEmpty />} title="No Point" />
					)}
			</div>
		);
	};

	// Tab Items
	const tabItems = [
		{
			label: 'Professional Fee',
			key: '0',
			children: professionalFee(),
		},
		{
			label: 'Points',
			key: '1',
			children: points(),
		},
	];

	return (
		<AppLayout activePath={activePath}>
			<Card>
				<Title level={5}>Balance</Title>
				<Divider />
				<Tabs
					defaultActiveKey={activeTab}
					centered
					items={tabItems}
					onChange={(e) => {
						console.log(e);
						setActiveTab(e);
					}}
				/>
				<ModalTransactionFilter
					visible={isShowModalFilter}
					setVisible={setIsShowModalFilter}
					filterList={filterList}
					setFilterList={setFilterList}
					activeTab={activeTab}
				/>
				<ModalDetail
					visible={isShowModalDetail}
					setVisible={setIsShowModalDetail}
					data={tmpData}
					activeTab={activeTab}
				/>
				<ModalRedeem
					visible={isShowModalRedeem}
					setVisible={setIsShowModalRedeem}
					currentAmount={currentPoint}
					fetchListCurrentPoint={getCurrentPoint}
					fetchListPointHistory={fetchListPointHistory}
				/>
			</Card>
		</AppLayout>
	);
}

const HeaderContainer = styled.div`
	width: 100%;
	height: 84px;
	border-radius: 12px;
	margin-bottom: 12px;
	display: flex;
	padding: 0 24px;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	background-color: ${({ theme }) => theme.colors.primary};
`;

const CircleBackground = styled.div`
	position: absolute;
	background-color: rgba(255, 255, 255, 0.15);
	top: -70px;
	right: 300px;
	width: 124px;
	height: 124px;
	border-radius: 68px;
	z-index: 1;
`;

const CircleBackground2 = styled.div`
	position: absolute;
	background-color: rgba(255, 255, 255, 0.15);
	top: 50px;
	left: -15px;
	width: 72px;
	height: 72px;
	border-radius: 36px;
`;

export const Ellips = styled.div`
	width: 5px;
	height: 5px;
	border-radius: 2.5px;
	background-color: #c4c4c4;
	margin: 0 4px;
`;
