import {
	Button,
	Card,
	Col,
	Divider,
	Input,
	message,
	Radio,
	RadioChangeEvent,
	Row,
	Space,
	Switch,
	Typography,
} from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import COLORS from '../../../assets/globalColors';
import PageHeader from '../../../components/PageHeader';
import ModalCancelConsultation from '../../../components/Webapp/Reschedule/ModalCancelConsultation';
import ModalOfferReschedule from '../../../components/Webapp/Reschedule/ModalOfferReschedule';
import AppLayout from '../../../layout/AppLayout';

import moment from 'moment';
import {
	apiCancelConsultation,
	apiRescheduledConsultation,
} from '../../../api/transactionConsultation';
import ComponentDateTime from '../../../components/Webapp/Reschedule/ComponentDateTime';
import { httpRequest } from '../../../helpers/api';
import { createTimeArray } from '../../../helpers/datetime';
import { generateQueryString } from '../../../helpers/generateQueryString';
import useBookConsultation from '../../../hooks/useBookConsultation';
import { ApiResponseDefault } from '../../../types/apiResponse.type';
import { DoctorPartnerScheduleProperties } from '../../../types/doctorPartnerSchedule';
import './styles.less';
// import useAuth from '../../../hooks/useAuth';
import { useAuth } from '../../../context/auth.context';

const activePath = '/app/schedule';

const { Text } = Typography;
const { TextArea } = Input;

export default function Reschedule() {
	const location: {
		state: {
			partnerId: string;
			partnerType: string;
			transactionConsultationId: string;
		};
	} = useLocation();

	const history = useHistory();
	const { userId, user } = useAuth();
	const transactionConsultationId = location?.state?.transactionConsultationId;
	const partnerId = location?.state?.partnerId;
	const partnerType = location?.state?.partnerType;

	const [selectedDate, setSelectedDate] = useState<any>('');
	const [selectedTime, setSelectedTime] = useState<any>('');
	const [selectedReason, setSelectedReason] = useState<any>();
	const [reasonInput, setReasonInput] = useState('');
	const [isModalCancelConsultVisible, setIsModalCancelConsultVisible] =
		useState(false);
	const [isOfferReschedule, setIsOfferReschedule] = useState(false);
	const [isModalOfferRescheduletVisible, setIsModalOfferRescheduletVisible] =
		useState(false);
	const [isLoadingTimes, setIsLoadingTimes] = useState(false);
	const [disabledTextarea, setDisabledTextare] = useState(true);
	const {
		currentPartnerDoctors,
		unavailableWeek,
		setCurrentPartnerDoctors,
		setUnavailableWeek,
	} = useBookConsultation();

	const days: any = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
	const months = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	];

	const onChange = (e: RadioChangeEvent) => {
		setSelectedReason(e.target.value);
	};

	useEffect(() => {
		const fetchSchedule = async () => {
			try {
				const resPartners = await httpRequest.get<ApiResponseDefault<any>>(
					'/partner-doctors' +
						generateQueryString({
							doctorId: userId,
						}),
				);

				if (resPartners.data && resPartners.data.payload) {
					setCurrentPartnerDoctors(resPartners.data.payload.results);
				}
			} catch (error) {
				console.error('failed fetchSchedule', error);
			}
		};

		fetchSchedule();
	}, []);

	const handleUnavailableWeek = (schedules: any[]) => {
		let weeks: number[] | undefined = [];
		weeks = schedules
			.map((item) => item.weekday)
			.filter((v, i, a) => a.indexOf(v) === i);
		setUnavailableWeek(
			[...Array.from(Array(7).keys())]
				.filter((item) => weeks && !weeks.includes(item))
				.map((item) => days[item]),
		);
	};

	const currentData = useMemo(() => {
		return currentPartnerDoctors.find(
			(item) => item.partner?.partnerType === partnerType,
		);
	}, [currentPartnerDoctors, partnerType]);

	useEffect(() => {
		if (currentData) {
			handleUnavailableWeek(currentData.schedules!);
		}
	}, [currentData]);
	console.log(currentPartnerDoctors);
	console.log(unavailableWeek);

	const [times, setTimes] = useState<
		{
			weekday: number;
			times: {
				times: string;
				timeGroup: string;
			}[];
		}[]
	>([]);

	let dates = [];
	for (let i = 0; i < 30; i++) {
		let date = new Date();
		date.setDate(date.getDate() + i);

		dates.push({
			year: date.getFullYear(),
			numberMonth: date.getMonth() + 1,
			day: days[date.getDay()],
			date: date.getDate(),
			month: months[date.getMonth()],
			weekday: days.indexOf(days[date.getDay()]),
		});
	}

	const generateTimeInterval = (
		schedules: DoctorPartnerScheduleProperties[],
	) => {
		let newIntervals: {
			weekday: number;
			times: {
				times: string;
				timeGroup: string;
			}[];
		}[] = [];

		for (const iterator of schedules) {
			const startAt = moment(iterator.startAt).format('HH');
			const endAt = moment(iterator.endAt).format('HH');
			console.log(startAt, endAt);

			const times = createTimeArray(30, Number(startAt), Number(endAt));
			newIntervals.push({
				weekday: iterator.weekday,
				times,
			});
		}
		setTimes(newIntervals);
	};
	const handleHour = async (e: any) => {
		if (!userId || !partnerId) return;
		setTimes([]);
		const query = {
			doctorId: userId,
			partnerId: partnerId,
			weekday: e.target.value,
		};

		setIsLoadingTimes(true);
		const res = await httpRequest.get<ApiResponseDefault<any>>(
			'/doctor-partner-schedules' + generateQueryString(query),
		);
		setIsLoadingTimes(false);
		const schedules = res.data.payload.results;
		// console.log(schedules);
		if (schedules && schedules.length > 0) {
			generateTimeInterval(schedules);
		}
	};

	async function cancelConsulHandler() {
		if (!selectedReason) {
			message.error('Select a reason!');
			return;
		}

		let reasonTemp: string;
		if (selectedReason === 4) {
			reasonTemp = reasonInput;
		} else {
			reasonTemp = selectedReason;
		}
		const data = {
			lastUpdatedByUser: user || {},
			reason: reasonTemp,
		};

		const res = await apiCancelConsultation(transactionConsultationId, data);
		if (res.code !== 'success') {
			message.error('Failed to cancel event');
			return;
		}
		message.success('Success cancel event');
		setSelectedReason('');
		setReasonInput('');
		history.push('/app/approval');
	}

	async function offerRescheduleHandler() {
		if (!selectedReason) {
			message.error('Select a reason!');
			return;
		}
		if (!selectedDate || !selectedTime) {
			message.error('Select date and time!');
			return;
		}

		let reasonTemp;
		if (selectedReason === 4) {
			reasonTemp = reasonInput;
		} else {
			reasonTemp = selectedReason;
		}

		const arr = selectedTime?.split(':');
		let endAt: string = '';

		if (arr && arr.length > 0) {
			if (arr[1] === '00') {
				endAt = ('0' + Number(arr[0])).slice(-2) + ':30';
			} else if (arr[1] === '30') {
				const newHour = parseInt(arr[0]) + 1;
				endAt = ('0' + newHour).slice(-2) + ':00';
			}
		}

		const scheduledStartAt = moment(
			selectedDate + ' ' + selectedTime,
			'YYYY-MM-DD HH:mm',
		).toDate();

		const scheduledEndAt = moment(
			selectedDate + ' ' + endAt,
			'YYYY-MM-DD HH:mm',
		).toDate();

		const dto = {
			lastUpdatedByUser: user || {},
			reason: reasonTemp,
			newScheduledStartAt: scheduledStartAt,
			newScheduledEndAt: scheduledEndAt,
		};

		const res = await apiRescheduledConsultation(transactionConsultationId, {
			...dto,
		});
		if (res.code !== 'success') {
			message.error('Failed to reschedule');
			return;
		}

		message.success('Success reschedule event');
		setSelectedReason('');
		setReasonInput('');
		history.goBack();
	}

	return (
		<AppLayout activePath={activePath}>
			<Card>
				<PageHeader
					title="Cancel Consultation"
					onClick={() => history.goBack()}
				/>
				<Divider />

				<Row style={{ marginBottom: 12 }}>
					<Text style={{ fontSize: 16, fontWeight: 500 }}>Input Reason</Text>
				</Row>
				<Row>
					<Col span={24}>
						<Radio.Group onChange={onChange} value={selectedReason}>
							<Space direction="vertical" style={{ width: '100%' }}>
								<Radio value={'I have medical emergency'}>
									I have medical emergency
								</Radio>
								<Radio value={'I am sick'}>I am sick</Radio>
								<Radio value={'I have a personal event to attend'}>
									I have a personal event to attend
								</Radio>
								<Radio
									value={4}
									onChange={(e) => setDisabledTextare(!e.target.checked)}
								>
									<TextArea
										disabled={disabledTextarea}
										cols={55}
										style={{ borderRadius: 16, verticalAlign: 'top' }}
										rows={4}
										placeholder="Input your reason"
										value={reasonInput}
										onChange={(e) => setReasonInput(e.target.value)}
									/>
								</Radio>
							</Space>
						</Radio.Group>
					</Col>
				</Row>
				<Divider />
				<Row gutter={6} align="middle">
					<Col>
						<Switch
							onChange={(checked: boolean) => {
								setIsOfferReschedule(checked);
								setSelectedDate('');
								setSelectedTime('');
								setTimes([]);
							}}
						/>
					</Col>
					<Col>
						<Text style={{ fontSize: 16, fontWeight: 500 }}>
							Offer Reschedule
						</Text>
					</Col>
				</Row>
				<Row
					style={{
						background: COLORS.ash_200,
						padding: 8,
						border: `1px solid ${COLORS.ash_400}`,
						borderRadius: 8,
						marginTop: 12,
					}}
				>
					<Text style={{ fontSize: 14, fontWeight: 400 }}>
						Offer your available time to change the consultation schedule to the
						Patient.
					</Text>
				</Row>
				<Divider />
				{isOfferReschedule && (
					<ComponentDateTime
						dates={dates}
						handleHour={handleHour}
						isLoadingTimes={isLoadingTimes}
						setSelectedDate={setSelectedDate}
						setSelectedTime={setSelectedTime}
						times={times}
						unAvailableWeek={unavailableWeek}
					/>
				)}

				<Row justify="end" gutter={10}>
					<Col>
						<Button
							type="primary"
							style={{
								minWidth: 180,
								height: 40,
								borderRadius: 12,
							}}
							onClick={() =>
								isOfferReschedule
									? setIsModalOfferRescheduletVisible(true)
									: setIsModalCancelConsultVisible(true)
							}
						>
							<Text style={{ fontWeight: 700, fontSize: 14, color: '#fff' }}>
								Cancel{' '}
								{isOfferReschedule ? 'and Offer Reschedule' : 'Consultation'}
							</Text>
						</Button>
					</Col>
				</Row>
			</Card>
			<ModalCancelConsultation
				isVisible={isModalCancelConsultVisible}
				setVisibility={setIsModalCancelConsultVisible}
				actionHandler={cancelConsulHandler}
			/>
			<ModalOfferReschedule
				isVisible={isModalOfferRescheduletVisible}
				setVisibility={setIsModalOfferRescheduletVisible}
				actionHandler={offerRescheduleHandler}
			/>
		</AppLayout>
	);
}
