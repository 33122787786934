import { useHistory } from 'react-router-dom';
import AppLayout from '../../../layout/AppLayout';
import CalendarAgenda from '../../../components/CalendarAgenda';
import './styles.less';
import useSchedulesNew from '../../../hooks/useSchedulesNew';
import { useAuth } from '../../../context/auth.context';
import { EKycStatus } from '../../../types/doctor.type';
import { Button, Dropdown, MenuProps, message } from 'antd';
import { Calendar, User, RefreshCw, Info } from 'react-feather';
import axios from 'axios';
import moment from 'moment';
import useSyncCalendar from '../../../hooks/useSyncCalendar';
import { IGooleCalendarSchedule } from '../../../types/schedule.type';
import { useEffect } from 'react';

export default function ScheduleScreen() {
  const history = useHistory();
  const activePath = '/app/schedule';
  const disabledDates = ['2022-09-06', '2022-09-07'];

  const { addSyncCalendar } = useSyncCalendar();
  const { user, doctorKYCStatus } = useAuth();
  const { schedules: semiProcessedSchedules } = useSchedulesNew({
    userId: user?.userId ?? '',
  });

  const goToaddEventScreen = () => history.push('/app/schedule/new');

  const items: MenuProps['items'] = [
    {
      icon: <Calendar width={20} />,
      label: 'Clinic and Practice Settings',
      key: '0',
      onClick: () => history.push('setting/clinics-and-practice'),
    },
    {
      icon: <User width={20} />,
      label: 'Personal Event',
      key: '1',
      onClick: () => history.push('schedule/personal-event'),
    },
    {
      icon: <RefreshCw width={20} />,
      label: 'Sync Calendar',
      key: '2',
      onClick: () => history.push('schedule/sync-calender'),
    },
    {
      icon: <Info width={20} />,
      label: 'Information',
      key: '3',
      onClick: () => history.push('schedule/schedule-icon-information'),
    },
  ];

  // useEffect(() => {
  //   const syncCalendarAutomatically = async () => {
  //     const accessToken = localStorage.getItem('googleAccessToken');
  //     const googleAccount = localStorage.getItem('googleAccount');

  //     if (accessToken && googleAccount) {
  //       try {
  //         const startDate = moment()
  //           .subtract(1, 'month')
  //           .startOf('month')
  //           .toISOString();
  //         const endDate = moment().add(2, 'month').endOf('month').toISOString();

  //         const events = await fetchCalendarEvents(
  //           accessToken,
  //           startDate,
  //           endDate,
  //         );

	// 				console.log('events:', events?.items);

  //         if (events?.items && events.items.length > 0) {
	// 					const dataImported = events.items.map((event) => {
	// 						const eventStartAt = event.start ? (event.start.dateTime || event.start.date) : null;
	// 						const eventEndAt = event.end ? (event.end.dateTime || event.end.date) : null;

	// 						return {
	// 							localEventId: event.id,
	// 							title: event.summary || 'Untitled Event',
	// 							description: event.description || '',
	// 							eventStartAt,
	// 							eventEndAt,
	// 							isBlocking: false,
	// 							createdByUserId: user?.userId,
	// 							metaCreatedByUser: user,
	// 							ownerId: user?.userId,
	// 							metaOwner: user,
	// 							isPrivate: true,
	// 						};
	// 					});

	// 					for (const event of dataImported) {
	// 						await handleAddEvent({
	// 							title: event.title,
	// 							description: event.description,
	// 							startDate: event.eventStartAt,
	// 							endDate: event.eventEndAt,
	// 						});
	// 					}

	// 					const resAddSync = await addSyncCalendar({
	// 						personalEventList: dataImported,
	// 						startDate,
	// 						endDate,
	// 					});
	// 					console.log('Auto-sync success:', resAddSync);
  //         }
  //       } catch (error) {
  //         console.error('Auto-sync error:', error);
  //       }
  //     }
  //   };

  //   syncCalendarAutomatically();
  // }, []);

  const fetchCalendarEvents = async (
    token: string,
    startDate: string,
    endDate: string,
  ): Promise<IGooleCalendarSchedule | null | undefined> => {
    const calendarId = 'primary';
    const url = `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events?maxResults=1000&timeMax=${endDate}&timeMin=${startDate}`;

    try {
      const resp = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return resp.data as IGooleCalendarSchedule;
    } catch (error) {
      console.error('Error fetching calendar events', error);
      return null;
    }
  };

  const addEventToGoogleCalendar = async (event: any) => {
    const accessToken = localStorage.getItem('googleAccessToken');
    if (!accessToken) {
      console.error('Google access token not found');
      return;
    }

    const googleEvent = {
      summary: event.title,
      description: event.description,
      start: {
        dateTime: moment(event.startDate).toISOString(),
      },
      end: {
        dateTime: moment(event.endDate).toISOString(),
      },
    };

    try {
      const resp = await axios.post(
        `https://www.googleapis.com/calendar/v3/calendars/primary/events`,
        googleEvent,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      console.log('Event added to Google Calendar:', resp.data);
    } catch (error) {
      console.error('Error adding event to Google Calendar', error);
    }
  };

  // const handleAddEvent = async (event: any) => {
  //   try {
  //     await addEventToGoogleCalendar(event);
  //     console.log('Event added to Google Calendar');
  //   } catch (error) {
  //     console.error('Error adding event:', error);
  //   }
  // };

  return (
    <AppLayout activePath={activePath}>
      <div className="schedule-screen">
        <div className="header-schedule">
          <h4>Schedule</h4>
          <Button
            type="ghost"
            onClick={goToaddEventScreen}
            disabled={doctorKYCStatus !== EKycStatus.APPROVED}
          >
            Add Schedule
          </Button>
          <Dropdown
            menu={{ items }}
            trigger={['click']}
            placement="bottomRight"
            arrow={{ pointAtCenter: true }}
          >
            <div className="dots-three">
              <span />
            </div>
          </Dropdown>
        </div>
        <CalendarAgenda
          disabledDates={disabledDates}
          eventItems={semiProcessedSchedules}
        />
      </div>
    </AppLayout>
  );
}