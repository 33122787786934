import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { ChevronRight } from 'react-feather';
import styled from 'styled-components';
import { theme } from '../../../assets/theme';
import { httpRequest } from '../../../helpers/api';
import { getErrorMessage } from '../../../helpers/errorHandler';
import { getName } from '../../../helpers/name';
// import useAuth from "../../../hooks/useAuth";
import { useAuth } from '../../../context/auth.context';
import { ApiResponsePagination } from '../../../types/apiResponse.type';
import {
	DoctorDocumentProperties,
	EMetaType,
} from '../../../types/doctorDocument.type';
import { LabRequestProperties } from '../../../types/labRequest.type';
import { RxPrescriptionProperties } from '../../../types/rxPrescription.type';
import Card from '../../Card';
import { ReactComponent as Spinner } from '../../../assets/icons/spinner.svg';
import { EPaymentStatus, PaymentProperties } from '../../../types/payment.type';
import { message } from 'antd';
import ModalNewDocument from './ModalNewDocument';
import { useHistory } from 'react-router-dom';
import { EKycStatus } from '../../../types/doctor.type';

const documentTabs = [
	{
		key: 'prescription',
		title: 'Prescription',
	},
	{
		key: 'lab-request',
		title: 'Lab Request',
	},
	{
		key: 'doctor-document',
		title: 'Doctor Document',
	},
];

export default function CardRecentCreatedDocuments() {
	const { user, doctorKYCStatus } = useAuth();
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(true);
	const [isShowNewDocumentModal, setIsShowNewDocumentModal] =
		useState<boolean>(false);
	const [documentTab, setDocumentTab] = useState(documentTabs[0]);
	function handleChangeDocTab(index: number) {
		setDocumentTab(documentTabs[index]);
	}
	const [prescriptionDocs, setPrescriptionDocs] = useState<
		RxPrescriptionProperties[]
	>([]);
	const [labRequestDocs, setLabRequestDocs] = useState<LabRequestProperties[]>(
		[],
	);
	const [doctorDocDocs, setDoctorDocDocs] = useState<
		DoctorDocumentProperties[]
	>([]);

	useEffect(() => {
		if (!user?.userId) return;

		(async () => {
			await Promise.all([
				(async () => {
					try {
						const res = await httpRequest.get<
							ApiResponsePagination<RxPrescriptionProperties>
						>(
							`rx-prescriptions?doctorId=${user.userId}&isTemporary=false&limit=5`,
						);

						setPrescriptionDocs(res.data.payload.results);
					} catch (err) {
						const error = getErrorMessage(err);
						console.error(error);
						message.error(error);
					}
				})(),
				(async () => {
					try {
						const res = await httpRequest.get<
							ApiResponsePagination<LabRequestProperties>
						>(`lab-requests?doctorId=${user.userId}&isTemporary=false&limit=5`);

						setLabRequestDocs(res.data.payload.results);
					} catch (err) {
						const error = getErrorMessage(err);
						console.error(error);
						message.error(error);
					}
				})(),
				(async () => {
					try {
						const res = await httpRequest.get<
							ApiResponsePagination<DoctorDocumentProperties>
						>(
							`doctor-documents?doctorId=${user.userId}&isTemporary=false&limit=5`,
						);

						setDoctorDocDocs(res.data.payload.results);
					} catch (err) {
						const error = getErrorMessage(err);
						console.error(error);
						message.error(error);
					}
				})(),
			]);

			setIsLoading(false);
		})();
	}, [user?.userId]);

	const docs = useMemo(() => {
		switch (documentTab.key) {
			case 'prescription':
				return prescriptionDocs;
			case 'lab-request':
				return labRequestDocs;
			case 'doctor-document':
				return doctorDocDocs;
		}
	}, [documentTab, prescriptionDocs, labRequestDocs, doctorDocDocs]);

	return (
		<Card className="p-4">
			<div className="flex justify-between gap-x-16 items-center">
				<p className="m-0 font-semibold text-4.5 text-gray-100">
					Recent Created Document
				</p>
				<PrimaryButton
					disabled={doctorKYCStatus !== EKycStatus.APPROVED}
					style={
						doctorKYCStatus !== EKycStatus.APPROVED
							? { background: 'gray' }
							: undefined
					}
					onClick={() => setIsShowNewDocumentModal(true)}
				>
					Create New
				</PrimaryButton>
			</div>
			<div className="mt-6">
				<DocTabContainer>
					{documentTabs.map((docTab, index) => (
						<Tab
							key={docTab.key}
							onClick={() => handleChangeDocTab(index)}
							style={
								documentTab === docTab
									? {
											borderBottomStyle: 'solid',
											borderBottomWidth: 4,
											borderBottomColor: theme.colors.primary,
									  }
									: undefined
							}
						>
							{docTab.title}
						</Tab>
					))}
				</DocTabContainer>
				<div className="mt-3">
					{!isLoading ? (
						docs?.map((doc, index) => (
							<DocumentContainer
								key={index}
								onClick={() => {
									switch (documentTab.key) {
										case 'prescription':
											history.push(
												`/app/rx-and-lab-request/rx-history/${
													(doc as RxPrescriptionProperties).rxPrescriptionId
												}`,
											);
											return '';
										case 'lab-request':
											history.push(
												`/app/rx-and-lab-request/lab-request-history/${
													(doc as LabRequestProperties).labRequestId
												}`,
											);
											return `/app/rx-and-lab-request/lab-request-history/`;
										case 'doctor-document':
											history.push(
												`/app/doctor-documents/history/${
													(doc as DoctorDocumentProperties).doctorDocumentId
												}`,
											);
											return '/app/doctor-documents/history';
									}
								}}
								style={{ cursor: 'pointer' }}
							>
								<DocumentTextContainer>
									<div className="flex gap-x-3 items-center">
										<p className="m-0 line-clamp-1">
											{getName(doc.metaPatient)}
										</p>
										{true && (
											// TODO cek ini
											<div className="text-3 text-primary px-1.5 border border-solid border-primary rounded-2">
												<p className="m-0">In-App</p>
											</div>
										)}
									</div>
									<div className="flex mt-3">
										<div className="flex-2">
											<p className="m-0 text-black-60">Prescribed on</p>
											<p className="m-0 text-4 text-black-100">
												{moment(doc.createdAt).format('MMMM DD, YYYY')}
											</p>
										</div>
										<div className="flex-2">
											<p className="m-0 text-black-60">Valid until</p>
											<p className="m-0 text-4 text-black-100">
												{moment(doc.validUntil).format('MMMM DD, YYYY')}
											</p>
										</div>
										{documentTab.key === 'doctor-document' && (
											<>
												<div className="flex-1">
													<p className="m-0 text-black-60">Price</p>
													<p className="m-0 text-4 text-black-100">
														{(doc as DoctorDocumentProperties).price}
													</p>
												</div>
												<div className="flex-2">
													<p className="m-0 text-black-60">Status</p>
													<div className="w-full text-4">
														{StatusTag(
															(doc as DoctorDocumentProperties)?.price,
															(doc as DoctorDocumentProperties).payment,
														)}
													</div>
												</div>
												<div className="flex-2">
													<p className="m-0 text-black-60">Type</p>
													<div className="w-full text-4">
														{TypeTag((doc as DoctorDocumentProperties)?.type)}
													</div>
												</div>
											</>
										)}
									</div>
								</DocumentTextContainer>
								<ChevronRight
									width={20}
									height={20}
									color={theme.colors.black30}
								/>
							</DocumentContainer>
						))
					) : (
						<div className="w-fit mx-auto">
							<Spinner
								width={27.5}
								height={27.5}
								color={theme.colors.primary}
							/>
						</div>
					)}
				</div>
				<div className="mt-3 p-3">
					<SeeMoreDocButton>See More</SeeMoreDocButton>
				</div>
			</div>
			<ModalNewDocument
				isVisible={isShowNewDocumentModal}
				setVisibility={setIsShowNewDocumentModal}
			/>
		</Card>
	);
}

function StatusTag(price?: number, paymentProperties?: PaymentProperties) {
	let color = {
		border: '',
		background: '',
		text: '',
	};

	let status = '';
	if (!price || paymentProperties?.totalNeedToPay === 0) {
		status = 'Free';
	} else {
		switch (paymentProperties?.paymentStatus) {
			case EPaymentStatus.PAID_FULL:
				status = 'Cleared';
				break;
			case EPaymentStatus.CANCELLED_BY_SYSTEM:
			case EPaymentStatus.CANCELLED_BY_USER:
				status = 'Canceled';
				break;
			default:
				status = 'Pending';
		}
	}

	switch (status) {
		case 'Free':
			color = {
				border: theme.colors.gray80,
				background: theme.colors.gray10,
				text: theme.colors.gray100,
			};
			break;
		case 'Pending':
			color = {
				border: theme.colors.yellow,
				background: theme.colors.yellow10,
				text: theme.colors.yellow,
			};
			break;
		case 'Cleared':
			color = {
				border: theme.colors.green,
				background: theme.colors.green10,
				text: theme.colors.green,
			};
			break;
		case 'Canceled':
			color = {
				border: theme.colors.primary,
				background: theme.colors.primary10,
				text: theme.colors.primary,
			};
	}
	return (
		<div
			className="w-20 border border-solid rounded-1.5 text-center"
			style={{
				borderColor: color.border,
				backgroundColor: color.background,
				color: color.text,
			}}
		>
			{status}
		</div>
	);
}

function TypeTag(type?: EMetaType) {
	let color = {
		background: '',
		text: '',
	};

	switch (type) {
		case EMetaType.CLEARANCE:
			color = {
				background: theme.colors.green10,
				text: theme.colors.green,
			};
			break;
		case EMetaType.FIT_TO_WORK:
			color = {
				background: theme.colors.purple10,
				text: theme.colors.purple,
			};
			break;
		case EMetaType.MEDICAL_CERTIFICATE:
			color = {
				background: theme.colors.blue10,
				text: theme.colors.blue,
			};
			break;
		case EMetaType.OTHERS:
			color = {
				background: theme.colors.gray10,
				text: theme.colors.gray100,
			};
			break;
	}

	return (
		<div
			className="w-40 rounded-full text-center"
			style={{
				backgroundColor: color.background,
				color: color.text,
			}}
		>
			{type
				?.split('_')
				.map((t) => t[0].toUpperCase() + t.slice(1).toLocaleLowerCase())
				.join(' ')}
		</div>
	);
}

const PrimaryButton = styled.button`
	background: ${({ theme }) => theme.colors.primary};
	border: none;
	border-radius: 12px;
	padding: 12px;
	font-weight: 700;
	font-size: 14px;
	color: ${({ theme }) => theme.colors.white};
	cursor: pointer;
`;

const DocTabContainer = styled.div`
	display: flex;
	width: 100%;
`;

const Tab = styled.button`
	flex: 1;
	background: none;
	border: none;
	padding: 15px;
	font-size: 16px;
	font-weight: 500;
	cursor: pointer;
	border-bottom: 4px solid ${({ theme }) => theme.colors.white};
`;

const DocumentContainer = styled.div`
	margin-top: 16px;
	padding-bottom: 16px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	display: flex;
	align-items: center;
`;

const DocumentTextContainer = styled.div`
	flex: 1;
`;

const SeeMoreDocButton = styled.button`
	display: block;
	margin-left: auto;
	border-style: none;
	border-width: 0;
	padding: 12.5px 68.5px;
	background-color: ${({ theme }) => theme.colors.ash300};
	border-radius: 12px;
	font-size: 14px;
	font-weight: 700;
	cursor: pointer;
`;
