import { useEffect, useMemo, useState } from 'react';
import AppLayout from '../../../layout/AppLayout';
import {
	connect,
	createLocalAudioTrack,
	createLocalVideoTrack,
	LocalAudioTrack,
	LocalVideoTrack,
	Room,
} from 'twilio-video';
import { useHistory, useParams } from 'react-router-dom';
import {
	EConsultationType,
	ETransactionStatusType,
	TransactionConsultationProperties,
} from '../../../types/transactionConsultation.type';
import { getErrorMessage } from '../../../helpers/errorHandler';
import { TwilioRoomProperties } from '../../../types/twilioRoom.type';
import WaitingRoom from '../../../components/Webapp/Consultation/WaitingRoom/WaitingRoom';
import TeleconferenceRoom, {
	ESidebarMode,
} from '../../../components/Webapp/Consultation/TeleconferenceRoom/TeleconferenceRoom';
import {
	apiConsultationRoomGenerateToken,
	apiConsultationRoomInitiate,
	apiGetTransactionConsultation,
} from '../../../api/transactionConsultation';
import { Client, Conversation } from '@twilio/conversations';
import { message } from 'antd';
import { httpRequest } from '../../../helpers/api';
import { ApiResponseDefault } from '../../../types/apiResponse.type';

const activePath = '/app/consultation';

interface Params {
	transactionConsultationId: string;
}

export default function ConsultationScreen() {
	const history = useHistory();
	const { transactionConsultationId } = useParams<Params>();
	const [state, setState] = useState<'INITIAL' | 'ROOM'>('INITIAL');
	const [isLoadingFetchConsultation, setIsLoadingFetchConsultation] =
		useState(true);
	const [isLoadingJoinRoom, setIsLoadingJoinRoom] = useState(false);

	const [transactionConsultation, setTransactionConsultation] =
		useState<TransactionConsultationProperties>();

	const roomType = useMemo(() => {
		if (!transactionConsultation) return;

		switch (transactionConsultation.consultationType) {
			case EConsultationType.CONSULT_NOW_VIDEO_CHAT_CONSULTATION:
			case EConsultationType.TELEMEDICINE_VIDEO_CHAT_CONSULTATION:
				return 'VIDEO_CHAT';
			case EConsultationType.CONSULT_NOW_CHAT_CONSULTATION:
			case EConsultationType.TELEMEDICINE_CHAT_CONSULTATION:
				return 'CHAT';
			case EConsultationType.FACE_TO_FACE_CONSULTATION:
				return 'IN_PERSON';
		}
	}, [transactionConsultation]);

	const [roomDetail, setRoomDetail] = useState<TwilioRoomProperties>();
	const [token, setToken] = useState<string>();
	const [room, setRoom] = useState<Room>();
	const [conversation, setConversation] = useState<Conversation>();
	const [localVideoTrack, setLocalVideoTrack] = useState<LocalVideoTrack>();
	const [localAudioTrack, setLocalAudioTrack] = useState<LocalAudioTrack>();

	const isVideoOn = localVideoTrack !== undefined;
	const isMicOn = localAudioTrack !== undefined;
	const [sidebarMode, setSidebarMode] = useState<ESidebarMode>();

	useEffect(() => {
		if (!roomType) return;

		(async () => {
			if (roomType !== 'VIDEO_CHAT') return;
			if (localVideoTrack) return;

			try {
				const localVideoTrack = await createLocalVideoTrack();
				setLocalVideoTrack(localVideoTrack);
			} catch (err) {
				console.error(err);
				message.error(err);
			}
		})();

		(async () => {
			if (roomType !== 'VIDEO_CHAT') return;
			if (localAudioTrack) return;

			try {
				const localAudioTrack = await createLocalAudioTrack();
				setLocalAudioTrack(localAudioTrack);
			} catch (err) {
				console.error(err);
				message.error(err);
			}
		})();
	}, [roomType]);

	useEffect(() => {
		if (!transactionConsultationId) return;

		(async () => {
			setIsLoadingFetchConsultation(true);
			const res = await apiGetTransactionConsultation(
				transactionConsultationId,
			);
			setTransactionConsultation(res);
			setIsLoadingFetchConsultation(false);
		})();
	}, [transactionConsultationId, state]);

	useEffect(() => {
		if (!transactionConsultation || !roomType) return;

		if (roomType !== 'IN_PERSON') {
			initiateRoom();
		}
	}, [transactionConsultation, roomType]);

	useEffect(() => {
		if (!isLoadingJoinRoom) return;
		if (
			roomType === 'CHAT' || roomType === 'VIDEO_CHAT' ? !conversation : false
		)
			return;

		(async () => {
			try {
				if (roomType === 'VIDEO_CHAT') {
					if (token) {
						const room = await connect(token, {
							video: false,
							audio: false,
						});
						setRoom(room);
						if (localVideoTrack) {
							room.localParticipant.publishTrack(localVideoTrack);
						}
						if (localAudioTrack) {
							room.localParticipant.publishTrack(localAudioTrack);
						}
					}
				}

				setState('ROOM');
			} catch (err) {
				const error = getErrorMessage(err);
				console.error(error);
				message.error(error);
			} finally {
				setIsLoadingJoinRoom(false);
			}
		})();
	}, [isLoadingJoinRoom, token, conversation]);

	async function initiateRoom() {
		const resRoomDetail = await apiConsultationRoomInitiate(
			transactionConsultationId,
		);
		if (resRoomDetail) {
			setRoomDetail(resRoomDetail);
		}

		const resToken = await apiConsultationRoomGenerateToken(
			transactionConsultationId,
		);
		if (resToken) {
			setToken(resToken.token);

			const client = new Client(resToken.token);
			if (client.connectionState === 'denied') {
				const err = 'Chat denied';
				console.error(err);
				message.error(err);
			} else {
				try {
					const paginator = await client.getSubscribedConversations();
					const newConversations = paginator.items;

					const conversation = newConversations.find((item) =>
						roomType === 'VIDEO_CHAT'
							? item.friendlyName === resRoomDetail?.videoRoomSID
							: item.sid === resRoomDetail?.conversationSID,
					);
					if (conversation) {
						setConversation(conversation);
					} else {
						const err = 'Conversation not found';
						console.error(err);
						message.error(err);
					}
				} catch (err) {
					console.error(err);
					message.error(err);
				}
			}
		}
	}

	async function toggleMic() {
		if (localAudioTrack) {
			room?.localParticipant.unpublishTrack(localAudioTrack);
			localAudioTrack.stop();
			setLocalAudioTrack(undefined);
		} else {
			const localAudioTrack = await createLocalAudioTrack();
			room?.localParticipant.publishTrack(localAudioTrack);
			setLocalAudioTrack(localAudioTrack);
		}
	}

	async function toggleVideo() {
		if (localVideoTrack) {
			room?.localParticipant.unpublishTrack(localVideoTrack);
			localVideoTrack.stop();
			setLocalVideoTrack(undefined);
		} else {
			const localVideoTrack = await createLocalVideoTrack();
			room?.localParticipant.publishTrack(localVideoTrack);
			setLocalVideoTrack(localVideoTrack);
		}
	}

	function toggleChat() {
		setSidebarMode((oldState) =>
			oldState === ESidebarMode.CHAT ? undefined : ESidebarMode.CHAT,
		);
	}

	function toggleConsultation() {
		setSidebarMode((oldState) =>
			oldState === ESidebarMode.CONSULTATION
				? undefined
				: ESidebarMode.CONSULTATION,
		);
	}

	async function startTeleconference() {
		setIsLoadingJoinRoom(true);
	}

	async function endTeleconference() {
		if (localVideoTrack) {
			room?.localParticipant.unpublishTrack(localVideoTrack);
		}
		if (localAudioTrack) {
			room?.localParticipant.unpublishTrack(localAudioTrack);
		}

		const res = await httpRequest.patch<
			ApiResponseDefault<TransactionConsultationProperties>
		>(`transaction-consults/${transactionConsultationId}`, {
			transactionStatus: ETransactionStatusType.COMPLETED,
		});
		console.log(res);
		if (res.data.code === 'success') {
			room?.disconnect();
			setRoom(undefined);
			setState('INITIAL');
			history.push('/');
			message.success('Telemedicine has ended');
		}
	}

	return state === 'INITIAL' ? (
		<AppLayout activePath={activePath}>
			<div className="h-full w-full flex items-center justify-center">
				<WaitingRoom
					roomType={roomType}
					isLoadingFetchConsultation={isLoadingFetchConsultation}
					isLoadingJoinRoom={isLoadingJoinRoom}
					transactionConsultation={transactionConsultation}
					localVideoTrack={localVideoTrack}
					isMicOn={isMicOn}
					toggleMic={toggleMic}
					isVideoOn={isVideoOn}
					toggleVideo={toggleVideo}
					startTeleconference={startTeleconference}
				/>
			</div>
		</AppLayout>
	) : state === 'ROOM' ? (
		<TeleconferenceRoom
			room={room}
			roomType={roomType}
			conversation={conversation}
			transactionConsultation={transactionConsultation}
			localVideoTrack={localVideoTrack}
			isMicOn={isMicOn}
			toggleMic={toggleMic}
			isVideoOn={isVideoOn}
			toggleVideo={toggleVideo}
			toggleChat={toggleChat}
			toggleConsultation={toggleConsultation}
			sidebarMode={sidebarMode}
			setSidebarMode={setSidebarMode}
			endTeleconference={endTeleconference}
		/>
	) : (
		<></>
	);
}
