import { message } from 'antd';
import { httpRequest } from '../helpers/api';
import { getErrorMessage } from '../helpers/errorHandler';
import { ApiResponseSuccess } from '../types/api.type';
import { ApiResponseDefault } from '../types/apiResponse.type';
import {
	ETransactionRejectReasonType,
	TransactionConsultationProperties,
} from '../types/transactionConsultation.type';
import {
	TwilioGenerateTokenProperties,
	TwilioRoomProperties,
} from '../types/twilioRoom.type';
import { IUser } from '../types/user.type';

export async function apiConsultationRoomInitiate(
	transactionConsultationId: string,
) {
	try {
		const res = await httpRequest.post<
			ApiResponseDefault<TwilioRoomProperties>
		>('transaction-consults/' + transactionConsultationId + '/initiate-room');
		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}

export async function apiConsultationRoomGenerateToken(
	transactionConsultationId: string,
) {
	try {
		const res = await httpRequest.post<
			ApiResponseDefault<TwilioGenerateTokenProperties>
		>('transaction-consults/' + transactionConsultationId + '/generate-token');
		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}

export async function apiGetTransactionConsultation(
	transactionConsultationId: string,
) {
	try {
		const res = await httpRequest.get<
			ApiResponseDefault<TransactionConsultationProperties>
		>(`/transaction-consults/${transactionConsultationId}`);
		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}

export async function apiExtendConsultation(
	transactionConsultationId: string,
	isCanExtend: boolean,
) {
	try {
		const res = await httpRequest.patch<
			ApiResponseDefault<TransactionConsultationProperties>
		>(`transaction-consults/${transactionConsultationId}`, {
			isCanExtend,
		});
		return res.data.payload;
	} catch (err) {
		const error = getErrorMessage(err);
		console.error(error);
		message.error(error);
	}
}

export async function apiApproveConsultation(
	id: string,
): Promise<ApiResponseDefault<{ isSuccess: boolean }>> {
	const res: ApiResponseSuccess<any> = await httpRequest.post(
		'/transaction-consults/' + id + '/approve',
	);
	return res?.data;
}

export async function apiRejectConsultation(
	id: string,
	data: { lastUpdatedByUser: IUser; reason: string },
): Promise<ApiResponseDefault<{ isSuccess: boolean }>> {
	const res: ApiResponseSuccess<any> = await httpRequest.post(
		'/transaction-consults/' + id + '/reject',
		data,
	);
	return res?.data;
}

export async function apiCancelConsultation(
	id: string,
	data: { lastUpdatedByUser: IUser; reason: string },
): Promise<ApiResponseDefault<{ isSuccess: boolean }>> {
	const res: ApiResponseSuccess<any> = await httpRequest.post(
		'/transaction-consults/' + id + '/cancel-by-doctor-or-secretary',
		data,
	);
	return res?.data;
}

export async function apiRescheduledConsultation(
	id: string,
	data: {
		lastUpdatedByUser: IUser;
		reason: string;
		newScheduledStartAt: Date;
		newScheduledEndAt: Date;
	},
) {
	const res: ApiResponseSuccess<any> = await httpRequest.post(
		'/transaction-consults/' + id + '/reschedule',
		data,
	);
	return res?.data;
}
