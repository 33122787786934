import React from 'react';
import { useHistory } from 'react-router-dom';
import AppLayout from '../../../layout/AppLayout';
import { Card, Typography, Divider, Row } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import {
	iconInputReferral as IconInputReferral,
	iconDoctorInformation as IconDoctorInformation,
	iconInviteFriends as IconInviteFriends,
	iconBankAccount as IconBankAccount,
	iconSecretaries as IconSecretaries,
	iconClinicsAndPractice as IconClinicsAndPractice,
} from '../../../assets/icons';
import { EKycStatus } from '../../../types/doctor.type';
import { useAuth } from '../../../context/auth.context';

const activePath = '/app/setting';
const { Text, Title } = Typography;

export default function SettingScreen() {
	const { doctorKYCStatus } = useAuth();

	return (
		<AppLayout activePath={activePath}>
			<Card>
				<Title level={5}>Setting</Title>
				<Divider />

				<div style={{ marginBottom: 8 }}>
					<Text type="secondary" style={{ fontSize: 16, fontWeight: 500 }}>
						Personal
					</Text>
					{ListItem(
						'Doctor Information',
						<IconDoctorInformation />,
						'/app/setting/doctor-information',
					)}
					{ListItem(
						'Clinics and Practice Settings',
						<IconClinicsAndPractice />,
						'/app/setting/clinics-and-practice',
					)}
					{ListItem(
						'Secretaries',
						<IconSecretaries
							style={{
								color:
									doctorKYCStatus === EKycStatus.APPROVED ? '#000000' : 'gray',
							}}
						/>,
						'/app/setting/secretaries',
						doctorKYCStatus !== EKycStatus.APPROVED,
					)}
				</div>

				<div style={{ marginBottom: 8 }}>
					<Text type="secondary" style={{ fontSize: 16, fontWeight: 500 }}>
						Payout
					</Text>
					{ListItem(
						'Bank Account',
						<IconBankAccount
							style={{
								color:
									doctorKYCStatus === EKycStatus.APPROVED ? '#000000' : 'gray',
							}}
						/>,
						'/app/setting/bank-account',
						doctorKYCStatus !== EKycStatus.APPROVED,
					)}
				</div>

				<div style={{ marginBottom: 8 }}>
					<Text type="secondary" style={{ fontSize: 16, fontWeight: 500 }}>
						Referral
					</Text>
					{ListItem(
						'Invite Friends to get Points',
						<IconInviteFriends />,
						'/app/setting/invite-friends',
					)}
					{ListItem(
						'Input Referall Code',
						<IconInputReferral />,
						'/app/setting/referral',
					)}
				</div>
			</Card>
		</AppLayout>
	);
}

const ListItem = (
	title: string,
	icon: any,
	pathTo: string,
	isDisabled?: boolean,
) => {
	const history = useHistory();

	return (
		<>
			<Row
				style={{
					margin: '16px 0',
					cursor: 'pointer',
					display: 'flex',
					justifyContent: 'space-between',
				}}
				align="middle"
				onClick={() => {
					if (!isDisabled) {
						history.push(pathTo);
					}
				}}
			>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<div style={{ width: 24, textAlign: 'center' }}>{icon}</div>
					<Text
						style={{
							marginLeft: 8,
							fontSize: 18,
							color: isDisabled ? 'gray' : undefined,
						}}
					>
						{title}
					</Text>
				</div>
				<RightOutlined style={{ color: '#C5CED6' }} height={14} width={8} />
			</Row>
			<Divider style={{ marginTop: 10, marginBottom: 10 }} />
		</>
	);
};
