import { Button, Col, Input, Row, Typography } from 'antd';
import { iconInformationCirclePrimary as IconInformationCircle } from '../../../assets/icons';
import COLORS from '../../../assets/globalColors';

const { Text } = Typography;

type Props = {
	referralCode: string;
	setReferralCode: React.Dispatch<React.SetStateAction<string>>;
	submitReferralHandler: () => void;
};

export default function InputReferralCode({
	referralCode,
	setReferralCode,
	submitReferralHandler,
}: Props) {
	return (
		<>
			<Row>
				<Col span={24}>
					<Text style={{ fontSize: 14 }}>Referral Code</Text>
					<Input
						type="text"
						value={referralCode}
						onChange={(e) => setReferralCode(e.target.value.toUpperCase())}
						style={{
							width: '100%',
							borderRadius: 12,
							padding: '6px 12px',
						}}
					/>
				</Col>
			</Row>

			<Row
				align="middle"
				gutter={10}
				style={{
					background: COLORS.ash_300,
					padding: 12,
					borderRadius: 12,
					margin: '25px 0px',
				}}
			>
				<Col>
					<IconInformationCircle
						width={16}
						height={16}
						style={{
							verticalAlign: 'middle',
						}}
					/>
				</Col>
				<Col style={{ paddingTop: 5 }}>
					<Text style={{ color: COLORS.black_70, fontSize: 16 }}>
						Once entered, the referral code cannot be changed
					</Text>
				</Col>
			</Row>

			<Row>
				<Button
					style={{
						background: COLORS.primary,
						width: '100%',
						height: 42,
						borderRadius: 12,
					}}
					onClick={submitReferralHandler}
				>
					<Text
						style={{
							color: COLORS.white,
							fontSize: 14,
							fontWeight: 700,
						}}
					>
						Save
					</Text>
				</Button>
			</Row>
		</>
	);
}
