import styled from 'styled-components';

export default styled.div`
	overflow: auto;
	&::-webkit-scrollbar {
		width: 4px;
		height: 8px;
	}
	&::-webkit-scrollbar-thumb {
		width: 4px;
		height: 8px;
		background: ${({ theme }) => theme.colors.gray20};
		border-radius: 32px;
	}
	&::-webkit-scrollbar-thumb:hover {
		background: ${({ theme }) => theme.colors.gray30};
	}
	&::-webkit-scrollbar-thumb:active {
		background: ${({ theme }) => theme.colors.gray60};
	}
`;
