import moment from 'moment';

export function validateEmail(mail: string) {
	if (
		/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
			mail,
		)
	) {
		return true;
	}
	return false;
}

export function validateUsername(text: string) {
	if (/^[a-zA-Z0-9]+$/.test(text)) {
		return true;
	}
	return false;
}

export function validateName(text: string) {
	if (/^[a-z\s]+$/i.test(text)) {
		return true;
	}
	return false;
}

export function validatePhoneNumber(text: string) {
	const errors = [];
	if (text?.length > 0) {
		if (/^[\+]?\d+$/.test(text)) {
			if (text.length >= 10 && text.length <= 13) {
				// return true;
			} else {
				errors.push('Character length must be between 10-13 characters');
			}
		} else {
			errors.push('Can only contain numbers');
		}
	}

	return errors;
}

export function validateAge(text: string) {
	if (/^\d+$/.test(text)) {
		return true;
	}
	return false;
}

export function validateMaxText(truncate: number, text: string) {
	if (text.length < truncate) {
		return text;
	} else {
		return `${text.substring(0, truncate)}...`;
	}
}

export function validateBirthdate(date: string) {
	let today = new Date();
	let birthDate = new Date(date);
	let age = today.getFullYear() - birthDate.getFullYear();
	let monthDiff = today.getMonth() - birthDate.getMonth();
	if (
		monthDiff < 0 ||
		(monthDiff === 0 && today.getDate() < birthDate.getDate())
	) {
		age--;
	}
	return age < 14;
}

export function capitalizeFirstLetter(string: string) {
	string = string.toLowerCase();
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export function checkTime(startAt: Date | undefined, endAt: Date | undefined) {
	const isToday = moment(startAt).isSame(moment(), 'day');
	if (isToday) {
		const startTime = moment()
			.hour(moment(startAt).hour())
			.minute(moment(startAt).minute() - 1);
		const endTime = moment()
			.hour(moment(endAt).hour())
			.minute(moment(endAt).minute() + 1);
		if (moment().isBetween(startTime, endTime)) {
			return true;
		} else {
			return false;
		}
	} else {
		return false;
	}
}
