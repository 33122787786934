import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useFetchList from '../../../../hooks/useFetchList';
import AppLayout from '../../../../layout/AppLayout';
import PageHeader from '../../../../components/PageHeader';
import {
	Button,
	Card,
	Divider,
	message,
	Row,
	Spin,
	Typography,
	Col,
	Space,
} from 'antd';
import {
	iconInviteSecretary as IconInviteSecretary,
	iconInvitationCode as IconInvitationCode,
	iconChevron as IconChevronRight,
	iconEmptySecretary as IconEmpty,
} from '../../../../assets/icons';
import Title from 'antd/lib/typography/Title';
import EmptyList from '../../../../components/EmptyList';

import {
	apiApproveSecretary,
	apiGetInvitationCode,
	apiRejectSecretary,
} from '../../../../api/doctorSecretaries';
import {
	SecretaryProperties,
	EDoctorSecretaryStatus,
} from '../../../../types/doctorSecretaries.type';
import { getName } from '../../../../helpers/name';
import ModalApproveSecretary from '../../../../components/Webapp/Secretaries/ModalApproveSecretary';
import ModalRejectSecretary from '../../../../components/Webapp/Secretaries/ModalRejectSecretary';
import { useAuth } from '../../../../context/auth.context';
import { httpRequest } from '../../../../helpers/api';
import { ApiResponsePagination } from '../../../../types/apiResponse.type';

const activePath = '/app/setting';
const { Text, Paragraph } = Typography;

type SelectedData = {
	doctorId: string;
	secretaryId: string;
	secretaryName: string;
};

export default function Secretaries() {
	const history = useHistory();
	const { userId } = useAuth();
	const [invitationCode, setInvitationCode] = useState<string>();
	const [isLoading, setIsLoading] = useState(true);
	const [isLoadingGetInviteCode, setIsLoadingGetInviteCode] = useState(true);

	const [isLoadingRejectAndApprove, setIsLoadingRejectAndApprove] =
		useState(false);
	const [rawData, setRawData] = useState<SecretaryProperties[]>();
	const [data, setData] = useState<SecretaryProperties[]>();
	const [workplaces, setWorkplaces] = useState<string[]>();
	const [selectedData, setSelectedData] = useState<SelectedData>();
	const [isModalRejectVisible, setIsModalRejectVisible] = useState(false);
	const [isModalApproveVisible, setIsModalApproveVisible] = useState(false);

	const fetchList = async () => {
		setIsLoading(true);

		try {
			const res = await httpRequest.get<
				ApiResponsePagination<SecretaryProperties>
			>('/doctor-secretaries/doctor/' + userId);
			if (res?.data?.payload?.results) {
				setRawData(res.data.payload?.results);
			}
		} catch (error) {
			console.info('error fetch doctor ::: ', error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (userId) {
			fetchList();
		}
	}, [userId]);

	useEffect(() => {
		if (!rawData) return;
		(async () => {
			const workplaces: string[] = [];
			setData(
				rawData.map((res) => {
					for (const partner of res.partners) {
						if (partner.isWorkplace) {
							workplaces.push(partner.partnerName);
							break;
						}
					}
					return {
						...res,
						secretary: {
							...res.secretary,
							name: getName({
								firstName: res?.secretary?.firstName,
								middleName: res?.secretary?.middleName,
								lastName: res?.secretary?.lastName,
								nickName: res?.secretary?.nickName,
							}),
						},
					};
				}),
			);
			setWorkplaces(workplaces);
		})();
	}, [rawData]);

	useEffect(() => {
		if (userId) {
			(async () => {
				setIsLoadingGetInviteCode(true);

				const res = await apiGetInvitationCode(userId);

				setIsLoadingGetInviteCode(false);

				if (res?.payload?.invitationCode) {
					setInvitationCode(res?.payload?.invitationCode);
				} else {
					message.error('Failed to get invitation code');
					setInvitationCode('ERROR');
				}
			})();
		}
	}, [userId]);

	const copyHandler = () => {
		if (!invitationCode) return;

		navigator.clipboard.writeText(invitationCode);

		message.success('Invitation code copied to clipboard');
	};

	const detailSecretaryHandler = (data: {
		secretaryId?: string;
		secretaryName?: string;
		partnerName?: string;
		phone?: string;
	}) => {
		history.push({
			pathname: `/app/setting/secretary/${data.secretaryId}`,
			state: data,
		});
	};

	const openModalRejectSecretary = (data: SelectedData) => {
		setSelectedData(data);
		setIsModalRejectVisible(true);
	};

	const openModalApproveSecretary = (data: SelectedData) => {
		setSelectedData(data);
		setIsModalApproveVisible(true);
	};

	const rejectSecretaryHandler = async () => {
		if (!selectedData) return;

		setIsLoadingRejectAndApprove(true);

		const res = await apiRejectSecretary(selectedData);

		setIsLoadingRejectAndApprove(false);

		if (res.code !== 'success') {
			message.error('Failed to reject secretary');
			return;
		}

		setSelectedData(undefined);
		fetchList();
		setIsModalRejectVisible(false);
	};

	const approveSecretaryHandler = async () => {
		if (!selectedData) return;

		setIsLoadingRejectAndApprove(true);

		const res = await apiApproveSecretary(selectedData);

		setIsLoadingRejectAndApprove(false);

		if (res.code !== 'success') {
			message.error('Failed to approve secretary');

			return;
		}

		setSelectedData(undefined);
		fetchList();
		setIsModalApproveVisible(false);
	};

	const ComponentInvitation = () => {
		return (
			<Row justify="space-between">
				<Col span={15}>
					<Space size={10} className="mb-1 items-center">
						<IconInviteSecretary width={27} height={22} />
						<Text className="text-4 font-bold">Invite Your Secretary!</Text>
					</Space>
					<Paragraph style={{ marginBottom: 0 }} className="text-3.5">
						Your Secretary can apply from the medeasy app by entering your
						invition code.
					</Paragraph>
				</Col>
				<Col span={8}>
					<Text className="text-3.5">Invitation Code!</Text>
					<Button
						loading={isLoadingGetInviteCode}
						type="ghost"
						className="w-full flex items-center justify-between py-4.5 rounded-3 border-primary"
						onClick={copyHandler}
					>
						<Text className="font-medium text-primary">{invitationCode}</Text>
						<IconInvitationCode width={12} height={13} />
					</Button>
				</Col>
			</Row>
		);
	};
	const ComponentSecretaryItem = ({
		item,
		idx,
	}: {
		item: SecretaryProperties;
		idx: number;
	}) => {
		return (
			<Row
				key={idx}
				align="middle"
				className={`${
					item?.status === EDoctorSecretaryStatus.APPROVED && 'cursor-pointer'
				} border-b border-solid border-0 border-gray-20 py-3 mb-3`}
				justify="space-between"
				onClick={
					item?.status === EDoctorSecretaryStatus.APPROVED
						? () =>
								detailSecretaryHandler({
									secretaryId: item?.secretaryId,
									secretaryName: item?.secretary?.name,
									partnerName: workplaces?.[idx]
										? workplaces?.[idx]
										: 'undefined',
									phone: item?.secretary?.phone,
								})
						: () => null
				}
			>
				<Col>
					<Text className="text-4 block mb-1">{item?.secretary?.name}</Text>
					<Text className="text-3.5 mb-1 block text-black-70">
						{workplaces?.[idx] ? workplaces?.[idx] : 'undefined'}{' '}
					</Text>
					<Text className="text-3.5 mb-0 block text-black-70">
						{item?.secretary?.phone}
					</Text>
				</Col>{' '}
				<Col>
					{item?.status === EDoctorSecretaryStatus.APPROVED ? (
						<Button>
							<IconChevronRight />
						</Button>
					) : undefined}

					{item?.status === EDoctorSecretaryStatus.WAITING_APPROVAL ? (
						<Space size="middle" className="z-10">
							<Button
								type="ghost"
								onClick={() =>
									openModalRejectSecretary({
										doctorId: userId!,
										secretaryId: item.secretaryId,
										secretaryName: item.secretary.name!,
									})
								}
								className="border-primary text-3.5 text-primary rounded-2.5 w-28"
							>
								Reject
							</Button>
							<Button
								type="primary"
								style={{
									color: '#fff',
								}}
								className="bg-primary text-3.5 rounded-2.5 w-28"
								onClick={() =>
									openModalApproveSecretary({
										doctorId: userId!,
										secretaryId: item.secretaryId,
										secretaryName: item.secretary.name!,
									})
								}
							>
								Approve
							</Button>
						</Space>
					) : undefined}
				</Col>
			</Row>
		);
	};
	const ComponentSecretaryList = () => {
		return (
			<div className="py-4 px-3">
				<Title level={5} style={{ fontSize: 18 }} className="font-bold">
					Secretary List
				</Title>

				<Spin spinning={isLoading} />
				{!isLoading &&
					data?.map((item, idx) => (
						<ComponentSecretaryItem
							key={item.secretaryId}
							item={item}
							idx={idx}
						/>
					))}
				{!isLoading && data?.length === 0 && (
					<EmptyList
						icon={<IconEmpty />}
						title="No Secretary Yet"
						description="No secretary has entered your code yet. Your secretary will appear here when entered your code."
					/>
				)}
			</div>
		);
	};

	return (
		<AppLayout activePath={activePath}>
			<Card style={{ paddingBottom: 110 }}>
				<PageHeader title="Secretaries" onClick={() => history.goBack()} />
				<Divider />
				<div
					style={{
						width: '100%',
						border: '1px solid #D5DCE1',
						borderRadius: 12,
						padding: 12,
						marginBottom: 16,
					}}
				>
					<ComponentInvitation />
				</div>
				<div className="border-2 border-solid border-gray-10 mb-3" />
				<ComponentSecretaryList />

				<ModalApproveSecretary
					isVisible={isModalApproveVisible}
					setVisibility={setIsModalApproveVisible}
					data={{
						doctorId: selectedData?.doctorId,
						secretaryId: selectedData?.secretaryId,
					}}
					isLoadingApprove={isLoadingRejectAndApprove}
					approveSecretaryHandler={approveSecretaryHandler}
				/>
				<ModalRejectSecretary
					data={{
						secretaryName: selectedData?.secretaryName
							? selectedData?.secretaryName
							: '',
					}}
					isVisible={isModalRejectVisible}
					setVisibility={setIsModalRejectVisible}
					isLoadingReject={isLoadingRejectAndApprove}
					rejectSecretaryHandler={rejectSecretaryHandler}
				/>
			</Card>
		</AppLayout>
	);
}
