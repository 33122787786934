import {
	AuthByPhoneLoginResponse,
	AuthByPhoneSendOTPResponse,
} from '../types/authByPhone.type';
import { httpRequest } from '../helpers/api';
import { IHttpResponse } from '../helpers/pagination';
import axios from 'axios';
import { IUser } from '../types/user.type';
import { getToken } from '../helpers/auth';

export async function apiSendOTP(
	phoneNumber: string,
): Promise<AuthByPhoneSendOTPResponse['data']['payload']> {
	try {
		const res: AuthByPhoneSendOTPResponse = await httpRequest.post(
			`/auth/phone/send-otp`,
			{ phoneNumber: '+63' + phoneNumber },
		);

		if (res && res.data) {
			return res.data.payload;
		} else {
			return Promise.reject({ code: 'no_response', message: 'No response' });
		}
	} catch (err) {
		return Promise.reject(err);
	}
}

export async function apiConfirmOTP(
	sessionId: string,
	otp: string,
): Promise<AuthByPhoneLoginResponse['data']['payload']> {
	try {
		const res: AuthByPhoneLoginResponse = await httpRequest.post(
			`/auth/phone/login`,
			{ sessionId: sessionId, otp: otp },
		);

		if (res && res.data) {
			return res.data.payload;
		} else {
			return Promise.reject({ code: 'no_response', message: 'No response' });
		}
	} catch (err) {
		return Promise.reject(err);
	}
}

export async function apiGetMyProfile(token?: string): Promise<IUser> {
	try {
		let res;
		if (token) {
			res = await axios.get<IHttpResponse<IUser>>(
				process.env.REACT_APP_BASE_URL + '/user/me',
				{
					headers: {
						Authorization: 'Bearer ' + token,
					},
				},
			);
		} else {
			res = await httpRequest.get<IHttpResponse<IUser>>('/user/me', {
				headers: {
					Authorization: 'Bearer ' + (await getToken()),
				},
			});
		}

		if (res && res.data) {
			return res.data.payload;
		} else {
			return Promise.reject({ code: 'no_response', message: 'No response' });
		}
	} catch (err) {
		return Promise.reject(err);
	}
}

export async function apiUpdateMyProfile(
	userId: string,
	userData?: IUser,
): Promise<IUser> {
	try {
		const res = await httpRequest.patch<IHttpResponse<IUser>>(
			`/user/${userId}`,
			userData,
		);

		if (res && res.data) {
			return res.data.payload;
		} else {
			return Promise.reject({ code: 'no_response', message: 'No response' });
		}
	} catch (err) {
		return Promise.reject(err);
	}
}
