import { ChevronRight, Info, Search } from 'react-feather';
import { theme } from '../../../../assets/theme';
import { useAuth } from '../../../../context/auth.context';
import useFetchList from '../../../../hooks/useFetchList';
import { TemplateRxPrescriptionProperties } from '../../../../types/templateRxPrescription.type';
import { Button } from '../../../Button/Button/Button';
import EmptyList from '../../../EmptyList';
import FormInput from '../../../Form/FormInput2';
import Separator from '../../../Separator';
import Spinner from '../../../Spinner';

interface Props {
	setTemplate: React.Dispatch<
		React.SetStateAction<Partial<TemplateRxPrescriptionProperties> | undefined>
	>;
	setNextStep: () => void;
	setIsEditing: () => void;
}

export default function ListRxTemplate(props: Props) {
	const { user } = useAuth();

	const {
		data: templates,
		isLoading: isLoadingTemplate,
		pagination: paginationTemplate,
		search: searchTemplate,
		setSearch: setSearchTemplate,
		changePage: changePageTemplate,
	} = useFetchList<TemplateRxPrescriptionProperties>({
		endpoint: 'template-rx-prescriptions',
		initialQuery: {
			doctorId: user?.userId,
		},
		pushData: true,
		primaryKey: 'templateRxPrescriptionId',
	});

	function handleNextPageTemplate() {
		if (!isLoadingTemplate && paginationTemplate.next) {
			changePageTemplate(paginationTemplate.page + 1);
		}
	}

	function onClickItem(template: TemplateRxPrescriptionProperties) {
		props.setIsEditing();
		props.setTemplate(template);
		props.setNextStep();
	}

	return (
		<div className="flex-1 flex flex-col">
			<div className="px-3 py-1">
				<FormInput
					prefix={
						<Search width={20} height={20} color={theme.colors.charcoal200} />
					}
					placeholder="Search template name"
					value={searchTemplate}
					onChange={setSearchTemplate}
				/>
			</div>
			<div className="flex-1 min-h-0">
				<div className="px-3">
					{!isLoadingTemplate ? (
						templates.length > 0 ? (
							templates.map((template) => (
								<div key={template.templateRxPrescriptionId}>
									<div className="py-4">
										<Item
											name={template.templateName}
											number={template.templatePrescriptionItems.length}
											onClick={() => onClickItem(template)}
										/>
									</div>
									<Separator />
								</div>
							))
						) : (
							<EmptyList
								icon={<Info color={theme.colors.black50} />}
								title="Template is empty"
								description="You can add new rx template"
							/>
						)
					) : (
						<div className="py-4 mx-auto w-fit">
							<Spinner size={25} color={theme.colors.primary} />
						</div>
					)}
				</div>
			</div>
			{!!paginationTemplate.next && (
				<div className="w-80 p-6 mx-auto">
					<Button type="SOLIDASH" onClick={handleNextPageTemplate}>
						Load More
					</Button>
				</div>
			)}
		</div>
	);
}

function Item(props: { name?: string; number: number; onClick: () => void }) {
	return (
		<button
			onClick={props.onClick}
			className="bg-transparent text-left w-full border-none cursor-pointer flex items-center gap-x-2"
		>
			<div className="flex-1">
				<p className="m-0 text-4">{props.name}</p>
				<p className="m-0 mt-1 text-3.5 text-black-70">
					{props.number} Medicine{props.number > 1 ? 's' : ''}
				</p>
			</div>
			<ChevronRight width={20} height={20} color={theme.colors.black30} />
		</button>
	);
}
