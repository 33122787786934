import React, { useMemo, useState } from 'react';
import { Card, Divider, Typography, Form } from 'antd';
import { useHistory } from 'react-router-dom';
import PageHeader from '../../../../components/PageHeader';
import AppLayout from '../../../../layout/AppLayout';
import PreResult from '../../../../components/Webapp/MedicalCalculator/PreResult';
import Score from '../../../../components/Webapp/MedicalCalculator/Score';
import FormQuestion from '../../../../components/Webapp/MedicalCalculator/FormQuestion';

const activePath = '/app/medical-calculator';

const { Text } = Typography;

export default function CardioRiskFramingham() {
	const history = useHistory();

	const [form] = Form.useForm();

	const [resultValue, setResultValue] = useState<any>({});
	const [estimated, setEstimated] = useState<any>({});

	const [isChecked, setChecked] = useState<any>(false);

	const dataIndicator = useMemo(
		() => [
			{
				title: 'Low',
				width: 12,
				color: '#7FD53B',
				start: 0,
				startValue: 0,
			},
			{
				title: 'Intermediate',
				width: 12,
				color: '#EFC222',
				start: 10,
				startValue: 10,
			},
			{
				title: 'High',
				width: 76,
				color: '#E93131',
				start: 24,
				startValue: 20,
			},
		],
		[],
	);

	const dataGenderOption = useMemo(
		() => [
			{ label: 'Male', value: 'male' },
			{ label: 'Female', value: 'female' },
		],
		[],
	);

	const dataQuestions = useMemo(
		() => [
			{
				row: 1,
				formItems: [
					{
						label: 'Gender',
						name: 'gender',
						type: 'select',
						options: dataGenderOption,
					},
					{
						label: 'Age',
						name: 'age',
						min: 30,
						type: 'inputNumber',
					},
				],
			},
			{
				row: 2,
				formItems: [
					{
						label: 'Total cholestrol',
						name: 'total_cholestrol',
						type: 'input',
						suffix: 'mmol/L',
					},
					{
						label: 'HDL cholestrol',
						name: 'hdl_cholestrol',
						type: 'input',
						suffix: 'mmol/L',
					},
					{
						label: 'Systolic blood pressure',
						name: 'systolic_blood_pressure',
						type: 'input',
						suffix: 'mmHg',
					},
				],
			},
			{
				row: 3,
				formItems: [
					{
						label: 'Smoker?',
						name: 'smoker',
						type: 'radio',
					},
					{
						label: 'Diabetes?',
						name: 'diabetes',
						type: 'radio',
					},
					{
						label: 'Treatment for high blood pressure?',
						name: 'systolic_bp_treat',
						type: 'radio',
					},
				],
			},
		],
		[],
	);

	function calculateFraminghamRiskScore({
		age,
		gender,
		systolicBP,
		systolicBPTreat,
		totalCholesterol,
		hdlCholesterol,
		smokingStatus,
		diabetesStatus,
	}: {
		age: number;
		gender: 'Male' | 'Female';
		systolicBP: number;
		systolicBPTreat: boolean;
		totalCholesterol: number;
		hdlCholesterol: number;
		smokingStatus: boolean;
		diabetesStatus: boolean;
	}) {
		console.log(
			age,
			gender,
			systolicBP,
			systolicBPTreat,
			totalCholesterol,
			hdlCholesterol,
			smokingStatus,
			diabetesStatus,
		);
		// Calculate the Framingham Risk Score
		let score = 0;
		if (gender === 'Male') {
			// Age points
			if (age >= 30 && age <= 34) {
				score += 0;
			} else if (age >= 35 && age <= 39) {
				score += 2;
			} else if (age >= 40 && age <= 44) {
				score += 5;
			} else if (age >= 45 && age <= 49) {
				score += 7;
			} else if (age >= 50 && age <= 54) {
				score += 8;
			} else if (age >= 55 && age <= 59) {
				score += 10;
			} else if (age >= 60 && age <= 64) {
				score += 11;
			} else if (age >= 65 && age <= 69) {
				score += 12;
			} else if (age >= 70 && age <= 74) {
				score += 14;
			} else if (age >= 75) {
				score += 15;
			}

			// HDL cholesterol points
			if (hdlCholesterol > 1.6) {
				score += -2;
			} else if (hdlCholesterol >= 1.3 && hdlCholesterol <= 1.6) {
				score += -1;
			} else if (hdlCholesterol >= 1.2 && hdlCholesterol <= 1.29) {
				score += 0;
			} else if (hdlCholesterol >= 0.9 && hdlCholesterol <= 1.19) {
				score += 1;
			} else if (hdlCholesterol < 0.9) {
				score += 2;
			}
			// Cholesterol points
			if (totalCholesterol < 4.1) {
				score += 0;
			} else if (totalCholesterol >= 4.1 && totalCholesterol <= 5.19) {
				score += 1;
			} else if (totalCholesterol >= 5.2 && totalCholesterol <= 6.19) {
				score += 2;
			} else if (totalCholesterol >= 6.2 && totalCholesterol <= 7.2) {
				score += 3;
			} else if (totalCholesterol > 7.2) {
				score += 4;
			}

			// Systolic blood pressure points
			if (systolicBP < 120 && systolicBPTreat) {
				score += 0;
			} else if (systolicBP < 120 && !systolicBPTreat) {
				score += -2;
			} else if (systolicBP >= 120 && systolicBP <= 129 && systolicBPTreat) {
				score += 2;
			} else if (systolicBP >= 120 && systolicBP <= 129 && !systolicBPTreat) {
				score += 0;
			} else if (systolicBP >= 130 && systolicBP <= 139 && systolicBPTreat) {
				score += 3;
			} else if (systolicBP >= 130 && systolicBP <= 139 && !systolicBPTreat) {
				score += 1;
			} else if (systolicBP >= 140 && systolicBP <= 149 && systolicBPTreat) {
				score += 4;
			} else if (systolicBP >= 140 && systolicBP <= 149 && !systolicBPTreat) {
				score += 2;
			} else if (systolicBP >= 150 && systolicBP <= 159 && systolicBPTreat) {
				score += 4;
			} else if (systolicBP >= 150 && systolicBP <= 159 && !systolicBPTreat) {
				score += 2;
			} else if (systolicBP >= 160 && systolicBPTreat) {
				score += 5;
			} else if (systolicBP >= 160 && !systolicBPTreat) {
				score += 3;
			}

			// Smoking points
			if (smokingStatus) {
				score += 4;
			} else {
				score += 0;
			}

			// Diabetes points
			if (diabetesStatus) {
				score += 3;
			} else {
				score += 0;
			}
		}

		if (gender === 'Female') {
			// Age points
			if (age >= 30 && age <= 34) {
				score += 0;
			} else if (age >= 35 && age <= 39) {
				score += 2;
			} else if (age >= 40 && age <= 44) {
				score += 4;
			} else if (age >= 45 && age <= 49) {
				score += 5;
			} else if (age >= 50 && age <= 54) {
				score += 7;
			} else if (age >= 55 && age <= 59) {
				score += 8;
			} else if (age >= 60 && age <= 64) {
				score += 9;
			} else if (age >= 65 && age <= 69) {
				score += 10;
			} else if (age >= 70 && age <= 74) {
				score += 11;
			} else if (age >= 75) {
				score += 12;
			}

			// HDL cholesterol points
			if (hdlCholesterol > 1.6) {
				score += -2;
			} else if (hdlCholesterol >= 1.3 && hdlCholesterol <= 1.6) {
				score += -1;
			} else if (hdlCholesterol >= 1.2 && hdlCholesterol <= 1.29) {
				score += 0;
			} else if (hdlCholesterol >= 0.9 && hdlCholesterol <= 1.19) {
				score += 1;
			} else if (hdlCholesterol < 0.9) {
				score += 2;
			}
			// Cholesterol points
			if (totalCholesterol < 4.1) {
				score += 0;
			} else if (totalCholesterol >= 4.1 && totalCholesterol <= 5.19) {
				score += 1;
			} else if (totalCholesterol >= 5.2 && totalCholesterol <= 6.19) {
				score += 3;
			} else if (totalCholesterol >= 6.2 && totalCholesterol <= 7.2) {
				score += 4;
			} else if (totalCholesterol > 7.2) {
				score += 5;
			}

			// Systolic blood pressure points
			if (systolicBP < 120 && systolicBPTreat) {
				score += -1;
			} else if (systolicBP < 120 && !systolicBPTreat) {
				score += -3;
			} else if (systolicBP >= 120 && systolicBP <= 129 && systolicBPTreat) {
				score += 2;
			} else if (systolicBP >= 120 && systolicBP <= 129 && !systolicBPTreat) {
				score += 0;
			} else if (systolicBP >= 130 && systolicBP <= 139 && systolicBPTreat) {
				score += 3;
			} else if (systolicBP >= 130 && systolicBP <= 139 && !systolicBPTreat) {
				score += 1;
			} else if (systolicBP >= 140 && systolicBP <= 149 && systolicBPTreat) {
				score += 5;
			} else if (systolicBP >= 140 && systolicBP <= 149 && !systolicBPTreat) {
				score += 2;
			} else if (systolicBP >= 150 && systolicBP <= 159 && systolicBPTreat) {
				score += 6;
			} else if (systolicBP >= 150 && systolicBP <= 159 && !systolicBPTreat) {
				score += 4;
			} else if (systolicBP >= 160 && systolicBPTreat) {
				score += 7;
			} else if (systolicBP >= 160 && !systolicBPTreat) {
				score += 5;
			}

			// Smoking points
			if (smokingStatus) {
				score += 3;
			} else {
				score += 0;
			}

			// Diabetes points
			if (diabetesStatus) {
				score += 4;
			} else {
				score += 0;
			}
		}

		return score;
	}

	function calculateCvdRisk(score: number, gender: 'Male' | 'Female') {
		let riskPercent;

		switch (gender) {
			case 'Male':
				if (score <= -3) {
					riskPercent = 1;
				} else if (score === -2) {
					riskPercent = 1.1;
				} else if (score === -1) {
					riskPercent = 1.4;
				} else if (score === 0) {
					riskPercent = 1.6;
				} else if (score === 1) {
					riskPercent = 1.9;
				} else if (score === 2) {
					riskPercent = 2.3;
				} else if (score === 3) {
					riskPercent = 2.8;
				} else if (score === 4) {
					riskPercent = 3.3;
				} else if (score === 5) {
					riskPercent = 3.9;
				} else if (score === 6) {
					riskPercent = 4.7;
				} else if (score === 7) {
					riskPercent = 5.6;
				} else if (score === 8) {
					riskPercent = 6.7;
				} else if (score === 9) {
					riskPercent = 7.9;
				} else if (score === 10) {
					riskPercent = 9.4;
				} else if (score === 11) {
					riskPercent = 11.2;
				} else if (score === 12) {
					riskPercent = 13.3;
				} else if (score === 13) {
					riskPercent = 15.6;
				} else if (score === 14) {
					riskPercent = 18.4;
				} else if (score === 15) {
					riskPercent = 21.6;
				} else if (score === 16) {
					riskPercent = 25.3;
				} else if (score === 17) {
					riskPercent = 29.4;
				} else if (score >= 18) {
					riskPercent = 30;
				}
				break;

			case 'Female':
				if (score <= -3) {
					riskPercent = 1;
				} else if (score === -2) {
					riskPercent = 1;
				} else if (score === -1) {
					riskPercent = 1.0;
				} else if (score === 0) {
					riskPercent = 1.2;
				} else if (score === 1) {
					riskPercent = 1.5;
				} else if (score === 2) {
					riskPercent = 1.7;
				} else if (score === 3) {
					riskPercent = 2.0;
				} else if (score === 4) {
					riskPercent = 2.4;
				} else if (score === 5) {
					riskPercent = 2.8;
				} else if (score === 6) {
					riskPercent = 3.3;
				} else if (score === 7) {
					riskPercent = 3.9;
				} else if (score === 8) {
					riskPercent = 4.5;
				} else if (score === 9) {
					riskPercent = 5.3;
				} else if (score === 10) {
					riskPercent = 6.3;
				} else if (score === 11) {
					riskPercent = 7.3;
				} else if (score === 12) {
					riskPercent = 8.6;
				} else if (score === 13) {
					riskPercent = 10;
				} else if (score === 14) {
					riskPercent = 11.7;
				} else if (score === 15) {
					riskPercent = 13.7;
				} else if (score === 16) {
					riskPercent = 15.9;
				} else if (score === 17) {
					riskPercent = 18.51;
				} else if (score === 18) {
					riskPercent = 21.5;
				} else if (score === 19) {
					riskPercent = 24.8;
				} else if (score === 20) {
					riskPercent = 27.5;
				} else if (score >= 21) {
					riskPercent = 30;
				}
				break;

			default:
				riskPercent = 0;
		}

		return riskPercent;
	}

	function calculateRiskStatus(percent: number | undefined) {
		let riskStatus;

		if (percent! < 10) {
			riskStatus = 'Low';
		} else if (percent! >= 10 && percent! <= 19) {
			riskStatus = 'Moderate';
		} else if (percent! >= 20) {
			riskStatus = 'High';
		}

		return riskStatus;
	}

	function calculateHeartAge(score: number, gender: 'Male' | 'Female') {
		let heartAge;

		switch (gender) {
			case 'Male':
				if (score < 0) {
					heartAge = '< 30';
				} else if (score === 0) {
					heartAge = 30;
				} else if (score === 1) {
					heartAge = 32;
				} else if (score === 2) {
					heartAge = 34;
				} else if (score === 3) {
					heartAge = 36;
				} else if (score === 4) {
					heartAge = 38;
				} else if (score === 5) {
					heartAge = 40;
				} else if (score === 6) {
					heartAge = 42;
				} else if (score === 7) {
					heartAge = 45;
				} else if (score === 8) {
					heartAge = 48;
				} else if (score === 9) {
					heartAge = 51;
				} else if (score === 10) {
					heartAge = 54;
				} else if (score === 11) {
					heartAge = 57;
				} else if (score === 12) {
					heartAge = 60;
				} else if (score === 13) {
					heartAge = 64;
				} else if (score === 14) {
					heartAge = 68;
				} else if (score === 15) {
					heartAge = 72;
				} else if (score === 16) {
					heartAge = 76;
				} else if (score >= 17) {
					heartAge = '> 80';
				}
				break;

			case 'Female':
				if (score < 1) {
					heartAge = '< 30';
				} else if (score === 1) {
					heartAge = 31;
				} else if (score === 2) {
					heartAge = 34;
				} else if (score === 3) {
					heartAge = 36;
				} else if (score === 4) {
					heartAge = 39;
				} else if (score === 5) {
					heartAge = 42;
				} else if (score === 6) {
					heartAge = 45;
				} else if (score === 7) {
					heartAge = 48;
				} else if (score === 8) {
					heartAge = 51;
				} else if (score === 9) {
					heartAge = 55;
				} else if (score === 10) {
					heartAge = 59;
				} else if (score === 11) {
					heartAge = 64;
				} else if (score === 12) {
					heartAge = 68;
				} else if (score === 13) {
					heartAge = 73;
				} else if (score === 14) {
					heartAge = 79;
				} else if (score >= 15) {
					heartAge = '> 80';
				}
				break;

			default:
				heartAge = 0;
		}

		return heartAge;
	}

	function calculateFramingham(data: any) {
		const {
			age,
			gender,
			diabetes,
			hdl_cholestrol,
			smoker,
			systolic_blood_pressure,
			systolic_bp_treat,
			total_cholestrol,
		} = data;

		const score = calculateFraminghamRiskScore({
			age: +age,
			gender,
			diabetesStatus: diabetes === 'yes' ? true : false,
			hdlCholesterol: parseFloat(hdl_cholestrol.replace(',', '.')),
			smokingStatus: smoker === 'yes' ? true : false,
			systolicBP: parseFloat(systolic_blood_pressure.replace(',', '.')),
			systolicBPTreat: systolic_bp_treat === 'yes' ? true : false,
			totalCholesterol: parseFloat(total_cholestrol.replace(',', '.')),
		});
		const riskPercent = calculateCvdRisk(score, gender);
		const riskStatus = calculateRiskStatus(riskPercent);
		const heartAge = calculateHeartAge(score, gender);

		return {
			score,
			riskPercent,
			riskStatus,
			heartAge,
		};
	}

	const checkHandler = (values: any) => {
		if (isChecked) {
			form.resetFields();
			setChecked(false);
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth',
			});
			return;
		}
		console.log(values);
		setChecked(true);
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
		const res = calculateFramingham({ ...values });

		setResultValue(handleResult(res));
		setEstimated({
			title: 'Estimated risk rate / 10-year',
			value: `${
				res?.riskPercent === 1 ? '<' : res?.riskPercent === 30 ? '>' : ''
			} ${res.riskPercent}%`,
		});
	};

	const handleResult = (value: any) => {
		if (value?.riskPercent >= 20) {
			return {
				score: value?.score,
				item: dataIndicator[dataIndicator?.length - 1],
				pointer: 80,
			};
		}
		const filtered = dataIndicator?.filter(
			(item) => value.riskPercent >= item?.startValue,
		);
		const itemSelected = filtered?.[filtered?.length - 1];
		const itemIndex = dataIndicator?.findIndex(
			(itm) => itm?.start === itemSelected?.start,
		);
		const nextValue = dataIndicator?.[itemIndex + 1];
		const pointer =
			(value.riskPercent / nextValue?.startValue) *
			(itemSelected?.width + itemSelected?.start);

		return {
			pointer: value.riskPercent,
			score: value.score,
			item: itemSelected,
			// pointer,
		};
	};

	return (
		<AppLayout activePath={activePath}>
			<Card style={{ minHeight: 550 }}>
				<PageHeader
					title="Framingham Risk Score"
					onClick={() => history.goBack()}
				/>

				<Divider />

				{isChecked ? (
					<Score
						dataIndicator={dataIndicator}
						estimated={estimated}
						resultValue={resultValue}
					/>
				) : (
					<PreResult />
				)}

				<FormQuestion
					checkHandler={checkHandler}
					dataQuestions={dataQuestions}
					form={form}
					isChecked={isChecked}
				/>
			</Card>
		</AppLayout>
	);
}
