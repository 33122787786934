import React, { forwardRef, useState } from 'react';
import { Typography, Modal, Row, Button, Input, Divider, message } from 'antd';
import { useHistory } from 'react-router-dom';
import {
	apiCreateUpdatePartnerDoctor,
	apiGetPartnerDoctors,
} from '../../api/partnerDoctor';
import { useAuth } from '../../context/auth.context';

interface IProps {
	visible: boolean;
	setVisible: any;
}

const { Text, Title } = Typography;

const ModalAddClinic = forwardRef<any, IProps>(
	({ visible, setVisible }, ref) => {
		const { userId } = useAuth();
		const history = useHistory();

		const [isLoading, setIsLoading] = useState<boolean>(false);

		const [partner, setPartner] = useState<any>({
			partnerName: '',
			phone: '',
			address: '',
		});

		async function handleSave() {
			setIsLoading(true);
			try {
				const data = {
					partnerId: '',
					doctorId: userId || '',
					myPartner: partner,
				};
				const res = await apiCreateUpdatePartnerDoctor(data);
				const partnetRes = await apiGetPartnerDoctors(data.doctorId || '');

				const item = partnetRes.results
					.filter((item) => item.partnerId === res?.partnerId)
					.map((item) => {
						let availableDay: any = [];
						item.schedules?.map((sch) => {
							if (!availableDay.includes(sch.weekday)) {
								availableDay.push(sch.weekday);
							}
						});

						return {
							partnerId: item.partnerId,
							title: item.partner?.partnerName || '-',
							type: 'appointment',
							isEnabled: true,
							location: item.partner?.address,
							price: item.pricePerSession || 0,
							availableDay: availableDay,
							schedules: [],
						};
					});

				history.push('/app/setting/clinics-and-practice/details', item?.[0]);
				message.success('Success Save Add New Clinics');
			} catch (error) {
				console.error('ERROR Save Add New Clinic', error);
				message.error('Failed Save Add New Clinics');
			} finally {
				setIsLoading(false);
				setVisible(false);
			}
		}

		const questionList = [
			{
				title: 'Clinic Name',
				placeholder: 'Input Clinic Name',
				field: 'partnerName',
			},
			{
				title: 'Contact Numbere',
				placeholder: 'Input Contact Number',
				field: 'phone',
			},
			{
				title: 'Clinic Address',
				placeholder: 'Input Clinic Address',
				field: 'address',
				type: 'textarea',
			},
		];

		return (
			<Modal open={visible} footer={null} closable={false}>
				<Title style={{ marginBottom: 36, textAlign: 'center' }} level={5}>
					Add New Clinic
				</Title>
				{questionList.map((item) => (
					<Row
						style={{
							marginBottom: 12,
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
						}}
					>
						<Text
							style={{
								fontSize: 16,
								fontWeight: 400,
								marginBottom: 8,
							}}
						>
							{item.title}
						</Text>
						<Input
							type={item.type ? item.type : 'text'}
							size="middle"
							placeholder={item.placeholder}
							onChange={(e) =>
								setPartner((prev: any) => ({
									...prev,
									[item.field]: e.target.value,
								}))
							}
							style={{
								flex: 1,
								borderRadius: 8,
								marginBottom: 12,
								padding: '4px 14px',
							}}
						/>
					</Row>
				))}
				<Text
					style={{
						backgroundColor: '#DAEFFA',
						padding: '12px 8px',
						borderRadius: 8,
						fontSize: 14,
					}}
				>
					After save this hospital / clinic, you can set your schedule and price
				</Text>
				<Divider />
				<Row
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						marginTop: 20,
					}}
				>
					<Button
						loading={isLoading}
						onClick={() => setVisible(false)}
						size="large"
						style={{
							backgroundColor: '#F1F3F5',
							width: '48%',
							borderRadius: 12,
						}}
					>
						<Text style={{ fontSize: 17, fontWeight: 500 }}>Cancel</Text>
					</Button>
					<Button
						loading={isLoading}
						onClick={() => handleSave()}
						disabled={
							!partner.partnerName || !partner.phone || !partner.address
						}
						size="large"
						style={{ width: '48%', borderRadius: 12 }}
						type="primary"
					>
						<Text style={{ fontSize: 17, fontWeight: 500, color: '#fff' }}>
							Save
						</Text>
					</Button>
				</Row>
			</Modal>
		);
	},
);

export default ModalAddClinic;
