import { Button, Col, Divider, Modal, Row, Typography } from 'antd';
import { iconApproveConfirmation as IconApproveConfirmation } from '../../../assets/icons';
import './styles.less';

type Props = {
	isVisible: boolean;
	setVisibility: (state: boolean) => void;
	isLoading?: boolean;
	actionHandler: () => void;
};

const { Text, Paragraph } = Typography;

export default function ModalRejectReschedule(props: Props) {
	function cancelReject() {
		props.setVisibility(false);
	}

	return (
		<Modal
			className="modal"
			open={props.isVisible}
			footer={null}
			closable={false}
		>
			<div className="flex flex-col items-center">
				<div className="icon">
					<IconApproveConfirmation />
				</div>
				<Text style={{ marginBottom: 10 }} className="text-5 font-semibold">
					Reject Reschedule
				</Text>
				<Paragraph style={{ marginBottom: 0, textAlign: 'center' }}>
					Do you want to reject the request for rescheduling consultation?
					Consultation will still be carried out on the current schedule.
				</Paragraph>
			</div>
			<Divider style={{ margin: '12px 0px' }} />

			<Row gutter={24} className="mt-2.5">
				<Col span={12}>
					<Button
						type="text"
						size="large"
						onClick={cancelReject}
						className="text-3.5 text-secondary rounded-3 bg-gray-10 border-0 w-full font-bold"
					>
						<Text style={{ fontSize: 14, fontWeight: 700 }}>Not Now</Text>
					</Button>
				</Col>
				<Col span={12}>
					<Button
						type="primary"
						size="large"
						onClick={props.actionHandler}
						className="rounded-3 bg-primary w-full"
					>
						<Text style={{ fontSize: 14, fontWeight: 700, color: '#fff' }}>
							Approve
						</Text>
					</Button>
				</Col>
			</Row>
		</Modal>
	);
}
