import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AppLayout from '../../../../layout/AppLayout';
import PageHeader from '../../../../components/PageHeader';
import { iconGiftInvite as IconGiftInvite } from '../../../../assets/icons';
import { Card, Divider, message, Row, Spin, Typography, Col } from 'antd';
import COLORS from '../../../../assets/globalColors';
import {
	apiGetUplineReferralCode,
	apiSubmitReferral,
} from '../../../../api/userRefferal';
import SubmittedReferral from '../../../../components/Webapp/Referral/SubmittedReferral';
import InputReferralCode from '../../../../components/Webapp/Referral/InputReferralCode';
import { useAuth } from '../../../../context/auth.context';
const activePath = '/app/setting';
const { Text } = Typography;

export default function Referral() {
	const history = useHistory();
	const { userId } = useAuth();

	const [isLoadingSubmitReferral, setIsLoadingSubmitReferral] = useState(false);
	const [isSubmitReferralSuccess, setIsSubmitReferralSuccess] = useState(false);
	const [isReferralExist, setIsReferralExist] = useState(false);
	const [referralCode, setReferralCode] = useState<string>('');

	useEffect(() => {
		const checkReferralCode = async () => {
			if (!userId) return;

			setIsLoadingSubmitReferral(true);
			const referral = await apiGetUplineReferralCode({ userId });
			setIsLoadingSubmitReferral(false);

			if (referral) {
				setIsReferralExist(true);
				setReferralCode(referral);
				return;
			}
		};
		checkReferralCode();
	}, [userId]);

	async function submitReferralHandler() {
		if (!userId || !referralCode || referralCode.length === 0) {
			return;
		}

		setIsLoadingSubmitReferral(true);

		const res = await apiSubmitReferral({
			userId,
			referralCode,
		});

		setIsLoadingSubmitReferral(false);

		if (res?.code !== 'success') {
			message.error(res?.message);
			return;
		}
		setIsSubmitReferralSuccess(true);
	}

	return (
		<AppLayout activePath={activePath}>
			<Card style={{ paddingBottom: 110 }}>
				<PageHeader
					title="Input Referral Code"
					onClick={() => history.goBack()}
				/>
				<Divider />
				<div
					className="p-4 rounded-4.5"
					style={{ border: `1px solid ${COLORS.ash_500}` }}
				>
					<Row>
						<Col>
							<Row>
								<IconGiftInvite style={{ marginBottom: 16 }} />
							</Row>
							<Row>
								<Text style={{ fontSize: 32, fontWeight: 500 }}>
									Input Referral Code
								</Text>
							</Row>
							<Row>
								<Text style={{ fontSize: 16, color: COLORS.black_70 }}>
									We will review your data and send notification regarding your
									submission immeadietly
								</Text>
							</Row>
						</Col>
					</Row>
					<Divider
						dashed={true}
						style={{
							margin: '26px 0px 20px',
							color: COLORS.ash_500,
							borderWidth: 1.1,
						}}
					/>

					{isLoadingSubmitReferral ? (
						<Spin />
					) : isReferralExist || isSubmitReferralSuccess ? (
						<SubmittedReferral referralCode={referralCode} />
					) : (
						<InputReferralCode
							referralCode={referralCode}
							setReferralCode={setReferralCode}
							submitReferralHandler={submitReferralHandler}
						/>
					)}
				</div>
			</Card>
		</AppLayout>
	);
}
