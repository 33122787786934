import { TransactionConsultationProperties } from './transactionConsultation.type';
import { PersonalEvent } from './personalEvent.type';
import { TransactionHealthServiceProps } from './transactionHealthService.type';
import { PersonalConsultationProperties } from './personalConsultation.type';
import { EPWIdStatus } from './patient.type';

export type DetailItemAgenda = {
	name: string;
	date: string;
	desc?: string;
	reason?: string;
	startTime: string;
	endTime: string;
	detailPatient: {
		name: string;
		age: string;
		ageString: string;
		gender: 'Male' | 'Female';
		img?: string;
	};
	raw:
		| TransactionConsultationProperties
		| TransactionHealthServiceProps
		| PersonalConsultationProperties
		| PersonalEvent;
	settings?: {
		type: EScheduleTypes;
		name: string;
		color: string;
	};
};

export enum EScheduleTypes {
	'telemedicine' = 'telemedicine',
	'telemedicine-personal' = 'telemedicine-personal',
	'consult-now' = 'consult-now',
	'medical-service' = 'medical-service',
	'personal-event' = 'personal-event',
	'personal-event-blocked' = 'personal-event-blocked',
	'appointment' = 'appointment',
	'appointment-personal' = 'appointment-personal',
	'webinar' = 'webinar',
	'personal-event-local' = 'personal-event-local',
	'Standup 2.1' = 'Standup 2.1',
}

export type IScheduleData = {
	status?: any;
	name: string;
	date: string;
	desc?: string;
	reason?: string;
	startTime: string;
	endTime: string;
	detailPatient?: {
		name?: string;
		gender?: 'Male' | 'Female';
		age?: number;
		ageString?: string;
		img?: string;
		pwIdStatus?: EPWIdStatus;
		profilePic?: string;
	};
	raw?:
		| TransactionConsultationProperties
		| TransactionHealthServiceProps
		| PersonalConsultationProperties
		| PersonalEvent;
	isBlockingEvent?: boolean;
	isExpire?: boolean;
	detailDoctor?: {
		name: string;
		title: string;
		gender?: 'Male' | 'Female';
		age?: number;
		ageString?: string;
		img?: string;
	};
	attachment?: {
		patient: Array<{
			title: string;
			data: Array<{
				title: string;
				desc: string;
			}>;
		}>;
		doctor: Array<{
			title: string;
			data: Array<{
				title: string;
				desc: string;
			}>;
		}>;
	};
	location?: string;
	detailLocation?: string;
	imgPlace?: { uri: string } | any;
	iconPlace?: { uri: string } | any;
};

export type ISchedule = {
	name: EScheduleTypes;
	myRole: 'user' | 'patient' | 'doctor' | 'secretary';
	data: IScheduleData[];
};

export type IGooleCalendarSchedule = {
	kind: string;
	etag: string;
	summary: string;
	description: string;
	updated: Date;
	timeZone: string;
	accessRole: string;
	defaultReminders: [
		{
			method: string;
			minutes: number;
		},
	];
	nextPageToken: string;
	nextSyncToken: string;
	items: IGooleCalendarScheduleItem[];
};

export type IGooleCalendarScheduleItem = {
	kind: string;
	etag: string;
	id: string;
	status: string;
	htmlLink: string;
	created: string;
	updated: string;
	summary: string;
	description: string;
	location: string;
	colorId: string;
	creator: {
		id: string;
		email: string;
		displayName: string;
		self: boolean;
	};
	organizer: {
		id: string;
		email: string;
		displayName: string;
		self: boolean;
	};
	start: {
		date: string;
		dateTime: string;
		timeZone: string;
	};
	end: {
		date: string;
		dateTime: string;
		timeZone: string;
	};
	endTimeUnspecified: boolean;
	recurrence: [string];
	recurringEventId: string;
	originalStartTime: {
		date: string;
		dateTime: string;
		timeZone: string;
	};
	transparency: string;
	visibility: string;
	iCalUID: string;
	sequence: number;
	attendees: [
		{
			id: string;
			email: string;
			displayName: string;
			organizer: boolean;
			self: boolean;
			resource: boolean;
			optional: boolean;
			responseStatus: string;
			comment: string;
			additionalGuests: number;
		},
	];
	attendeesOmitted: boolean;
	extendedProperties: {
		private: {
			[key: string]: string;
		};
		shared: {
			[key: string]: string;
		};
	};
	hangoutLink: string;
	conferenceData: {
		createRequest: {
			requestId: string;
			conferenceSolutionKey: {
				type: string;
			};
			status: {
				statusCode: string;
			};
		};
		entryPoints: [
			{
				entryPointType: string;
				uri: string;
				label: string;
				pin: string;
				accessCode: string;
				meetingCode: string;
				passcode: string;
				password: string;
			},
		];
		conferenceSolution: {
			key: {
				type: string;
			};
			name: string;
			iconUri: string;
		};
		conferenceId: string;
		signature: string;
		notes: string;
	};
	gadget: {
		type: string;
		title: string;
		link: string;
		iconLink: string;
		width: number;
		height: number;
		display: string;
		preferences: {
			(key: string): string;
		};
	};
	anyoneCanAddSelf: boolean;
	guestsCanInviteOthers: boolean;
	guestsCanModify: boolean;
	guestsCanSeeOtherGuests: boolean;
	privateCopy: boolean;
	locked: boolean;
	reminders: {
		useDefault: boolean;
		overrides: [
			{
				method: string;
				minutes: number;
			},
		];
	};
	source: {
		url: string;
		title: string;
	};
	workingLocationProperties: {
		type: string;
		homeOffice: any;
		customLocation: {
			label: string;
		};
		officeLocation: {
			buildingId: string;
			floorId: string;
			floorSectionId: string;
			deskId: string;
			label: string;
		};
	};
	outOfOfficeProperties: {
		autoDeclineMode: string;
		declineMessage: string;
	};
	focusTimeProperties: {
		autoDeclineMode: string;
		declineMessage: string;
		chatStatus: string;
	};
	attachments: [
		{
			fileUrl: string;
			title: string;
			mimeType: string;
			iconLink: string;
			fileId: string;
		},
	];
	eventType: string;
};
