import { useEffect, useState } from 'react';
import { theme } from '../../../../assets/theme';
import FormInput, { EFormType } from '../../../Form/FormInput2';
import Separator from '../../../Separator';
import { ReactComponent as LabRequest } from '../../../../assets/icons/doc-multi-choice-with-pen.svg';
import EmptyList from '../../../EmptyList';
import IconButton from '../../../Button/IconButton';
import { CheckSquare, Plus } from 'react-feather';
import { useAuth } from '../../../../context/auth.context';
import {
	LabRequestItem,
	LabRequestProperties,
} from '../../../../types/labRequest.type';
import { LabRequestTemplateProperties } from '../../../../types/templateLabRequest';
import { Button } from '../../../Button/Button/Button';
import {
	apiCreateTemplateLabRequest,
	apiDeleteTemplateLabRequest,
	apiUpdateTemplateLabRequest,
} from '../../../../api/templateLabRequest';
import ModalAddProcedure from '../CreateLabRequest/Components/Modal/ModalAddProcedure';
import { IndividualHealthServiceProperties } from '../../../../types/individualHealthService.type';
import Modal from 'antd/lib/modal/Modal';
import { useHistory } from 'react-router-dom';
import COLORS from '../../../../assets/globalColors';
import { ICDItem } from '../../ICD/ICDItem';
import { ICDProperties } from '../../../../types/icd.type';
import ModalAddICD from '../CreateRx/Components/Modal/ModalAddICD';

interface Props {
	template?: Partial<LabRequestTemplateProperties>;
	onBack: () => void;
	setTemplate: React.Dispatch<
		React.SetStateAction<LabRequestTemplateProperties | undefined>
	>;
	icds?: ICDProperties[];
	setIcds: React.Dispatch<React.SetStateAction<ICDProperties[] | undefined>>;
}

export default function FormCreateLabRequestTemplate(props: Props) {
	const { user } = useAuth();
	const history = useHistory();
	const [isLoadingDelete, setIsLoadingDelete] = useState(false);
	const [isLoadingSave, setIsLoadingSave] = useState(false);
	const [showModalAddProcedure, setShowModalAddProcedure] = useState(false);
	const [showModalUseTemplate, setShowModalUseTemplate] = useState(false);
	const [showModalAddIcd, setShowModalAddIcd] = useState(false);

	const [templateName, setTemplateName] = useState<string>();
	const [labRequest, setLabRequest] = useState<LabRequestProperties>();

	useEffect(() => {
		if (!props.template) return;

		if (props.template.templateName) {
			setTemplateName(props.template.templateName);
		}

		setLabRequest((old) => ({
			...old,
			assessmentDiagnosis: props.template?.assessmentDiagnosis,
			procedures: props.template?.templateProcedures?.map((tp) => ({
				metaHealthService: tp.metaHealthService,
				healthServiceId: tp.healthServiceId,
			})),
			specialInstructions: props.template?.specialInstructions,
		}));
		if (props.template.icds) {
			props.setIcds(props.template.icds);
		}
	}, [props.template]);

	useEffect(() => {
		setShowModalAddIcd(false);
	}, [props.icds]);

	function removeProcedure(procedureId: string) {
		setLabRequest((old) => {
			const data = { ...old };
			data.procedures = old?.procedures?.filter(
				(procedure) => procedure.healthServiceId !== procedureId,
			);
			return data;
		});
	}

	async function saveLabRequestTemplate(type: string) {
		if (
			!templateName ||
			!labRequest?.assessmentDiagnosis ||
			!labRequest.procedures
		)
			return;

		setIsLoadingSave(true);

		let isSuccess = false;

		const procedures: LabRequestItem[] = [];
		for (const item of labRequest.procedures) {
			const lritem: LabRequestItem = {
				metaHealthService: item.metaHealthService,
				healthServiceId: item.healthServiceId,
			};
			procedures.push(lritem);
		}

		if (props.template?.templateLabRequestId) {
			const res = await apiUpdateTemplateLabRequest(
				props.template.templateLabRequestId,
				{
					doctorId: user?.userId,
					templateName: templateName,
					templateProcedures: procedures,
					assessmentDiagnosis: labRequest.assessmentDiagnosis,
					specialInstructions: labRequest.specialInstructions,
					icds: props.icds,
				},
			);
			if (res) {
				props.setTemplate(res);
				isSuccess = true;
			}
			if (type == 'use' && isSuccess) {
				history.push(
					'/app/rx-and-lab-request/create-lab-request/' +
						res?.templateLabRequestId,
				);
				console.log(res?.templateLabRequestId);
			} else {
				setIsLoadingSave(false);
			}
		} else {
			const res = await apiCreateTemplateLabRequest({
				doctorId: user?.userId,
				templateName,
				templateProcedures: procedures,
				assessmentDiagnosis: labRequest.assessmentDiagnosis,
				specialInstructions: labRequest.specialInstructions,
				icds: props.icds,
			});
			if (res) {
				props.setTemplate(res);
				isSuccess = true;
			}
			if (type == 'use' && isSuccess) {
				history.push(
					'/app/rx-and-lab-request/create-lab-request/' +
						res?.templateLabRequestId,
				);
				console.log(res?.templateLabRequestId);
			} else {
				setIsLoadingSave(false);
			}
		}

		if (type == 'save' && isSuccess) {
			props.onBack();
		} else {
			setIsLoadingSave(false);
		}
	}

	function appendProcedures(procedures: IndividualHealthServiceProperties[]) {
		const items: LabRequestItem[] = procedures.map((procedure) => ({
			healthServiceId: procedure.healthServiceId,
			metaHealthService: procedure,
		}));

		setLabRequest((old) => {
			const data = { ...old };
			if (data.procedures) {
				for (const procedure of items) {
					const index = data.procedures.findIndex(
						(oldProcedure) =>
							oldProcedure.healthServiceId === procedure.healthServiceId,
					);
					if (index >= 0) {
						data.procedures[index] = procedure;
					} else {
						data.procedures.push(procedure);
					}
				}
			} else {
				data.procedures = items;
			}
			return data;
		});
	}

	async function deleteTemplate() {
		if (!props.template?.templateLabRequestId) return;

		setIsLoadingDelete(true);
		const res = await apiDeleteTemplateLabRequest(
			props.template.templateLabRequestId,
		);

		if (res) {
			props.onBack();
		} else {
			setIsLoadingDelete(false);
		}
	}

	return (
		<>
			<form className="flex-1">
				<div className="py-6 px-3">
					<div>
						<div className="flex gap-x-3">
							<div className="pt-2 w-1/4">
								<p className="m-0 font-semibold text-4">Information</p>
							</div>
							<div className="flex-1">
								<FormInput
									required
									label="Template Name"
									placeholder="Template name"
									value={templateName}
									onChange={setTemplateName}
								/>
							</div>
						</div>
						<div className="py-4">
							<Separator />
						</div>
						<div className="flex gap-x-3">
							<div className="w-1/4">
								<p className="m-0 font-semibold text-4">Prescription</p>
							</div>
							<div className="flex-1">
								<FormInput
									required
									label="Assessment/Diagnosis"
									placeholder="Write here"
									type={EFormType.MULTI_LINE}
									rows={7}
									value={labRequest?.assessmentDiagnosis}
									onChange={(val) =>
										setLabRequest((old) => ({
											...old,
											assessmentDiagnosis: val,
										}))
									}
								/>
								<div className="py-2">
									<div className="flex justify-between">
										<p className="m-0 text-4">
											ICD 10
										</p>
										<div>
											<button
												onClick={() => setShowModalAddIcd(true)}
												type="button"
												className="border-none bg-transparent p-0 flex items-center gap-x-1 text-primary text-4 cursor-pointer"
											>
												<Plus width={24} height={24} />
												Add ICD
											</button>
										</div>
									</div>
									<div className="mb-5">
										{props.icds && props.icds?.length > 0 ? (
											<div className="mt-2">
												{props.icds?.map((icd, index) => (
													<ICDItem
														id={icd.id}
														value={icd.value}
														onDeleteItem={() => {
															const updatedIcds = props.icds
																? [...props.icds]
																: [];
															updatedIcds.splice(index, 1);
															props.setIcds(updatedIcds);
														}}
													/>
												))}
											</div>
										) : (
											<EmptyList
												icon={
													<LabRequest
														width={48}
														height={48}
														color={theme.colors.black50}
													/>
												}
												title="ICD 10 empty"
												description="ICD 10 that you add will appear here"
											/>
										)}
									</div>
								</div>
								<div className="py-2">
									<div className="flex justify-between">
										<p className="m-0 text-4">
											Diagnostic Procedure(s){' '}
											<span style={{ color: COLORS.red }}>*</span>
										</p>
										<div>
											<button
												onClick={() => setShowModalAddProcedure(true)}
												type="button"
												className="border-none bg-transparent p-0 flex items-center gap-x-1 text-primary text-4 cursor-pointer"
											>
												<Plus width={24} height={24} />
												Add Lab Request
											</button>
										</div>
									</div>
									<div>
										{labRequest?.procedures &&
										labRequest?.procedures.length > 0 ? (
											<div className="mt-2">
												{labRequest.procedures.map((procedure) => (
													<div
														key={procedure.healthServiceId}
														onClick={() =>
															removeProcedure(procedure.healthServiceId!)
														}
														style={{ cursor: 'pointer' }}
														className="py-2 flex gap-x-3"
													>
														<IconButton onClick={() => {}}>
															<CheckSquare
																width={20}
																height={20}
																color={theme.colors.primary}
															/>
														</IconButton>
														<p className="m-0">
															{procedure.metaHealthService.healthServiceName}
														</p>
													</div>
												))}
											</div>
										) : (
											<EmptyList
												icon={
													<LabRequest
														width={48}
														height={48}
														color={theme.colors.black50}
													/>
												}
												title="Lab Request Empty"
												description="Lab requests that you add will appear here"
											/>
										)}
									</div>
								</div>
								<FormInput
									label="Special Instructions"
									placeholder="Write here"
									type={EFormType.MULTI_LINE}
									rows={7}
									value={labRequest?.specialInstructions}
									onChange={(val) =>
										setLabRequest((old) => ({
											...old,
											specialInstructions: val,
										}))
									}
								/>
							</div>
						</div>
					</div>
				</div>
				<Separator />
				<div className="py-3 px-4 flex justify-between">
					<div className="w-44">
						{!!props.template?.templateLabRequestId && (
							<Button
								type="SOLIDASH"
								onClick={deleteTemplate}
								isLoading={isLoadingDelete}
								isDisable={
									!props.template?.templateLabRequestId || isLoadingSave
								}
							>
								Delete
							</Button>
						)}
					</div>
					<div className="w-44">
						<Button
							type="SOLID"
							onClick={() => setShowModalUseTemplate(true)}
							isLoading={isLoadingSave}
							isDisable={
								!templateName ||
								!labRequest?.assessmentDiagnosis ||
								!labRequest.procedures ||
								isLoadingDelete
							}
						>
							Next
						</Button>
					</div>
				</div>
			</form>
			<ModalAddICD
				isShow={showModalAddIcd}
				onCloseModal={() => setShowModalAddIcd(false)}
				icds={props.icds}
				setIcds={props.setIcds}
			/>
			<ModalAddProcedure
				isShow={showModalAddProcedure}
				selectedHealthServices={labRequest?.procedures}
				onCloseModal={() => setShowModalAddProcedure(false)}
				addProcedures={appendProcedures}
			/>

			<Modal
				open={showModalUseTemplate}
				onCancel={() => setShowModalUseTemplate(false)}
				footer={null}
				title={
					<p className="m-0 text-center font-semibold">Use this template?</p>
				}
			>
				<div className="flex p-3 justify-between">
					<div className="w-44">
						<Button
							type="SOLIDASH"
							onClick={() => saveLabRequestTemplate('use')}
						>
							Save & Use
						</Button>
					</div>
					<div className="w-44">
						<Button type="SOLID" onClick={() => saveLabRequestTemplate('save')}>
							Save
						</Button>
					</div>
				</div>
			</Modal>
		</>
	);
}
