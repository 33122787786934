import { useMemo } from 'react';
import FormInput, { EFormType } from '../../../../components/Form/FormInput';
import ToggleBtn from '../../../../components/ToggleBtn';
import './styles.less';
import moment from 'moment';
import { iconInformationCircle as IconInformationCircle } from '../../../../assets/icons';

export default function FormPersonal({
	dataForm,
	onChangeHandler,
	onSelectTimeStart,
	onSelectTimeEnd,
	disabledHoursAtEndTime,
	disabledMinutesAtEndTime,
}: any) {
	const renderBlocking = useMemo(
		() => (
			<div className="mt-2 mb-2 blocking-toggle">
				<div className="content-container">
					<div className="title-plc">
						<h5>Blocking</h5>
						<IconInformationCircle className="icon-information" />
					</div>
					<p>Set this events Blocking Schedule</p>
				</div>
				<ToggleBtn
					classNames="toggle"
					value={dataForm.isBlocking}
					clickHandler={() => {
						onChangeHandler(!dataForm.isBlocking, 'isBlocking');
					}}
				/>
			</div>
		),
		[dataForm.isBlocking],
	);

	return (
		<div className="form-personal-event">
			<FormInput
				required
				label="Title"
				value={dataForm?.title}
				placeholder="Enter title of the event"
				onChange={(val) => onChangeHandler(val, 'title')}
			/>
			{renderBlocking}
			<div className="flex">
				<div className="flex-3">
					<FormInput
						required
						classInput="w-full text-4 border border-solid border-ash-800 rounded-4 py-2.5 px-3"
						label="Start"
						placeholder="YYYY/MM/DD"
						value={dataForm?.startDate}
						type={EFormType.DATE_PICKER}
						onChangeDate={(date: any) => {
							onChangeHandler(
								date ? moment(date).format('YYYY-MM-DD') : '',
								'startDate',
							);
						}}
						onSelect={() => {
							onChangeHandler('', 'endDate');
							onChangeHandler('', 'endTime');
							onChangeHandler('', 'endTimeHours');
							onChangeHandler('', 'endTimeMinutes');
							onChangeHandler('', 'startTime');
							onChangeHandler('', 'startTimeHours');
							onChangeHandler('', 'startTimeMinutes');
						}}
					/>
				</div>
				<div className="ml-4 flex-1">
					<FormInput
						classInput="w-full text-4 border border-solid border-ash-800 rounded-4 py-2.5 px-3"
						label="At"
						placeholder="15:00"
						value={dataForm?.startTime}
						type={EFormType.TIME_PICKER}
						onChangeTime={(time: any) =>
							onChangeHandler(
								time ? moment(time).format('HH:mm') : '',
								'startTime',
							)
						}
						onSelect={(e) => onSelectTimeStart(e)}
					/>
				</div>
			</div>
			<div className="flex">
				<div className="flex-3">
					<FormInput
						required
						classInput="w-full text-4 border border-solid border-ash-800 rounded-4 py-2.5 px-3"
						label="End"
						placeholder="YYYY/MM/DD"
						value={dataForm?.endDate}
						type={EFormType.DATE_PICKER}
						onChangeDate={(date: any) =>
							onChangeHandler(
								date ? moment(date).format('YYYY-MM-DD') : '',
								'endDate',
							)
						}
						disabled={(current) => {
							return (
								// make sure to set to the beginning of the day as you compare !
								new Date(current).setHours(0, 0, 0, 0) <
								new Date(dataForm.startDate as any).setHours(0, 0, 0, 0)
							);
							// }
						}}
						onSelect={() => {
							onChangeHandler('', 'endTime');
							onChangeHandler('', 'endTimeHours');
							onChangeHandler('', 'endTimeMinutes');
						}}
					/>
				</div>
				<div className="ml-4 flex-1">
					<FormInput
						classInput="w-full text-4 border border-solid border-ash-800 rounded-4 py-2.5 px-3"
						label="At"
						placeholder="15:00"
						value={dataForm?.endTime}
						type={EFormType.TIME_PICKER}
						onChangeTime={(time: any) =>
							onChangeHandler(
								time ? moment(time).format('HH:mm') : '',
								'endTime',
							)
						}
						onSelect={(e) => onSelectTimeEnd(e)}
						disabledHours={() => disabledHoursAtEndTime()}
						disabledMinutes={() => disabledMinutesAtEndTime()}
					/>
				</div>
			</div>
			<FormInput
				type={EFormType.MULTI_LINE}
				className="mb-4"
				label="Description"
				value={dataForm?.description}
				placeholder="Input the activity"
				onChange={(val) => onChangeHandler(val, 'description')}
			/>
		</div>
	);
}
