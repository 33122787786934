import { useEffect, useState } from 'react';
import { ArrowLeft } from 'react-feather';
import { useHistory, useParams } from 'react-router-dom';
import { RxPrescriptionProperties } from '../../../../types/rxPrescription.type';
import { Button } from '../../../Button/Button/Button';
import Separator from '../../../Separator';
import PreviewDocument from '../../Components/PreviewDocument';
import DetailRxHistory from './DetailRxHistory';
import ListRxHistory from './ListRxHistory';
import { httpRequest } from '../../../../helpers/api';
import { ApiResponseDefault } from '../../../../types/apiResponse.type';

interface Props {
	prescription?: RxPrescriptionProperties;
}

export default function MainRxHistory(props?: Props) {
	const history = useHistory();

	// get document to detail id
	const { docId } = useParams<any>();

	const [step, setStep] = useState(0);
	const [title, setTitle] = useState('Rx History');

	const [selectedPrescription, setSelectedPrescription] =
		useState<RxPrescriptionProperties>();

	function nextStep() {
		const _step = step + 1;
		if (_step === 1) {
			setTitle('Detail');
			setStep(_step);
		} else if (_step === 2) {
			setTitle('Result');
			setStep(_step);
		}
	}

	function onBack() {
		const _step = step - 1;
		if (_step >= 0) {
			if (_step === 0) {
				setTitle('Rx History');
				history.push('/app/rx-and-lab-request/rx-history/');
			} else if (_step === 1) {
				setTitle('Detail');
			}
			setStep(_step);
		} else {
			history.goBack();
		}
	}

	function createRx() {
		history.push('/app/rx-and-lab-request/create-rx');
	}

	function editPrescription() {
		history.push(
			`/app/rx-and-lab-request/create-rx?docId=${selectedPrescription?.rxPrescriptionId}`,
		);
	}

	async function goToDetailRx() {
		const response = await httpRequest.get<
			ApiResponseDefault<RxPrescriptionProperties>
		>(`rx-prescriptions/${docId}`);
		setSelectedPrescription(response.data.payload);
		nextStep();
	}

	useEffect(() => {
		if (docId) {
			goToDetailRx();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [docId]);

	return (
		<div className="flex-1 flex flex-col">
			<div className="relative px-6 py-5.5 flex items-center">
				<button
					onClick={onBack}
					className="border-none bg-transparent p-0 absolute flex items-center gap-x-1 cursor-pointer"
				>
					<ArrowLeft width={20} height={20} />
					<p className="m-0 font-bold text-3.5">Back</p>
				</button>
				<h1 className="font-semibold text-6 m-0 w-full text-center">{title}</h1>
				{step === 0 ? (
					<div className="w-24 absolute right-6">
						<Button type="SOLID" onClick={createRx}>
							Create
						</Button>
					</div>
				) : (
					step === 1 && (
						<div className="w-24 absolute right-6">
							<Button type="TRANSPARENT" onClick={editPrescription}>
								Edit
							</Button>
						</div>
					)
				)}
			</div>
			<Separator />
			{step === 0 ? (
				<ListRxHistory
					setSelectedPrescription={setSelectedPrescription}
					nextStep={nextStep}
				/>
			) : step === 1 ? (
				<DetailRxHistory
					prescription={selectedPrescription!}
					nextStep={nextStep}
				/>
			) : step === 2 ? (
				<PreviewDocument
					docId={selectedPrescription!.rxPrescriptionId!}
					type="rx-prescription"
					buttonType="none"
				/>
			) : undefined}
		</div>
	);
}
