import { DoctorProperties } from './doctor.type';
import { PatientProperties } from './patient.type';
import { PatientInRelationProperties } from './patientInRelation.type';

export type EAdminRole =
	| 'admin'
	| 'customer'
	| 'customer_premium'
	| 'customer_trainer';

export enum EUserType {
	customer = 'customer',
	admin = 'admin',
}

export type EAdminStatus = 'active' | 'inactive';

export const initialUser: IUser = {
	userId: '',
	name: '',
	email: '',
	phone: '',
	userType: EUserType.customer,
};

export type IChangePassword = {
	oldPassword: '';
	newPassword: '';
	retypePassword: '';
};

export type IUser = {
	userId?: string;
	name?: string;
	email?: string;
	gender?: string;
	phone?: string;
	birthdate?: string;
	province?: string;
	city?: string;
	userType?: EUserType;
	firstName?: string;
	middleName?: string;
	lastName?: string;
	nickName?: string;
	profilePic?: string;
	status?: any;

	isEmailVerified?: boolean;
	isPhoneVerified?: boolean;

	doctor?: DoctorProperties;
	patient?: PatientProperties;
	patientInRelations?: PatientInRelationProperties[];
};
