import React, { forwardRef, useState } from 'react';
import {
	Typography,
	Modal,
	Row,
	Button,
	Input,
	Divider,
	message,
	Select,
	Empty,
} from 'antd';
import {
	iconBankAccount as IconBankAccount,
	iconInformationCircle as IconInformationCircle,
} from '../../assets/icons';
import { UserBankAccountProperties } from '../../types/bankAccount.type';
import { useDebounce } from 'use-debounce';
import useFetchList from '../../hooks/useFetchList';
import { httpRequest } from '../../helpers/api';
import { ApiResponseDefault } from '../../types/apiResponse.type';
import { useAuth } from '../../context/auth.context';

interface IProps {
	visible: any;
	setVisible: any;
	data?: any;
	fetchList: any;
}

const { Text, Title } = Typography;

const ModalAddEditBankAccount = forwardRef<any, IProps>(
	({ visible, setVisible, data, fetchList }, ref) => {
		const { userId } = useAuth();
		const [isShowModalDeleteConfirm, setIsShowModalDeleteConfirm] =
			useState<boolean>(false);

		const [isLoadingConfirm, setIsLoadingConfirm] = useState<boolean>(false);
		const [isDuplicate, setIsDuplicate] = React.useState<boolean>(false);

		const [searchBankList, setSearchBankList] = React.useState<string>('');

		const initialFormBankAccount = {
			bankCode: '',
			bankAccountNumber: '',
			bankAccountOWner: '',
		};

		const [formDataBankAccount, setFormDataBankAccount] = React.useState<any>(
			initialFormBankAccount,
		);

		React.useEffect(() => {
			data
				? setFormDataBankAccount({
						bankCode: data.bankCode,
						bankAccountNumber: data.bankAccountNumber,
						bankAccountOwner: data.bankAccountOwner,
				  })
				: setFormDataBankAccount(initialFormBankAccount);
		}, [visible]);

		const [validateValue] = useDebounce(formDataBankAccount, 500);

		const { data: userBankData } = useFetchList<UserBankAccountProperties>({
			endpoint: '/user-bank-accounts',
			limit: 100000,
		});

		const { data: bankList } = useFetchList<any>({
			endpoint: '/banks',
			limit: 100000,
		});

		React.useEffect(() => {
			const isFound = userBankData.filter(
				(item) =>
					(data ? item.userBankAccountId !== data.userBankAccountId : true) &&
					item.bankCode === validateValue?.bankCode &&
					item.bankAccountNumber === validateValue?.bankAccountNumber,
			);
			setIsDuplicate(isFound.length > 0 ? true : false);
		}, [validateValue]);

		const handleCancel = () => {
			setVisible(false);
			setIsDuplicate(false);
		};

		const handleLeftButtonClick = () => {
			data ? setIsShowModalDeleteConfirm(true) : handleCancel();
		};

		const handleRightButtonClick = async () => {
			setIsLoadingConfirm(true);
			try {
				const params = {
					userId: userId,
					...formDataBankAccount,
					isDefault: false,
				};
				if (data) {
					await httpRequest.patch<
						ApiResponseDefault<UserBankAccountProperties>
					>('/user-bank-accounts/' + data.userBankAccountId, params);
				} else {
					await httpRequest.post<ApiResponseDefault<UserBankAccountProperties>>(
						'/user-bank-accounts',
						params,
					);
				}
				message.success(`Success ${data ? 'Edit' : 'Add New'} Bank Account`);
			} catch (error) {
				console.log(error);
				message.error(`Failed ${data ? 'Edit' : 'Add New'} Bank Account`);
			} finally {
				fetchList();
				setIsLoadingConfirm(false);
				setVisible(false);
			}
		};

		const handleDelete = async () => {
			setIsLoadingConfirm(true);
			try {
				const res = await httpRequest.delete<
					ApiResponseDefault<{ isSuccess: boolean }>
				>('/user-bank-accounts/' + data.userBankAccountId);

				if (res.data?.payload?.isSuccess) {
					message.success(
						`Success Delete ${data.bankAccountNumber} Bank Account`,
					);
				}
			} catch (error) {
				message.success(`Failed Delete ${data.bankAccountNumber} Bank Account`);
			} finally {
				fetchList();
				setIsLoadingConfirm(false);
				setIsShowModalDeleteConfirm(false);
				setVisible(false);
			}
		};

		return (
			<Modal
				open={visible}
				footer={null}
				closable={true}
				onCancel={() => handleCancel()}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						marginBottom: 24,
					}}
				>
					<div
						style={{
							width: 64,
							height: 64,
							backgroundColor: '#F1F3F5',
							borderRadius: '50%',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							marginBottom: 24,
						}}
					>
						<IconBankAccount width={24} height={18} />
					</div>
					<Text style={{ fontSize: 20, fontWeight: 600 }}>
						Add New Bank Account
					</Text>
				</div>
				<Row
					style={{
						display: 'flex',
						backgroundColor: '#F1F3F5',
						alignItems: 'center',
						padding: '6px 12px',
						borderRadius: 8,
						marginBottom: 36,
					}}
				>
					<IconInformationCircle />
					<div
						style={{ display: 'flex', flexDirection: 'column', marginLeft: 12 }}
					>
						<Text style={{ fontSize: 14, fontWeight: 700, color: '#405261' }}>
							Verification required
						</Text>
						<Text style={{ fontSize: 14, fontWeight: 400, color: '#405261' }}>
							We will verify your bank account, this process will be fast.
						</Text>
					</div>
				</Row>
				<Row style={{ display: 'flex', flexDirection: 'column' }}>
					<Text style={{ fontSize: 16, fontWeight: 400, marginBottom: 8 }}>
						Bank Name
					</Text>
					<Select
						size="large"
						placeholder="Select Bank"
						autoClearSearchValue={true}
						notFoundContent={<Empty />}
						showSearch={true}
						onSearch={(val) => setSearchBankList(val)}
						searchValue={searchBankList}
						onChange={(e) =>
							setFormDataBankAccount({ ...formDataBankAccount, bankCode: e })
						}
						value={
							bankList.find(
								(item: any) => item.bankId === formDataBankAccount?.bankCode,
							)?.bankId
						}
						style={{ marginBottom: 12, fontSize: 18, width: '100%' }}
						options={bankList.map((item: any) => ({
							value: item.bankId,
							label: item.bankName,
						}))}
					></Select>
					<Text style={{ fontSize: 16, fontWeight: 400, marginBottom: 8 }}>
						Account Number
					</Text>
					<Input
						size="middle"
						placeholder="Input account number"
						onChange={(e) =>
							setFormDataBankAccount({
								...formDataBankAccount,
								bankAccountNumber: e.target.value,
							})
						}
						value={formDataBankAccount?.bankAccountNumber}
						style={{ borderRadius: 6, marginBottom: 12, padding: '4px 12px' }}
					/>
					<Text style={{ fontSize: 16, fontWeight: 400, marginBottom: 8 }}>
						Full name in your Bank Account
					</Text>
					<Input
						size="middle"
						placeholder="Input full name"
						onChange={(e) =>
							setFormDataBankAccount({
								...formDataBankAccount,
								bankAccountOwner: e.target.value,
							})
						}
						value={formDataBankAccount?.bankAccountOwner}
						style={{ borderRadius: 6, marginBottom: 12, padding: '4px 12px' }}
					/>
				</Row>
				{isDuplicate && (
					<Text style={{ color: '#D7373F', fontSize: 14 }}>
						Bank account already exist
					</Text>
				)}
				<Divider />
				<Row
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						marginTop: 20,
					}}
				>
					<Button
						loading={isLoadingConfirm}
						onClick={() => handleLeftButtonClick()}
						size="large"
						style={{
							backgroundColor: '#F1F3F5',
							width: '48%',
							borderRadius: 12,
						}}
					>
						<Text style={{ fontSize: 17, fontWeight: 500 }}>
							{data ? 'Delete' : 'Cancel'}
						</Text>
					</Button>
					<Button
						loading={isLoadingConfirm}
						disabled={
							!formDataBankAccount.bankCode ||
							!formDataBankAccount.bankAccountNumber ||
							!formDataBankAccount.bankAccountOwner ||
							isDuplicate
						}
						onClick={() => handleRightButtonClick()}
						size="large"
						style={{ width: '48%', borderRadius: 12 }}
						type="primary"
					>
						<Text style={{ fontSize: 17, fontWeight: 500, color: '#fff' }}>
							{data ? 'Save' : 'Submit'}
						</Text>
					</Button>
				</Row>
				<Modal open={isShowModalDeleteConfirm} footer={null} closable={false}>
					<Title level={5}>Delete Confirmation</Title>
					<Row style={{ display: 'flex', flexDirection: 'column' }}>
						<Text style={{ fontSize: 16, fontWeight: 400, marginBottom: 8 }}>
							Are you sure to delete this{' '}
							{formDataBankAccount.bankAccountNumber} bank account?
						</Text>
					</Row>
					<Divider />
					<Row style={{ display: 'flex', justifyContent: 'space-between' }}>
						<Button
							loading={isLoadingConfirm}
							onClick={() => setIsShowModalDeleteConfirm(false)}
							size="large"
							style={{
								backgroundColor: '#F1F3F5',
								width: '48%',
								borderRadius: 12,
							}}
						>
							<Text style={{ fontSize: 17, fontWeight: 500 }}>Cancel</Text>
						</Button>
						<Button
							loading={isLoadingConfirm}
							onClick={() => handleDelete()}
							size="large"
							style={{ width: '48%', borderRadius: 12 }}
							type="primary"
						>
							<Text style={{ fontSize: 17, fontWeight: 500, color: '#fff' }}>
								Confirm
							</Text>
						</Button>
					</Row>
				</Modal>
			</Modal>
		);
	},
);

export default ModalAddEditBankAccount;
