import React, { useRef, useState, createContext } from 'react';
import { Carousel } from 'antd';
import styled from 'styled-components';
import AppLayout from '../../../layout/AppLayout';
import Menstruation from '../../../components/AppCalculator/MenstruationCalendar/IndexMenstruation';
import PregnancyDueDate from '../../../components/AppCalculator/MenstruationCalendar/IndexPregnancyDueDate';
import MenstruationLog from '../../../components/AppCalculator/MenstruationCalendar/IndexMenstruationLog';
import ModalMenstruationCalendar from '../../../components/AppCalculator/MenstruationCalendar/ModalMenstruationCalendar';

const activePath = '/app/medical-calculator';

export type DateType = {
	mensCycle?: number;
	mensLong?: number;
	currDates?: Date[] | undefined | null;
	mensStart?: Date[] | undefined | null;
	mensEnd?: Date[] | undefined | null;
	nextOvul?: Date[] | undefined | null;
	fertileStart?: Date[] | undefined | null;
	fertileEnd?: Date[] | undefined | null;
};

type DateContextProvider = {
	dates: DateType | undefined | null;
	setDates: React.Dispatch<React.SetStateAction<DateType | null>>;
};

export const DatesContext = createContext({} as DateContextProvider);

export default function MedicalCalculatorScreenMens() {
	const [dates, setDates] = useState<DateType | null>(null);

	const bannerRef: any = useRef();

	const backBtnHandler = () => {
		bannerRef.current.prev();
	};

	const nextBtnHandler = () => {
		bannerRef.current.next();
	};

	return (
		<AppLayout activePath={activePath}>
			<ModalMenstruationCalendar />
			<DatesContext.Provider value={{ dates, setDates }}>
				<ScreenWrapper>
					<Carousel dots={false} style={{ height: '100%' }} ref={bannerRef}>
						<Menstruation nextBtn={() => nextBtnHandler()} />
						<PregnancyDueDate
							nextBtn={() => nextBtnHandler()}
							backBtn={() => backBtnHandler()}
						/>
						<MenstruationLog backBtn={() => backBtnHandler()} />
					</Carousel>
				</ScreenWrapper>
			</DatesContext.Provider>
		</AppLayout>
	);
}

const ScreenWrapper = styled.div`
	border-radius: 12px;
	overflow: hidden;
`;
