import { Button, Col, Divider, Modal, Row, Typography } from 'antd';
import { iconDeleteOutline as IconDeleteOutline } from '../../../assets/icons';

const { Text, Paragraph } = Typography;

type Props = {
	isVisible: boolean;
	setVisibility: React.Dispatch<React.SetStateAction<boolean>>;
	selectedPartner?: {
		partnerId: string;
		partnerName: string;
		active: boolean;
		type: 'TELEMEDICINE' | 'INPERSON';
	};
	cancel: () => void;
	confirm: (
		partnerId: string,
		type: 'TELEMEDICINE' | 'INPERSON',
		status: boolean,
	) => Promise<void>;
};

export default function ModalTogglePartner(props: Props) {
	function cancel() {
		props.cancel();
	}

	function confirm() {
		if (!props.selectedPartner?.partnerId || !props.selectedPartner?.type) {
			return;
		}
		props.confirm(
			props.selectedPartner.partnerId,
			props.selectedPartner.type,
			!props.selectedPartner.active,
		);
		props.setVisibility(false);
	}

	return (
		<Modal
			className="modal"
			open={props.isVisible}
			footer={null}
			closable={false}
		>
			<div className="flex flex-col items-center">
				<div className="icon">
					<IconDeleteOutline />
				</div>

				<Text style={{ marginBottom: 10 }} className="text-5 font-semibold">
					Confirm {props.selectedPartner?.active ? 'Remove' : 'Add'} Partner
				</Text>
				<Paragraph style={{ marginBottom: 0 }}>
					Are you sure you want to{' '}
					{props.selectedPartner?.active ? 'remove' : 'add'}{' '}
					{props.selectedPartner?.partnerName}?
				</Paragraph>
			</div>
			<Divider style={{ margin: '12px 0px' }} />
			<Row gutter={24} className="mt-2.5">
				<Col span={12}>
					<Button
						type="ghost"
						size="large"
						onClick={cancel}
						className="text-3.5 text-secondary rounded-3 bg-gray-10 border-0 w-full font-bold"
					>
						Cancel
					</Button>
				</Col>
				<Col span={12}>
					<Button
						type="primary"
						size="large"
						style={{
							color: '#fff',
						}}
						onClick={confirm}
						className="text-3.5 text-white rounded-3 bg-primary w-full font-bold"
					>
						Confirm
					</Button>
				</Col>
			</Row>
		</Modal>
	);
}
