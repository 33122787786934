import { Conversation } from '@twilio/conversations';
import { useEffect, useState } from 'react';
import { apiGetListDoctorDocumentsByConsultations } from '../../../../api/doctorDocument';
import { apiGetLabRequestFromTrxId } from '../../../../api/labRequest';
import { apiGetMedicalNotes } from '../../../../api/medicalRecord';
import { apiGetRxPrescriptions } from '../../../../api/rxPrescription';
import {
	DoctorDocumentProperties,
	EMetaType,
} from '../../../../types/doctorDocument.type';
import { LabRequestProperties } from '../../../../types/labRequest.type';
import { MedicalRecordProperties } from '../../../../types/medicalRecord.type';
import { RxPrescriptionProperties } from '../../../../types/rxPrescription.type';
import { TransactionConsultationProperties } from '../../../../types/transactionConsultation.type';
import { IUser } from '../../../../types/user.type';
import CustomScrollDiv from '../../../CustomScrollDiv';
import TeleconferenceChat from '../TeleconferenceRoom/TeleconferenceChat';
import { ESidebarMode } from '../TeleconferenceRoom/TeleconferenceRoom';
import ModalEndConsultation from './ModalEndConsultation';
import SidebarClearance from './SidebarClearance';
import SidebarConsultationDefault, {
	EConsultSidebarContent,
} from './SidebarConsultationDefault';
import SidebarFitToWork from './SidebarFitToWork';
import SidebarLabRequest from './SidebarLabRequest/SidebarLabRequest';
import SidebarMedicalCertificate from './SidebarMedicalCertificate';
import SidebarMedicalId from './SidebarMedicalId';
import SidebarMedicalNotes from './SidebarMedicalNotes';
import SidebarOwnTemplate from './SidebarOwnTemplate';
import SidebarPrescription from './SidebarPrescription/SidebarPrescription';

interface Props {
	conversation?: Conversation;
	sidebarMode: ESidebarMode;
	transactionConsultation?: TransactionConsultationProperties;
	isAlwaysOpenSidebar?: boolean;
	setSidebarMode: React.Dispatch<
		React.SetStateAction<ESidebarMode | undefined>
	>;
	endTeleconference: () => void;
}

export default function ConsultationSidebar(props: Props) {
	const [consultSidebarContent, setConsultSidebarContent] =
		useState<EConsultSidebarContent>();

	const [medicalNotes, setMedicalNotes] = useState<MedicalRecordProperties>(
		props.transactionConsultation?.medicalRecord as MedicalRecordProperties,
	);
	const [rxPrescription, setRxPrescription] =
		useState<RxPrescriptionProperties>();
	const [labRequest, setLabRequest] = useState<LabRequestProperties>();
	const [medicalCertificate, setMedicalCertificate] =
		useState<DoctorDocumentProperties>();
	const [clearance, setClearance] = useState<DoctorDocumentProperties>();
	const [fitToWork, setFitToWork] = useState<DoctorDocumentProperties>();
	const [ownTemplate, setOwnTemplate] = useState<DoctorDocumentProperties>();
	const [isModalEndConsultVisible, setIsModalEndConsultVisible] =
		useState(false);

	useEffect(() => {
		if (medicalNotes) return;

		(async () => {
			if (!props.transactionConsultation?.patientId) return;

			const res = await apiGetMedicalNotes(
				props.transactionConsultation.patientId,
			);
			if (res) {
				setMedicalNotes(res);
			}
		})();
	}, [medicalNotes]);

	useEffect(() => {
		(async () => {
			if (!props.transactionConsultation?.transactionConsultationId) return;

			const res = await apiGetRxPrescriptions([
				props.transactionConsultation.transactionConsultationId,
			]);
			if (res) {
				setRxPrescription(res[0]);
			}
		})();

		(async () => {
			if (!props.transactionConsultation?.transactionConsultationId) return;

			const res = await apiGetLabRequestFromTrxId([
				props.transactionConsultation.transactionConsultationId,
			]);
			if (res) {
				setLabRequest(res[0]);
			}
		})();

		(async () => {
			if (!props.transactionConsultation?.transactionConsultationId) return;
			console.log(props.transactionConsultation.transactionConsultationId);
			const res = await apiGetListDoctorDocumentsByConsultations([
				props.transactionConsultation.transactionConsultationId,
			]);
			if (res) {
				console.log(res);
				for (const doctorDocument of res) {
					switch (doctorDocument.type) {
						case EMetaType.MEDICAL_CERTIFICATE:
							setMedicalCertificate(doctorDocument);
							break;
						case EMetaType.CLEARANCE:
							setClearance(doctorDocument);
							break;
						case EMetaType.FIT_TO_WORK:
							setFitToWork(doctorDocument);
							break;
						case EMetaType.OTHERS:
							setOwnTemplate(doctorDocument);
							break;
					}
				}
			}
		})();
	}, [props.transactionConsultation?.transactionConsultationId]);

	return (
		<div className="ml-auto rounded-l-6 bg-white p-4 w-106 flex-1 min-h-0 flex flex-col mb-40 lg:mb-0">
			{!props.isAlwaysOpenSidebar && (
				<div className="mb-4 p-3 border-b-solid border-b border-gray-10">
					<div className="flex p-1 rounded-full border-solid border border-black-10">
						<button
							onClick={() => props.setSidebarMode(ESidebarMode.CHAT)}
							className={
								'flex-1 border-none rounded-full cursor-pointer ' +
								(props.sidebarMode === 'CHAT'
									? 'bg-primary text-white'
									: 'bg-white text-black')
							}
						>
							Chat
						</button>
						<button
							onClick={() => props.setSidebarMode(ESidebarMode.CONSULTATION)}
							className={
								'flex-1 border-none rounded-full cursor-pointer ' +
								(props.sidebarMode === 'CONSULTATION'
									? 'bg-primary text-white'
									: 'bg-white text-black')
							}
						>
							Consultation
						</button>
					</div>
				</div>
			)}

			{props.isAlwaysOpenSidebar && (
				<div className="p-3">
					<p className="m-0 text-5 font-medium">Consultation</p>
				</div>
			)}

			<CustomScrollDiv className="relative flex overflow-auto w-full h-full">
				<div className="w-full">
					{props.sidebarMode === 'CHAT' && (
						<TeleconferenceChat
							otherParticipant={
								props.transactionConsultation?.metaPatient as IUser
							}
							conversation={props.conversation}
						/>
					)}
					{props.sidebarMode === 'CONSULTATION' &&
						(!consultSidebarContent ? (
							<SidebarConsultationDefault
								transactionConsultation={props.transactionConsultation}
								isExistHistoryPhysicalFindings={
									!!medicalNotes?.historyPhysicalFindings
								}
								isExistAssesmentDiagnosis={!!medicalNotes?.diagnosis}
								isExistPlanForManagement={!!medicalNotes?.planForManagement}
								isExistRxPrescription={!!rxPrescription?.rxPrescriptionId}
								isExistLabRequest={!!labRequest?.labRequestId}
								isExistMedicalCertificate={
									!!medicalCertificate?.doctorDocumentId
								}
								isExistClearance={!!clearance?.doctorDocumentId}
								isExistFitToWork={!!fitToWork?.doctorDocumentId}
								isExistOwnTemplate={!!ownTemplate?.doctorDocumentId}
								onClickMenu={(menu: EConsultSidebarContent) =>
									setConsultSidebarContent(menu)
								}
								setIsModalEndConsultVisible={setIsModalEndConsultVisible}
							/>
						) : consultSidebarContent === EConsultSidebarContent.MEDICAL_ID ? (
							<SidebarMedicalId
								patientId={props.transactionConsultation?.patientId}
								onBack={() => setConsultSidebarContent(undefined)}
							/>
						) : consultSidebarContent ===
						  EConsultSidebarContent.HISTORY_PHYSICAL_FINDINGS ? (
							<SidebarMedicalNotes
								onBack={() => setConsultSidebarContent(undefined)}
								title="History/Physical Findings"
								value={medicalNotes?.historyPhysicalFindings}
								medicalRecordId={medicalNotes?.medicalRecordId}
								setMedicalNotes={setMedicalNotes}
								consultSidebarContent={consultSidebarContent}
							/>
						) : consultSidebarContent ===
						  EConsultSidebarContent.ASSESSMENT_DIAGNOSIS ? (
							<SidebarMedicalNotes
								onBack={() => setConsultSidebarContent(undefined)}
								title="Assesment/Diagnosis"
								value={medicalNotes?.diagnosis}
								medicalRecordId={medicalNotes?.medicalRecordId}
								setMedicalNotes={setMedicalNotes}
								consultSidebarContent={consultSidebarContent}
							/>
						) : consultSidebarContent ===
						  EConsultSidebarContent.PLAN_FOR_MANAGEMENT ? (
							<SidebarMedicalNotes
								onBack={() => setConsultSidebarContent(undefined)}
								title="Plan for Management"
								value={medicalNotes?.planForManagement}
								medicalRecordId={medicalNotes?.medicalRecordId}
								setMedicalNotes={setMedicalNotes}
								consultSidebarContent={consultSidebarContent}
							/>
						) : consultSidebarContent ===
						  EConsultSidebarContent.PRESCRIPTION ? (
							<SidebarPrescription
								transactionConsultationId={
									props.transactionConsultation?.transactionConsultationId
								}
								patientProfile={
									props.transactionConsultation?.metaPatient as IUser
								}
								rxPrescription={rxPrescription}
								setRxPrescription={setRxPrescription}
								onBack={() => setConsultSidebarContent(undefined)}
							/>
						) : consultSidebarContent === EConsultSidebarContent.LAB_REQUEST ? (
							<SidebarLabRequest
								transactionConsultationId={
									props.transactionConsultation?.transactionConsultationId
								}
								patientProfile={
									props.transactionConsultation?.metaPatient as IUser
								}
								labRequest={labRequest}
								setLabRequest={setLabRequest}
								onBack={() => setConsultSidebarContent(undefined)}
							/>
						) : consultSidebarContent ===
						  EConsultSidebarContent.MEDICAL_CERTIFICATE ? (
							<SidebarMedicalCertificate
								patientProfile={
									props.transactionConsultation?.metaPatient as IUser
								}
								transactionConsultationId={
									props.transactionConsultation?.transactionConsultationId
								}
								medicalCertificate={medicalCertificate}
								setMedicalCertificate={setMedicalCertificate}
								onBack={() => setConsultSidebarContent(undefined)}
							/>
						) : consultSidebarContent === EConsultSidebarContent.CLEARANCE ? (
							<SidebarClearance
								patientProfile={
									props.transactionConsultation?.metaPatient as IUser
								}
								transactionConsultationId={
									props.transactionConsultation?.transactionConsultationId
								}
								clearance={clearance}
								setClearance={setClearance}
								onBack={() => setConsultSidebarContent(undefined)}
							/>
						) : consultSidebarContent === EConsultSidebarContent.FIT_TO_WORK ? (
							<SidebarFitToWork
								patientProfile={
									props.transactionConsultation?.metaPatient as IUser
								}
								transactionConsultationId={
									props.transactionConsultation?.transactionConsultationId
								}
								fitToWork={fitToWork}
								setFitToWork={setFitToWork}
								onBack={() => setConsultSidebarContent(undefined)}
							/>
						) : consultSidebarContent ===
						  EConsultSidebarContent.OWN_TEMPLATE ? (
							<SidebarOwnTemplate
								patientProfile={
									props.transactionConsultation?.metaPatient as IUser
								}
								transactionConsultationId={
									props.transactionConsultation?.transactionConsultationId
								}
								ownTemplate={ownTemplate}
								setOwnTemplate={setOwnTemplate}
								onBack={() => setConsultSidebarContent(undefined)}
							/>
						) : undefined)}
				</div>
			</CustomScrollDiv>
			<ModalEndConsultation
				isVisible={isModalEndConsultVisible}
				setVisibility={setIsModalEndConsultVisible}
				actionHandler={props.endTeleconference}
			/>
		</div>
	);
}
