import { ChevronRight } from 'react-feather';
import { theme } from '../../../../../assets/theme';
import { EMetaType } from '../../../../../types/doctorDocument.type';
import { EPaymentStatus } from '../../../../../types/payment.type';
import Separator from '../../../../Separator';

interface Props {
	patientName: string;
	prescribedOn: string;
	validUntil: string;
	price?: number;
	paymentStatus?: EPaymentStatus;
	type?: EMetaType;
	onClick: () => void;
}

export default function ItemDoctorDocument(props: Props) {
	return (
		<button
			onClick={props.onClick}
			className="p-0 bg-transparent text-left border-none py-4 w-full cursor-pointer"
		>
			<div className="flex w-full">
				<div className="w-full">
					<div className="flex items-center gap-x-3">
						<p className="m-0 text-4.5">{props.patientName}</p>
						<div className="rounded-2 border border-solid border-primary bg-primary-10">
							<p className="m-0 text-primary text-3 px-1.5">In-App</p>
						</div>
					</div>
					<div className="mt-3.5 flex gap-x-4">
						<div className="flex-2">
							<p className="m-0 text-3.5 text-black-60">Prescribed on</p>
							<p className="m-0 mt-2 text-4">{props.prescribedOn}</p>
						</div>
						<div className="flex-2">
							<p className="m-0 text-3.5 text-black-60">Valid Until</p>
							<p className="m-0 mt-2 text-4">{props.validUntil}</p>
						</div>
						<div className="flex-1">
							<p className="m-0 text-3.5 text-black-60">Price</p>
							<p className="m-0 mt-2 text-4">₱{props.price ?? 0}</p>
						</div>
						<div className="flex-1">
							<p className="m-0 text-3.5 text-black-60">Status</p>
							<div
								className={`mt-2 w-15 border border-solid rounded-1.5 ${
									!props.price
										? 'border-gray-80 bg-gray-10 text-gray-100'
										: props.paymentStatus === EPaymentStatus.PAID_FULL
										? 'border-green bg-green-10 text-green'
										: props.paymentStatus ===
												EPaymentStatus.CANCELLED_BY_SYSTEM ||
										  props.paymentStatus === EPaymentStatus.CANCELLED_BY_USER
										? 'border-rd bg-red-10 text-red'
										: 'border-yellow bg-yellow-10 text-yellow-100'
								}`}
							>
								<p className="m-0 text-3 text-center">
									{!props.price
										? 'Free'
										: props.paymentStatus === EPaymentStatus.PAID_FULL
										? 'Cleared'
										: props.paymentStatus ===
												EPaymentStatus.CANCELLED_BY_SYSTEM ||
										  props.paymentStatus === EPaymentStatus.CANCELLED_BY_USER
										? 'Cancelled'
										: 'Pending'}
								</p>
							</div>
						</div>
						<div className="flex-1">
							<p className="m-0 text-3.5 text-black-60">Type</p>
							<div
								className={`mt-2 w-32 rounded-full ${
									props.type === EMetaType.MEDICAL_CERTIFICATE
										? 'bg-blue-10 text-blue'
										: props.type === EMetaType.CLEARANCE
										? 'bg-green-10 text-green'
										: props.type === EMetaType.FIT_TO_WORK
										? 'bg-purple-10 text-purple'
										: 'bg-gray-10 text-gray-100'
								}`}
							>
								<p className="m-0 text-3 text-center">{getType(props.type)}</p>
							</div>
						</div>
					</div>
				</div>
				<div>
					<ChevronRight width={20} height={20} color={theme.colors.black30} />
				</div>
			</div>
			<Separator />
		</button>
	);
}

function getType(type?: EMetaType) {
	return type
		?.split('_')
		.map((t) => t[0].toUpperCase() + t.slice(1).toLowerCase())
		.join(' ');
}
