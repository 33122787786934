export interface PartnerDoctorPrice {
	partnerId: string;
	doctorId: string;
	pricePerSession: number;
	createdAt?: string;
	updatedAt?: string;
}

export enum EPartnerType {
	ONLINE_CLINIC = 'ONLINE_CLINIC',
	CONSULT_NOW = 'CONSULT_NOW',

	HOSPITAL = 'HOSPITAL',
	CLINIC = 'CLINIC',
	LABORATORY = 'LABORATORY',
}

export type PartnerProperties = {
	partnerId: string;
	partnerType: EPartnerType; // 'HOSPITAL';
	partnerName: string; // 'RS Juara';
	address?: string;
	geolocation?: {
		latitude: number;
		longitude: number;
	};
	isPublished?: boolean;
	isConfirmedPartner?: boolean;
	phone?: string;
	email?: string;
	imageLogoURL?: string;
	imageCoverURL?: string;

	doctors?: PartnerDoctorPrice[];
	createdAt?: string;
	updatedAt?: string;

	isWorkplace?: boolean;
};

export type ApiPartnersQuery = {
	search?: string;
	searchByName?: string;
	limit?: number;
	offset?: number;
	isPublished?: 0 | 1;
	isConfirmedPartner?: 0 | 1;
	includePartnerTypes?: string;
};
