import Card from '../../components/Card';
import AppLayout from '../../layout/AppLayout';
import { useEffect, useState } from 'react';
import MainDoctorDocument, {
	DoctorDocumentNav,
} from '../../components/Webapp/DoctorDocument/MainDoctorDocument';
import MainDoctorDocumentHistory from '../../components/Webapp/DoctorDocument/DoctorDocumentHistory/MainDoctorDocumentHistory';
import { useHistory, useParams } from 'react-router-dom';
import MainCreateDoctorDocument from '../../components/Webapp/DoctorDocument/CreateDoctorDocument/MainCreateDoctorDocument';
import { EMetaType } from '../../types/doctorDocument.type';
import MainOwnTemplate from '../../components/Webapp/DoctorDocument/OwnTemplate/MainOwnTemplate';

const activePath = '/app/doctor-documents';

interface Params {
	documentType?: string;
	docId?: string;
}

export default function DoctorDocumentsScreen() {
	const history = useHistory();
	const { documentType, docId } = useParams<Params>();

	const [nav, setNav] = useState(DoctorDocumentNav.Main);
	const [title, setTitle] = useState('Doctor Document');
	const [type, setType] = useState<EMetaType>();

	useEffect(() => {
		if (!documentType) {
			setNav(DoctorDocumentNav.Main);
			setTitle('Doctor Document');
			return;
		}

		const h = documentType
			.split('-')
			.map((t) => t[0].toUpperCase() + t.slice(1));

		const nav = h.join('-') as DoctorDocumentNav;
		let title = '';
		switch (nav) {
			case DoctorDocumentNav.History:
				title = 'Doctor Document History';
				break;
			case DoctorDocumentNav.FormOwnTemplate:
				title = 'Own Template';
				break;
			case DoctorDocumentNav.MedicalCertificate:
				setType(EMetaType.MEDICAL_CERTIFICATE);
				break;
			case DoctorDocumentNav.Clearance:
				setType(EMetaType.CLEARANCE);
				break;
			case DoctorDocumentNav.FitToWork:
				setType(EMetaType.FIT_TO_WORK);
				break;
			case DoctorDocumentNav.OwnTemplate:
				setType(EMetaType.OTHERS);
				break;
		}
		if (!title) {
			title = h.join(' ');
		}
		setTitle(title);
		setNav(nav);
	}, [documentType]);

	function addNav(nav: DoctorDocumentNav) {
		history.push(`${activePath}/${nav.toLowerCase()}`);
	}

	return (
		<AppLayout activePath={activePath}>
			<Card className="flex flex-col">
				{nav === DoctorDocumentNav.Main ? (
					<MainDoctorDocument title={title} addNav={addNav} />
				) : nav === DoctorDocumentNav.MedicalCertificate ||
				  nav === DoctorDocumentNav.Clearance ||
				  nav === DoctorDocumentNav.FitToWork ||
				  nav === DoctorDocumentNav.FormOwnTemplate ? (
					<MainCreateDoctorDocument
						name={title}
						type={type}
						docId={docId}
						onBack={history.goBack}
					/>
				) : nav === DoctorDocumentNav.OwnTemplate ? (
					<MainOwnTemplate />
				) : nav === DoctorDocumentNav.History ? (
					<MainDoctorDocumentHistory title={title} />
				) : (
					<></>
				)}
			</Card>
		</AppLayout>
	);
}
