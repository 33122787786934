import React from 'react';
import Header from '../Header';
import ResultsCalculator from './ResultsCalculator';

type Props = {
	nextBtn: () => void;
	backBtn: () => void;
};

export default function IndexPregnancyDueDate(Props: Props) {
	return (
		<div style={{ minHeight: '100vh' }}>
			<Header
				title="Pregnancy Due Date"
				backBtnHandler={() => Props.backBtn()}
			/>
			<ResultsCalculator {...Props} />
		</div>
	);
}
