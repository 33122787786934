import moment from 'moment';
import { ChevronRight, Info, Search } from 'react-feather';
import { theme } from '../../../../assets/theme';
import { useAuth } from '../../../../context/auth.context';
import { getName } from '../../../../helpers/name';
import useFetchList from '../../../../hooks/useFetchList';
import { RxPrescriptionProperties } from '../../../../types/rxPrescription.type';
import { Button } from '../../../Button/Button/Button';
import EmptyList from '../../../EmptyList';
import FormInput from '../../../Form/FormInput2';
import Separator from '../../../Separator';
import Spinner from '../../../Spinner';
import { useEffect } from 'react';

interface Props {
	setSelectedPrescription: React.Dispatch<
		React.SetStateAction<RxPrescriptionProperties | undefined>
	>;
	nextStep: () => void;
	disableSearchBar?: boolean;
	search?: string;
}

export default function ListRxHistory(props: Props) {
	const { user, init } = useAuth();

	const queryParams = new URLSearchParams(window.location.search);
	const rxPrescriptionId = queryParams.get('rxPrescriptionId');

	const {
		data: rxHistories,
		isLoading,
		pagination,
		search,
		setSearch,
		changePage,
		fetchList,
	} = useFetchList<RxPrescriptionProperties>({
		endpoint: 'rx-prescriptions',
		initialQuery: {
			doctorId: user?.userId,
			isTemporary: 'false',
		},
		pushData: true,
		primaryKey: 'rxPrescriptionId',
	});

	// useEffect(() => {
	//   init();
	// }, []);

	function handleNextPage() {
		if (!isLoading && pagination.next) {
			changePage(pagination.page + 1);
		}
	}

	function onClickItem(item: RxPrescriptionProperties) {
		props.setSelectedPrescription(item);
		props.nextStep();
	}

	function handleSearchChange(e: string) {
		const value = e;
		console.log('searchinput', value);
		setSearch(value);
	}

	useEffect(() => {
		setSearch(props.search);
	}, [props.search]);

	return (
		<div className="flex-1 flex flex-col">
			{!props.disableSearchBar && (
				<div className="px-3 py-1">
					<FormInput
						prefix={
							<Search width={20} height={20} color={theme.colors.charcoal200} />
						}
						placeholder="Search patient name"
						value={search}
						onChange={handleSearchChange}
					/>
				</div>
			)}
			<div className="flex-1 min-h-0">
				<div className="px-3">
					{!isLoading ? (
						rxHistories.length > 0 ? (
							rxHistories.map((history) => (
								<div key={history.rxPrescriptionId}>
									<div className="py-4">
										{rxPrescriptionId &&
											history.rxPrescriptionId === rxPrescriptionId &&
											onClickItem(history)}
										<Item
											name={getName(history.metaPatient)}
											createdAt={history.createdAt}
											validUntil={history.validUntil}
											onClick={() => onClickItem(history)}
										/>
									</div>
									<Separator />
								</div>
							))
						) : (
							<EmptyList
								icon={<Info color={theme.colors.black50} />}
								title="Rx prescription is empty"
								description="You can add new rx prescription"
							/>
						)
					) : (
						<div className="py-4 mx-auto w-fit">
							<Spinner size={25} color={theme.colors.primary} />
						</div>
					)}
				</div>
				<div className="h-3" />
			</div>
			{!!pagination.next && (
				<div className="p-3 w-80 mx-auto">
					<Button type="SOLIDASH" onClick={handleNextPage}>
						Load More
					</Button>
				</div>
			)}
		</div>
	);
}

function Item(props: {
	name: string;
	createdAt?: string;
	validUntil?: string;
	onClick: () => void;
}) {
	return (
		<button
			onClick={props.onClick}
			className="bg-transparent text-left w-full border-none cursor-pointer flex gap-x-2 items-center"
		>
			<div className="flex-1">
				<div className="flex gap-x-3 items-center">
					<p className="m-0">{props.name}</p>
					<p className="m-0 text-3 text-primary px-1.5 border border-solid border-primary rounded-2">
						In-App
					</p>
				</div>
				<div className="flex gap-x-4">
					<div className="flex-1">
						<p className="m-0 text-3.5 text-black-60">Prescribed on</p>
						<p className="m-0 text-4">
							{moment(props.createdAt).format('MMMM DD, YYYY')}
						</p>
					</div>
					<div className="flex-1">
						<p className="m-0 text-3.5 text-black-60">Valid Until</p>
						<p className="m-0 text-4">
							{moment(props.validUntil).format('MMMM DD, YYYY')}
						</p>
					</div>
				</div>
			</div>
			<ChevronRight width={20} height={20} color={theme.colors.black30} />
		</button>
	);
}
