import React from 'react';
import './styles.less';
import { Calendar, Button, Col, Row, Typography } from 'antd';
import moment from 'moment';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const { Title } = Typography;

type Props = {
	dateCellRender?: any;
	onSelect?: any;
	onPanelChange?: any;
};

export default function CalendarLayout(Props: Props) {
	const monthOption: Intl.DateTimeFormatOptions = {
		month: 'long',
	};

	return (
		<CalendarContainer>
			<Calendar
				className="mens-date-custom"
				fullscreen={false}
				headerRender={({ value, type, onChange, onTypeChange }) => {
					const newValue = value.clone();
					const newDate = newValue.toDate();
					const currMonth = newValue.month();
					const monthName = newDate.toLocaleString('default', monthOption);
					const year = newDate.getFullYear();
					const label = `${monthName} ${year}`;

					return (
						<div style={{ padding: 10, border: '0px solid black' }}>
							<Row justify="space-between">
								<Col>
									<Button
										style={{ color: 'black' }}
										onClick={() => {
											onChange(newValue.month(currMonth - 1));
										}}
										icon={<LeftOutlined />}
									/>
								</Col>
								<Col>
									<Title style={TitleStyles} level={2}>
										{label}
									</Title>
								</Col>
								<Col>
									<Button
										style={{ color: 'black' }}
										onClick={() => {
											onChange(newValue.month(currMonth + 1));
										}}
										icon={<RightOutlined color="red" />}
									/>
								</Col>
							</Row>
						</div>
					);
				}}
				disabledDate={(current) => {
					return current.month > moment().month;
				}}
				onPanelChange={Props.onPanelChange}
				onSelect={Props.onSelect}
				dateCellRender={Props.dateCellRender}
			/>
		</CalendarContainer>
	);
}

const TitleStyles: React.CSSProperties = {
	// border: "1px solid black",
	fontSize: 24,
	fontWeight: 600,
	margin: 0,
	color: 'black',
};

const CalendarContainer = styled.div`
	width: 100%;
	border: 0px;
	padding: 12px;
`;
