import { Col, Divider, Image, Row, Spin, Typography } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import COLORS from '../../../assets/globalColors';
import { httpRequest } from '../../../helpers/api';
import AppLayout from '../../../layout/AppLayout';
import { ArticleProps } from '../../../types/article.type';
import { BaseResponseProps2 } from '../../../types/config.type';
import DEFAULT_IMAGE from '../../../assets/default-image.jpeg';
import RenderHTML from '../../../components/RenderHTML';
import useFetchList from '../../../hooks/useFetchList';
import { BannerProps } from '../../../types/banner.type';
import SidebarArticle from '../../../components/Webapp/Articles/SidebarArticle';
import { shuffleArray } from '../../../helpers/array';
import BottomPromoAndDeals from '../../../components/Webapp/Articles/BottomPromoAndDeals';
import PageHeader from '../../../components/PageHeader';
import { useAuth } from '../../../context/auth.context';
import { apiPostPointReward } from '../../../types/pointReward';
import { EPointCategory } from '../../../types/point.type';
import moment from 'moment';

export default function DetailArticle() {
	const history = useHistory();
	const { isPatient, isLoggedIn, isDoctor } = useAuth();
	const { articleId } = useParams() as { articleId: string };
	const [article, setArticle] = useState<ArticleProps | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isPointPosted, setIsPointPosted] = useState<boolean>(false);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const { data: banners, isLoading: isLoadingBanner } =
		useFetchList<BannerProps>({
			endpoint: '/banners',
			// limit: 10,
			initialQuery: {
				isPublished: true,
				target: isDoctor ? 'DOCTOR' : 'PATIENT',
			},
		});

	const topicIds = article?.topics?.map((item) => item.topicId || '') || [];
	const { data: relatedArticles, isLoading: isLoadingArticle } =
		useFetchList<ArticleProps>({
			endpoint: '/articles',
			limit: 3,
			initialQuery: {
				isPublished: 'true',
				targetReaders: isPatient ? 'PATIENT' : 'DOCTOR',
				filterTopicIds: topicIds,
			},
		});
	const filteredRelatedArticles = useMemo(() => {
		let allRelatedArticles: ArticleProps[] = [...relatedArticles];
		if (topicIds?.includes('general')) {
			allRelatedArticles = [...allRelatedArticles];
		}
		allRelatedArticles = shuffleArray(allRelatedArticles);
		return (allRelatedArticles || [])
			.filter((item) => item.articleId && ![articleId].includes(item.articleId))
			.filter((_item, index) => index < 5);
	}, [articleId, topicIds, relatedArticles]);

	const maxTagShow = 2;

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			try {
				const res = await httpRequest.get<BaseResponseProps2<ArticleProps>>(
					`/articles/${articleId}?isPublished=true`,
				);

				if (res && res.data && res.data.payload) {
					setArticle(res.data.payload);
				}
				setIsLoading(false);
			} catch (error) {
				setIsLoading(false);
				console.log(error);
			}
		})();
	}, [articleId]);

	useEffect(() => {
		(async () => {
			isLoggedIn && (await setReward(articleId, article));
		})();
	}, [article]);

	useEffect(() => {
		httpRequest.post(`/articles/${articleId}/add-score`, {
			scoreChangeValue: 1,
		});
	}, []);

	const setReward = async (id: string, meta: any) => {
		// console.info('setReward--> id: ', id);
		// console.info('setReward--> meta: ', meta);

		if (isLoggedIn && article) {
			let isSuccess = false;

			if (isPointPosted === false) {
				isSuccess = await apiPostPointReward({
					category: EPointCategory.READ_ARTICLE,
					relatedActionId: id,
					isRelatedActionIdUnique: true,
					metaRelatedAction: meta,
					filters: {
						maxNumber: 1,
						dayInterval: 1,
					},
				});
				setIsPointPosted(true);
			}

			if (isPointPosted === true) {
				// show notification
				setIsPointPosted(false);
			}
		}
	};

	return (
		<AppLayout
			activePath={isLoggedIn ? '/app/articles/id' : '/articles'}
			relatedArticles={filteredRelatedArticles}
		>
			<div className={isLoggedIn ? 'wrapper-login' : 'container mt-12'}>
				{isLoggedIn && (
					<>
						<PageHeader
							title="Detail Article"
							onClick={() => history.goBack()}
						/>
						<Divider style={{ marginTop: 0 }} />
					</>
				)}
				{isLoading && (
					<div className="text-center my-10">
						<Spin spinning />
					</div>
				)}

				{!isLoading && (
					<Row gutter={48} style={{ marginBottom: 100 }}>
						<Col span={24} lg={isLoggedIn ? 24 : 16}>
							<Typography.Title
								level={4}
								style={{ color: COLORS.gray_100 }}
								className="font-bold"
							>
								{article?.title}
							</Typography.Title>
							<Image
								src={
									article?.images
										? article.images.length > 0
											? article.images[0]
											: DEFAULT_IMAGE
										: DEFAULT_IMAGE
								}
								alt={article?.title}
								style={{ borderRadius: 10 }}
								fallback={DEFAULT_IMAGE}
								preview={false}
								width="100%"
								height={500}
							/>
							<Typography.Text
								className="text-4.5 block"
								style={{ marginTop: 30 }}
								color="#201D1D"
							>
								<RenderHTML content={article?.contentText || ''} />
							</Typography.Text>

							<div style={{ marginTop: 30, textAlign: 'justify' }}>
								<Typography.Text
									className="text-6 block font-bold"
									color="#000"
								>
									Reference
								</Typography.Text>
								<Typography.Link
									href={article?.rssDetail?.fullArticleUrl}
									target='_blank'
									className="text-primary text-4.5"
									style={{
										color: 'blue',
										textDecoration: 'underline',
									}}
								>
									{`${article?.title} by ${article?.rssDetail
										?.rssUrl ? article?.rssDetail
										?.rssUrl : article?.metaCreatedByUser?.name} Published at ${moment(
										article?.publishedAt,
									).format('MMMM Do YYYY - hh:mm')}`}
								</Typography.Link>
								
							</div>

							<div style={{ marginTop: 30 }}>
								<Typography.Text
									className="text-6 block font-bold"
									color="#000"
								>
									Tags
								</Typography.Text>
								{article?.topics
									.map((topic) => topic.topicName)
									.filter((tag, idx) => idx < maxTagShow)
									.map((tag, index) => (
										<Typography.Text
											className="text-primary text-4.5"
											style={{ marginRight: 10 }}
										>
											#{tag.toUpperCase()}
										</Typography.Text>
									))}
								{article?.topics.map((topic) => topic.topicName)!.length! >
								maxTagShow ? (
									<Typography.Text
										className="text-primary text-4.5"
										style={{ marginRight: 10 }}
									>
										+
										{article?.topics.map((topic) => topic.topicName)!.length! -
											maxTagShow}{' '}
										More
									</Typography.Text>
								) : null}
							</div>
							{isLoggedIn && (
								<BottomPromoAndDeals
									isLoading={isLoadingBanner}
									banners={banners}
									bgVariant="white"
								/>
							)}
						</Col>
						{!isLoggedIn && (
							<Col span={24} lg={8}>
								<div className="top-27 sticky">
									<SidebarArticle
										isLoading={isLoadingArticle}
										articles={filteredRelatedArticles}
									/>
								</div>
							</Col>
						)}
					</Row>
				)}
			</div>

			{!isLoggedIn && (
				<BottomPromoAndDeals isLoading={isLoadingBanner} banners={banners} />
			)}
		</AppLayout>
	);
}
