import { Card, Divider, Row, Typography, Button, Col, Space } from 'antd';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import COLORS from '../../../assets/globalColors';

import PageHeader from '../../../components/PageHeader';

import AppLayout from '../../../layout/AppLayout';
import { iconChevronBlack as IconChevron } from '../../../assets/icons';

const activePath = '/app/medical-calculator';

const { Text } = Typography;

export default function CardioRisk() {
	const history = useHistory();

	const listCardioRisk = useMemo(
		() => [
			{
				title: 'ACC/AHA Risk Calculator',
				desc: 'Calculate your 10-year risk of heart disease or stroke using the ASCVD algorithm published in 2013 ACC/AHA Guideline on the Assessment of Cardiovascular Risk',
				path: '/app/medical-calculator/cardio-risk/aha',
			},
			{
				title: 'Framingham Risk Score',
				desc: 'The Framingham Risk Score is a sex-specific algorithm used to estimate the 10-year cardiovascular risk of an individual',
				path: '/app/medical-calculator/cardio-risk/framingham',
			},
			{
				title: 'Revised Cardiac Risk Index',
				desc: 'Estimates risk of cardiac complications after noncardiac surgery',
				path: '/app/medical-calculator/cardio-risk/revised-cardiac',
			},
		],
		[],
	);

	const ComponentCardConsult = ({
		item,
	}: {
		item: {
			title: string;
			desc: string;
			path: string;
		};
	}) => {
		return (
			<CardConsult onClick={() => history.push(item.path)}>
				<Row justify="space-between" className="items-center">
					<Col span={20}>
						<Row>
							<Text
								style={{
									fontSize: 16,
									color: COLORS.black,
									fontWeight: 700,
								}}
							>
								{item.title}
							</Text>
						</Row>
						<Row>
							<Text
								style={{
									fontSize: 14,
									color: COLORS.primary_black_60,
								}}
							>
								{item.desc}
							</Text>
						</Row>
					</Col>
					<Col span={1} style={{ paddingTop: 10 }}>
						<IconChevron />
					</Col>
				</Row>
			</CardConsult>
		);
	};

	return (
		<AppLayout activePath={activePath}>
			<Card style={{ minHeight: 550 }}>
				<PageHeader title="Cardio Risk" onClick={() => history.goBack()} />
				<Divider />
				<Space direction="vertical" size="middle">
					{listCardioRisk.map((item) => (
						<ComponentCardConsult item={item} key={item.title} />
					))}
				</Space>
			</Card>
		</AppLayout>
	);
}

const CardConsult = styled(Card)`
	border-radius: 12px;
	cursor: pointer;

	div.ant-card-body {
		padding: 12px 16px;
	}
`;
