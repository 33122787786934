import React from 'react';
import {
	Button,
	Col,
	Form,
	Input,
	InputNumber,
	Radio,
	Row,
	Select,
	Space,
	Typography,
} from 'antd';
import styled from 'styled-components';

type Props = {
	checkHandler: any;
	form: any;
	isChecked: boolean;
	dataQuestions: any;
	isComingSoon?: boolean;
};

const { Text } = Typography;

export default function FormQuestion(props: Props) {
	const renderInput = (item: any) => {
		switch (item?.type) {
			case 'input':
				return (
					<InputCustom placeholder="Input" size="large" suffix={item.suffix} />
				);
			case 'inputNumber':
				return (
					<InputNumber
						type="number"
						placeholder="Input"
						className="w-full"
						size="large"
					/>
				);
			case 'select':
				return (
					<Select size="large" placeholder="Select" options={item.options} />
				);
			case 'radio':
				return (
					<Radio.Group buttonStyle="solid">
						<Space size="middle">
							<RadioBtnCustom value={item.options?.yesValue || 0}>
								<Text
									style={{
										fontSize: 14,
										fontWeight: 700,
										color: 'inherit',
									}}
								>
									Yes
								</Text>
							</RadioBtnCustom>
							<RadioBtnCustom value={item.options?.noValue || 0}>
								<Text
									style={{
										fontSize: 14,
										fontWeight: 700,
										color: 'inherit',
									}}
								>
									No
								</Text>
							</RadioBtnCustom>
						</Space>
					</Radio.Group>
				);
		}
	};
	return (
		<>
			<Row style={{ margin: '32px 0px 10px' }}>
				<Text style={{ fontSize: 24, fontWeight: 600 }}>Question</Text>
			</Row>

			<Form
				form={props.form}
				onFinish={props.checkHandler}
				name="basic"
				layout="vertical"
				autoComplete="off"
			>
				{props.dataQuestions.map((item: any) => (
					<Row gutter={16} key={item.row}>
						{item.formItems.map((formItem: any) => (
							<Col
								span={
									item.formItems.length === 2
										? 12
										: item.formItems.length === 3
										? 8
										: 24
								}
								key={formItem.name}
								style={{ display: 'flex', flex: 1, flexDirection: 'column' }}
							>
								<label style={{ display: 'flex', flex: 1, marginBottom: 8 }}>
									{formItem.label}
								</label>
								<Form.Item
									name={formItem.name}
									className="w-full"
									rules={
										formItem.type === 'inputNumber'
											? [
													{
														type: 'number',
														min: formItem.min,
														message: `Age should not be less than ${formItem.min}`,
													},
											  ]
											: []
									}
								>
									{renderInput(formItem)}
								</Form.Item>
							</Col>
						))}
					</Row>
				))}
				<Row justify="end">
					<Form.Item shouldUpdate>
						{() => (
							<Button
								disabled={
									props.isComingSoon ||
									!props.form.isFieldsTouched(true) ||
									props.form
										.getFieldsError()
										.filter(({ errors }: { errors: any }) => errors.length)
										.length > 0
								}
								type="primary"
								htmlType="submit"
								size="large"
								style={{ width: 220, marginTop: 16, border: 'none' }}
							>
								<Text
									style={{
										fontSize: 16,
										fontWeight: 600,
										color: 'inherit',
									}}
								>
									{props.isComingSoon
										? 'Coming Soon'
										: props.isChecked
										? 'Reset'
										: 'Check'}
								</Text>
							</Button>
						)}
					</Form.Item>
				</Row>
			</Form>
		</>
	);
}

const RadioBtnCustom = styled(Radio.Button)`
	border: none;

	&:not(:checked) {
		background-color: #f1f3f5;
	}
`;

const InputCustom = styled(Input)`
	span.ant-input-suffix {
		font-size: 16px;
		color: #aeaeae;
	}
`;
