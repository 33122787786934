export const MAPPING_BALANCE_POINT_CATEGORY = {
	MEDICAL_SERVICE: {
		title: 'Medical Service',
	},
	TELEMEDICINE: {
		title: 'Telemedicine',
	},
	CONSULT_NOW: {
		title: 'Consult Now',
	},
	IN_PERSON_CONSULTATION: {
		title: 'In Person Consultation',
	},
	WITHDRAWAL: {
		title: 'Withdrawal',
	},
	REFERRAL: {
		title: 'Referral',
	},
	DOCTOR_DOCUMENT: {
		title: 'Doctor Document',
	},
	READ_ARTICLE: {
		title: 'Read Article',
	},
	SHARE_ARTICLE: {
		title: 'Share Article',
	},
};

export enum EBalanceCategory {
	MEDICAL_SERVICE = 'MEDICAL_SERVICE',
	TELEMEDICINE = 'TELEMEDICINE',
	CONSULT_NOW = 'CONSULT_NOW',
	IN_PERSON_CONSULTATION = 'IN_PERSON_CONSULTATION',
	WITHDRAWAL = 'WITHDRAWAL',
	REFERRAL = 'REFERRAL',
	DOCTOR_DOCUMENT = 'DOCTOR_DOCUMENT',
	READ_ARTICLE = 'READ_ARTICLE',
}

export type BalanceHistoryProperties = {
	historyId: string;
	balanceId: string;
	userId: string;
	type?: string;
	metaUser?: any;
	amount: number;
	transactionId?: string;
	paymentId?: string;
	metaTransaction?: {};
	note?: string;
	category?: EBalanceCategory;
	createdAt: Date;
	updatedAt: Date;
};

export interface BalanceProperties {
	balanceId: string;
	currentAmount: number;
	userId: string;
	createdAt: string;
	updatedAt: string;
}
