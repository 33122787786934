import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
	colors: {
		primary: '#D81F64',
		secondary: '#1d2b36',
		black: '#000000',
		white: '#ffffff',

		primary10: '#FEF5F9',

		black10: '#DCDCDC',
		black20: '#d8d8d8',
		black30: '#D3D3D3',
		black40: '#C0C0C0',
		black50: '#AEAEAE',
		black60: '#929292',
		black70: '#777777',
		black80: '#535353',
		black100: '#121212',

		gray10: '#F1F3F5',
		gray20: '#D5DCE1',
		gray30: '#C5CED6',
		gray50: '#A5B2BD',
		gray60: '#748494',
		gray70: '#405261',
		gray80: '#314351',
		gray90: '#253642',
		gray100: '#1D2B36',

		ash200: '#F9FAFA',
		ash300: '#F1F3F5',
		ash400: '#E5E9EC',
		ash700: '#B7C2CC',
		ash800: '#A5B2BD',

		charcoal200: '#93A1B0',
		charcoal300: '#748494',

		green10: '#d7f4eb',

		yellow10: '#fcf1e3',

		purple10: '#EAE4F9',

		blue10: '#DAEFFA',

		red: '#D7373F',
		blue: '#2B9CDC',
		green: '#268e6c',
		yellow: '#DA7B11',
		purple: '#825DD1',
	},
};

export { theme };
