import React, { useState, useEffect } from 'react';
import { Card, Typography } from 'antd';
import styled from 'styled-components';
import ResultSectionScoreCategory from './ResultSectionCategoryScore';
import ResultSectionProblems from './ResultSectionProblems';
import ResutlSuggestions from './ResultSectionSuggestions';
import AppToolsList from '../AppToolsList';

const { Title } = Typography;

interface BmiResultProps {
	data: {
		value: number;
		weight: number;
		height: number;
		idealWeight: number;
		age: number;
		gender: string;
	};
}

export default function ResultCalculator({ data }: BmiResultProps) {
	const [message, setMessage] = useState<string>('');
	const [deltaWeight, setDeltaWeight] = useState<any>(0);

	useEffect(() => {
		setDeltaWeight(
			Math.abs(Math.round((data.idealWeight - data.weight) * 100) / 100),
		);
		data.idealWeight - data.weight !== 0 && data.idealWeight - data.weight > 0
			? setMessage('increase')
			: setMessage('decrease');
	}, [data.idealWeight, data.weight]);

	const bmiScore = Math.round(data.value * 100) / 100;
	const idealWeight = Math.round(data.idealWeight * 100) / 100;

	return (
		<>
			<Card style={CardWrapperStyles}>
				<ResultCardsWrapper>
					<ResultSectionScoreCategory
						bmiScore={bmiScore}
						height={data.height}
						weight={data.weight}
						age={data.age}
					/>
					<ResutlSuggestions
						deltaWeight={deltaWeight}
						idealWeight={idealWeight}
						message={message}
					/>
					<ResultSectionProblems />
				</ResultCardsWrapper>
				<Title level={5}>Track your health with our other tools</Title>
				<OtherToolsContainer>
					<AppToolsList exclude="bmi" />
				</OtherToolsContainer>
			</Card>
		</>
	);
}

const CardWrapperStyles: React.CSSProperties = {
	borderBottomLeftRadius: '12px',
	borderBottomRightRadius: '12px',
};

const ResultCardsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	margin-bottom: 24px;
`;

const OtherToolsContainer = styled.div`
	// border: 1px solid black;
	margin-top: 24px;
	margin-bottom: 24px;
	display: flex;
	gap: 24px;
	justify-content: start;
	align-items: center;
	width: 100%;
`;
