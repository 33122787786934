import {
	Card,
	Divider,
	Row,
	Typography,
	Button,
	Col,
	Space,
	Form,
	Radio,
} from 'antd';
import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import PageHeader from '../../../../components/PageHeader';

import AppLayout from '../../../../layout/AppLayout';
import PreResult from '../../../../components/Webapp/MedicalCalculator/PreResult';
import Score from '../../../../components/Webapp/MedicalCalculator/Score';

const activePath = '/app/medical-calculator';

const { Text } = Typography;

export default function CardioRiskRevisedCardiac() {
	const [form] = Form.useForm();
	const history = useHistory();
	const [value, setValue] = useState<any>(0);
	const [estimated, setEstimated] = useState<any>({});
	const [isChecked, setChecked] = useState<any>(false);

	const dataIndicator = useMemo(
		() => [
			{
				title: 'Class I',
				width: 20,
				color: '#7FD53B',
				startWidth: 0,
				startValue: 0,
			},
			{
				title: 'Class II',
				width: 20,
				color: '#EFC222',
				startWidth: 20,
				startValue: 1,
			},
			{
				title: 'Class III',
				width: 20,
				color: '#E93131',
				startWidth: 40,
				startValue: 2,
			},
			{
				title: 'Class IV',
				width: 40,
				color: '#D81F64',
				startWidth: 60,
				startValue: 3,
			},
		],
		[],
	);

	const dataQuestion = useMemo(
		() => [
			{
				label: 'High risk surgery?',
				name: 'high_risk_surgery',
			},
			{
				label: 'Coronary artery disease?',
				name: 'coronary_artery_disease',
			},
			{
				label: 'Congestive heart failure?',
				name: 'congestive_heart_failure',
			},
			{
				label: 'Cerebrovascular disease?',
				name: 'cerebrovascular_disease',
			},
			{
				label: 'Diabetes mellitus on insulin?',
				name: 'diabetes_melitus',
			},
			{
				label: 'Serum creatinine >2 mg/dl or >177 µmol/l',
				name: 'serum_creatinine_more_2mg_or_177mol',
			},
		],
		[],
	);

	const checkHandler = (values: any) => {
		if (isChecked) {
			form.resetFields();
			setChecked(false);
			return;
		}

		setChecked(true);
		const res = calculateRevisedCardiac({ ...values });
		setValue(res);
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	};

	const calculateRevisedCardiac = (values: any) => {
		let result = Object.values(values).reduce(
			(acc: any, curr: any) => acc + curr,
			0,
		);
		return result;
	};

	const resultValue = useMemo(() => {
		const score = value;
		if (score >= dataIndicator?.[dataIndicator?.length - 1]?.startValue) {
			return {
				score,
				item: dataIndicator[dataIndicator?.length - 1],
				pointer: 100 - dataIndicator?.[dataIndicator?.length - 1]?.width / 2,
			};
		}

		const filtered = dataIndicator?.filter((item) => score >= item?.startValue);
		const itemSelected = filtered?.[filtered?.length - 1];
		const itemIndex: any = dataIndicator?.findIndex(
			(itm) => itm?.startValue === itemSelected?.startValue,
		);
		const nextValue = dataIndicator?.[itemIndex + 1];

		const pointer =
			(score / nextValue?.startValue) *
				(itemSelected?.width + itemSelected?.startWidth) +
			itemSelected?.width / 2;

		return {
			score,
			item: itemSelected,
			pointer,
		};
	}, [value, dataIndicator]);

	const dataEstimated = useMemo(() => {
		let title = 'Estimated risk of major cardiac event (%)';
		let val = '0';
		switch (value) {
			case 0:
				val = '0.4';
				break;
			case 1:
				val = '0.9';
				break;
			case 2:
				val = '6.6';
				break;
			default:
				val = '11';
		}
		return { title, value: val };
	}, [value]);

	console.log(resultValue);
	console.log(dataEstimated);

	return (
		<AppLayout activePath={activePath}>
			<Card style={{ minHeight: 550 }}>
				<PageHeader
					title="Revised Cardiac Risk Index"
					onClick={() => history.goBack()}
				/>
				<Divider />
				{isChecked ? (
					<Score
						dataIndicator={dataIndicator}
						estimated={dataEstimated}
						resultValue={resultValue}
					/>
				) : (
					<PreResult />
				)}

				<Row style={{ margin: '32px 0px 10px' }}>
					<Text style={{ fontSize: 24, fontWeight: 600 }}>Question</Text>
				</Row>
				<Form
					form={form}
					onFinish={checkHandler}
					name="basic"
					layout="vertical"
					autoComplete="off"
				>
					<Row gutter={10}>
						{dataQuestion?.map((item) => (
							<Col span={12} key={item.name}>
								<Form.Item
									label={item.label}
									name={item.name}
									className="w-full"
								>
									<Radio.Group buttonStyle="solid">
										<Space size="middle">
											<RadioBtnCustom value={1}>
												<Text
													style={{
														fontSize: 14,
														fontWeight: 700,
														color: 'inherit',
													}}
												>
													Yes
												</Text>
											</RadioBtnCustom>
											<RadioBtnCustom value={0}>
												<Text
													style={{
														fontSize: 14,
														fontWeight: 700,
														color: 'inherit',
													}}
												>
													No
												</Text>
											</RadioBtnCustom>
										</Space>
									</Radio.Group>
								</Form.Item>
							</Col>
						))}
					</Row>

					<Row style={{ justifyContent: 'end' }}>
						<Form.Item shouldUpdate>
							{() => (
								<Button
									disabled={
										!form.isFieldsTouched(true) ||
										form.getFieldsError().filter(({ errors }) => errors.length)
											.length > 0
									}
									type="primary"
									htmlType="submit"
									size="large"
									style={{ width: 220, marginTop: 16, border: 'none' }}
								>
									<Text
										style={{
											fontSize: 16,
											fontWeight: 600,
											color: 'inherit',
										}}
									>
										{isChecked ? 'Reset' : 'Check'}
									</Text>
								</Button>
							)}
						</Form.Item>
					</Row>
				</Form>
			</Card>
		</AppLayout>
	);
}

const RadioBtnCustom = styled(Radio.Button)`
	border: none;

	&:not(:checked) {
		background-color: #f1f3f5;
	}
`;
