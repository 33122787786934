
import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Typography, UploadFile, message } from 'antd';
import { CertificateProperties } from '../../../types/doctor.type';
import FormInput from '../../Form/FormInput';
import Upload, { RcFile } from 'antd/lib/upload';
import { UploadProps } from 'antd/es/upload';
import { UploadOutlined } from '@ant-design/icons';
import styled from 'styled-components';

type Props = {
	data: CertificateProperties | undefined;
	isVisible: boolean;
	isClose: () => void;
	isLoading: boolean;
	action: (name: string, image: any, action: 'edit' | 'add') => Promise<void>;
};

const { Text } = Typography;

export default function ModalAddEditCertificate(props: Props) {
	const [name, setName] = useState<string>('');

	const [previewCertificateOpen, setPreviewCertificateOpen] = useState(false);
	const [previewCertificateImage, setPreviewCertificateImage] = useState('');
	const [previewCertificateTitle, setPreviewCertificateTitle] = useState('');
	const [fileCertificate, setFileCertificate] = useState<any>([]);

	useEffect(() => {
		props.data?.name && setName(props.data?.name);
		props.data?.url &&
			setFileCertificate([
				{
					uid: '1',
					name: props.data.name,
					status: 'done',
					url: props.data.url,
				},
			]);
	}, [props.data]);

	const getBase64 = (file: RcFile): Promise<string> =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result as string);
			reader.onerror = (error) => reject(error);
		});

	const handleSignaturePreview = async (file: UploadFile) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj as RcFile);
		}

		setPreviewCertificateImage(file.url || (file.preview as string));
		setPreviewCertificateOpen(true);
		setPreviewCertificateTitle(
			file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1),
		);
	};

	const handleSignatureChange: UploadProps['onChange'] = ({
		fileList: newFileList,
	}) => {
		setFileCertificate(newFileList);
	};

	const handleSignatureCancel = () => setPreviewCertificateOpen(false);

	const uploadSignatureButton = (
		<>
			<Button
				type="primary"
				block
				ghost
				icon={<UploadOutlined />}
				style={{
					backgroundColor: '#ffffff',
					color: '#D81F64',
					width: '100%',
				}}
			>
				Upload Certificate
			</Button>
		</>
	);

	const handleAction = async () => {
		await props.action(
			name,
			fileCertificate[0].originFileObj,
			props.data ? 'edit' : 'add',
		);
		setName('');
		setPreviewCertificateOpen(false);
		setPreviewCertificateImage('');
		setPreviewCertificateTitle('');
		setFileCertificate([]);
	};

	return (
		<Modal
			className="modal"
			open={props.isVisible}
			footer={null}
			closable={false}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<Text style={{ fontWeight: 600, fontSize: 20, color: '#000000' }}>
					{props.data ? 'Edit Certificate' : 'Add Certificate'}
				</Text>
				<Text style={{ fontWeight: 400, fontSize: 14, color: '#000000' }}>
					{props.data
						? 'Edit your certificate detail here'
						: 'Write your certificate detail here'}
				</Text>
			</div>
			<div>
				<FormInput
					label="Certificate's name"
					placeholder="eg. Passed, Philippine Speciality Board of Adul"
					onChange={(val) => setName(val)}
					value={name}
				/>
				<Text style={{ fontSize: 16 }}>Certificate's Image</Text>
				<CustomUpload
					listType="picture-card"
					fileList={fileCertificate || props.data?.url}
					maxCount={1}
					onPreview={handleSignaturePreview}
					onChange={handleSignatureChange}
					name="file"
					accept=".jpg,.jpeg,.png"
					beforeUpload={(file) => {
						const isLtMaxSize = file.size / 1024 / 1024 < 1;
						if (!isLtMaxSize) {
							message.error(`Image must smaller than ${1}MB!`);
							return Upload.LIST_IGNORE; 
						}

						return false;
					}}
				>
					{uploadSignatureButton}
				</CustomUpload>
				<Modal
					open={previewCertificateOpen}
					title={previewCertificateTitle}
					footer={null}
					onCancel={handleSignatureCancel}
				>
					<img
						alt="example"
						style={{ width: '100%' }}
						src={previewCertificateImage}
					/>
				</Modal>
			</div>
			<div className="modal-body">
				<Row gutter={24} style={{ marginTop: '48px' }}>
					<Col span={12}>
						<Button
							type="text"
							size="large"
							className="text-3.5 text-secondary rounded-3 bg-gray-10 border-0 w-full font-bold"
							onClick={() => {
								props.isClose();
								setName('');
								setPreviewCertificateOpen(false);
								setPreviewCertificateImage('');
								setPreviewCertificateTitle('');
								setFileCertificate([]);
							}}
						>
							Not Now
						</Button>
					</Col>
					<Col span={12}>
						<Button
							disabled={!name || fileCertificate.length === 0}
							loading={props.isLoading}
							type="primary"
							size="large"
							style={{ color: '#fff' }}
							className="text-3.5 text-white rounded-3 bg-primary w-full font-bold"
							onClick={() => handleAction()}
						>
							Yes
						</Button>
					</Col>
				</Row>
			</div>
		</Modal>
	);
}

const CustomUpload = styled(Upload)`
	.ant-upload {
		width: 100%;
		border: none;
		background-color: white;
	}

	.ant-upload-list-picture-card-container {
		height: 250px;
		width: 100%;
	}

	.ant-upload-list-picture-card .ant-upload-list-item {
		width: 100%;
	}
`;
