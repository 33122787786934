export default function IconButton(props: {
	children: any;
	className?: string;
	onClick: any;
}) {
	return (
		<button
			onClick={props.onClick}
			type="button"
			className={
				'flex bg-transparent border-none p-0 cursor-pointer' +
				(props.className ? ' ' + props.className : '')
			}
		>
			{props.children}
		</button>
	);
}
