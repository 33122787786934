import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Info, Menu, Search } from 'react-feather';
import { theme } from '../../../../assets/theme';
import { getName } from '../../../../helpers/name';
import useFetchList from '../../../../hooks/useFetchList';
import {
	DoctorDocumentProperties,
	EMetaType,
} from '../../../../types/doctorDocument.type';
import { Button } from '../../../Button/Button/Button';
import IconButton from '../../../Button/IconButton';
import EmptyList from '../../../EmptyList';
import FormInput from '../../../Form/FormInput2';
import Spinner from '../../../Spinner';
import Tab from '../../../Tab/Tab';
import ItemDoctorDocument from './Components/ItemDoctorDocument';
import { useAuth } from '../../../../context/auth.context';

const tabs = [
	'All',
	'Medical Certificate',
	'Clearance',
	'Fit To Work',
	'Own Template',
];

interface Props {
	toDetail: (docId: string) => void;
	disableSearchBar?: boolean;
	search?: string;
}

export default function DoctorDocumentList(props: Props) {
	const { user } = useAuth();
	const [selectedTabIndex, setSelectedTabIndex] = useState(0);

	const { data, isLoading, pagination, search, setSearch, changePage } =
		useFetchList<DoctorDocumentProperties>({
			endpoint: 'doctor-documents',
			initialQuery: {
				isTemporary: 'false',
				doctorId: user?.userId,
			},
			primaryKey: 'doctorDocumentId',
			pushData: true,
		});

	const selectedData = useMemo(() => {
		if (!data || selectedTabIndex === undefined) return;

		switch (selectedTabIndex) {
			case 0:
				return data;
			case 1:
				return data.filter((d) => d.type === EMetaType.MEDICAL_CERTIFICATE);
			case 2:
				return data.filter((d) => d.type === EMetaType.CLEARANCE);
			case 3:
				return data.filter((d) => d.type === EMetaType.FIT_TO_WORK);
			case 4:
				return data.filter((d) => d.type === EMetaType.OTHERS);
		}
	}, [data, selectedTabIndex]);

	const handleNextPage = () => {
		if (!isLoading && pagination.next) {
			changePage(pagination.page + 1);
		}
	};

	useEffect(() => {
		setSearch(props.search);
	}, [props.search]);

	return (
		<div className="px-3">
			{!props.disableSearchBar && (
				<Tab
					tabs={tabs}
					selectedTabIndex={selectedTabIndex}
					setSelectedTabIndex={setSelectedTabIndex}
					useBottomSeparator
				/>
			)}
			{!props.disableSearchBar && (
				<div className="flex items-center gap-x-3 py-4">
					<div className="flex-1">
						<FormInput
							prefix={
								<div className="flex">
									<Search
										width={20}
										height={20}
										color={theme.colors.charcoal200}
									/>
								</div>
							}
							placeholder="Search Document"
							value={search}
							onChange={setSearch}
						/>
					</div>
					<IconButton onClick={() => {}}>
						<Menu width={20} height={20} color={theme.colors.black50} />
					</IconButton>
				</div>
			)}
			<div>
				{!isLoading ? (
					selectedData && selectedData.length > 0 ? (
						<>
							{selectedData.map((doc) => (
								<ItemDoctorDocument
									key={doc.doctorDocumentId}
									patientName={getName(doc.metaPatient)}
									prescribedOn={
										moment(doc.createdAt).format('MMMM DD, YYYY') ?? 'unknown'
									}
									validUntil={
										moment(doc.validUntil).format('MMMM DD, YYYY') ?? 'unknown'
									}
									price={doc.price}
									paymentStatus={doc.payment?.paymentStatus}
									onClick={() => props.toDetail(doc.doctorDocumentId!)}
									type={doc.type}
								/>
							))}
							{pagination.next ? (
								<div className="max-w-sm mx-auto pt-4 pb-6">
									<Button type="SOLIDASH" onClick={handleNextPage}>
										Load More
									</Button>
								</div>
							) : undefined}
						</>
					) : (
						<EmptyList
							icon={<Info />}
							title="Data Empty"
							description="You have not added Doctor Document History."
						/>
					)
				) : (
					<div className="py-4 w-fit mx-auto">
						<Spinner size={25} color={theme.colors.primary} />
					</div>
				)}
			</div>
		</div>
	);
}
