import { useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import useTodaySchedule from '../../../hooks/useTodaySchedule';
import { EPWIdStatus } from '../../../types/patient.type';
import { EScheduleTypes } from '../../../types/schedule.type';
import { ITodayScheduleItem } from '../../../types/todaySchedule.type';
import Card from '../../Card';
import { ReactComponent as Illustration } from '../../../assets/icons/illustration.svg';
import moment from 'moment';
import { ReactComponent as Spinner } from '../../../assets/icons/spinner.svg';
import { theme } from '../../../assets/theme';
import {
	ETransactionStatusType,
	TransactionConsultationProperties,
} from '../../../types/transactionConsultation.type';
import { Button } from '../../Button/Button/Button';
import { httpRequest } from '../../../helpers/api';
import { ApiResponseDefault } from '../../../types/apiResponse.type';
import { apiGetTwilioConversations } from '../../../api/waitingRoom';
import { message } from 'antd';
import ModalAcceptConsultation from '../DetailSchedule/ModalAcceptConsultation';
import ModalRejectConsultation from '../DetailSchedule/ModalRejectConsultation';

export default function CardSchedule() {
	const history = useHistory();

	const { todayScheduleList: todayScheduleListRaw, getList } = useTodaySchedule(
		{
			initLoad: true,
			role: 'doctor',
		},
	);

	const [isLoading, setIsLoading] = useState(true);
	const [tmpData, setTmpData] = useState<TransactionConsultationProperties>();
	const [isModalAcceptConsulVisible, setIsModalAcceptConsulVisible] =
		useState(false);
	const [isModalRejectConsulVisible, setIsModalRejectConsulVisible] =
		useState(false);

	const [todayScheduleList, setTodayScheduleList] = useState<
		(ITodayScheduleItem & { status?: 'Not Joined' | 'In Room' | 'Finished' })[]
	>([]);

	useEffect(() => {
		if (!todayScheduleListRaw || todayScheduleListRaw.length === 0) {
			if (isLoading) {
				setIsLoading(false);
			}
			return;
		}

		setIsLoading(true);

		const todayScheduleList: (ITodayScheduleItem & {
			status?: 'Not Joined' | 'In Room' | 'Finished';
		})[] = todayScheduleListRaw;

		const trxConsultsIdAndIndex: { id: string; index: number }[] = [];

		todayScheduleListRaw.forEach((schedule, index) => {
			if (
				schedule.type === EScheduleTypes['consult-now'] ||
				schedule.type === EScheduleTypes.telemedicine
			) {
				const id = (schedule.data.raw as TransactionConsultationProperties)
					.transactionConsultationId;
				if (id) {
					trxConsultsIdAndIndex.push({ id, index });
				}
			}
		});

		(async () => {
			const userStatus = await apiGetTwilioConversations(
				trxConsultsIdAndIndex.map((trx) => trx.id),
			);

			if (userStatus) {
				for (const status of userStatus) {
					const trxConsultIdAndIndex = trxConsultsIdAndIndex.find(
						(trx) => trx.id === status.transactionConsultationId,
					);
					if (trxConsultIdAndIndex?.index) {
						let userStatus: 'Not Joined' | 'In Room' | 'Finished';
						switch (status.eventType) {
							case 'onParticipantAdded':
								userStatus = 'In Room';
								break;
							default:
								userStatus = 'Not Joined';
						}

						if (
							moment(
								todayScheduleList[trxConsultIdAndIndex.index].data.startTime,
								'HH:mm',
							).isBefore(moment()) &&
							moment(
								todayScheduleList[trxConsultIdAndIndex.index].data.endTime,
								'HH:mm',
							).isBefore(moment())
						) {
							userStatus = 'Finished';
						}

						todayScheduleList[trxConsultIdAndIndex.index].status = userStatus;
					}
				}
			}

			setTodayScheduleList(todayScheduleList);
			setIsLoading(false);
		})();
	}, [todayScheduleListRaw]);

	const numberOfSchedule = useMemo(() => {
		if (!todayScheduleList) return;

		return calculateNumberOfScheduleType(todayScheduleList);
	}, [todayScheduleList]);

	async function updateTransactionConsult(
		id: string,
		data: Partial<TransactionConsultationProperties>,
	) {
		try {
			const res = await httpRequest.patch<
				ApiResponseDefault<TransactionConsultationProperties>
			>(`transaction-consults/${id}`, data);
			if (res.data.payload) {
				message.success('Success approve event!');
				setIsModalAcceptConsulVisible(false);
			}
		} catch (err) {
			console.log(err);
			message.error('ERROR: Failed to approve event! Please try again');
		} finally {
			await getList();
			setTmpData(undefined);
		}
	}

	return (
		<Card className="p-4">
			<div className="flex justify-between gap-x-16">
				<p className="m-0 font-semibold text-4.5 text-gray-100">Schedule</p>
				<Link to="/app/schedule" className="text-gray-60">
					See all
				</Link>
			</div>
			<div className="mt-6">
				<ScheduleCardSubtitle>
					<p className="m-0 font-semibold text-4">
						Today, {moment().format('MMM DD yyyy')}
					</p>
					{numberOfSchedule?.total ? (
						<div className="flex gap-x-4.5">
							{numberOfSchedule?.telemedicine ? (
								<ScheduleInfoTag>
									<div className="w-3 h-3 rounded-1.5 bg-primary" />
									<p className="m-0 text-gray-60 text-3.5">
										{numberOfSchedule.telemedicine} Telemedicine
									</p>
								</ScheduleInfoTag>
							) : undefined}
							{numberOfSchedule?.consultNow ? (
								<ScheduleInfoTag>
									<div className="w-3 h-3 rounded-1.5 bg-primary" />
									<p className="m-0 text-gray-60 text-3.5">
										{numberOfSchedule.consultNow} Consult Now
									</p>
								</ScheduleInfoTag>
							) : undefined}
							{numberOfSchedule?.appointment ? (
								<ScheduleInfoTag>
									<div className="w-3 h-3 rounded-1.5 bg-blue" />
									<p className="m-0 text-gray-60 text-3.5">
										{numberOfSchedule.appointment} In-Person Consultation
									</p>
								</ScheduleInfoTag>
							) : undefined}
							{numberOfSchedule?.medicalService ? (
								<ScheduleInfoTag>
									<div className="w-3 h-3 rounded-1.5 bg-red" />
									<p className="m-0 text-gray-60 text-3.5">
										{numberOfSchedule.medicalService} Medical Service
									</p>
								</ScheduleInfoTag>
							) : undefined}
						</div>
					) : undefined}
				</ScheduleCardSubtitle>
				{numberOfSchedule?.total ? (
					<>
						{todayScheduleList.map((schedule, index) => {
							console.log(schedule);
							return (
								<PatientScheduleContainer key={index}>
									<ScheduleTagMarker>
										<div
											className={
												'w-1 h-16 ' +
												(schedule.type?.includes('appointment')
													? 'bg-blue'
													: 'bg-primary')
											}
										/>
									</ScheduleTagMarker>
									<p className="m-0 w-28 text-center">{schedule.time}</p>
									<PatientInfoContainer>
										<img
											src={
												schedule.data.detailPatient?.profilePic
													? schedule.data.detailPatient?.profilePic
													: '/Logo.svg'
											}
											alt="Patient"
											className="w-10 h-10 rounded-full"
										/>
										<div className="flex flex-col">
											<p className="m-0 text-4.5 line-clamp-1">
												{schedule.data.detailPatient?.name}
											</p>
											<div className="text-charcoal-300">
												<div className="flex items-center">
													<img src="/icons/collection.svg" alt="PWD/Senior" />
													<p className="m-0 ml-1 line-clamp-1 text-3.5">
														{schedule.data.detailPatient?.age} Years Old •{' '}
														{schedule.data.detailPatient?.gender}
														{schedule.data.detailPatient?.pwIdStatus ===
														EPWIdStatus.APPROVED
															? ' • PWD/Senior'
															: ''}
													</p>
												</div>
												<div className="flex items-center">
													<img
														src="/icons/map-pin.svg"
														alt="Consultation place"
													/>
													<p className="m-0 ml-1 line-clamp-1 text-3.5">
														{getScheduleType(schedule.type)}
													</p>
												</div>
											</div>
										</div>
									</PatientInfoContainer>
									{schedule?.status && schedule.status === 'In Room' && (
										<InRoomContainer>
											<div className="w-1 h-1 bg-white rounded-1.5" />
											<p className="m-0 text-white text-3">In Room</p>
										</InRoomContainer>
									)}
									{isUseTeleconferenceRoom(schedule.type) &&
										(schedule.data.raw as TransactionConsultationProperties)
											.transactionStatus ===
											ETransactionStatusType.APPROVED && (
											<OutlinedButton
												onClick={() =>
													history.push(
														`/app/consultation/${(
															schedule.data
																.raw as TransactionConsultationProperties
														)?.transactionConsultationId}`,
													)
												}
											>
												Join Room
											</OutlinedButton>
										)}
									{(schedule.data.raw as TransactionConsultationProperties)
										.transactionStatus ===
										ETransactionStatusType.WAITING_APPROVAL && (
										<div className="w-44 flex gap-x-2">
											<Button
												type="SOLIDASH"
												onClick={() => {
													setTmpData(
														schedule.data
															.raw as TransactionConsultationProperties,
													);
													setIsModalRejectConsulVisible(true);
												}}
											>
												Reject
											</Button>
											<Button
												type="SOLID"
												onClick={() => {
													setTmpData(
														schedule.data
															.raw as TransactionConsultationProperties,
													);
													setIsModalAcceptConsulVisible(true);
												}}
											>
												Approve
											</Button>
										</div>
									)}
								</PatientScheduleContainer>
							);
						})}
					</>
				) : !isLoading ? (
					<div className="py-4">
						<div className="py-7 w-fit mx-auto">
							<Illustration />
						</div>
						<p className="m-0 mt-6 font-medium text-primary text-center">
							No Appointment Today
						</p>
					</div>
				) : (
					<div className="py-4 w-fit mx-auto">
						<Spinner width={27.5} height={27.5} color={theme.colors.primary} />
					</div>
				)}
			</div>
			<ModalAcceptConsultation
				isVisible={isModalAcceptConsulVisible}
				setVisibility={setIsModalAcceptConsulVisible}
				actionHandler={() => {
					updateTransactionConsult(tmpData?.transactionConsultationId || '', {
						transactionStatus: ETransactionStatusType.APPROVED,
					});
				}}
			/>
			<ModalRejectConsultation
				isVisible={isModalRejectConsulVisible}
				setVisibility={setIsModalRejectConsulVisible}
				transactionConsultationId={tmpData?.transactionConsultationId || ''}
				setFetchTrue={getList}
			/>
		</Card>
	);
}

function calculateNumberOfScheduleType(schedules: ITodayScheduleItem[]) {
	const data = {
		personalEvent: 0,
		personalEventBlocked: 0,
		telemedicine: 0,
		consultNow: 0,
		appointment: 0,
		medicalService: 0,
	};

	for (const schedule of schedules) {
		switch (schedule.type) {
			case EScheduleTypes['personal-event']:
				++data.personalEvent;
				break;
			case EScheduleTypes['personal-event-blocked']:
				++data.personalEventBlocked;
				break;
			case EScheduleTypes.telemedicine:
				++data.telemedicine;
				break;
			case EScheduleTypes['consult-now']:
				++data.consultNow;
				break;
			case EScheduleTypes.appointment:
				++data.appointment;
				break;
			case EScheduleTypes['medical-service']:
				++data.medicalService;
				break;
		}
	}

	return { ...data, total: schedules.length };
}

function getScheduleType(type?: EScheduleTypes) {
	if (!type) return '';

	let scheduleType = type
		.split('-')
		.map((type) => type[0].toUpperCase() + type.slice(1))
		.join(' ');

	if (scheduleType === 'Telemedicine' || scheduleType === 'Consult Now') {
		scheduleType = `Medeasy App (${scheduleType})`;
	}
	return scheduleType;
}

function isUseTeleconferenceRoom(type?: EScheduleTypes) {
	switch (type) {
		case EScheduleTypes['consult-now']:
		case EScheduleTypes.telemedicine:
		case EScheduleTypes.appointment:
		case EScheduleTypes['appointment-personal']:
			return true;
		default:
			return false;
	}
}

const ScheduleCardSubtitle = styled.div`
	margin: 0;
	margin-top: 24px;
	padding-bottom: 12px;
	border-bottom: 1px solid ${({ theme }) => theme.colors.gray30};
`;

const ScheduleInfoTag = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 4px;
`;

const ScheduleTagMarker = styled.div`
	border-radius: 0px 4px 4px 0px;
	overflow: hidden;
`;

const PatientScheduleContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-bottom: 12px;
	margin-top: 12px;
	column-gap: 12px;
	border-bottom: 1px solid ${({ theme }) => theme.colors.ash300};
`;

const PatientInfoContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 12px;
`;

const InRoomContainer = styled.div`
	margin: 0 20px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 4px 12px;
	gap: 8px;
	background: ${({ theme }) => theme.colors.green};
	border-radius: 24px;
`;

const OutlinedButton = styled.button`
	padding: 10px 12px;
	background: none;
	border: 1px solid ${({ theme }) => theme.colors.primary};
	border-radius: 10px;
	font-weight: 600;
	font-size: 14px;
	color: ${({ theme }) => theme.colors.primary};
	cursor: pointer;
`;
