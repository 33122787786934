import React from 'react';
import { Col, Row, Typography, Button } from 'antd';
import { IconChevronRightPrimary } from '../assets/icons';
import styled from 'styled-components';
import COLORS from '../assets/globalColors';
import useWindowSize from '../hooks/useWindowSize';
import { useHistory, useLocation } from 'react-router-dom';
import { IconButtonAppStore, IconButtonGooglePlay } from '../assets/images';
import { useGlobal } from '../context/global.context';

const { Title } = Typography;
const HomeHero: React.FC = () => {
	const location = useLocation();
	const history = useHistory();
	const isApplicationPage = location.pathname === '/our-apps';
	const { isMobile } = useWindowSize();

	const { setIsShowModalChooseRole } = useGlobal();

	return (
		<section id="hero">
			<div className="container">
				<Row className="hero-grid" gutter={[40, 40]} align="middle" wrap={true}>
					<Col xs={24} lg={{ flex: 'auto' }} className="hero-text">
						{isApplicationPage ? (
							<Title level={1} style={{ marginBottom: 25, fontSize: 55 }}>
								Access healthcare made <br /> easy with Medeasy
							</Title>
						) : (
							<Title level={1} style={{ marginBottom: 25, fontSize: 55 }}>
								Get Ready for Healthcare MadeEasy
							</Title>
						)}

						<Title level={2} style={{ marginTop: 15, color: '#000' }}>
							{isApplicationPage
								? 'Holistic healthcare at your fingertips'
								: 'Your health needs in one app'}
						</Title>
						{isApplicationPage ? (
							<Row
								gutter={[40, 20]}
								style={{ marginTop: isMobile ? 60 : 80 }}
								className="items-center"
							>
								<Col>
									<Typography.Text className="text-gray-60 text-4.5 font-medium">
										Download Now
									</Typography.Text>
								</Col>
								<Col className="flex items-center" style={{ columnGap: 25 }}>
									<a
										className="cursor-pointer"
										href="https://play.google.com/store/apps/details?id=com.medeasyapp&hl=en&gl=US"
										target="_blank"
										rel="noreferrer"
									>
										<IconButtonGooglePlay />
									</a>

									<a
										className="cursor-pointer"
										href="https://apps.apple.com/id/app/medeasy-ph/id6443653585?l=id"
										target="_blank"
										rel="noreferrer"
									>
										<IconButtonAppStore />
									</a>
								</Col>
							</Row>
						) : (
							<Row gutter={[40, 20]} style={{ marginTop: isMobile ? 60 : 80 }}>
								<Col>
									<BtnGetStarted onClick={() => setIsShowModalChooseRole(true)}>
										Get Started
									</BtnGetStarted>
								</Col>
								<Col
									className="flex items-center gap-x-3 cursor-pointer"
									onClick={() => history.push('/our-apps')}
								>
									<BtnDownload type="text">Download Medeasy App</BtnDownload>
									<IconChevronRightPrimary />
								</Col>
							</Row>
						)}
					</Col>
					<Col xs={24} lg={{ flex: '600px' }} className="hero-image">
						<div className="object-hero">
							{isApplicationPage ? (
								<img src="/images/medeasy-app-2.png" alt="Medeasy App" />
							) : (
								<img src="/images/medeasy-app.png" alt="Medeasy App" />
							)}
						</div>
					</Col>
				</Row>
			</div>
		</section>
	);
};
export default HomeHero;

const BtnGetStarted = styled.button`
	width: 166px;
	height: 52px;

	background-color: ${COLORS.primary};
	color: #fff;
	border-radius: 9999px;
	font-weight: 600;
	outline: none;
	font-size: 20px;
	border: none;
	cursor: pointer;
`;

const BtnDownload = styled(Button)`
	span {
		font-weight: 600;
		color: ${COLORS.primary};
	}
`;
