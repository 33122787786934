import moment from 'moment';
import { useMemo } from 'react';
import { theme } from '../../../../assets/theme';
import { EConsultationType } from '../../../../types/transactionConsultation.type';
import { ReactComponent as AppointmentIcon } from '../../../../assets/icons/icon-appointment.svg';
import { ReactComponent as TelemedicineIcon } from '../../../../assets/icons/icon-telemedicine.svg';

interface Props {
	consultationType?: EConsultationType;
	partnerName?: string;
	partnerAddress?: string;
}

export default function TeleconferenceRoomInfo(props: Props) {
	const Icon = useMemo(() => {
		if (!props.consultationType) return;

		switch (props.consultationType) {
			case EConsultationType.CONSULT_NOW_CHAT_CONSULTATION:
			case EConsultationType.CONSULT_NOW_VIDEO_CHAT_CONSULTATION:
			case EConsultationType.TELEMEDICINE_CHAT_CONSULTATION:
			case EConsultationType.TELEMEDICINE_VIDEO_CHAT_CONSULTATION:
				return (
					<div className="p-3 bg-primary flex">
						<TelemedicineIcon
							width={22}
							height={22}
							color={theme.colors.white}
						/>
					</div>
				);
			case EConsultationType.FACE_TO_FACE_CONSULTATION:
				return (
					<div className="p-3 bg-blue flex">
						<AppointmentIcon
							width={22}
							height={22}
							color={theme.colors.white}
						/>
					</div>
				);
		}
	}, [props.consultationType]);

	return (
		<div className="max-w-sm">
			{Icon !== undefined && (
				<div className="w-fit mx-auto rounded-full overflow-hidden">{Icon}</div>
			)}
			<p className="m-0 mt-4 text-center text-5.5 font-bold">
				{getTitle(props.consultationType)}
			</p>
			<p className="m-0 mt-4 text-5 text-center">
				{moment().format('ddd, MMM DD YYYY')}
			</p>
			{props.partnerName && (
				<p className="m-0 mt-4 text-center text-5">{props.partnerName}</p>
			)}
			{props.partnerAddress && (
				<p className="m-0 mt-1 text-center text-3.5 text-gray-100 opacity-50">
					{props.partnerAddress}
				</p>
			)}
		</div>
	);
}

function getTitle(type?: EConsultationType) {
	switch (type) {
		case EConsultationType.CONSULT_NOW_CHAT_CONSULTATION:
		case EConsultationType.CONSULT_NOW_VIDEO_CHAT_CONSULTATION:
			return 'Consult Now';
		case EConsultationType.TELEMEDICINE_CHAT_CONSULTATION:
		case EConsultationType.TELEMEDICINE_VIDEO_CHAT_CONSULTATION:
			return 'Telemedicine';
		case EConsultationType.FACE_TO_FACE_CONSULTATION:
			return 'In Person Consultation';
		default:
			return 'Consultation';
	}
}
