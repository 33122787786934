import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import {
	DrugMedicineProperties,
	initialDrugMedicine,
} from '../../../../../../types/drugMedicine';
import { RxPrescriptionProperties } from '../../../../../../types/rxPrescription.type';
import { TemplateRxPrescriptionProperties } from '../../../../../../types/templateRxPrescription.type';
import ModalContentAddMedicine from './ModalContentAddMedicine';
import ModalContentFormAddMedicine from './ModalContentFormAddMedicine';
import ModalContentSelectedMedicine from './ModalContentSelectedMedicine';
import ModalContentSelectedTemplate from './ModalContentSelectedTemplate';
import { Button } from '../../../../../Button/Button/Button';
import { PlusCircle } from 'react-feather';

export enum EModalAddMedicineState {
	ADD_MEDICINE = 'ADD_MEDICINE',
	FORM_ADD_MEDICINE = 'FORM_ADD_MEDICINE',
	SELECTED_MEDICINE = 'SELECTED_MEDICINE',
	SELECTED_TEMPLATE = 'SELECTED_TEMPLATE',
}

interface Props {
	isShow: boolean;
	isHideTemplate?: boolean;
	onCloseModal: () => void;
	setPrescription: React.Dispatch<
		React.SetStateAction<RxPrescriptionProperties | undefined>
	>;
}

export default function ModalAddMedicine(props: Props) {
	const [state, setState] = useState(EModalAddMedicineState.ADD_MEDICINE);

	const [selectedTemplate, setSelectedTemplate] =
		useState<TemplateRxPrescriptionProperties>();
	const [selectedMedicine, setSelectedMedicine] =
		useState<DrugMedicineProperties>();
	const [isSearchNotFound, setIsSearchNotFound] = useState<boolean>(false);

	useEffect(() => {
		setState(EModalAddMedicineState.ADD_MEDICINE);
	}, [props.isShow]);

	return (
		<Modal
			open={props.isShow}
			onCancel={props.onCloseModal}
			footer={
				state === EModalAddMedicineState.ADD_MEDICINE ? (
					<>
						<Button
							onClick={() => {
								setState(EModalAddMedicineState.FORM_ADD_MEDICINE);
								setSelectedMedicine(undefined);
							}}
							type={isSearchNotFound ? 'SOLID' : 'SOLIDASH'}
						>
							<>
								<PlusCircle width={20} height={20} style={{ marginRight: 4 }} />
								Add New Medicine
							</>
						</Button>
					</>
				) : null
			}
			title={<p className="m-0 text-center font-semibold">Add Medicine</p>}
			bodyStyle={{ padding: 0 }}
			wrapClassName="modal-h-full"
			style={{ height: 700 }}
		>
			<div className="flex-1 min-h-0">
				{state === EModalAddMedicineState.ADD_MEDICINE ? (
					<ModalContentAddMedicine
						state={state}
						setState={setState}
						setSelectedTemplate={setSelectedTemplate}
						setSelectedMedicine={setSelectedMedicine}
						isHideTemplate={props.isHideTemplate}
						setIsSearchNotFound={setIsSearchNotFound}
					/>
				) : state === EModalAddMedicineState.FORM_ADD_MEDICINE ? (
					<ModalContentFormAddMedicine
						setState={setState}
						medicine={selectedMedicine}
						setPrescription={props.setPrescription}
						onCloseModal={props.onCloseModal}
					/>
				) : state === EModalAddMedicineState.SELECTED_MEDICINE ? (
					<ModalContentSelectedMedicine
						setState={setState}
						medicine={selectedMedicine!}
						setSelectedMedicine={setSelectedMedicine}
					/>
				) : state === EModalAddMedicineState.SELECTED_TEMPLATE ? (
					<ModalContentSelectedTemplate
						setState={setState}
						template={selectedTemplate}
						setPrescription={props.setPrescription}
						onCloseModal={props.onCloseModal}
					/>
				) : undefined}
			</div>
		</Modal>
	);
}
