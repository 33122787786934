import { ReactComponent as SpinnerIcon } from '../assets/icons/spinner.svg';

interface Props {
	size: number;
	color: string;
}

export default function Spinner(props: Props) {
	return (
		<SpinnerIcon width={props.size} height={props.size} color={props.color} />
	);
}
