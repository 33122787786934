import React, { useContext, useEffect, useState } from 'react';
import { Card, Button, Typography } from 'antd';
import styled from 'styled-components';
import { DatesContext } from '../../../screens/app/Menstruation/Index';
import moment from 'moment';
import './styles.less';
import AppToolsList from '../AppToolsList';

type Props = {
	nextBtn: () => void;
	backBtn: () => void;
};

const { Title } = Typography;

const dayOption: Intl.DateTimeFormatOptions = {
	weekday: 'short',
};

const monthOption: Intl.DateTimeFormatOptions = {
	month: 'short',
};

const getDay = (date: Date) => {
	const day = new Intl.DateTimeFormat('en-US', dayOption).format(date);
	return String(`${day}`);
};

const getMonth = (date: Date) => {
	const month = new Intl.DateTimeFormat('en-US', monthOption).format(date);
	return String(`${month}`);
};

const getDayMonthDate = (date: Date) => {
	const day = getDay(date);
	const month = getMonth(date);
	const dateS = date.getDate();
	return String(`${day}, ${month} ${dateS}`);
};

const dateToISOString = (date: Date) => {
	const myDate = new Date(new Date(date).setHours(12));
	return myDate.toISOString().replace('-', '/').split('T')[0].replace('-', '/');
};

const timeToOvulation = (date2: Date, date1: Date, mensCycle: number) => {
	const diff = (date2.getTime() - date1.getTime()) / 1000;
	const diffNorm = Math.floor(diff / 86400) + 1;
	if (diffNorm < 0) {
		return mensCycle + diffNorm;
	}
	return Math.floor(diff / 86400) + 1;
};

const getCurrFerInterval = (ferStart: Date, ferEnd: Date) => {
	const dateFerStart = ferStart.getDate().toString();
	const dateFerEnd = ferEnd.getDate().toString();
	const monthFerStart = getMonth(ferStart);
	const monthFerEnd = getMonth(ferEnd);
	const ferInterval = `${monthFerStart} ${dateFerStart} - ${monthFerEnd} ${dateFerEnd}`;
	return ferInterval;
};

const getIncomingFerInterval = (delta: number) => {
	const nDate = new Date();
	const ferStart = moment(nDate, 'YYYY/MM/DD').add(delta, 'days').toDate();
	const ferEnd = moment(ferStart, 'YYYY/MM/DD').add(4, 'days').toDate();
	const dateFerStart = ferStart.getDate().toString();
	const dateFerEnd = ferEnd.getDate().toString();
	const monthFerStart = getMonth(ferStart);
	const monthFerEnd = getMonth(ferEnd);
	const ferInterval = `${monthFerStart} ${dateFerStart} - ${monthFerEnd} ${dateFerEnd}`;
	return ferInterval;
};

const getIncomingOvulation = (delta: number) => {
	const nDate = new Date();
	const ovulation = moment(nDate, 'YYYY/MM/DD').add(delta, 'days').toDate();
	const dateOvluation = ovulation.getDate().toString();
	const monthOvulation = getMonth(ovulation);
	const nextOvluation = `${monthOvulation} ${dateOvluation}`;
	return nextOvluation;
};

const getCurrOvulation = (date: Date) => {
	const dateOvluation = date.getDate().toString();
	const monthOvulation = getMonth(date);
	const currOvluation = `${monthOvulation} ${dateOvluation}`;
	return currOvluation;
};

const getIncomingMens = (delta: number) => {
	const nDate = new Date();
	const mens = moment(nDate, 'YYYY/MM/DD').add(delta, 'days').toDate();
	const dateMens = mens.getDate().toString();
	const monthMens = getMonth(mens);
	const nextMens = `${monthMens} ${dateMens}`;
	return nextMens;
};

const getWordFromString = (text: string, index: number) => {
	const myArray = text.split(' ');
	return myArray[index];
};

const getSplittedMonth = (text: string) => {
	const myArray = text.split(' ');
	if (myArray[0] === myArray[3]) {
		return myArray[0];
	} else {
		return String(`${myArray[0]} - ${myArray[3]}`);
	}
};

export default function ResultCalculator(Props: Props) {
	const datesContext = useContext(DatesContext);
	const { dates } = datesContext;
	const [status, setStatus] = useState<string>('');
	const [message, setMessage] = useState<string>('');
	const [suggest, setSuggest] = useState<string>('');
	const [fertileWindow, setFertileWindow] = useState<string>('');
	const [ovulation, setOvulation] = useState<string>('');
	const [nextPeriod, setNextPeriod] = useState<string>('');

	const now = dates?.currDates?.at(0);
	const incomingFertile = dates?.fertileStart?.at(0);
	const mensCycle = dates?.mensCycle;

	useEffect(() => {
		const nDate = new Date();
		setStatus(statusNow(now ?? nDate));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dates]);

	const isNowMens = (date: Date) => {
		for (let i = 0; i < 3; i++) {
			if (
				date >= new Date(dates?.mensStart?.at(i) ?? new Date()) &&
				date <= new Date(dates?.mensEnd?.at(i) ?? new Date())
			) {
				return true;
			}
		}
		return false;
	};

	const isNowFertile = (date: Date) => {
		for (let i = 0; i < 3; i++) {
			if (
				date >= new Date(dates?.fertileStart?.at(i) ?? new Date()) &&
				date <= new Date(dates?.fertileEnd?.at(i) ?? new Date())
			) {
				isNowOvulation(date);
				const ferStart = new Date(dates?.fertileStart?.at(i) ?? new Date());
				const ferEnd = new Date(dates?.fertileEnd?.at(i) ?? new Date());
				const ferInterval = getCurrFerInterval(ferStart, ferEnd);
				setFertileWindow(ferInterval);
				return true;
			}
		}
		return false;
	};

	const isNowOvulation = (date: Date) => {
		const nDate = new Date();
		for (let i = 0; i < 3; i++) {
			if (
				dateToISOString(date) ===
				dateToISOString(dates?.nextOvul?.at(i) ?? nDate)
			) {
				const incomingMens = getIncomingMens(14);
				const currOvulation = getCurrOvulation(date);
				setNextPeriod(incomingMens);
				setOvulation(currOvulation);
				return;
			}
		}
		if (date < new Date(dates?.nextOvul?.at(0) ?? nDate)) {
			const delta = timeToOvulation(
				new Date(dates?.nextOvul?.at(0) ?? nDate),
				date,
				0,
			);
			const incomingOvulation = getIncomingOvulation(delta);
			const incomingMens = getIncomingMens(delta + 14);
			setOvulation(incomingOvulation);
			setNextPeriod(incomingMens);
			return;
		} else {
			const delta = timeToOvulation(
				new Date(dates?.nextOvul?.at(0) ?? nDate),
				date,
				0,
			);
			const incomingOvulation = getIncomingOvulation(delta + 3);
			const incomingMens = getIncomingMens(delta + 17);
			setOvulation(incomingOvulation);
			setNextPeriod(incomingMens);
		}
		return false;
	};

	const statusNow = (date: Date) => {
		const nDate = new Date();
		if (isNowMens(date)) {
			const delta = timeToOvulation(
				incomingFertile ?? nDate,
				now ?? nDate,
				mensCycle ?? 0,
			);
			const incomingMens = getIncomingMens(delta + 17);
			const incomingOvulation = getIncomingOvulation(delta + 3);
			const ferInterval = getIncomingFerInterval(delta);
			setMessage(`Ovulation in ${delta} ${delta > 1 ? 'days' : 'day'}`);
			setSuggest('Low chance of getting pregnant');
			setFertileWindow(ferInterval);
			setOvulation(incomingOvulation);
			setNextPeriod(incomingMens);
			return 'Period Day';
		}
		if (isNowFertile(date)) {
			setMessage('');
			setSuggest('High chance of getting pregnant');
			if (nextPeriod === 'x') {
				console.log('');
			}
			return 'Fertile Day';
		}
		const delta = timeToOvulation(
			incomingFertile ?? nDate,
			now ?? nDate,
			mensCycle ?? 0,
		);
		const incomingMens = getIncomingMens(delta + 17);
		const incomingOvulation = getIncomingOvulation(delta + 3);
		const ferInterval = getIncomingFerInterval(delta);
		setSuggest('Low chance of getting pregnant');
		setMessage(`Ovulation in ${delta} ${delta > 1 ? 'days' : 'day'}`);
		setFertileWindow(ferInterval);
		setOvulation(incomingOvulation);
		setNextPeriod(incomingMens);
		return 'Ordinary Day';
	};

	return (
		<>
			<Card style={CardWrapperStyles}>
				<ResultsWrapper>
					<CircleContainer>
						<div>{getDayMonthDate(now ?? new Date())}</div>
						<Title level={3} style={ResTitleSyles}>
							{status}
						</Title>
						<div>
							<div>{message}</div>
							<div style={SuggestStyles}>{suggest}</div>
						</div>
					</CircleContainer>
					<Button type="primary" size="large" onClick={() => Props.nextBtn()}>
						Log Period
					</Button>
					<ResultDatesContainer>
						<ResultDatesItem>
							<div style={FerDateWrapper}>
								<div style={FerDateStyles}>
									<Title level={3} style={ResTitleSyles}>
										{getWordFromString(fertileWindow, 1)} -{' '}
										{getWordFromString(fertileWindow, 4)}
									</Title>
									<Title level={2} style={{ ...ResTitleSyles, color: 'black' }}>
										{getSplittedMonth(fertileWindow)}
									</Title>
								</div>
							</div>
							<div style={ResTextStyles}>Fertile Window</div>
						</ResultDatesItem>
						<ResultDatesItem>
							<Title level={3} style={ResTitleSyles}>
								{getWordFromString(ovulation, 1)}
							</Title>
							<Title level={2} style={{ ...ResTitleSyles, color: 'black' }}>
								{getWordFromString(ovulation, 0)}
							</Title>
							<div style={ResTextStyles}>Approximation Ovulation</div>
						</ResultDatesItem>
						<ResultDatesItem>
							<Title level={3} style={ResTitleSyles}>
								{getWordFromString(nextPeriod, 1)}
							</Title>
							<Title level={2} style={{ ...ResTitleSyles, color: 'black' }}>
								{getWordFromString(nextPeriod, 0)}
							</Title>
							<div style={ResTextStyles}>Next Period Prediction</div>
						</ResultDatesItem>
					</ResultDatesContainer>
				</ResultsWrapper>
				<Title level={5}>Track your health with our other tools</Title>
				<OtherToolsContainer>
					<AppToolsList exclude="menstruation" />
				</OtherToolsContainer>
			</Card>
			<FooterContainer>
				<Button size="large" type="primary" onClick={() => Props.backBtn()}>
					Calculate
				</Button>
			</FooterContainer>
		</>
	);
}

const CardWrapperStyles: React.CSSProperties = {
	paddingTop: 26,
	minHeight: '600px',
	borderRadius: 0,
};

const ResTitleSyles: React.CSSProperties = {
	fontSize: 24,
	margin: 0,
};

const ResTextStyles: React.CSSProperties = {
	// border: "1px solid black",
	marginTop: 12,
	display: 'block',
	lineHeight: '100%',
	width: 100,
	textAlign: 'center',
};

const FerDateStyles: React.CSSProperties = {
	// border: "1px solid black",
	textAlign: 'center',
};

const FerDateWrapper: React.CSSProperties = {
	// border: "1px solid black",
	display: 'flex',
};

const SuggestStyles: React.CSSProperties = {
	width: 150,
	lineHeight: '100%',
	textAlign: 'center',
	fontSize: 14,
	color: 'gray',
};

const CircleContainer = styled.div`
	border: 0.5px solid #dcdcdc;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	width: 234px;
	height: 234px;
	border-radius: 50%;
	padding-top: 34px;
	padding-bottom: 34px;
`;

const ResultDatesItem = styled.div`
	border: 0.5px solid #dcdcdc;
	height: 100%;
	width: 205px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 24px;
	padding-bottom: 24px;
	border-radius: 12px;
`;

const ResultDatesContainer = styled.div`
	// border: 1px solid black;
	width: 100%;
	display: flex;
	justify-content: center;
	gap: 16px;
`;

const ResultsWrapper = styled.div`
	// border: 1px solid black;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	gap: 18px;
	margin-bottom: 24px;
`;

const OtherToolsContainer = styled.div`
	// border: 1px solid black;
	margin-top: 24px;
	margin-bottom: 24px;
	display: flex;
	gap: 24px;
	justify-content: start;
	align-items: center;
	width: 100%;
`;

const FooterContainer = styled.div`
	// border: 1px solid black;
	margin-top: 0.5px;
	display: flex;
	width: 100%;
	justify-content: flex-end;
	left: 12px;
	top: 12px;
	border-bottom-left-radius: 12px;
	border-bottom-right-radius: 12px;
	padding: 12px;
	padding-right: 16px;
	background-color: white;
`;
