import moment from 'moment';

export function convertDateToSameDateForScheduleTime(
	date: string | Date,
	sameData?: {
		year: number;
		month: number;
		date: number;
	},
) {
	date = new Date(date);

	date.setFullYear(sameData?.year || 2022);
	date.setMonth(sameData?.month || 0);
	date.setDate(sameData?.date || 1);
	date.setSeconds(0);

	return date;
}

export function createTimeArray(
	interval?: number,
	startAt?: number,
	endAt?: number,
) {
	let times = [];
	if (startAt !== undefined && endAt !== undefined) {
		let start = startAt * 60;
		const defaultInterval = 30;
		const morning = 11 * 60;
		const afternoon = 17 * 60;
		const evening = 19 * 60;

		for (let i = 0; start < (endAt === 0 ? 24 : endAt) * 60; i++) {
			const hh = Math.floor(start / 60);
			const mm = start % 60;
			times[i] = {
				times: ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2),
				timeGroup:
					start < morning
						? 'morning'
						: start < afternoon
						? 'afternoon'
						: start < evening
						? 'evening'
						: 'night',
			};

			start = start + (interval ? interval : defaultInterval);

			// console.info('')
			// console.info('createTimeArray', JSON.stringify(times))
			// console.info('start', JSON.stringify(start))
			// console.info('')
		}
	}
	return times;
}

export function createTimeArraySimple(
	interval?: number,
	startAt?: number,
	endAt?: number,
) {
	let times = [];
	// console.info('startAt - endAt', startAt, endAt)
	if (startAt !== undefined && endAt !== undefined) {
		let start = startAt * 60;
		const defaultInterval = 30;

		// console.info('inside', start, endAt * 60)

		for (let i = 0; start < endAt * 60; i++) {
			const hh = Math.floor(start / 60);
			const mm = start % 60;

			times[i] = ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2);

			start = start + (interval ? interval : defaultInterval);
		}
	}
	return times;
}

export function calculateAge(date?: Date | string) {
	// console.info('calculateAge', date);
	if (!date) {
		return undefined;
	}
	date = new Date(date);
	return moment().diff(moment(date), 'years');
}
