import { useEffect, useState } from 'react';
import { ArrowLeft } from 'react-feather';
import { useHistory } from 'react-router-dom';
import { LabRequestTemplateProperties } from '../../../../types/templateLabRequest';
import { Button } from '../../../Button/Button/Button';
import Separator from '../../../Separator';
import FormCreateLabRequestTemplate from './FormCreateLabRequestTemplate';
import ListLabRequestTemplate from './ListLabRequestTemplate';
import { ICDProperties } from '../../../../types/icd.type';

export default function MainLabRequestTemplate() {
	const history = useHistory();

	const [step, setStep] = useState(0);
	const [title, setTitle] = useState('Lab Request Template');
	const [isEditing, setIsEditing] = useState(false);
	const [icds, setIcds] = useState<ICDProperties[]>();

	const [selectedTemplate, setSelectedTemplate] =
		useState<LabRequestTemplateProperties>();

	useEffect(() => {
		if (!isEditing) return;

		let _title = title;
		if (_title.includes('Create')) {
			_title = _title.replace('Create', 'Edit');
		}
		setTitle(_title);
	}, [isEditing, title]);

	function nextStep() {
		const _step = step + 1;
		if (_step === 1) {
			setTitle(`${isEditing ? 'Edit' : 'Create'} Lab Request Template`);
			setStep(_step);
		}
	}

	function onBack() {
		const _step = step - 1;
		if (_step >= 0) {
			if (_step === 0) {
				setIsEditing(false);
				setTitle('Lab Request Template');
			}
			setStep(_step);
		} else {
			history.goBack();
		}
	}

	function createLabRequestTemplate() {
		setSelectedTemplate(undefined);
		nextStep();
	}

	return (
		<div className="flex-1 flex flex-col">
			<div className="relative px-6 py-5.5 flex items-center">
				<button
					onClick={onBack}
					className="border-none bg-transparent p-0 absolute flex items-center gap-x-1 cursor-pointer"
				>
					<ArrowLeft width={20} height={20} />
					<p className="m-0 font-bold text-3.5">Back</p>
				</button>
				<h1 className="font-semibold text-6 m-0 w-full text-center">{title}</h1>
				{step === 0 && (
					<div className="w-24 absolute right-6">
						<Button type="SOLID" onClick={createLabRequestTemplate}>
							Create
						</Button>
					</div>
				)}
			</div>
			<Separator />
			{step === 0 ? (
				<ListLabRequestTemplate
					setSelectedTemplate={setSelectedTemplate}
					nextStep={nextStep}
					setIsEditing={setIsEditing}
					icds={icds}
					setIcds={setIcds}
				/>
			) : step === 1 ? (
				<FormCreateLabRequestTemplate
					template={selectedTemplate}
					setTemplate={setSelectedTemplate}
					onBack={onBack}
					icds={icds}   
					setIcds={setIcds} 
				/>
			) : undefined}
		</div>
	);
}
