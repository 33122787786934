interface Props {
	isEnableRemoveBtn: boolean;
	isEnableSaveBtn: boolean;
	onRemove: () => void;
	onSave: () => void;
}

export default function ButtonRemoveSave(props: Props) {
	return (
		<div className="flex gap-x-2.5">
			<button
				onClick={props.isEnableRemoveBtn ? props.onRemove : undefined}
				className={
					'flex-1 p-3.5 rounded-4 border-none text-3.5 font-bold bg-ash-300' +
					(props.isEnableRemoveBtn ? ' cursor-pointer' : '')
				}
			>
				Remove
			</button>
			<button
				onClick={props.isEnableSaveBtn ? props.onSave : undefined}
				className={`flex-1 p-3.5 rounded-4 border-none text-3.5 font-bold ${
					props.isEnableSaveBtn
						? 'cursor-pointer bg-primary text-white'
						: 'bg-ash-300'
				}`}
			>
				Save
			</button>
		</div>
	);
}
