import { useState } from 'react';
import { ArrowLeft } from 'react-feather';
import { useHistory } from 'react-router-dom';
import Separator from '../../../Separator';
import ListICD from './ListICD';

export default function MainICD() {
  const history = useHistory();
  const [title] = useState('ICD List');

  function onBack() {
    history.goBack();
  }

  return (
    <div>
      <div className="relative px-6 py-5.5 flex items-center">
        <button
          onClick={onBack}
          className="border-none bg-transparent p-0 absolute flex items-center gap-x-1 cursor-pointer"
        >
          <ArrowLeft width={20} height={20} />
          <p className="m-0 font-bold text-3.5">Back</p>
        </button>
        <h1 className="font-semibold text-6 m-0 w-full text-center">{title}</h1>
      </div>
      <Separator />
      <ListICD />
    </div>
  );
}
