import styled from 'styled-components';
import CardBalance from '../../components/Webapp/Home/CardBalance';
import CardPoint from '../../components/Webapp/Home/CardPoint';
import CardRecentCreatedDocuments from '../../components/Webapp/Home/CardRecentCreatedDocuments';
import CardSchedule from '../../components/Webapp/Home/CardSchedule';
import AppLayout from '../../layout/AppLayout';
import { useEffect } from 'react';
import { message, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../context/auth.context';
import { apiPostPointReward } from '../../types/pointReward';
import { EPointCategory } from '../../types/point.type';

const activePath = '/app';

export default function HomeScreen() {
	const history = useHistory();
	const { user, isDoctor, doctorKYCStatus, isLoggedIn } = useAuth();

	useEffect(() => {
		if (user && user.firstName) {
			if (!isDoctor) {
				notification['warning']({
					key: 'alert-kyc-status',
					message: 'Your account is not a doctor',
					description:
						'Please complete the registration form if you want to continue as a doctor. [4]',
					// onClick: () => {
					//   console.log('Notification Clicked!');
					// },
				});
				// logout();
				history.push('/register');
			} else if (isDoctor && doctorKYCStatus === 'WAITING_APPROVAL') {
				notification['warning']({
					key: 'alert-kyc-status',
					message: 'Your account is still waiting approval',
					description:
						'You can use full features if your account has been approved.',
					// onClick: () => {
					//   console.log('Notification Clicked!');
					// },
				});
			} else if (isDoctor && doctorKYCStatus === 'REJECTED') {
				notification['error']({
					key: 'alert-kyc-status',
					message: 'Your account is rejected',
					description:
						'Please fix your data and resubmit your registration data.',
					// onClick: () => {
					//   console.log('Notification Clicked!');
					// },
				});
				// logout();
			}
		}
	}, [user]);

	return (
		<AppLayout activePath={activePath}>
			<div className="flex flex-col gap-y-6">
				<TwoCols>
					<CardPoint />
					<CardBalance />
				</TwoCols>
				<CardSchedule />
				<CardRecentCreatedDocuments />
			</div>
		</AppLayout>
	);
}

const TwoCols = styled.div`
	width: 100%;
	display: flex;
	gap: 24px;
`;
