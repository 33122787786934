import { theme } from '../../../assets/theme';
import Spinner from '../../Spinner';

interface Props {
	children: any;
	type: 'OUTLINE' | 'SOLID' | 'SOLIDASH' | 'TRANSPARENT';
	isLoading?: boolean;
	isDisable?: boolean;
	className?: string;
	onClick: () => void;
}

export function Button(props: Props) {
	const isDisable = props.isDisable || props.isLoading;

	return (
		<button
			onClick={!isDisable ? props.onClick : undefined}
			type="button"
			className={
				'm-0 w-full p-2.5 border-solid flex items-center justify-center rounded-3 text-3.5 font-bold ' +
				(!isDisable ? 'cursor-pointer ' : '') +
				(!isDisable
					? props.type === 'OUTLINE'
						? 'bg-transparent border-primary text-primary'
						: props.type === 'SOLID'
						? 'bg-primary border-primary text-white'
						: props.type === 'SOLIDASH'
						? 'bg-ash-300 border-ash-300'
						: props.type === 'TRANSPARENT'
						? 'border-transparent bg-transparent text-primary'
						: undefined
					: 'bg-ash-300 border-ash-300 text-ash-700') +
				(props.className ? ' ' + props.className : '')
			}
		>
			{!props.isLoading ? (
				props.children
			) : (
				<Spinner size={22} color={theme.colors.ash700} />
			)}
		</button>
	);
}
