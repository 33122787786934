import { useEffect, useState } from 'react';
import { EScheduleTypes, ISchedule } from '../types/schedule.type';
import { ITodayScheduleItem } from '../types/todaySchedule.type';
import { useAuth } from '../context/auth.context';
import useSchedulesNew from './useSchedulesNew';
import merge from 'deepmerge';
import { combineMerge } from './useSchedulesNewData';
import moment from 'moment';
import {
	EConsultationType,
	TransactionConsultationProperties,
} from '../types/transactionConsultation.type';
import { PersonalEvent } from '../types/personalEvent.type';
import { getName } from '../helpers/name';
import { TransactionHealthServiceProps } from '../types/transactionHealthService.type';
import { findEventSettingByType } from '../constants';
import { IUser } from '../types/user.type';
import { apiGetMyProfile } from '../api/auth';

const todayStartAt = new Date();
todayStartAt.setHours(0);
todayStartAt.setMinutes(0);
todayStartAt.setSeconds(0);
todayStartAt.setMilliseconds(0);
const todayEndAt = new Date();
todayEndAt.setHours(23);
todayEndAt.setMinutes(59);
todayEndAt.setSeconds(59);
todayEndAt.setMilliseconds(999);

interface IProps {
	initLoad?: boolean;
	role: 'patient' | 'doctor' | 'secretary';
}

export default function useTodaySchedule(props: IProps) {
	const { token } = useAuth();
	const [todayScheduleList, setTodayScheduleList] = useState<
		ITodayScheduleItem[]
	>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [userId, setUserId] = useState<string | undefined>('');

	useEffect(() => {
		(async () => {
			let resultUser: IUser = await apiGetMyProfile(token);
			setUserId(resultUser?.userId);
		})();
	}, [token]);

	const { apiGetListSchedule } = useSchedulesNew({
		userId: userId!,
	});

	useEffect(() => {
		getList();
	}, []);

	const getList = async () => {
		setIsLoading(true);
		let resultUser: IUser = await apiGetMyProfile(token);

		const res = await apiGetListSchedule(
			{
				startAt: todayStartAt.toISOString(),
				endAt: todayEndAt.toISOString(),
			},
			resultUser?.userId,
		);

		let newList: ISchedule[] = [];
		for (const arr of Object.values(res)) {
			newList = [...newList, ...arr];
		}

		newList = merge(newList, [], {
			arrayMerge: combineMerge,
		});

		let newMappedList: ITodayScheduleItem[] = [];

		for (const data of newList) {
			for (const item of data.data) {
				if (
					data.name === EScheduleTypes['personal-event'] ||
					data.name === EScheduleTypes['personal-event-blocked']
				) {
					newMappedList.push({
						type: data.name,
						time: item.startTime + '-' + item.endTime,
						name: (item.raw as PersonalEvent)?.title,
						settings: findEventSettingByType(data.name),
						data: item,
					});
				} else if (
					data.name === EScheduleTypes.telemedicine ||
					data.name === EScheduleTypes['consult-now'] ||
					data.name === EScheduleTypes.appointment ||
					data.name === EScheduleTypes['telemedicine-personal'] ||
					data.name === EScheduleTypes['appointment-personal']
				) {
					newMappedList.push({
						type: data.name,
						time: item.startTime + '-' + item.endTime,
						name: getName(
							(item.raw as TransactionConsultationProperties)?.metaDoctor,
						),
						settings: findEventSettingByType(data.name),
						data: item,
					});
				} else if (data.name === EScheduleTypes['medical-service']) {
					newMappedList.push({
						type: data.name,
						time: item.startTime + '-' + item.endTime,
						name:
							(item.raw as TransactionHealthServiceProps)?.metaPartner
								?.partnerName || '',
						settings: findEventSettingByType(data.name),
						data: item,
					});
				}
			}
		}

		newMappedList.sort((a, b) => moment(a.time).diff(b.time));
		newMappedList = newMappedList.filter((_item, index) => index < 3);

		setTodayScheduleList(newMappedList);
		setIsLoading(false);
	};

	return {
		todayScheduleList,
		isLoading,
		getList,
	};
}
