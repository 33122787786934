import React from 'react';
import { Modal, Typography, InputNumber, Row, Col, Button, Form } from 'antd';
import styled from 'styled-components';
import { IconRoleDoctor, IconRolePatient } from '../../assets/images';

const { Text, Paragraph, Title } = Typography;

interface SignInModalProps {
	role: 'Patient' | 'Doctor';
	showSignInModal: boolean;
	onCancel: () => void;
	onChange: (arg0: string) => void;
	onConfirm: () => void;
	phoneNumber: string;
}

function SignInModal({
	role,
	showSignInModal,
	onCancel,
	onChange,
	onConfirm,
	phoneNumber,
}: SignInModalProps) {
	return (
		<CustomModal
			centered
			visible={showSignInModal}
			footer={null}
			onCancel={onCancel}
		>
			<Row>{role === 'Patient' ? <IconRolePatient /> : <IconRoleDoctor />}</Row>
			<Row>
				<CustomModalTitle level={2}>Sign in as {role}</CustomModalTitle>
			</Row>
			<Row>
				<Text
					style={{
						fontSize: '18px',
						lineHeight: '20px',
						textAlign: 'center',
					}}
				>
					Input Phone Number and we will send the verification code to your
					phone number
				</Text>
			</Row>
			<Row>
				<Col span={24}>
					<CustomInputNumber
						type="number"
						placeholder="Enter phone number"
						addonBefore={'+63'}
						controls={false}
						onChange={(e) => {
							onChange(e as string);
						}}
					/>{' '}
					{(phoneNumber?.toString().length > 0 &&
						phoneNumber?.toString().length < 10) ||
					phoneNumber?.toString().length > 13 ? (
						<Text type="danger" style={{ fontSize: 14 }}>
							Character length must be between 10-13 characters
						</Text>
					) : null}
				</Col>
			</Row>
			<Row>
				<Paragraph style={{ textAlign: 'center' }}>
					By signing up, you are agree to our{' '}
					<Text
						className="strong-primary"
						style={{ cursor: 'pointer' }}
						onClick={() => window.open('/term-and-conditions', '_blank')}
					>
						Terms &amp; Conditions
					</Text>{' '}
					and{' '}
					<Text
						className="strong-primary"
						style={{ cursor: 'pointer' }}
						onClick={() => window.open('/privacy-policy', '_blank')}
					>
						Privacy Policy
					</Text>
				</Paragraph>
			</Row>
			<Button
				disabled={
					!phoneNumber ||
					phoneNumber?.toString().length < 10 ||
					phoneNumber?.toString().length > 13
				}
				type="primary"
				onClick={onConfirm}
				block
				style={{ margin: '6px 0 6px 0' }}
			>
				Next
			</Button>
		</CustomModal>
	);
}

export default SignInModal;

const CustomModalTitle = styled(Title)`
	font-weight: 700 !important;
	color: black !important;
	margin-bottom: 0 !important;
`;

const CustomModal = styled(Modal)`
	.ant-modal-title {
		font-size: 36px;
		font-weight: 700;
		color: black;
	}
	.ant-modal-body {
		display: grid;
		gap: 20px;
		display: flex;
		flex-direction: column;
		justify-content: center !important;
		align-items: center !important;
		padding: 3.75rem 3rem 3rem 3rem;
		max-width: 700px;
	}
	div.ant-typography {
		font-weight: 400;
		font-size: 16px;
		line-height: 22px;
		color: #748494;
	}

	.strong-primary {
		font-weight: 600 !important;
		color: #d81f64 !important;
	}
`;

const CustomInputNumber = styled(InputNumber)`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 12px;
	gap: 8px;
	width: 100%;
	background: #ffffff;
`;
