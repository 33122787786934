import React, { useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Modal, Result, Row, Typography } from 'antd';
import {
	DoctorSpecialistProperties,
	SpecialistProperties,
} from '../../../types/doctor.type';
import FormInput from '../../Form/FormInput';
import { IconSpecializationRegisterForm } from '../../../assets/images';

const { Text } = Typography;

interface Props {
	showModal: any;
	closeModal: () => void;
	subSpecializaionList: any;
	data: DoctorSpecialistProperties[];
	onConfirm: (data: SpecialistProperties[]) => void;
}

const ModalSubSpecialization = (props: Props) => {
	const [showAddSubSpecializationModal, setShowAddSubSpecializationModal] =
		useState<boolean>(false);

	const [search, setSearch] = useState<string>('');
	const [addSubSpecialization, setAddSubSpecialization] = useState<string>('');

	const [selectedSubSpecialization, setSelectedSubSpecialization] = useState<
		SpecialistProperties[]
	>([]);
	const [listSubSpecialization, setListSubSpecialization] = useState<
		SpecialistProperties[]
	>([]);

	useEffect(() => {
		setListSubSpecialization(props.subSpecializaionList);
	}, [props.subSpecializaionList]);

	const handleSelectItem = (data: SpecialistProperties) => {
		const selectedData = selectedSubSpecialization?.filter(
			(item) => item.specialistName === data.specialistName,
		);
		if (selectedData.length > 0) {
			const newSelectedData = selectedSubSpecialization.filter(
				(item) => item.specialistName !== data.specialistName,
			);
			setSelectedSubSpecialization(newSelectedData);
		} else {
			setSelectedSubSpecialization((oldValue) => [...oldValue, data]);
		}
	};

	const handleAddSubSpecialization = () => {
		const item = {
			specialistName: addSubSpecialization,
		};
		setSelectedSubSpecialization((oldValue) => [...oldValue, item]);
		setListSubSpecialization((oldValue) => [item, ...oldValue]);
		setSearch('');
		setAddSubSpecialization('');
		setShowAddSubSpecializationModal(false);
	};

	return (
		<Modal
			centered
			closable={true}
			open={props.showModal}
			onCancel={() => {
				props.closeModal();
			}}
			footer={[
				<Button
					key="back"
					onClick={() => {
						props.closeModal();
					}}
				>
					Cancel
				</Button>,
				<Button
					key="submit"
					type="primary"
					onClick={() => {
						props.onConfirm(selectedSubSpecialization);
						props.closeModal();
						setSelectedSubSpecialization([]);
					}}
				>
					Save
				</Button>,
			]}
		>
			<Row>
				<Text strong>Sub Specialization</Text>
			</Row>
			<Row>
				<Text type="secondary">
					Choose type of consultation that you want to participate
				</Text>
			</Row>
			<FormInput
				placeholder="Search Sub Specialization Name"
				onChange={(e) => setSearch(e)}
			/>
			<div style={{ height: 400, overflowY: 'scroll' }}>
				{listSubSpecialization?.filter((item) =>
					item.specialistName.toLowerCase().includes(search.toLowerCase()),
				)?.length > 0 ? (
					listSubSpecialization
						?.filter((item) =>
							item.specialistName.toLowerCase().includes(search.toLowerCase()),
						)
						.map((item: SpecialistProperties, index) => {
							return (
								<Row key={index}>
									<Checkbox
										onChange={(e) => handleSelectItem(e.target.value)}
										value={item}
										disabled={
											props.data.find(
												(selectedItem) =>
													selectedItem.subSpecialistName ===
														item.specialistName ||
													selectedItem.specialistName === item.specialistName,
											)
												? true
												: false
										}
										checked={
											selectedSubSpecialization.find(
												(selectedItem) =>
													selectedItem.specialistName === item.specialistName,
											) ||
											props.data.find(
												(selectedItem) =>
													selectedItem.subSpecialistName ===
														item.specialistName ||
													selectedItem.specialistName === item.specialistName,
											)
												? true
												: false
										}
									>
										{item.specialistName}
									</Checkbox>
								</Row>
							);
						})
				) : (
					<Result
						icon={<IconSpecializationRegisterForm />}
						title="Sub Specialization Not Found"
						subTitle="Are you want to add new sub specialization?"
						extra={
							<Button
								type="primary"
								ghost
								onClick={() => {
									setShowAddSubSpecializationModal(true);
								}}
							>
								Add New Sub Specialization
							</Button>
						}
					></Result>
				)}
			</div>

			<Modal
				centered
				closable={true}
				open={showAddSubSpecializationModal}
				onCancel={() => {
					setShowAddSubSpecializationModal(false);
				}}
				footer={[
					<Button
						key="back"
						onClick={() => {
							setShowAddSubSpecializationModal(false);
						}}
					>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						onClick={() => {
							handleAddSubSpecialization();
							setShowAddSubSpecializationModal(false);
						}}
					>
						Add Sub Specialization
					</Button>,
				]}
			>
				<Row>
					<Text strong>Add New Sub Specialization</Text>
				</Row>
				<FormInput
					onChange={(e) => setAddSubSpecialization(e)}
					placeholder="New Sub Specialization Name"
					value={addSubSpecialization}
				/>
			</Modal>
		</Modal>
	);
};

export default ModalSubSpecialization;
