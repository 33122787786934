import { TransactionConsultationProperties } from '../../../../types/transactionConsultation.type';
import ConsultationSidebar from '../Sidebar/ConsultationSidebar';
import { LocalVideoTrack, Room } from 'twilio-video';
import TeleconferenceVideo from './TeleconferenceVideo';
import { Conversation } from '@twilio/conversations';
import { useMemo } from 'react';
import TeleconferenceChat from './TeleconferenceChat';
import { IUser } from '../../../../types/user.type';
import Separator from '../../../Separator';
import TeleconferenceRoomInfo from '../Components/TeleconferenceRoomInfo';
import IconButton from '../../../Button/IconButton';
import { theme } from '../../../../assets/theme';
import { ReactComponent as ChevronLeft } from '../../../../assets/icons/chevron-left-alt.svg';

export enum ESidebarMode {
	CHAT = 'CHAT',
	CONSULTATION = 'CONSULTATION',
}

interface Props {
	room?: Room;
	roomType: 'VIDEO_CHAT' | 'CHAT' | 'IN_PERSON' | undefined;
	conversation?: Conversation;
	transactionConsultation?: TransactionConsultationProperties;
	localVideoTrack?: LocalVideoTrack;
	isMicOn: boolean;
	isVideoOn: boolean;
	sidebarMode?: ESidebarMode;
	toggleMic: () => void;
	toggleVideo: () => void;
	toggleChat: () => void;
	toggleConsultation: () => void;
	setSidebarMode: React.Dispatch<
		React.SetStateAction<ESidebarMode | undefined>
	>;
	endTeleconference: () => void;
}

export default function TeleconferenceRoom(props: Props) {
	const isAlwaysOpenSidebar = useMemo(() => {
		if (!props.roomType) return;

		if (props.roomType === 'CHAT') return true;
		if (props.roomType === 'IN_PERSON') return true;

		return false;
	}, [props.roomType]);

	return (
		<div
			className={
				'relative w-screen h-screen flex flex-col ' +
				(isAlwaysOpenSidebar ? 'bg-gray-10' : 'bg-gray-100')
			}
		>
			<div
				className={
					'absolute w-full top-0 left-0' +
					(isAlwaysOpenSidebar ? ' bg-white' : '')
				}
			>
				<p
					className={
						'm-0 ml-2 lg:ml-12 p-3 font-medium ' +
						(isAlwaysOpenSidebar ? 'text-primary' : 'text-white')
					}
				>
					Medeasy Telemedicine
				</p>
				{isAlwaysOpenSidebar ? (
					<Separator className="relative z-10" />
				) : undefined}
			</div>
			<div
				className={
					'w-full h-screen pt-13 flex flex-col' +
					(isAlwaysOpenSidebar ? ' lg:px-15' : '')
				}
			>
				<div
					className={
						'relative flex w-full flex-1 min-h-0' +
						(isAlwaysOpenSidebar ? ' bg-white' : '')
					}
				>
					{props.roomType === 'VIDEO_CHAT' ? (
						<TeleconferenceVideo
							transactionConsultation={props.transactionConsultation}
							room={props.room}
							localVideoTrack={props.localVideoTrack}
							isMicOn={props.isMicOn}
							isVideoOn={props.isVideoOn}
							toggleMic={props.toggleMic}
							toggleVideo={props.toggleVideo}
							toggleChat={props.toggleChat}
							toggleConsultation={props.toggleConsultation}
							endTeleconference={props.endTeleconference}
						/>
					) : props.roomType === 'CHAT' ? (
						<div className="h-full flex-1 min-w-0 bg-white p-4 flex flex-col border-0 border-solid border-r border-ash-400">
							<div>
								<h2 className="m-0 p-3 text-5 font-medium">Live chat</h2>
							</div>
							<div className="flex-1">
								<TeleconferenceChat
									conversation={props.conversation}
									otherParticipant={
										props.transactionConsultation?.metaPatient as IUser
									}
									transactionConsultationStatus={
										props.transactionConsultation?.transactionStatus
									}
								/>
							</div>
						</div>
					) : props.roomType === 'IN_PERSON' ? (
						<div className="relative h-full flex-1 min-w-0 bg-white p-4 flex items-center justify-center border-0 border-solid border-r border-ash-400 overflow-hidden">
							<div
								className="absolute rounded-full border-2 border-solid border-gray-50 opacity-10 aspect-square"
								style={{ width: '90%', top: '-80%', right: '-5%' }}
							/>
							<div
								className="absolute rounded-full border-2 border-solid border-gray-50 opacity-10 aspect-square"
								style={{ width: '100%', top: '-75%', right: '-23%' }}
							/>
							<div
								className="absolute rounded-full border-2 border-solid border-gray-50 opacity-10 aspect-square"
								style={{ width: '110%', top: '80%', left: '-60%' }}
							/>
							<TeleconferenceRoomInfo
								consultationType={
									props.transactionConsultation?.consultationType
								}
								partnerName={
									props.transactionConsultation?.metaPartner?.partnerName
								}
								partnerAddress={
									props.transactionConsultation?.metaPartner?.address
								}
							/>
						</div>
					) : undefined}
					<div
						className={
							props.roomType === 'IN_PERSON'
								? 'flex'
								: `fixed lg:static right-0 top-13 ${
										props.roomType === 'CHAT' ? 'bottom-0' : 'bottom-28'
								  } flex`
						}
					>
						<IconButton
							onClick={() =>
								props.setSidebarMode((prev) =>
									prev ? undefined : ESidebarMode.CONSULTATION,
								)
							}
							className={
								props.roomType === 'IN_PERSON'
									? 'hidden'
									: 'lg:hidden flex items-center'
							}
						>
							<div className="bg-white py-8 px-3 rounded-l-6 flex">
								<ChevronLeft
									color={theme.colors.gray20}
									className={
										'transition-transform' +
										(props.sidebarMode ? ' rotate-180' : '')
									}
								/>
							</div>
						</IconButton>
						{props.sidebarMode || isAlwaysOpenSidebar ? (
							<ConsultationSidebar
								conversation={props.conversation}
								transactionConsultation={props.transactionConsultation}
								setSidebarMode={props.setSidebarMode}
								sidebarMode={props.sidebarMode ?? ESidebarMode.CONSULTATION}
								isAlwaysOpenSidebar={isAlwaysOpenSidebar}
								endTeleconference={props.endTeleconference}
							/>
						) : undefined}
					</div>
				</div>
			</div>
		</div>
	);
}
