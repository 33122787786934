import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import AppLayout from '../../../../layout/AppLayout';
import PageHeader from '../../../../components/PageHeader';
import {
	ArrowRightOutlined,
	PlusCircleOutlined,
	WarningOutlined,
} from '@ant-design/icons';
import {
	Button,
	Card,
	Divider,
	message,
	Row,
	Spin,
	Typography,
	Col,
	Modal,
	Result,
} from 'antd';
import { iconDeleteOutline as IconDeleteOutline } from '../../../../assets/icons';
import moment from 'moment';
import { apiGetPartnerDoctors } from '../../../../api/partnerDoctor';
import COLORS from '../../../../assets/globalColors';
import FormInput, { EFormType } from '../../../../components/Form/FormInput';
import {
	apiCreateDoctorPartnerSchedule,
	apiDeleteDoctorPartnerSchedule,
	apiUpdateDoctorPartnerSchedule,
} from '../../../../api/doctorPartnerSchedule';
import { useAuth } from '../../../../context/auth.context';

const activePath = '/app/setting/clinics-and-practice/add-clinic';
const { Text } = Typography;

type IDataAvailabilityItem = {
	weekday: number;
	title: string;
	data?: {
		scheduleId: string;
		text: string;
		startAt: Date | string;
		endAt: Date | string;
	}[];
};

interface Props {
	partnerId: string;
	type: string;
	availability: IDataAvailabilityItem;
}

interface NewSchedule {
	startAt: string;
	endAt: string;
}

export default function DetailDayScreen() {
	const { userId } = useAuth();
	const history = useHistory();
	const location = useLocation();
	const payload = location.state as unknown as Props;

	const [data, setData] = useState<Props>(
		payload
			? payload
			: {
					partnerId: '',
					type: '-',
					availability: {
						weekday: 0,
						title: '-',
						data: [
							{
								scheduleId: '',
								text: '',
								startAt: '',
								endAt: '',
							},
						],
					},
			  },
	);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [showModalConfirmation, setShowModalConfirmation] =
		useState<boolean>(false);
	const [showModalAddNew, setShowModalAddNew] = useState<boolean>(false);
	const [addNewSchedule, setAddNewSchedule] = useState<NewSchedule>({
		startAt: '',
		endAt: '',
	});

	// useEffect(() => {
	// 	fetchListSchedules();
	// }, []);

	// API
	const handleSaveNewAvailability = async (
		startAtTime: string,
		endAtTime: string,
	) => {
		try {
			setIsLoading(true);
			const partnerId = data.partnerId;

			if (!startAtTime || !endAtTime) {
				message.warning('WARNING: Time cannot be empty');
				setIsLoading(false);
				return;
			}

			// Get today's date
			//const todayDate = moment().format('YYYY-MM-DD');

			// Combine today's date with the selected time
			// const startAtDateTime = moment(`${todayDate} ${startAtTime}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm:ss');
			// const endAtDateTime = moment(`${todayDate} ${endAtTime}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm:ss');

			// Example logging for verification
			console.log('startAtTime:', startAtTime);
			console.log('endAtTime:', endAtTime);
			// console.log('Formatted Start At:', startAtDateTime);
			// console.log('Formatted End At:', endAtDateTime);

			// Call your API with the formatted datetime
			await apiCreateDoctorPartnerSchedule({
				partnerId,
				doctorId: userId || '',
				weekday: data.availability.weekday,
				startAt: startAtTime,
				endAt: endAtTime,
			});

			setIsLoading(false);
			message.success('SUCCESS: New schedule has been created successfully');
			fetchListSchedules();
		} catch (err) {
			setIsLoading(false);
			message.error('ERROR: Failed to add schedule');
			console.error('Error adding schedule:', err);
		}
	};

	const handleDeleteAvailability = async (scheduleId: string) => {
		try {
			const res = await apiDeleteDoctorPartnerSchedule(scheduleId);
			if (res) {
				message.success('SUCCESS: Schedule deleted successfully');
			}
			fetchListSchedules();
		} catch (err) {
			message.error('ERROR: Failed to delete schedule');
		} finally {
			setShowModalConfirmation(false);
		}
	};

	const handleUpdateAvailability = async (
		scheduleId: string,
		newStartAt: string,
		newEndAt: string,
	) => {
		try {
			const partnerId = data.partnerId;

			// Ensure data.availability.data is defined before mapping
			const updatedSchedules = data.availability.data
				? data.availability.data.map((e) => {
						if (e.scheduleId === scheduleId) {
							e.startAt = newStartAt;
							e.endAt = newEndAt;
						}
						return {
							startAt: moment
								.utc(e.startAt)
								.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
							endAt: moment.utc(e.endAt).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
						};
				  })
				: [];

			await apiUpdateDoctorPartnerSchedule({
				partnerId,
				doctorId: userId || '',
				weekday: data.availability.weekday,
				schedules: updatedSchedules,
			});
			message.success('SUCCESS: Schedule updated successfully');
			fetchListSchedules();
		} catch (err) {
			message.error('ERROR: Failed to update schedule');
		}
	};

	const fetchListSchedules = async () => {
		try {
			setIsLoading(true);
			const res = await apiGetPartnerDoctors(userId, data.partnerId);

			console.log('res::', res);

			if (res) {
				const availableDay: number[] = [];
				const schedules: {
					scheduleId: string;
					weekday: number;
					startAt: string | Date;
					endAt: string | Date;
				}[] = [];
				for (const result of res.results) {
					if (result.schedules) {
						for (const sch of result.schedules) {
							if (!availableDay.includes(sch.weekday)) {
								availableDay.push(sch.weekday);
							}
							schedules.push({
								scheduleId: sch.scheduleId || '',
								weekday: sch.weekday,
								startAt: sch.startAt,
								endAt: sch.endAt,
							});
						}
					}
				}

				const dataAvailability = Array.from({ length: 7 }, (v, i) => ({
					weekday: i,
					title: moment().isoWeekday(i).format('dddd'),
					data: schedules
						.filter((sch) => sch.weekday === i)
						.map((sch) => ({
							scheduleId: sch.scheduleId,
							text:
								moment(sch.startAt).format('HH:mm') +
								'-' +
								moment(sch.endAt).format('HH:mm'),
							startAt: sch.startAt,
							endAt: sch.endAt,
						})), // get from schedules
				}));
				const selectedDay = dataAvailability.filter((e) => {
					if (e.weekday === data.availability.weekday) {
						return e;
					}
				});
				console.log('selectedDay::', selectedDay);

				setData({
					partnerId: data.partnerId,
					type: data.type,
					availability: selectedDay[0],
				});
				console.log('newData:', {
					partnerId: data.partnerId,
					type: data.type,
					availability: selectedDay[0],
				});
			}
			setIsLoading(false);
		} catch (err) {
			setIsLoading(false);
			message.error('ERROR: Failed to load schedules');
		}
	};

	// Components
	const TitleDay = () => {
		const day = data ? data.availability.title : '-';
		return (
			<>
				<Row>
					<Text style={{ fontWeight: 700, fontSize: 18 }}>{day} Schedule</Text>
				</Row>
				<Row>
					<Text
						style={{
							color: COLORS.charcoal_200,
							fontSize: 14,
							fontWeight: 500,
						}}
					>
						Enter your consultation fee
					</Text>
				</Row>
				<Divider />
			</>
		);
	};

	const Time = (startTime: string, endTime: string, scheduleId?: string) => {
		// scheduleId ada -> update schedule
		// scheduleId gaada -> create new schedule

		return (
			<div className="flex flex-1">
				<FormInput
					className="flex-1 ml-2"
					classInput="w-full text-4 border border-solid border-ash-800 rounded-4 py-2.5 px-3"
					rangePlaceholder={
						[startTime, endTime] || [
							addNewSchedule.startAt,
							addNewSchedule.endAt,
						] || ['00.00', '00.00']
					}
					value={
						[startTime, endTime] || [
							addNewSchedule.startAt,
							addNewSchedule.endAt,
						]
					}
					type={EFormType.TIME_PICKER_RANGE}
					onChangeTime={(time: any) => {
						console.log('time:', time);
						console.log('scheduleId:', scheduleId);
						const todayDate = moment().format('YYYY-MM-DD');
						const newStartTime = moment(`${todayDate} ${time[0]}`).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
						const newEndTime = moment(`${todayDate} ${time[1]}`).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

						console.log('newStartTime:', newStartTime);
						console.log('newEndTime:', newEndTime);
						//output day

						if (scheduleId) {
							handleUpdateAvailability(scheduleId, newStartTime, newEndTime);
						} else {
							setAddNewSchedule({
								startAt: newStartTime,
								endAt: newEndTime,
							});
						}
					}}
					rangeSeparator={<ArrowRightOutlined />}
				/>
			</div>
		);
	};

	const TimeRow = (scheduleId: string, startTime: string, endTime: string) => {
		return (
			<>
				<Row align="middle" justify="space-between">
					<Col>{Time(startTime, endTime, scheduleId)}</Col>
					<CustomIconDeleteOutline
						onClick={() => {
							setShowModalConfirmation(true);
						}}
						style={{ cursor: 'pointer' }}
					/>
				</Row>
				<Modal
					centered
					closable={false}
					open={showModalConfirmation}
					onOk={() => {
						handleDeleteAvailability(scheduleId);
					}}
					onCancel={() => {
						setShowModalConfirmation(false);
					}}
					footer={
						<>
							<Row gutter={16} style={{ width: '100%' }}>
								<Col span={12}>
									<Button
										onClick={() => {
											setShowModalConfirmation(false);
										}}
										ghost
										style={{
											width: '100%',
											color: '#1D2B36',
											backgroundColor: '#F1F3F5',
											fontSize: 14,
											fontWeight: 700,
										}}
									>
										Not Now
									</Button>
								</Col>
								<Col span={12}>
									<Button
										type="primary"
										onClick={() => {
											handleDeleteAvailability(scheduleId);
										}}
										style={{
											width: '100%',
											fontSize: 14,
											fontWeight: 700,
										}}
									>
										Yes
									</Button>
								</Col>
							</Row>
						</>
					}
				>
					<Result
						icon={<ModalIcon />}
						title="Confirmation"
						subTitle="Are you sure to delete this consultation time?"
					/>
				</Modal>
				<Divider />
			</>
		);
	};

	const AddNewScheduleButton = () => {
		return (
			<Row justify="center">
				<Button
					type="ghost"
					style={{
						color: COLORS.primary,
						borderColor: COLORS.primary,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
					onClick={() => {
						setShowModalAddNew(true);
					}}
				>
					<div
						style={{
							width: 100,
							height: 48,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<PlusCircleOutlined
							style={{ color: COLORS.primary, marginRight: 10 }}
						/>
						<Text
							style={{
								color: COLORS.primary,
								fontSize: 14,
								fontWeight: 500,
							}}
						>
							Add Time
						</Text>
					</div>
				</Button>
			</Row>
		);
	};

	// Modal Confirmation Delete Schedule
	const ModalIcon = () => {
		return (
			<Row align="middle" justify="center" style={{ width: '100%' }}>
				<div
					style={{
						backgroundColor: '#F1F3F5',
						borderRadius: '50%',
						width: 64,
						height: 64,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<WarningOutlined style={{ fontSize: 28 }} />
				</div>
			</Row>
		);
	};

	// Component Group
	const TimeRowList = () => {
		const output = [];
		if (data && data.availability.data) {
			const times = data.availability.data.sort((a, b) =>
				(a.startAt as string).localeCompare(b.startAt as string),
			);
			for (let i = 0; i < times.length; i++) {
				const startAt = moment.utc(times[i].startAt).format('HH:mm');
				const endAt = moment.utc(times[i].endAt).format('HH:mm');
				const scheduleId = times[i].scheduleId;
				output.push(TimeRow(scheduleId, startAt, endAt));
			}
		}
		return output;
	};

	return (
		<AppLayout activePath={activePath}>
			<Card style={{ paddingBottom: 110 }}>
				<PageHeader
					title={data ? `${data.type} Setting` : 'Setting'}
					onClick={() => history.goBack()}
				/>
				<Divider />
				<TitleDay />
				{isLoading && <CustomSpin spinning={isLoading} />}
				{!isLoading && TimeRowList()}
				<AddNewScheduleButton />
				{/* Modal Add New */}
				<Modal
					centered
					closable={false}
					open={showModalAddNew}
					onCancel={() => {
						setShowModalAddNew(false);
					}}
					footer={
						<>
							<Row gutter={16} style={{ width: '100%' }}>
								<Col span={12}>
									<Button
										onClick={() => {
											setShowModalAddNew(false);
										}}
										ghost
										style={{
											width: '100%',
											color: '#1D2B36',
											backgroundColor: '#F1F3F5',
											fontSize: 14,
											fontWeight: 700,
										}}
									>
										Not Now
									</Button>
								</Col>
								<Col span={12}>
									<Button
										type="primary"
										onClick={() => {
											setShowModalAddNew(false);
											handleSaveNewAvailability(
												addNewSchedule.startAt,
												addNewSchedule.endAt,
											);
										}}
										style={{
											width: '100%',
											fontSize: 14,
											fontWeight: 700,
										}}
									>
										Yes
									</Button>
								</Col>
							</Row>
						</>
					}
				>
					<Row justify="center">
						<Text
							style={{
								fontWeight: 600,
								fontSize: 20,
								marginTop: 12,
							}}
						>
							Select Time
						</Text>
					</Row>
					<Row justify="center" style={{ marginBottom: 24 }}>
						<Text style={{ fontSize: 14 }}>Set your consultation time</Text>
					</Row>
					<Row justify="center">{Time('15.00', '15.00')}</Row>
				</Modal>
			</Card>
		</AppLayout>
	);
}

// Styled Components
const CustomIconDeleteOutline = styled(IconDeleteOutline)`
	path {
		stroke: #d81f64;
	}
`;
const CustomSpin = styled(Spin)`
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0);
`;