import React, { useEffect, useState, createRef } from 'react';
import AppLayout from '../../layout/AppLayout';
import {
	Typography,
	Card,
	Row,
	Col,
	Form,
	Steps,
	Button,
	message,
	Space,
	Input,
	InputNumber,
	Result,
	Modal,
	Radio,
	Upload,
	Spin,
	Divider,
	DatePicker,
} from 'antd';
import type { RadioChangeEvent } from 'antd';
import type { RcFile, UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import { ReactSketchCanvas } from 'react-sketch-canvas';
import {
	DoctorProperties,
	initialDoctor,
	SpecialistProperties,
	UploadCertificateResponse,
	UploadSignatureResponse,
} from '../../types/doctor.type';
import styled from 'styled-components';
import {
	IconSubmitRegisterForm,
	IconSpecializationRegisterForm,
	IconUserOutlined,
	IconBookmarkOutlined,
} from '../../assets/images';
import { useHistory } from 'react-router-dom';
import { httpRequest } from '../../helpers/api';
import { UploadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { ApiResponseDefault } from '../../types/apiResponse.type';
import { iconDeleteOutlineColorize as IconDeleteOutline } from '../../assets/icons';
import ModalSpecialization from '../../components/Webapp/DoctorInformation/ModalSpecialization';
import ModalSubSpecialization from '../../components/Webapp/DoctorInformation/ModalSubSpecialization';
import { exportAsPng, getBase64 } from '../../helpers/image';
import useSpecialization from '../../hooks/useSpecialization';
import { useAuth } from '../../context/auth.context';
import { generateFullName } from '../../utils/name';
import { apiUpdateMyProfile } from '../../api/auth';
import { getErrorMessage } from '../../helpers/errorHandler';
import { apiPostPointReward } from '../../types/pointReward';
import { EPointCategory } from '../../types/point.type';
import { getInitialMiddleName, getInitialName } from '../../helpers/name';
import { generateFormRules } from '../../helpers/formRules';
import moment from 'moment';

const { Text } = Typography;
const { Step } = Steps;

const DoctorRegisterPage: React.FC = () => {
	const history = useHistory();
	const { user, token, getProfile, signout } = useAuth();

	const [form] = Form.useForm();

	const [doctor, setDoctor] = useState<DoctorProperties>({
		...initialDoctor,
		user: { ...user },
		doctorId: user?.userId,
	});

	const [submit, setSubmit] = useState<boolean>(false);
	const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

	const [current, setCurrent] = useState(0);

	const inAppSignatureRef: any = createRef();
	const [signatureType, setSignatureType] = useState<'inApp' | 'upload'>(
		'inApp',
	);
	const [exportImage, setExportImage] = useState<any>(null);

	const [uploadedCertificateUrl, setUploadedCertificateUrl] = useState<any[]>(
		[],
	);

	const [showSpecializationModal, setShowSpecializationModal] =
		useState<boolean>(false);
	const [showSubSpecializationModal, setShowSubSpecializationModal] =
		useState<any>({});
	const { specializations } = useSpecialization();
	const [groupingSpecialist, setGroupingSpecialist] = useState<any>([]);

	useEffect(() => {
		if (user) {
			setDoctor({
				...doctor,
				...(user?.doctor || {}),
				user: { ...user },
				doctorId: user?.userId,
			});
		}
	}, [user]);

	useEffect(() => {
		let newGroupSpecialists = doctor.specialists?.reduce(function (
			storage: any,
			item: any,
		) {
			const data = {
				specialistId: item.specialistId || '',
				specialistName: item.specialistName || '',
				subSpecialists:
					item.subSpecialistId || item.subSpecialistName
						? [
								{
									subSpecialistId: item.subSpecialistId || '',
									subSpecialistName: item.subSpecialistName || '',
								},
						  ]
						: [],
			};

			let group = item['specialistId'];
			if (!group) {
				group = item['specialistName'];
			}

			storage[group] = storage[group] || {};
			const storageLength = Object.keys(storage[group]).length;
			if (storageLength > 0) {
				storage[group] = {
					...storage[group],
					subSpecialists: [
						...(storage[group].subSpecialists || []),
						...(data.subSpecialists || []),
					],
				};
			} else {
				storage[group] = data;
			}
			return storage;
		}, {});

		setGroupingSpecialist(newGroupSpecialists);
	}, [doctor.specialists]);

	useEffect(() => {
		const names = [
			doctor.user?.firstName,
			doctor.user?.middleName,
			doctor.user?.lastName,
		];

		const fullname = names.filter((item) => item).join(' ');
		if (fullname && doctor.title) {
			setDoctor({
				...doctor,
				nameOfSignature: [fullname, doctor.title].join(', '),
			});
		} else {
			setDoctor({
				...doctor,
				nameOfSignature: 'Your Name, Your Title',
			});
		}
	}, [
		doctor.user?.firstName,
		doctor.user?.middleName,
		doctor.user?.lastName,
		doctor.title,
	]);

	const handleConfirmSpecialization = (items: SpecialistProperties[]) => {
		const selectedData = items.map((item) => ({
			specialistId: item.specialistId || '',
			specialistName: item.specialistName || '',
		}));

		setDoctor((old) => ({
			...old,
			specialists: [...(old.specialists || []), ...selectedData],
		}));

		setShowSpecializationModal(false);
	};

	const handleConfirmSubSpecialization = (
		items: SpecialistProperties[],
		parent: SpecialistProperties,
		index: number,
	) => {
		const selectedData = items.map((item) => ({
			specialistId: parent.specialistId || '',
			specialistName: parent.specialistName || '',
			subSpecialistId: item.specialistId || '',
			subSpecialistName: item.specialistName || '',
		}));

		console.log('selectedData=>>>>>>', selectedData);

		setDoctor((old) => ({
			...old,
			specialists: [...(old.specialists || []), ...selectedData],
		}));

		setShowSubSpecializationModal((prev: any) => ({
			...prev,
			[parent.specialistName]: false,
		}));
	};

	const handleRemoveItemSpecialization = (name: string) => {
		const newSpecialitites = doctor.specialists?.filter(
			(specialist) =>
				specialist.specialistName !== name &&
				specialist.subSpecialistName !== name,
		);
		setDoctor((old) => ({
			...old,
			specialists: newSpecialitites,
		}));
	};

	const warning = () => {
		return (
			<Row style={{ paddingBottom: 20, paddingTop: 20 }}>
				<InfoCircleOutlined style={{ color: '#D81F64' }} />
				<Text type="secondary" style={{ fontSize: 14, paddingLeft: 10 }}>
					Before saving data, make sure all information is filled in correctly
				</Text>
			</Row>
		);
	};

	// Steps & Radio
	const next = () => {
		setCurrent(current + 1);
	};

	const prev = () => {
		setCurrent(current - 1);
	};

	const onRadioChange = (e: RadioChangeEvent) => {
		console.log('e.target.value is::', e.target.value);
		setSignatureType(e.target.value);
	};

	// In App Signature
	// const exportAsSvg = async () => {
	// 	const exportSvg = inAppSignatureRef.current.exportSvg;
	// 	if (exportSvg) {
	// 		const inAppSignatureSVGURI = await exportSvg();
	// 		setInAppSignatureSVG(inAppSignatureSVGURI);
	// 	} else {
	// 		message.error('ERROR: Failed to save in-app signature, please try again!');
	// 	}
	// };

	const exportAsPngImage = async () => {
		console.log('ini signature export as png');
		console.log(exportImage);
		console.log('converting to File...');
		const file = await exportAsPng(exportImage);
		console.log('processed file is:::', file);
		return file;
	};

	// Upload Certificate
	const [previewCertificateOpen, setPreviewCertificateOpen] = useState(false);
	const [previewCertificateImage, setPreviewCertificateImage] = useState('');
	const [previewCertificateTitle, setPreviewCertificateTitle] = useState('');
	const [fileCertificateList, setFileCertificateList] = useState<any[]>([]);

	const handleCertificateCancel = () => setPreviewCertificateOpen(false);

	const handleCertificatePreview = async (file: UploadFile) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj as RcFile);
		}

		setPreviewCertificateImage(file.url || (file.preview as string));
		setPreviewCertificateOpen(true);
		setPreviewCertificateTitle(
			file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1),
		);
		console.log('fileList:::', fileCertificateList);
	};

	const handleCertificateChange: UploadProps['onChange'] = ({
		fileList: newFileList,
	}) => {
		setFileCertificateList(newFileList);
		console.log('fileList::: dari handleCertificateChange', newFileList);
	};

	const uploadCertificateButton = (
		<>
			<Button
				type="primary"
				block
				ghost
				icon={<UploadOutlined />}
				style={{
					marginBottom: '20px',
					backgroundColor: '#ffffff',
					color: '#D81F64',
					marginTop: '20px',
				}}
			>
				Upload Certificate
			</Button>
		</>
	);

	// Upload Signature
	const [previewSignatureOpen, setPreviewSignatureOpen] = useState(false);
	const [previewSignatureImage, setPreviewSignatureImage] = useState('');
	const [previewSignatureTitle, setPreviewSignatureTitle] = useState('');
	const [fileSignature, setFileSignature] = useState<any>();

	const saveInAppSignature = async () => {
		if (signatureType === 'inApp' && inAppSignatureRef.current) {
			const image = await inAppSignatureRef.current.exportImage('png');
			setExportImage(image);
			message.success('In App Signature Saved succesfully');
		}
	};

	React.useEffect(() => {
		if (signatureType === 'inApp') {
			setFileSignature(undefined);
		} else if (signatureType === 'upload') {
			setExportImage(undefined);
		}
	}, [signatureType]);

	const handleSignatureCancel = () => setPreviewSignatureOpen(false);

	const handleSignaturePreview = async (file: UploadFile) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj as RcFile);
		}

		setPreviewSignatureImage(file.url || (file.preview as string));
		setPreviewSignatureOpen(true);
		setPreviewSignatureTitle(
			file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1),
		);
		console.log('fileList:::', fileSignature);
	};

	const handleSignatureChange: UploadProps['onChange'] = ({
		fileList: newFileList,
	}) => {
		setFileSignature(newFileList);
		console.log('fileSignature:::', newFileList);
	};

	const uploadSignatureButton = (
		<>
			<Button
				type="primary"
				block
				ghost
				icon={<UploadOutlined />}
				style={{
					marginBottom: '20px',
					backgroundColor: '#ffffff',
					color: '#D81F64',
					marginTop: '20px',
					width: 586,
				}}
			>
				Upload Signature
			</Button>
		</>
	);

	// Forms

	const profileForm = () => {
		return (
			<>
				<Form.Item
					label="First Name"
					required
					name={'doctor.user?.firstName'}
					rules={generateFormRules('First Name', [
						'required',
						'letter-and-space',
					])}
				>
					<Input
						placeholder="Name"
						value={doctor.user?.firstName}
						onChange={(e) =>
							setDoctor({
								...doctor,
								user: { ...doctor.user!, firstName: e.target.value as string },
							})
						}
					/>
				</Form.Item>
				<Form.Item label="Middle Initial">
					<Input
						placeholder="Middle Initial"
						value={doctor.user?.middleName}
						onChange={(e) =>
							setDoctor({
								...doctor,
								user: {
									...doctor.user!,
									middleName: getInitialMiddleName(e.target.value as string),
								},
							})
						}
					/>
				</Form.Item>
				<Form.Item label="Last Name">
					<Input
						placeholder="Last Name"
						value={doctor.user?.lastName}
						onChange={(e) =>
							setDoctor({
								...doctor,
								user: { ...doctor.user!, lastName: e.target.value as string },
							})
						}
					/>
				</Form.Item>
				<Form.Item
					label="Doctor's Title"
					required
					name={'doctor.title'}
					rules={generateFormRules("Doctor's Title", ['required'])}
					extra="Separate title with coma (,)"
				>
					<Input
						placeholder="e.g MD, FPCP, FPCC"
						value={doctor.title}
						onChange={(e) =>
							setDoctor({ ...doctor, title: e.target.value as string })
						}
					/>
				</Form.Item>
				<Form.Item
					label="Nickname"
					required
					name={'doctor.user?.nickName'}
					rules={generateFormRules('Nickname', [
						'required',
						'letter-and-space',
					])}
				>
					<Input
						placeholder="Nickname"
						value={doctor.user?.nickName}
						onChange={(e) =>
							setDoctor({
								...doctor,
								user: { ...doctor.user!, nickName: e.target.value as string },
							})
						}
					/>
				</Form.Item>
				<Form.Item
					label="Gender"
					required
					name={'doctor.user?.gender'}
					rules={generateFormRules('Gender', ['required'])}
				>
					<Radio.Group
						value={doctor.user?.gender}
						defaultValue={'male'}
						onChange={(e) =>
							setDoctor({
								...doctor,
								user: { ...doctor.user!, gender: e.target.value as string },
							})
						}
					>
						<Radio value="male">Male</Radio>
						<Radio value="female">Female</Radio>
					</Radio.Group>
				</Form.Item>
				<Form.Item
					label="Birthdate"
					required
					name={'doctor.user?.birthdate'}
					rules={generateFormRules('Birthdate', ['required'])}
				>
					<DatePicker
						style={{ width: '100%' }}
						allowClear
						format={'DD MMMM YYYY'}
						disabledDate={(current) =>
							current.isAfter(moment().subtract(14, 'years'))
						}
						value={moment(doctor.user?.birthdate)}
						onChange={(date: any, dateString: string) =>
							setDoctor({
								...doctor,
								user: { ...doctor.user!, birthdate: dateString },
							})
						}
					/>
				</Form.Item>
				<Form.Item
					label="Name of Signature"
					required
					rules={generateFormRules('Name of Signature', ['required'])}
				>
					<Input
						disabled
						placeholder="Name of Signature"
						value={doctor.nameOfSignature}
						onChange={(e) =>
							setDoctor({
								...doctor,
								nameOfSignature: e.target.value,
							})
						}
					/>
				</Form.Item>
				<Form.Item
					label="Experience"
					name={'doctor.experience'}
					rules={generateFormRules('Experience', ['number'])}
				>
					<InputNumber
						placeholder="Experience"
						value={doctor.experience}
						type="number"
						min={0}
						addonAfter="Years"
						onChange={(e) =>
							setDoctor({
								...doctor,
								experience: e as unknown as number,
							})
						}
					/>
				</Form.Item>
			</>
		);
	};

	const specializationForm = () => {
		return (
			<>
				<Form.Item
					style={{ margin: 0 }}
					label="Specialization"
					name={'doctor.specialists'}
					required
				>
					{doctor.specialists?.length === 0 ? (
						<Result
							style={{ padding: 0 }}
							icon={<IconSpecializationRegisterForm />}
							title="Specialization Empty"
							subTitle="The specialization you enter will appear here."
							extra={
								<Button
									type="primary"
									ghost
									onClick={() => {
										setShowSpecializationModal(true);
									}}
								>
									Choose
								</Button>
							}
						></Result>
					) : (
						<>
							<div>
								{Object.keys(groupingSpecialist)?.map(
									(specialistId: any, index) => {
										const specialist = groupingSpecialist[specialistId];

										return (
											<div key={index}>
												<div
													style={{
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'space-between',
													}}
												>
													<Text>{specialist.specialistName}</Text>
													<Button
														style={{ display: 'flex', alignItems: 'center' }}
														onClick={() =>
															handleRemoveItemSpecialization(
																specialist.specialistName,
															)
														}
													>
														<IconDeleteOutline width={20} />
													</Button>
												</div>
												<Divider style={{ marginTop: 10, marginBottom: 10 }} />

												<div
													style={{
														paddingLeft: 25,
													}}
												>
													{specialist?.subSpecialists?.map((sub: any) => (
														<>
															<div
																style={{
																	display: 'flex',
																	justifyContent: 'space-between',
																	alignItems: 'center',
																}}
															>
																<Text>{sub?.subSpecialistName}</Text>
																<Button
																	style={{
																		display: 'flex',
																		alignItems: 'center',
																	}}
																	onClick={() =>
																		handleRemoveItemSpecialization(
																			sub.subSpecialistName,
																		)
																	}
																>
																	<IconDeleteOutline width={20} />
																</Button>
															</div>
															<Divider
																style={{ marginTop: 10, marginBottom: 10 }}
															/>
														</>
													))}
													<Button
														type="ghost"
														style={{ borderColor: '#D81F64', fontSize: 14 }}
														onClick={() =>
															setShowSubSpecializationModal((prev: any) => ({
																...prev,
																[specialist.specialistName]: true,
															}))
														}
													>
														<Text style={{ color: '#D81F64' }}>
															Add Sub Specialization
														</Text>
													</Button>
													<Divider
														style={{ marginTop: 10, marginBottom: 10 }}
													/>
												</div>
												<ModalSubSpecialization
													subSpecializaionList={specializations}
													data={doctor.specialists || []}
													onConfirm={(data: SpecialistProperties[]) =>
														handleConfirmSubSpecialization(
															data,
															specialist,
															index,
														)
													}
													showModal={
														showSubSpecializationModal[
															specialist.specialistName
														]
													}
													closeModal={() =>
														setShowSubSpecializationModal((prev: any) => ({
															...prev,
															[specialist.specialistName]: false,
														}))
													}
												/>
											</div>
										);
									},
								)}
								<Button
									type="ghost"
									style={{
										width: '100%',
										borderColor: '#D81F64',
										marginTop: doctor?.educations?.length !== 0 ? 20 : 0,
									}}
									onClick={() => setShowSpecializationModal(true)}
								>
									<Text
										style={{
											fontSize: 14,
											fontWeight: 700,
											color: '#D81F64',
											marginLeft: 8,
										}}
									>
										Add Specialization
									</Text>
								</Button>
							</div>
						</>
					)}
				</Form.Item>
				<Form.Item
					label="Other Sub Specialization"
					style={{ paddingTop: '24px', marginBottom: 10 }}
					extra="If more than one separate with coma (,)"
				>
					<Input
						placeholder="Other Sub Specialization"
						value={doctor.otherSpecialist}
						onChange={(e) =>
							setDoctor({
								...doctor,
								otherSpecialist: e.target.value,
							})
						}
					/>
				</Form.Item>
				<Form.Item
					label="PRC Number"
					required
					name={'doctor.prcNumber'}
					rules={generateFormRules('PRC Number', ['required', 'number'])}
				>
					<Input
						type="number"
						placeholder="PRC Number"
						value={doctor.prcNumber}
						onChange={(e) =>
							setDoctor({
								...doctor,
								prcNumber: e.target.value,
							})
						}
					/>
				</Form.Item>
				<Form.Item
					label="PTR Number"
					required
					name={'doctor.ptrNumber'}
					rules={generateFormRules('PTR Number', ['required', 'number'])}
				>
					<Input
						type="number"
						placeholder="PTR Number"
						value={doctor.ptrNumber}
						onChange={(e) =>
							setDoctor({
								...doctor,
								ptrNumber: e.target.value,
							})
						}
					/>
				</Form.Item>
				<Form.Item
					label="Tax Identification Number"
					required
					name={'doctor.tinNumber'}
					rules={generateFormRules('TIN Number', ['required', 'number'])}
				>
					<Input
						type="number"
						placeholder="TIN Number"
						value={doctor.tinNumber}
						onChange={(e) =>
							setDoctor({
								...doctor,
								tinNumber: e.target.value,
							})
						}
					/>
				</Form.Item>
			</>
		);
	};

	const signatureForm = () => {
		return (
			<>
				<Form.Item label="Signature">
					<Row>
						<Text
							type="secondary"
							style={{ textAlign: 'center', paddingBottom: 16 }}
						>
							You can choose wether create signature in app or upload signature
							image{' '}
						</Text>
					</Row>
					<Row>
						<Radio.Group
							onChange={onRadioChange}
							value={signatureType}
							style={{ paddingBottom: 15 }}
						>
							<Radio value={'inApp'}>In App Signature</Radio>
							<Radio value={'upload'}>Upload Image</Radio>
						</Radio.Group>
					</Row>
					<>
						{signatureType === 'inApp' && (
							<>
								<Row
									style={{
										flex: 1,
										borderColor: '#A5B2BD',
										borderRadius: 16,
									}}
								>
									<Col>
										<ReactSketchCanvas
											ref={inAppSignatureRef}
											width="586px"
											strokeWidth={5}
											strokeColor="black"
											exportWithBackgroundImage={false}
										/>
									</Col>
									<Col>
										<Button
											onClick={() => {
												inAppSignatureRef.current.clearCanvas();
											}}
											style={{
												backgroundColor: '#F1F3F5',
												color: 'black',
												zIndex: 1,
												position: 'absolute',
												top: 12,
												right: 10,
											}}
										>
											Clear
										</Button>
									</Col>
								</Row>
								<Row>
									<Button
										type="ghost"
										style={{ borderColor: '#D81F64', marginTop: 10, flex: 1 }}
										onClick={() => saveInAppSignature()}
									>
										Save Signature
									</Button>
								</Row>
							</>
						)}
						{signatureType === 'upload' && (
							<>
								{(!fileSignature ||
									(fileSignature && fileSignature.length === 0)) && (
									<Result
										icon={<IconUserOutlined />}
										title="Signature Empty"
										subTitle="If you have entered your signature, your signature will appear here."
									/>
								)}
								<div
									style={{
										display: 'flex',
										justifyContent: 'center',
										alignContent: 'center',
									}}
								>
									<CustomUpload
										listType="picture-card"
										fileList={fileSignature}
										maxCount={1}
										onPreview={handleSignaturePreview}
										onChange={handleSignatureChange}
										name="file"
										accept=".jpg,.jpeg,.png"
										style={{
											width: '100%',
											display: 'block',
										}}
										beforeUpload={(file) => {
											const isLtMaxSize = file.size / 1024 / 1024 < 5;
											if (!isLtMaxSize) {
												message.error(`Image must smaller than ${5}MB!`);
											} else {
												// generate preview image
												const reader = new FileReader();
												reader.readAsDataURL(file);
												// end generate preview image
											}

											return false;
										}}
									>
										{uploadSignatureButton}
									</CustomUpload>
									<Modal
										open={previewSignatureOpen}
										title={previewSignatureTitle}
										footer={null}
										onCancel={handleSignatureCancel}
									>
										<img
											alt="example"
											style={{ width: '100%' }}
											src={previewSignatureImage}
										/>
									</Modal>
								</div>
							</>
						)}
					</>
				</Form.Item>
				<Form.Item label="Certificates">
					<>
						{fileCertificateList.length === 0 && (
							<Result
								icon={<IconBookmarkOutlined />}
								title="Certificate Empty"
								subTitle="Aliquam et magna nunc morbi porta malesuada velit interdum nunc tempor"
							/>
						)}
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignContent: 'center',
							}}
						>
							<CustomUpload
								listType="picture-card"
								fileList={fileCertificateList}
								onPreview={handleCertificatePreview}
								onChange={handleCertificateChange}
								name="file"
								accept=".jpg,.jpeg,.png"
								style={{
									width: '100%',
									display: 'block',
								}}
								beforeUpload={(file) => {
									const isLtMaxSize = file.size / 1024 / 1024 < 5;
									if (!isLtMaxSize) {
										message.error(`Image must smaller than ${5}MB!`);
									} else {
										// generate preview image
										const reader = new FileReader();
										reader.readAsDataURL(file);
										reader.onloadend = function (e) {
											if (e.target && e.target.result) {
												// setImageUrl(e.target.result as string);
												setUploadedCertificateUrl([
													...uploadedCertificateUrl,
													e.target.result as string,
												]);
												// if (props.onPreviewChange) {
												// 	props.onPreviewChange(e.target.result as string);
												// }
											}
										};
										// end generate preview image
									}

									// props.onChange(file);

									return false;
								}}
								maxCount={100}
							>
								{uploadCertificateButton}
							</CustomUpload>
							<Modal
								open={previewCertificateOpen}
								title={previewCertificateTitle}
								footer={null}
								onCancel={handleCertificateCancel}
							>
								<img
									alt="example"
									style={{ width: '100%' }}
									src={previewCertificateImage}
								/>
							</Modal>
						</div>
					</>
				</Form.Item>
			</>
		);
	};

	const steps = [
		{
			title: 'Profile',
			content: profileForm(),
		},
		{
			title: 'Specialization',
			content: specializationForm(),
		},
		{
			title: 'Signature',
			content: signatureForm(),
		},
	];

	const onSubmitRegisterAsDoctor = async () => {
		setLoadingSubmit(true);

		// update user name
		const name = generateFullName({
			firstName: doctor.user?.firstName,
			middleName: doctor.user?.middleName,
			lastName: doctor.user?.lastName,
		});

		console.log('ini doctor yg dikirim:::', {
			...doctor,
			user: { ...user, name: name },
		});

		// post doctor info (without certificates & signature)
		try {
			const resUser = await apiUpdateMyProfile(user?.userId || '', {
				...doctor.user,
				name: name,
			});
			console.log('ini resUser::', resUser);

			if (user?.doctor) {
				// update doctor profile
				message.warn(
					'You have been registered as doctor. Please contact admin.',
				);
			} else {
				// register doctor account
				await httpRequest.post(`${process.env.REACT_APP_BASE_URL}/doctors`, {
					...doctor,
				});
			}
			message.success(
				'Doctor profile has been submitted and waiting for review.',
			);
			await apiPostPointReward({
				category: EPointCategory.REGISTER,
				filters: {
					maxNumber: 1,
				},
			});
		} catch (err) {
			message.error(
				'Failed when submit doctor profile. ' + getErrorMessage(err),
			);
		}

		// put certificate
		for (const image of fileCertificateList) {
			console.log('UPLOAD CERTIFICATE - 1. image certificate ::: ', image);
			console.log('UPLOAD CERTIFICATE - 2. user ::: ', user);

			let data = new FormData();
			data.append('doctorId', user?.userId!);
			data.append('image', image.originFileObj);

			try {
				const resUploadCertificate = await httpRequest.post<
					ApiResponseDefault<UploadCertificateResponse>
				>('/certificates', data, {
					headers: {
						Authorization: 'Bearer ' + token,
						'Content-Type': 'multipart/form-data',
					},
				});

				if (resUploadCertificate.data && resUploadCertificate.data.payload) {
					console.log(
						'UPLOAD CERTIFICATE - 3. result ::: ',
						resUploadCertificate.data.payload,
					);
				}
				message.success('Your certificate data has been submitted');
			} catch (error) {
				message.error('error upload certificate. ' + getErrorMessage(error));
				console.log('error upload certificate ::: ', error);
			}
		}

		// put signature
		let signatureImage;
		if (signatureType === 'inApp') {
			console.log('exporting as png...');
			signatureImage = await exportAsPngImage();
		} else {
			signatureImage = fileSignature[0].originFileObj;
		}
		console.log('UPLOAD SIGNATURE - 1. image signature ::: ', signatureImage);
		console.log('UPLOAD SIGNATURE - 2. user ::: ', user);

		let data = new FormData();
		data.append('image', signatureImage);

		try {
			const resUploadSignature = await httpRequest.put<
				ApiResponseDefault<UploadSignatureResponse>
			>('/doctors/' + user?.userId + '/upload-signature', data, {
				headers: {
					Authorization: 'Bearer ' + token,
					'Content-Type': 'multipart/form-data',
				},
			});

			console.log('UPLOAD SIGNATURE - 3. result ::: ', resUploadSignature);

			if (resUploadSignature.data && resUploadSignature.data.payload) {
				console.log('UPLOAD SIGNATURE - 3. result ::: ', resUploadSignature);
			}
			message.success('Your singature has been submitted');
		} catch (error) {
			message.error('error upload signature. ' + getErrorMessage(error));
			console.log('error upload signature ::: ', error);
		}

		await getProfile();

		setLoadingSubmit(false);
		setSubmit(true);
	};

	return (
		<Spin spinning={loadingSubmit}>
			<AppLayout activePath={'/register'}>
				<Space
					align="center"
					direction="vertical"
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						padding: '30px',
						backgroundColor: '#F1F3F5',
					}}
				>
					{!submit && (
						<CustomCard title="Doctor Information" bordered={false}>
							<CustomSteps current={current} labelPlacement="vertical">
								{steps.map((item) => (
									<Step key={item.title} title={item.title} />
								))}
							</CustomSteps>
							<div className="steps-content">
								<CustomForm
									layout="vertical"
									name="validateOnly"
									onFinish={() => {
										current < steps.length - 1
											? next()
											: onSubmitRegisterAsDoctor();
										window.scrollTo({ top: 0, behavior: 'smooth' });
									}}
									form={form}
								>
									{steps[current].content}

									{warning()}

									<Form.Item
										shouldUpdate
										className="steps-action"
										style={{ display: 'flex', justifyContent: 'space-between' }}
									>
										{() => (
											<>
												{current > 0 ? (
													<Button
														style={{
															margin: '0 8px',
															backgroundColor: ' #F1F3F5',
															color: '#1D2B36',
														}}
														onClick={() => {
															prev();
															window.scrollTo({ top: 0, behavior: 'smooth' });
														}}
													>
														Previous
													</Button>
												) : (
													<Button
														style={{
															margin: '0 8px',
															backgroundColor: ' #F1F3F5',
															color: '#1D2B36',
														}}
														onClick={() => {
															Modal.confirm({
																title: 'Confirmation',
																content: 'Are you sure want to signout?',
																onOk: () => {
																	signout();
																	history.replace('/');
																},
															});
														}}
													>
														Logout
													</Button>
												)}
												{current < steps.length - 1 && (
													<Button
														type="primary"
														htmlType="submit"
														disabled={
															current === 0
																? !doctor.user?.firstName ||
																  !doctor.user?.nickName ||
																  !doctor.user?.gender ||
																  !doctor.user?.birthdate ||
																  !doctor.title ||
																  !doctor.nameOfSignature ||
																  !!form
																		.getFieldsError()
																		.some(({ errors }) => errors.length)
																: !doctor.user?.firstName ||
																  doctor.specialists?.length === 0 ||
																  !doctor.prcNumber ||
																  !doctor.ptrNumber ||
																  !doctor.tinNumber ||
																  !!form
																		.getFieldsError()
																		.some(({ errors }) => errors.length)
														}
													>
														Next
													</Button>
												)}
												{current === steps.length - 1 && (
													<Button
														type="primary"
														disabled={
															(!exportImage && !fileSignature) ||
															!!form
																.getFieldsError()
																.some(({ errors }) => errors.length)
														}
														onClick={() => {
															onSubmitRegisterAsDoctor();
															window.scrollTo({ top: 0, behavior: 'smooth' });
														}}
													>
														Done
													</Button>
												)}
											</>
										)}
									</Form.Item>
								</CustomForm>
							</div>
						</CustomCard>
					)}
					{submit && (
						<CustomCard>
							<Result
								icon={<IconSubmitRegisterForm />}
								title="Thank you for Submitting"
								subTitle="We will review your data and send notification regarding your submission immediately"
								extra={
									<div
										style={{ justifyContent: 'space-evenly' }}
										className="flex"
									>
										<Button
											type="primary"
											ghost
											onClick={() => {
												history.push(`/`);
											}}
										>
											Back to Landing Page
										</Button>
										<Button
											type="primary"
											onClick={() => {
												history.push(`/app`);
											}}
										>
											Go to Medeasy Home
										</Button>
									</div>
								}
							></Result>
						</CustomCard>
					)}
					<ModalSpecialization
						specializaionList={specializations}
						data={doctor.specialists || []}
						onConfirm={(data: SpecialistProperties[]) =>
							handleConfirmSpecialization(data)
						}
						showModal={showSpecializationModal}
						setShowModal={setShowSpecializationModal}
					/>
				</Space>
			</AppLayout>
		</Spin>
	);
};

export default DoctorRegisterPage;

const CustomCard = styled(Card)`
	display: flex;
	flex-direction: column;
	align-items: center;
	background: white;
	border-radius: 23px;
	padding: 30px 0 30px 0;
	max-width: 650px;

	div.ant-card-head-title {
		font-weight: 600;
		font-size: 20px;
	}

	div.ant-card-head {
		border-bottom: none !important;
	}
`;

const CustomSteps = styled(Steps)`
	padding: 0 0 30px 0;

	div.ant-steps-item-title {
		font-size: 14px;
	}
`;

const CustomForm = styled(Form)`
	.ant-form-item-label {
		font-size: 16px;
	}
`;

const CustomUpload = styled(Upload)`
	width: 100%;
	.ant-upload {
		width: 100%;
		border: none;
		background-color: white;
	}

	.ant-upload-list {
		display: flex;
		flex-direction: column;
		width: auto;
		justify-content: space-between;
		align-items: space-between;
	}

	.ant-upload-list-picture-card-container {
		display: inline-block;
		width: auto;
		height: auto;
		margin: 10px 0 10px 0;
		vertical-align: top;
		height: 222px;
		width: 586px;
	}

	.ant-upload-list-picture-card .ant-upload-list-item {
		height: 222px;
		width: 586px;
		margin: 2px 0 2px 0;
	}
`;

const CustomRow = styled(Row)`
	padding: 12px 0px;
	box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
`;
